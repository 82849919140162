import React, { useState } from "react";
import { FeatureGroup, CircleMarker } from "react-leaflet";
import { chain } from "lodash";
import AnalysisObstaclePopup from "@components/map/AnalysisObstaclePopup";
import { areaHighLightColor, staticColorMap } from "@constants/colors";

export default function RenderAnalysisAreaObstacles({
  obstacles,
  isShowPenetrationObstacle,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
  selectedAreas,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const areas = selectedAreas.map((area) => area.split("__")[0]);
  let visibleObstacles = chain(obstacles)
    .filter((obstacle) => {
      let name = `${obstacle.layer_rwy}_${obstacle.layer_sub_category}`;
      if (obstacle.layer_category === "AREA4") {
        name = `${obstacle.layer_rwy}_${obstacle.layer_category}`;
      } else if (obstacle.layer_sub_category === "2D") {
        name = "Area2D";
      }

      return areas.includes(name);
    })
    .value();
  if (isAbovePermissibleClick && !isBelowPermissibleClick) {
    visibleObstacles = visibleObstacles.filter(
      (obstacle) => obstacle.penetration
    );
  } else if (isBelowPermissibleClick && !isAbovePermissibleClick) {
    visibleObstacles = visibleObstacles.filter(
      (obstacle) => !obstacle.penetration
    );
  }

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <FeatureGroup pane="popupPane">
      {visibleObstacles.map((obstacle, i) => {
        let obstacleColor = staticColorMap["Obstacles"];

        if (isShowPenetrationObstacle) {
          obstacleColor = obstacle.penetration
            ? staticColorMap["RedObstacle"]
            : staticColorMap["GreenObstacle"];
        }

        return (
          <CircleMarker
            key={i}
            center={[
              Number(obstacle.dd_latitude),
              Number(obstacle.dd_longitude),
            ]}
            radius={6}
            pathOptions={{
              fillColor: obstacleColor,
              color:
                selectedOverE?.uid === obstacle.uid
                  ? areaHighLightColor
                  : obstacleColor,
              weight: selectedOverE?.uid === obstacle.uid ? 2 : 0,
              fillOpacity: 1,
            }}
            eventHandlers={{
              mouseover: () => handlePolygonOver(obstacle),
              mouseout: () => handlePolygonOut(),
            }}
          >
            <AnalysisObstaclePopup info={obstacle} />
          </CircleMarker>
        );
      })}
    </FeatureGroup>
  );
}
