import React from "react";
import { join } from "lodash";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Box,
} from "@mui/material";
import StyledTableCell, {
  StyledTableCellV3,
} from "@components/tabel-component/StyledTableCell";
import TableRowLoader from "@components/tabel-component/TableRowLoader";

export default function DPRTable({ isLoading, DPRDataList, selectedDPR }) {
  const rows = DPRDataList.filter((dpr) => {
    const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
    return selectedDPR.includes(dprName);
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellV3>
              <Box>Identification</Box>
              <Box>Name</Box>
            </StyledTableCellV3>
            <StyledTableCell>Lateral Limits</StyledTableCell>
            <StyledTableCellV3>
              <Box>Upper Limit</Box>
              <Box>Lower Limit</Box>
            </StyledTableCellV3>
            <StyledTableCellV3>Remarks</StyledTableCellV3>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader
            isLoading={isLoading}
            data={DPRDataList}
            colSpan={4}
          />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.Airspace_name}`}>
                <StyledTableCell>
                  <Box>{row?.designator || "-"}</Box>
                  <Box>{row?.Airspace_name || "-"}</Box>
                </StyledTableCell>
                <StyledTableCell>{row?.description || "-"}</StyledTableCell>
                <StyledTableCell>
                  <Box>{row?.upperLimit || "-"}</Box>
                  <Box>{row?.lowerLimit || "-"}</Box>
                </StyledTableCell>
                <StyledTableCell>
                  {join(row?.remarks, ", ") || "-"}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
