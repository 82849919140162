import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce, map, isEmpty, keys, flattenDeep, find } from "lodash";
import Select from "react-select";
import { toast } from "react-toastify";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import AIXMBanner from "@assets/images/aixmbanner.png";
import {
  catAAiData,
  aixmFilterType,
  generalData,
  enrData,
} from "@constants/aixmData";
import {
  airspaceFilterSortType,
  airspaceFilterType,
  DPRTypeFilterOptions,
} from "@constants/airspaceData";
import { ACCESS_TOKEN, defaultLocationCenter } from "@constants/constant";
import AirspaceTables from "@components/airspace/AirspaceTables";
import AixmAD1 from "@components/AixmAD1";
import AixmGeneral from "@components/AixmGeneral";
import AixmMap from "@components/AixmOSMMap";
import AixmSideBar from "@components/AixmSideBar";
import AixmAirportDetailTables from "@components/aixm-table-item/AixmAirportDetailTables";
import { PrimaryButton } from "@components/button";
import EnrouteCategoryComponent from "@components/EnrouteCategoryData";
import Header from "@components/Header";
import AixmExportdataModal from "@components/Modals/AixmExportdataModal";
import {
  selectFIRData,
  selectTMAData,
  selectCTAData,
  selectRegulatedAirspaceData,
  selectAllRoutesOptions,
  selectRoutesNameList,
  selectAllNavaidOptions,
  selectAllNavaidData,
  selectAllSignificantPointOptions,
  selectAllSignificantPoints,
  selectAllDPROptions,
  selectDPRNameList,
  selectDPRDataList,
  selectAllTRA_TSA_ADIZOptions,
  selectTRA_TSA_ADIZNameList,
  selectTRA_TSA_ADIZDataList,
} from "@store/airspace";
import {
  getFIRData,
  getTMAData,
  getCTAData,
  getRegulatedAirspaceData,
  getALLRoutes,
  getRouteData,
  getAllNavaid,
  getAllSignificantPoint,
  getAllTRA_TSA_ADIZ,
  getAllDPR,
  exportAirspaceSheet,
  exportRouteSheet,
  exportDRPSheet,
  exportTRASheet,
} from "@store/airspace/actions";
import {
  selectAIXMCategoryOptions,
  setSelectedVersion,
  setAllGeneral,
  setAD1Aerodrome,
  setAllEnroute,
  enrSubCategoryOptions,
} from "@store/aixm";
import {
  getAllAIXMAirport,
  getAixmDataVersion,
  getAllGeneralFeatureData,
  getAD1AerodromeData,
  getEnrouteFeatureData,
  getAD1_3AerodromeData,
  getAD1_5AerodromeData,
} from "@store/aixm/actions";
import { setAAIMuiAirport } from "@store/airport";
import { getAAIAirport } from "@store/airport/actions";
import { styles } from "@styles/aixmStyles";
import { commonStyles } from "@styles/commonStyles";
import { cleanString, splitCleanString } from "@utils/util";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";

export default function Aixm() {
  const dispatch = useDispatch();
  const {
    isLoadingAirport,
    isLoadingAirportDetail,
    isLoadingGetAixmDataVersion,
    allAirports,
    aixmDataVersions,
    selectedVersion,
    allGeneral,
    isLoadingGeneral,
    isLoadingAD1_3Aerodrome,
    isLoadingAD1_5Aerodrome,
    allEnroute,
  } = useSelector((state) => state.aixm);
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const categories = useSelector(selectAIXMCategoryOptions);
  const [selctedAirport, setAirport] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [selctedFeature, setFeature] = useState(null);

  // airspace
  const [searchQuery, setsearchQuery] = useState("");
  const {
    isLoadingAirspaceExport,
    isLoadingFIRData,
    isLoadingTMAData,
    isLoadingCTAData,
    isLoadingRegulatedAirspaceData,
    isLoadingGetAllNavaid,
    isLoadingGetAllsignificantPoint,
    isLoadingGetAllRoute,
    isLoadingGetRouteData,
    isLoadingGetAllDPR,
    isLoadingGetAllTRA_TSA_ADIZ,
  } = useSelector((state) => state.airspace);
  const { AAIMuiAirport, isLoadingAAIAirport } = useSelector(
    (state) => state.airport
  );
  const FIROptions = useSelector((state) => selectFIRData(state, searchQuery));
  const FIRData = useSelector((state) => selectFIRData(state));
  const TMAOptions = useSelector((state) => selectTMAData(state, searchQuery));
  const TMAData = useSelector((state) => selectTMAData(state));
  const CTAOptions = useSelector((state) => selectCTAData(state, searchQuery));
  const CTAData = useSelector((state) => selectCTAData(state));
  const regulatedAirspaceOptions = useSelector((state) =>
    selectRegulatedAirspaceData(state, searchQuery)
  );
  const regulatedAirspaceData = useSelector((state) =>
    selectRegulatedAirspaceData(state)
  );
  const [selctedRouteFilter, setSelctedRouteFilter] = useState(null);
  const allRoutesOptions = useSelector(selectAllRoutesOptions);
  const routesNameList = useSelector((state) =>
    selectRoutesNameList(state, selctedRouteFilter?.value, searchQuery)
  );
  const routeData = useSelector((state) => state.airspace.routeData);
  const navaidOptions = useSelector((state) =>
    selectAllNavaidOptions(state, searchQuery)
  );
  const allNavaidData = useSelector((state) => selectAllNavaidData(state));
  const significantPointOptions = useSelector((state) =>
    selectAllSignificantPointOptions(state, searchQuery)
  );
  const allSignificantPoints = useSelector((state) =>
    selectAllSignificantPoints(state)
  );
  const allDPROptions = useSelector((state) => selectAllDPROptions(state));
  const allTRA_TSA_ADIZOptions = useSelector((state) =>
    selectAllTRA_TSA_ADIZOptions(state)
  );
  const [selctedDPRFilter, setSelctedDPRFilter] = useState(null);
  const [selctedDPRTypeFilter, setSelctedDPRTypeFilter] = useState(null);
  const dprNameList = useSelector((state) =>
    selectDPRNameList(
      state,
      selctedDPRFilter?.value,
      selctedDPRTypeFilter?.value,
      searchQuery
    )
  );
  const DPRDataList = useSelector((state) =>
    selectDPRDataList(
      state,
      selctedDPRFilter?.value,
      selctedDPRTypeFilter?.value
    )
  );
  const [selctedMETAFilter, setSelctedMETAFilter] = useState(null);
  const METANameList = useSelector((state) =>
    selectTRA_TSA_ADIZNameList(state, selctedMETAFilter?.value, searchQuery)
  );
  const METADataList = useSelector((state) =>
    selectTRA_TSA_ADIZDataList(state, selctedMETAFilter?.value)
  );
  const [selctedAirspaceFilter, setSelctedAirspaceFilter] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedAirspaceRows, setSelectedAirspaceRows] = useState([]);
  const [selectedAirspaceRowsIds, setSelectedAirspaceRowsIds] = useState([]);
  const hasSectedAirspace = selectedAirspaceRows.length > 0 || selectedRoute;
  const [isOpenExportModal, setOpenExportModal] = useState(false);
  const [selectedAD2Category, setSelectedAD2Category] = useState(null);
  const [generalOptions, setGeneralOptions] = useState([]);
  const [selectedGeneral, setSelectedGeneral] = useState(null);
  const [generalCategoryOptions, setGeneralCategoryOptions] = useState([]);
  const [selectedGeneralCategory, setSelectedGeneralCategory] = useState(null);
  const [selectedAerodrome, setAerodrome] = useState(null);
  const [selectedAD1Category, setAD1Category] = useState(null);
  const [selectedEnrouteCategory, setSelectedEnrouteCategory] = useState(null);
  const selectedEnrSubCategoryOptions = useSelector((state) =>
    enrSubCategoryOptions(state, selectedEnrouteCategory)
  );

  useEffect(() => {
    dispatch(setSelectedVersion(null));
    // version list api
    dispatch(getAixmDataVersion());
  }, []); // eslint-disable-line

  useEffect(() => {
    let optionKeys = [];
    let optionKeysData = [];
    if (allGeneral && allGeneral.length > 0) {
      const alldata = allGeneral;
      alldata.map((data) => {
        return optionKeys.push(keys(data));
      });
      flattenDeep([...optionKeys]).forEach((d, index) => {
        optionKeysData.push({
          id: index + 1,
          label: d.replace(/\w+/g, (txt) => {
            return txt.split("_").join(" ").toUpperCase();
          }),
          value: d,
        });
      });
      setGeneralOptions([...optionKeysData]);
    }
  }, [allGeneral]);

  useEffect(() => {
    let optionKeysData = [];
    if (isEmpty(selectedGeneral)) return false;
    const alldata = allGeneral;
    alldata.map((data) => {
      if (!isEmpty(data[selectedGeneral.value])) {
        keys(data[selectedGeneral.value]).forEach((d, index) => {
          const strAr = splitCleanString(d);

          optionKeysData.push({
            id: index + 1,
            label: !isEmpty(strAr)
              ? `${strAr[0]} ${strAr[1]}.${strAr[2]}`
              : cleanString(d),
            value: d,
          });
        });
        setGeneralCategoryOptions([...optionKeysData]);
      }
      return true;
    });
  }, [allGeneral, selectedGeneral]);

  const handleFeature = (option) => {
    if (selctedFeature?.value === option.value) return;

    if (option === null) {
      setFeature(null);
      dispatch(setAAIMuiAirport(catAAiData));
      dispatch(setAllGeneral(null));
      dispatch(setAllEnroute(null));
      setSelectedGeneralCategory(null);
      setSelectedGeneral(null);
      dispatch(setAD1Aerodrome(null));
    } else {
      setFeature(option);
      if (option.value === "Airport") {
        dispatch(getAllAIXMAirport());
      } else if (option.value === "General") {
        dispatch(getAllGeneralFeatureData(generalData, null));
      } else if (option.value === "Airspace") {
        dispatch(getEnrouteFeatureData(enrData, null));
      }
    }

    setAerodrome(null);
    setAD1Category(null);
    setSelectedEnrouteCategory(null);
    setAirport(null);
    setAirportLocation(defaultLocationCenter);
    setZoomLevel(5);
    handleClearAllCategory();
    // airspace
    setSelctedAirspaceFilter(null);
    setSelctedRouteFilter(null);
    setSelctedMETAFilter(null);
    setSelectedRoute(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);
    setsearchQuery(null);
    handleAirspaceClear();
    // general
    setSelectedGeneral(null);
    setGeneralOptions([]);
    setGeneralCategoryOptions([]);
  };

  const handleGeneral = (option) => {
    if (option === null) {
      setSelectedGeneral(null);
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(5);
    } else {
      setSelectedGeneral(option);
      setZoomLevel(15);
    }
    setSelectedGeneralCategory(null);
  };

  const handleGeneralCategory = (option) => {
    if (option === null) {
      setSelectedGeneralCategory(null);
    } else {
      setSelectedGeneralCategory(option);
    }
  };

  const handleAirport = (option) => {
    if (option === null) {
      setAirport(null);
      handleClearAllCategory();
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(5);
    } else {
      setAirport(option);
      setAirportLocation(option.coordinates);
      setZoomLevel(15);
      if (selectedAD2Category)
        handleAirodromeAD2SelectedCategories(selectedAD2Category, option);
    }
  };

  const updateSelectedValues = (selectedValues, value) => {
    if (!selectedValues.includes(value)) {
      selectedValues.push(value);
    } else {
      selectedValues.splice(selectedValues.indexOf(value), 1);
    }
  };

  const handleClearAllCategory = () => {
    setSelectedAD2Category(null);
  };

  const handleVersion = (option) => {
    dispatch(setSelectedVersion(option));
  };

  const handleAirspaceDateFilter = (option, version) => {
    if (option.value === airspaceFilterType.firuir) {
      dispatch(getFIRData(version));
    } else if (option.value === airspaceFilterType.tma) {
      dispatch(getTMAData(version));
    } else if (option.value === airspaceFilterType.controlArea) {
      dispatch(getCTAData(version));
    } else if (option.value === airspaceFilterType.otherRegulatedAirspace) {
      dispatch(getRegulatedAirspaceData(version));
    } else if (option.value === airspaceFilterType.radioNavigation) {
      dispatch(getAllNavaid(version));
    } else if (option.value === airspaceFilterType.significantPoints) {
      dispatch(getAllSignificantPoint(version));
    } else if (
      option.value === airspaceFilterType.prohibitedRestrictedandDangerAreas
    ) {
      dispatch(getAllDPR(version));
    } else if (
      option.value === airspaceFilterType.militaryExecriseandTrainingAreas
    ) {
      dispatch(getAllTRA_TSA_ADIZ(version));
    } else if (option.value === airspaceFilterType.routes) {
      dispatch(getALLRoutes(version));
    }
  };

  const dateFilterCategoryApiData = (catType, arpId, versn) => {
    dispatch(
      getAAIAirport(catType, arpId, versn, (data) => {
        dispatch(
          setAAIMuiAirport({
            ...AAIMuiAirport,
            [`${catType}`]: data?.Status === "Error" ? null : data,
          })
        );
      })
    );
  };

  const handleDateFilter = () => {
    const version = selectedVersion?.id;

    if (selctedFeature?.value === aixmFilterType.airspace) {
      setSelectedRoute(null);
      // ENR apis call
      if (selctedAirspaceFilter?.isEnrApi) {
        // ENR 1,3,5 apis call
        dispatch(getEnrouteFeatureData(enrData, version));
      } else {
        // ENR 2,4 apis call
        handleAirspaceDateFilter(selctedAirspaceFilter, version);
      }
    } else if (!isEmpty(selectedAD2Category)) {
      // AD 2 apis call
      dispatch(
        getAllAIXMAirport(version, (airports) => {
          const findSelectedAirport = find(airports, [
            "icao",
            selctedAirport.icao,
          ]);
          let selectedAirportId = selctedAirport.id;
          if (findSelectedAirport) {
            setAirport(findSelectedAirport);
            selectedAirportId = findSelectedAirport.id;
          }

          switch (selectedAD2Category.codeId) {
            case "AD2.2":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.geoadministrative))
                return null;
              dateFilterCategoryApiData(
                "geoadministrative",
                selectedAirportId,
                version
              );
              break;
            case "AD2.3":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.operationalhours))
                return null;
              dateFilterCategoryApiData(
                "operationalhours",
                selectedAirportId,
                version
              );
              break;
            case "AD2.4":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.handlingservice))
                return null;
              dateFilterCategoryApiData(
                "handlingservice",
                selectedAirportId,
                version
              );
              break;
            case "AD2.5":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.passengerfacilities))
                return null;
              dateFilterCategoryApiData(
                "passengerfacilities",
                selectedAirportId,
                version
              );
              break;
            case "AD2.6":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rescueservices))
                return null;
              dateFilterCategoryApiData(
                "rescueservices",
                selectedAirportId,
                version
              );
              break;
            case "AD2.7":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.seasonalservice))
                return null;
              dateFilterCategoryApiData(
                "seasonalservice",
                selectedAirportId,
                version
              );
              break;
            case "AD2.8apr":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.apron)) return null;
              dateFilterCategoryApiData("apron", selectedAirportId, version);
              break;
            case "AD2.8txy":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.taxiway))
                return null;
              dateFilterCategoryApiData("taxiway", selectedAirportId, version);
              break;
            case "AD2.8chk":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.checkpoints))
                return null;
              dateFilterCategoryApiData(
                "checkpoints",
                selectedAirportId,
                version
              );
              break;
            case "AD2.9":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.surfacmovements))
                return null;
              dateFilterCategoryApiData(
                "checkpoints",
                selectedAirportId,
                version
              );
              break;
            case "AD2.10":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aixmobstacles))
                return null;
              dateFilterCategoryApiData(
                "aixmobstacles",
                selectedAirportId,
                version
              );
              break;
            case "AD2.11":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.meteorologicalinfo))
                return null;
              dateFilterCategoryApiData(
                "meteorologicalinfo",
                selectedAirportId,
                version
              );
              break;
            case "AD2.12rwydetail":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.runwayphysicals))
                return null;
              dateFilterCategoryApiData(
                "runwayphysicals",
                selectedAirportId,
                version
              );
              break;
            case "AD2.13":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.declareddistaces))
                return null;
              dateFilterCategoryApiData(
                "declareddistaces",
                selectedAirportId,
                version
              );
              break;
            case "AD2.14":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rwylights))
                return null;
              dateFilterCategoryApiData(
                "rwylights",
                selectedAirportId,
                version
              );
              break;
            case "AD2.15":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.powerlights))
                return null;
              dateFilterCategoryApiData(
                "powerlights",
                selectedAirportId,
                version
              );
              break;
            case "AD2.17":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airspaceservice))
                return null;
              dateFilterCategoryApiData(
                "airspaceservice",
                selectedAirportId,
                version
              );
              break;
            case "AD2.18":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airtrafficservices))
                return null;
              dateFilterCategoryApiData(
                "airtrafficservices",
                selectedAirportId,
                version
              );
              break;
            case "AD2.19":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.navaids))
                return null;
              dateFilterCategoryApiData("navaids", selectedAirportId, version);
              break;
            case "AD2.20":
              if (
                !AAIMuiAirport &&
                !isEmpty(AAIMuiAirport.aerodromeregulationinfo)
              )
                return null;
              dateFilterCategoryApiData(
                "aerodromeregulationinfo",
                selectedAirportId,
                version
              );
              break;
            case "AD2.21":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight))
                return null;
              dateFilterCategoryApiData(
                "noiseflight",
                selectedAirportId,
                version
              );
              break;
            case "AD2.22":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight))
                return null;
              dateFilterCategoryApiData(
                "noiseflight",
                selectedAirportId,
                version
              );
              break;
            case "AD2.23":
              if (
                !AAIMuiAirport &&
                !isEmpty(AAIMuiAirport.aerodromeregulationinfo)
              )
                return null;
              dateFilterCategoryApiData(
                "aerodromeregulationinfo",
                selectedAirportId,
                version
              );
              break;
            case "AD2.24":
              if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.charts)) return null;
              dateFilterCategoryApiData("charts", selectedAirportId, version);
              break;
            default:
              break;
          }
        })
      );
    } else if (selctedFeature?.value === aixmFilterType.general) {
      // GEN apis call
      dispatch(getAllGeneralFeatureData(generalData, version));
    } else if (!isEmpty(selectedAD1Category)) {
      // AD 1 apis call
      dispatch(getAD1AerodromeData(version));
      dispatch(getAD1_3AerodromeData(version));
      dispatch(getAD1_5AerodromeData(version));
    }
  };

  // airspace
  const handleAirspaceSearch = debounce((event) => {
    setsearchQuery(event.target.value);
  }, 500);

  const handleAirspaceFilter = (option) => {
    handleAirspaceClear();
    setSelctedRouteFilter(null);
    setSelctedMETAFilter(null);
    setSelectedRoute(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);
    setsearchQuery(null);

    if (option === null) {
      setSelctedAirspaceFilter(null);
    } else {
      setSelctedAirspaceFilter(option);
      handleAirspaceDateFilter(option, null);
    }
  };

  const handleRouteFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelectedRoute(null);

    if (option === null) {
      setSelctedRouteFilter(null);
    } else {
      setSelctedRouteFilter(option);
    }
  };

  const handleMETAFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelectedRoute(null);

    if (option === null) {
      setSelctedMETAFilter(null);
    } else {
      setSelctedMETAFilter(option);
    }
  };

  const handleDPRFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelctedDPRFilter(null);
    setSelctedDPRTypeFilter(null);

    if (option === null) {
      setSelctedDPRFilter(null);
    } else {
      setSelctedDPRFilter(option);
    }
  };

  const handleDPRTypeFilter = (option) => {
    handleAirspaceClear();
    setsearchQuery(null);
    setSelctedDPRTypeFilter(null);

    if (option === null) {
      setSelctedDPRTypeFilter(null);
    } else {
      setSelctedDPRTypeFilter(option);
    }
  };

  const handleAirspaceNameCheck = (data) => {
    updateSelectedValues(selectedAirspaceRows, data.name);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
    updateSelectedValues(selectedAirspaceRowsIds, String(data.id));
    setSelectedAirspaceRowsIds([...selectedAirspaceRowsIds]);
  };

  const handleAirspaceDesignatorCheck = (data) => {
    updateSelectedValues(selectedAirspaceRows, data.designator);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleAiespaceAerodromeCheck = (ra) => {
    updateSelectedValues(selectedAirspaceRows, ra.Aerodrome);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
  };

  const handleDPRMetaCheck = (dpr) => {
    const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
    updateSelectedValues(selectedAirspaceRows, dprName);
    setSelectedAirspaceRows([...selectedAirspaceRows]);
    updateSelectedValues(selectedAirspaceRowsIds, String(dpr.id));
    setSelectedAirspaceRowsIds([...selectedAirspaceRowsIds]);
  };

  const handleRoute = (route) => {
    setSelectedRoute(route);
    dispatch(getRouteData(route.id));
  };

  const handleAirspaceAllSelect = () => {
    if (selctedAirspaceFilter.value === airspaceFilterType.firuir) {
      const firMap = [];
      const firMapId = [];
      FIRData.forEach((fir) => {
        firMap.push(fir.name);
        firMapId.push(String(fir.id));
      });
      setSelectedAirspaceRows([...firMap]);
      setSelectedAirspaceRowsIds([...firMapId]);
    } else if (selctedAirspaceFilter.value === airspaceFilterType.tma) {
      const tmaMap = [];
      const tmaMapIds = [];
      TMAData.forEach((tma) => {
        tmaMap.push(tma.name);
        tmaMapIds.push(String(tma.id));
      });
      setSelectedAirspaceRows([...tmaMap]);
      setSelectedAirspaceRowsIds([...tmaMapIds]);
    } else if (selctedAirspaceFilter.value === airspaceFilterType.controlArea) {
      const ctaMap = [];
      const ctaMapIds = [];
      CTAData.forEach((cta) => {
        ctaMap.push(cta.name);
        ctaMapIds.push(String(cta.id));
      });
      setSelectedAirspaceRows([...ctaMap]);
      setSelectedAirspaceRowsIds([...ctaMapIds]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.otherRegulatedAirspace
    ) {
      const raMap = [];
      const raMapIds = [];
      regulatedAirspaceData.forEach((ra) => {
        raMap.push(ra.Aerodrome);
        raMapIds.push(String(ra.id));
      });
      setSelectedAirspaceRows([...raMap]);
      setSelectedAirspaceRowsIds([...raMapIds]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.radioNavigation
    ) {
      const rnMap = [];
      const rnMapIds = [];
      navaidOptions.forEach((rn) => {
        rnMap.push(rn.designator);
        rnMapIds.push(String(rn.id));
      });
      setSelectedAirspaceRows([...rnMap]);
      setSelectedAirspaceRowsIds([...rnMapIds]);
    } else if (
      selctedAirspaceFilter.value === airspaceFilterType.significantPoints
    ) {
      const spMap = [];
      const spMapIds = [];
      significantPointOptions.forEach((sp) => {
        spMap.push(sp.name);
        spMapIds.push(String(sp.id));
      });
      setSelectedAirspaceRows([...spMap]);
      setSelectedAirspaceRowsIds([...spMapIds]);
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.prohibitedRestrictedandDangerAreas
    ) {
      const dprMap = [];
      const dprMapIds = [];
      dprNameList.forEach((dpr) => {
        dprMap.push(`${dpr.Airspace_name} ${dpr.designator}`);
        dprMapIds.push(String(dpr.id));
      });
      setSelectedAirspaceRows([...dprMap]);
      setSelectedAirspaceRowsIds([...dprMapIds]);
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.militaryExecriseandTrainingAreas
    ) {
      const metaMap = [];
      const metaMapIds = [];
      METANameList.forEach((meta) => {
        metaMap.push(`${meta.Airspace_name} ${meta.designator}`);
        metaMapIds.push(String(meta.id));
      });
      setSelectedAirspaceRows([...metaMap]);
      setSelectedAirspaceRowsIds([...metaMapIds]);
    }
  };

  const handleAirspaceClear = () => {
    setSelectedAirspaceRows([]);
    setSelectedAirspaceRowsIds([]);
  };

  const selectedAdcategoryData = (catType, arpOption) => {
    dispatch(
      getAAIAirport(catType, arpOption.id, arpOption.version_id, (data) => {
        dispatch(
          setAAIMuiAirport({
            ...AAIMuiAirport,
            [`${catType}`]: data,
          })
        );
      })
    );
  };

  const handleAirodromeAD2SelectedCategories = (
    category,
    selectedArpOption = null
  ) => {
    if (isEmpty(category)) return;
    const arpOption = selectedArpOption ? selectedArpOption : selctedAirport;
    setSelectedAD2Category(category);
    switch (category.codeId) {
      case "AD2.2":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.geoadministrative))
          return null;
        selectedAdcategoryData("geoadministrative", arpOption);
        break;
      case "AD2.3":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.operationalhours))
          return null;
        selectedAdcategoryData("operationalhours", arpOption);
        break;
      case "AD2.4":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.handlingservice))
          return null;
        selectedAdcategoryData("handlingservice", arpOption);
        break;
      case "AD2.5":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.passengerfacilities))
          return null;
        selectedAdcategoryData("passengerfacilities", arpOption);
        break;
      case "AD2.6":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rescueservices))
          return null;
        selectedAdcategoryData("rescueservices", arpOption);
        break;
      case "AD2.7":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.seasonalservice))
          return null;
        selectedAdcategoryData("seasonalservice", arpOption);
        break;
      case "AD2.8apr":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.apron)) return null;
        selectedAdcategoryData("apron", arpOption);
        break;
      case "AD2.8txy":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.taxiway)) return null;
        selectedAdcategoryData("taxiway", arpOption);
        break;
      case "AD2.8chk":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.checkpoints)) return null;
        selectedAdcategoryData("checkpoints", arpOption);
        break;
      case "AD2.9":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.surfacmovements))
          return null;
        selectedAdcategoryData("surfacmovements", arpOption);
        break;
      case "AD2.10":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aixmobstacles))
          return null;
        selectedAdcategoryData("aixmobstacles", arpOption);
        break;
      case "AD2.11":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.meteorologicalinfo))
          return null;
        selectedAdcategoryData("meteorologicalinfo", arpOption);
        break;
      case "AD2.12rwydetail":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.runwayphysicals))
          return null;
        selectedAdcategoryData("runwayphysicals", arpOption);
        break;
      case "AD2.13":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.declareddistaces))
          return null;
        selectedAdcategoryData("declareddistaces", arpOption);
        break;
      case "AD2.14":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rwylights)) return null;
        selectedAdcategoryData("rwylights", arpOption);
        break;
      case "AD2.15":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.powerlights)) return null;
        selectedAdcategoryData("powerlights", arpOption);
        break;
      case "AD2.17":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airspaceservice))
          return null;
        selectedAdcategoryData("airspaceservice", arpOption);
        break;
      case "AD2.18":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airtrafficservices))
          return null;
        selectedAdcategoryData("airtrafficservices", arpOption);
        break;
      case "AD2.19":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.navaids)) return null;
        selectedAdcategoryData("navaids", arpOption);
        break;
      case "AD2.20":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aerodromeregulationinfo))
          return null;
        selectedAdcategoryData("aerodromeregulationinfo", arpOption);
        break;
      case "AD2.21":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight)) return null;
        selectedAdcategoryData("noiseflight", arpOption);
        break;
      case "AD2.22":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight)) return null;
        selectedAdcategoryData("noiseflight", arpOption);
        break;
      case "AD2.23":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aerodromeregulationinfo))
          return null;
        selectedAdcategoryData("aerodromeregulationinfo", arpOption);
        break;
      case "AD2.24":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.charts)) return null;
        selectedAdcategoryData("charts", arpOption);
        break;
      default:
        break;
    }
  };

  const handleOpenCloseExportModal = (isOpenClose) => {
    setOpenExportModal(isOpenClose);
  };

  const handleCancelExportModal = () => {
    handleOpenCloseExportModal(false);
  };

  const handleExportCallback = () => {
    handleOpenCloseExportModal(false);
  };

  const handleEnRouteExportData = (infoType) => {
    if (selctedAirspaceFilter.value === airspaceFilterType.routes) {
      if (infoType === "selectedData") {
        if (!selectedRoute) return toast.error("Select data first!");
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          if (currentUser?.user_access?.export || accessToken) {
            dispatch(
              exportRouteSheet(
                { Idlist: [String(selectedRoute.id)] },
                token,
                handleExportCallback
              )
            );
          }
        });
      } else {
        const ids = map(routesNameList, "id").map(String);
        ids &&
          ids.length > 0 &&
          getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
            if (currentUser?.user_access?.export || accessToken) {
              dispatch(
                exportRouteSheet({ Idlist: ids }, token, handleExportCallback)
              );
            }
          });
      }
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.prohibitedRestrictedandDangerAreas
    ) {
      let payload = {
        version: String(selectedVersion?.id),
        airport_icao: String(selctedDPRFilter.value),
        type: String(selctedDPRTypeFilter.value),
        Idlist: [""],
      };
      if (infoType === "selectedData") {
        if (selectedAirspaceRowsIds.length === 0) {
          return toast.error("Select data first!");
        }
        payload.Idlist = selectedAirspaceRowsIds;
      } else {
        payload.Idlist = ["ALL"];
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        if (currentUser?.user_access?.export || accessToken) {
          dispatch(exportDRPSheet(payload, token, handleExportCallback));
        }
      });
    } else if (
      selctedAirspaceFilter.value ===
      airspaceFilterType.militaryExecriseandTrainingAreas
    ) {
      let payload = {
        version: String(selectedVersion?.id),
        category: String(selctedMETAFilter.value),
        Idlist: [""],
      };
      if (infoType === "selectedData") {
        if (selectedAirspaceRowsIds.length === 0) {
          return toast.error("Select data first!");
        }
        payload.Idlist = selectedAirspaceRowsIds;
      } else {
        payload.Idlist = ["ALL"];
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        if (currentUser?.user_access?.export || accessToken) {
          dispatch(exportTRASheet(payload, token, handleExportCallback));
        }
      });
    } else {
      let payload = {
        version: String(selectedVersion?.id),
        category: String(airspaceFilterSortType[selctedAirspaceFilter.value]),
        Idlist: [""],
      };
      if (infoType === "selectedData") {
        if (selectedAirspaceRowsIds.length === 0) {
          return toast.error("Select data first!");
        }
        payload.Idlist = selectedAirspaceRowsIds;
      } else {
        payload.Idlist = ["ALL"];
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        if (currentUser?.user_access?.export || accessToken) {
          dispatch(exportAirspaceSheet(payload, token, handleExportCallback));
        }
      });
    }
  };

  const handleAerodrome = (option) => {
    if (selectedAerodrome?.value === option.value) return;
    setAD1Category(null);
    setAirport(null);
    if (option === null) {
      setAerodrome(null);
      dispatch(setAD1Aerodrome(null));
    } else {
      setAerodrome(option);
      if (option.value === "AD1") {
        dispatch(getAD1AerodromeData());
        dispatch(getAD1_3AerodromeData());
        dispatch(getAD1_5AerodromeData());
        setSelectedAD2Category(null);
        setAirport(null);
      }
    }
  };

  const handleAerodromeCategories = (option) => {
    if (option === null) {
      setAD1Category(null);
    } else {
      setAD1Category(option);
    }
  };

  const handleEnrouteCategory = (option) => {
    setSelctedAirspaceFilter(null);
    if (option === null) {
      setSelectedEnrouteCategory(null);
    } else {
      setSelectedEnrouteCategory(option);
    }
  };

  return (
    <div className="wrapper">
      <Header active="aixm" />
      <div className="main-content">
        <AixmSideBar
          isLoadingAirport={isLoadingAirport}
          isLoadingAirportDetail={isLoadingAirportDetail}
          isLoadingGetAixmDataVersion={isLoadingGetAixmDataVersion}
          isLoadingFIRData={isLoadingFIRData}
          isLoadingTMAData={isLoadingTMAData}
          isLoadingCTAData={isLoadingCTAData}
          isLoadingRegulatedAirspaceData={isLoadingRegulatedAirspaceData}
          isLoadingGetAllNavaid={isLoadingGetAllNavaid}
          isLoadingGetAllsignificantPoint={isLoadingGetAllsignificantPoint}
          isLoadingGetAllRoute={isLoadingGetAllRoute}
          isLoadingGetAllDPR={isLoadingGetAllDPR}
          isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
          isLoadingGetRouteData={isLoadingGetRouteData}
          allAirports={allAirports}
          categories={categories}
          selectedCategories={[]}
          selctedAirport={selctedAirport}
          isActiveAirport={!selctedAirport}
          selctedFeature={selctedFeature}
          selctedAirspaceFilter={selctedAirspaceFilter}
          firOptions={FIROptions}
          selectedAirspaceRows={selectedAirspaceRows}
          TMAOptions={TMAOptions}
          CTAOptions={CTAOptions}
          regulatedAirspaceOptions={regulatedAirspaceOptions}
          allRoutesOptions={allRoutesOptions}
          selctedRouteFilter={selctedRouteFilter}
          routesNameList={routesNameList}
          selectedRoute={selectedRoute}
          navaidOptions={navaidOptions}
          allDPROptions={allDPROptions}
          allTRA_TSA_ADIZOptions={allTRA_TSA_ADIZOptions}
          selctedDPRFilter={selctedDPRFilter}
          significantPointOptions={significantPointOptions}
          DPRTypeFilterOptions={DPRTypeFilterOptions}
          dprNameList={dprNameList}
          hasSectedAirspace={hasSectedAirspace}
          handleAirport={handleAirport}
          handleFeature={handleFeature}
          handleAirspaceFilter={handleAirspaceFilter}
          handleAirspaceSearch={handleAirspaceSearch}
          handleAirspaceAllSelect={handleAirspaceAllSelect}
          handleAirspaceNameCheck={handleAirspaceNameCheck}
          handleAirspaceDesignatorCheck={handleAirspaceDesignatorCheck}
          handleAiespaceAerodromeCheck={handleAiespaceAerodromeCheck}
          handleRouteFilter={handleRouteFilter}
          handleRoute={handleRoute}
          handleAirspaceClear={handleAirspaceClear}
          handleDPRFilter={handleDPRFilter}
          selctedDPRTypeFilter={selctedDPRTypeFilter}
          handleDPRTypeFilter={handleDPRTypeFilter}
          handleDPRMetaCheck={handleDPRMetaCheck}
          selctedMETAFilter={selctedMETAFilter}
          handleMETAFilter={handleMETAFilter}
          METANameList={METANameList}
          handleOpenCloseExportModal={handleOpenCloseExportModal}
          selectedAD2Category={selectedAD2Category}
          handleAirportFromCategory={handleAirodromeAD2SelectedCategories}
          generalOptions={generalOptions}
          isLoadingGeneral={isLoadingGeneral}
          selectedGeneral={selectedGeneral}
          selectedGeneralCategory={selectedGeneralCategory}
          generalCategoryOptions={generalCategoryOptions}
          handleGeneral={handleGeneral}
          handleGeneralCategory={handleGeneralCategory}
          handleAerodrome={handleAerodrome}
          selectedAerodrome={selectedAerodrome}
          handleAerodromeCategories={handleAerodromeCategories}
          selectedAD1Category={selectedAD1Category}
          selectedEnrouteCategory={selectedEnrouteCategory}
          handleEnrouteCategory={handleEnrouteCategory}
          EnrSubCategoryOptions={selectedEnrSubCategoryOptions}
          isLoadingAD1_5Aerodrome={isLoadingAD1_5Aerodrome}
          isLoadingAD1_3Aerodrome={isLoadingAD1_3Aerodrome}
        />
        <div className="main">
          {(selectedAD2Category ||
            hasSectedAirspace ||
            (!isEmpty(selectedGeneral) && !isEmpty(selectedGeneralCategory)) ||
            !isEmpty(selectedAD1Category) ||
            selctedAirspaceFilter?.isEnrApi) && (
            <Stack
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              sx={styles.filterDate}
            >
              <Box className="filter-date">
                <Typography variant="caption">Published Date</Typography>
                <Select
                  isClearable
                  isLoading={isLoadingGetAixmDataVersion}
                  isDisabled={isLoadingAirportDetail}
                  value={selectedVersion || null}
                  placeholder="Publication Date"
                  onChange={handleVersion}
                  options={aixmDataVersions}
                  getOptionLabel={(option) => option.published_date}
                  getOptionValue={(option) => option.published_date}
                  styles={commonStyles.selectDateScrollbar}
                />
              </Box>
              <Box className="filter-date">
                <Typography variant="caption">Effective Date</Typography>
                <Select
                  isClearable
                  isLoading={isLoadingGetAixmDataVersion}
                  isDisabled={isLoadingAirportDetail}
                  value={selectedVersion || null}
                  placeholder="Effective Date"
                  onChange={handleVersion}
                  options={aixmDataVersions}
                  getOptionLabel={(option) => option.effective_date}
                  getOptionValue={(option) => option.effective_date}
                  styles={commonStyles.selectDateScrollbar}
                />
              </Box>
              <Box>
                <PrimaryButton
                  label="Apply Filter"
                  disabled={isLoadingAirportDetail || !Boolean(selectedVersion)}
                  onClick={handleDateFilter}
                  sx={styles.filterBtn}
                />
              </Box>
            </Stack>
          )}
          <div className="content-area">
            {!selctedAirport &&
              selectedAirspaceRows.length === 0 &&
              !selectedRoute &&
              (isEmpty(selectedGeneral) || isEmpty(selectedGeneralCategory)) &&
              isEmpty(selectedAD1Category) &&
              !selctedAirspaceFilter?.isEnrApi && (
                <Box className="aixm-banner">
                  <img src={AIXMBanner} alt="aixm" style={styles.aixmBanner} />
                  <Box className="banner-text">
                    <Typography component="h1" className="aixm-title">
                      AIXM
                    </Typography>
                    <Typography className="aixm-desc">
                      AERONAUTICAL INFORMATION EXCHANGE MODEL
                    </Typography>
                    <Typography className="aixm-content">
                      The objective of the Aeronautical Information Exchange
                      Model (AIXM) is to enable the provision in digital format
                      of the aeronautical information that is in the scope of
                      Aeronautical Information Services (AIS).
                    </Typography>
                  </Box>
                </Box>
              )}
            {/* Aerodrome AD1 category data START */}
            {!isEmpty(selectedAD1Category) && (
              <AixmAD1
                selectedAerodrome={selectedAerodrome}
                selectedAD1Category={selectedAD1Category}
              />
            )}
            {/* Aerodrome AD1 category data END */}
            {/* General category data START */}
            {!isEmpty(selectedGeneral) && !isEmpty(selectedGeneralCategory) && (
              <AixmGeneral
                selectedGeneral={selectedGeneral}
                selectedGeneralCategory={selectedGeneralCategory}
                generalOptions={generalOptions}
                generalCategoryOptions={generalCategoryOptions}
              />
            )}
            {/* General category data END */}
            {selctedAirport && isEmpty(selectedAD2Category) && (
              <AixmMap
                airportLocation={airportLocation}
                zoomLevel={zoomLevel}
                selctedAirport={selctedAirport}
              />
            )}
            {/* Aerodrome AD2 category data START */}
            {selectedAD2Category && (
              <div
                className="aixm-category-table"
                style={{ borderTop: "1px solid #e0e0e0" }}
              >
                <Box sx={styles.airportTable}>
                  {isLoadingAAIAirport && <LinearProgress />}
                  {selectedAD2Category && !isLoadingAAIAirport && (
                    <AixmAirportDetailTables
                      activeCategory={selectedAD2Category}
                      selctedAirport={selctedAirport}
                    />
                  )}
                </Box>
              </div>
            )}
            {/* Aerodrome AD2 category data END */}
            {/* En-Route (Airspace) category data START */}
            {!isEmpty(selctedAirspaceFilter) &&
              selctedAirspaceFilter?.isEnrApi && (
                <EnrouteCategoryComponent
                  selectedEnrCategory={selectedEnrouteCategory}
                  selectedEnrSubCategory={selctedAirspaceFilter}
                  allEnrouteData={allEnroute}
                />
              )}
            {selctedFeature &&
              selctedFeature.value === "Airspace" &&
              selectedEnrouteCategory && (
                <AirspaceTables
                  selctedAirspaceFilter={selctedAirspaceFilter}
                  selectedAirspaceRows={selectedAirspaceRows}
                  selectedRoute={selectedRoute}
                  routeData={routeData}
                  allNavaidData={allNavaidData}
                  allSignificantPoints={allSignificantPoints}
                  DPRDataList={DPRDataList}
                  METADataList={METADataList}
                  FIRData={FIRData}
                  TMAData={TMAData}
                  CTAData={CTAData}
                  regulatedAirspaceData={regulatedAirspaceData}
                  isLoadingFIRData={isLoadingFIRData}
                  isLoadingTMAData={isLoadingTMAData}
                  isLoadingCTAData={isLoadingCTAData}
                  isLoadingRegulatedAirspaceData={
                    isLoadingRegulatedAirspaceData
                  }
                  isLoadingGetAllNavaid={isLoadingGetAllNavaid}
                  isLoadingGetAllsignificantPoint={
                    isLoadingGetAllsignificantPoint
                  }
                  isLoadingGetAllDPR={isLoadingGetAllDPR}
                  isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
                  selctedMETAFilter={selctedMETAFilter}
                  isLoadingGetRouteData={isLoadingGetRouteData}
                />
              )}
            {/* En-Route (Airspace) category data END */}
          </div>
        </div>
      </div>
      {selctedAirspaceFilter?.value === airspaceFilterType.routes && (
        <AixmExportdataModal
          title="Export CSV"
          description="Download any csv file from the below"
          filtertype={airspaceFilterType.routes}
          isOpen={isOpenExportModal}
          loading={isLoadingAirspaceExport}
          disabled={isLoadingAirspaceExport}
          handleCancel={handleCancelExportModal}
          handleExport={handleEnRouteExportData}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas && (
        <AixmExportdataModal
          isOpen={isOpenExportModal}
          filtertype={airspaceFilterType.prohibitedRestrictedandDangerAreas}
          loading={isLoadingAirspaceExport}
          disabled={isLoadingAirspaceExport}
          handleCancel={handleCancelExportModal}
          handleExport={handleEnRouteExportData}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas && (
        <AixmExportdataModal
          isOpen={isOpenExportModal}
          filtertype={airspaceFilterType.militaryExecriseandTrainingAreas}
          loading={isLoadingAirspaceExport}
          disabled={isLoadingAirspaceExport}
          handleCancel={handleCancelExportModal}
          handleExport={handleEnRouteExportData}
        />
      )}
      {(selctedAirspaceFilter?.value !== airspaceFilterType.routes ||
        selctedAirspaceFilter?.value !==
          airspaceFilterType.prohibitedRestrictedandDangerAreas ||
        selctedAirspaceFilter?.value !==
          airspaceFilterType.militaryExecriseandTrainingAreas) && (
        <AixmExportdataModal
          isOpen={isOpenExportModal}
          filtertype={selctedAirspaceFilter?.value}
          loading={isLoadingAirspaceExport}
          disabled={isLoadingAirspaceExport}
          handleCancel={handleCancelExportModal}
          handleExport={handleEnRouteExportData}
        />
      )}
    </div>
  );
}
