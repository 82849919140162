import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Geoadministrative from "@components/aixm-table-item/Geoadministrative";
import Passengerfacilities from "@components/aixm-table-item/Passengerfacilities";
import Rescueservices from "./Rescueservices";
import Seasonalservice from "./Seasonalservice";
import Surfacmovements from "./Surfacmovements";
import Operationalhours from "./Operationalhours";
import HandlingServices from "./HandlingServices";
import AerodromeObstacles from "./AerodromeObstacles";
import Apron from "./Apron";
import Taxiway from "./Taxiway";
import Checkpoints from "./Checkpoints";
import NoiseAbatementProcedures from "./NoiseAbatementProcedures";
import FlightProcedures from "./FlightProcedures";
import MeteorologicalInfo from "./MeteorologicalInfo";
import RWYPhysicalCharacteristics from "./RWYPhysicalCharacteristics";
import DeclaredDistaces from "./DeclaredDistaces";
import Approachrunwaylighting from "./Approachrunwaylighting";
import AirTrafficControlServiceItem from "./AirTrafficControlServiceItem";
import NavaidItem from "./NavaidItem";
import AirTrafficAirspaceService from "./AirTrafficAirspaceService";
import AerodromeRegulation from "./AerodromeRegulation";
import PowerLightsSupply from "./PowerLightsSupply";
import Charts from "./Charts";

export default function AixmAirportDetailTables({
  selctedAirport,
  activeCategory,
}) {
  const { AAIMuiAirport } = useSelector((state) => state.airport);
  if (isEmpty(selctedAirport)) return null;

  return (
    <Fragment>
      {activeCategory.codeId === "AD2.2" && (
        <Geoadministrative
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          geoadministrative={AAIMuiAirport.geoadministrative}
        />
      )}
      {activeCategory.codeId === "AD2.3" && (
        <Operationalhours
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          operationalhours={AAIMuiAirport.operationalhours}
        />
      )}
      {activeCategory.codeId === "AD2.4" && (
        <HandlingServices
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          handlingservice={AAIMuiAirport.handlingservice}
        />
      )}
      {activeCategory.codeId === "AD2.5" && (
        <Passengerfacilities
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          passengerfacilities={AAIMuiAirport.passengerfacilities}
        />
      )}
      {activeCategory.codeId === "AD2.6" && (
        <Rescueservices
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          rescueservices={AAIMuiAirport.rescueservices}
        />
      )}
      {activeCategory.codeId === "AD2.7" && (
        <Seasonalservice
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          seasonalservice={AAIMuiAirport.seasonalservice}
        />
      )}
      {activeCategory.codeId === "AD2.8apr" && (
        <Apron
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          apron={AAIMuiAirport.apron}
        />
      )}
      {activeCategory.codeId === "AD2.8txy" && (
        <Taxiway
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          taxiway={AAIMuiAirport.taxiway}
        />
      )}
      {activeCategory.codeId === "AD2.8chk" && (
        <Checkpoints
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          checkpoints={AAIMuiAirport.checkpoints}
        />
      )}
      {activeCategory.codeId === "AD2.9" && (
        <Surfacmovements
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          surfacmovements={AAIMuiAirport.surfacmovements}
        />
      )}
      {activeCategory.codeId === "AD2.10" && (
        <AerodromeObstacles
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          aixmobstacles={AAIMuiAirport.aixmobstacles}
        />
      )}
      {activeCategory.codeId === "AD2.11" && (
        <MeteorologicalInfo
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          meteorologicalinfo={AAIMuiAirport.meteorologicalinfo}
        />
      )}
      {activeCategory.codeId === "AD2.12rwydetail" && (
        <RWYPhysicalCharacteristics
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          runways={AAIMuiAirport.runwayphysicals}
        />
      )}
      {activeCategory.codeId === "AD2.13" && (
        <DeclaredDistaces
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          declareddistaces={AAIMuiAirport.declareddistaces}
        />
      )}
      {activeCategory.codeId === "AD2.14" && (
        <Approachrunwaylighting
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          rwylights={AAIMuiAirport.rwylights}
        />
      )}
      {activeCategory.codeId === "AD2.15" && (
        <PowerLightsSupply
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          powerlights={AAIMuiAirport.powerlights}
        />
      )}
      {activeCategory.codeId === "AD2.17" && (
        <AirTrafficAirspaceService
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          airspaceservice={AAIMuiAirport.airspaceservice}
        />
      )}
      {activeCategory.codeId === "AD2.18" && (
        <AirTrafficControlServiceItem
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          airtrafficservices={AAIMuiAirport.airtrafficservices}
        />
      )}
      {activeCategory.codeId === "AD2.19" && (
        <NavaidItem
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          navaids={AAIMuiAirport.navaids}
        />
      )}
      {activeCategory.codeId === "AD2.20" && (
        <AerodromeRegulation
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          aerodromeregulationinfo={
            AAIMuiAirport.aerodromeregulationinfo?.aerodrome_regulation
          }
        />
      )}
      {activeCategory.codeId === "AD2.23" && (
        <AerodromeRegulation
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          aerodromeregulationinfo={
            AAIMuiAirport.aerodromeregulationinfo?.additional_info
          }
        />
      )}
      {activeCategory.codeId === "AD2.21" && (
        <NoiseAbatementProcedures
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          noise={AAIMuiAirport.noiseflight}
        />
      )}
      {activeCategory.codeId === "AD2.22" && (
        <FlightProcedures
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          flight={AAIMuiAirport.noiseflight}
        />
      )}
      {activeCategory.codeId === "AD2.24" && (
        <Charts
          selctedAirport={selctedAirport}
          activeCategory={activeCategory}
          charts={AAIMuiAirport.charts}
        />
      )}
    </Fragment>
  );
}
