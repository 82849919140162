import React from "react";

export const ManageDataIcon = (props) => (
  <svg
    width={props.width || "80"}
    height={props.height || "80"}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3333 61.6693H18.6666M18.6666 61.6693H39.9999M18.6666 61.6693V27.0031C18.6666 23.9695 18.6666 22.4516 19.2479 21.2929C19.7592 20.2737 20.5745 19.4456 21.5781 18.9263C22.7189 18.3359 24.2135 18.3359 27.2005 18.3359H31.4671C34.4541 18.3359 35.9469 18.3359 37.0877 18.9263C38.0911 19.4456 38.9079 20.2737 39.4191 21.2929C39.9999 22.4504 39.9999 23.9665 39.9999 26.9942V35.2525M39.9999 61.6693H61.3332M39.9999 61.6693V35.2525M39.9999 35.2525L40.9636 34.373C42.9794 32.534 43.9874 31.6142 45.1268 31.2653C46.1306 30.958 47.2018 30.958 48.2055 31.2653C49.3452 31.6142 50.3538 32.5336 52.3698 34.373L58.5031 39.9693C59.5452 40.9202 60.0652 41.3963 60.4391 41.9667C60.7703 42.4721 61.0165 43.03 61.1653 43.6182C61.3333 44.2815 61.3332 44.9941 61.3332 46.4165V61.6693M61.3332 61.6693H66.6666"
      stroke="#42444A"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
