import { useSelector } from "react-redux";
import { Paper, Box } from "@mui/material";
import { theme } from "@styles/theme";

function AD220Form() {
  const { AAIAirport } = useSelector((state) => state.airport);

  return AAIAirport && AAIAirport?.aerodrome_regulation ? (
    <Paper className="add-airport-data-form">
      <Box
        dangerouslySetInnerHTML={{ __html: AAIAirport.aerodrome_regulation }}
        sx={{
          padding: "0px 15px 0px 34px",
          whiteSpace: "pre-line",
          p: { color: theme.palette.primary.main },
        }}
      />
    </Paper>
  ) : (
    <Box sx={{ textAlign: "center", pt: 2 }}>No data found.</Box>
  );
}

export default AD220Form;
