import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { find } from "lodash";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
  TextField,
} from "@mui/material";
import { LightButton, PrimaryButton } from "@components/button";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import { hoursOfOperation } from "@constants/ad2Data";
import { AD23initialValue } from "@constants/ad2DataForm.constant";
import { createAd23ValidationSchema } from "@constants/ad2Data";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad23OperationalhoursForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd23Form.setFieldValue(
      "air_traffic",
      AAIAirport?.air_traffic ? AAIAirport?.air_traffic : ""
    );
    createAd23Form.setFieldValue(
      "areodome_op",
      AAIAirport?.areodome_op ? AAIAirport?.areodome_op : ""
    );
    createAd23Form.setFieldValue("aro", AAIAirport?.aro ? AAIAirport?.aro : "");
    createAd23Form.setFieldValue("bof", AAIAirport?.bof ? AAIAirport?.bof : "");
    createAd23Form.setFieldValue(
      "custom",
      AAIAirport?.custom ? AAIAirport?.custom : ""
    );
    createAd23Form.setFieldValue(
      "fuel",
      AAIAirport?.fuel ? AAIAirport?.fuel : ""
    );
    createAd23Form.setFieldValue(
      "handling",
      AAIAirport?.handling ? AAIAirport?.handling : ""
    );
    createAd23Form.setFieldValue(
      "health",
      AAIAirport?.health ? AAIAirport?.health : ""
    );
    createAd23Form.setFieldValue("met", AAIAirport?.met ? AAIAirport?.met : "");
    createAd23Form.setFieldValue(
      "security",
      AAIAirport?.security ? AAIAirport?.security : ""
    );
    createAd23Form.setFieldValue(
      "remarks",
      AAIAirport?.remarks ? AAIAirport?.remarks : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "operationalhours",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd23Form.resetForm();
    createAd23Form.setSubmitting(false);
  };

  const createAd23Form = useFormik({
    enableReinitialize: true,
    initialValues: AD23initialValue,
    validationSchema: createAd23ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "operationalhours",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  const handleResetForm = () => {
    createAd23Form.resetForm();
    setTimeout(() => {
      createAd23Form.setValues({
        air_traffic: "",
        areodome_op: "",
        aro: "",
        bof: "",
        custom: null,
        fuel: "",
        handling: "",
        health: "",
        met: "",
        security: "",
        remarks: "",
      });
    });
    createAd23Form.setSubmitting(false);
  };

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Aerodrome Operator</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, mt: 2, width: "100%" }}
                error={
                  createAd23Form.touched?.areodome_op &&
                  Boolean(createAd23Form.errors?.areodome_op)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="areodome_op"
                  name="areodome_op"
                  value={createAd23Form.values.areodome_op}
                  onChange={createAd23Form.handleChange}
                  aria-describedby="aerodrome-areodome_op"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-areodome_op">
                  {createAd23Form.touched?.areodome_op &&
                    createAd23Form.errors?.areodome_op}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Custom and immigration</Box>
            <Box sx={styles.adInput}>
              <SelectInput
                name="custom"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.custom,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("custom", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Health and sanitation</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="health"
                name="health"
                value={createAd23Form.values.health}
                onChange={createAd23Form.handleChange}
                error={
                  createAd23Form.touched.health &&
                  Boolean(createAd23Form.errors.health)
                }
                helperText={
                  createAd23Form.touched.health && createAd23Form.errors.health
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mb: 2, mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>AIS briefing office</Box>
            <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
              <SelectInput
                name="bof"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.bof,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("bof", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>ATS reporting office (ARO)</Box>
            <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
              <SelectInput
                name="aro"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.aro,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("aro", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>MET Briefing office</Box>
            <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
              <SelectInput
                name="met"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.met,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("met", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Air Traffic Service</Box>
            <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
              <SelectInput
                name="air_traffic"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.air_traffic,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("air_traffic", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Fuelling</Box>
            <Box sx={styles.adInput}>
              <SelectInput
                name="fuel"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.fuel,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("fuel", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Handling</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="handling"
                name="handling"
                value={createAd23Form.values.handling}
                onChange={createAd23Form.handleChange}
                error={
                  createAd23Form.touched.handling &&
                  Boolean(createAd23Form.errors.handling)
                }
                helperText={
                  createAd23Form.touched.handling &&
                  createAd23Form.errors.handling
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mb: 2, mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Security</Box>
            <Box sx={styles.adInput}>
              <SelectInput
                name="security"
                value={find(hoursOfOperation, {
                  value: createAd23Form.values.security,
                })}
                onChange={(option) => {
                  createAd23Form.setFieldValue("security", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, mt: 2, width: "100%" }}
                error={
                  createAd23Form.touched?.remarks &&
                  Boolean(createAd23Form.errors?.remarks)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="remarks"
                  name="remarks"
                  value={createAd23Form.values.remarks}
                  onChange={createAd23Form.handleChange}
                  aria-describedby="aerodrome-remarks"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-remarks">
                  {createAd23Form.touched?.remarks &&
                    createAd23Form.errors?.remarks}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => handleResetForm()}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd23Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad23OperationalhoursForm;
