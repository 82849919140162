import {
  pickBy,
  isArray,
  remove,
  isEmpty,
  keys,
  includes,
  concat,
  sortBy,
} from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { aixmFeatureKeyMap, enrnewOptions } from "@constants/aixmData";
import { airspaceFilterOptions } from "@constants/airspaceData";
import { aixm } from "./slice";

export const {
  setAirportLoadingRequest,
  setExportSheetRequest,
  setAllAirport,
  setLoadingGetAixmDataVersion,
  setAixmDataVersions,
  setSelectedVersion,
  setGeneralLoadingRequest,
  setAllGeneral,
  setAD1Aerodrome,
  setLoadingAD1Aerodrome,
  setEnrouteLoadingRequest,
  setAllEnroute,
  setAD1_3Aerodrome,
  setAD1_5Aerodrome,
  setLoadingAD1_3Aerodrome,
  setLoadingAD1_5Aerodrome,
} = aixm.actions;

export default aixm.reducer;

export const selectAIXMCategoryOptions = (state) => {
  const airportDetails = state.aixm.airportDetails;

  if (airportDetails) {
    const validCategories = pickBy(airportDetails, (value) => {
      return value && isArray(value) && value.length > 0;
    });
    const categories = Object.keys(validCategories);
    remove(categories, (c) => {
      return [
        "Checkpoint_VOR",
        "Checkpoint_INS",
        "AirTrafficControlService_list",
        "AirportGroundService_list",
        "unit_list",
      ].includes(c);
    });
    categories.push("NavigationSystemCheckpoint_list");
    categories.push("Runway_List");
    categories.push("RD_List");
    categories.push("ObstacleArea_list");
    categories.push("Handling_services_list");
    categories.push("Passenger_facilities_list");
    categories.push("Rescue_list");
    categories.push("Seasonal_service_list");
    categories.push("Operator_list");
    categories.push("Surface_Movement_Guidance");

    return categories.map((category, i) => {
      return {
        id: i + 1,
        label: aixmFeatureKeyMap[category],
        value: category,
      };
    });
  }

  return [];
};

const selectGeneral = (state, general) => general;
const selectGeneralCategory = (a, b, genCategory) => genCategory;
const selectAllGeneralData = (state) => state.aixm.allGeneral;
const selectEnrouteCategory = (state, selectEnrcategory) => selectEnrcategory;
const selectAllEnrouteData = (state) => state.aixm.allEnroute;

export const selectedGeneralCategoryData = createSelector(
  [selectAllGeneralData, selectGeneral, selectGeneralCategory],
  (allGeneralData, selectGen, selectedGenCat) => {
    let dataObj;
    if (!isEmpty(selectGen) && !isEmpty(selectedGenCat)) {
      allGeneralData.map((data) => {
        if (includes(keys(data), selectGen.value)) {
          if (
            typeof data[selectGen.value][selectedGenCat.value] === "object" &&
            !Array.isArray(data[selectGen.value][selectedGenCat.value])
          ) {
            dataObj = Object.assign(
              {},
              data[selectGen.value][selectedGenCat.value]
            );
          } else if (
            Array.isArray(data[selectGen.value][selectedGenCat.value])
          ) {
            dataObj = data[selectGen.value][selectedGenCat.value];
          } else if (
            typeof data[selectGen.value][selectedGenCat.value] === "string" &&
            typeof data[selectGen.value][selectedGenCat.value] !== "object" &&
            !Array.isArray(data[selectGen.value][selectedGenCat.value])
          ) {
            dataObj = {
              [selectedGenCat.value]:
                data[selectGen.value][selectedGenCat.value],
            };
          }
        }
        return dataObj;
      });
    }
    return dataObj;
  }
);

export const enrSubCategoryOptions = createSelector(
  [selectAllEnrouteData, selectEnrouteCategory],
  (allGeneralData, selectedENRCat) => {
    if (isEmpty(allGeneralData) && isEmpty(selectedENRCat)) return [];
    const options = concat(airspaceFilterOptions, enrnewOptions).filter(
      (data) => data.enr === selectedENRCat?.value
    );

    return sortBy(options, ["id"], ["asc"]);
  }
);
