import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { AD26initialValue } from "@constants/ad2DataForm.constant";
import { createAd26ValidationSchema } from "@constants/ad2Data";
import { PrimaryButton, LightButton } from "@components/button";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function AD26RescueservicesForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd26Form.setFieldValue(
      "REMOVAL_AIRCRAFT",
      AAIAirport?.REMOVAL_AIRCRAFT ? AAIAirport?.REMOVAL_AIRCRAFT : ""
    );
    createAd26Form.setFieldValue(
      "RESCUE_EQPT_DESC",
      AAIAirport?.RESCUE_EQPT_DESC ? AAIAirport?.RESCUE_EQPT_DESC : ""
    );

    createAd26Form.setFieldValue(
      "REMARKS",
      AAIAirport?.REMARKS ? AAIAirport?.REMARKS : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "rescueservices",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd26Form.resetForm();
    createAd26Form.setSubmitting(false);
  };

  const createAd26Form = useFormik({
    enableReinitialize: true,
    initialValues: AD26initialValue,
    validationSchema: createAd26ValidationSchema,
    onSubmit: (values) => {
      const payload = {
        REMOVAL_AIRCRAFT: values.REMOVAL_AIRCRAFT,
        RESCUE_EQPT_DESC: values.RESCUE_EQPT_DESC,
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "rescueservices",
            payload,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Capability for removal of disabled aircraft
            </Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd26Form.touched?.REMOVAL_AIRCRAFT &&
                  Boolean(createAd26Form.errors?.REMOVAL_AIRCRAFT)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="REMOVAL_AIRCRAFT"
                  name="REMOVAL_AIRCRAFT"
                  value={createAd26Form.values.REMOVAL_AIRCRAFT}
                  onChange={createAd26Form.handleChange}
                  aria-describedby="aerodrome-REMOVAL_AIRCRAFT"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-REMOVAL_AIRCRAFT">
                  {createAd26Form.touched?.REMOVAL_AIRCRAFT &&
                    createAd26Form.errors?.REMOVAL_AIRCRAFT}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Rescue equipment</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd26Form.touched?.RESCUE_EQPT_DESC &&
                  Boolean(createAd26Form.errors?.RESCUE_EQPT_DESC)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="RESCUE_EQPT_DESC"
                  name="RESCUE_EQPT_DESC"
                  value={createAd26Form.values.RESCUE_EQPT_DESC}
                  onChange={createAd26Form.handleChange}
                  aria-describedby="aerodrome-RESCUE_EQPT_DESC"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-RESCUE_EQPT_DESC">
                  {createAd26Form.touched?.RESCUE_EQPT_DESC &&
                    createAd26Form.errors?.RESCUE_EQPT_DESC}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd26Form.touched?.REMARKS &&
                  Boolean(createAd26Form.errors?.REMARKS)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="REMARKS"
                  name="REMARKS"
                  value={createAd26Form.values.REMARKS}
                  onChange={createAd26Form.handleChange}
                  aria-describedby="aerodrome-REMARKS"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-REMARKS">
                  {createAd26Form.touched?.REMARKS &&
                    createAd26Form.errors?.REMARKS}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd26Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd26Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default AD26RescueservicesForm;
