import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import SatsureSkies from "@assets/svg/SatsureSkies";
import { requestNewPasswordValidationSchema } from "@constants/surveyorData";
import { LightButton, PrimaryButton } from "@components/button";
import { requestNewPassword } from "@store/userSession/actions";
import { styles } from "@styles/loginStyles";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRequestNewPassowrdLoading } = useSelector(
    (state) => state.userSession
  );
  const newPasswordRequestForm = useFormik({
    initialValues: {
      user_email: "",
    },
    validationSchema: requestNewPasswordValidationSchema,
    onSubmit: (values) => {
      dispatch(requestNewPassword(values, handleSuccessCallback));
    },
  });
  const handleSuccessCallback = () => {
    newPasswordRequestForm.resetForm();
    newPasswordRequestForm.setSubmitting(false);
    navigate(-1);
  };
  return (
    <div>
      <Box className="auth-form-box">
        <Box sx={{ position: "absolute", top: "96px", left: "90px" }}>
          <SatsureSkies />
        </Box>
        <Box className="forget-pass-form">
          <Box>
            <Typography sx={styles.reqPassLabel}>Request Password?</Typography>
            <Typography sx={{ fontSize: "14px", pb: 5 }}>
              Enter email address you use to log in
            </Typography>
          </Box>
          <Box sx={{pb:3}}>
            <InputLabel htmlFor="username" className="field-label">
              Email
            </InputLabel>
            <TextField
              sx={{ width: "100%", pb: 29 }}
              autoComplete="current-email"
              name="user_email"
              placeholder="Enter your mail address"
              variant="standard"
              value={newPasswordRequestForm.values.user_email}
              onChange={newPasswordRequestForm.handleChange}
              error={
                newPasswordRequestForm.touched.user_email &&
                Boolean(newPasswordRequestForm.errors.user_email)
              }
              helperText={
                newPasswordRequestForm.touched.user_email &&
                newPasswordRequestForm.errors.user_email
              }
            />
          </Box>
          <Box>
            <PrimaryButton
              label="Request Password"
              type="submit"
              className="login-btn"
              onClick={() => newPasswordRequestForm.handleSubmit()}
              isLoading={isRequestNewPassowrdLoading}
            />
            <Box sx={{ textAlign: "center" }}>
              <LightButton
                label="Back to Login"
                className="forget-pass-btn"
                sx={styles.backToLoginBtn}
                onClick={() => navigate(-1)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ForgetPassword;
