import React, { useRef } from "react";
import { CircleMarker, Popup, FeatureGroup } from "react-leaflet";
import Box from "@mui/material/Box";
import PopupBox from "@components/map/PopupBox";

const RenderObstacles = ({ data }) => {
  const shapeRef = useRef(null);

  return (
    <FeatureGroup ref={shapeRef}>
      {data.map((geometry, i) => {
        const coordinates = geometry.geometry_json.coordinates;

        return (
          <CircleMarker
            key={i}
            pane="tooltipPane"
            center={coordinates}
            pathOptions={{
              color: "green",
              fillOpacity: 0.5,
              weight: 1,
            }}
            radius={4}
          >
            <Popup>
              <PopupBox>
                <Box>UID:</Box>
                <Box>{geometry.uid}</Box>
                <Box>Object Type:</Box>
                <Box>{geometry.object}</Box>
                <Box>RWY Direction:</Box>
                <Box>{geometry.rwy_direction}</Box>
                <Box>Top Elevation:</Box>
                <Box>{geometry.top_elev}</Box>
                <Box>Penetration:</Box>
                <Box>{geometry.penetration}</Box>
              </PopupBox>
            </Popup>
          </CircleMarker>
        );
      })}
    </FeatureGroup>
  );
};

export default RenderObstacles;
