import React from "react";

export const ManageUserIcon = (props) => (
  <svg
    width={props.width || "80"}
    height={props.height || "80"}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.3333 63.3333C73.3333 65.1733 71.8433 66.6667 70 66.6667C68.1566 66.6667 66.6666 65.1733 66.6666 63.3333C66.6666 57.82 62.18 53.3333 56.6666 53.3333C54.39 53.3333 52.2266 54.1133 50.48 55.4967C52.2933 58.8167 53.3333 62.6233 53.3333 66.6667C53.3333 68.5067 51.8433 70 50 70C48.1566 70 46.6666 68.5067 46.6666 66.6667C46.6666 57.4767 39.19 50 30 50C20.81 50 13.3333 57.4767 13.3333 66.6667C13.3333 68.5067 11.8433 70 9.99996 70C8.15663 70 6.66663 68.5067 6.66663 66.6667C6.66663 53.8 17.1366 43.3333 30 43.3333C36.4233 43.3333 42.2433 45.9433 46.4666 50.1533C49.3633 47.9133 52.93 46.6667 56.6666 46.6667C65.8566 46.6667 73.3333 54.1433 73.3333 63.3333ZM56.6666 30C58.5066 30 60 31.4967 60 33.3333C60 35.17 58.5066 36.6667 56.6666 36.6667C54.8266 36.6667 53.3333 35.17 53.3333 33.3333C53.3333 31.4967 54.8266 30 56.6666 30ZM56.6666 43.3333C62.18 43.3333 66.6666 38.8467 66.6666 33.3333C66.6666 27.82 62.18 23.3333 56.6666 23.3333C51.1533 23.3333 46.6666 27.82 46.6666 33.3333C46.6666 38.8467 51.1533 43.3333 56.6666 43.3333ZM30 16.6667C33.6766 16.6667 36.6666 19.6567 36.6666 23.3333C36.6666 27.01 33.6766 30 30 30C26.3233 30 23.3333 27.01 23.3333 23.3333C23.3333 19.6567 26.3233 16.6667 30 16.6667ZM30 36.6667C37.3533 36.6667 43.3333 30.6867 43.3333 23.3333C43.3333 15.98 37.3533 10 30 10C22.6466 10 16.6666 15.98 16.6666 23.3333C16.6666 30.6867 22.6466 36.6667 30 36.6667Z"
      fill={props.color || "#6E3BD1"}
    />
  </svg>
);
