import { theme } from "./theme";

export const styles = {
  logintitle: {
    mb: 8,
    color: theme.palette.black.light,
    fontWeight: 600,
    fontSize: 24,
  },
  reqPassLabel: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    pb: 1,
  },
  forgetPassBtn: {
    mb: 5.75,
    mt: 1.5,
    p: 0,
    color: theme.palette.primary.main,
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
  },
  backToLoginBtn: {
    mb: 2,
    color: theme.palette.primary.main,
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "600",
  },
};
