export const styles = {
  checklistBtn: {
    p: 0,
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "12px",
  },
  shapBtn: {
    p: 0,
    mt: 0.5,
  },
  apnShapPolygonPop: {
    borderBottom: "0.5px solid #42444A",
    mb: 0.5,
    p: "0px 0px 12px 10px",
    fontSize: "14px",
    color: "#333333",
  },
  modifyShapeBtn: {
    p: 0,
    mt: 1,
    mb: 1,
    mr: 7,
  },
  sidebarFeaturelabel: {
    mb: 1,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
  },
};
