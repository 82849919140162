import Select from "react-select";

function SelectInput({ value, ...props }) {
  return (
    <Select
      isClearable={false}
      placeholder="Select..."
      components={{
        IndicatorSeparator: () => null,
      }}
      value={value || ""}
      {...props}
    />
  );
}

export default SelectInput;
