import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

export default function Passengerfacilities({
  selctedAirport,
  activeCategory,
  passengerfacilities,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {passengerfacilities ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Hotel(s) at or in the vicinity of aerodrome
                </StyledTableCell>
                <StyledTableCell>{passengerfacilities.HOTEL}</StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Restaurant(s) at or in the vicinity of aerodrome
                </StyledTableCell>
                <StyledTableCell>{passengerfacilities.HOTEL}</StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Transportation possibilities
                </StyledTableCell>
                <StyledTableCell>
                  {passengerfacilities.TRANSPORT
                    ? passengerfacilities.TRANSPORT
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Medical Facilities
                </StyledTableCell>
                <StyledTableCell>
                  {passengerfacilities.MEDIC ? passengerfacilities.MEDIC : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Bank and post office at or in the vicinity of aerodrome
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={{ fontSize: "14px" }}>
                    Banks:{" "}
                    {passengerfacilities.BANK ? passengerfacilities.BANK : "-"}
                  </Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Post office:{" "}
                    {passengerfacilities.POST ? passengerfacilities.POST : "-"}
                  </Typography>
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Tourist office
                </StyledTableCell>
                <StyledTableCell>
                  {passengerfacilities.INFO ? passengerfacilities.INFO : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>Remarks</StyledTableCell>
                <StyledTableCell>
                  {passengerfacilities?.REMARKS
                    ? passengerfacilities?.REMARKS
                    : "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}
