import { Box, Button, Modal, Typography } from "@mui/material";
import { styles } from "@styles/obstacleAnalysisStyles";

export default function ProceedObstacleAnalysisModal(props) {
  const handleOnCancel = () => {
    props.handleCancel();
  };

  const handleOnSuccess = () => {
    props.handleSuccess();
  };

  return (
    <Modal
      open={props.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ top: "35%", left: "35%" }}
    >
      <Box sx={styles.proceedModal}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4 }}>
          {props.description}
        </Typography>
        <Box variant="div" className="template-btns" sx={props.sx}>
          {props.isSuccess && (
            <Button
              sx={{ marginRight: 2.5, minWidth: "98px", height: "30px" }}
              variant={props.btnVariant || "contained"}
              onClick={() => handleOnSuccess()}
            >
              {props.successBtnText}
            </Button>
          )}
          <Button
            sx={{ minWidth: "98px", height: "30px" }}
            variant="outlined"
            onClick={handleOnCancel}
          >
            {props.cancelBtnText || "Cancel"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
