import React from "react";

export const RoundRightArrowIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.999 10.0054C14.999 10.0034 15 10.0024 15 10.0004C15 9.9674 14.984 9.9404 14.981 9.9084C14.973 9.8124 14.961 9.7164 14.924 9.6264C14.897 9.5584 14.851 9.5044 14.81 9.4444C14.779 9.4004 14.762 9.3494 14.724 9.3094L11.861 6.3094C11.479 5.9094 10.847 5.8954 10.447 6.2764C10.048 6.6574 10.033 7.2904 10.414 7.6904L11.664 9.0004H6C5.448 9.0004 5 9.4474 5 10.0004C5 10.5524 5.448 11.0004 6 11.0004H11.586L10.293 12.2934C9.902 12.6834 9.902 13.3164 10.293 13.7074C10.488 13.9024 10.744 14.0004 11 14.0004C11.256 14.0004 11.512 13.9024 11.707 13.7074L14.707 10.7074C14.798 10.6164 14.872 10.5064 14.922 10.3854C14.973 10.2644 14.998 10.1354 14.999 10.0054ZM10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18ZM10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
      fill={props.color}
    />
  </svg>
);
