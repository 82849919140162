import React, { Fragment, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { ENRshapePane } from "@constants/airspaceData";
import { areaHighLightColor } from "@constants/colors";
import { getENRAirspaceGeometryInfo } from "@store/airspace/actions";

export default function RenderPDRTraTsaMtrTmaOtherRegAdizAirspace({
  ENRgeometryData,
  colorTray,
  handleViewMore,
}) {
  const dispatch = useDispatch();
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleViewMore(info);
  };

  return (
    <Fragment>
      {ENRgeometryData.map((data, i) => {
        if (isEmpty(data.geometry)) return null;
        const typePane = data.catType;
        return (
          <GeoJSON
            key={data.id}
            pane={ENRshapePane[`${typePane}`]}
            data={data}
            pathOptions={{
              color:
                selectedOverE?.id === data.id
                  ? areaHighLightColor
                  : typePane === "ADIZ"
                  ? "#B16704"
                  : colorTray[i],
              weight: selectedOverE?.id === data.id ? 3 : 2,
              fillColor: typePane === "ADIZ" ? "#B16704" : colorTray[i],
              opacity: 0.8,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut(),
              click: () =>
                dispatch(
                  getENRAirspaceGeometryInfo(data.id, (data) => {
                    handlePolygonClick(data);
                  })
                ),
            }}
          />
        );
      })}
    </Fragment>
  );
}
