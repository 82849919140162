import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Box, Button, Input, Modal, Typography } from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { surveyCategory } from "@constants/surveyorData";
import { OutlinedButton } from "@components/button";
import { setVisualiseObstacles } from "@store/etod";
import { downloadCategoryTemplate } from "@store/survey/actions";
import { styles } from "@styles/obstacleAnalysisStyles";
import { convertDmsToDD } from "@utils/geocoords";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function VisualSurveyUploadModal(props) {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState(null);
  const isDownloadTemplate = useSelector(
    (state) => state.survey.isDownloadTemplate
  );

  const handleOnCancel = () => {
    setUploadedFile(null);
    props.handleCancel();
  };

  const handleOnUpload = () => {
    if (uploadedFile) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const data = e.target.result;
        const readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws);
        const formatedData = dataParse.map((d, i) => {
          return {
            ...d,
            id: i + 1,
            latitude: convertDmsToDD(d.Latitude),
            longitude: convertDmsToDD(d.Longitude),
          };
        });

        dispatch(setVisualiseObstacles(formatedData));
      };

      reader.readAsBinaryString(uploadedFile);
      props.handleUpload(uploadedFile);
      setUploadedFile(null);
    } else {
      toast.error("Upload the obstacle file for visualising");
    }
  };

  const handleDownloadTemplate = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        downloadCategoryTemplate(surveyCategory.objectsObstacles, token)
      );
    });
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setUploadedFile(event.target.files[0]);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ top: "35%", left: "35%" }}
    >
      <Box sx={styles.visualModalLabel}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Visualise Survey Data without review
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2.5 }}>
          Upload the xls file for visualising
        </Typography>
        <Box variant="div" sx={{ cursor: "pointer", position: "relative" }}>
          <Input
            sx={styles.visualModalInput}
            readOnly
            value={uploadedFile?.name || ""}
            placeholder="Select file"
          />
          <Button
            sx={styles.visualModalUploadBtn}
            variant="contained"
            onClick={handleOnUpload}
          >
            Upload
          </Button>
          <input
            style={styles.modalInputFile}
            accept="xlsx/*"
            type="file"
            onChange={handleFileChange}
          />
        </Box>
        <Box variant="div" sx={styles.downloadTempBtn}>
          <OutlinedButton
            label="Download Template"
            isLoading={isDownloadTemplate}
            disabled={isDownloadTemplate}
            onClick={handleDownloadTemplate}
            sx={{ marginRight: 2.5, minWidth: "98px", height: "30px" }}
          />
          <OutlinedButton
            label="Cancel"
            onClick={handleOnCancel}
            sx={{ minWidth: "98px", height: "30px" }}
          />
        </Box>
      </Box>
    </Modal>
  );
}
