import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { isEmpty } from "lodash";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Box, Divider, Typography } from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import {
  exportObstacleData,
  deleteEtodAirportLayer,
  exportAirportLayersData,
} from "@store/etod/actions";
import {
  setCreatelayerAirportData,
  setEtodLayers,
  setEtodDems,
  setAnalysisObstacles,
  setVisualiseObstacles,
} from "@store/etod";
import { commonStyles } from "@styles/commonStyles";
import { styles } from "@styles/etodStyles";
import { theme } from "@styles/theme";
import { getStorageValue, getAsyncStorageValue } from "@utils/localStorage";
import AirportFeature from "./AirportFeature";
import { PrimaryButtonV2 } from "./button";
import DropDownLabel from "./DropDownLabel";

export default function EtodSideBar({
  isLoadingEtodAirport,
  isLoadingExportObstacle,
  isLoadingEtodLayers,
  isLoadingEtodDems,
  allAirports = [],
  selctedAirport,
  selctedFeature,
  selectedAreas,
  isActiveAirport,
  areaOptions,
  olsOptions,
  selectedOls,
  selectedAirportFeature,
  handleFeature,
  handleAirport,
  handleAreaCheck,
  handleSelectAll,
  handleClearAll,
  handleParentOlsCheck,
  handleOlsCheck,
  isShowPenetrationObstacle,
  handlePenetrationObstacleToggle,
  handleParentAreaCheck,
  handle3DMap,
  is3DMap,
  etodDems,
  demOptions,
  selectedDems,
  handleParentDemCheck,
  handleDemCheck,
  handleSelectAllDems,
  handleSelectAllOls,
  handleSelectAllArea,
  handleClearAllArea,
  handleClearAlldem,
  handleClearAllOls,
  surafceTitle,
  isVisualUploadProceedObstacle,
  isShowVisualSurveyBtn,
  isShowProceedbtn,
  isShowObstacleAirportFeature,
  handleProceedVisualObstacle,
  handleOpenVisualSurveyModal,
  handleBackGenerateSurface,
  handleEmptySurfaceTitle,
  areaAnalysisObstacle,
  olsAnalysisObstacle,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const currentUser = useSelector((state) => state.userSession.user);
  const {
    isLoadingExportAreaOls,
    analysisObstacles,
    isLoadingAnalysisObstacles,
    isUplodeeTodDemFileRequest,
    createlayerAirportData,
    isLoadingExportAirportLayers,
  } = useSelector((state) => state.etod);
  const isAreaLoading = isLoadingEtodLayers;
  const isDisabled = isActiveAirport || isAreaLoading;
  const isEnabledFilter = Boolean(selctedFeature);

  let etodClassName = "etod-check-list";

  if (!isEmpty(createlayerAirportData) && isShowVisualSurveyBtn) {
    etodClassName = "generate-etod-check-list";
  }

  const handleChangeAirport = (option) => {
    handleAirport(option);
  };

  const handleExportObstacle = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (currentUser?.user_access?.export || accessToken) {
        dispatch(
          exportObstacleData(selctedFeature.value, selctedAirport, token)
        );
      }
    });
  };

  const handleBack = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (!createlayerAirportData) return;
      dispatch(deleteEtodAirportLayer(createlayerAirportData.id, token));
      handleEmptySurfaceTitle();
      dispatch(setCreatelayerAirportData(null));
      dispatch(setEtodLayers([]));
      dispatch(setEtodDems([]));
      dispatch(setAnalysisObstacles([]));
      dispatch(setVisualiseObstacles([]));
    });
    navigate(routeNames.Etod, { replace: true });
  };

  const handleOnGenerategeoJason = () => {
    if (createlayerAirportData.id) {
      getAsyncStorageValue(ACCESS_TOKEN).then( (token) => {
        dispatch(
          exportAirportLayersData(
            createlayerAirportData.id,
            selctedFeature.value,
            createlayerAirportData.airport_name,
            token
          )
        );
      });
    }
  };

  const handleVisualSurvey = () => {
    handleOpenVisualSurveyModal();
  };

  const handleProceedVisualSurveys = () => {
    handleProceedVisualObstacle();
  };

  const handleBackSurface = () => {
    handleBackGenerateSurface();
  };

  return (
    <div className="sidebar">
      {isEmpty(createlayerAirportData) ? (
        <Box sx={{ mb: 2 }}>
          <DropDownLabel label="Airport" isRequired />
          <Select
            isClearable
            isLoading={isLoadingEtodAirport}
            value={selctedAirport || null}
            placeholder="Select..."
            onChange={handleChangeAirport}
            options={allAirports}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            styles={commonStyles.selectScrollbar}
          />
        </Box>
      ) : (
        <>
          {isVisualUploadProceedObstacle ? (
            <div style={styles.etodSidebarBack} onClick={handleBackSurface}>
              <HiOutlineArrowNarrowLeft
                style={{ marginLeft: "10px", marginRight: "10px" }}
                color="#000"
              />
              back to selection
            </div>
          ) : (
            <div style={styles.etodSidebarBack} onClick={handleBack}>
              <HiOutlineArrowNarrowLeft
                style={{ marginLeft: "10px", marginRight: "10px" }}
                color="#000"
              />
              back to selection
            </div>
          )}
          <Divider sx={{ mt: 2, mb: 1 }} />
          {surafceTitle && (
            <div className="etod-airport-sidebar">
              <Typography component="p" className="surface-title">
                {surafceTitle}
              </Typography>
              <Typography component="p" className="select-title">
                {createlayerAirportData.airport_name}
              </Typography>
            </div>
          )}
        </>
      )}
      {isShowObstacleAirportFeature && (
        <AirportFeature
          moduleClassname={etodClassName}
          selectedAirport={selctedAirport}
          selctedFeature={selctedFeature}
          isActiveAirport={isActiveAirport}
          isAreaLoading={isAreaLoading}
          isLoadingEtodLayers={isLoadingEtodLayers}
          isEnabledFilter={isEnabledFilter}
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          isLoadingAnalysisObstacles={isLoadingAnalysisObstacles}
          isDisabled={isDisabled}
          isLoadingExportAreaOls={isLoadingExportAreaOls}
          isLoadingEtodDems={isLoadingEtodDems}
          isUplodeeTodDemFileRequest={isUplodeeTodDemFileRequest}
          selectedAirportFeature={selectedAirportFeature}
          analysisObstacles={analysisObstacles}
          areaOptions={areaOptions}
          selectedAreas={selectedAreas}
          demOptions={demOptions}
          etodDems={etodDems}
          selectedDems={selectedDems}
          olsOptions={olsOptions}
          selectedOls={selectedOls}
          areaOptionStyles={{
            maxHeight:
              createlayerAirportData?.isGenerateSurface ||
              isVisualUploadProceedObstacle
                ? `calc(100vh - 670px);`
                : `calc(100vh - 632px);`,
          }}
          olsOptionStyles={{
            maxHeight:
              createlayerAirportData?.isGenerateSurface ||
              isVisualUploadProceedObstacle
                ? `calc(100vh - 575px);`
                : `calc(100vh - 538px);`,
          }}
          handleFeature={handleFeature}
          handlePenetrationObstacleToggle={handlePenetrationObstacleToggle}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          handleSelectAllArea={handleSelectAllArea}
          handleClearAllArea={handleClearAllArea}
          handleParentAreaCheck={handleParentAreaCheck}
          handleAreaCheck={handleAreaCheck}
          handleSelectAllDems={handleSelectAllDems}
          handleClearAlldem={handleClearAlldem}
          handleParentDemCheck={handleParentDemCheck}
          handleDemCheck={handleDemCheck}
          handleSelectAllOls={handleSelectAllOls}
          handleClearAllOls={handleClearAllOls}
          handleParentOlsCheck={handleParentOlsCheck}
          handleOlsCheck={handleOlsCheck}
        />
      )}

      <div style={{ position: "absolute", bottom: "1.5rem", width: "inherit" }}>
        {!isEmpty(createlayerAirportData) && isShowVisualSurveyBtn && (
          <div className="etod-sidebar-footer" style={{ marginBottom: "8px" }}>
            <PrimaryButtonV2
              label="Visualise Surfaces"
              onClick={handleVisualSurvey}
            />
          </div>
        )}
        {!isEmpty(createlayerAirportData) && isShowProceedbtn && (
          <div className="etod-sidebar-footer">
            <Box>
              <Typography
                color={theme.palette.grey.main}
                sx={{ width: "160px", mb: 1 }}
              >
                Proceed to Obstacles Analysis
              </Typography>
              <PrimaryButtonV2
                label="Proceed"
                disabled={!isVisualUploadProceedObstacle}
                onClick={handleProceedVisualSurveys}
              />
            </Box>
          </div>
        )}
        {!isEmpty(createlayerAirportData) &&
          areaAnalysisObstacle.length > 0 &&
          olsAnalysisObstacle.length > 0 && (
            <div
              className="etod-sidebar-footer"
              style={{ marginBottom: "8px" }}
            >
              <PrimaryButtonV2
                label="Export Obstacles"
                disabled={
                  isEmpty(createlayerAirportData) ||
                  isEmpty(selctedFeature) ||
                  analysisObstacles?.length === 0 ||
                  !currentUser?.user_access?.export || !accessToken
                }
                onClick={() => handleExportObstacle()}
              />
            </div>
          )}
        {!isEmpty(createlayerAirportData) &&
          (!isVisualUploadProceedObstacle ||
            (areaAnalysisObstacle.length > 0 &&
              olsAnalysisObstacle.length > 0)) && (
            <div
              className="etod-sidebar-footer"
              style={{ marginBottom: "8px" }}
            >
              <PrimaryButtonV2
                label="Export GeoJSON"
                isLoading={isLoadingExportAirportLayers}
                disabled={
                  isEmpty(createlayerAirportData) ||
                  isEmpty(selctedFeature) ||
                  !currentUser?.user_access?.export || !accessToken
                }
                onClick={() => handleOnGenerategeoJason()}
              />
            </div>
          )}
        {isEmpty(createlayerAirportData) && !isVisualUploadProceedObstacle && (
          <div className="etod-sidebar-footer">
            <PrimaryButtonV2
              label={is3DMap ? "2D MAP" : "3D MAP"}
              disabled={!selctedAirport}
              onClick={() => handle3DMap()}
            />
          </div>
        )}
        {currentUser && accessToken && isEmpty(createlayerAirportData) && (
          <div className="etod-sidebar-footer" style={{ marginTop: 5 }}>
            <PrimaryButtonV2
              label="Export Obstacles"
              disabled={
                !selctedAirport ||
                analysisObstacles?.length === 0 ||
                !selctedFeature ||
                !currentUser?.user_access?.export || !accessToken
              }
              isLoading={isLoadingExportObstacle}
              onClick={() => handleExportObstacle()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
