import React, { Fragment } from "react";
import { orderBy } from "lodash";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function ObstacleAcceptTable({ data }) {
  return (
    <Fragment>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Accepted
        </Typography>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">UID</StyledTableCell>
            <StyledTableCell align="center">LATITUDE</StyledTableCell>
            <StyledTableCell align="center">LONGITUDE</StyledTableCell>
            <StyledTableCell align="center">Affected Area</StyledTableCell>
            <StyledTableCell align="center">Point Name</StyledTableCell>
            <StyledTableCell align="center">
              TOP ELEV. IN m. (EGM 08)
            </StyledTableCell>
            <StyledTableCell align="center">UOM Elevation</StyledTableCell>
            <StyledTableCell align="center">Vertical Datum</StyledTableCell>
            <StyledTableCell align="center">Horizontal Datum</StyledTableCell>
            <StyledTableCell align="center">Object</StyledTableCell>
            <StyledTableCell align="center">Marking lgt</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["uid1"], ["asc"]).map((row, i) => {
            return (
              <TableRow key={i}>
                <StyledTableCell align="center">{row["uid1"]}</StyledTableCell>
                <StyledTableCell align="center">
                  {row["latitude1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["longitude1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["affected_area1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["point_name1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["top_elev1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["uom_elevation1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["vertical_datum1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["horizontal_datum1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["object1"] || "-"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["marking_lgt1"]}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
}
