import React, { useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderArea2D({ etodArea2D, getAreaColors }) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const a2dcoordinates = etodArea2D[0].coordinates.coordinates || [];
  const areaColor = getAreaColors();
  const area2DMap = flatten(a2dcoordinates).map((coordinate) => {
    return coordinate.map((area) => {
      return [area[1], area[0]];
    });
  });

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Polygon
      positions={area2DMap}
      pathOptions={{
        fillColor: areaColor["Area2D"],
        color: selectedOverE?.areaA2D
          ? areaHighLightColor
          : areaColor["Area2D"],
        weight: selectedOverE?.areaA2D ? 3 : 2,
      }}
      eventHandlers={{
        mouseover: () => handlePolygonOver({ areaA2D: true }),
        mouseout: () => handlePolygonOut(),
      }}
    />
  );
}
