import React, { Fragment } from "react";
import { groupBy, isEmpty, keys } from "lodash";
import { Box, Typography } from "@mui/material";
import { enr34options } from "@constants/aixmData";
import { styles } from "@styles/airspaceStyles";

function EnrouteCategoryComponent({
  allEnrouteData,
  selectedEnrCategory,
  selectedEnrSubCategory,
}) {
  const selectedENRKeys = selectedEnrSubCategory?.value.split(",");

  return (
    <Box className="airspace-category-table">
      {!isEmpty(allEnrouteData) ? (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography sx={styles.airspaceCatTable}>
            En-Route{" "}
            {!isEmpty(selectedEnrCategory) &&
              `>> ${selectedEnrCategory?.label}`}{" "}
            {!isEmpty(selectedEnrSubCategory) &&
              `>> ${selectedEnrSubCategory?.label}`}
          </Typography>
          <Box className="selected-general-data">
            {!isEmpty(selectedEnrSubCategory) && (
              <Typography sx={styles.airspaceGenData}>
                {selectedEnrSubCategory.label}
              </Typography>
            )}
            {selectedENRKeys &&
              selectedENRKeys.length > 0 &&
              selectedENRKeys.map((enrkey, ind) => {
                return (
                  <Fragment key={ind}>
                    {enrkey !== "enr_3_4" &&
                      Array.isArray(allEnrouteData[enrkey]) && (
                        <Box sx={{ ml: 1.5, mr: 1.5 }}>
                          <table className="AmdtTable">
                            <thead>
                              <tr>
                                {!isEmpty(allEnrouteData[enrkey]) &&
                                  allEnrouteData[enrkey].length > 0 &&
                                  keys(allEnrouteData[enrkey][0]).map(
                                    (d, i) => {
                                      return (
                                        <th
                                          style={styles.airspaceENRkey}
                                          key={d + i + 1}
                                        >
                                          {d.replace("_", " ").toUpperCase()}
                                        </th>
                                      );
                                    }
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {!isEmpty(allEnrouteData[enrkey]) &&
                                allEnrouteData[enrkey].length > 0 &&
                                allEnrouteData[enrkey].map((d, i) => {
                                  return (
                                    <tr key={i}>
                                      {keys(d).map((inrd, j) => {
                                        return <td key={i + j}>{d[inrd]}</td>;
                                      })}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </Box>
                      )}
                    {enrkey === "enr_3_4" &&
                      Array.isArray(allEnrouteData[enrkey]) && (
                        <Box sx={{ ml: 1.5, mr: 1.5 }}>
                          <table className="enr-table">
                            <thead>
                              <tr>
                                {enr34options &&
                                  enr34options.map((data) => {
                                    return (
                                      <th style={styles.airspaceENRLabel}>
                                        {data.label}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {allEnrouteData[enrkey] &&
                                allEnrouteData[enrkey].length > 0 &&
                                keys(
                                  groupBy(allEnrouteData[enrkey], "name")
                                ).map((nameKey, i) => {
                                  const nameKeyData = groupBy(
                                    allEnrouteData[enrkey],
                                    "name"
                                  )[nameKey];

                                  return (
                                    nameKeyData &&
                                    nameKeyData.length > 0 &&
                                    nameKeyData.map((d, j) => {
                                      return (
                                        <tr key={i + j + d?.designator}>
                                          {j === 0 && (
                                            <td rowSpan={nameKeyData.length}>
                                              <Typography
                                                sx={styles.airspaceENRLabel}
                                              >
                                                {d?.name} ({d?.designator})
                                              </Typography>
                                              <Typography
                                                sx={{ fontSize: "12px" }}
                                              >
                                                {d?.coordinates_dms}
                                              </Typography>
                                            </td>
                                          )}
                                          <td style={{ fontSize: "12px" }}>
                                            {d?.inbound_course}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {d?.turn_direction}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {d?.speed_limit}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {d?.lower_limit} / {d?.upper_limit}
                                          </td>
                                          <td style={{ fontSize: "12px" }}>
                                            {d?.holding_duration}
                                          </td>
                                          <td>
                                            <Typography>
                                              {d?.controlling_unit_name}
                                            </Typography>
                                            <Typography>
                                              {d?.controlling_unit_frequency}
                                            </Typography>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  );
                                })}
                            </tbody>
                          </table>
                        </Box>
                      )}
                    {typeof allEnrouteData[enrkey] === "string" && (
                      <Box
                        sx={styles.allENRData}
                        dangerouslySetInnerHTML={{
                          __html: allEnrouteData[enrkey],
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            {selectedENRKeys &&
              selectedENRKeys.length === 1 &&
              isEmpty(allEnrouteData[selectedEnrSubCategory?.value]) && (
                <Typography sx={{ m: 1, textAlign: "center" }}>
                  No data found!
                </Typography>
              )}
          </Box>
        </Box>
      ) : (
        <Typography sx={{ m: 1, textAlign: "center" }}>
          No data found!
        </Typography>
      )}
    </Box>
  );
}

export default EnrouteCategoryComponent;
