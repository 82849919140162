import React, { Fragment, useMemo, useState } from "react";
import { split } from "lodash";
import { Viewer } from "resium";
import { Box } from "@mui/system";
import Control3DMapCenter from "@components/map/Control3DMapCenter";
import ObstacleLegends from "@components/map/ObstacleLegends";
import DemLegends from "@components/map/DemLegends";
import AirportLegend from "@components/map/AirportLegend";
import Render3DArea1 from "./Render3DArea1";
import Render3DArea from "./Render3DArea";
import Render3DArea2D from "./Render3DArea2D";
import Render3DOLSApproach from "./Render3DOLSApproach";
import Render3DOLSTakeOff from "./Render3DOLSTakeOff";
import Render3DOLSRwyStrip from "./Render3DOLSRwyStrip";
import Render3DOLSTransitionalSurface from "./Render3DOLSTransitionalSurface";
import Render3DOLSOTransitionalSurface from "./Render3DOLSOTransitionalSurface";
import Render3DOLSHorizontalSurface from "./Render3DOLSHorizontalSurface";
import Render3DOLSObstacleFreeZone from "./Render3DOLSObstacleFreeZone";
import Render3dOLSConicalSurface from "./Render3dOLSConicalSurface";
import Render3DDems from "./Render3DDems";
import Render3DVisualiseObstacles from "./Render3DVisualiseObstacles";
import Render3DAnalysisAreaObstacles from "./Render3DAnalysisAreaObstacles";
import Render3DAnalysisOlsObstacles from "./Render3DAnalysisOlsObstacles";
import { styles } from "@styles/etodStyles";

export default function ResiumMapContainer({
  isEtod,
  airportLocation,
  zoomLevel,
  selectedAreas,
  etodAreaList,
  etodArea2D,
  selectedOls,
  OLSApproachList,
  OLSHorizontalSurfaceList,
  OLSTransitionalSurfaceList,
  OLSOuterTransitionalSurfaceList,
  OLSObstacleFreeZoneList,
  OLSRwyStripList,
  OLSTakeOffList,
  OLSConicalSurfaceList,
  area1Data,
  isShowPenetrationObstacle,
  etodDems,
  selectedDems,
  selectedAirportDetail,
  selctedAirport,
  visualiseObstacles,
  areaAnalysisObstacle,
  olsAnalysisObstacle,
}) {
  const [isAbovePermissibleClick, setAbovePermissibleClick] = useState(true);
  const [isBelowPermissibleClick, setBelowPermissibleClick] = useState(true);

  const getAreaColors = () => {
    const areaColor = {};

    selectedAreas.forEach((a) => {
      const splitArray = split(a, "__");
      areaColor[splitArray[0]] = splitArray[1];
    });

    return areaColor;
  };

  const getOLSColors = () => {
    const olsColor = {};

    selectedOls.forEach((a) => {
      const splitArray = split(a, "__");
      olsColor[splitArray[0]] = splitArray[1];
    });

    return olsColor;
  };

  const handleAbovePermissible = (event) => {
    setAbovePermissibleClick(event.target.checked);
  };

  const handleBelowPermissible = (event) => {
    setBelowPermissibleClick(event.target.checked);
  };

  const etod3dMap = useMemo(() => {
    const selectedAreasT = selectedAreas.map((a) => a.split("__")[0]);
    const isSelectedArea1 = Boolean(selectedAreasT.includes("Area1"));
    const isSelectedArea2D = Boolean(selectedAreasT.includes("Area2D"));

    return (
      <Viewer
        full
        geocoder={false}
        homeButton={false}
        timeline={false}
        animation={false}
      >
        {Boolean(isSelectedArea1) && (
          <Render3DArea1 area1Data={area1Data} getAreaColors={getAreaColors} />
        )}
        <Render3DArea
          etodAreaList={etodAreaList}
          selectedAreas={selectedAreas}
          getAreaColors={getAreaColors}
        />
        {isSelectedArea2D && (
          <Render3DArea2D
            etodArea2D={etodArea2D}
            getAreaColors={getAreaColors}
          />
        )}
        <Render3DOLSTakeOff
          OLSTakeOffList={OLSTakeOffList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSRwyStrip
          OLSRwyStripList={OLSRwyStripList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSTransitionalSurface
          OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSOTransitionalSurface
          OLSOuterTransitionalSurfaceList={OLSOuterTransitionalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSHorizontalSurface
          OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3dOLSConicalSurface
          OLSConicalSurfaceList={OLSConicalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSApproach
          OLSApproachList={OLSApproachList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <Render3DOLSObstacleFreeZone
          OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        {etodDems && etodDems.length > 0 && (
          <Render3DDems etodDems={etodDems} selectedDems={selectedDems} />
        )}
        {visualiseObstacles && visualiseObstacles.length > 0 && (
          <Render3DVisualiseObstacles obstacles={visualiseObstacles} />
        )}
        {selctedAirport &&
          areaAnalysisObstacle &&
          areaAnalysisObstacle.length > 0 &&
          selectedAreas?.length > 0 && (
            <Render3DAnalysisAreaObstacles
              selctedAirport={selctedAirport}
              obstacles={areaAnalysisObstacle}
              selectedAreas={selectedAreas}
              isShowPenetrationObstacle={isShowPenetrationObstacle}
              isAbovePermissibleClick={isAbovePermissibleClick}
              isBelowPermissibleClick={isBelowPermissibleClick}
            />
          )}
        {selctedAirport &&
          olsAnalysisObstacle &&
          olsAnalysisObstacle.length > 0 &&
          selectedOls?.length > 0 && (
            <Render3DAnalysisOlsObstacles
              selctedAirport={selctedAirport}
              obstacles={olsAnalysisObstacle}
              selectedOls={selectedOls}
              isShowPenetrationObstacle={isShowPenetrationObstacle}
              isAbovePermissibleClick={isAbovePermissibleClick}
              isBelowPermissibleClick={isBelowPermissibleClick}
            />
          )}
        <Control3DMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
      </Viewer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selectedOls,
    selectedAreas,
    selectedDems,
    etodDems,
    visualiseObstacles,
    areaAnalysisObstacle,
    olsAnalysisObstacle,
    isShowPenetrationObstacle,
    isAbovePermissibleClick,
    isBelowPermissibleClick,
    isEtod,
  ]);

  return (
    <Fragment>
      {etod3dMap}
      <Box sx={styles.etod3DMap}>
        {!isEtod && selectedAirportDetail && (
          <AirportLegend selectedAirportDetail={selectedAirportDetail} />
        )}
        <ObstacleLegends
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          handleAbovePermissible={handleAbovePermissible}
          handleBelowPermissible={handleBelowPermissible}
          isAbovePermissibleClick={isAbovePermissibleClick}
          isBelowPermissibleClick={isBelowPermissibleClick}
        />
        {etodDems && etodDems.length > 0 && selectedDems.length > 0 && (
          <DemLegends etodDems={etodDems} />
        )}
      </Box>
    </Fragment>
  );
}
