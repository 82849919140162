import L from "leaflet";
import CompulsoryImg from "@assets/images/compulsory.png";
import OnRequestImg from "@assets/images/onrequest.png";
import FlyByCompulsoryImg from "@assets/images/fly-by-compulsory.png";
import FlyByOnrequestImg from "@assets/images/fly-by-onrequest.png";
import ReportingPointImg from "@assets/images/reporting-point.png";
import DMEImg from "@assets/images/DME.png";
import NDBImg from "@assets/images/NDB.png";
import VORImg from "@assets/images/VOR.png";
import VOR_DMEImg from "@assets/images/VOR_DME.png";
import LocationImg from "@assets/images/location-icon.png";
import RoutePlanImg from "@assets/svg/RoutePlanPoint.svg";
import CivilAerodomeImg from "@assets/svg/civilaerodomeicon.svg";
import LightTriangleImg from "@assets/svg/LightTriangleImg.svg";

export const CompulsoryIcon = L.icon({
  iconUrl: CompulsoryImg,
  iconSize: [18, 18],
});

export const OnRequestIcon = L.icon({
  iconUrl: OnRequestImg,
  iconSize: [18, 18],
});

export const FlyByCompulsoryIcon = L.icon({
  iconUrl: FlyByCompulsoryImg,
  iconSize: [20, 20],
});

export const FlyByOnrequestIcon = L.icon({
  iconUrl: FlyByOnrequestImg,
  iconSize: [20, 20],
});

export const ReportingPointImgIcon = L.icon({
  iconUrl: ReportingPointImg,
  iconSize: [20, 20],
});

export const DMEImgIcon = L.icon({
  iconUrl: DMEImg,
  iconSize: [27, 20],
});

export const NDBImgIcon = L.icon({
  iconUrl: NDBImg,
  iconSize: [40, 40],
});

export const VORImgIcon = L.icon({
  iconUrl: VORImg,
  iconSize: [25, 25],
});

export const VOR_DMEImgIcon = L.icon({
  iconUrl: VOR_DMEImg,
  iconSize: [27, 20],
});

export const LocationImgIcon = L.icon({
  iconUrl: LocationImg,
  iconSize: [80, 80],
});

export const EmptyImgIcon = L.icon({
  iconUrl: LocationImg,
  iconSize: [0, 0],
});

export const RoutePlanPoint = L.icon({
  iconUrl: RoutePlanImg,
  iconSize: [28, 28],
});

export const CivilAerodomeIcon = L.icon({
  iconUrl: CivilAerodomeImg,
  iconSize: [14, 14],
});

export const LightTriangleIcon = L.icon({
  iconUrl: LightTriangleImg,
  iconSize: [24, 30],
});
