import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { includes } from "lodash";
import { Checkbox, Divider, Typography } from "@mui/material";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { ACCESS_TOKEN } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import { getAsyncStorageValue } from "@utils/localStorage";
import { generateEtodAirportLayer } from "@store/etod/actions";
import { styles } from "@styles/etodStyles";
import { theme } from "@styles/theme";
import {  PrimaryButtonV2 } from "./button";

export default function EtodAirportDetailSideBar({ airportId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoadingGenerateEtodAirport } = useSelector((state) => state.etod);
  const [type, setType] = useState([]);

  const handleChange = (event) => {
    if (includes(type, event.target.name)) {
      type.splice(type.indexOf(event.target.name), 1);
      setType([...type]);
    } else {
      type.push(event.target.name);
      setType([...type]);
    }
  };

  const handleCallback = (status) => {
    if (status) {
      navigate(routeNames.Etod);
    }
  };

  const handleOnGenerate = () => {
    if (!airportId || type.length === 0) return;
    let selectedType = type[0];

    if (type.length > 1) {
      selectedType = "AREA_OLS";
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        generateEtodAirportLayer(airportId, selectedType, token, handleCallback)
      );
    });
  };

  return (
    <div className="sidebar">
      <div style={styles.etodSidebar} onClick={() => navigate(routeNames.Etod)}>
        <HiOutlineArrowNarrowLeft
          style={{ marginLeft: "10px", marginRight: "10px" }}
          color={theme.palette.black.main}
        />
        back to selection
      </div>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <div className="etod-airport-sidebar">
        <Typography component="p" className="surface-title">
          Generate Surfaces
        </Typography>
        <Typography component="p" className="select-title">
          Select features to add
        </Typography>
        <Typography component="p" className="area-title">
          <Checkbox label="Area" name="AREA" onChange={handleChange} />
          <Typography component="span">Area</Typography>
        </Typography>
        <Typography component="p" className="ols-title">
          <Checkbox label="OLS" name="OLS" onChange={handleChange} />
          <Typography component="span">OLS</Typography>
        </Typography>
      </div>
      <div style={{ position: "absolute", bottom: "1.5rem", width: "inherit" }}>
        <div className="etod-sidebar-footer">
          <PrimaryButtonV2
            label="Generate"
            isLoading={isLoadingGenerateEtodAirport}
            disabled={type.length === 0}
            onClick={handleOnGenerate}
          />
        </div>
      </div>
    </div>
  );
}
