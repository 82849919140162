import { theme } from "./theme";

export const styles = {
  popBox: {
    bgcolor: theme.palette.white.main,
    borderRadius: 1,
    textAlign: "center",
    typography: "subtitle2",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  popItem: {
    borderColor: "black",
    borderBottom: "1px solid",
    paddingTop: 5,
    paddingBottom: 5,
  },
  obstaclePopBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 1,
    paddingBottom: 1,
  },
  etodGetObstacleSymbol: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 1,
    paddingBottom: 1,
  },
};
