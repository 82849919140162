import { toast } from "react-toastify";
import {
  RSA_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REQUEST_NEW_PASSWORD_URL,
  CRC32Q_GENERATE,
} from "@constants/urls";
import { roles } from "@constants/constant";
import { ACCESS_TOKEN, REFRESH_TOKEN, AES_KEY } from "@constants/constant";
import {
  getSurveyorAirport,
} from "@store/survey/actions";
import {
  setStorageValue,
  getStorageValue,
  removeAllCookies,
} from "@utils/localStorage";
import { decryptRsa } from "@utils/decodeEncodeData";
import { apiCall, fileUploadApicall } from "@utils/connect";
import {
  setRsaRequest,
  setLoginRequest,
  setLogoutRequest,
  setRSA,
  setUser,
  setRequestNewPassowrdLoading,
  setCRC32QGenerateRequest,
} from ".";

export const getRSA = () => (dispatch) => {
  try {
    dispatch(setRsaRequest(true));

    const onSuccess = (response) => {
      dispatch(setRSA(response.data.rsa));
      dispatch(setRsaRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRsaRequest(false));
    };

    apiCall("GET", RSA_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setRsaRequest(false));
    toast.error(error.message);
  }
};

export const userLogin = (payload, callback) => (dispatch) => {
  try {
    dispatch(setLoginRequest(true));

    const onSuccess = (response) => {
      decryptRsa(response.data.accessToken).then((aesKey) => {
        dispatch(setUser(response.data));
        setStorageValue(AES_KEY, aesKey);
        setStorageValue(ACCESS_TOKEN, response.data.accessToken);
        setStorageValue(REFRESH_TOKEN, response.data.refreshToken);
        if (response.data.role === roles.surveyor) {
          dispatch(getSurveyorAirport(response.data.accessToken));
        }
        dispatch(setLoginRequest(false));
        callback();
        toast.success("User logged in successfully");
      });
    };
    const onFailure = (error) => {
      dispatch(setLoginRequest(false));
      toast.error(error.message);
    };

    apiCall(
      "POST",
      LOGIN_URL,
      { ...payload, encryptionDetails: "PKCS1_OAEP" },
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoginRequest(false));
    toast.error(error.message);
  }
};

const clearTokens = (callback) => (dispatch) => {
  removeAllCookies().then(() => {
    callback();
  });
  dispatch(setRSA(null));
  dispatch(setUser(null));
};

export const userLogout = (callback) => (dispatch) => {
  try {
    dispatch(setLogoutRequest(true));

    const onSuccess = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };
    const onFailure = () => {
      dispatch(clearTokens(callback));
      dispatch(setLogoutRequest(false));
    };

    const refreshToken = getStorageValue(REFRESH_TOKEN);
    if (refreshToken !== undefined && refreshToken !== null) {
      apiCall("DELETE", LOGOUT_URL, "", onSuccess, onFailure, refreshToken);
    }
  } catch (error) {
    dispatch(clearTokens(callback));
    dispatch(setLogoutRequest(false));
  }
};

export const requestNewPassword = (payload, callback) => (dispatch) => {
  try {
    dispatch(setRequestNewPassowrdLoading(true));

    const onSuccess = () => {
      toast.success("Request sent Successfully");
      dispatch(setRequestNewPassowrdLoading(false));
      callback();
    };
    const onFailure = (error) => {
      toast.success("Request sent Failed");
      dispatch(setRequestNewPassowrdLoading(false));
    };

    apiCall("POST", REQUEST_NEW_PASSWORD_URL, payload, onSuccess, onFailure);
  } catch (error) {
    dispatch(setRequestNewPassowrdLoading(false));
    toast.success("Request sent Failed");
  }
};

export const generateCRC32Q = (file, successCallback) => (dispatch) => {
  try {
    dispatch(setCRC32QGenerateRequest(true));

    const onSuccess = (response) => {
      successCallback(response.message);
      dispatch(setCRC32QGenerateRequest(false));
    };
    const onFailure = () => {
      toast.error("CRC32Q code not generated");
      dispatch(setCRC32QGenerateRequest(false));
    };

    fileUploadApicall(CRC32Q_GENERATE, file, onSuccess, onFailure, null);
  } catch (error) {
    dispatch(setCRC32QGenerateRequest(false));
    toast.error("CRC32Q code not generated");
  }
};
