import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import parseQueryParameters from "parse-url-query-params";
import { IconButton, LinearProgress } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { EditIcon } from "@assets/svg/edit";
import { ACCESS_TOKEN } from "@constants/constant";
import { ad2Category } from "@constants/ad2Data";
import Ad22From from "@components/aerodome-forms/Ad22Form";
import Ad23OperationalhoursForm from "@components/aerodome-forms/Ad23OperationalhoursForm";
import AD24HandlingServiceForm from "@components/aerodome-forms/AD24HandlingServiceForm";
import Ad25PassengerFacilities from "@components/aerodome-forms/Ad25PassengerFacilities";
import AD26RescueservicesForm from "@components/aerodome-forms/AD26RescueservicesForm";
import Ad27SeasonalserviceForm from "@components/aerodome-forms/Ad27SeasonalserviceForm";
import Ad28ApronForm from "@components/aerodome-forms/Ad28ApronForm";
import Ad28TaxiwayForm from "@components/aerodome-forms/Ad28TaxiwayForm";
import AD28CheckpointsForm from "@components/aerodome-forms/AD28CheckpointsForm";
import AD29SurfacmovementsForm from "@components/aerodome-forms/AD29SurfacmovementsForm";
import Ad210From from "@components/aerodome-forms/Ad210Form";
import Ad211MeteorologicalForm from "@components/aerodome-forms/Ad211MeteorologicalForm";
import Ad212RunWayDetailFrom from "@components/aerodome-forms/Ad212RunWayDetailForm";
import Ad213Form from "@components/aerodome-forms/Ad213Form";
import Ad214Form from "@components/aerodome-forms/Ad214Form";
import Ad215Form from "@components/aerodome-forms/Ad215Form";
import Ad217Form from "@components/aerodome-forms/Ad217Form";
import Ad218Form from "@components/aerodome-forms/Ad218Form";
import AD220Form from "@components/aerodome-forms/AD220Form";
import Ad219NavidForm from "@components/aerodome-forms/Ad219NavidForm";
import Ad221Form from "@components/aerodome-forms/Ad221Form";
import Ad222Form from "@components/aerodome-forms/Ad222Form";
import AD223Form from "@components/aerodome-forms/AD223Form";
import Ad224ChartsForm from "@components/aerodome-forms/Ad224ChartsForm";
import { setSelctedAirportFormCategories } from "@store/airport";
import { getAllNewAirports, getAdRunwayInfo } from "@store/airport/actions";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function AddAirportData() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    selctedAirportFormCategories,
    isLoadingAAIAirport,
    adRunwayInfo,
    allAirportsCharts,
  } = useSelector((state) => state.airport);
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const [isReadOnlyFormField, setReadOnlyFormField] = useState(true);

  const prsedUrl = parseQueryParameters(
    decodeURIComponent(`${location.pathname}${location.search}`)
  );
  const selectedAirport = allNewAirports.find(
    (airport) => airport.id === Number(prsedUrl.airportId)
  );

  useEffect(() => {
    dispatch(setSelctedAirportFormCategories(null));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (allNewAirports && allNewAirports.length === 0) {
        dispatch(getAllNewAirports(token));
      }
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!selectedAirport) {
      return;
    }

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (isEmpty(adRunwayInfo)) {
        dispatch(
          getAdRunwayInfo(selectedAirport?.id, selectedAirport?.version_id)
        );
      }
    });
  }, [selectedAirport]); // eslint-disable-line

  const handleEditFormField = (isReadOnly) => {
    setReadOnlyFormField(isReadOnly);
  };

  if (!selectedAirport) return null;

  return (
    <div className="add-airport-data-form-main">
      {selctedAirportFormCategories && (
        <Box sx={styles.addAirportCatMain}>
          <Box sx={styles.addAirportCat}>
            <Typography variant="span" sx={styles.addAirportCatLabel}>
              {selctedAirportFormCategories.code}{" "}
              {capitalize(selctedAirportFormCategories.label)}
            </Typography>
            {selctedAirportFormCategories.value !== ad2Category.ad2_8_apron &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_8_taxiway &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_8_checkpoints &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_18_airtraffic_service &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_12_runway_details &&
              selctedAirportFormCategories.value !== ad2Category.ad2_13 &&
              selctedAirportFormCategories.value !== ad2Category.ad2_14 &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_19_navids &&
              selctedAirportFormCategories.value !== ad2Category.ad2_20 &&
              selctedAirportFormCategories.value !== ad2Category.ad2_23 &&
              selctedAirportFormCategories.value !==
                ad2Category.ad2_24_charts && (
                <IconButton
                  color="primary"
                  onClick={() => handleEditFormField(true)}
                  disabled={isReadOnlyFormField}
                >
                  <EditIcon disabled={isReadOnlyFormField} />
                </IconButton>
              )}
          </Box>
          <Box className="add-airport-content">
            {isLoadingAAIAirport && (
              <LinearProgress />
              // <Typography sx={styles.addAirportContent}>Loading...</Typography>
            )}
            {/* <Stack justifyContent="center" sx={{ height: "100%" }}> */}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_2_aerodom_geographical_and_administrative_data && (
                  <Ad22From
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_3_operationalhours && (
                  <Ad23OperationalhoursForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_4_handlingservice && (
                  <AD24HandlingServiceForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_5_passenger_facilities && (
                  <Ad25PassengerFacilities
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_6_rescueservices && (
                  <AD26RescueservicesForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_7_seasonalservice && (
                  <Ad27SeasonalserviceForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_8_apron && (
                  <Ad28ApronForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_8_taxiway && (
                  <Ad28TaxiwayForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_8_checkpoints && (
                  <AD28CheckpointsForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_9_surfacmovements && (
                  <AD29SurfacmovementsForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_10_aerodom_obstacles && (
                  <Ad210From
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_11_meteorological && (
                  <Ad211MeteorologicalForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_12_runway_details && (
                  <Ad212RunWayDetailFrom
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_13 && (
                  <Ad213Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_14 && (
                  <Ad214Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_15 && (
                  <Ad215Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_17 && (
                  <Ad217Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_18_airtraffic_service && (
                  <Ad218Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_19_navids && (
                  <Ad219NavidForm
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    adRunwayInfo={adRunwayInfo}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_20 && (
                  <AD220Form />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_21_noise && (
                  <Ad221Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_22_flight && (
                  <Ad222Form
                    selectedAirport={selectedAirport}
                    selectedCategory={selctedAirportFormCategories.value}
                    isReadOnly={isReadOnlyFormField}
                    handleCancelReadOnly={() => handleEditFormField(false)}
                  />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value === ad2Category.ad2_23 && (
                  <AD223Form />
                )}
              {selctedAirportFormCategories &&
                selctedAirportFormCategories.value ===
                  ad2Category.ad2_24_charts && (
                  <Ad224ChartsForm
                    selectedAirport={selectedAirport}
                    allAirportsCharts={allAirportsCharts}
                  />
                )}
            {/* </Stack> */}
          </Box>
        </Box>
      )}
    </div>
  );
}
