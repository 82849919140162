import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

function Charts({ selctedAirport, activeCategory, charts }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Chart Name</StyledTableCell>
              <StyledTableCell>URL</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(charts) ? (
              charts.map((row, i) => {
                return (
                  <TableRow key={`${i}_${row?.id}`}>
                    <StyledTableCell>{row?.chart_name}</StyledTableCell>
                    <StyledTableCell>
                      <a href={row?.url} rel="noopener noreferrer">
                        {row?.chart_name}
                      </a>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No data found.
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Charts;
