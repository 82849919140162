import React from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { addShapValidationSchema } from "@constants/amdbData";
import { addEditDeleteAMDBCreateGeometry } from "@store/amdb/actions";
import { getAsyncStorageValue } from "@utils/localStorage";
import { OutlinedButton, PrimaryButton } from "@components/button";

export default function AddAmdbShapModal({
  isOpenAddShapDialog,
  selctedAirport,
  coords,
  handleClose,
  layerType,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddEditDeletePolygon, amdbDetails } = useSelector(
    (state) => state.amdb
  );

  const addShapForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      feature: "",
      shape_name: "",
      uid: "",
      top_elev: "",
      ellipsoida: "",
    },
    validationSchema: addShapValidationSchema,
    onSubmit: (values) => {
      let payload;
      let method;
      let newEditedCoords = [];
      if (layerType === "polygon") {
        method = "POST";
        payload = {
          data: {
            geometry: coords,
          },
          name: values.shape_name,
          details: {},
        };
      } else if (layerType === "circlemarker") {
        method = "POST";
        payload = {
          data: {
            geometry: coords,
          },
          name: values.shape_name,
          details: {
            uid: values.uid,
            latitude: coords[1],
            longitude: coords[0],
            top_elev: String(values.top_elev),
            ellipsoida: String(values.ellipsoida),
          },
        };
      } else if (layerType === "polyline") {
        if (
          amdbDetails &&
          amdbDetails?.PARKING_BAYLINE_List &&
          amdbDetails?.PARKING_BAYLINE_List.length > 0
        ) {
          method = "PUT";
          const oldCoords =
            amdbDetails?.PARKING_BAYLINE_List[0].coordinates?.coordinates;
          const editCoords = [...oldCoords];
          editCoords.push(coords);
          newEditedCoords = editCoords;
          payload = {
            data: {
              geometry: newEditedCoords,
            },
            name: values.shape_name,
            details: {},
            polyId: amdbDetails?.PARKING_BAYLINE_List[0]?.id,
          };
        } else {
          method = "POST";
          newEditedCoords = [coords];
          payload = {
            data: {
              geometry: newEditedCoords,
            },
            name: values.shape_name,
            details: {},
          };
        }
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditDeleteAMDBCreateGeometry(
            method,
            payload,
            selctedAirport?.id,
            values.feature,
            token,
            onCloseAddShapDialog
          )
        );
      });
    },
  });
  const onCloseAddShapDialog = () => {
    addShapForm.resetForm();
    addShapForm.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpenAddShapDialog}>
      <DialogTitle sx={{ pt: 3, pb: 3 }}>Add Shape</DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <form>
          <FormControl
            fullWidth
            sx={{ mt: 1 }}
            error={
              addShapForm.touched.feature && Boolean(addShapForm.errors.feature)
            }
          >
            <InputLabel id="category">Feature*</InputLabel>
            {layerType === "polygon" && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={addShapForm.values.feature}
                onChange={addShapForm.handleChange}
              >
                <MenuItem value="runway">RunWay</MenuItem>
                <MenuItem value="apron">Apron</MenuItem>
                <MenuItem value="taxiway">Taxiway</MenuItem>
              </Select>
            )}
            {layerType === "polyline" && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={addShapForm.values.feature}
                onChange={addShapForm.handleChange}
              >
                <MenuItem value="bayline">Parking Bayline</MenuItem>
              </Select>
            )}
            {layerType === "circlemarker" && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={addShapForm.values.feature}
                onChange={addShapForm.handleChange}
              >
                <MenuItem value="baypoint">Parking Bay Points</MenuItem>
              </Select>
            )}
            <FormHelperText>
              {addShapForm.touched.feature && addShapForm.errors.feature}
            </FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            variant="outlined"
            id="shape_name"
            name="shape_name"
            label="Shape Name*"
            value={addShapForm.values.shape_name}
            onChange={addShapForm.handleChange}
            error={
              addShapForm.touched.shape_name &&
              Boolean(addShapForm.errors.shape_name)
            }
            helperText={
              addShapForm.touched.shape_name && addShapForm.errors.shape_name
            }
            sx={{ mt: 2 }}
          />
          {layerType === "circlemarker" && (
            <>
              <TextField
                fullWidth
                variant="outlined"
                id="uid"
                name="uid"
                label="Uid*"
                type="number"
                value={addShapForm.values.uid}
                onChange={addShapForm.handleChange}
                error={
                  addShapForm.touched.uid && Boolean(addShapForm.errors.uid)
                }
                helperText={addShapForm.touched.uid && addShapForm.errors.uid}
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="top_elev"
                name="top_elev"
                label="Top Elevation*"
                type="number"
                value={addShapForm.values.top_elev}
                onChange={addShapForm.handleChange}
                error={
                  addShapForm.touched.top_elev &&
                  Boolean(addShapForm.errors.top_elev)
                }
                helperText={
                  addShapForm.touched.top_elev && addShapForm.errors.top_elev
                }
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="ellipsoida"
                name="ellipsoida"
                label="Ellipsoida*"
                type="number"
                value={addShapForm.values.ellipsoida}
                onChange={addShapForm.handleChange}
                error={
                  addShapForm.touched.ellipsoida &&
                  Boolean(addShapForm.errors.ellipsoida)
                }
                helperText={
                  addShapForm.touched.ellipsoida &&
                  addShapForm.errors.ellipsoida
                }
                sx={{ mt: 2 }}
              />
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ mb: 3, pt: 0 }}>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={onCloseAddShapDialog}
          sx={{ width: 98, height: 30 }}
        />
        <PrimaryButton
          label="Save"
          onClick={() => addShapForm.handleSubmit()}
          disabled={isLoadingAddEditDeletePolygon}
          isLoading={isLoadingAddEditDeletePolygon}
          sx={{ width: 98, mr: 2, height: 30 }}
        />
      </DialogActions>
    </Dialog>
  );
}
