import React, { useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderArea1({ area1Data, getAreaColors }) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const area1coordinates = area1Data[0]?.coordinates.coordinates || [];
  const area1CoordinateMap = flatten(area1coordinates).map((area1Coords) => {
    return area1Coords.map((tol) => {
      return [tol[1], tol[0]];
    });
  });
  const areaColor = getAreaColors();

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Polygon
      positions={area1CoordinateMap}
      pathOptions={{
        fillColor: areaColor["Area1"],
        color: selectedOverE?.area1 ? areaHighLightColor : areaColor["Area1"],
        weight: selectedOverE?.area1 ? 3 : 2,
      }}
      eventHandlers={{
        mouseover: () => handlePolygonOver({ area1: true }),
        mouseout: () => handlePolygonOut(),
      }}
    />
  );
}
