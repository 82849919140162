import React, { Fragment } from "react";
import { orderBy } from "lodash";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function ApproachAcceptDataTable({ data }) {
  return (
    <Fragment>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Accepted
        </Typography>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              colSpan={6}
              sx={{ width: "1vw" }}
            ></StyledTableCell>
            <StyledTableCell colSpan={4} align="center" sx={{ width: "1vw" }}>
              280M RUNWAY STRIP
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" rowSpan={2}>
              UID
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              OBJECT
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              LATITUDE
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              LONGITUDE
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              TOP ELEV. IN M.
            </StyledTableCell>
            <StyledTableCell align="center" rowSpan={2}>
              BASE ELEV. IN M.
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center">
              <Stack sx={{ width: "5vw" }}>
                <StyledBoxV2>DISTANCE (IN M)</StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>X</StyledBoxV2>
                  <StyledBoxV2>Y</StyledBoxV2>
                  <StyledBoxV2>YY</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Stack sx={{ width: "10vw" }}>
                <StyledBoxV2>
                  W.R.T 1:50 IN APP FUNNEL {"&"} 1:7 IN T.S. (IN M.)
                </StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                  <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Stack sx={{ width: "10vw", m: 0 }}>
                <StyledBoxV2>W.R.T. I.H.S./C.S./O.H.S. (IN M.)</StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                  <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{ typography: "caption", sx: "1vw" }}
            >
              REMARKS
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["uid1"], ["asc"]).map((row, i) => {
            return (
              <TableRow key={i}>
                <StyledTableCell align="center">{row["uid1"]}</StyledTableCell>
                <StyledTableCell align="center">
                  {row["object1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["latitude1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["longitude1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["top_elev1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row["base_elev1"]}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Stack
                    sx={{ width: "6vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["x1"]}</Box>
                    <Box>{row["y1"]}</Box>
                    <Box>{row["yy1"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Stack
                    sx={{ width: "10vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["APPpermissible_elev1"]}</Box>
                    <Box>{row["APPobstacle1"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Stack
                    sx={{ width: "10vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["IHSpermissible_elev1"]}</Box>
                    <Box>{row["IHSobstacle1"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ sx: "2vw" }}>
                  {row["remarks1"]}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
}
