import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCellV2 } from "@components/tabel-component/StyledTableCell";
import StyledTableRow from "@components/tabel-component/StyledTableRow";
import { selecteChartTableData } from "@store/electronicChart";
import { styles } from "@styles/aixmStyles";

export default function ADCChartTable({ selectedEchart }) {
  const data = useSelector((state) =>
    selecteChartTableData(state, selectedEchart)
  );

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellV2>Id</StyledTableCellV2>
            <StyledTableCellV2>Bearing Strength</StyledTableCellV2>
            <StyledTableCellV2>Direction</StyledTableCellV2>
            <StyledTableCellV2>RWY</StyledTableCellV2>
            <StyledTableCellV2>Coordinates</StyledTableCellV2>
            <StyledTableCellV2>Elev</StyledTableCellV2>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {!isEmpty(data) ? (
            data.map((row) => (
              <StyledTableRow
                key={row.id}
                id={`table-row-${row.id}`}
                sx={styles.tableRowLastBorderNone}
              >
                <StyledTableCellV2>{row.id}</StyledTableCellV2>
                <StyledTableCellV2>{row.bearing_strength}</StyledTableCellV2>
                <StyledTableCellV2>{row.direction}</StyledTableCellV2>
                <StyledTableCellV2>{row.rwy}</StyledTableCellV2>
                <StyledTableCellV2>{row.thr_coordinates}</StyledTableCellV2>
                <StyledTableCellV2>{row.thr_elev}</StyledTableCellV2>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow sx={styles.tableRowLastBorderNone}>
              <StyledTableCellV2 colSpan={6} align='center'>No data found!</StyledTableCellV2>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
