import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DownloadBoldIcon } from "@assets/svg/download-bold";
import { EyeIcon } from "@assets/svg/eye";
import { ACCESS_TOKEN, AES_KEY } from "@constants/constant";
import { categoryKeyMap, statusColorMap } from "@constants/surveyorData";
import { LightTooltip } from "@components/core/LightTooltip";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableRowLoader from "@components/tabel-component/TableRowLoader";
import { setSelectedSurveyReport, selectAllSurveyReports } from "@store/survey";
import { getAllSurveyReport, exportReviewReport } from "@store/survey/actions";
import { theme } from "@styles/theme";
import { decryptStatic } from "@utils/decodeEncodeData";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";

export default function SurveyorReportList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoadingAllSurveyReport, isExportReviewReportLoading } = useSelector(
    (state) => state.survey
  );
  const currentUser = useSelector((state) => state.userSession.user);
   const accessToken = getStorageValue(ACCESS_TOKEN);
  const allSurveyReports = useSelector(selectAllSurveyReports);
  const aesKey = getStorageValue(AES_KEY);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyReport(token));
    });
  }, []); // eslint-disable-line

  const handleReport = (report) => {
    dispatch(setSelectedSurveyReport(report));
    navigate(`/data-management/review/surveyor-report-list/${report.id}`, {
      state: report,
    });
  };

  const handleExportDataset = (id, type) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (currentUser?.user_access?.export || accessToken) {
        dispatch(exportReviewReport(id, type, token));
      }
    });
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>SURVEYOR NAME</StyledTableCell>
            <StyledTableCell>AIRPORT NAME</StyledTableCell>
            <StyledTableCell>DATASET TYPE</StyledTableCell>
            <StyledTableCell>DATE AND TIME</StyledTableCell>
            <StyledTableCell align="center">STATUS</StyledTableCell>
            <StyledTableCell align="center">EXPORT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader
            isLoading={isLoadingAllSurveyReport}
            data={allSurveyReports}
            colSpan={6}
          />
          {allSurveyReports?.map((report) => {
            return (
              <TableRow key={report.id} tabIndex={-1}>
                <StyledTableCell>
                  {decryptStatic(report.user_name, aesKey)}
                </StyledTableCell>
                <StyledTableCell>{report.airport_name}</StyledTableCell>
                <StyledTableCell>
                  {categoryKeyMap[report.feature_name]}
                </StyledTableCell>
                <StyledTableCell>{report.created_datetime}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box
                    component="div"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <p
                      style={{
                        padding: 5,
                        backgroundColor: statusColorMap[report.status],
                        color: theme.palette.white.main,
                        fontWeight: "bold",
                        borderRadius: 5,
                        width: 100,
                        textAlign: "center",
                        margin: "3px 0",
                      }}
                    >
                      {report.status}
                    </p>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <LightTooltip title="Review">
                    <IconButton
                      color="primary"
                      onClick={() => handleReport(report)}
                    >
                      <EyeIcon />
                    </IconButton>
                  </LightTooltip>
                  {categoryKeyMap[report.feature_name] ===
                    categoryKeyMap["Objects/Obstacles"] && (
                    <LightTooltip title="Export">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleExportDataset(
                            report.id,
                            categoryKeyMap[report.feature_name]
                          )
                        }
                        loading={isExportReviewReportLoading}
                        disabled={
                          isExportReviewReportLoading ||
                          !currentUser?.user_access?.export ||
                          !accessToken
                        }
                      >
                        <DownloadBoldIcon
                          disabled={
                            !currentUser?.user_access?.export || !accessToken
                          }
                        />
                      </IconButton>
                    </LightTooltip>
                  )}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
