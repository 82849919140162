import { useEffect } from "react";
import {
  Box,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd25tValidationSchema } from "@constants/ad2Data";
import { AD25initialValue } from "@constants/ad2DataForm.constant";
import { PrimaryButton, LightButton } from "@components/button";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad25PassengerFacilities({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd25Form.setFieldValue(
      "BANK",
      AAIAirport?.BANK ? AAIAirport?.BANK : ""
    );
    createAd25Form.setFieldValue(
      "HOTEL",
      AAIAirport?.HOTEL ? AAIAirport?.HOTEL : ""
    );
    createAd25Form.setFieldValue(
      "INFO",
      AAIAirport?.INFO ? AAIAirport?.INFO : ""
    );
    createAd25Form.setFieldValue(
      "MEDIC",
      AAIAirport?.MEDIC ? AAIAirport?.MEDIC : ""
    );
    createAd25Form.setFieldValue(
      "POST",
      AAIAirport?.POST ? AAIAirport?.POST : ""
    );
    createAd25Form.setFieldValue(
      "REST",
      AAIAirport?.REST ? AAIAirport?.REST : ""
    );
    createAd25Form.setFieldValue(
      "TRANSPORT",
      AAIAirport?.TRANSPORT ? AAIAirport?.TRANSPORT : ""
    );
    createAd25Form.setFieldValue(
      "REMARKS",
      AAIAirport?.REMARKS ? AAIAirport?.REMARKS : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "passengerfacilities",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd25Form.resetForm();
    createAd25Form.setSubmitting(false);
  };

  const createAd25Form = useFormik({
    enableReinitialize: true,
    initialValues: AD25initialValue,
    validationSchema: createAd25tValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "passengerfacilities",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Bank</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="BANK"
                name="BANK"
                value={createAd25Form.values.BANK}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.BANK &&
                  Boolean(createAd25Form.errors.BANK)
                }
                helperText={
                  createAd25Form.touched.BANK && createAd25Form.errors.BANK
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Post</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="POST"
                name="POST"
                value={createAd25Form.values.POST}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.POST &&
                  Boolean(createAd25Form.errors.POST)
                }
                helperText={
                  createAd25Form.touched.POST && createAd25Form.errors.POST
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Hotel(s) at or in the vicinity of aerodrome
            </Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="HOTEL"
                name="HOTEL"
                value={createAd25Form.values.HOTEL}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.HOTEL &&
                  Boolean(createAd25Form.errors.HOTEL)
                }
                helperText={
                  createAd25Form.touched.HOTEL && createAd25Form.errors.HOTEL
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Tourist office</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="INFO"
                name="INFO"
                value={createAd25Form.values.INFO}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.INFO &&
                  Boolean(createAd25Form.errors.INFO)
                }
                helperText={
                  createAd25Form.touched.INFO && createAd25Form.errors.INFO
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Medical Facilities</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="MEDIC"
                name="MEDIC"
                value={createAd25Form.values.MEDIC}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.MEDIC &&
                  Boolean(createAd25Form.errors.MEDIC)
                }
                helperText={
                  createAd25Form.touched.MEDIC && createAd25Form.errors.MEDIC
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Restaurant(s) at or in the vicinity of aerodrome
            </Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="REST"
                name="REST"
                value={createAd25Form.values.REST}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.REST &&
                  Boolean(createAd25Form.errors.REST)
                }
                helperText={
                  createAd25Form.touched.REST && createAd25Form.errors.REST
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Transportation possibilities</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="TRANSPORT"
                name="TRANSPORT"
                value={createAd25Form.values.TRANSPORT}
                onChange={createAd25Form.handleChange}
                error={
                  createAd25Form.touched.TRANSPORT &&
                  Boolean(createAd25Form.errors.TRANSPORT)
                }
                helperText={
                  createAd25Form.touched.TRANSPORT &&
                  createAd25Form.errors.TRANSPORT
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd25Form.touched?.REMARKS &&
                  Boolean(createAd25Form.errors?.REMARKS)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="REMARKS"
                  name="REMARKS"
                  value={createAd25Form.values.REMARKS}
                  onChange={createAd25Form.handleChange}
                  aria-describedby="aerodrome-REMARKS"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-REMARKS">
                  {createAd25Form.touched?.REMARKS &&
                    createAd25Form.errors?.REMARKS}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd25Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd25Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}
