import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingSurveyAirport: false,
  isDownloadTemplate: false,
  isUploadSurveyFile: false,
  isLoadingAllSurveyReport: false,
  isLoadingSurveyReportDetail: false,
  isLoadingVerification: false,
  surveyAllAirports: [],
  uploadFileData: null,
  allSurveyReports: [],
  selectedReport: null,
  surveyReportDetail: [],
  isLoadingReviewFeaturesdata: false,
  reviewBackFeaturesData: [],
  isLoadingReviewBackSurveyData: false,
  reviewBackSurveyData: null,
  isLoadingGetAllSurveyor: false,
  allSurveyorList: [],
  isLoadingPostSurveyorRegistration: false,
  isLoadingEditSurveyorRegistration: false,
  isLoadingDisableSurveyor: false,
  isLoadingEnableSurveyor: false,
  isResetingMasterPassword: false,
  isLoadingGetallReqPassword: false,
  allReqPassword: [],
  isLoadingPostEditReviewData: false,
  isLoadingCompareData: false,
  comparedata: null,
  isSurveyorAirportLoading: false,
  surveyorAirportData: [],
  isExportReviewReportLoading: false,
};

export const survey = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setLoadingSurveyAirportLoadingRequest: (state, action) => {
      state.isLoadingSurveyAirport = action.payload;
    },
    setTemplateDownloadRequest: (state, action) => {
      state.isDownloadTemplate = action.payload;
    },
    setUplodSurveyFileRequest: (state, action) => {
      state.isUploadSurveyFile = action.payload;
    },
    setAllSurveyReportRequest: (state, action) => {
      state.isLoadingAllSurveyReport = action.payload;
    },
    setSurveyReportDetailRequest: (state, action) => {
      state.isLoadingSurveyReportDetail = action.payload;
    },
    setSurveyVerificationRequest: (state, action) => {
      state.isLoadingVerification = action.payload;
    },
    setAllSurveyAirport: (state, action) => {
      state.surveyAllAirports = action.payload;
    },
    setUploadFileData: (state, action) => {
      state.uploadFileData = action.payload?.survey_data;
    },
    setAllSurveyReports: (state, action) => {
      state.allSurveyReports = action.payload?.SurveyFeaturesa_list;
    },
    setSelectedSurveyReport: (state, action) => {
      state.selectedReport = action.payload;
    },
    setSurveyReportDetail: (state, action) => {
      state.surveyReportDetail = action.payload?.SurveyData_list;
    },
    setReviewFeaturesdataLoading: (state, action) => {
      state.isLoadingReviewFeaturesdata = action.payload;
    },
    setReviewBackFeaturesData: (state, action) => {
      state.reviewBackFeaturesData = action.payload;
    },
    setReviewBackSurveyDataLoading: (state, action) => {
      state.isLoadingReviewBackSurveyData = action.payload;
    },
    setReviewBackSurveyData: (state, action) => {
      state.reviewBackSurveyData = {
        ...state.reviewBackSurveyData,
        [action.payload.id]: action.payload.data,
      };
    },
    setGetAllSurveyorDataLoading: (state, action) => {
      state.isLoadingGetAllSurveyor = action.payload;
    },
    setAllSurveyorList: (state, action) => {
      state.allSurveyorList = action.payload;
    },
    setPostSurveyorRegistrationLoading: (state, action) => {
      state.isLoadingPostSurveyorRegistration = action.payload;
    },
    setEditSurveyorRegistrationLoading: (state, action) => {
      state.isLoadingEditSurveyorRegistration = action.payload;
    },
    setDisableSurveyorLoading: (state, action) => {
      state.isLoadingDisableSurveyor = action.payload;
    },
    setEnableSurveyorLoading: (state, action) => {
      state.isLoadingEnableSurveyor = action.payload;
    },
    setResetingMasterPasswordLoading: (state, action) => {
      state.isResetingMasterPassword = action.payload;
    },
    setLoadingGetallReqPassword: (state, action) => {
      state.isLoadingGetallReqPassword = action.payload;
    },
    setAllReqPassword: (state, action) => {
      state.allReqPassword = action.payload;
    },
    setLoadingPostEditReviewData: (state, action) => {
      state.isLoadingPostEditReviewData = action.payload;
    },
    setLoadingCompareData: (state, action) => {
      state.isLoadingCompareData = action.payload;
    },
    setCompareData: (state, action) => {
      state.comparedata = action.payload;
    },
    setSurveyorAirportLoading: (state, action) => {
      state.isSurveyorAirportLoading = action.payload;
    },
    setSurveyorAirportData: (state, action) => {
      state.surveyorAirportData = action.payload;
    },
    setExportReviewReportLoading: (state, action) => {
      state.isExportReviewReportLoading = action.payload;
    },
  },
});
