import React, { Fragment, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { airspaceType } from "@constants/airspaceData";
import { areaHighLightColor } from "@constants/colors";

export default function RenderFIRAirspaceUsePlan({ FIRAirspaceGeometry, handleAirspaceUsePlanDetails }) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (e, data) => {
    e.target.bringToFront();
    e.target.openPopup();
    setSelectedOverE(data);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleAirspaceUsePlanDetails(info, airspaceType.firuir);
  };

  return (
    <Fragment>
      {FIRAirspaceGeometry.map((data, i) => {
        return (
          <GeoJSON
            key={data.name}
            pane="tilePane"
            data={data}
            pathOptions={{
              color:
                selectedOverE?.name === data.name
                  ? areaHighLightColor
                  : "#0112dd",
              weight: selectedOverE?.name === data.name ? 3 : 2,
              fillColor: "#0112dd",
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut(),
              click: () => handlePolygonClick(data),
            }}
          />
        );
      })}
    </Fragment>
  );
}
