import React, {
  Fragment,
  useMemo,
  useRef,
  useEffect,
  useState,
  memo,
} from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import ControlMapCenter from "@components/map/ControlMapCenter";
import FullScreenControl from "@components/map/FullScreenControl";
import MapLayers from "@components/map/MapLayers";
import { styles } from "@styles/echartStyles";
import RenderContours from "./RenderContours";
import RenderSurface from "./RenderSurface";
import RenderObstacles from "./RenderObstacles";
import RenderGrid from "./RenderGrid";
import RenderGridLabel from "./RenderGridLabel";
import RenderPointsProfile from "./RenderPointsProfile";
import RendeRwyProfile from "./RendeRwyProfile";
import RenderSlopeline from "./RenderSlopeline";
import RenderSwyProfile from "./RenderSwyProfile";
import RenderTerrainProfile from "./RenderTerrainProfile";
import RenderMaxTerrainProfile from "./RenderMaxTerrainProfile";
import RenderTerrainProfileSlop from "./RenderTerrainProfileSlop";

const EchartSplitMap = ({
  selctedAirport,
  airportLocation,
  zoomLevel,
  atocaData,
  isShowLabel,
  selectedEchart,
  isShowMaxTerrain,
}) => {
  const firstMapRef = useRef(null);
  const secondMapRef = useRef(null);
  const [selectedType, setSelectedType] = useState(null);
  const selectedrwy = selectedEchart.split("-")[2];
  const planView = atocaData.PlanView;
  const profileView = atocaData.ProfileView;
  const contoursList = planView.contours_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const obstaclesList = planView.obstacles_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const surfaceList = planView.surface_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const gridList = profileView.grid_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const gridtextList = profileView.gridtext_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const pointsprofileList = profileView.pointsprofile_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const rwyprofileList = profileView.rwyprofile_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const slopelineList = profileView.slopeline_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const swyprofileList = profileView.swyprofile_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const terrainprofileList = profileView.terrainprofile_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const terrainprofileslopeList = profileView.terrainprofileslope_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );
  const maxterrainprofileList = profileView.maxterrainprofile_list.filter(
    (g) => g.rwy_direction === selectedrwy
  );

  useEffect(() => {
    if (!selectedEchart || selectedType === selectedEchart) return;
    setSelectedType(selectedEchart);
  }, [selectedEchart]); // eslint-disable-line

  useEffect(() => {
    if (selectedEchart !== selectedType) return;
    firstMapRef.current?.on("moveend", (e) => {
      const firstZoom = e.target.getZoom();
      const firstCenter = e.target.getCenter();
      secondMapRef.current.setZoom(firstZoom);
      secondMapRef.current.panTo(firstCenter);
    });
  }, [firstMapRef.current, selectedType]); // eslint-disable-line

  const PlainMapE = useMemo(() => {
    return (
      <MapContainer
        ref={firstMapRef}
        style={{ height: "100%", width: "100%" }}
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        <MapLayers />
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        {contoursList.length > 0 && <RenderContours data={contoursList} />}
        {surfaceList.length > 0 && <RenderSurface data={surfaceList} />}
        {obstaclesList.length > 0 && <RenderObstacles data={obstaclesList} />}
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );

    // eslint-disable-next-line
  }, [
    airportLocation,
    zoomLevel,
    selctedAirport,
    atocaData,
    contoursList,
    surfaceList,
    obstaclesList,
  ]);

  const ProfileMapE = useMemo(() => {
    return (
      <MapContainer
        ref={secondMapRef}
        style={styles.echartMap}
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        doubleClickZoom={false}
      >
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <RenderGrid data={gridList} />
        <RenderGridLabel
          data={gridtextList.length > 0 ? gridtextList : null}
          isShowLabel={isShowLabel}
        />
        <RenderPointsProfile data={pointsprofileList} />
        <RendeRwyProfile data={rwyprofileList} />
        <RenderSlopeline data={slopelineList} />
        <RenderSwyProfile data={swyprofileList} />
        <RenderTerrainProfile data={terrainprofileList} />
        <RenderTerrainProfileSlop data={terrainprofileslopeList} />
        {isShowMaxTerrain && (
          <RenderMaxTerrainProfile data={maxterrainprofileList} />
        )}
        <FullScreenControl />
      </MapContainer>
    );

    // eslint-disable-next-line
  }, [
    airportLocation,
    zoomLevel,
    selctedAirport,
    atocaData,
    isShowLabel,
    isShowMaxTerrain,
    gridList,
    gridtextList,
  ]);

  return (
    <Fragment>
      {PlainMapE}
      {ProfileMapE}
    </Fragment>
  );
};

export default memo(EchartSplitMap);
