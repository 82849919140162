import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function DeclaredDistaces({
  selctedAirport,
  activeCategory,
  declareddistaces,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={6}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>RWY Designator</StyledTableCell>
              <StyledTableCell>Take-off run available TORA (M)</StyledTableCell>
              <StyledTableCell>
                Take-off distance available TODA (M)
              </StyledTableCell>
              <StyledTableCell>
                Accelerate distance available ASDA (M)
              </StyledTableCell>
              <StyledTableCell>
                Landing distance available LDA (M)
              </StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(declareddistaces) ? (
              declareddistaces.map((dd, index) => (
                <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                  <StyledTableCell>{dd.rd ? dd.rd : "NIL"}</StyledTableCell>
                  <StyledTableCell>{dd.tora ? dd.tora : "NIL"}</StyledTableCell>
                  <StyledTableCell>{dd.toda ? dd.toda : "NIL"}</StyledTableCell>
                  <StyledTableCell>{dd.asda ? dd.asda : "NIL"}</StyledTableCell>
                  <StyledTableCell>{dd.lda ? dd.lda : "NIL"}</StyledTableCell>
                  <StyledTableCell>
                    {dd.remarks ? dd.remarks : "NIL"}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}

export default DeclaredDistaces;
