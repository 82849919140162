import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import {PrimaryButton, LightButton } from "@components/button";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd215ValidationSchema } from "@constants/ad2Data";
import { AD215initialValue } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad215Form({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd215Form.setFieldValue(
      "colour",
      AAIAirport?.colour ? AAIAirport?.colour : ""
    );
    createAd215Form.setFieldValue(
      "flashing",
      AAIAirport?.flashing ? AAIAirport?.flashing : ""
    );
    createAd215Form.setFieldValue(
      "groundlightdescrpt",
      AAIAirport?.groundlightdescrpt ? AAIAirport?.groundlightdescrpt : ""
    );
    createAd215Form.setFieldValue(
      "landingDirectionIndicator",
      AAIAirport?.landingDirectionIndicator
        ? AAIAirport?.landingDirectionIndicator
        : ""
    );
    createAd215Form.setFieldValue(
      "landingDirectiondescrpt",
      AAIAirport?.landingDirectiondescrpt
        ? AAIAirport?.landingDirectiondescrpt
        : ""
    );
    createAd215Form.setFieldValue(
      "secondaryPowerSupply",
      AAIAirport?.secondaryPowerSupply ? AAIAirport?.secondaryPowerSupply : ""
    );
    createAd215Form.setFieldValue(
      "secondaryPowerSupplydescrpt",
      AAIAirport?.secondaryPowerSupplydescrpt
        ? AAIAirport?.secondaryPowerSupplydescrpt
        : ""
    );
    createAd215Form.setFieldValue(
      "windDirectionIndicator",
      AAIAirport?.windDirectionIndicator
        ? AAIAirport?.windDirectionIndicator
        : ""
    );
    createAd215Form.setFieldValue(
      "windDirectiondescrpt",
      AAIAirport?.windDirectiondescrpt ? AAIAirport?.windDirectiondescrpt : ""
    );

    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "powerlights",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd215Form.resetForm();
    createAd215Form.setSubmitting(false);
  };

  const createAd215Form = useFormik({
    enableReinitialize: true,
    initialValues: AD215initialValue,
    validationSchema: createAd215ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "powerlights",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Colour</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="colour"
                name="colour"
                value={createAd215Form.values.colour}
                onChange={createAd215Form.handleChange}
                error={
                  createAd215Form.touched.colour &&
                  Boolean(createAd215Form.errors.colour)
                }
                helperText={
                  createAd215Form.touched.colour &&
                  createAd215Form.errors.colour
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Flashing</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="flashing"
                name="flashing"
                value={createAd215Form.values.flashing}
                onChange={createAd215Form.handleChange}
                error={
                  createAd215Form.touched.flashing &&
                  Boolean(createAd215Form.errors.flashing)
                }
                helperText={
                  createAd215Form.touched.flashing &&
                  createAd215Form.errors.flashing
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Ground Light Descrpt</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, mt: 2, width: "100%" }}
                error={
                  createAd215Form.touched?.groundlightdescrpt &&
                  Boolean(createAd215Form.errors?.groundlightdescrpt)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="groundlightdescrpt"
                  name="groundlightdescrpt"
                  value={createAd215Form.values.groundlightdescrpt}
                  onChange={createAd215Form.handleChange}
                  aria-describedby="aerodrome-groundlightdescrpt"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-groundlightdescrpt">
                  {createAd215Form.touched?.groundlightdescrpt &&
                    createAd215Form.errors?.groundlightdescrpt}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Landing Direction Indicator</Box>
            <Box sx={styles.adInput}>
              <FormControl
                fullWidth
                size="small"
                error={
                  createAd215Form.touched.landingDirectionIndicator &&
                  Boolean(createAd215Form.errors.landingDirectionIndicator)
                }
              >
                <MuiSelect
                  name="landingDirectionIndicator"
                  value={createAd215Form.values.landingDirectionIndicator}
                  onChange={createAd215Form.handleChange}
                  inputProps={{
                    readOnly: !isReadOnly,
                  }}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </MuiSelect>
                <FormHelperText>
                  {createAd215Form.touched.landingDirectionIndicator &&
                    createAd215Form.errors.landingDirectionIndicator}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Landing Direction Descrpt</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="landingDirectiondescrpt"
                name="landingDirectiondescrpt"
                value={createAd215Form.values.landingDirectiondescrpt}
                onChange={createAd215Form.handleChange}
                error={
                  createAd215Form.touched.landingDirectiondescrpt &&
                  Boolean(createAd215Form.errors.landingDirectiondescrpt)
                }
                helperText={
                  createAd215Form.touched.landingDirectiondescrpt &&
                  createAd215Form.errors.landingDirectiondescrpt
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mb: 2, mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Secondary Power Supply</Box>
            <Box sx={styles.adInput}>
              <FormControl
                fullWidth
                size="small"
                error={
                  createAd215Form.touched.secondaryPowerSupply &&
                  Boolean(createAd215Form.errors.secondaryPowerSupply)
                }
              >
                <MuiSelect
                  name="secondaryPowerSupply"
                  value={createAd215Form.values.secondaryPowerSupply}
                  onChange={createAd215Form.handleChange}
                  inputProps={{
                    readOnly: !isReadOnly,
                  }}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </MuiSelect>
                <FormHelperText>
                  {createAd215Form.touched.secondaryPowerSupply &&
                    createAd215Form.errors.secondaryPowerSupply}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Secondary Power Supply Descrpt</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, mt: 2, width: "100%" }}
                error={
                  createAd215Form.touched?.secondaryPowerSupplydescrpt &&
                  Boolean(createAd215Form.errors?.secondaryPowerSupplydescrpt)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="secondaryPowerSupplydescrpt"
                  name="secondaryPowerSupplydescrpt"
                  value={createAd215Form.values.secondaryPowerSupplydescrpt}
                  onChange={createAd215Form.handleChange}
                  aria-describedby="aerodrome-secondaryPowerSupplydescrpt"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-secondaryPowerSupplydescrpt">
                  {createAd215Form.touched?.secondaryPowerSupplydescrpt &&
                    createAd215Form.errors?.secondaryPowerSupplydescrpt}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Wind Direction Indicator</Box>
            <Box sx={styles.adInput}>
              <FormControl
                fullWidth
                size="small"
                error={
                  createAd215Form.touched.windDirectionIndicator &&
                  Boolean(createAd215Form.errors.windDirectionIndicator)
                }
              >
                <MuiSelect
                  name="windDirectionIndicator"
                  value={createAd215Form.values.windDirectionIndicator}
                  onChange={createAd215Form.handleChange}
                  inputProps={{
                    readOnly: !isReadOnly,
                  }}
                >
                  <MenuItem value="YES">YES</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </MuiSelect>
                <FormHelperText>
                  {createAd215Form.touched.windDirectionIndicator &&
                    createAd215Form.errors.windDirectionIndicator}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Wind Direction descrpt</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="windDirectiondescrpt"
                name="windDirectiondescrpt"
                value={createAd215Form.values.windDirectiondescrpt}
                onChange={createAd215Form.handleChange}
                error={
                  createAd215Form.touched.windDirectiondescrpt &&
                  Boolean(createAd215Form.errors.windDirectiondescrpt)
                }
                helperText={
                  createAd215Form.touched.windDirectiondescrpt &&
                  createAd215Form.errors.windDirectiondescrpt
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd215Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd215Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad215Form;
