import { theme } from "./theme";

export const styles = {
  userInputBtn: {
    mt: 4,
    position: "relative",
    cursor: "pointer",
  },
  etodLabelfs24: {
    fontSize: 24,
    fontWeight: 600,
  },
  etodLabelfs48: {
    fontSize: 48,
    fontWeight: 600,
  },
  searchIcon: {
    position: "absolute",
    top: "69px",
    left: "56px",
  },
  etodContent: {
    flex: 1,
    borderRadius: 5,
    padding: "26px 16px 12px 16px",
  },
  createRwyForm: {
    height: "calc(100% - 38px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
    border: "0.5px solid #BDBDBD",
    borderBottom: 0,
    borderTop: 0,
  },
  etodUserlabelMain: {
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: "3px 3px 0px 0px",
    border: "0.5px solid #BDBDBD",
    borderBottom: "0px",
    pt: 1,
    pb: 1,
    pl: 3,
  },
  etodUserlabel: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.black.main,
  },
  etodARP: {
    color: "#42444A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    mb: 1,
  },
  runWay: {
    width: "100%",
    borderBottom: `0.5px solid ${theme.palette.grey.borderclr}`,
    pb: 1,
    pt: 1,
  },
  runWayLabel: {
    width: "95%",
    display: "inline-block",
  },
  runWayDetails: {
    color: "#42444A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    mb: 1.5,
  },
  thrCoordinates: {
    color: "#42444A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    mt: 2,
  },
  thrLatitude: {
    padding: 10,
    fontSize: 20,
    marginRight: "30px",
    width: "100%",
  },
  RWYDirection: {
    color: "#42444A",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    mt: 1.5,
    position: "relative",
    cursor: "pointer",
    display: "flex",
  },
  circleIcon: {
    paddingLeft: 2,
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  proceedBtnMain: {
    padding: "0 30px",
    height: "52px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.tertiary.main,
    borderRadius: "0px 0px 5px 5px",
    border: "0.5px solid #bdbdbd",
    borderTop: 0,
    button: {
      height: "32px",
    },
  },
  proceedBtn: {
    width: 155,
    paddingTop: "3px",
    paddingBottom: "3px",
    ".MuiTypography-button": {
      fontWeight: 600,
      fontSize: 18,
    },
  },
  etodMapContainer: { flex: 1, borderRadius: 5, overflow: "auto" },
  etodSidebar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#000",
    cursor: "pointer",
  },
  etodGenSurfContent: {
    height: "calc(100vh - 170px)",
    overflow: "auto",
    width: "100%",
  },
  etodGenSurfLabel: {
    width: "100%",
    "div:last-child": {
      border: 0,
    },
    pt: 0,
    pr: "15px",
    pb: 0,
    pl: "25px",
  },
  etodSidebarBack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "#000",
    cursor: "pointer",
  },
  airportFeatureUpload: {
    width: "100%",
    backgroundColor: theme.palette.white.main,
    marginTop: -6.5,
    borderTop: "1px dashed #828282",
  },
  airportFeatureUploadBtn: {
    width: "100%",
    color: theme.palette.primary.main,
    textTransform: "unset",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderRadius: 0,
    borderTop: 0,
    cursor: "pointer",
  },
  airportFeatureUploadDEM: {
    width: "100%",
    opacity: 0,
    cursor: "pointer",
    height: "100%",
    position: "absolute",
  },
  etod3DMap: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1001,
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
  },
  etodObstaclesLabel: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginTop: 17,
  },
  etodObstacleSymbol: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 1,
    paddingBottom: 1,
    height: "65px",
  },
};
