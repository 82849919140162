import { Fragment } from "react";
import { isEmpty } from "lodash";
import UTMLatLng from "utm-latlng";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

const Checkpoints = ({ selctedAirport, activeCategory, checkpoints }) => {
  const utmHandler = new UTMLatLng();
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        {!isEmpty(checkpoints) ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderTableCell colSpan={2}>
                    {selctedAirport.name} {">"} Checkpoint VOR
                  </HeaderTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Elevation</StyledTableCell>
                  <StyledTableCell>Coordinates</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkpoints.VOR && checkpoints.VOR.length > 0 ? (
                  checkpoints.VOR.map((vor, j) => {
                    const utmData = utmHandler.convertLatLngToUtm(
                      vor.coordinates[0],
                      vor.coordinates[1],
                      2
                    );
                    const utmLat = utmData?.Northing;
                    const utmLng = utmData?.Easting;

                    return (
                      <TableRow key={`${j}_vor`}>
                        <StyledTableCell style={{ width: 300 }}>
                          {vor.elevation || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {utmLat ? `${utmLat}N` : ""}
                          {utmLng ? `, ${utmLng}E` : ""}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      No data found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderTableCell colSpan={2}>
                    {selctedAirport.name} {">"} Checkpoint INS
                  </HeaderTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Elevation</StyledTableCell>
                  <StyledTableCell>Coordinates</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkpoints.INS && checkpoints.INS.length > 0 ? (
                  checkpoints.INS.map((ins, j) => {
                    const utmData = utmHandler.convertLatLngToUtm(
                      ins.coordinates[0],
                      ins.coordinates[1],
                      2
                    );
                    const utmLat = utmData?.Northing;
                    const utmLng = utmData?.Easting;

                    return (
                      <TableRow key={`${j}_ins`}>
                        <StyledTableCell style={{ width: 300 }}>
                          {ins.elevation || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {utmLat ? `${utmLat}N` : ""}
                          {utmLng ? `, ${utmLng}E` : ""}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      No data found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderTableCell colSpan={2}>
                    {selctedAirport.name} {">"} Remarks
                  </HeaderTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>
                    {checkpoints?.remarks || "-"}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Fragment>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center">No data found.</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Paper>
    </Fragment>
  );
};

export default Checkpoints;
