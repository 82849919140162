import React from "react";
import { alpha } from '@mui/material/styles';
import {Toolbar, Typography} from '@mui/material';

export default function EnhancedTableToolbar({ numSelected }) {
  return (
    <Toolbar
      sx={{
        pr: 2,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} selected
      </Typography>
    </Toolbar>
  );
};