import { toast } from "react-toastify";
import {
  GET_ALL_ETOD_AIRPORT_URL,
  GET_ETOD_AREA_1_URL,
  GET_ETOD_AREA_1_OBSTACLES_URL,
  GET_ETOD_LAYER_URL,
  EXPORT_OBSTACLES_URL,
  GET_ETOD_AIXM_AIRPORT_URL,
  GENERATE_ETOD_AIXM_AIRPORT_LAYER_URL,
  ETOD_DEMS_URL,
  ETOD_AREA_OLS_EXPORT_URL,
  GET_GEOSERVER_LAYER_DOWNALOLD_URL,
  GET_OBSTACLE_ANALYSIS_URL,
  POST_OBSTACLE_ANALYSIS_URL,
  UPLOAD_OBSTACLE_ANALYSIS_URL,
  ETOD_AIRPOT_CREATE_LAYERS_URL,
  EXPORT_ETOD_AIRPOT_LAYER_URL,
  GET_ETOD_AIRPOT_LAYER_URL,
  DELETE_ETOD_AIRPOT_LAYER_URL,
} from "@constants/urls";
import {
  headers,
  apiCall,
  downloadReadableStreamFile,
  fileUploadApicall,
  Timeout,
} from "@utils/connect";
import {
  setEtodAirportLoadingRequest,
  setEtodArea1LoadingRequest,
  setAllEtodAirport,
  setArea1Details,
  setLoadingArea1Obstacles,
  setArea1Obstacles,
  setLoadingExportObstacle,
  setLoadingEtodLayers,
  setEtodLayers,
  setLoadingEtodAixmAirports,
  setEtodAixmAirports,
  setLoadingGenerateEtodAirport,
  setLoadingEtodDems,
  setEtodDems,
  setUplodeeTodDemFileRequest,
  setLoadingExportAreaOls,
  setLoadingAnalysisObstacles,
  setAnalysisObstacles,
  setLoadingProcessObstacleAnalysis,
  setLoadingUploadObstacleAnalysis,
  setLoadingAirportCreatelayers,
  setLoadingAirportlayer,
  setLoadingExportAirportLayers,
  setLoadingDeleteAirportlayer,
  setCreatelayerAirportData,
} from ".";

export const getEtodAllAirport = () => (dispatch) => {
  try {
    dispatch(setEtodAirportLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllEtodAirport(response.data));
      dispatch(setEtodAirportLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodAirportLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_ETOD_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodAirportLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodArea1 = () => (dispatch) => {
  try {
    dispatch(setEtodArea1LoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setArea1Details(response.data));
      dispatch(setEtodArea1LoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setEtodArea1LoadingRequest(false));
    };

    apiCall("GET", GET_ETOD_AREA_1_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setEtodArea1LoadingRequest(false));
    toast.error(error.message);
  }
};

export const getEtodArea1Obstacles = () => (dispatch) => {
  try {
    dispatch(setLoadingArea1Obstacles(true));

    const onSuccess = (response) => {
      dispatch(setArea1Obstacles(response.data));
      dispatch(setLoadingArea1Obstacles(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingArea1Obstacles(false));
    };

    apiCall("GET", GET_ETOD_AREA_1_OBSTACLES_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingArea1Obstacles(false));
    toast.error(error.message);
  }
};

export const exportObstacleData = (type, airport, token) => (dispatch) => {
  try {
    dispatch(setLoadingExportObstacle(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".xls"
          : `${type}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingExportObstacle(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingExportObstacle(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_OBSTACLES_URL(airport?.id, type),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingExportObstacle(false));
    toast.error(error.message);
  }
};

export const getEtodLayers = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEtodLayers(true));

    const onSuccess = (response) => {
      dispatch(setEtodLayers(response.data));
      dispatch(setLoadingEtodLayers(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEtodLayers(false));
    };

    apiCall("GET", GET_ETOD_LAYER_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingEtodLayers(false));
    toast.error(error.message);
  }
};

export const getEtodAixmAirports = (token) => (dispatch) => {
  try {
    dispatch(setLoadingEtodAixmAirports(true));

    const onSuccess = (response) => {
      dispatch(setEtodAixmAirports(response.data));
      dispatch(setLoadingEtodAixmAirports(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEtodAixmAirports(false));
    };

    apiCall("GET", GET_ETOD_AIXM_AIRPORT_URL, "", onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingEtodAixmAirports(false));
    toast.error(error.message);
  }
};

export const generateEtodAirportLayer =
  (airportId, type, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingGenerateEtodAirport(true));

      const onSuccess = (response) => {
        dispatch(setLoadingGenerateEtodAirport(false));
        dispatch(getEtodAixmAirports(token));
        toast.success("Airport generated successfully");
        callback(response.status);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingGenerateEtodAirport(false));
      };

      apiCall(
        "POST",
        GENERATE_ETOD_AIXM_AIRPORT_LAYER_URL(airportId, type),
        "",
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingGenerateEtodAirport(false));
      toast.error(error.message);
    }
  };

export const getEtodDems = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEtodDems(true));

    const onSuccess = (response) => {
      dispatch(setEtodDems(response.data));
      dispatch(setLoadingEtodDems(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEtodDems(false));
    };

    apiCall("GET", ETOD_DEMS_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingEtodDems(false));
    toast.error(error.message);
  }
};

export const getAnalysisObstacle = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingAnalysisObstacles(true));

    const onSuccess = (response) => {
      dispatch(setAnalysisObstacles(response.data));
      dispatch(setLoadingAnalysisObstacles(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAnalysisObstacles(false));
    };

    apiCall(
      "GET",
      GET_OBSTACLE_ANALYSIS_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAnalysisObstacles(false));
    toast.error(error.message);
  }
};

export const postAnalysisObstacle =
  (airportId, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingProcessObstacleAnalysis(true));

      const onSuccess = (response) => {
        callback(true);
        dispatch(setAnalysisObstacles(response.data));
        dispatch(setLoadingProcessObstacleAnalysis(false));
      };
      const onFailure = (error) => {
        callback(false);
        toast.error(error.message);
        dispatch(setLoadingProcessObstacleAnalysis(false));
      };

      apiCall(
        "POST",
        POST_OBSTACLE_ANALYSIS_URL(airportId),
        "",
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      callback(false);
      dispatch(setLoadingProcessObstacleAnalysis(false));
      toast.error(error.message);
    }
  };

export const uploadeObstacleFileWithoutReview =
  (airportId, file, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingUploadObstacleAnalysis(true));

      const onSuccess = (response) => {
        dispatch(setAnalysisObstacles(response.data));
        dispatch(setLoadingUploadObstacleAnalysis(false));
        callback(true);
      };
      const onFailure = (error) => {
        dispatch(setLoadingUploadObstacleAnalysis(false));
        toast.error(error?.message || "Upload failed");
        callback(false);
      };

      fileUploadApicall(
        UPLOAD_OBSTACLE_ANALYSIS_URL(airportId),
        file,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingUploadObstacleAnalysis(false));
      callback(false);
    }
  };

export const uploadeTodDemFile =
  (airportId, file, token, successCallback) => (dispatch) => {
    try {
      dispatch(setUplodeeTodDemFileRequest(true));

      const onSuccess = () => {
        toast.success("Successfully Uploaded");
        dispatch(setUplodeeTodDemFileRequest(false));
        successCallback();
      };
      const onFailure = () => {
        dispatch(setUplodeeTodDemFileRequest(false));
        toast.error("Upload failed");
      };

      fileUploadApicall(
        ETOD_DEMS_URL(airportId),
        file,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setUplodeeTodDemFileRequest(false));
      toast.error("Upload failed");
    }
  };

export const exportAreaOlsData = (airport, type, token) => (dispatch) => {
  try {
    dispatch(setLoadingExportAreaOls(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        `${airport.name}(${airport.icao})(${type}).zip`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingExportAreaOls(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingExportAreaOls(false));
    };

    downloadReadableStreamFile(
      "GET",
      ETOD_AREA_OLS_EXPORT_URL(airport?.id, type),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingExportAreaOls(false));
    toast.error(error.message);
  }
};

export const exportDemFiles = async (airport, etodDems, token) => {
  const updateHeader = headers;
  updateHeader["Authorization"] = token;
  const promises = etodDems.map((dem) => {
    const formData = {
      method: "GET",
      headers: updateHeader,
      signal: Timeout(100).signal,
    };

    return fetch(
      GET_GEOSERVER_LAYER_DOWNALOLD_URL(dem.dem_gs_layer_name),
      formData,
      500
    )
      .then((response) => {
        return response
          .blob()
          .then((blob) => {
            const newBlob = new Blob([blob]);
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute(
              "download",
              `${airport.icao}(${dem.rwy || ""}_${dem.rwy_dir || ""}_${
                dem.sub_category
              })(DEM).zip`
            );
            document.body.appendChild(link);
            link.click();

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);

            return blob;
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        return error;
      });
  });

  return Promise.all(promises)
    .then(() => {})
    .catch(() => {
      toast.error("Download failed");
    });
};

export const postEtodAirportCreateLayer =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingAirportCreatelayers(true));

      const onSuccess = (response) => {
        callback(response.data?.id, true);
        dispatch(
          setCreatelayerAirportData({
            id: response.data?.id,
            isGenerateSurface: true,
            airport_name: payload.airport_name,
            arp_latitude: payload.arp_latitude,
            arp_longitude: payload.arp_longitude,
            rwys: payload.rwys,
          })
        );
        dispatch(setLoadingAirportCreatelayers(false));
      };
      const onFailure = (error) => {
        callback(false);
        toast.error(error.message);
        dispatch(setLoadingAirportCreatelayers(false));
      };

      apiCall(
        "POST",
        ETOD_AIRPOT_CREATE_LAYERS_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      callback(false);
      dispatch(setLoadingAirportCreatelayers(false));
      toast.error(error.message);
    }
  };

export const getEtodAirportLayer = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingAirportlayer(true));

    const onSuccess = (response) => {
      dispatch(setLoadingAirportlayer(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirportlayer(false));
    };

    apiCall(
      "GET",
      GET_ETOD_AIRPOT_LAYER_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingAirportlayer(false));
    toast.error(error.message);
  }
};

export const deleteEtodAirportLayer = (airportId, token) => (dispatch) => {
  try {
    dispatch(setLoadingDeleteAirportlayer(true));

    const onSuccess = (response) => {
      dispatch(setLoadingDeleteAirportlayer(false));
    };
    const onFailure = () => {
      dispatch(setLoadingDeleteAirportlayer(false));
    };

    apiCall(
      "DELETE",
      DELETE_ETOD_AIRPOT_LAYER_URL(airportId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingDeleteAirportlayer(false));
  }
};

export const exportAirportLayersData =
  (airportId, type, arpName, token) => (dispatch) => {
    try {
      dispatch(setLoadingExportAirportLayers(true));

      const onSuccess = (blob) => {
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `${arpName}-(${type}).zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up url
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setLoadingExportAirportLayers(false));
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingExportAirportLayers(false));
      };

      downloadReadableStreamFile(
        "GET",
        EXPORT_ETOD_AIRPOT_LAYER_URL(airportId, type),
        "",
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingExportAirportLayers(false));
      toast.error(error.message);
    }
  };
