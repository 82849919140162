import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import rootReducer from "./rootReducer";

const storeSelectedUser = createWhitelistFilter("userSession", [
  "rsaKey",
  "user",
]);
const storeSelectedaEtodAirport = createWhitelistFilter("etod", [
  "etodAixmAirports",
]);

const persistConfig = {
  key: "root_aai",
  storage,
  blacklist: ["airport", "airspace", "amdb", "aixm", "survey", "eChart"],
  transforms: [
    storeSelectedaEtodAirport,
    storeSelectedUser,
  ],
};

const middlewares = [];

if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export const persistor = persistStore(store);
export default store;
