import { createSelector } from "@reduxjs/toolkit";
import { chain, remove, orderBy } from "lodash";
import { eChartAirportList } from "@constants/constant";
import { eChart } from "./slice";

export const {
  setLoadingAlleChartAirport,
  setAlleChartAirports,
  setLoadingechartPDFData,
  setechartPDFData,
  setLoadingechartData,
  setechartData,
  setLoadingEditDeletePolyline,
  setLoadingAlleChartExportUrl,
  setAlleChartExportUrls,
  setLoadingATOCAData,
  setATOCAData,
  setLoadingEchartTableData,
  setTableData,
} = eChart.actions;

export default eChart.reducer;

export const selecteChartAirports = (state) => {
  const eChartAirports = state.eChart.alleChartAirports;

  if (eChartAirports && eChartAirports.length > 0) {
    return chain(eChartAirports)
      .filter((airport) => {
        return eChartAirportList.includes(airport.name);
      })
      .orderBy(["name"], ["asc"])
      .value();
  }

  return [];
};

export const selecteChartPDFData = (state) => {
  const echartPDFData = state.eChart.echartPDFData;

  if (echartPDFData && echartPDFData.length > 0) {
    return echartPDFData;
  }

  return [];
};

export const selecteChartData = (state) => {
  const echartData = state.eChart.echartData;

  if (!echartData) {
    return null;
  }

  return echartData;
};

export const selecteChartOptions = (state) => {
  const echartData = state.eChart.echartData;

  if (!echartData) {
    return [];
  }

  const echartOptions = [];

  Object.keys(echartData).forEach((echart) => {
    echartOptions.push({
      label: echart,
      value: echart,
    });
  });

  remove(echartOptions, (o) => o.value === "grids");

  return echartOptions;
};

const selectChartType = (state, type) => type;
const selecteAllChartTableData = (state, type) => state.eChart.chartTableData;
export const selecteChartTableData = createSelector(
  [selecteAllChartTableData, selectChartType],
  (tableData, type) => {
    if (tableData && tableData[type]) {
      const data = tableData[type];
      return orderBy(data, ["id"], ["asc"]);
    }

    return [];
  }
);
