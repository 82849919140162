import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as XLSX from "xlsx";
import { FaBell } from "react-icons/fa";
import { Typography, Stack, Snackbar, Button, Badge } from "@mui/material";
import { ManageDataIcon } from "@assets/svg/ManageDataIcon";
import { ManageUserIcon } from "@assets/svg/ManageUserIcon";
import { ReviewDataIcon } from "@assets/svg/ReviewDataIcon";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import {
  surveyCategory,
  surveyComapareTableHeaderName,
} from "@constants/surveyorData";
import { firData } from "@constants/dataManagement.constant";
import { routeNames } from "@constants/pageRoutes.constants";
import { PrimaryButton } from "@components/button";
import StyledButton from "@components/button/StyledButton";
import DataManagementSideBar from "@components/DataManagementSideBar";
import Header from "@components/Header";
import NavButton from "@components/NavButton";
import ApproachPreviewTable from "@components/surveyor-tables/ApproachPreviewTable";
import ApproachTable from "@components/surveyor-tables/ApproachTable";
import DataCompareTable from "@components/surveyor-tables/DataCompareTable";
import ObjectsPreviewDataTable from "@components/surveyor-tables/ObjectsPreviewDataTable";
import ObstacleCompareTable from "@components/surveyor-tables/ObstacleCompareTable";
import ObstaclePreviewDataTable from "@components/surveyor-tables/ObstaclePreviewDataTable";
import PreviewDataTable from "@components/surveyor-tables/PreviewDataTable";
import VerticalSignificanceTable from "@components/surveyor-tables/VerticalSignificanceTable";
import TableSheetHeaderRow from "@components/surveyor-tables/TableSheetHeaderRow";
import TopTableHeaderRow from "@components/surveyor-tables/TopTableHeaderRow";
import VerticalSignificancePreviewTable from "@components/surveyor-tables/VerticalSignificancePreviewTable";
import { setAAIAirport, setSelctedAirportFormCategories } from "@store/airport";
import { setCompareData, setUploadFileData } from "@store/survey";
import {
  downloadCategoryTemplate,
  uploadSurveyFile,
  getReviewFeaturesdata,
  getAllRequestPassword,
  comparedata as comparedataAction,
  getSurveyorAirport,
} from "@store/survey/actions";
import { generateCRC32Q } from "@store/userSession/actions";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";
import { getAsyncStorageValue } from "@utils/localStorage";
import { sheetValidation } from "@utils/sheetValidation";

export default function UserDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userSession.user);
  const isAdmin = currentUser?.role === roles.admin;
  const isAAI = currentUser?.role === roles.aai;
  const isSurveyor = currentUser?.role === roles.surveyor;
  const {
    isUploadSurveyFile,
    isLoadingCompareData,
    isSurveyorAirportLoading,
    surveyorAirportData,
    uploadFileData,
    comparedata,
    isLoadingGetallReqPassword,
    allReqPassword,
  } = useSelector((state) => state.survey);
  const [selctedCategory, setCategory] = useState(null);
  const [selctedAirport, setAirport] = useState(null);
  const [uploaLocalFileData, setUploaLocalFileData] = useState(null);
  const [uploadLocalFile, setUploadLocalFile] = useState(null);
  const [CRCcode, setCRCcode] = useState(null);
  const [isOpenCrcPopup, setOpenCrcPopup] = useState(false);

  useEffect(() => {
    if (!CRCcode) return;
    setOpenCrcPopup(true);
  }, [CRCcode]);

  useEffect(() => {
    dispatch(setUploadFileData(null));
    dispatch(setCompareData(null));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (!isAdmin) {
        dispatch(getReviewFeaturesdata(token));
      }
      if (isSurveyor) {
        dispatch(getSurveyorAirport(token));
      }
      if (isAdmin) {
        dispatch(getAllRequestPassword(token));
      }
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (surveyorAirportData.length === 0) {
      return;
    }
    setAirport(surveyorAirportData[0]);
  }, [surveyorAirportData]);

  const handleCategory = (option) => {
    if (option === null) {
      setCategory(null);
    } else {
      setCategory(option);
    }

    setUploaLocalFileData(null);
    setUploadLocalFile(null);
    dispatch(setUploadFileData(null));
    dispatch(setCompareData(null));
  };

  const handleTemplateDownload = (template) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(downloadCategoryTemplate(template, token));
    });
  };

  const handleSuccessFileUploadCallback = (reportData) => {
    setUploadLocalFile(null);
    setUploaLocalFileData(null);

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        comparedataAction(
          selctedAirport.id,
          selctedCategory.value,
          currentUser.userId,
          reportData.report_id,
          token
        )
      );
    });
  };

  const handleFileUpload = (event, ref) => {
    event.preventDefault();

    const files = event.target.files;
    const size = (files[0].size / 1024 / 1024).toFixed(2);
    setUploadLocalFile(files);

    if (size > 10) {
      toast.error("File should be less than 10 MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws);
      const sheetCoulmns = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const validation = sheetValidation(selctedCategory.value, sheetCoulmns);

      if (validation.isValid) {
        setUploaLocalFileData(dataParse);
        dispatch(
          generateCRC32Q(files[0], (crcCode) => {
            setCRCcode(crcCode);
          })
        );
      } else {
        setUploaLocalFileData(null);
        setUploadLocalFile(null);
        toast.error(validation.message);
      }
    };

    reader.readAsBinaryString(files[0]);
  };

  const handleFileUploadSubmit = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        uploadSurveyFile(
          selctedCategory.value,
          selctedAirport.id,
          CRCcode,
          uploadLocalFile[0],
          token,
          handleSuccessFileUploadCallback
        )
      );
    });
  };

  const handleNavigateAD2dataentry = () => {
    navigate(routeNames.AD2DataEntry);
    dispatch(setAAIAirport(null));
    dispatch(setSelctedAirportFormCategories(null));
  };

  const handleCRC = () => {
    navigate(routeNames.CRCCheck);
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div className="main-content">
        {isSurveyor && (
          <DataManagementSideBar
            isUploadSurveyFile={isUploadSurveyFile}
            isLoadingCompareData={isLoadingCompareData}
            isActiveAirport={!selctedAirport}
            selctedCategory={selctedCategory}
            isActiveCategory={!selctedCategory}
            handleCategory={handleCategory}
            handleTemplateDownload={handleTemplateDownload}
            handleFileUpload={handleFileUpload}
          />
        )}
        <div className="main" style={!isSurveyor ? styles.mainNosurveyor : {}}>
          {isAdmin && (
            <Fragment>
              <div className="content-area ">
                <div className="admin-home">
                  <div style={styles.adminWelcomeLabel}>
                    <Typography
                      variant="h3"
                      component="div"
                      sx={{ fontWeight: 600 }}
                    >
                      WELCOME TO
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ fontWeight: 600, fontSize: "24px" }}
                    >
                      Admin Portal
                    </Typography>
                  </div>
                  <Stack direction="row" sx={{ mb: 9 }}>
                    <NavButton
                      isShowLeftIndicator
                      icon={
                        <ManageUserIcon color={theme.palette.black.light} />
                      }
                      label="MANAGE USER"
                      onClick={() => {
                        navigate(routeNames.ManageUsers);
                      }}
                    />
                    <NavButton
                      isShowLeftIndicator
                      icon={<ReviewDataIcon />}
                      label="REVIEW DATASETS"
                      onClick={() => {
                        navigate(
                          "/data-management/review/surveyor-report-list"
                        );
                      }}
                    />
                    <NavButton
                      isShowLeftIndicator
                      icon={<ManageDataIcon className="manage-data-icon" />}
                      label="CREATE NEW AIRPORT"
                      onClick={() => navigate(routeNames.NewAirport)}
                    />
                  </Stack>
                  <div className="admin-dashboard-footer">
                    <PrimaryButton
                      variant="outlined"
                      sx={{ width: 192, p: "2.75px 15px" }}
                      label="Check CRC32Q"
                      onClick={handleCRC}
                    />
                    <Badge
                      badgeContent={allReqPassword.length}
                      sx={styles.badgeStyle}
                    >
                      <PrimaryButton
                        variant="outlined"
                        sx={{ width: 192, ml: 3, p: "2.75px 15px" }}
                        label="Password Requests"
                        onClick={() => navigate(routeNames.NewPassReq)}
                        startIcon={<FaBell />}
                        disabled={
                          isLoadingGetallReqPassword ||
                          allReqPassword.length === 0
                        }
                        isLoading={isLoadingGetallReqPassword}
                      />
                    </Badge>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {isAAI && (
            <div className="content-dashboard-area ">
              <div className="welcome-aai-area">
                <Typography
                  variant="h3"
                  component="div"
                  sx={{ fontWeight: 600 }}
                >
                  WELCOME TO
                </Typography>
                {selctedAirport && (
                  <Typography variant="subtitle2" component="div">
                    {selctedAirport?.name}
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  sx={styles.aaiUserDashboard}
                  component="div"
                >
                  {firData[currentUser?.fir]}
                </Typography>
                <Typography
                  sx={styles.aaiUserDashboard}
                  variant="subtitle2"
                  component="div"
                >
                  AAI Portal
                </Typography>
                {isSurveyorAirportLoading && (
                  <Typography variant="subtitle1" component="div">
                    Loading...
                  </Typography>
                )}
                <div className="ad2-data-text" align="center">
                  <Typography
                    component="div"
                    sx={{ fontSize: 24, fontWeight: 600 }}
                  >
                    To enter AD 2 Data, please click on enter data below
                  </Typography>
                </div>
                <div style={{ marginBottom: "118px" }}>
                  <StyledButton
                    label="Enter AD 2 Data"
                    style={styles.UserDashboardBtn}
                    onClick={() => handleNavigateAD2dataentry()}
                  />
                </div>
                <div>
                  <PrimaryButton
                    variant="outlined"
                    sx={{ width: 192, p: "2.75px 15px" }}
                    label="Check CRC32Q"
                    onClick={handleCRC}
                  />
                  <Badge badgeContent={"0"} sx={styles.badgeStyle}>
                    <PrimaryButton
                      variant="outlined"
                      sx={{ width: 192, ml: 3, p: "2.75px 15px" }}
                      label="Data Re-Validation"
                      onClick={() => {}}
                      disabled
                      isLoading={false}
                    />
                  </Badge>
                </div>
              </div>
            </div>
          )}
          {isSurveyor && (
            <div className="content-area ">
              {!uploaLocalFileData && !uploadFileData && (
                <div className="welcome-area">
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{ fontWeight: 600 }}
                  >
                    WELCOME TO
                  </Typography>
                  {selctedAirport && (
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ fontSize: 24, fontWeight: 600 }}
                    >
                      {selctedAirport?.name}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ fontSize: 24, fontWeight: 600 }}
                  >
                    Surveyor Portal
                  </Typography>
                  {isSurveyorAirportLoading && (
                    <Typography variant="subtitle1" component="div">
                      Loading...
                    </Typography>
                  )}
                </div>
              )}
              {isLoadingCompareData && (
                <div className="welcome-area">
                  <Typography variant="subtitle2" component="div">
                    Comapring Data...
                  </Typography>
                </div>
              )}
              {uploaLocalFileData && uploaLocalFileData.length > 0 && (
                <div className="surveyor-category-table white-bg">
                  <TopTableHeaderRow />
                  <TableSheetHeaderRow
                    selctedAirport={selctedAirport}
                    selctedCategory={selctedCategory}
                  />
                  {selctedCategory?.value === surveyCategory.approach && (
                    <ApproachPreviewTable data={uploaLocalFileData} />
                  )}
                  {selctedCategory?.value === surveyCategory.objects && (
                    <ObjectsPreviewDataTable data={uploaLocalFileData} />
                  )}
                  {selctedCategory?.value ===
                    surveyCategory.vertical_significance && (
                    <VerticalSignificancePreviewTable
                      data={uploaLocalFileData}
                    />
                  )}
                  {selctedCategory?.value ===
                    surveyCategory.objectsObstacles && (
                    <ObstaclePreviewDataTable data={uploaLocalFileData} />
                  )}
                  {selctedCategory?.value !==
                    surveyCategory.vertical_significance &&
                    selctedCategory?.value !== surveyCategory.approach &&
                    selctedCategory?.value !== surveyCategory.objects &&
                    selctedCategory?.value !==
                      surveyCategory.objectsObstacles && (
                      <PreviewDataTable
                        data={uploaLocalFileData}
                        selctedCategory={selctedCategory}
                      />
                    )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ pr: 2, pt: 2, pb: 2 }}
                  >
                    <PrimaryButton
                      label="Submit"
                      isLoading={isUploadSurveyFile}
                      disabled={isUploadSurveyFile}
                      onClick={handleFileUploadSubmit}
                      sx={{ width: 200 }}
                    />
                  </Stack>
                </div>
              )}
              {uploadFileData && comparedata && !isLoadingCompareData && (
                <div className="surveyor-category-table white-bg">
                  {selctedCategory?.value === surveyCategory.approach && (
                    <ApproachTable
                      rows={comparedata || []}
                      selctedAirport={selctedAirport}
                      selctedCategory={selctedCategory}
                    />
                  )}
                  {selctedCategory?.value !==
                    surveyCategory.vertical_significance &&
                    selctedCategory?.value !==
                      surveyCategory.objectsObstacles &&
                    selctedCategory?.value !== surveyCategory.approach && (
                      <DataCompareTable
                        isLoading={isUploadSurveyFile || isLoadingCompareData}
                        rows={comparedata || []}
                        selctedAirport={selctedAirport}
                        selctedCategory={selctedCategory}
                        headerName={
                          surveyComapareTableHeaderName[selctedCategory?.value]
                        }
                      />
                    )}
                  {selctedCategory?.value ===
                    surveyCategory.vertical_significance && (
                    <VerticalSignificanceTable
                      rows={comparedata || []}
                      selctedAirport={selctedAirport}
                      selctedCategory={selctedCategory}
                    />
                  )}
                  {selctedCategory?.value ===
                    surveyCategory.objectsObstacles && (
                    <ObstacleCompareTable
                      rows={comparedata || []}
                      selctedAirport={selctedAirport}
                      selctedCategory={selctedCategory}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={isOpenCrcPopup}
        autoHideDuration={6000}
        message={`CRC32Q code is ${CRCcode}`}
        onClose={() => setOpenCrcPopup(false)}
        action={
          <CopyToClipboard text={CRCcode}>
            <Button color="inherit" size="small">
              Copy
            </Button>
          </CopyToClipboard>
        }
      />
    </div>
  );
}
