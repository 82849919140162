import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { PrimaryButtonV2 } from "./button";

export default function EChartExportButton({
  label,
  selectedEchart,
  isLoading,
  isDisabled,
  handleExport,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNonADCClick = () => {
    if (!isDisabled) handleExport(selectedEchart, "full");
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExport(selectedEchart, item);
  };

  return (
    <>
      {selectedEchart !== "ADC" && (
        <PrimaryButtonV2
          label={label}
          isLoading={isLoading}
          disabled={
            isLoading ||
            !selectedEchart ||
            selectedEchart === "ATOCA" ||
            isDisabled
          }
          onClick={handleNonADCClick}
        />
      )}
      {selectedEchart === "ADC" && (
        <PrimaryButtonV2
          label={label}
          id="export-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          isLoading={isLoading}
          disabled={isLoading || !selectedEchart || isDisabled}
          onClick={handleClick}
        />
      )}
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "export-button",
        }}
      >
        <MenuItem onClick={() => handleOnExport("full")}>Full Chart</MenuItem>
        <MenuItem onClick={() => handleOnExport("grids")}>Chose Grid</MenuItem>
      </Menu>
    </>
  );
}
