import { createSlice } from "@reduxjs/toolkit";
import { orderBy } from "lodash";

const initialState = {
  isLoadingAirport: false,
  isLoadingGeneral: false,
  isLoadingAD1Aerodrome: false,
  isExportingSheet: false,
  allAirports: [],
  isLoadingGetAixmDataVersion: false,
  aixmDataVersions: [],
  selectedVersion: null,
  allGeneral: [],
  allEnroute: null,
  AD1Aerodrome: [],
  AD1_3Aerodrome: [],
  AD1_5Aerodrome: [],
  isLoadingAD1_3Aerodrome: false,
  isLoadingAD1_5Aerodrome: false,
};

export const aixm = createSlice({
  name: "aixm",
  initialState,
  reducers: {
    setAirportLoadingRequest: (state, action) => {
      state.isLoadingAirport = action.payload;
    },
    setExportSheetRequest: (state, action) => {
      state.isExportingSheet = action.payload;
    },
    setAllAirport: (state, action) => {
      state.allAirports = orderBy(action.payload, "name", "asc");
    },
    setLoadingGetAixmDataVersion: (state, action) => {
      state.isLoadingGetAixmDataVersion = action.payload;
    },
    setAixmDataVersions: (state, action) => {
      state.aixmDataVersions = orderBy(action.payload, "id", "desc");
      const versions = orderBy(action.payload, ["id"], "desc");
      state.selectedVersion = versions[0];
    },
    setSelectedVersion: (state, action) => {
      state.selectedVersion = action.payload;
    },
    setGeneralLoadingRequest: (state, action) => {
      state.isLoadingGeneral = action.payload;
    },
    setAllGeneral: (state, action) => {
      state.allGeneral = action.payload;
    },
    setAD1Aerodrome: (state, action) => {
      state.AD1Aerodrome = action.payload;
    },
    setLoadingAD1Aerodrome: (state, action) => {
      state.isLoadingAD1Aerodrome = action.payload;
    },
    setAD1_3Aerodrome: (state, action) => {
      state.AD1_3Aerodrome = action.payload;
    },
    setAD1_5Aerodrome: (state, action) => {
      state.AD1_5Aerodrome = action.payload;
    },
    setLoadingAD1_3Aerodrome: (state, action) => {
      state.isLoadingAD1_3Aerodrome = action.payload;
    },
    setLoadingAD1_5Aerodrome: (state, action) => {
      state.isLoadingAD1_5Aerodrome = action.payload;
    },
    setEnrouteLoadingRequest: (state, action) => {
      state.isLoadingEnroute = action.payload;
    },
    setAllEnroute: (state, action) => {
      state.allEnroute = action.payload;
    },
  },
});
