import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

function MeteorologicalInfo({
  selctedAirport,
  activeCategory,
  meteorologicalinfo,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport?.name} {">"} {activeCategory?.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {meteorologicalinfo ? (
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Name of the associated meteorological office
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.name ? meteorologicalinfo.name : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Hours of service and, where applicable, the designation of the
                  responsible meteorological office outside these hours
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.hours ? meteorologicalinfo.hours : ""}
                  {meteorologicalinfo.hours_remarks
                    ? ` - ${meteorologicalinfo.hours_remarks}`
                    : ""}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Office responsible for preparation of TAFs and periods of
                  validity and interval of issuance of the forecasts
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.office
                    ? meteorologicalinfo.office
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Availability of the trend forecast for the aerodrome and
                  interval of issuance
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.forecast
                    ? meteorologicalinfo.forecast
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Information on how briefing and/or consultation is provided
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.briefing
                    ? meteorologicalinfo.briefing
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Types of flight documentation supplied and language(s) used in
                  flight documentation
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.flight_documentation
                    ? meteorologicalinfo.flight_documentation
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Charts and other information displayed or available for
                  briefing or consultation
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.charts
                    ? meteorologicalinfo.charts
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Supplementary equipment available for providing information on
                  meteorological conditions, e.g. weather radar and receiver for
                  satellite images
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.sup_met
                    ? meteorologicalinfo.sup_met
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  The air traffic services unit(s) provided with meteorological
                  information
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.air_traffic
                    ? meteorologicalinfo.air_traffic
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: 450 }}>
                  Additional information, e.g. concerning any limitation of
                  service.
                </StyledTableCell>
                <StyledTableCell>
                  {meteorologicalinfo?.remarks
                    ? meteorologicalinfo.remarks
                    : "NIL"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default MeteorologicalInfo;
