import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { find, isEmpty } from "lodash";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import { EditIcon } from "@assets/svg/edit";
import { LightButton, PrimaryButton } from "@components/button";
import SelectInput from "@components/core/SelectInput";
import StyledTableCell, {
  StyledTableCellV2,
} from "@components/tabel-component/StyledTableCell";
import { LightTooltip } from "@components/core/LightTooltip";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd212ValidationSchema,
  Ad212SurfaceTypes,
  Ad212PavementTypes,
  Ad212EveluationTypes,
  Ad212MaxTyrePressureTypes,
  Ad212PavementSubgradeTypes,
} from "@constants/ad2Data";
import {
  AD212CreateFormInitialValue,
  AD212EditFormInitialValue,
} from "@constants/ad2DataForm.constant";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad212RunWayDetailForm({
  selectedAirport,
  selectedCategory,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );
  const [editRWYId, setEditRWYId] = useState(null);
  const [isOpenEditModal, setOpenEditModal] = useState(false);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "runwayphysicals",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd212RWYForm.resetForm();
    createAd212RWYForm.setSubmitting(false);
    handleGetADCallBack();
  };

  const createAd212RWYForm = useFormik({
    enableReinitialize: true,
    initialValues: AD212CreateFormInitialValue,
    validationSchema: createAd212ValidationSchema,
    onSubmit: (values) => {
      const defaultData = {
        nominalLength: values.nominalLength ? `${values.nominalLength}M` : "",
        nominalWidth: values.nominalWidth ? `${values.nominalWidth}M` : "",
        lengthStrip: values.lengthStrip ? `${values.lengthStrip}M` : "",
        widthStrip: values.widthStrip ? `${values.widthStrip}M` : "",
        lengthAccuracy: values.lengthAccuracy
          ? `${values.lengthAccuracy}M`
          : "",
        widthAccuracy: values.widthAccuracy ? `${values.widthAccuracy}M` : "",
        widthShoulder: values.widthShoulder ? values.widthShoulder : "",
        composition: values.composition ? values.composition : "",
        classPCN: values.classPCN ? values.classPCN : "",
        pavementTypePCN: values.pavementTypePCN ? values.pavementTypePCN : "",
        pavementSubgradePCN: values.pavementSubgradePCN
          ? values.pavementSubgradePCN
          : "",
        maxTyrePressurePCN: values.maxTyrePressurePCN
          ? values.maxTyrePressurePCN
          : "",
        evaluationMethodPCN: values.evaluationMethodPCN
          ? values.evaluationMethodPCN
          : "",
      };
      if (values.runways && values.runways.length > 0) {
        const rd = values.runways.map((rwy) => {
          return {
            designator: rwy.designator ? String(rwy.designator) : "",
            trueBearing: rwy.trueBearing ? `${rwy.trueBearing}DEG` : "",
            thr_lat: rwy.thr_lat,
            thr_lng: rwy.thr_lng,
            thr_el: rwy.thr_el ? `${rwy.thr_el}FT` : "",
            end_lat: rwy.end_lat,
            end_lng: rwy.end_lng,
            tdz_el: rwy.tdz_el ? `${rwy.tdz_el}FT` : "",
            slopeTDZ: rwy.slopeTDZ ? `${rwy.slopeTDZ}%` : "",
            resalength: rwy.resalength ? `${rwy.resalength}M` : "",
            resawidth: rwy.resawidth ? `${rwy.resawidth}M` : "",
            stopwaylength: rwy.stopwaylength ? `${rwy.stopwaylength}M` : "",
            stopwaywidth: rwy.stopwaywidth ? `${rwy.stopwaywidth}M` : "",
            clearwaylength: rwy.clearwaylength ? `${rwy.clearwaylength}M` : "",
            clearwaywidth: rwy.clearwaywidth ? `${rwy.clearwaywidth}M` : "",
            ofz: rwy.ofz === "yes" ? true : false,
            remarks: rwy.remarks ? rwy.remarks : "",
          };
        });
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addAD2AAIAirportDetail(
              "runwayphysicals",
              {
                ...defaultData,
                rd,
              },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                handleAddSuccessCallback();
              }
            )
          );
        });
      }
    },
  });

  /* Edit Runway */
  const handleEditRow = (data) => {
    const nominalLength = data?.nominalLength
      ? Number(data?.nominalLength.replace("M", "").trim())
      : "";
    const nominalWidth = data?.nominalWidth
      ? Number(data?.nominalWidth.replace("M", "").trim())
      : "";
    const lengthStrip = data?.lengthStrip
      ? Number(data?.lengthStrip.replace("M", "").trim())
      : "";
    const widthStrip = data?.widthStrip
      ? Number(data?.widthStrip.replace("M", "").trim())
      : "";
    const widthShoulder = data?.widthShoulder
      ? Number(data?.widthShoulder.replace("M", "").trim())
      : "";
    const clearwaylength = data?.clearwaylength
      ? Number(data?.clearwaylength.replace("M", "").trim())
      : "";
    const clearwaywidth = data?.clearwaywidth
      ? Number(data?.clearwaywidth.replace("M", "").trim())
      : "";
    const lengthAccuracy = data?.lengthAccuracy
      ? Number(data?.lengthAccuracy.replace("M", "").trim())
      : "";
    const widthAccuracy = data?.widthAccuracy
      ? Number(data?.widthAccuracy.replace("M", "").trim())
      : "";
    const resalength = data?.resalength
      ? Number(data?.resalength.replace("M", "").trim())
      : "";
    const resawidth = data?.resawidth
      ? Number(data?.resawidth.replace("M", "").trim())
      : "";
    const stopwaylength = data?.stopwaylength
      ? Number(data?.stopwaylength.replace("M", "").trim())
      : "";
    const stopwaywidth = data?.stopwaywidth
      ? Number(data?.stopwaywidth.replace("M", "").trim())
      : "";
    const slopeTDZ = data?.slopeTDZ
      ? Number(data?.slopeTDZ.replace("%", "").trim())
      : "";
    const tdz_el = data?.tdz_el
      ? Number(data?.tdz_el.replace("FT", "").trim())
      : "";
    const thr_el = data?.thr_el
      ? Number(data?.thr_el.replace("FT", "").trim())
      : "";
    const trueBearing = data?.trueBearing
      ? Number(data?.trueBearing.replace("DEG", "").trim())
      : "";
    const ofz = data?.ofz ? "yes" : "no";

    editAd212RWYForm.setValues({
      nominalLength: nominalLength ? nominalLength : "",
      nominalWidth: nominalWidth ? nominalWidth : "",
      lengthStrip: lengthStrip ? lengthStrip : "",
      widthStrip: widthStrip ? widthStrip : "",
      widthShoulder: widthShoulder ? widthShoulder : "",
      lengthAccuracy: lengthAccuracy ? lengthAccuracy : "",
      widthAccuracy: widthAccuracy ? widthAccuracy : "",
      composition: data.composition ? data.composition : "",
      classPCN: data.classPCN ? data.classPCN : "",
      pavementTypePCN: data.pavementTypePCN ? data.pavementTypePCN : "",
      pavementSubgradePCN: data.pavementSubgradePCN
        ? data.pavementSubgradePCN
        : "",
      maxTyrePressurePCN: data.maxTyrePressurePCN
        ? data.maxTyrePressurePCN
        : "",
      evaluationMethodPCN: data.evaluationMethodPCN
        ? data.evaluationMethodPCN
        : "",
      clearwaylength: clearwaylength ? clearwaylength : "",
      clearwaywidth: clearwaywidth ? clearwaywidth : "",
      resalength: resalength ? resalength : "",
      resawidth: resawidth ? resawidth : "",
      stopwaylength: stopwaylength ? stopwaylength : "",
      stopwaywidth: stopwaywidth ? stopwaywidth : "",
      slopeTDZ: slopeTDZ ? slopeTDZ : "",
      tdz_el: tdz_el ? tdz_el : "",
      thr_el: thr_el ? thr_el : "",
      trueBearing: trueBearing ? trueBearing : "",
      designator: data.designator ? data.designator : "",
      thr_lat: data.thr_lat ? data.thr_lat : "",
      thr_lng: data.thr_lng ? data.thr_lng : "",
      end_lat: data.end_lat ? data.end_lat : "",
      end_lng: data.end_lng ? data.end_lng : "",
      ofz: ofz ? ofz : "",
      remarks: data.remarks ? data.remarks : "",
    });
  };

  const editAd212RWYForm = useFormik({
    enableReinitialize: true,
    initialValues: AD212EditFormInitialValue,
    validationSchema: createAd212ValidationSchema,
    onSubmit: (values) => {
      const defaultData = {
        nominalLength: values.nominalLength ? `${values.nominalLength}M` : "",
        nominalWidth: values.nominalWidth ? `${values.nominalWidth}M` : "",
        lengthStrip: values.lengthStrip ? `${values.lengthStrip}M` : "",
        widthStrip: values.widthStrip ? `${values.widthStrip}M` : "",
        lengthAccuracy: values.lengthAccuracy
          ? `${values.lengthAccuracy}M`
          : "",
        widthAccuracy: values.widthAccuracy ? `${values.widthAccuracy}M` : "",
        widthShoulder: values.widthShoulder ? values.widthShoulder : "",
        composition: values.composition ? values.composition : "",
        classPCN: values.classPCN ? values.classPCN : "",
        pavementTypePCN: values.pavementTypePCN ? values.pavementTypePCN : "",
        pavementSubgradePCN: values.pavementSubgradePCN
          ? values.pavementSubgradePCN
          : "",
        maxTyrePressurePCN: values.maxTyrePressurePCN
          ? values.maxTyrePressurePCN
          : "",
        evaluationMethodPCN: values.evaluationMethodPCN
          ? values.evaluationMethodPCN
          : "",
        rdId: editRWYId,
        trueBearing: values.trueBearing ? `${values.trueBearing}DEG` : "",
        thr_lat: values.thr_lat,
        thr_lng: values.thr_lng,
        thr_el: values.thr_el ? `${values.thr_el}FT` : "",
        end_lat: values.end_lat,
        end_lng: values.end_lng,
        tdz_el: values.tdz_el ? `${values.tdz_el}FT` : "",
        slopeTDZ: values.slopeTDZ ? `${values.slopeTDZ}%` : "",
        resalength: values.resalength ? `${values.resalength}M` : "",
        resawidth: values.resawidth ? `${values.resawidth}M` : "",
        stopwaylength: values.stopwaylength ? `${values.stopwaylength}M` : "",
        stopwaywidth: values.stopwaywidth ? `${values.stopwaywidth}M` : "",
        clearwaylength: values.clearwaylength
          ? `${values.clearwaylength}M`
          : "",
        clearwaywidth: values.clearwaywidth ? `${values.clearwaywidth}M` : "",
        ofz: values.ofz === "yes" ? true : false,
      };
      if (editRWYId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "runwayphysicals",
              defaultData,
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                handleAddSuccessCallback();
                setOpenEditModal(false);
                setEditRWYId(null);
              }
            )
          );
        });
      }
    },
  });

  const handleResetEditForm = () => {
    editAd212RWYForm.resetForm();
    editAd212RWYForm.setSubmitting(false);
  };

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        {!isEmpty(AAIAirport) && (
          <Box sx={{ p: 2, mb: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={styles.ad212RwyDetailLabel}>
                    Designations
                  </StyledTableCell>
                  <StyledTableCell sx={styles.ad212RwyDetailLabel}>
                    TRUE Bearings
                  </StyledTableCell>
                  <StyledTableCell sx={styles.ad212RwyDetailLabel}>
                    Dimensions of RWY (M)
                  </StyledTableCell>
                  <StyledTableCell sx={styles.ad212RwyDetailLabel} />
                </TableRow>
              </TableHead>
              <TableBody>
                {AAIAirport.map((rwy, index) => {
                  return (
                    <TableRow key={index}>
                      <StyledTableCellV2>
                        {rwy.designator ? rwy.designator : "NIL"}
                      </StyledTableCellV2>
                      <StyledTableCellV2>
                        {rwy.trueBearing ? rwy.trueBearing : "NIL"}
                      </StyledTableCellV2>
                      <StyledTableCellV2>
                        {rwy.nominalLength ? rwy.nominalLength : ""}
                        {rwy.nominalWidth ? ` x ${rwy.nominalWidth}` : "-"}
                      </StyledTableCellV2>
                      <StyledTableCell>
                        <LightTooltip title="Edit">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              setOpenEditModal(true);
                              setEditRWYId(rwy.id);
                              handleEditRow(rwy);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </LightTooltip>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
        <Box sx={{ pl: 2, pb: 1, color: theme.palette.primary.main,pt:1 }}>
          Runway
        </Box>
        <Divider />
        <FormikProvider value={createAd212RWYForm}>
          <form>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Nominal Length</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="nominalLength"
                  value={createAd212RWYForm.values.nominalLength}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.nominalLength &&
                    Boolean(createAd212RWYForm.errors?.nominalLength)
                  }
                  helperText={createAd212RWYForm.errors?.nominalLength || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Nominal Width</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="nominalWidth"
                  value={createAd212RWYForm.values.nominalWidth}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.nominalWidth &&
                    Boolean(createAd212RWYForm.errors?.nominalWidth)
                  }
                  helperText={createAd212RWYForm.errors?.nominalWidth || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Length Strip</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="lengthStrip"
                  value={createAd212RWYForm.values.lengthStrip}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.lengthStrip &&
                    Boolean(createAd212RWYForm.errors?.lengthStrip)
                  }
                  helperText={createAd212RWYForm.errors?.lengthStrip || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Width Strip</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="widthStrip"
                  value={createAd212RWYForm.values.widthStrip}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.widthStrip &&
                    Boolean(createAd212RWYForm.errors?.widthStrip)
                  }
                  helperText={createAd212RWYForm.errors?.widthStrip || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Length Accuracy</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="lengthAccuracy"
                  value={createAd212RWYForm.values.lengthAccuracy}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.lengthAccuracy &&
                    Boolean(createAd212RWYForm.errors?.lengthAccuracy)
                  }
                  helperText={createAd212RWYForm.errors?.lengthAccuracy || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Width Accuracy</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="widthAccuracy"
                  value={createAd212RWYForm.values.widthAccuracy}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.widthAccuracy &&
                    Boolean(createAd212RWYForm.errors?.widthAccuracy)
                  }
                  helperText={createAd212RWYForm.errors?.widthAccuracy || ""}
                  sx={{ mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Width Shoulder</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="widthShoulder"
                  value={createAd212RWYForm.values.widthShoulder}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.widthShoulder &&
                    Boolean(createAd212RWYForm.errors?.widthShoulder)
                  }
                  helperText={createAd212RWYForm.errors?.widthShoulder || ""}
                  sx={{ mt: 2, mb: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">M</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Composition</Box>
              <Box sx={styles.adInput}>
                <SelectInput
                  name="composition"
                  value={find(Ad212SurfaceTypes, {
                    value: createAd212RWYForm.values.composition,
                  })}
                  onChange={(option) => {
                    createAd212RWYForm.setFieldValue(
                      "composition",
                      option.value
                    );
                  }}
                  options={Ad212SurfaceTypes}
                  aria-describedby="aerodrome-composition"
                  error={
                    createAd212RWYForm.touched?.composition &&
                    Boolean(createAd212RWYForm.errors?.composition)
                  }
                />
                <FormHelperText
                  id="aerodrome-composition"
                  sx={{ color: "#d32f2f", ml: 1.8 }}
                >
                  {createAd212RWYForm.touched?.composition &&
                    createAd212RWYForm.errors?.composition}
                </FormHelperText>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%", pt: 1 }}>Class PCN</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  name="classPCN"
                  value={createAd212RWYForm.values.classPCN}
                  onChange={createAd212RWYForm.handleChange}
                  error={
                    createAd212RWYForm.touched?.classPCN &&
                    Boolean(createAd212RWYForm.errors?.classPCN)
                  }
                  helperText={createAd212RWYForm.errors?.classPCN || ""}
                  sx={{ mb: 2, mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">NUM</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Pavement Type PCN</Box>
              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                <SelectInput
                  name="pavementTypePCN"
                  value={find(Ad212PavementTypes, {
                    value: createAd212RWYForm.values.pavementTypePCN,
                  })}
                  onChange={(option) => {
                    createAd212RWYForm.setFieldValue(
                      "pavementTypePCN",
                      option.value
                    );
                  }}
                  options={Ad212PavementTypes}
                  aria-describedby="aerodrome-pavementTypePCN"
                />
                <FormHelperText
                  id="aerodrome-pavementTypePCN"
                  sx={{ color: "#d32f2f", ml: 1.8 }}
                >
                  {createAd212RWYForm.touched?.pavementTypePCN &&
                    createAd212RWYForm.errors?.pavementTypePCN}
                </FormHelperText>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Pavement Subgrade PCN</Box>
              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                <SelectInput
                  name="pavementSubgradePCN"
                  value={find(Ad212PavementSubgradeTypes, {
                    value: createAd212RWYForm.values.pavementSubgradePCN,
                  })}
                  onChange={(option) => {
                    createAd212RWYForm.setFieldValue(
                      "pavementSubgradePCN",
                      option.value
                    );
                  }}
                  options={Ad212PavementSubgradeTypes}
                  aria-describedby="aerodrome-pavementSubgradePCN"
                />
                <FormHelperText
                  id="aerodrome-pavementSubgradePCN"
                  sx={{ color: "#d32f2f", ml: 1.8 }}
                >
                  {createAd212RWYForm.touched?.pavementSubgradePCN &&
                    createAd212RWYForm.errors?.pavementSubgradePCN}
                </FormHelperText>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Max Tyre Pressure PCN</Box>
              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                <SelectInput
                  name="maxTyrePressurePCN"
                  value={find(Ad212MaxTyrePressureTypes, {
                    value: createAd212RWYForm.values.maxTyrePressurePCN,
                  })}
                  onChange={(option) => {
                    createAd212RWYForm.setFieldValue(
                      "maxTyrePressurePCN",
                      option.value
                    );
                  }}
                  options={Ad212MaxTyrePressureTypes}
                  aria-describedby="aerodrome-maxTyrePressurePCN"
                />
                <FormHelperText
                  id="aerodrome-maxTyrePressurePCN"
                  sx={{ color: "#d32f2f", ml: 1.8 }}
                >
                  {createAd212RWYForm.touched?.maxTyrePressurePCN &&
                    createAd212RWYForm.errors?.maxTyrePressurePCN}
                </FormHelperText>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Evaluation Method PCN</Box>
              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                <SelectInput
                  name="evaluationMethodPCN"
                  value={find(Ad212EveluationTypes, {
                    value: createAd212RWYForm.values.evaluationMethodPCN,
                  })}
                  onChange={(option) => {
                    createAd212RWYForm.setFieldValue(
                      "evaluationMethodPCN",
                      option.value
                    );
                  }}
                  options={Ad212EveluationTypes}
                  aria-describedby="aerodrome-evaluationMethodPCN"
                />
                <FormHelperText
                  id="aerodrome-evaluationMethodPCN"
                  sx={{ color: "#d32f2f", ml: 1.8 }}
                >
                  {createAd212RWYForm.touched?.evaluationMethodPCN &&
                    createAd212RWYForm.errors?.evaluationMethodPCN}
                </FormHelperText>
              </Box>
            </Stack>
            <Box sx={{ pl: 2, pt: 2, minWidth: "260px" }}>
              <FieldArray
                name="runways"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {createAd212RWYForm.values.runways.map((_, index) => {
                        const isTouched = createAd212RWYForm.touched?.runways
                          ? createAd212RWYForm.touched?.runways[index]
                          : false;
                        const isErrors = createAd212RWYForm.errors?.runways
                          ? createAd212RWYForm.errors?.runways[index]
                          : false;

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pr: 2 }}
                            >
                              <Box sx={styles.adFuelMain}>
                                <Typography
                                  variant="span"
                                  sx={styles.adFuelLabel}
                                >
                                  Runway Direction {index + 1}
                                </Typography>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Designations</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].designator`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .designator
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .designator &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .designator
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].designator
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>TRUE Bearings</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].trueBearing`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .trueBearing
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .trueBearing &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .trueBearing
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].trueBearing
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        DEG
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>THR Lattitude</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`runways[${index}].thr_lat`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .thr_lat
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .thr_lat &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .thr_lat
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].thr_lat
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>THR Longitude</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`runways[${index}].thr_lng`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .thr_lng
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .thr_lng &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .thr_lng
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].thr_lng
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>END Lattitude</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`runways[${index}].end_lat`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .end_lat
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .end_lat &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .end_lat
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].end_lat
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>END Longitude</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`runways[${index}].end_lng`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .end_lng
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .end_lng &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .end_lng
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].end_lng
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>THR Elevation</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].thr_el`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .thr_el
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .thr_el &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .thr_el
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].thr_el
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>TDZ Elevation</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].tdz_el`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .tdz_el
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .tdz_el &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .tdz_el
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].tdz_el
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Slope TDZ</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].slopeTDZ`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .slopeTDZ
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .slopeTDZ &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .slopeTDZ
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].slopeTDZ
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Runway end safety areas Length(resaLength)
                              </Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].resalength`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .resalength
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .resalength &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .resalength
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].resalength
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Runway end safety areas width(resawidth)
                              </Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].resawidth`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .resawidth
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .resawidth &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .resawidth
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].resawidth
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Stopway Length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].stopwaylength`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .stopwaylength
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .stopwaylength &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .stopwaylength
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].stopwaylength
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Stopway width</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].stopwaywidth`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .stopwaywidth
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .stopwaywidth &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .stopwaywidth
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].stopwaywidth
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Clearway length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].clearwaylength`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .clearwaylength
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .clearwaylength &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .clearwaylength
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].clearwaylength
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Clearway width</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`runways[${index}].clearwaywidth`}
                                  value={
                                    createAd212RWYForm.values.runways[index]
                                      .clearwaywidth
                                  }
                                  onChange={createAd212RWYForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .clearwaywidth &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .clearwaywidth
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].clearwaywidth
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Existing of an Obstacle Free Zone
                              </Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  sx={{ mb: 2 }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .ofz &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .ofz
                                    )
                                  }
                                >
                                  <MuiSelect
                                    name={`runways[${index}].ofz`}
                                    value={
                                      createAd212RWYForm.values.runways[index]
                                        .ofz
                                    }
                                    onChange={createAd212RWYForm.handleChange}
                                  >
                                    <MenuItem key="yes" value="yes">
                                      Yes
                                    </MenuItem>
                                    <MenuItem key="no" value="no">
                                      No
                                    </MenuItem>
                                  </MuiSelect>
                                  <FormHelperText>
                                    {isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].ofz
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, width: "100%" }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd212RWYForm.touched?.runways[index]
                                      .remarks &&
                                    Boolean(
                                      createAd212RWYForm.errors?.runways[index]
                                        .remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    name={`runways[${index}].remarks`}
                                    value={
                                      createAd212RWYForm.values.runways[index]
                                        .remarks
                                    }
                                    onChange={createAd212RWYForm.handleChange}
                                    aria-describedby="remarks-textarea"
                                  />
                                  <FormHelperText id="remarks-textarea">
                                    {isErrors && isTouched
                                      ? createAd212RWYForm.errors?.runways[
                                          index
                                        ].remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </Box>
          </form>
        </FormikProvider>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd212RWYForm.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd212RWYForm.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail}
          sx={{ width: 200 }}
        />
      </Stack>
      {isOpenEditModal && (
        <Modal
          open={isOpenEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.ad210EditForm}>
            <Typography sx={{ borderBottom: "0.5px solid #c7cccc" }}>
              Edit Runway Details
            </Typography>
            <Box sx={{ height: "550px", overflow: "auto" }}>
              <form>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Nominal Length</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="nominalLength"
                      value={editAd212RWYForm.values.nominalLength}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.nominalLength &&
                        Boolean(editAd212RWYForm.errors?.nominalLength)
                      }
                      helperText={editAd212RWYForm.errors?.nominalLength || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Nominal Width</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="nominalWidth"
                      value={editAd212RWYForm.values.nominalWidth}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.nominalWidth &&
                        Boolean(editAd212RWYForm.errors?.nominalWidth)
                      }
                      helperText={editAd212RWYForm.errors?.nominalWidth || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Length Strip</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="lengthStrip"
                      value={editAd212RWYForm.values.lengthStrip}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.lengthStrip &&
                        Boolean(editAd212RWYForm.errors?.lengthStrip)
                      }
                      helperText={editAd212RWYForm.errors?.lengthStrip || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Width Strip</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="widthStrip"
                      value={editAd212RWYForm.values.widthStrip}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.widthStrip &&
                        Boolean(editAd212RWYForm.errors?.widthStrip)
                      }
                      helperText={editAd212RWYForm.errors?.widthStrip || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Length Accuracy</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="lengthAccuracy"
                      value={editAd212RWYForm.values.lengthAccuracy}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.lengthAccuracy &&
                        Boolean(editAd212RWYForm.errors?.lengthAccuracy)
                      }
                      helperText={editAd212RWYForm.errors?.lengthAccuracy || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Width Accuracy</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="widthAccuracy"
                      value={editAd212RWYForm.values.widthAccuracy}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.widthAccuracy &&
                        Boolean(editAd212RWYForm.errors?.widthAccuracy)
                      }
                      helperText={editAd212RWYForm.errors?.widthAccuracy || ""}
                      sx={{ mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Width Shoulder</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="widthShoulder"
                      value={editAd212RWYForm.values.widthShoulder}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.widthShoulder &&
                        Boolean(editAd212RWYForm.errors?.widthShoulder)
                      }
                      helperText={editAd212RWYForm.errors?.widthShoulder || ""}
                      sx={{ mt: 2, mb: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Composition</Box>
                  <Box sx={{ width: "65%" }}>
                    <SelectInput
                      name="composition"
                      value={find(Ad212SurfaceTypes, {
                        value: editAd212RWYForm.values.composition,
                      })}
                      onChange={(option) => {
                        editAd212RWYForm.setFieldValue(
                          "composition",
                          option.value
                        );
                      }}
                      options={Ad212SurfaceTypes}
                      aria-describedby="aerodrome-composition"
                      error={
                        editAd212RWYForm.touched?.composition &&
                        Boolean(editAd212RWYForm.errors?.composition)
                      }
                    />
                    <FormHelperText
                      id="aerodrome-composition"
                      sx={{ color: "#d32f2f", ml: 1.8 }}
                    >
                      {editAd212RWYForm.touched?.composition &&
                        editAd212RWYForm.errors?.composition}
                    </FormHelperText>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%", pt: 1 }}>Class PCN</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="classPCN"
                      value={editAd212RWYForm.values.classPCN}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.classPCN &&
                        Boolean(editAd212RWYForm.errors?.classPCN)
                      }
                      helperText={editAd212RWYForm.errors?.classPCN || ""}
                      sx={{ mb: 2, mt: 2 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">NUM</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Pavement Type PCN</Box>
                  <Box sx={{ width: "65%", mb: 2 }}>
                    <SelectInput
                      name="pavementTypePCN"
                      value={find(Ad212PavementTypes, {
                        value: editAd212RWYForm.values.pavementTypePCN,
                      })}
                      onChange={(option) => {
                        editAd212RWYForm.setFieldValue(
                          "pavementTypePCN",
                          option.value
                        );
                      }}
                      options={Ad212PavementTypes}
                      aria-describedby="aerodrome-pavementTypePCN"
                    />
                    <FormHelperText
                      id="aerodrome-pavementTypePCN"
                      sx={{ color: "#d32f2f", ml: 1.8 }}
                    >
                      {editAd212RWYForm.touched?.pavementTypePCN &&
                        editAd212RWYForm.errors?.pavementTypePCN}
                    </FormHelperText>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Pavement Subgrade PCN</Box>
                  <Box sx={{ width: "65%", mb: 2 }}>
                    <SelectInput
                      name="pavementSubgradePCN"
                      value={find(Ad212PavementSubgradeTypes, {
                        value: editAd212RWYForm.values.pavementSubgradePCN,
                      })}
                      onChange={(option) => {
                        editAd212RWYForm.setFieldValue(
                          "pavementSubgradePCN",
                          option.value
                        );
                      }}
                      options={Ad212PavementSubgradeTypes}
                      aria-describedby="aerodrome-pavementSubgradePCN"
                    />
                    <FormHelperText
                      id="aerodrome-pavementSubgradePCN"
                      sx={{ color: "#d32f2f", ml: 1.8 }}
                    >
                      {editAd212RWYForm.touched?.pavementSubgradePCN &&
                        editAd212RWYForm.errors?.pavementSubgradePCN}
                    </FormHelperText>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Max Tyre Pressure PCN</Box>
                  <Box sx={{ width: "65%", mb: 2 }}>
                    <SelectInput
                      name="maxTyrePressurePCN"
                      value={find(Ad212MaxTyrePressureTypes, {
                        value: editAd212RWYForm.values.maxTyrePressurePCN,
                      })}
                      onChange={(option) => {
                        editAd212RWYForm.setFieldValue(
                          "maxTyrePressurePCN",
                          option.value
                        );
                      }}
                      options={Ad212MaxTyrePressureTypes}
                      aria-describedby="aerodrome-maxTyrePressurePCN"
                    />
                    <FormHelperText
                      id="aerodrome-maxTyrePressurePCN"
                      sx={{ color: "#d32f2f", ml: 1.8 }}
                    >
                      {editAd212RWYForm.touched?.maxTyrePressurePCN &&
                        editAd212RWYForm.errors?.maxTyrePressurePCN}
                    </FormHelperText>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Evaluation Method PCN</Box>
                  <Box sx={{ width: "65%", mb: 2 }}>
                    <SelectInput
                      name="evaluationMethodPCN"
                      value={find(Ad212EveluationTypes, {
                        value: editAd212RWYForm.values.evaluationMethodPCN,
                      })}
                      onChange={(option) => {
                        editAd212RWYForm.setFieldValue(
                          "evaluationMethodPCN",
                          option.value
                        );
                      }}
                      options={Ad212EveluationTypes}
                      aria-describedby="aerodrome-evaluationMethodPCN"
                    />
                    <FormHelperText
                      id="aerodrome-evaluationMethodPCN"
                      sx={{ color: "#d32f2f", ml: 1.8 }}
                    >
                      {editAd212RWYForm.touched?.evaluationMethodPCN &&
                        editAd212RWYForm.errors?.evaluationMethodPCN}
                    </FormHelperText>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>TRUE Bearings</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="trueBearing"
                      value={editAd212RWYForm.values.trueBearing}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.trueBearing &&
                        Boolean(editAd212RWYForm.errors?.trueBearing)
                      }
                      helperText={editAd212RWYForm.errors?.trueBearing || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">DEG</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>THR Lattitude</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="thr_lat"
                      value={editAd212RWYForm.values.thr_lat}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.thr_lat &&
                        Boolean(editAd212RWYForm.errors?.thr_lat)
                      }
                      helperText={editAd212RWYForm.errors?.thr_lat || ""}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>THR Longitude</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="thr_lng"
                      value={editAd212RWYForm.values.thr_lng}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.thr_lng &&
                        Boolean(editAd212RWYForm.errors?.thr_lng)
                      }
                      helperText={editAd212RWYForm.errors?.thr_lng || ""}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>END Lattitude</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="end_lat"
                      value={editAd212RWYForm.values.end_lat}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.end_lat &&
                        Boolean(editAd212RWYForm.errors?.end_lat)
                      }
                      helperText={editAd212RWYForm.errors?.end_lat || ""}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>END Longitude</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="end_lng"
                      value={editAd212RWYForm.values.end_lng}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.end_lng &&
                        Boolean(editAd212RWYForm.errors?.end_lng)
                      }
                      helperText={editAd212RWYForm.errors?.end_lng || ""}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>THR Elevation</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="thr_el"
                      value={editAd212RWYForm.values.thr_el}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.thr_el &&
                        Boolean(editAd212RWYForm.errors?.thr_el)
                      }
                      helperText={editAd212RWYForm.errors?.thr_el || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">FT</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>TDZ Elevation</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="tdz_el"
                      value={editAd212RWYForm.values.tdz_el}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.tdz_el &&
                        Boolean(editAd212RWYForm.errors?.tdz_el)
                      }
                      helperText={editAd212RWYForm.errors?.tdz_el || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">FT</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Slope TDZ</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="slopeTDZ"
                      value={editAd212RWYForm.values.slopeTDZ}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.slopeTDZ &&
                        Boolean(editAd212RWYForm.errors?.slopeTDZ)
                      }
                      helperText={editAd212RWYForm.errors?.slopeTDZ || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>
                    Runway end safety areas Length(resaLength)
                  </Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="resalength"
                      value={editAd212RWYForm.values.resalength}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.resalength &&
                        Boolean(editAd212RWYForm.errors?.resalength)
                      }
                      helperText={editAd212RWYForm.errors?.resalength || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>
                    Runway end safety areas width(resawidth)
                  </Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="resawidth"
                      value={editAd212RWYForm.values.resawidth}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.resawidth &&
                        Boolean(editAd212RWYForm.errors?.resawidth)
                      }
                      helperText={editAd212RWYForm.errors?.resawidth || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Stopway Length</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="stopwaylength"
                      value={editAd212RWYForm.values.stopwaylength}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.stopwaylength &&
                        Boolean(editAd212RWYForm.errors?.stopwaylength)
                      }
                      helperText={editAd212RWYForm.errors?.stopwaylength || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Stopway width</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="stopwaywidth"
                      value={editAd212RWYForm.values.stopwaywidth}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.stopwaywidth &&
                        Boolean(editAd212RWYForm.errors?.stopwaywidth)
                      }
                      helperText={editAd212RWYForm.errors?.stopwaywidth || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Clearway length</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="clearwaylength"
                      value={editAd212RWYForm.values.clearwaylength}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.clearwaylength &&
                        Boolean(editAd212RWYForm.errors?.clearwaylength)
                      }
                      helperText={editAd212RWYForm.errors?.clearwaylength || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>Clearway width</Box>
                  <Box sx={{ width: "65%" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      type="number"
                      name="clearwaywidth"
                      value={editAd212RWYForm.values.clearwaywidth}
                      onChange={editAd212RWYForm.handleChange}
                      error={
                        editAd212RWYForm.touched?.clearwaywidth &&
                        Boolean(editAd212RWYForm.errors?.clearwaywidth)
                      }
                      helperText={editAd212RWYForm.errors?.clearwaywidth || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">M</InputAdornment>
                        ),
                      }}
                      sx={{ mb: 2, mt: 2 }}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ pl: 2, pr: 2 }}
                >
                  <Box sx={{ width: "35%" }}>
                    Existing of an Obstacle Free Zone
                  </Box>
                  <Box sx={{ width: "65%" }}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ mb: 2 }}
                      error={
                        editAd212RWYForm.touched?.ofz &&
                        Boolean(editAd212RWYForm.errors?.ofz)
                      }
                    >
                      <MuiSelect
                        name="ofz"
                        value={editAd212RWYForm.values.ofz}
                        onChange={editAd212RWYForm.handleChange}
                      >
                        <MenuItem key="yes" value="yes">
                          Yes
                        </MenuItem>
                        <MenuItem key="no" value="no">
                          No
                        </MenuItem>
                      </MuiSelect>
                      <FormHelperText>
                        {editAd212RWYForm.errors?.ofz || ""}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Stack>
              </form>
            </Box>
            <Box sx={styles.ad210SubCanBtn}>
              <LightButton
                label="Clear"
                sx={{ mr: 2 }}
                isLoading={false}
                onClick={() => handleResetEditForm()}
              />
              <PrimaryButton
                label="Submit"
                onClick={() => editAd212RWYForm.handleSubmit()}
                isLoading={isLoadingAddAAIAirportDetail}
                disabled={isLoadingAddAAIAirportDetail}
                sx={{ width: 100, mr: 2 }}
              />
              <PrimaryButton
                label="Cancel"
                variant="outlined"
                onClick={() => {
                  setOpenEditModal(false);
                  setEditRWYId(null);
                }}
                sx={{ width: 100 }}
              />
            </Box>
          </Box>
        </Modal>
      )}
    </Paper>
  );
}
