import React from "react";
import { theme } from "@styles/theme";

function UserSurfaceGen(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3333 43.3333C20.47 43.3333 10 53.8 10 66.6667C10 68.5067 11.49 70 13.3333 70C15.1767 70 16.6667 68.5067 16.6667 66.6667C16.6667 57.4767 24.1433 50 33.3333 50C42.5233 50 50 57.4767 50 66.6667C50 68.5067 51.49 70 53.3333 70C55.1767 70 56.6667 68.5067 56.6667 66.6667C56.6667 53.8 46.1967 43.3333 33.3333 43.3333ZM26.6667 23.3333C26.6667 27.01 29.6567 30 33.3333 30C37.01 30 40 27.01 40 23.3333C40 19.6567 37.01 16.6667 33.3333 16.6667C29.6567 16.6667 26.6667 19.6567 26.6667 23.3333ZM20 23.3333C20 15.98 25.98 10 33.3333 10C40.6867 10 46.6667 15.98 46.6667 23.3333C46.6667 30.6867 40.6867 36.6667 33.3333 36.6667C25.98 36.6667 20 30.6867 20 23.3333ZM72.5167 18.8533L63.8167 28.8533C63.1867 29.5767 62.28 29.9933 61.32 30H61.3C60.35 30 59.4467 29.5967 58.8167 28.89L54.1833 23.7133C52.9567 22.34 53.0733 20.2333 54.4433 19.0067C55.82 17.7767 57.9267 17.8933 59.15 19.2667L61.2633 21.63L67.4833 14.48C68.6933 13.0867 70.7967 12.9433 72.1867 14.1533C73.5767 15.36 73.7233 17.4667 72.5167 18.8533Z"
        fill={props.color || theme.palette.primary.main}
      />
    </svg>
  );
}

export default UserSurfaceGen;
