import { toast } from "react-toastify";
import {
  GET_AMDB_DATA_URL,
  GET_AMDB_AIRPORT_URL,
  EXPORT_GEO_JSON_DATA_URL,
  ADD_EDIT_DELETE_AMDB_CREATE_GEOMETRY_URL,
} from "@constants/urls";
import { apiCall, downloadReadableStreamFile } from "@utils/connect";
import {
  setAMDBDataLoadingRequest,
  setAMDBDetails,
  setLoadingAllAirportamdb,
  setAllamdbAirports,
  setExportGeoJSONLoading,
  setLoadingAddEditDeletePolygon,
} from ".";

export const getAMDBAirports = () => (dispatch) => {
  try {
    dispatch(setLoadingAllAirportamdb(true));

    const onSuccess = (response) => {
      dispatch(setAllamdbAirports(response.data));
      dispatch(setLoadingAllAirportamdb(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAllAirportamdb(false));
    };

    apiCall("GET", GET_AMDB_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAllAirportamdb(false));
    toast.error(error.message);
  }
};

export const getAMDBDetails = (airportId) => (dispatch) => {
  try {
    dispatch(setAMDBDataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAMDBDetails(response.data));
      dispatch(setAMDBDataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAMDBDataLoadingRequest(false));
    };

    apiCall("GET", GET_AMDB_DATA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setAMDBDataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const exportGeoJSONData = (type, airport, token) => (dispatch) => {
  try {
    dispatch(setExportGeoJSONLoading(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".json"
          : `$${airport.location_indicator}_${type}.json`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportGeoJSONLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportGeoJSONLoading(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_GEO_JSON_DATA_URL(type, airport?.id),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setExportGeoJSONLoading(false));
    toast.error(error.message);
  }
};

export const addEditDeleteAMDBCreateGeometry =
  (method, payload, airportId, geometryType, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingAddEditDeletePolygon(true));

      const onSuccess = () => {
        const onSuccessAmdb = (response) => {
          dispatch(setAMDBDetails(response.data));
          dispatch(setLoadingAddEditDeletePolygon(false));
          if (method === "POST") {
            toast.success("Created Succesfully");
          } else if (method === "PUT") {
            toast.success("Updated Succesfully");
          } else if (method === "DELETE") {
            toast.success("Deleted Succesfully");
          }
          callback();
        };
        const onFailureAmdb = (error) => {
          toast.error(error.message);
          dispatch(setLoadingAddEditDeletePolygon(false));
          callback();
        };

        apiCall(
          "GET",
          GET_AMDB_DATA_URL(airportId),
          "",
          onSuccessAmdb,
          onFailureAmdb
        );
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAddEditDeletePolygon(false));
      };

      apiCall(
        method,
        ADD_EDIT_DELETE_AMDB_CREATE_GEOMETRY_URL(airportId, geometryType),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingAddEditDeletePolygon(false));
      toast.error(error.message);
    }
  };
