import React from "react";

const UndoIcon = (props) => {
  return (
    <svg
      width={props.width || "12"}
      height={props.height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0761 8.29548C10.1819 8.18582 10.2067 8.02123 10.1378 7.8853L9.78936 7.19712C8.99696 5.63223 7.39191 4.64588 5.63784 4.64588H5.44509C5.43296 4.38179 5.41727 4.11781 5.39805 3.85403L5.36538 3.40589C5.33899 3.04387 4.93532 2.8415 4.62945 3.03694C3.61968 3.68216 2.74184 4.51355 2.04273 5.48678L1.82309 5.79253C1.73345 5.91732 1.73345 6.08541 1.82309 6.2102L2.04273 6.51595C2.74185 7.48918 3.61968 8.32057 4.62945 8.96579C4.93533 9.16123 5.33899 8.95886 5.36538 8.59684L5.39805 8.14869C5.42099 7.83391 5.43889 7.51884 5.45173 7.20362C6.48845 7.17323 7.52399 7.38226 8.47561 7.82147L9.66848 8.37202C9.80681 8.43586 9.97039 8.40515 10.0761 8.29548ZM9.02097 7.28469L8.77561 7.17145C7.61505 6.63581 6.34166 6.40967 5.0781 6.50672C4.89563 6.52074 4.75312 6.67012 4.74771 6.85305C4.73545 7.26787 4.71422 7.68251 4.68403 8.09665L4.68101 8.13815C3.8874 7.57797 3.192 6.88875 2.62417 6.09828L2.55455 6.00136L2.62417 5.90445C3.192 5.11398 3.8874 4.42476 4.68101 3.86458L4.68403 3.90608C4.71098 4.27576 4.73079 4.64582 4.74345 5.01607C4.75005 5.20889 4.90827 5.36179 5.1012 5.36179L5.63784 5.36179C7.03188 5.36179 8.31468 6.09806 9.02097 7.28469Z"
        fill={props.color || "#42444A"}
      />
    </svg>
  );
};

export default UndoIcon;
