import React, { useRef, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, LinearProgress, Switch } from "@mui/material";
import { UploadIcon } from "@assets/svg/UploadIcon";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import ETODAccordion from "@components/core/ETODAccordion";
import { eTodArea } from "@constants/etodData";
import { roles, ACCESS_TOKEN } from "@constants/constant";
import {
  exportAreaOlsData,
  exportDemFiles,
  uploadeTodDemFile,
  getEtodDems,
} from "@store/etod/actions";
import { styles } from "@styles/etodStyles";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import DropDownLabel from "./DropDownLabel";
import { LightButton } from "./button";

function AirportFeature({
  moduleClassname = "",
  isEtod = true,
  selectedAirport,
  selctedFeature,
  isActiveAirport,
  isAreaLoading,
  isLoadingEtodLayers,
  isEnabledFilter,
  isShowPenetrationObstacle,
  isLoadingAnalysisObstacles,
  isDisabled,
  isLoadingExportAreaOls,
  isLoadingEtodDems,
  isUplodeeTodDemFileRequest,
  selectedAirportFeature,
  analysisObstacles,
  areaOptions,
  selectedAreas,
  demOptions,
  etodDems,
  selectedDems,
  olsOptions,
  selectedOls,
  areaOptionStyles,
  olsOptionStyles,
  handleFeature,
  handlePenetrationObstacleToggle,
  handleSelectAll,
  handleClearAll,
  handleSelectAllArea,
  handleClearAllArea,
  handleParentAreaCheck,
  handleAreaCheck,
  handleSelectAllDems,
  handleClearAlldem,
  handleParentDemCheck,
  handleDemCheck,
  handleSelectAllOls,
  handleClearAllOls,
  handleParentOlsCheck,
  handleOlsCheck,
}) {
  const dispatch = useDispatch();
  const demInpurRef = useRef(null);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const currentUser = useSelector((state) => state.userSession.user);
  const [expanded, setExpanded] = useState("");
  const [isAllAreasCheck, setAllAreasCheck] = useState(false);
  const [isAllDemAreasCheck, setAllDemAreasCheck] = useState(false);
  const [isAllOlsCheck, setAllOlsCheck] = useState(false);
  const [isExportDemFiles, setExportDemFiles] = useState(false);
  const [selectedDemFile, setSelectedDemFile] = useState(null);

  const handleAirportFeature = (option) => {
    setAllAreasCheck(false);
    setAllDemAreasCheck(false);
    setAllOlsCheck(false);
    handleFeature(option);
  };

  const handleSelectAllOption = () => {
    handleSelectAll();
    if (selctedFeature && selctedFeature.value === eTodArea.area) {
      setAllOlsCheck(false);
      setAllAreasCheck(true);
      setAllDemAreasCheck(true);
    }
    if (selctedFeature && selctedFeature.value === eTodArea.ols) {
      setAllAreasCheck(false);
      setAllDemAreasCheck(false);
      setAllOlsCheck(true);
    }
  };

  const handleClearAllOption = () => {
    handleClearAll();
    setAllAreasCheck(false);
    setAllDemAreasCheck(false);
    setAllOlsCheck(false);
  };

  const handleExpandAccordain = (name) => {
    setExpanded(name);
  };

  const handleChangeSelectAllAreas = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      setAllAreasCheck(event.target.checked);
      handleSelectAllArea();
    } else {
      setAllAreasCheck(false);
      handleClearAllArea();
    }
  };

  const handleExportAreaOls = (type) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(exportAreaOlsData(selectedAirport, type, token));
    });
  };

  const handleChangeSelectAllDemAreas = (event) => {
    if (event.target.checked) {
      setAllDemAreasCheck(event.target.checked);
      handleSelectAllDems();
    } else {
      setAllDemAreasCheck(false);
      handleClearAlldem();
    }
  };

  const handleExportDem = async () => {
    setExportDemFiles(true);
    getAsyncStorageValue(ACCESS_TOKEN).then(async (token) => {
      await exportDemFiles(selectedAirport, etodDems, token);
      setExportDemFiles(false);
    });
  };

  const handleUploadDemFile = (event) => {
    event.preventDefault();

    const files = event.target.files;
    setSelectedDemFile(files[0]);
  };

  const handleChangeSelectAllOls = (event) => {
    if (event.target.checked) {
      setAllOlsCheck(event.target.checked);
      handleSelectAllOls();
    } else {
      setAllOlsCheck(false);
      handleClearAllOls();
    }
  };

  const handleUploadCallback = () => {
    dispatch(getEtodDems(selectedAirport.id));
  };

  const handleSaveUploadDem = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        uploadeTodDemFile(
          selectedAirport.id,
          selectedDemFile,
          token,
          handleUploadCallback
        )
      );

      setSelectedDemFile(null);
      demInpurRef.current.value = null;
    });
  };

  const handleCloseUploadDem = () => {
    setSelectedDemFile(null);
    demInpurRef.current.value = null;
  };

  return (
    <>
      <Box>
        <DropDownLabel label="Feature" isRequired />
        <Select
          isClearable
          isDisabled={
            isActiveAirport ||
            isAreaLoading ||
            selectedAirportFeature?.length === 0
          }
          isLoading={isAreaLoading}
          value={selctedFeature || null}
          placeholder="Select..."
          onChange={handleAirportFeature}
          options={selectedAirportFeature}
        />
      </Box>
      {isLoadingEtodLayers ? (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      ) : (
        <Divider sx={{ mt: 2, mb: 1 }} />
      )}
      {isEnabledFilter && (
        <div className="check-list-header">
          <Box sx={{ m: 0 }}>
            <Switch
              checked={isShowPenetrationObstacle}
              onChange={handlePenetrationObstacleToggle}
              disabled={
                isLoadingAnalysisObstacles || analysisObstacles?.length === 0
              }
            />
            {isLoadingAnalysisObstacles
              ? "Obstacle Loading..."
              : "Obstacle Penetration"}
          </Box>
        </div>
      )}
      {isEnabledFilter && (
        <div className="check-list-header">
          <div className="check-list-action">
            <LightButton
              label="SELECT ALL"
              onClick={handleSelectAllOption}
              disabled={isDisabled}
              sx={{ p: 0 }}
            />
            <LightButton
              label="CLEAR ALL"
              onClick={handleClearAllOption}
              disabled={isDisabled}
              sx={{ p: 0 }}
            />
          </div>
        </div>
      )}

      <div className={`check-list ${moduleClassname}`}>
        {selctedFeature && selctedFeature.value === eTodArea.area && (
          <div>
            <ETODAccordion
              title="Areas"
              isExpand={expanded === "areas"}
              isDisabled={isLoadingEtodLayers || areaOptions.length === 0}
              optionTrees={areaOptions}
              selectedOptions={selectedAreas}
              isDisabledOption={isDisabled}
              detailStyle={areaOptionStyles}
              handleExpandAccordain={() =>
                handleExpandAccordain(expanded === "areas" ? "" : "areas")
              }
              isAllChecked={isAllAreasCheck}
              handleSelectAll={(event) => handleChangeSelectAllAreas(event)}
              handleExport={() => handleExportAreaOls(eTodArea.area)}
              isLoadingExport={isLoadingExportAreaOls}
              handleParentCheckbox={(event, data) =>
                handleParentAreaCheck(event, data, data.color)
              }
              handleCheckbox={(data) => handleAreaCheck(data, data.color)}
            />
            <ETODAccordion
              title="DEM Areas"
              isExpand={expanded === "demAreas"}
              isWithNoColor
              isDisabled={isLoadingEtodDems || demOptions.length === 0}
              optionTrees={demOptions}
              selectedOptions={selectedDems}
              isDisabledOption={isDisabled}
              detailStyle={areaOptionStyles}
              handleExpandAccordain={() =>
                handleExpandAccordain(expanded === "demAreas" ? "" : "demAreas")
              }
              isAllChecked={isAllDemAreasCheck}
              handleSelectAll={(event) => handleChangeSelectAllDemAreas(event)}
              handleExport={() => handleExportDem()}
              isLoadingExport={isExportDemFiles}
              handleParentCheckbox={(event, data) =>
                handleParentDemCheck(event, data)
              }
              handleCheckbox={(data) => handleDemCheck(data)}
            />
            {isEtod &&
              currentUser &&
              accessToken &&
              currentUser.role === roles.admin && (
                <div style={styles.airportFeatureUpload}>
                  <Button
                    disabled={isUplodeeTodDemFileRequest}
                    sx={styles.airportFeatureUploadBtn}
                    startIcon={<UploadIcon />}
                  >
                    {isUplodeeTodDemFileRequest
                      ? "Loading..."
                      : "Upload DEM (.tif file)"}
                    <input
                      ref={demInpurRef}
                      style={styles.airportFeatureUploadDEM}
                      disabled={isUplodeeTodDemFileRequest}
                      onChange={handleUploadDemFile}
                      accept="image/tiff,.tif"
                      type="file"
                    />
                  </Button>
                </div>
              )}
          </div>
        )}
        {selctedFeature && selctedFeature.value === eTodArea.ols && (
          <div>
            <ETODAccordion
              title="OLS"
              isExpand={true}
              isDisabled={isLoadingEtodLayers || areaOptions.length === 0}
              isExpandIconEmpty
              optionTrees={olsOptions}
              selectedOptions={selectedOls}
              isDisabledOption={isDisabled}
              detailStyle={olsOptionStyles}
              handleExpandAccordain={() => {}}
              isAllChecked={isAllOlsCheck}
              handleSelectAll={(event) => handleChangeSelectAllOls(event)}
              handleExport={() => handleExportAreaOls(eTodArea.ols)}
              isLoadingExport={isLoadingExportAreaOls}
              handleParentCheckbox={(event, data) =>
                handleParentOlsCheck(event, data, data.color)
              }
              handleCheckbox={(data) => handleOlsCheck(data, data.color)}
            />
          </div>
        )}
      </div>
      {!!selectedDemFile && (
        <ConfirmationDialog
          fullWidth
          open={!!selectedDemFile}
          title="Upload DEM"
          content={`Are you sure you want to upload this file ${selectedDemFile.name}?`}
          saveButtonLabel="Upload"
          handleSave={handleSaveUploadDem}
          handleCancel={handleCloseUploadDem}
        />
      )}
    </>
  );
}

export default AirportFeature;
