import React from "react";
import { useSelector } from "react-redux";
import { RiLoader3Fill } from "react-icons/ri";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { DownloadBoldIcon } from "@assets/svg/download-bold";
import {  ACCESS_TOKEN } from "@constants/constant";
import { LightTooltip } from "@components/core/LightTooltip";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";
import {  getStorageValue } from "@utils/localStorage";

export default function AirportExportButton({
  items,
  selectedAirport,
  airport,
  isExportXMLAD2Loading,
  handleExportXML,
  handleExportAllXML,
}) {
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExportXML(item.value);
  };

  const handleOnAllExport = (items) => {
    handleClose();
    handleExportAllXML(items);
  };

  return (
    <>
      <LightTooltip title="Export">
        <IconButton
          aria-label="more"
          id="airport-download-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          color="primary"
          disabled={
            (selectedAirport?.id === airport.id && isExportXMLAD2Loading) ||
            !currentUser?.user_access?.export ||
            !accessToken
          }
          onClick={handleClick}
        >
          {selectedAirport?.id === airport.id && isExportXMLAD2Loading ? (
            <RiLoader3Fill size={25} />
          ) : (
            <DownloadBoldIcon
              disabled={!currentUser?.user_access?.export || !accessToken}
            />
          )}
        </IconButton>
      </LightTooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "airport-download-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleOnExport(item)}
            sx={{ color: theme.palette.grey.light, fontWeight: 500 }}
          >
            {item.label}
          </MenuItem>
        ))}
        <MenuItem
          sx={{ paddingTop: 0 }}
          onClick={() => handleOnAllExport(items)}
        >
          <Typography variant="span" sx={styles.airportExpDownload}>
            Download All
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
