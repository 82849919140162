import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTypographyV2 } from "@components/core/StyledTypography";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

export default function Geoadministrative({
  selctedAirport,
  activeCategory,
  geoadministrative,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2} sx={{backgroundColor:'red'}}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {geoadministrative ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Aerodrome reference point coordinates and its site
                </StyledTableCell>
                <StyledTableCell>
                  <Typography>
                    {`${geoadministrative.latitude} ${geoadministrative.longitude}`}
                  </Typography>
                  <Typography>
                    {geoadministrative.referencePointDescription}
                  </Typography>
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Direction and distance of aerodrome reference point from the
                  center of the city or town which the aerodrome serves
                </StyledTableCell>
                <StyledTableCell>
                  {geoadministrative.locationDescription}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Aerodrome elevation and reference temperature
                </StyledTableCell>
                <StyledTableCell>{`${geoadministrative.fieldElevation} /${geoadministrative.referenceTemperature}`}</StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Magnetic variation, date of information and annual change
                </StyledTableCell>
                <StyledTableCell>
                  {`${geoadministrative.magneticVariation} (${geoadministrative.dateMagneticVariation}) /${geoadministrative.magneticVariationChange}`}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Name of aerodrome operator, address, telephone, telefax,
                  e-mail address, AFS address, website (if available)
                </StyledTableCell>
                <StyledTableCell sx={{ p: 0 }}>
                  <Box sx={{ p: "6px 10px",pr:0 }}>
                    <Typography sx={styles.administrativeAuthority}>
                      {`${geoadministrative.authority_name}, ${geoadministrative.address}`}
                    </Typography>
                    <Box sx={styles.administrativePhone}>
                      <StyledTypographyV2 variant="span">
                        Telephone:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {geoadministrative.voice}
                      </Typography>
                    </Box>
                    <Box sx={styles.administrativePhone}>
                      <StyledTypographyV2 variant="span">
                        Fax:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {geoadministrative.facsimile}
                      </Typography>
                    </Box>
                    <Box sx={styles.administrativePhone}>
                      <StyledTypographyV2 variant="span">
                        AFS:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {geoadministrative.linkage}
                      </Typography>
                    </Box>
                    <Box sx={styles.administrativeEmail}>
                      <StyledTypographyV2 variant="span">
                        Email:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {geoadministrative.eMail}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Types of traffic permitted (IFR/VFR)
                </StyledTableCell>
                <StyledTableCell>
                  {geoadministrative.type_IFRVFR === "ALL"
                    ? "IFR/VFR"
                    : geoadministrative.type_IFRVFR}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>Remarks</StyledTableCell>
                <StyledTableCell>
                  {geoadministrative?.remark ? geoadministrative?.remark : "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}
