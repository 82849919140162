import React, { Fragment } from "react";
import { chain, flatten, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DOLSTakeOff({
  OLSTakeOffList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSTakeOffList = chain(OLSTakeOffList)
    .filter((takeOff) => {
      const name = `${takeOff.category}_${takeOff.rwy_dir}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleOLSTakeOffList.map((olsTol, i) => {
        const tolCoordinates = olsTol.coordinates.coordinates;
        const name = `${olsTol.category}_${olsTol.rwy_dir}`;
        const tolCoordinateMap = flatten(tolCoordinates).map((tolCoords) => {
          return tolCoords.map((tol) => {
            return [tol[0], tol[1], tol[2] * ZvalueMultiplyby];
          });
        });

        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(tolCoordinateMap)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
