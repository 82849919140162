import { capitalize, isEmpty } from "lodash";
import { userSession } from "./slice";
import { roles } from "@constants/constant";
import { firShortData } from "../../constants/dataManagement.constant";

export const {
  setRsaRequest,
  setLoginRequest,
  setLogoutRequest,
  setRSA,
  setUser,
  setRequestNewPassowrdLoading,
  setCRC32QGenerateRequest,
  setLoginCallbakUrl,
} = userSession.actions;

export default userSession.reducer;

export const getUserRegion = (state) => {
  const loginUser = state.userSession.user;
  const surveyorData = state.survey.surveyorAirportData;
  let data = "";
  if (!isEmpty(loginUser)) {
    const userRole = loginUser.role;
    if (userRole === roles.surveyor) {
      if (surveyorData && surveyorData.length > 0) {
        return surveyorData[0].name;
      }
      return userRole;
    } else if (userRole === roles.aai) {
      return firShortData[loginUser?.fir];
    } else if (userRole === roles.admin) {
      return capitalize(userRole);
    }
  }

  return data;
};
