import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useFormik } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd22tValidationSchema } from "@constants/ad2Data";
import { AD22initialValue } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";
import { LightButton, PrimaryButton } from "../button";

export default function Ad22Form({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(AAIAirport)) {
      handleCancelReadOnly(false);
      const fieldelv = AAIAirport?.fieldElevation
        ? AAIAirport?.fieldElevation.split(" ")[0]
        : "";
      const fieldelvAccuracy = AAIAirport?.fieldElevationAccuracy
        ? AAIAirport?.fieldElevationAccuracy.split(" ")[0]
        : "";
      const refTemp = AAIAirport?.referenceTemperature
        ? AAIAirport?.referenceTemperature.split(" ")[0]
        : "";
      const magVar = AAIAirport?.magneticVariation
        ? AAIAirport?.magneticVariation.split(" ")[0]
        : "";
      const annualChange = AAIAirport?.magneticVariationChange
        ? AAIAirport?.magneticVariationChange.split(" ")[0]
        : "";
      const magAccuracy = AAIAirport?.magneticVariationAccuracy
        ? AAIAirport?.magneticVariationAccuracy.split(" ")[0]
        : "";

      createAd22Form.setFieldValue(
        "site",
        AAIAirport?.referencePointDescription
          ? AAIAirport?.referencePointDescription
          : ""
      );
      createAd22Form.setFieldValue(
        "dir_distance_city",
        AAIAirport?.locationDescription ? AAIAirport?.locationDescription : ""
      );
      createAd22Form.setFieldValue("elevation", fieldelv);
      createAd22Form.setFieldValue("fieldElevationAccuracy", fieldelvAccuracy);
      createAd22Form.setFieldValue("temp", refTemp);
      createAd22Form.setFieldValue(
        "dateMagneticVariation",
        AAIAirport?.dateMagneticVariation
          ? AAIAirport?.dateMagneticVariation
          : ""
      );
      createAd22Form.setFieldValue("magneticVariation", magVar);
      createAd22Form.setFieldValue("magneticVariationAccuracy", magAccuracy);
      createAd22Form.setFieldValue("magneticVariationChange", annualChange);
      createAd22Form.setFieldValue(
        "traffic_type",
        AAIAirport?.type_IFRVFR ? AAIAirport?.type_IFRVFR : ""
      );
      createAd22Form.setFieldValue(
        "verticalDatum",
        AAIAirport?.verticalDatum ? AAIAirport?.verticalDatum : ""
      );
      createAd22Form.setFieldValue(
        "authority_name",
        AAIAirport?.authority_name ? AAIAirport?.authority_name : ""
      );
      createAd22Form.setFieldValue(
        "address",
        AAIAirport?.address ? AAIAirport?.address : ""
      );
      createAd22Form.setFieldValue(
        "telephone",
        AAIAirport?.voice ? AAIAirport?.voice : ""
      );
      createAd22Form.setFieldValue(
        "fax",
        AAIAirport?.facsimile ? AAIAirport?.facsimile : ""
      );
      createAd22Form.setFieldValue(
        "afs",
        AAIAirport?.linkage ? AAIAirport?.linkage : ""
      );
      createAd22Form.setFieldValue(
        "email",
        AAIAirport?.eMail ? AAIAirport?.eMail : ""
      );
      createAd22Form.setFieldValue(
        "remark",
        AAIAirport?.remark ? AAIAirport?.remark : ""
      );
    } else {
      handleCancelReadOnly(true);
    }
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "geoadministrative",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd22Form.resetForm();
    createAd22Form.setSubmitting(false);
  };

  const createAd22Form = useFormik({
    enableReinitialize: true,
    initialValues: AD22initialValue,
    validationSchema: createAd22tValidationSchema,
    onSubmit: (values) => {
      const payload = {
        address: values.address,
        authority_name: values.authority_name,
        dateMagneticVariation: values.dateMagneticVariation,
        eMail: values.email,
        facsimile: values.fax,
        fieldElevation: values.elevation + " FT",
        fieldElevationAccuracy: values.elevation + " m",
        latitude: selectedAirport.latitude,
        linkage: values.afs,
        locationDescription: values.dir_distance_city,
        longitude: selectedAirport.longitude,
        magneticVariation: values.magneticVariation + " DEG W",
        magneticVariationAccuracy: values.magneticVariationAccuracy + " DEG",
        magneticVariationChange: values.magneticVariationChange + " DEG E",
        referencePointDescription: values.site,
        referenceTemperature: values.temp + " DEG C",
        type_IFRVFR: values.traffic_type,
        verticalDatum: values.verticalDatum,
        voice: values.telephone,
      };

      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "geoadministrative",
            payload,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Aerodrome reference point coordinates
            </Box>
            <Box sx={{ pl: 2, minWidth: "260px" }}>
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                variant="outlined"
                size="small"
                id="coordinates"
                name="coordinates"
                value={`${selectedAirport.latitude}, ${selectedAirport.longitude}`}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Aerodrome reference point site</Box>
            <Box sx={styles.adInput}>
              <TextField
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                fullWidth
                variant="outlined"
                size="small"
                id="site"
                name="site"
                value={createAd22Form.values.site}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.site &&
                  Boolean(createAd22Form.errors.site)
                }
                helperText={
                  createAd22Form.touched.site && createAd22Form.errors.site
                }
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Direction and distance of aerodrome reference point from the
              center of the city or town which the aerodrome serves
            </Box>
            <Box sx={styles.adInput}>
              <TextField
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                fullWidth
                variant="outlined"
                size="small"
                id="dir_distance_city"
                name="dir_distance_city"
                value={createAd22Form.values.dir_distance_city}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.dir_distance_city &&
                  Boolean(createAd22Form.errors.dir_distance_city)
                }
                helperText={
                  createAd22Form.touched.dir_distance_city &&
                  createAd22Form.errors.dir_distance_city
                }
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Aerodrome elevation</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="elevation"
                name="elevation"
                type="number"
                value={createAd22Form.values.elevation}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.elevation &&
                  Boolean(createAd22Form.errors.elevation)
                }
                helperText={
                  createAd22Form.touched.elevation &&
                  createAd22Form.errors.elevation
                }
                sx={{ mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">FT</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Aerodrome Field Elevation Accuracy</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="fieldElevationAccuracy"
                name="fieldElevationAccuracy"
                type="number"
                value={createAd22Form.values.fieldElevationAccuracy}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.fieldElevationAccuracy &&
                  Boolean(createAd22Form.errors.fieldElevationAccuracy)
                }
                helperText={
                  createAd22Form.touched.fieldElevationAccuracy &&
                  createAd22Form.errors.fieldElevationAccuracy
                }
                sx={{ mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">M</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Aerodrome reference temperature</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="temp"
                name="temp"
                type="number"
                value={createAd22Form.values.temp}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.temp &&
                  Boolean(createAd22Form.errors.temp)
                }
                helperText={
                  createAd22Form.touched.temp && createAd22Form.errors.temp
                }
                sx={{ mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">DEG C</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Magnetic variation</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="magneticVariation"
                name="magneticVariation"
                type="number"
                value={createAd22Form.values.magneticVariation}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.magneticVariation &&
                  Boolean(createAd22Form.errors.magneticVariation)
                }
                helperText={
                  createAd22Form.touched.magneticVariation &&
                  createAd22Form.errors.magneticVariation
                }
                sx={{ mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">DEG W</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Date of information</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="dateMagneticVariation"
                name="dateMagneticVariation"
                value={createAd22Form.values.dateMagneticVariation}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.dateMagneticVariation &&
                  Boolean(createAd22Form.errors.dateMagneticVariation)
                }
                helperText={
                  createAd22Form.touched.dateMagneticVariation &&
                  createAd22Form.errors.dateMagneticVariation
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Annual change</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="magneticVariationChange"
                name="magneticVariationChange"
                type="number"
                value={createAd22Form.values.magneticVariationChange}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.magneticVariationChange &&
                  Boolean(createAd22Form.errors.magneticVariationChange)
                }
                helperText={
                  createAd22Form.touched.magneticVariationChange &&
                  createAd22Form.errors.magneticVariationChange
                }
                sx={{ mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">DEG E</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Magnetic Variation Accuracy</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="magneticVariationAccuracy"
                name="magneticVariationAccuracy"
                type="number"
                value={createAd22Form.values.magneticVariationAccuracy}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.magneticVariationAccuracy &&
                  Boolean(createAd22Form.errors.magneticVariationAccuracy)
                }
                helperText={
                  createAd22Form.touched.magneticVariationAccuracy &&
                  createAd22Form.errors.magneticVariationAccuracy
                }
                sx={{ mb: 2, mt: 2 }}
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">DEG</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Types of traffic permitted (IFR/VFR)
            </Box>
            <Box sx={styles.adInput}>
              <FormControl
                fullWidth
                size="small"
                error={
                  createAd22Form.touched.traffic_type &&
                  Boolean(createAd22Form.errors.traffic_type)
                }
              >
                <MuiSelect
                  id="traffic_type"
                  name="traffic_type"
                  value={createAd22Form.values.traffic_type}
                  onChange={createAd22Form.handleChange}
                  inputProps={{
                    readOnly: !isReadOnly,
                  }}
                >
                  <MenuItem value="ALL">IFR/VFR</MenuItem>
                  <MenuItem value="IFR">IFR</MenuItem>
                  <MenuItem value="VFR">VFR</MenuItem>
                </MuiSelect>
                <FormHelperText>
                  {createAd22Form.touched.traffic_type &&
                    createAd22Form.errors.traffic_type}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Vertical Datum</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="verticalDatum"
                name="verticalDatum"
                value={createAd22Form?.values.verticalDatum}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.verticalDatum &&
                  Boolean(createAd22Form.errors.verticalDatum)
                }
                helperText={
                  createAd22Form.touched.verticalDatum &&
                  createAd22Form.errors.verticalDatum
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Name of aerodrome operator</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="authority_name"
                name="authority_name"
                value={createAd22Form.values.authority_name}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.authority_name &&
                  Boolean(createAd22Form.errors.authority_name)
                }
                helperText={
                  createAd22Form.touched.authority_name &&
                  createAd22Form.errors.authority_name
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Address</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd22Form.touched.address &&
                  Boolean(createAd22Form.errors.address)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="address"
                  name="address"
                  value={createAd22Form.values.address}
                  onChange={createAd22Form.handleChange}
                  aria-describedby="aerodrome-address"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-address">
                  {createAd22Form.touched.address &&
                    createAd22Form.errors.address}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Telephone</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="telephone"
                name="telephone"
                value={createAd22Form.values.telephone}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.telephone &&
                  Boolean(createAd22Form.errors.telephone)
                }
                helperText={
                  createAd22Form.touched.telephone &&
                  createAd22Form.errors.telephone
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Telefax</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="fax"
                name="fax"
                value={createAd22Form.values.fax}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.fax &&
                  Boolean(createAd22Form.errors.fax)
                }
                helperText={
                  createAd22Form.touched.fax && createAd22Form.errors.fax
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>AFS address</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="afs"
                name="afs"
                value={createAd22Form?.values.afs}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.afs &&
                  Boolean(createAd22Form.errors.afs)
                }
                helperText={
                  createAd22Form.touched.afs && createAd22Form.errors.afs
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>e-mail address</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="email"
                name="email"
                type="email"
                value={createAd22Form.values.email}
                onChange={createAd22Form.handleChange}
                error={
                  createAd22Form.touched.email &&
                  Boolean(createAd22Form.errors.email)
                }
                helperText={
                  createAd22Form.touched.email && createAd22Form.errors.email
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd22Form.touched?.remark &&
                  Boolean(createAd22Form.errors?.remark)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="remark"
                  name="remark"
                  value={createAd22Form.values.remark}
                  onChange={createAd22Form.handleChange}
                  aria-describedby="aerodrome-remark"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-remark">
                  {createAd22Form.touched?.remark &&
                    createAd22Form.errors?.remark}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd22Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd22Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}
