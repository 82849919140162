import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import {
  surveyCategory,
  surveyComapareTableHeaderName,
  surveyReportStatus,
} from "@constants/surveyorData";
import { ACCESS_TOKEN, AES_KEY } from "@constants/constant";
import { statusColorMap } from "@constants/surveyorData";
import Header from "@components/Header";
import ApproachReviewTable from "@components/surveyor-review-table/ApproachReviewTable";
import CompareReviewDataTable from "@components/surveyor-review-table/CompareReviewDataTable";
import ObstacleReviewTable from "@components/surveyor-review-table/ObstacleReviewTable";
import VerticalSignificanceReviewTable from "@components/surveyor-review-table/VerticalSignificanceReviewTable";
import { setSelectedSurveyReport } from "@store/survey";
import { comparedata, postSurveyVerification } from "@store/survey/actions";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import { decryptStatic } from "@utils/decodeEncodeData";

export default function ReviewSurveyData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeState = useLocation();
  const aesKey = getStorageValue(AES_KEY);
  const { isLoadingVerification, selectedReport, isLoadingCompareData } =
    useSelector((state) => state.survey);

  const surveyReportDetail = useSelector((state) =>
    state.survey.comparedata ? state.survey.comparedata : []
  );
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const { state } = routeState;
    dispatch(setSelectedSurveyReport(state));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        comparedata(
          state.airport_id,
          state.feature_name,
          state.user_id,
          state.id,
          token
        )
      );
    });
  }, [routeState]); // eslint-disable-line

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filter(surveyReportDetail, [
        "verification1",
        surveyReportStatus.notVerified,
      ]).map((n) => n.id1);

      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleCallback = () => {
    setSelected([]);
  };

  const handleAccept = () => {
    const acceptedPayload = selected.map((id) => ({
      id,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        postSurveyVerification(
          {
            status: surveyReportStatus.accepted,
            issue_data: acceptedPayload,
          },
          selectedReport,
          token,
          handleCallback
        )
      );
    });
  };

  const handleReview = (payload) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        postSurveyVerification(
          {
            status: surveyReportStatus.inReview,
            issue_data: payload,
          },
          selectedReport,
          token,
          handleCallback
        )
      );
    });
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            className="surveyor-category-table white-bg"
            style={{ padding: 24, paddingTop: 0, width: "auto" }}
          >
            <Box
              sx={{
                ...styles.reviewDatasetBar,
                m: "0px",
                height: "72px",
              }}
            >
              <Box sx={{ ...styles.reviewDatasetBarinr, width: "12%" }}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "-8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  variant="span"
                  color="primary"
                  sx={{
                    pl: 1,
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                >
                  Review Datasets
                </Typography>
              </Box>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: 40, mt: 2 }}
              />
              <Box sx={styles.surSubheadermain}>
                <Box sx={styles.surSubheaderinr}>
                  <Typography sx={styles.surheadtitle}>Status</Typography>
                  <Typography
                    sx={{
                      ...styles.surheadvaluebtn,
                      backgroundColor: statusColorMap[selectedReport?.status],
                    }}
                  >
                    {selectedReport.status}
                  </Typography>
                </Box>
                <Box sx={styles.surSubheaderinr}>
                  <Typography sx={styles.surheadtitle}>Unique ID</Typography>
                  <Typography sx={styles.surheadvalue}>
                    {selectedReport.user_empid}
                  </Typography>
                </Box>
                <Box sx={styles.surSubheaderinr}>
                  <Typography sx={styles.surheadtitle}>Airport Name</Typography>
                  <Typography sx={styles.surheadvalue}>
                    {selectedReport?.airport_name}
                  </Typography>
                </Box>
                <Box sx={styles.surSubheaderinr}>
                  <Typography sx={styles.surheadtitle}>
                    Surveyor Name
                  </Typography>
                  <Typography sx={styles.surheadvalue}>
                    {selectedReport
                      ? decryptStatic(selectedReport?.user_name, aesKey)
                      : "-"}
                  </Typography>
                </Box>
                <Box sx={styles.surSubheaderinr}>
                  <Typography sx={styles.surheadtitle}>Dataset Type</Typography>
                  <Typography sx={styles.surheadvalue}>
                    {selectedReport?.feature_name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                ...styles.arpReviewCategoryList,
                margin: 0,
                height: "calc(100vh - 162px)",
                overflow: "auto",
                position: "relative",
              }}
            >
              {selectedReport?.feature_name === surveyCategory.approach && (
                <ApproachReviewTable
                  isLoadingCompareData={isLoadingCompareData}
                  isLoadingVerification={isLoadingVerification}
                  selectedReport={selectedReport}
                  surveyReportDetail={surveyReportDetail}
                  selected={selected}
                  handleSelectAllClick={handleSelectAllClick}
                  handleClick={handleClick}
                  handleAccept={handleAccept}
                  handleReview={handleReview}
                />
              )}
              {selectedReport?.feature_name !==
                surveyCategory.vertical_significance &&
                selectedReport?.feature_name !==
                  surveyCategory.objectsObstacles &&
                selectedReport?.feature_name !== surveyCategory.approach && (
                  <CompareReviewDataTable
                    isLoadingCompareData={isLoadingCompareData}
                    isLoadingVerification={isLoadingVerification}
                    selectedReport={selectedReport}
                    surveyReportDetail={surveyReportDetail}
                    selected={selected}
                    headerName={
                      surveyComapareTableHeaderName[
                        selectedReport?.feature_name
                      ]
                    }
                    handleSelectAllClick={handleSelectAllClick}
                    handleClick={handleClick}
                    handleAccept={handleAccept}
                    handleReview={handleReview}
                  />
                )}
              {selectedReport?.feature_name ===
                surveyCategory.vertical_significance && (
                <VerticalSignificanceReviewTable
                  isLoadingCompareData={isLoadingCompareData}
                  isLoadingVerification={isLoadingVerification}
                  selectedReport={selectedReport}
                  surveyReportDetail={surveyReportDetail}
                  selected={selected}
                  handleSelectAllClick={handleSelectAllClick}
                  handleClick={handleClick}
                  handleAccept={handleAccept}
                  handleReview={handleReview}
                />
              )}
              {selectedReport?.feature_name ===
                surveyCategory.objectsObstacles && (
                <ObstacleReviewTable
                  isLoadingCompareData={isLoadingCompareData}
                  isLoadingVerification={isLoadingVerification}
                  selectedReport={selectedReport}
                  surveyReportDetail={surveyReportDetail}
                  selected={selected}
                  handleSelectAllClick={handleSelectAllClick}
                  handleClick={handleClick}
                  handleAccept={handleAccept}
                  handleReview={handleReview}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
