import { find, isEmpty } from "lodash";
import { Typography } from "@mui/material";
import { aupAfterStyle, aupBeforeStyle } from "@constants/airspaceData";

function AUPActivemarker({
  element,
  showtooltipData,
  showtooltipText,
  classname,
}) {
  return (
    <Typography
      variant="span"
      className={classname}
      sx={
        showtooltipData.includes(element) &&
        !isEmpty(find(showtooltipText, ["time_fm", element]))
          ? {
              "&:after": aupAfterStyle(
                find(showtooltipText, ["time_fm", element])?.lower_fl
              ),
              "&:before": aupBeforeStyle(
                find(showtooltipText, ["time_fm", element])?.upper_fl
              ),
              // color: "#27ae60",
            }
          : {}
      }
    >
      {String(element).length < 4 ? `0${element}` : element}
    </Typography>
  );
}

export default AUPActivemarker;
