import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "@scripts/wmts";
import { GET_GEOSERVER_WMTS_URL } from "@constants/urls";

const WMTSDemTileLayer = ({ dem }) => {
  const map = useMap();
  const wmsLayerGroup = L.featureGroup();

  useEffect(() => {
    const wmtsParams = {
      layer: `inetra:${dem.dem_gs_layer_name}`,
      format: "image/png8",
      transparent: true,
      maxZoom: 15,
      pane: "overlayPane",
      opacity: 1,
      tilematrixset: "EPSG:900913",
      height: 256,
      width: 256,
    };

    const wmtslayer = L.tileLayer
      .wmts(GET_GEOSERVER_WMTS_URL, wmtsParams)
      .addTo(map);
    wmsLayerGroup.addLayer(wmtslayer);
    wmsLayerGroup.addTo(map);

    return () => {
      wmsLayerGroup.clearLayers();
    };

    // eslint-disable-next-line
  }, [dem]);

  return null;
};

export default WMTSDemTileLayer;
