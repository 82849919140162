import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import { theme } from "@styles/theme";
import { capitalize, isEmpty, keys } from "lodash";

export default function RenderPDRTraTsaMtrTmaOtherRegAdizAirspaceDetail({
  isLoading,
  airspaceDetails,
}) {
  return (
    <TableContainer sx={{ maxHeight: 350, overflow: "auto" }}>
      <Table>
        <TableBody sx={{ backgroundColor: theme.palette.white.main }}>
          {isLoading && (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                Loading...
              </TableCell>
            </TableRow>
          )}
          {!isLoading && Object.keys(airspaceDetails).length === 0 && (
            <TableRow key="emptydetail">
              <TableCell align="center" colSpan={2}>
                No Record Found.
              </TableCell>
            </TableRow>
          )}
          {Object.keys(airspaceDetails).map((key, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  ":first-of-type": {
                    backgroundColor: theme.palette.secondary.light,
                  },
                }}
              >
                <TableCell sx={{ width: "100px" }}>
                  {key === "extracol"
                    ? "Unit"
                    : capitalize(key.replace("_", " "))}
                </TableCell>
                <TableCell>
                  {Array.isArray(airspaceDetails[key]) ? (
                    <Table>
                      <TableBody
                        sx={{ backgroundColor: theme.palette.white.main }}
                      >
                        {!isEmpty(airspaceDetails[key]) ? (
                          <>
                            <TableRow>
                              {keys(airspaceDetails[key][0]).map((head) => {
                                return (
                                  <TableCell
                                    sx={{
                                      p: 1,
                                      backgroundColor:
                                        theme.palette.secondary.light,
                                    }}
                                    key={head}
                                  >
                                    {head === "unit"
                                      ? "Unit Name"
                                      : capitalize(head.replace(/_/g, " "))}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                            {airspaceDetails[key].map((d, ind) => {
                              return (
                                <TableRow>
                                  {keys(d).map((inrd, j) => {
                                    return (
                                      <TableCell
                                        sx={{ p: 1 }}
                                        key={`${ind}-${j}`}
                                      >
                                        {d[inrd]}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableBody>
                    </Table>
                  ) : (
                    airspaceDetails[key] || "-"
                  )}
                  {/* {key === "extracol" ? "-" : airspaceDetails[key] || "-"} */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
