import React, { Fragment } from "react";
import { chain, flatten, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DOLSApproach({
  OLSApproachList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSApproachList = chain(OLSApproachList)
    .filter((approachObj) => {
      const name = `${approachObj.category}_${approachObj.rwy_dir}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleOLSApproachList.map((olsApproach, i) => {
        const approachCoordinates = olsApproach.coordinates.coordinates;
        const name = `${olsApproach.category}_${olsApproach.rwy_dir}`;
        const approachCoordinateMap = flatten(approachCoordinates).map(
          (approachCoords) => {
            return approachCoords.map((approach) => {
              return [approach[0], approach[1], approach[2] * ZvalueMultiplyby];
            });
          }
        );

        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(approachCoordinateMap)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
