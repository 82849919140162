import { drop, join, take, takeRight } from "lodash";
import { convertToDms } from "@utils/geocoords";

export const firColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    const sliceArray = drop(r.extracol);

    rowData.push({
      Name: r.name,
      "Lateral Limits": r.description,
      "Class of airspace": r.Airspace_class_note,
      "Vertical Limits": `${r.AV_upperLimit}/${r.AV_lowerLimit}`,
      "Unit providing service": r.extracol[0].unit,
      "Call sign": r.extracol[0].call_sign,
      Languages: r.extracol[0].languages,
      "Hours of service": r.extracol[0].hour,
      "Frequency/Purpose": r.extracol[0].frequency,
      Remarks: r.remarks,
    });

    sliceArray.forEach((ex) => {
      rowData.push({
        Name: null,
        "Lateral Limits": null,
        "Class of airspace": null,
        "Vertical Limits": null,
        "Unit providing service": ex.unit,
        "Call sign": ex.call_sign,
        Languages: ex.languages,
        "Hours of service": ex.hour,
        "Frequency/Purpose": ex.frequency,
        Remarks: null,
      });
    });
  });

  return rowData;
};

export const tmaColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      Name: r.name,
      "Lateral Limits": r.description,
      "Class of airspace": r.Airspace_class_note,
      "Vertical Limits": `${r.AV_upperLimit}/${r.AV_lowerLimit}`,
      "Unit providing service": r.units,
      "Call sign": r.callsign,
      Languages: r.language,
      "Hours of service": r.hours,
      "Frequency/Purpose": r.freq,
      Remarks: r.remarks,
    });
  });

  return rowData;
};

export const ctaColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    const sliceArray = drop(r.extracol);

    rowData.push({
      Name: r.name,
      "Lateral Limits": r.description,
      "Class of airspace": r.Airspace_class_note,
      "Vertical Limits": `${r.AV_upperLimit}/${r.AV_lowerLimit}`,
      "Unit providing service": r.extracol[0].unit,
      "Call sign": r.extracol[0].call_sign,
      Languages: r.extracol[0].languages,
      "Hours of service": r.extracol[0].hour,
      "Frequency/Purpose": r.extracol[0].frequency,
      Remarks: r.remarks,
    });

    sliceArray.forEach((ex) => {
      rowData.push({
        Name: null,
        "Lateral Limits": null,
        "Class of airspace": null,
        "Vertical Limits": null,
        "Unit providing service": ex.unit,
        "Call sign": ex.call_sign,
        Languages: ex.languages,
        "Hours of service": ex.hour,
        "Frequency/Purpose": ex.frequency,
        Remarks: null,
      });
    });
  });

  return rowData;
};

export const raColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      Aerodrome: r.Aerodrome,
      "Hours of Ops": r.hours,
      "Controlled airspace lateral limits": r.description,
      "Upper limit / Lower Limit": `${r.upperLimit}/${r.lowerLimit}`,
      Languages: r.language,
      Remarks: join(r?.remarks, ", "),
    });
  });

  return rowData;
};

export const rnColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      "Name of the Station": r.name,
      Type: r.type,
      ID: r.designator,
      "Frequency (Channel)": join(r?.channel_freq, ", "),
      "Hours of operation": r.operationalStatus,
      "DME Antenna Elevation": r.language,
      Coordinates: `${convertToDms(r?.dd_coordinates[0])}, ${convertToDms(
        r?.dd_coordinates[1],
        true
      )}`,
      Remarks: join(r?.remarks, ", "),
    });
  });

  return rowData;
};

export const spColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      Waypoint: r.name,
      Coordinates: `${convertToDms(r?.dd_latitude)}, ${convertToDms(
        r?.dd_longitude,
        true
      )}`,
    });
  });

  return rowData;
};

export const dprColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      Identification: r.designator,
      Name: r.Airspace_name,
      "Lateral Limits": r.description,
      "Upper Limit/Lower Limit": `${r.upperLimit}/${r.lowerLimit}`,
      Remarks: join(r?.remarks, ", "),
    });
  });

  return rowData;
};

export const metaColumnsData = (rows) => {
  const rowData = [];

  rows.forEach((r) => {
    rowData.push({
      Name: `${r.designator} ${r.Airspace_name}`,
      "Lateral Limits": r.description,
      "Upper Limit/Lower Limit": `${r.upperLimit}/${r.lowerLimit}`,
      Remarks: join(r?.remarks, ", "),
    });
  });

  return rowData;
};

export const routeColumnsData = (rows) => {
  const firstRow = take(rows)[0];
  const lastRow = takeRight(rows)[0];
  const endpoint_designator = lastRow.endpoint_designator
    ? `(${lastRow.endpoint_designator})`
    : "";
  const endCoordinates = `${convertToDms(
    lastRow.DD_endpoint_coordinates.lat
  )}, ${convertToDms(lastRow.DD_endpoint_coordinates.lng, true)}`;
  const header = `${firstRow.designatorSecondLetter}${
    firstRow.designatorNumber
  }${firstRow.multipleIdentifier || ""}${" "}(${
    firstRow?.navigationType || ""
  }${firstRow?.RNP ? " " : ""}${firstRow?.RNP ? firstRow?.RNP : ""})`.trim();
  const points = `(${firstRow.startpoint_name} - ${lastRow.endpoint_name})`;

  const rowData = [
    {
      "Route Designator (RNP Type)\nName of Significant Points Coordinates": `${header}\n${points}`,
      "Track Magnetic/Geo VOR Radial\nDistance": null,
      "Upper Limit\nLower Limit": null,
      "Lateral Limits": null,
      "Direction of Cruising Levels\nOdd": null,
      "Direction of Cruising Levels\nEven": null,
    },
  ];

  rows.forEach((r) => {
    const startpoint_designator = r.startpoint_designator
      ? `(${r.startpoint_designator})`
      : "";
    const startCoordinates = `${convertToDms(
      r.DD_startpoint_coordinates.lat
    )}, ${convertToDms(r.DD_startpoint_coordinates.lng, true)}`;
    const magneticTrack = `${r.magneticTrack || "-"}/${
      r.reverseMagneticTrack || "-"
    }`;

    rowData.push({
      "Route Designator (RNP Type)\nName of Significant Points Coordinates": `${
        r.startpoint_name
      }\n${
        r.startpoint_navaid_type || ""
      }${startpoint_designator}\n${startCoordinates}`,
      "Track Magnetic/Geo VOR Radial\nDistance": null,
      "Upper Limit\nLower Limit": null,
      "Lateral Limits": null,
      "Direction of Cruising Levels\nOdd": null,
      "Direction of Cruising Levels\nEven": null,
    });
    rowData.push({
      "Route Designator (RNP Type)\nName of Significant Points Coordinates":
        null,
      "Track Magnetic/Geo VOR Radial\nDistance": `${magneticTrack}\n${r.length}`,
      "Upper Limit\nLower Limit": `${r.upperLimit}\n${r.lowerLimit}`,
      "Lateral Limits": r.Lateral_Limits,
      "Direction of Cruising Levels\nOdd": r.ODD
        ? r.ODD === "FORWARD"
          ? "↓"
          : "↑"
        : "-",
      "Direction of Cruising Levels\nEven": r.EVEN
        ? r.EVEN === "FORWARD"
          ? "↓"
          : "↑"
        : "-",
    });
  });

  rowData.push({
    "Route Designator (RNP Type)\nName of Significant Points Coordinates": `${lastRow.endpoint_name}\n${lastRow.endpoint_navaid_type}${endpoint_designator}\n${endCoordinates}`,
    "Track Magnetic/Geo VOR Radial\nDistance": null,
    "Upper Limit\nLower Limit": null,
    "Lateral Limits": null,
    "Direction of Cruising Levels\nOdd": null,
    "Direction of Cruising Levels\nEven": null,
  });

  rowData.push({
    "Route Designator (RNP Type)\nName of Significant Points Coordinates": `Remarks\n${firstRow?.remarks}`,
    "Track Magnetic/Geo VOR Radial\nDistance": null,
    "Upper Limit\nLower Limit": null,
    "Lateral Limits": null,
    "Direction of Cruising Levels\nOdd": null,
    "Direction of Cruising Levels\nEven": null,
  });

  return rowData;
};
