import React from "react";
import AirportReviewListTable from "@components/AirportReviewListTable";

export default function AirportReviewList() {
  return (
    <div className="surveyor-category-table white-bg">
      <AirportReviewListTable />
    </div>
  );
}
