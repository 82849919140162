import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingAllAirports: false,
  isLoadingAAIAirport: false,
  allAirports: [],
  allAirportsCharts: [],
  AAIAirport: null,
  AD212RWYDetail: null,
  AAIMuiAirport: {
    geoadministrative: null,
    operationalhours: null,
    handlingservice: null,
    passengerfacilities: null,
    rescueservices: null,
    seasonalservice: null,
    apron: [],
    taxiway: [],
    checkpoints: [],
    surfacmovements: null,
    aixmobstacles: [],
    meteorologicalinfo: null,
    runwayphysicals: [],
    declareddistaces: [],
    rwylights: [],
    airspaceservice: null,
    airtrafficservices: [],
    navaids: [],
    powerlights: null,
    noiseflight: null,
    aerodromeregulationinfo: null,
    charts: [],
  },
  selectedAAIAirport: null,
  isLoadingPostCreateNewAirport: false,
  selctedAirportFormCategories: null,
  isLoadingAirportStatusChange: false,
  isExportAD2XML: false,
  isLoadingAdRunwayInfo: false,
  adRunwayInfo: null,
  isLoadingAddAAIAirportDetail: false,
  isLoadingDeleteAD210AAIAirport: false,
  isLoadingAddAAIAirportDetailUpload: false,
};

export const airport = createSlice({
  name: "airport",
  initialState,
  reducers: {
    setLoadingAllAirports: (state, action) => {
      state.isLoadingAllAirports = action.payload;
    },
    setAllAirports: (state, action) => {
      state.allAirports = action.payload;
    },
    setLoadingAAIAirport: (state, action) => {
      state.isLoadingAAIAirport = action.payload;
    },
    setAAIAirport: (state, action) => {
      state.AAIAirport = action.payload;
    },
    setAllAirportsCharts: (state, action) => {
      state.allAirportsCharts = action.payload;
    },
    setAAIAD212RWYDetail: (state, action) => {
      state.AD212RWYDetail = action.payload;
    },
    setAAIMuiAirport: (state, action) => {
      state.AAIMuiAirport = action.payload;
    },
    setSelectedAAIAirport: (state, action) => {
      state.selectedAAIAirport = action.payload;
    },
    setLoadingPostCreateNewAirport: (state, action) => {
      state.isLoadingPostCreateNewAirport = action.payload;
    },
    setSelctedAirportFormCategories: (state, action) => {
      state.selctedAirportFormCategories = action.payload;
    },
    setLoadingAirportStatusChange: (state, action) => {
      state.isLoadingAirportStatusChange = action.payload;
    },
    setExportXMLAD2Loading: (state, action) => {
      state.isExportXMLAD2Loading = action.payload;
    },
    setLoadingAdRunwayInfo: (state, action) => {
      state.isLoadingAdRunwayInfo = action.payload;
    },
    setAdRunwayInfo: (state, action) => {
      state.adRunwayInfo = action.payload;
    },
    setLoadingAddAAIAirportDetail: (state, action) => {
      state.isLoadingAddAAIAirportDetail = action.payload;
    },
    setLoadingAddAAIAirportDetailUpload: (state, action) => {
      state.isLoadingAddAAIAirportDetailUpload = action.payload;
    },
    setLoadingDeleteAD2AAIAirport: (state, action) => {
      state.isLoadingDeleteAD210AAIAirport = action.payload;
    },
    setLoadingAD28AAIAirportDetail: (state, action) => {
      state.isLoadingAD28AAIAirportDetail = action.payload;
    },
  },
});
