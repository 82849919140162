import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableRowLoader from "@components/tabel-component/TableRowLoader";
import { convertToDms } from "@utils/geocoords";

export default function SPTable({ isLoading, SPData, selectedSP }) {
  const rows = SPData.filter((sp) => selectedSP.includes(sp.name));
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Waypoints</StyledTableCell>
            <StyledTableCell>Coordinates</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowLoader isLoading={isLoading} data={SPData} colSpan={2} />
          {rows.map((row, i) => {
            return (
              <TableRow key={`${i}_${row?.name}`}>
                <StyledTableCell>{row?.name || "-"}</StyledTableCell>
                <StyledTableCell>
                  {convertToDms(row?.dd_latitude)},{" "}
                  {convertToDms(row?.dd_longitude, true)}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
