import React from "react";
import { useSelector } from "react-redux";
import parseQueryParameters from "parse-url-query-params";
import { useLocation, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "@components/Header";
import AD2DataEntrySideBar from "@components/AD2DataEntrySideBar";
import AirportReviewListTable from "@components/AirportReviewListTable";
import Ad2DataEntrySubHeader from "@components/Ad2DataEntrySubHeader";
import { styles } from "@styles/dataManagementStyles";

export default function AD2DataEntry() {
  const location = useLocation();
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const isParentSorveyorPath = location.pathname === "/ad-2-data-entry";

  const prsedUrl = parseQueryParameters(
    decodeURIComponent(`${location.pathname}${location.search}`)
  );
  const selectedAirport = allNewAirports.find(
    (airport) => airport.id === Number(prsedUrl.airportId)
  );

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div className="content-area white-bg">
          <div
            className="surveyor-category-table white-bg"
            style={{ padding: 24, paddingTop: 0 }}
          >
            <Ad2DataEntrySubHeader
              isParentSorveyorPath={isParentSorveyorPath}
              selectedAirport={selectedAirport}
            />
            <Box
              sx={{
                ...styles.arpReviewCategoryList,
                margin: 0,
                height: "calc(100% - 73px)",
              }}
            >
            {isParentSorveyorPath && <AirportReviewListTable />}
            {!isParentSorveyorPath && (
              <>
                <AD2DataEntrySideBar />
                <Outlet />
              </>
            )}
              </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
