import { TbTrees, TbBuildingFactory } from "react-icons/tb";
import { BsBuilding } from "react-icons/bs";
import { RiSignalTowerFill } from "react-icons/ri";
import {
  GiCrane,
  GiBrickWall,
  GiIndiaGate,
  GiWatchtower,
  GiWaterTank,
  GiChimney,
  GiRailway,
  GiFireplace,
  GiMountaintop,
  GiWaterTower,
  GiControlTower,
  GiRadioTower,
} from "react-icons/gi";
import AerodromeReferencePointIcon from "@assets/images/obstacles/AerodromeReferencePointIcon";
import ChruchIcon from "@assets/images/obstacles/ChruchIcon";
import LargeStructureIcon from "@assets/images/obstacles/LargeStructureIcon";
import ObstacleIcon from "@assets/images/obstacles/ObstacleIcon";
import PoleIcon from "@assets/images/obstacles/PoleIcon";
import TreeIcon from "@assets/images/obstacles/TreeIcon";

// 'NATURAL HIGHPOINT',
// 'OTHER',
// 'STACK',
// 'NAV-AID',
// 'HUT',
// 'HIGHPOINT',

export const getObstacleSymbol = (name) => {
  let image = <ObstacleIcon />;

  switch (name) {
    case "MAST":
      image = <RiSignalTowerFill size={34} />;
      break;
    case "APPROACH LIGHT":
      image = <AerodromeReferencePointIcon />;
      break;
    case "POLE":
      image = <PoleIcon />;
      break;
    case "RAILWAY":
      image = <GiRailway />;
      break;
    case "CHURCH":
      image = <ChruchIcon />;
      break;
    case "HANGAR":
      image = <LargeStructureIcon />;
      break;
    case "TREES":
      image = <TbTrees size={34} />;
      break;
    case "TREE":
      image = <TreeIcon size={34} />;
      break;
    case "ANTENNA":
    case "TOWER":
      image = <GiRadioTower size={34} />;
      break;
    case "BUILDING":
      image = <BsBuilding size={34} />;
      break;
    case "CRANE":
      image = <GiCrane size={34} />;
      break;
    case "CHIMNEY":
      image = <GiChimney size={34} />;
      break;
    case "GATE":
      image = <GiIndiaGate size={34} />;
      break;
    case "HILL TOP":
      image = <GiMountaintop size={34} />;
      break;
    case "FIRE PIT":
      image = <GiFireplace size={34} />;
      break;
    case "TANK":
      image = <GiWaterTank size={34} />;
      break;
    case "POWER PLANT":
      image = <TbBuildingFactory size={34} />;
      break;
    case "WATCH TOWER":
      image = <GiWatchtower size={34} />;
      break;
    case "WALL":
      image = <GiBrickWall size={34} />;
      break;
    case "OHWT":
      image = <GiWaterTower size={34} />;
      break;
    case "TERMINAL":
      image = <GiControlTower size={34} />;
      break;
    default:
      image = <ObstacleIcon />;
      break;
  }

  return image;
};
