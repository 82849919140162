import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd221ValidationSchema } from "@constants/ad2Data";
import { PrimaryButton,LightButton } from "@components/button";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad221Form({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;
    createAd221Form.setFieldValue(
      "noise",
      AAIAirport?.noise ? AAIAirport?.noise : ""
    );

    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "noiseflight",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd221Form.resetForm();
    createAd221Form.setSubmitting(false);
  };

  const createAd221Form = useFormik({
    enableReinitialize: true,
    initialValues: { noise: "" },
    validationSchema: createAd221ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "noiseflight",
            {
              noise: values.noise,
              flight: AAIAirport?.flight,
            },
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Noise abatement procedures</Box>
            <Box sx={{ pl: 2, minWidth: "350px" }}>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, mt: 2, width: "100%" }}
                error={
                  createAd221Form.touched?.noise &&
                  Boolean(createAd221Form.errors?.noise)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="noise"
                  name="noise"
                  value={createAd221Form.values.noise}
                  onChange={createAd221Form.handleChange}
                  aria-describedby="aerodrome-noise"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-noise">
                  {createAd221Form.touched?.noise &&
                    createAd221Form.errors?.noise}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd221Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd221Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad221Form;
