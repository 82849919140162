import React from "react";
import { Box, Typography } from "@mui/material";
import { airportDataCategories } from "@constants/ad2Data";
import { styles } from "@styles/dataManagementStyles";
import CategoryCard from "./core/CategoryCard";
import { theme } from "@styles/theme";

export default function AirportReviewSideBar({
  selctedAirport,
  selectedCategory,
  handleAirportSelectedCategory,
}) {
  return (
    selctedAirport && (
      <Box sx={{ ...styles.reviewSidebarCat, pt: 0 }}>
        <Typography sx={styles.catTitle}>Category</Typography>
        <Box
          sx={{
            height: "calc(100vh - 163px - 47px)",
            overflow: "auto",
            m: 1,
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: theme.palette.white.main,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "5px",
            },
          }}
        >
          {airportDataCategories &&
            airportDataCategories.map((category, index) => (
              <CategoryCard
                key={index}
                categoryData={category}
                activeCategory={selectedCategory}
                selectedCategories={[]}
                handleActiveCategory={handleAirportSelectedCategory}
              />
            ))}
        </Box>
      </Box>
    )
  );
}
