import React, { Fragment, useState } from "react";
import { Polyline, Polygon, CircleMarker } from "react-leaflet";
import { flatten, isEmpty } from "lodash";
import { airspaceType } from "@constants/airspaceData";
import { areaHighLightColor } from "@constants/colors";
import { mapDrawType } from "@constants/constant";

export default function RenderTraTsaLiveTrackerAUP({
  colorTray,
  filterTraTsaAirspace,
  selectedAirspacePlanDetail,
  handleAirspaceUsePlanDetails,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const handlePolygonOver = (e, data) => {
    e.target.bringToFront();
    e.target.openPopup();
    setSelectedOverE(data);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonClick = (info) => {
    handleAirspaceUsePlanDetails(info, airspaceType.restrictedAirspace);
  };

  if (isEmpty(filterTraTsaAirspace)) return null;

  return (
    <Fragment>
      {filterTraTsaAirspace.map((data, i) => {
        const option = {
          color:
            selectedOverE?.name === data.name
              ? areaHighLightColor
              : colorTray[i],
          weight: selectedOverE?.name === data.name ? 3 : 2,
          fillColor: colorTray[i],
          fillOpacity:
            selectedAirspacePlanDetail &&
            selectedAirspacePlanDetail?.selectedPolygon?.name === data.name
              ? 1
              : 0.2,
        };

        if (data.typeOfcoordinates === mapDrawType.point) {
          return (
            <CircleMarker
              key={i}
              pane="tooltipPane"
              center={flatten(data.coordinates)[0]}
              pathOptions={{ ...option, fillOpacity: 1 }}
              radius={6}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
                click: () => handlePolygonClick(data),
              }}
            />
          );
        }

        if (data.typeOfcoordinates === mapDrawType.lineString) {
          return (
            <Polyline
              key={i}
              positions={flatten(data.coordinates)}
              pathOptions={option}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, data),
                mouseout: () => handlePolygonOut(),
                click: () => handlePolygonClick(data),
              }}
            />
          );
        }

        return (
          <Polygon
            key={i}
            positions={flatten(data.coordinates)}
            pathOptions={option}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, data),
              mouseout: () => handlePolygonOut(),
              click: () => handlePolygonClick(data),
            }}
          />
        );
      })}
    </Fragment>
  );
}
