import React from 'react'
import { Typography } from '@mui/material';

function AUPCenterMarker({ element }) {
  return (
    <Typography variant="span" className="center-marker">
      {element}
    </Typography>
  );
}

export default AUPCenterMarker