import React from "react";

const ObstacleIcon = (props) => {
  return (
    <svg
      width={props.width || "99"}
      height={props.height || "48"}
      viewBox="0 0 99 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.26004 18.9907L6.18418 18.7291"
        stroke="#42444A"
        strokeWidth="1.17647"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.09344 4.68344L12.6339 4.20461"
        stroke="#42444A"
        strokeWidth="1.17647"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.61481 24.6552L11.0964 24.2578"
        stroke="#42444A"
        strokeWidth="1.17647"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2987 20.7024L14.4962 24.7698C14.2053 25.1919 14.5253 25.7637 15.0364 25.737L16.6682 25.6523C16.7981 25.6449 16.9232 25.5979 17.0232 25.5159L26.5706 17.8288"
        stroke="#42444A"
        strokeWidth="1.17647"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8532 12.3781L26.7104 12.7525L18.9882 3.50774C18.8685 3.35288 18.6792 3.27657 18.4818 3.28764L16.4958 3.39119C16.1011 3.41332 15.8247 3.80995 15.9436 4.18717L18.6904 13.1732L10.7578 13.5879C9.93112 13.6324 9.14431 13.2655 8.65176 12.5976L6.97418 10.3472C6.85349 10.1818 6.65121 10.0918 6.44213 10.1036L5.18414 10.1681C4.75189 10.1917 4.47815 10.6262 4.64735 11.0258L6.16242 14.6704C7.17981 17.079 9.58535 18.5985 12.1878 18.4618L37.703 17.1241C38.5917 17.077 39.2694 16.3236 39.222 15.436C39.1943 14.8952 38.8969 14.4152 38.4363 14.1293L36.6352 13.0609C35.7931 12.5615 34.8287 12.3274 33.8532 12.3781Z"
        stroke="#42444A"
        strokeWidth="1.17647"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.337 46.6953L9.00171 46.6953"
        stroke="#42444A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0002 36.2031L50.208 18.9063L59.7665 28.6094M70.9873 40L59.7665 28.6094M59.7665 28.6094L75.1431 13L98.0002 36.2031"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ObstacleIcon;
