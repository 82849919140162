import React, { Fragment } from "react";
import {Box, Paper, Typography} from "@mui/material";
import AntSwitch from "@components/core/AntSwitch";
import { staticColorMap } from "@constants/colors";

export default function ObstacleLegends({
  isShowPenetrationObstacle,
  handleAbovePermissible,
  handleBelowPermissible,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
}) {
  return (
    <Fragment>
      {isShowPenetrationObstacle && (
        <Paper sx={{ p: 1, borderRadius: 1, width: 160, mb: 1 }}>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="body2" sx={{ fontSize: 14, mr: 1 }}>
              Above Permissible
            </Typography>
            <AntSwitch
              checked={isAbovePermissibleClick}
              onChange={(event) => handleAbovePermissible(event)}
              trackcolor={staticColorMap["RedObstacle"]}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" sx={{ fontSize: 14, mr: 1 }}>
              Below Permissible
            </Typography>
            <AntSwitch
              checked={isBelowPermissibleClick}
              onChange={(event) => handleBelowPermissible(event)}
              trackcolor={staticColorMap["GreenObstacle"]}
            />
          </Box>
        </Paper>
      )}
    </Fragment>
  );
}
