import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingEtodAirport: false,
  isLoadingArea1: false,
  etodAllAirports: [],
  area1: null,
  isLoadingArea1Obstacles: false,
  area1Obstacles: [],
  isLoadingExportObstacle: false,
  isLoadingEtodLayers: false,
  etodLayers: [],
  isLoadingEtodAixmAirports: false,
  isLoadingGenerateEtodAirport: false,
  etodAixmAirports: [],
  isLoadingEtodDems: false,
  etodDems: [],
  isUplodeeTodDemFileRequest: false,
  isLoadingExportAreaOls: false,
  visualiseObstacles: [],
  isLoadingAnalysisObstacles: false,
  analysisObstacles: [],
  isLoadingProcessObstacleAnalysis: false,
  isLoadingUploadObstacleAnalysis: false,
  isLoadingAirportCreatelayers: false,
  isLoadingAirportlayer: false,
  isLoadingExportAirportLayers: false,
  isLoadingDeleteAirportlayer: false,
  createlayerAirportData: null,
};

export const etod = createSlice({
  name: "etod",
  initialState,
  reducers: {
    setEtodAirportLoadingRequest: (state, action) => {
      state.isLoadingEtodAirport = action.payload;
    },
    setEtodArea1LoadingRequest: (state, action) => {
      state.isLoadingArea1 = action.payload;
    },
    setAllEtodAirport: (state, action) => {
      state.etodAllAirports = action.payload;
    },
    setArea1Details: (state, action) => {
      state.area1 = action.payload;
    },
    setLoadingArea1Obstacles: (state, action) => {
      state.isLoadingArea1Obstacles = action.payload;
    },
    setArea1Obstacles: (state, action) => {
      state.area1Obstacles = action.payload;
    },
    setLoadingExportObstacle: (state, action) => {
      state.isLoadingExportObstacle = action.payload;
    },
    setLoadingEtodLayers: (state, action) => {
      state.isLoadingEtodLayers = action.payload;
    },
    setEtodLayers: (state, action) => {
      state.etodLayers = action.payload;
    },
    setLoadingEtodAixmAirports: (state, action) => {
      state.isLoadingEtodAixmAirports = action.payload;
    },
    setEtodAixmAirports: (state, action) => {
      state.etodAixmAirports = action.payload;
    },
    setLoadingGenerateEtodAirport: (state, action) => {
      state.isLoadingGenerateEtodAirport = action.payload;
    },
    setLoadingEtodDems: (state, action) => {
      state.isLoadingEtodDems = action.payload;
    },
    setEtodDems: (state, action) => {
      state.etodDems = action.payload;
    },
    setUplodeeTodDemFileRequest: (state, action) => {
      state.isUplodeeTodDemFileRequest = action.payload;
    },
    setLoadingExportAreaOls: (state, action) => {
      state.isLoadingExportAreaOls = action.payload;
    },
    setVisualiseObstacles: (state, action) => {
      state.visualiseObstacles = action.payload;
    },
    setLoadingAnalysisObstacles: (state, action) => {
      state.isLoadingAnalysisObstacles = action.payload;
    },
    setAnalysisObstacles: (state, action) => {
      state.analysisObstacles = action.payload;
    },
    setLoadingProcessObstacleAnalysis: (state, action) => {
      state.isLoadingProcessObstacleAnalysis = action.payload;
    },
    setLoadingUploadObstacleAnalysis: (state, action) => {
      state.isLoadingUploadObstacleAnalysis = action.payload;
    },
    setLoadingAirportCreatelayers: (state, action) => {
      state.isLoadingAirportCreatelayers = action.payload;
    },
    setCreatelayerAirportData: (state, action) => {
      state.createlayerAirportData = action.payload;
    },
    setLoadingAirportlayer: (state, action) => {
      state.isLoadingAirportlayer = action.payload;
    },
    setLoadingExportAirportLayers: (state, action) => {
      state.isLoadingExportAirportLayers = action.payload;
    },
    setLoadingDeleteAirportlayer: (state, action) => {
      state.isLoadingDeleteAirportlayer = action.payload;
    },
  },
});
