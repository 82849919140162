import React, { Fragment } from "react";
import { flatten, chain, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DArea({
  etodAreaList,
  selectedAreas,
  getAreaColors,
}) {
  const areaColor = getAreaColors();
  const visibleAreaList = chain(etodAreaList)
    .filter((area) => {
      let name = `${area.rwy}_${area.sub_category}`;
      if (area.category === "AREA4") {
        name = `${area.rwy}_${area.category}`;
      }
      return selectedAreas.includes(`${name}__${areaColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleAreaList.map((area, i) => {
        let name = `${area.rwy}_${area.sub_category}`;
        if (area.category === "AREA4") {
          name = `${area.rwy}_${area.category}`;
        }
        const areaCoordinates = area.coordinates.coordinates;
        const areaCoordinateMap = flatten(areaCoordinates).map((areaCoords) => {
          return areaCoords.map((areal) => {
            return [areal[0], areal[1], areal[2] * ZvalueMultiplyby];
          });
        });

        if (area.sub_category === "2C") {
          return areaCoordinateMap.map((coord, j) => {
            return (
              <Entity
                key={`${name}-${j}`}
                name={name}
                polygon={{
                  hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                    flatten(coord)
                  ),
                  extrudedHeight: 0,
                  perPositionHeight: true,
                  material: Cesium.Color.fromCssColorString(
                    areaColor[name]
                  ).withAlpha(0.5),
                  outline: true,
                }}
              />
            );
          });
        }

        return (
          <Entity
            key={`${name}-${i}`}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(areaCoordinateMap)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                areaColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
