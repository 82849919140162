import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tertiary.main,
    color: "#030303",
    fontSize:16,
    fontWeight:600,
    borderRadius: '3px 3px 0px 0px'
  },
  padding: "6px 10px",
}));

export default HeaderTableCell;