import React, { useEffect, useRef, useState } from "react";
import { Polygon, Polyline, Popup, FeatureGroup } from "react-leaflet";
import { useSelector } from "react-redux";
import { chain, flatten, split } from "lodash";
import { Box, Stack } from "@mui/material";
import { LightButton } from "@components/button";
import { mapDrawType, roles } from "@constants/constant";
import { areaHighLightColor } from "@constants/colors";
import { modifyCategories } from "@constants/amdbData";
import { styles } from "@styles/amdbStyles";

export default function RenderRunWayShape({
  selectedFeatures,
  rwyElementList,
  handleEditShap,
  handleDeleteShape,
  isEditShape,
}) {
  const popupElRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [rwyShapes, setRwyShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const rwyList = chain(rwyElementList)
      .filter((rwy) => {
        return selectedFeatures.includes(
          `${rwy.type}__${areaColorT[rwy.type]}`
        );
      })
      .value();

    setAreaColor(areaColorT);
    setRwyShapes(rwyList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = (e) => {
    e.target.bringToBack();
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.runway);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const handlePolylineEdit = (data) => {
    handleEditShap(data, modifyCategories.runway, "polyline");
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const handlePolygonDelete = (data) => {
    handleDeleteShape(data, modifyCategories.runway);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  return (
    <FeatureGroup>
      {rwyShapes.map((rwyGeometry) => {
        const rwyCoordinates = rwyGeometry.coordinates.coordinates;
        const coordinateType = rwyGeometry.coordinates.type;
        const rwyCoordinateMap = flatten(rwyCoordinates).map((rwyCoords) => {
          return rwyCoords.map((rwyel) => {
            return {
              lng: rwyel[0],
              lat: rwyel[1],
            };
          });
        });

        if (coordinateType === mapDrawType.polyline) {
          return rwyCoordinateMap.map((rwyPolygonPath, i) => (
            <Polyline
              key={i}
              positions={rwyPolygonPath}
              pathOptions={{
                color: areaColor[rwyGeometry.type],
                weight: selectedOverE?.type === rwyGeometry.type ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, rwyGeometry),
                mouseout: (e) => handlePolygonOut(e),
              }}
            >
              {!isEditShape && (
                <Popup ref={popupElRef} className="polygon-popup">
                  <Box sx={styles.apnShapPolygonPop}>
                    RunWay: {rwyGeometry?.type}
                  </Box>
                  {isAdmin && (
                    <Stack
                      sx={{ width: 200 }}
                      direction="row"
                      flexDirection="column"
                    >
                      <LightButton
                        label="Modify Polyline"
                        onClick={() => handlePolylineEdit(rwyGeometry)}
                        sx={styles.modifyShapeBtn}
                      />
                      <LightButton
                        label="Delete Polyline"
                        onClick={() => handlePolygonDelete(rwyGeometry)}
                        sx={{ p: 0, mr: 7 }}
                      />
                    </Stack>
                  )}
                </Popup>
              )}
            </Polyline>
          ));
        }

        return rwyCoordinateMap.map((rwyPolygonPath, i) => (
          <Polygon
            key={i}
            positions={rwyPolygonPath}
            pathOptions={{
              fillColor: areaColor[rwyGeometry.type],
              color:
                selectedOverE?.type === rwyGeometry.type
                  ? areaHighLightColor
                  : areaColor[rwyGeometry.type],
              weight: selectedOverE?.type === rwyGeometry.type ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, rwyGeometry),
              mouseout: (e) => handlePolygonOut(e),
            }}
          >
            {!isEditShape && (
              <Popup ref={popupElRef} className="polygon-popup">
                <Box sx={styles.apnShapPolygonPop}>
                  RunWay: {rwyGeometry?.type}
                </Box>
                {isAdmin && (
                  <Stack
                    sx={{ width: 200 }}
                    direction="row"
                    flexDirection="column"
                  >
                    <LightButton
                      label="Modify Polygon"
                      onClick={() => handlePolygonEdit(rwyGeometry)}
                      sx={{ p: 0, mt: 1, mb: 1, mr: 7 }}
                    />
                    <LightButton
                      label="Delete Polygon"
                      onClick={() => handlePolygonDelete(rwyGeometry)}
                      sx={{ p: 0, mr: 7 }}
                    />
                  </Stack>
                )}
              </Popup>
            )}
          </Polygon>
        ));
      })}
    </FeatureGroup>
  );
}
