import { take, flattenDeep, remove, isUndefined, difference } from "lodash";
import { surveyCategory } from "@constants/surveyorData";
import { ad2Category } from "@constants/ad2Data";

export const sheetValidation = (category, data) => {
  let diff = [];

  if (category === surveyCategory.approach) {
    const columns = [
      "S.NO",
      "UID ",
      "OBJECT",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN M.",
      "BASE ELEV. IN M.",
      "280M RUNWAY STRIP",
      "DISTANCE  (IN M)",
      "W.R.T  1:50 IN APP FUNNEL&1:7 IN T.S. (IN M.)",
      "W.R.T. I.H.S./C.S./O.H.S.   (IN M.)",
      "REMARKS",
      "PERMISSIBLE  TOP ELEV. IN M.",
      "OBSTACLE IN M.",
      "PERMISSIBLE TOP ELEV. IN M.",
      "OBSTACLE IN M.",
      "X",
      "Y",
      "YY",
    ];
    const sheetColumns = take(data, 4);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.ihp) {
    const columns = [
      "INTERMEDIATE HOLDING POSITION",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.ltp_fpap_garp) {
    const columns = [
      "LTP, FPAP & GARP",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.navaids) {
    const columns = [
      "NAVIGATIONAL AIDS",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "BASE ELEV. IN m. (EGM 08)",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.objects) {
    const columns = [
      "SNO",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "BASE ELEV. IN m. (EGM 08)",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.pacs_sacs) {
    const columns = [
      "PACS & SACS",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.parking_bay) {
    const columns = [
      "PARKING BAY DETAILS",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.rwy_points) {
    const columns = [
      "RWY POINTS",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.rwy_twy_intersection) {
    const columns = [
      "TWY POINTS",
      "UID",
      "POINT NAME",
      "LATITUDE",
      "LONGITUDE",
      "TOP ELEV. IN m. (EGM 08)",
      "ELLIPSOIDAL ELEV. IN M.",
    ];
    const sheetColumns = take(data, 2);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (category === surveyCategory.vertical_significance) {
    const columns = [
      "SNO",
      "UID",
      "LATITUDE",
      "LONGITUDE",
      "OBJECT NAME",
      "TOP ELEV. IN M (EGM-08)",
      "DISTANCE IN M. FROM NEW ARP",
      "MAG. BRG.FROM NEW ARP",
    ];
    const sheetColumns = take(data, 1);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (
    category === ad2Category.ad2_10_aerodom_obstacles ||
    category === surveyCategory.objectsObstacles
  ) {
    const columns = [
      "RWY /AREA AFFECTED",
      "OBSTACLE TYPE",
      "Latitude",
      "Longitude",
      "Elevation",
      "MARKING /LGT",
      "REMARKS",
      "IDENTIFIER",
      "Horizontal Datum",
      "Vertical Datum",
      "UOM Elevation",
      "RWY Direction",
      "Airport",
    ];
    const sheetColumns = take(data, 1);
    const flatten = flattenDeep(sheetColumns);
    remove(flatten, isUndefined);
    diff = difference(columns, flatten);
  }

  if (diff.length > 0) {
    return {
      isValid: false,
      message: `This columns are missing ${diff.join()}`,
    };
  }

  return {
    isValid: true,
    message: "",
  };
};
