import React from "react";

const ETodIcon = (props) => {
  return (
    <svg
      width={props.width || "97"}
      height={props.height || "40"}
      viewBox="0 0 97 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 30.3438L47.4481 13.6875L56.5584 23.0313M67.2533 34L56.5584 23.0313M56.5584 23.0313L71.2143 8L93 30.3438"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 38.5H3M95.5 38.5H27M27 38.5V11C27 9.89543 26.1046 9 25 9H21C19.8954 9 19 9.89543 19 11V19M27 38.5H19M19 38.5V19M19 38.5H3M19 19V3C19 1.89543 18.1046 1 17 1H5C3.89543 1 3 1.89543 3 3V38.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ETodIcon;
