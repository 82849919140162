import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { defaultLocationCenter } from "@constants/constant";

export default function ControlMapCenter({
  airportLocation = defaultLocationCenter,
  zoomLevel,
}) {
  const map = useMap();

  useEffect(() => {
    map.setView(airportLocation, zoomLevel, { animate: false });
  }, [airportLocation, zoomLevel]); // eslint-disable-line

  return null;
}
