import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingFIRData: false,
  isLoadingTMAData: false,
  isLoadingCTAData: false,
  isLoadingRegulatedAirspaceData: false,
  isLoadingRestrictiveAirspaceGeometry: false,
  FIRData: [],
  TMAData: [],
  CTAData: [],
  regulatedAirspaceData: [],
  restrictiveAirspaceGeometry: [],
  isLoadingFIRAirspaceGeometry: false,
  FIRAirspaceGeometry: [],
  isLoadingGetAllRoute: false,
  allRoutes: {},
  isLoadingGetRouteData: false,
  routeData: [],
  isLoadingAreaNavigationalRouteData: false,
  areaNavigationalRouteData: [],
  areaNavigationalLiveTrackRouteData: [],
  isLoadingConventionalRouteData: false,
  conventionalRouteData: [],
  conventionalLiveTrackRouteData: [],
  isLoadingGetAllNavaid: false,
  allNavaid: [],
  isLoadingGetAllsignificantPoint: false,
  allsignificantPoint: [],
  isLoadingGetAllDPR: false,
  allDPR: [],
  isLoadingGetAllTRA_TSA_ADIZ: false,
  allTRA_TSA_ADIZ: [],
  isLoadingGetADIZGeoData: false,
  adizGeoData: [],
  isLoadingRestrictiveAirspaceDetails: false,
  restrictiveAirspaceDetails: null,
  isLoadingRNAVRoutesGeometry: false,
  isLoadingCNAVRoutesGeometry: false,
  isLoadingRNAVRoutes: false,
  RNAVRoutesData: [],
  isLoadingCNAVRoutes: false,
  CNAVRoutesData: [],
  isLoadingAirspaceExport: false,
  isLoadingWaypointRouteData: false,
  waypointRouteData: [],
  isLoadingEnrouteExportJson: false,
  isAirspaceUsePlanDataLoading: false,
  airspaceUsePlanDates: [],
  militryAirspaceUsePlanData: null,
  routeAirspaceUsePlanData: null,
  isLoadingRoutePlanValidation: false,
  isAirspaceRouteBufferPointsLoading: false,
  isArrivalDepartureAirportRoutePlanLoading: false,
  arrvalDepAirportRouteData: [],
  routeThroughPointRPData: [],
  isRouteThroughPointRPLoading: false,
  isENRAirspaceGeometryLoading: false,
  isENRAirspaceGeometryFetchLoading: false,
  isENRAirspaceGeometryInfoLoading: false,
  ENRAirspaceGeometryOptions: null,
  ENRTMACoordsData: [],
  ENRMTRCoordsData: [],
  ENRPDRCoordsData: [],
  ENROTHERCoordsData: [],
  ENRADIZRCoordsData: [],
  ENRTRATSACoordsData: [],
  ENRAD217CoordsData: [],
  ENRAirspaceGeometryInfoData: null,
};

export const airspace = createSlice({
  name: "airspace",
  initialState,
  reducers: {
    setFIRdataLoadingRequest: (state, action) => {
      state.isLoadingFIRData = action.payload;
    },
    setTMAdataLoadingRequest: (state, action) => {
      state.isLoadingTMAData = action.payload;
    },
    setCTAdataLoadingRequest: (state, action) => {
      state.isLoadingCTAData = action.payload;
    },
    setRegulatedAirspaceDataLoadingRequest: (state, action) => {
      state.isLoadingRegulatedAirspaceData = action.payload;
    },
    setRestrictiveAirspaceGeometryLoadingRequest: (state, action) => {
      state.isLoadingRestrictiveAirspaceGeometry = action.payload;
    },
    setFIRDetails: (state, action) => {
      state.FIRData = action.payload;
    },
    setTMADetails: (state, action) => {
      state.TMAData = action.payload;
    },
    setCTADetails: (state, action) => {
      state.CTAData = action.payload;
    },
    setRegulatedAirspaceDetails: (state, action) => {
      state.regulatedAirspaceData = action.payload;
    },
    setRestrictiveAirspaceGeometryData: (state, action) => {
      state.restrictiveAirspaceGeometry = action.payload;
    },
    setFIRAirspaceGeometryLoadingRequest: (state, action) => {
      state.isLoadingFIRAirspaceGeometry = action.payload;
    },
    setFIRAirspaceGeometryData: (state, action) => {
      state.FIRAirspaceGeometry = action.payload;
    },
    setCTRLAirspaceGeometryData: (state, action) => {
      state.CTRLAirspaceGeometry = action.payload;
    },
    setLoadingGetAllRoute: (state, action) => {
      state.isLoadingGetAllRoute = action.payload;
    },
    setAllRoutes: (state, action) => {
      state.allRoutes = action.payload;
    },
    setLoadingGetRouteData: (state, action) => {
      state.isLoadingGetRouteData = action.payload;
    },
    setRouteData: (state, action) => {
      state.routeData = action.payload;
    },
    setLoadingAreaNavigationalRouteData: (state, action) => {
      state.isLoadingAreaNavigationalRouteData = action.payload;
    },
    setAreaNavigationalRouteData: (state, action) => {
      state.areaNavigationalRouteData = action.payload;
    },
    setAreaNavigationalLiveTrackRouteData: (state, action) => {
      state.areaNavigationalLiveTrackRouteData = action.payload;
    },
    setLoadingConventionalRouteData: (state, action) => {
      state.isLoadingConventionalRouteData = action.payload;
    },
    setConventionalRouteData: (state, action) => {
      state.conventionalRouteData = action.payload;
    },
    setConventionalLiveTrackRouteData: (state, action) => {
      state.conventionalLiveTrackRouteData = action.payload;
    },
    setLoadingGetAllNavaid: (state, action) => {
      state.isLoadingGetAllNavaid = action.payload;
    },
    setAllNavaid: (state, action) => {
      state.allNavaid = action.payload;
    },
    setLoadingGetAllsignificantPoint: (state, action) => {
      state.isLoadingGetAllsignificantPoint = action.payload;
    },
    setAllsignificantPoint: (state, action) => {
      state.allsignificantPoint = action.payload;
    },
    setLoadingGetAllDPR: (state, action) => {
      state.isLoadingGetAllDPR = action.payload;
    },
    setAllDPR: (state, action) => {
      state.allDPR = action.payload;
    },
    setLoadingGetAllTRA_TSA_ADIZ: (state, action) => {
      state.isLoadingGetAllTRA_TSA_ADIZ = action.payload;
    },
    setAllTRA_TSA_ADIZ: (state, action) => {
      state.allTRA_TSA_ADIZ = action.payload;
    },
    setLoadingGetADIZGeoData: (state, action) => {
      state.isLoadingGetADIZGeoData = action.payload;
    },
    setADIZGeoData: (state, action) => {
      state.adizGeoData = action.payload;
    },
    setLoadingRestrictiveAirspaceDetails: (state, action) => {
      state.isLoadingRestrictiveAirspaceDetails = action.payload;
    },
    setRestrictiveAirspaceDetails: (state, action) => {
      state.restrictiveAirspaceDetails = action.payload;
    },
    setLoadingRNAVRoutesGeometry: (state, action) => {
      state.isLoadingRNAVRoutesGeometry = action.payload;
    },
    setLoadingCNAVRoutesGeometry: (state, action) => {
      state.isLoadingCNAVRoutesGeometry = action.payload;
    },
    setLoadingRNAVRoutes: (state, action) => {
      state.isLoadingRNAVRoutes = action.payload;
    },
    setRNAVRoutesData: (state, action) => {
      state.RNAVRoutesData = action.payload;
    },
    setLoadingCNAVRoutes: (state, action) => {
      state.isLoadingCNAVRoutes = action.payload;
    },
    setCNAVRoutesData: (state, action) => {
      state.CNAVRoutesData = action.payload;
    },
    setLoadingAirspaceExport: (state, action) => {
      state.isLoadingAirspaceExport = action.payload;
    },
    setLoadingWaypointRouteData: (state, action) => {
      state.isLoadingWaypointRouteData = action.payload;
    },
    setWaypointRouteData: (state, action) => {
      state.waypointRouteData = action.payload;
    },
    setLoadingEnrouteExportJson: (state, action) => {
      state.isLoadingEnrouteExportJson = action.payload;
    },
    setAirspaceUsePlanDataLoading: (state, action) => {
      state.isAirspaceUsePlanDataLoading = action.payload;
    },
    setAirspaceUsePlanDataByIdLoading: (state, action) => {
      state.isAirspaceUsePlanDataByIdLoading = action.payload;
    },
    setAirspaceUsePlanDates: (state, action) => {
      state.airspaceUsePlanDates = action.payload;
    },
    setMilitryAirspaceUsePlanData: (state, action) => {
      state.militryAirspaceUsePlanData = action.payload;
    },
    setRouteAirspaceUsePlanData: (state, action) => {
      state.routeAirspaceUsePlanData = action.payload;
    },
    setLoadingRoutePlanValidation: (state, action) => {
      state.isLoadingRoutePlanValidation = action.payload;
    },
    setAirspaceRouteBufferPointsLoading: (state, action) => {
      state.isAirspaceRouteBufferPointsLoading = action.payload;
    },
    setArrivalDepartureAirportRoutePlanLoading: (state, action) => {
      state.isArrivalDepartureAirportRoutePlanLoading = action.payload;
    },
    setArrvalDepAirportRouteData: (state, action) => {
      state.arrvalDepAirportRouteData = action.payload;
    },
    setRouteThroughPointRPLoading: (state, action) => {
      state.isRouteThroughPointRPLoading = action.payload;
    },
    setRouteThroughPointRPData: (state, action) => {
      state.routeThroughPointRPData = action.payload;
    },
    setENRAirspaceGeometryLoading: (state, action) => {
      state.isENRAirspaceGeometryLoading = action.payload;
    },
    setENRAirspaceGeometryOptions: (state, action) => {
      state.ENRAirspaceGeometryOptions = action.payload;
    },
    setENRAirspaceGeometryFetchLoading: (state, action) => {
      state.isENRAirspaceGeometryFetchLoading = action.payload;
    },
    setENRTMACoordsData: (state, action) => {
      state.ENRTMACoordsData = action.payload;
    },
    setENRMTRCoordsData: (state, action) => {
      state.ENRMTRCoordsData = action.payload;
    },
    setENRPDRCoordsData: (state, action) => {
      state.ENRPDRCoordsData = action.payload;
    },
    setENROTHERCoordsData: (state, action) => {
      state.ENROTHERCoordsData = action.payload;
    },
    setENRADIZRCoordsData: (state, action) => {
      state.ENRADIZRCoordsData = action.payload;
    },
    setENRTRATSACoordsData: (state, action) => {
      state.ENRTRATSACoordsData = action.payload;
    },
    setENRAD217CoordsData: (state, action) => {
      state.ENRAD217CoordsData = action.payload;
    },
    setENRAirspaceGeometryInfoLoading: (state, action) => {
      state.isENRAirspaceGeometryInfoLoading = action.payload;
    },
    setENRAirspaceGeometryInfoData: (state, action) => {
      state.ENRAirspaceGeometryInfoData = action.payload;
    },
  },
});
