import React, { useRef } from "react";
import { Polyline, Popup, FeatureGroup } from "react-leaflet";
import Box from "@mui/material/Box";
import PopupBox from "@components/map/PopupBox";

const RenderSlopeline = ({ data }) => {
  const shapeRef = useRef(null);

  return (
    <FeatureGroup ref={shapeRef}>
      {data.map((geometry, i) => {
        const coordinates = geometry.geometry_json.coordinates;

        return (
          <Polyline
            key={`${geometry.id}_${i}`}
            positions={coordinates}
            pathOptions={{
              color: "red",
              weight: 2,
              dashArray: "5, 10",
              lineCap: "square",
              opacity: 1,
            }}
          >
            <Popup>
              <PopupBox>
                <Box>Slop Line</Box>
                <Box></Box>
                <Box>Rwy Direction:</Box>
                <Box>{geometry.rwy_direction}</Box>
                <Box>Slope Perc:</Box>
                <Box>{geometry.slope_perc}</Box>
              </PopupBox>
            </Popup>
          </Polyline>
        );
      })}
    </FeatureGroup>
  );
};

export default RenderSlopeline;
