import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderOLSApproach({
  OLSApproachList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSApproachList = chain(OLSApproachList)
    .filter((approachObj) => {
      const name = `${approachObj.category}_${approachObj.rwy_dir}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSApproachList.map((olsApproach) => {
        const approachCoordinates = olsApproach.coordinates.coordinates;
        const name = `${olsApproach.category}_${olsApproach.rwy_dir}`;
        const approachCoordinateMap = flatten(approachCoordinates).map(
          (approachCoords) => {
            return approachCoords.map((approach) => {
              return [approach[1], approach[0]];
            });
          }
        );

        return approachCoordinateMap.map((approachPolygonPath, i) => (
          <Polygon
            key={i}
            pane="tilePane"
            positions={approachPolygonPath}
            pathOptions={{
              fillColor: olsColor[name],
              color:
                selectedOverE?.rwy_dir === olsApproach.rwy_dir
                  ? areaHighLightColor
                  : olsColor[name],
              weight: selectedOverE?.rwy_dir === olsApproach.rwy_dir ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, olsApproach),
              mouseout: () => handlePolygonOut(),
            }}
          />
        ));
      })}
    </Fragment>
  );
}
