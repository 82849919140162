import React, { Fragment } from "react";
import { chain, flatten, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DOLSOTransitionalSurface({
  OLSOuterTransitionalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSTransitionalSurfaceList = chain(
    OLSOuterTransitionalSurfaceList
  )
    .filter((tslObj) => {
      const name = `${tslObj.category}_${tslObj.rwy}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleOLSTransitionalSurfaceList.map((tslObj, i) => {
        const tslCoordinates = tslObj.coordinates.coordinates;
        const name = `${tslObj.category}_${tslObj.rwy}`;
        const tslCoordinateMap = flatten(tslCoordinates).map((tslCoords) => {
          return tslCoords.map((tsl) => {
            return [tsl[0], tsl[1], tsl[2] * ZvalueMultiplyby];
          });
        });

        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(tslCoordinateMap)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
