import React from "react";
import Typography from "@mui/material/Typography";
import RowStack from "@components/tabel-component/RowStack";

export default function AirportDetailTableRow({ selectedReport }) {
  return (
    <RowStack
      direction="row"
      justifyContent="space-between"
      sx={{
        position: "fixed",
        width: "calc(100vw - 312px)",
        marginTop: "39px",
      }}
    >
      <Typography variant="body1" gutterBottom>
        {`${selectedReport?.airport_name} -> ${selectedReport?.feature_name}`}
      </Typography>
    </RowStack>
  );
}
