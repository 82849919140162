import { theme } from "./theme";

export const styles = {
  EnRouteShowDetails: {
    width: "100%",
    zIndex: 1002,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    bottom: "0",
  },
  EnRouteDetailsCloseIcon: {
    position: "absolute",
    height: "19px",
    width: "19px",
    zIndex: 1001,
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
    svg: {
      pt: "1px",
    },
  },
  airspaceMapContent: {
    zIndex: 1010,
    display: "flex",
    justifyContent: "center",
    top: 10,
  },
  AUPCloseBtn: {
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "5px",
    width: "98%",
    flexDirection: "column",
  },
  AUPMain: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "5px",
    width: "100%",
    flexDirection: "column",
    gap: 1.3,
  },
  AUPDetails: {
    display: "flex",
    justifyContent: "space-between",
    pt: 1.5,
    pl: 2,
    pr: 2,
    pb: 1,
  },
  AUPSeeDetailBtn: {
    padding: 0,
    borderBottom: `0.5px solid ${theme.palette.primary.main}`,
    height: 19,
    ml: 1,
    borderRadius: 0,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  AUPText: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.black.main,
  },
  airspacePlanId: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#42444A",
  },
  AUPSliderBox: {
    height: 90,
    border: `0.5px solid ${theme.palette.grey.borderclr}`,
    borderRadius: "4px",
  },
  AUPSlider: {
    width: "100%",
    height: "inherit",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  AirspaceDetailBtn: {
    position: "absolute",
    zIndex: 1001,
    right: 12,
    bottom: 2,
  },
  AirspaceDetailMain: {
    width: "100%",
    position: "absolute",
    bottom: 300,
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
  },
  AirspaceDetails: {
    height: 300,
    backgroundColor: theme.palette.white.main,
    borderRadius: 1,
  },
  AirspaceDetailCloseBtn: {
    position: "absolute",
    zIndex: 1001,
    height: "19px",
    width: "19px",
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    right: "-8px",
    top: "-5px",
    cursor: "pointer",
    svg: {
      pt: "1px",
    },
  },
  airspaceCatTable: {
    backgroundColor: theme.palette.secondary.main,
    height: "36px",
    fontSize: 14,
    color: theme.palette.white.main,
    display: "flex",
    alignItems: "center",
    pl: 1.5,
  },
  airspaceGenData: {
    backgroundColor: theme.palette.secondary.light,
    mb: 1,
    pl: 1.5,
    fontSize: "14px",
    height: "25px",
    lineHeight: "25px",
  },
  airspaceENRkey: {
    backgroundColor: theme.palette.secondary.light,
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "12px",
    fontWeight: 500,
  },
  airspaceENRLabel: {
    fontSize: "14px",
    fontWeight: 500,
  },
  allENRData: {
    ml: 1.5,
    mr: 1.5,
    mt: 1.5,
    whiteSpace: "pre-line",
  },
  RoutestartpointText: {
    fontSize: 14,
    color: theme.palette.black.light,
    textShadow: "none",
  },
  AIRACCycle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    position: "absolute",
    zIndex: "9999",
    minWidth: "98px",
    height: "70px",
    top: 8,
    right: 2,
    borderRadius: "4px 0px 0px 4px",
    backgroundColor: "#fff",
    gap: 1,
    boxShadow: "0px 0px 3px 0px #00000080",
  },
};
