import { toast } from "react-toastify";
import {
  GET_AMDB_AIRPORT_URL,
  GET_ECHART_PDF_DATA_URL,
  GET_ECHART_DATA_URL,
  ECHART_EDIT_DELETE_DATA_URL,
  ECHART_EXPORT_URL,
  ECHART_ATOCA_URL,
  ECHART_TABLE_DETAIL_URL,
} from "@constants/urls";
import { apiCall } from "@utils/connect";
import {
  setLoadingAlleChartAirport,
  setAlleChartAirports,
  setLoadingechartPDFData,
  setechartPDFData,
  setLoadingechartData,
  setechartData,
  setLoadingEditDeletePolyline,
  setLoadingAlleChartExportUrl,
  setAlleChartExportUrls,
  setLoadingATOCAData,
  setATOCAData,
  setLoadingEchartTableData,
  setTableData,
} from ".";

export const geteChartAirports = () => (dispatch) => {
  try {
    dispatch(setLoadingAlleChartAirport(true));

    const onSuccess = (response) => {
      dispatch(setAlleChartAirports(response.data));
      dispatch(setLoadingAlleChartAirport(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAlleChartAirport(false));
    };

    apiCall("GET", GET_AMDB_AIRPORT_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAlleChartAirport(false));
    toast.error(error.message);
  }
};

export const geteChartExportUrls = (airportId, token) => (dispatch) => {
  try {
    dispatch(setLoadingAlleChartExportUrl(true));

    const onSuccess = (response) => {
      dispatch(setAlleChartExportUrls(response.data));
      dispatch(setLoadingAlleChartExportUrl(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAlleChartExportUrl(false));
    };

    apiCall(
      "GET",
      ECHART_EXPORT_URL(airportId),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAlleChartExportUrl(false));
    toast.error(error.message);
  }
};

export const geteChartPDFData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingechartPDFData(true));

    const onSuccess = (response) => {
      dispatch(setechartPDFData(response.data));
      dispatch(setLoadingechartPDFData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingechartPDFData(false));
    };

    apiCall(
      "GET",
      GET_ECHART_PDF_DATA_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingechartPDFData(false));
    toast.error(error.message);
  }
};

export const geteChartData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingechartData(true));

    const onSuccess = (response) => {
      dispatch(setechartData(response.data));
      dispatch(setLoadingechartData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingechartData(false));
    };

    apiCall("GET", GET_ECHART_DATA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingechartData(false));
    toast.error(error.message);
  }
};

export const editDeleteEchartPolyline =
  (method, payload, polyId, airportId, token, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingEditDeletePolyline(true));
      const onSuccess = () => {
        toast.success("Polyline updated successfully");

        const onSuccessEchart = (response) => {
          dispatch(setechartData(response.data));
          dispatch(setLoadingEditDeletePolyline(false));
          callback();
        };
        const onFailureEcgart = (error) => {
          toast.error(error.message);
          dispatch(setLoadingEditDeletePolyline(false));
          callback();
        };

        apiCall(
          "GET",
          GET_ECHART_DATA_URL(airportId),
          "",
          onSuccessEchart,
          onFailureEcgart
        );
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingEditDeletePolyline(false));
      };

      apiCall(
        method,
        ECHART_EDIT_DELETE_DATA_URL(polyId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingEditDeletePolyline(false));
      toast.error(error.message);
    }
  };

export const getATOCAData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingATOCAData(true));

    const onSuccess = (response) => {
      dispatch(setATOCAData(response.data));
      dispatch(setLoadingATOCAData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingATOCAData(false));
    };

    apiCall("GET", ECHART_ATOCA_URL(airportId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingATOCAData(false));
    toast.error(error.message);
  }
};

export const getEchartTableData = (airportId) => (dispatch) => {
  try {
    dispatch(setLoadingEchartTableData(true));

    const onSuccess = (response) => {
      dispatch(setTableData(response.data));
      dispatch(setLoadingEchartTableData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingEchartTableData(false));
    };

    apiCall(
      "GET",
      ECHART_TABLE_DETAIL_URL(airportId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingEchartTableData(false));
    toast.error(error.message);
  }
};
