import React, { Fragment } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, uniqBy } from "lodash";
import {
  Box,
  Checkbox,
  Divider,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
} from "@mui/material";
import {
  aixmFilterOptions,
  aixmFilterType,
  aixmAirportExportItems,
  AD1SubCategories,
  aerodromeFilterType,
  ADCategoryOptions,
  enrOptions,
} from "@constants/aixmData";
import { ACCESS_TOKEN } from "@constants/constant";
import { airportDataCategories } from "@constants/ad2Data";
import { airspaceFilterType } from "@constants/airspaceData";
import { LightButton, PrimaryButton } from "@components/button";
import CategoryCard from "@components/core/CategoryCard";
import { exportXMLAD2Data } from "@store/airport/actions";
import { styles } from "@styles/aixmStyles";
import { commonStyles } from "@styles/commonStyles";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import AixmAirportExportButton from "./AixmAirportExportButton";
import DropDownLabel from "./DropDownLabel";

export default function AixmSideBar({
  isLoadingAirport,
  isLoadingFIRData,
  isLoadingTMAData,
  isLoadingCTAData,
  isLoadingRegulatedAirspaceData,
  isLoadingGetAllRoute,
  isLoadingGetAllNavaid,
  isLoadingGetAllsignificantPoint,
  isLoadingGetAllDPR,
  isLoadingGetAllTRA_TSA_ADIZ,
  isLoadingGetRouteData,
  allAirports = [],
  selctedAirport,
  selectedCategories,
  selctedFeature,
  selctedAirspaceFilter,
  firOptions,
  selectedAirspaceRows,
  TMAOptions,
  CTAOptions,
  regulatedAirspaceOptions,
  allRoutesOptions,
  selctedRouteFilter,
  routesNameList,
  selectedRoute,
  navaidOptions,
  significantPointOptions,
  allDPROptions,
  selctedDPRFilter,
  DPRTypeFilterOptions,
  dprNameList,
  hasSectedAirspace,
  handleAirport,
  handleFeature,
  handleAirspaceFilter,
  handleAirspaceSearch,
  handleAirspaceNameCheck,
  handleAirspaceDesignatorCheck,
  handleAiespaceAerodromeCheck,
  handleDPRMetaCheck,
  handleAirspaceAllSelect,
  handleRouteFilter,
  handleRoute,
  handleAirspaceClear,
  handleDPRFilter,
  selctedDPRTypeFilter,
  handleDPRTypeFilter,
  allTRA_TSA_ADIZOptions,
  selctedMETAFilter,
  handleMETAFilter,
  METANameList,
  handleOpenCloseExportModal,
  handleAirportFromCategory,
  selectedAD2Category,
  generalOptions,
  isLoadingGeneral,
  selectedGeneral,
  selectedGeneralCategory,
  generalCategoryOptions,
  handleGeneral,
  handleGeneralCategory,
  selectedAerodrome,
  handleAerodrome,
  handleAerodromeCategories,
  selectedAD1Category,
  isLoadingAD1Aerodrome,
  selectedEnrouteCategory,
  handleEnrouteCategory,
  EnrSubCategoryOptions,
  isLoadingAD1_3Aerodrome,
  isLoadingAD1_5Aerodrome,
}) {
  const dispatch = useDispatch();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const currentUser = useSelector((state) => state.userSession.user);
  const isExportAixmXMLLoading = useSelector(
    (state) => state.airport.isExportXMLAD2Loading
  );
  const isAerodomAD1DataLoading =
    isLoadingAD1Aerodrome || isLoadingAD1_3Aerodrome || isLoadingAD1_5Aerodrome;
  const isAirspaceDisabled =
    (isLoadingFIRData &&
      selctedAirspaceFilter?.value === airspaceFilterType.firuir) ||
    (isLoadingTMAData &&
      selctedAirspaceFilter?.value === airspaceFilterType.tma) ||
    (isLoadingCTAData &&
      selctedAirspaceFilter?.value === airspaceFilterType.controlArea) ||
    (isLoadingRegulatedAirspaceData &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace) ||
    isLoadingGetAllRoute ||
    isLoadingGetRouteData ||
    (isLoadingGetAllNavaid &&
      selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation) ||
    (isLoadingGetAllsignificantPoint &&
      selctedAirspaceFilter?.value === airspaceFilterType.significantPoints) ||
    (isLoadingGetAllDPR &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas) ||
    (isLoadingGetAllTRA_TSA_ADIZ &&
      selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas);

  const isExportDisabled =
    selctedAirspaceFilter?.value === airspaceFilterType.firuir ||
    selctedAirspaceFilter?.value === airspaceFilterType.tma ||
    selctedAirspaceFilter?.value === airspaceFilterType.controlArea ||
    selctedAirspaceFilter?.value ===
      airspaceFilterType.otherRegulatedAirspace ||
    selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation ||
    selctedAirspaceFilter?.value === airspaceFilterType.significantPoints ||
    (selctedAirspaceFilter?.value ===
      airspaceFilterType.prohibitedRestrictedandDangerAreas &&
      selctedDPRFilter !== null &&
      selctedDPRTypeFilter !== null) ||
    (selctedAirspaceFilter?.value === airspaceFilterType.routes &&
      selctedRouteFilter !== null) ||
    (selctedAirspaceFilter?.value ===
      airspaceFilterType.militaryExecriseandTrainingAreas &&
      selctedMETAFilter !== null);

  let listClass = "check-list";
  if (
    selctedAirspaceFilter &&
    (selctedAirspaceFilter?.value === airspaceFilterType.firuir ||
      selctedAirspaceFilter?.value === airspaceFilterType.tma ||
      selctedAirspaceFilter?.value === airspaceFilterType.controlArea ||
      selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace ||
      selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation ||
      selctedAirspaceFilter?.value === airspaceFilterType.significantPoints)
  ) {
    listClass = "check-list airspace-check-list";
  } else if (selctedAirspaceFilter?.value === airspaceFilterType.routes) {
    if (selctedRouteFilter) {
      listClass = "check-list airspace-check-list-2 airspace-check-list-2-2";
    } else {
      listClass = "check-list airspace-check-list-2";
    }
  } else if (
    selctedAirspaceFilter?.value ===
    airspaceFilterType.militaryExecriseandTrainingAreas
  ) {
    if (selctedMETAFilter) {
      listClass = "check-list airspace-check-list-3 airspace-check-list-4-4";
    } else {
      listClass = "check-list airspace-check-list-3";
    }
  } else if (
    selctedAirspaceFilter?.value ===
    airspaceFilterType.prohibitedRestrictedandDangerAreas
  ) {
    if (selctedDPRFilter) {
      listClass = "check-list airspace-check-list-4";
      if (selctedDPRTypeFilter) {
        listClass = "check-list airspace-check-list-4 airspace-check-list-4-4";
      }
    } else {
      listClass = "check-list airspace-check-list-3";
    }
  } else if (isEmpty(selectedEnrouteCategory)) {
    listClass = "check-list check-list-1";
  }

  const handleExportAixmXML = (type, airport) => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (currentUser?.user_access?.export || accessToken) {
        dispatch(exportXMLAD2Data(type, airport, token));
      }
    });
  };

  const handleExportModal = () => {
    if (Boolean(accessToken)) handleOpenCloseExportModal(true);
  };

  return (
    <div className="sidebar">
      <Box>
        <DropDownLabel label="Feature" isRequired />
        <Select
          isClearable
          placeholder="Select..."
          value={selctedFeature || null}
          onChange={handleFeature}
          options={aixmFilterOptions}
          styles={commonStyles.selectScrollbar}
        />
      </Box>
      {/* General Feature */}
      {selctedFeature?.value === aixmFilterType.general && (
        <>
          <Box sx={{ mt: 2 }}>
            <DropDownLabel label="General" isRequired />
            <Select
              isClearable
              isLoading={isLoadingGeneral}
              value={selectedGeneral || null}
              placeholder="Select..."
              onChange={handleGeneral}
              options={generalOptions}
              isDisabled={isLoadingGeneral}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              styles={commonStyles.selectScrollbar}
            />
          </Box>
          {selectedGeneral && (
            <Box sx={{ mt: 2 }}>
              <DropDownLabel label="Category" isRequired />
              <Select
                isClearable
                isLoading={isLoadingGeneral}
                value={selectedGeneralCategory || null}
                placeholder="Select..."
                onChange={handleGeneralCategory}
                options={generalCategoryOptions}
                isDisabled={isLoadingGeneral}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
          )}
        </>
      )}
      {/* Aerodrome (AD) (Airport) */}
      {selctedFeature?.value === aixmFilterType.airport && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100% - 72px)",
          }}
        >
          <div style={{ marginBottom: 16 }}>
            <Box sx={{ mt: 2 }}>
              <DropDownLabel label="Aerodromes" isRequired />
              <Select
                isClearable
                isLoading={isLoadingAirport}
                isDisabled={isLoadingAirport}
                value={selectedAerodrome || null}
                placeholder="Select..."
                onChange={handleAerodrome}
                options={ADCategoryOptions}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
            {selectedAerodrome?.value === aerodromeFilterType.AD1 && (
              <Box sx={{ mt: 2 }}>
                <DropDownLabel label="Category" isRequired />
                <Select
                  isClearable
                  isLoading={isAerodomAD1DataLoading}
                  isDisabled={isAerodomAD1DataLoading}
                  value={selectedAD1Category || null}
                  placeholder="Select..."
                  onChange={handleAerodromeCategories}
                  options={AD1SubCategories}
                  styles={commonStyles.selectScrollbar}
                />
              </Box>
            )}
            {selectedAerodrome?.value === aerodromeFilterType.AD2 && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <DropDownLabel label="AD 2 (Aerodromes)" isRequired />
                <Select
                  isClearable
                  isLoading={isLoadingAirport}
                  value={selctedAirport || null}
                  placeholder="Select..."
                  onChange={handleAirport}
                  options={allAirports}
                  getOptionLabel={(option) => `${option.icao} (${option.name})`}
                  getOptionValue={(option) => option.name}
                  styles={commonStyles.selectScrollbar}
                />
              </Box>
            )}
            {selctedAirport &&
              selectedAerodrome?.value === aerodromeFilterType.AD2 && (
                <>
                  <DropDownLabel label="Category" isRequired />
                  <Box sx={styles.AD2Category}>
                    {airportDataCategories &&
                      airportDataCategories.map((category, index) => (
                        <CategoryCard
                          key={index}
                          categoryData={category}
                          activeCategory={selectedAD2Category}
                          selectedCategories={selectedCategories}
                          handleActiveCategory={handleAirportFromCategory}
                        />
                      ))}
                  </Box>
                </>
              )}
          </div>
          <AixmAirportExportButton
            label="Export XML"
            items={aixmAirportExportItems}
            handleExportXML={(type) =>
              handleExportAixmXML(type, selctedAirport)
            }
            isExportXMLLoading={isExportAixmXMLLoading}
            isExportXMLDisabled={!selctedAirport}
          />
        </div>
      )}
      {/* En-Route (ENR) (Airspace) */}
      {selctedFeature?.value === aixmFilterType.airspace && (
        <Fragment>
          <Box sx={{ mt: 2 }}>
            <DropDownLabel label="En-Route" isRequired />
            <Select
              isClearable
              placeholder="Select..."
              value={selectedEnrouteCategory || null}
              onChange={handleEnrouteCategory}
              options={enrOptions}
              styles={commonStyles.selectScrollbar}
            />
          </Box>
          {!isEmpty(selectedEnrouteCategory) && (
            <Box sx={{ mt: 2 }}>
              <DropDownLabel label="Category" isRequired />
              <Select
                isClearable
                placeholder="Select..."
                value={selctedAirspaceFilter || null}
                onChange={handleAirspaceFilter}
                options={EnrSubCategoryOptions}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value === airspaceFilterType.routes && (
            <Box sx={{ mt: 2 }}>
              <DropDownLabel label="Route" isRequired />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedRouteFilter || null}
                onChange={handleRouteFilter}
                options={allRoutesOptions}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.militaryExecriseandTrainingAreas && (
            <Box sx={{ mt: 1 }}>
              <DropDownLabel label="Area" isRequired />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedMETAFilter || null}
                onChange={handleMETAFilter}
                options={allTRA_TSA_ADIZOptions}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.prohibitedRestrictedandDangerAreas && (
            <Box sx={{ mt: 2 }}>
              <DropDownLabel label="DPR" isRequired />
              <Select
                isClearable
                isDisabled={isAirspaceDisabled}
                placeholder="Select..."
                value={selctedDPRFilter || null}
                onChange={handleDPRFilter}
                options={allDPROptions}
                styles={commonStyles.selectScrollbar}
              />
            </Box>
          )}
          {selctedAirspaceFilter?.value ===
            airspaceFilterType.prohibitedRestrictedandDangerAreas &&
            selctedDPRFilter && (
              <Box sx={{ mt: 2 }}>
                <DropDownLabel label="Type" isRequired />
                <Select
                  isClearable
                  isDisabled={isAirspaceDisabled}
                  placeholder="Select..."
                  value={selctedDPRTypeFilter || null}
                  onChange={handleDPRTypeFilter}
                  options={DPRTypeFilterOptions}
                  styles={commonStyles.selectScrollbar}
                />
              </Box>
            )}
          {isAirspaceDisabled && !hasSectedAirspace ? (
            <LinearProgress sx={{ mt: 2, mb: 1 }} />
          ) : (
            <Divider sx={{ mt: 2, mb: 1 }} />
          )}
          {selctedAirspaceFilter &&
            selctedAirspaceFilter?.value !== airspaceFilterType.routes &&
            !selctedAirspaceFilter?.isEnrApi && (
              <>
                <div className="check-list-header">
                  <div className="check-list-action">
                    <LightButton
                      label="SELECT ALL"
                      onClick={handleAirspaceAllSelect}
                      disabled={isAirspaceDisabled}
                      sx={{ p: 0 }}
                    />
                    <LightButton
                      label="CLEAR ALL"
                      onClick={handleAirspaceClear}
                      disabled={isAirspaceDisabled}
                      sx={{ p: 0 }}
                    />
                  </div>
                </div>
                <TextField
                  fullWidth
                  size="small"
                  label="Search"
                  onChange={handleAirspaceSearch}
                  sx={{ mt: 0, mb: 2, zIndex: 0 }}
                />
              </>
            )}

          <div className={listClass}>
            {selctedAirspaceFilter?.value === airspaceFilterType.firuir &&
              firOptions.map((fir) => (
                <ListItem key={fir.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingFIRData}
                    onClick={() => handleAirspaceNameCheck(fir)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(fir.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": fir.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={fir.id} primary={fir.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.tma &&
              TMAOptions.map((tma) => (
                <ListItem key={tma.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingTMAData}
                    onClick={() => handleAirspaceNameCheck(tma)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(tma.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": tma.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={tma.id} primary={tma.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.controlArea &&
              uniqBy(CTAOptions, "name").map((cta) => (
                <ListItem key={cta.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingCTAData}
                    onClick={() => handleAirspaceNameCheck(cta)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(cta.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": cta.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={cta.id} primary={cta.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.otherRegulatedAirspace &&
              regulatedAirspaceOptions.map((ra) => (
                <ListItem key={ra.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingRegulatedAirspaceData}
                    onClick={() => handleAiespaceAerodromeCheck(ra)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(ra.Aerodrome)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": ra.Aerodrome }}
                      />
                    </ListItemIcon>
                    <ListItemText id={ra.id} primary={ra.Aerodrome} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value === airspaceFilterType.routes &&
              routesNameList.map((route) => (
                <ListItem key={route.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllRoute}
                    selected={selectedRoute?.id === route.id}
                    onClick={() => handleRoute(route)}
                    dense
                  >
                    <ListItemIcon sx={{ minWidth: "42px" }}>
                      <Radio
                        value={route.id}
                        checked={selectedRoute?.id === route.id}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-label": `${route.designatorSecondLetter}${
                            route.designatorNumber
                          }${
                            route.multipleIdentifier
                              ? route.multipleIdentifier
                              : ""
                          }`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={route.id}
                      primary={`${route.designatorSecondLetter}${
                        route.designatorNumber
                      }${
                        route.multipleIdentifier ? route.multipleIdentifier : ""
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.radioNavigation &&
              navaidOptions.map((rn) => (
                <ListItem key={rn.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllNavaid}
                    onClick={() => handleAirspaceDesignatorCheck(rn)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(rn.designator)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": rn.designator }}
                      />
                    </ListItemIcon>
                    <ListItemText id={rn.id} primary={rn.designator} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.significantPoints &&
              significantPointOptions.map((sp) => (
                <ListItem key={sp.id} disablePadding>
                  <ListItemButton
                    disabled={isLoadingGetAllsignificantPoint}
                    onClick={() => handleAirspaceNameCheck(sp)}
                    dense
                  >
                    <ListItemIcon sx={{ mr: -3 }}>
                      <Checkbox
                        edge="start"
                        checked={selectedAirspaceRows.includes(sp.name)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": sp.name }}
                      />
                    </ListItemIcon>
                    <ListItemText id={sp.id} primary={sp.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.prohibitedRestrictedandDangerAreas &&
              dprNameList.map((dpr) => {
                const dprName = `${dpr.Airspace_name} ${dpr.designator}`;
                return (
                  <ListItem key={dpr.id} disablePadding>
                    <ListItemButton
                      disabled={isLoadingGetAllDPR}
                      onClick={() => handleDPRMetaCheck(dpr)}
                      dense
                    >
                      <ListItemIcon sx={{ mr: -3 }}>
                        <Checkbox
                          edge="start"
                          checked={selectedAirspaceRows.includes(dprName)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": dprName }}
                        />
                      </ListItemIcon>
                      <ListItemText id={dpr.id} primary={dprName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            {selctedAirspaceFilter?.value ===
              airspaceFilterType.militaryExecriseandTrainingAreas &&
              METANameList.map((meta) => {
                const metaName = `${meta.Airspace_name} ${meta.designator}`;
                return (
                  <ListItem key={meta.id} disablePadding>
                    <ListItemButton
                      disabled={isLoadingGetAllTRA_TSA_ADIZ}
                      onClick={() => handleDPRMetaCheck(meta)}
                      dense
                    >
                      <ListItemIcon sx={{ mr: -3 }}>
                        <Checkbox
                          edge="start"
                          checked={selectedAirspaceRows.includes(metaName)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": metaName }}
                        />
                      </ListItemIcon>
                      <ListItemText id={meta.id} primary={metaName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </div>

          <PrimaryButton
            label="Export"
            disabled={
              !isExportDisabled ||
              !Boolean(accessToken) ||
              !currentUser?.user_access?.export
            }
            sx={styles.exportBtn}
            onClick={handleExportModal}
          />
        </Fragment>
      )}
    </div>
  );
}
