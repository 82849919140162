import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { fill, isEmpty } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { OutlinedButton, PrimaryButton } from "@components/button";
import { ACCESS_TOKEN } from "@constants/constant";
import { addShapValidationSchema } from "@constants/amdbData";
import { addEditDeleteAMDBCreateGeometry } from "@store/amdb/actions";
import { getAsyncStorageValue } from "@utils/localStorage";
import { toast } from "react-toastify";

export default function EditAmdbShapModal({
  isOpen,
  selctedAirport,
  coords,
  handleClose,
  layerType,
  editabletype,
  editableLayer,
  editableMarkerLayer,
  editableLineLayer,
  editLineIndex,
}) {
  const dispatch = useDispatch();
  const isLoadingAddEditDeletePolygon = useSelector(
    (state) => state.amdb.isLoadingAddEditDeletePolygon
  );

  useEffect(() => {
    if (layerType === "circlemarker" && editableMarkerLayer) {
      editShapForm.setValues({
        feature: "baypoint",
        shape_name: editableMarkerLayer?.point_name || "",
        uid: editableMarkerLayer.uid,
        top_elev: editableMarkerLayer.top_elev,
        ellipsoida: editableMarkerLayer.ellipsoida,
      });
    } else if (layerType === "polygon" && editableLayer) {
      if (editabletype === "APN_ELE") {
        editShapForm.setValues({
          feature: "apron",
          shape_name: editableLayer?.apn || "",
        });
      }
      if (editabletype === "RWY_ELE") {
        editShapForm.setValues({
          feature: "runway",
          shape_name: editableLayer?.type || "",
        });
      }
      if (editabletype === "TWY_ELE") {
        editShapForm.setValues({
          feature: "taxiway",
          shape_name: editableLayer?.twy || "",
        });
      }
    } else if (layerType === "multipolyline" && editableLineLayer) {
      editShapForm.setValues({
        feature: "bayline",
      });
    } else if (layerType === "polyline") {
      if (editabletype === "APN_ELE") {
        editShapForm.setValues({
          feature: "apron",
          shape_name: editableLayer?.apn || "",
        });
      }
      if (editabletype === "RWY_ELE") {
        editShapForm.setValues({
          feature: "runway",
          shape_name: editableLayer?.type || "",
        });
      }
      if (editabletype === "TWY_ELE") {
        editShapForm.setValues({
          feature: "taxiway",
          shape_name: editableLayer?.twy || "",
        });
      }
    }
    // eslint-disable-next-line
  }, [
    editableMarkerLayer,
    editableLayer,
    layerType,
    editabletype,
    editableLineLayer,
  ]);

  const editShapForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      feature: "",
      shape_name: "",
      uid: "",
      top_elev: "",
      ellipsoida: "",
    },
    validationSchema: addShapValidationSchema,
    onSubmit: (values) => {
      if (isEmpty(coords)) return toast.warning("First edit shape!");
      let payload;
      if (layerType === "polygon") {
        payload = {
          data: {
            geometry: coords[0],
          },
          name: values.shape_name || "",
          details: {},
          polyId: editableLayer?.id,
        };
      } else if (layerType === "polyline") {
        payload = {
          data: {
            geometry: coords[0],
          },
          name: values.shape_name || "",
          details: {},
          polyId: editableLayer?.id,
        };
      } else if (layerType === "circlemarker") {
        payload = {
          data: {
            geometry: coords,
          },
          name: values.shape_name || "",
          details: {
            uid: values.uid,
            latitude: coords[1],
            longitude: coords[0],
            top_elev: String(values.top_elev),
            ellipsoida: String(values.ellipsoida),
          },
          polyId: editableMarkerLayer?.id,
        };
      } else if (layerType === "multipolyline") {
        const oldCoords = editableLineLayer?.coordinates?.coordinates;
        const newEditedCoords = fill(
          [...oldCoords],
          coords,
          editLineIndex,
          oldCoords.length
        );
        payload = {
          data: {
            geometry: newEditedCoords,
          },
          name: values.shape_name || "",
          details: {},
          polyId: editableLineLayer?.id,
        };
      }
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditDeleteAMDBCreateGeometry(
            "PUT",
            payload,
            selctedAirport?.id,
            values.feature,
            token,
            handleEditShapDialogCallback
          )
        );
      });
    },
  });
  const handleEditShapDialogCallback = () => {
    editShapForm.resetForm();
    editShapForm.setSubmitting(false);
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle sx={{ pt: 3, pb: 3 }}>Edit Shape</DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <form>
          <FormControl
            fullWidth
            sx={{ mt: 1 }}
            error={
              editShapForm.touched.feature &&
              Boolean(editShapForm.errors.feature)
            }
          >
            <InputLabel id="category">Feature*</InputLabel>
            {(layerType === "polygon" || layerType === "polyline") && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={editShapForm.values.feature}
                onChange={editShapForm.handleChange}
                readOnly={true}
              >
                <MenuItem value="runway">RunWay</MenuItem>
                <MenuItem value="apron">Apron</MenuItem>
                <MenuItem value="taxiway">Taxiway</MenuItem>
              </Select>
            )}
            {layerType === "multipolyline" && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={editShapForm.values.feature}
                onChange={editShapForm.handleChange}
                readOnly={true}
              >
                <MenuItem value="bayline">Parking Bayline</MenuItem>
              </Select>
            )}
            {layerType === "circlemarker" && (
              <Select
                labelId="feature"
                id="feature"
                name="feature"
                label="feature"
                value={editShapForm.values.feature}
                onChange={editShapForm.handleChange}
                readOnly={true}
              >
                <MenuItem value="baypoint">Parking Bay Points</MenuItem>
              </Select>
            )}
            <FormHelperText>
              {editShapForm.touched.feature && editShapForm.errors.feature}
            </FormHelperText>
          </FormControl>
          {layerType !== "multipolyline" && (
            <TextField
              fullWidth
              variant="outlined"
              id="shape_name"
              name="shape_name"
              label="Shape Name*"
              readOnly={true}
              value={editShapForm.values.shape_name}
              onChange={editShapForm.handleChange}
              error={
                editShapForm.touched.shape_name &&
                Boolean(editShapForm.errors.shape_name)
              }
              helperText={
                editShapForm.touched.shape_name &&
                editShapForm.errors.shape_name
              }
              sx={{ mt: 2 }}
            />
          )}
          {layerType === "circlemarker" && (
            <>
              <TextField
                fullWidth
                variant="outlined"
                id="uid"
                name="uid"
                label="Uid*"
                type="number"
                readOnly={true}
                value={editShapForm.values.uid}
                onChange={editShapForm.handleChange}
                error={
                  editShapForm.touched.uid && Boolean(editShapForm.errors.uid)
                }
                helperText={editShapForm.touched.uid && editShapForm.errors.uid}
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="top_elev"
                name="top_elev"
                label="Top Elevation*"
                type="number"
                readOnly={true}
                value={editShapForm.values.top_elev}
                onChange={editShapForm.handleChange}
                error={
                  editShapForm.touched.top_elev &&
                  Boolean(editShapForm.errors.top_elev)
                }
                helperText={
                  editShapForm.touched.top_elev && editShapForm.errors.top_elev
                }
                sx={{ mt: 2 }}
              />
              <TextField
                fullWidth
                variant="outlined"
                id="ellipsoida"
                name="ellipsoida"
                label="Ellipsoida*"
                type="number"
                readOnly={true}
                value={editShapForm.values.ellipsoida}
                onChange={editShapForm.handleChange}
                error={
                  editShapForm.touched.ellipsoida &&
                  Boolean(editShapForm.errors.ellipsoida)
                }
                helperText={
                  editShapForm.touched.ellipsoida &&
                  editShapForm.errors.ellipsoida
                }
                sx={{ mt: 2 }}
              />
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ mb: 3, pt: 0 }}>
        <OutlinedButton
          label="Cancel"
          color="secondary"
          onClick={handleEditShapDialogCallback}
          sx={{ width: 98, height: 30 }}
        />
        <PrimaryButton
          label="Save"
          onClick={() => editShapForm.handleSubmit()}
          disabled={isLoadingAddEditDeletePolygon}
          isLoading={isLoadingAddEditDeletePolygon}
          sx={{ width: 98, mr: 2, height: 30 }}
        />
      </DialogActions>
    </Dialog>
  );
}
