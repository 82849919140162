import { theme } from "./theme";

export const styles = {
  proceedModal: {
    width: "530px",
    backgroundColor: theme.palette.white.main,
    padding: "18px 33px 18px 33px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  visualModalLabel: {
    width: "530px",
    height: "200px",
    backgroundColor: theme.palette.white.main,
    padding: "18px 33px 0px 33px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  visualModalInput: {
    width: "435px",
    padding: "0",
    cursor: "pointer",
    color: "#272362",
    fontSize: "14px",
    paddingLeft: "15px",
    border: "0.5px solid #4F4F4F",
    borderRadius: "4px 0px 0px 4px",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    ">input": {
      cursor: "pointer",
    },
    height: "30px",
    borderRight: 0,
  },
  visualModalUploadBtn: {
    width: "98px",
    height: "30px",
    borderRadius: "4px",
    position: "absolute",
    top: "0px",
    right: "1px",
  },
  modalInputFile: {
    height: "30px",
    zIndex: "11",
    position: "relative",
    opacity: 0,
    width: "82%",
    top: "-30px",
  },
  downloadTempBtn: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  obstacleAnalysisIcon: {
    position: "absolute",
    bottom: "-8px",
    right: "-8px",
  },
  obstacleLabelfs24: {
    fontSize: 24,
    fontWeight: 600,
  },
  obstacleLabelfs48: {
    fontSize: 48,
    fontWeight: 600,
  },
};