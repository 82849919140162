import React from "react";

function VisualiseData(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.3333 58.1788L56.6667 55.1855V21.8188L63.3333 24.8121V58.1788ZM43.3333 24.8121L50 21.8188V55.1855L43.3333 58.1788V24.8121ZM30 21.8188L36.6667 24.8121V58.1788L30 55.1855V21.8188ZM16.6667 24.8121L23.3333 21.8188V55.1855L16.6667 58.1788V24.8121ZM68.0333 19.6188L54.7 13.6255C54.65 13.6021 54.5967 13.5988 54.5467 13.5788C54.41 13.5255 54.2733 13.4888 54.1333 13.4521C54.0033 13.4188 53.8733 13.3888 53.74 13.3755C53.61 13.3588 53.48 13.3555 53.3467 13.3555C53.2 13.3555 53.0567 13.3588 52.91 13.3755C52.7867 13.3888 52.67 13.4188 52.55 13.4488C52.4033 13.4855 52.26 13.5255 52.1167 13.5788C52.07 13.5988 52.0133 13.6021 51.9667 13.6255L40 19.0021L28.0333 13.6255C27.9833 13.6021 27.93 13.5988 27.88 13.5788C27.7433 13.5255 27.6067 13.4888 27.4667 13.4521C27.3367 13.4188 27.2067 13.3888 27.0733 13.3755C26.9433 13.3588 26.8133 13.3555 26.68 13.3555C26.5333 13.3555 26.39 13.3588 26.2433 13.3755C26.12 13.3888 26.0033 13.4188 25.8833 13.4488C25.7367 13.4855 25.5933 13.5255 25.45 13.5788C25.4033 13.5988 25.3467 13.6021 25.3 13.6255L11.9667 19.6188C10.77 20.1555 10 21.3455 10 22.6588V63.3321C10 64.4621 10.5733 65.5155 11.5233 66.1321C12.07 66.4855 12.7 66.6655 13.3333 66.6655C13.7967 66.6655 14.2633 66.5688 14.7 66.3721L26.6667 60.9955L38.6333 66.3721C38.6833 66.3955 38.7367 66.3855 38.79 66.4055C39.18 66.5621 39.5833 66.6655 40 66.6655C40.4167 66.6655 40.82 66.5621 41.21 66.4055C41.26 66.3855 41.32 66.3955 41.3667 66.3721L53.3333 60.9955L65.3 66.3721C65.7367 66.5688 66.2033 66.6655 66.6667 66.6655C67.3 66.6655 67.93 66.4855 68.4767 66.1321C69.4267 65.5155 70 64.4621 70 63.3321V22.6588C70 21.3455 69.23 20.1555 68.0333 19.6188Z"
        fill={props.color ||"black"}
      />
    </svg>
  );
}

export default VisualiseData;
