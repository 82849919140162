import * as yup from "yup";
import { theme } from "@styles/theme";

export const modifyCategories = {
  runway: "RWY_ELE",
  apron: "APN_ELE",
  taxiway: "TWY_ELE",
};

export const airportFeatures = [
  {
    id: 1,
    name: "RunWay",
    value: "RWY_ELEMENT_List",
    color: "#027215",
    sub: [],
  },
  {
    id: 2,
    name: "Apron",
    value: "APN_ELEMENT_List",
    color: "#c6a70b",
    sub: [],
  },
  {
    id: 3,
    name: "Taxiway",
    value: "TWY_ELEMENT_List",
    color: "#2b058c",
    sub: [],
  },
  {
    id: 4,
    name: "Parking Bayline",
    value: "PARKING_BAYLINE_List",
    color: "#ffdf00",
    sub: [],
  },
  {
    id: 5,
    name: "Parking Bay Points",
    value: "PARKING_BAY_POINTS_List",
    color: "#ef9b0d",
    sub: [],
  },
  {
    id: 6,
    name: "Runway Marking",
    value: "RWY_ELE_MARKING_List",
    color: theme.palette.black.main,
    sub: [],
  },
];

export const addShapValidationSchema = yup.object().shape({
  feature: yup.string("Enter your feature").required("Feature is required"),
  // shape_name: yup
  //   .string("Enter your shap name")
  //   .required("Shap name is required"),
  // uid: yup.string("Enter your Uid").required("Uid is required"),
  // top_elev: yup
  //   .string("Enter your Top Elevation")
  //   .required("Top Elevation is required"),
  // ellipsoida: yup
  //   .string("Enter your Ellipsoida")
  //   .required("Ellipsoida is required"),
});
