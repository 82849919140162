import React from "react";

function LogoutIcon(props) {
  return (
    <svg
      width={props.width || "14"}
      height={props.height || "12"}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.333252 5.99855L3.66625 8.59085V6.64662H9.66565V5.35047H3.66625V3.40625L0.333252 5.99855Z"
        fill="#666666"
      />
      <path
        d="M7.66656 0.164085C6.87832 0.16196 6.09749 0.311918 5.36929 0.605271C4.64109 0.898624 3.98 1.32954 3.42432 1.87306L4.36689 2.78944C5.24813 1.93268 6.42002 1.46023 7.66656 1.46023C8.9131 1.46023 10.085 1.93268 10.9662 2.78944C11.8475 3.64619 12.3334 4.78551 12.3334 5.99741C12.3334 7.20931 11.8475 8.34862 10.9662 9.20538C10.085 10.0621 8.9131 10.5346 7.66656 10.5346C6.42002 10.5346 5.24813 10.0621 4.36689 9.20538L3.42432 10.1218C4.55687 11.2235 6.06339 11.8307 7.66656 11.8307C9.26973 11.8307 10.7762 11.2235 11.9088 10.1218C13.042 9.02068 13.6666 7.55603 13.6666 5.99741C13.6666 4.43879 13.042 2.97414 11.9088 1.87306C11.3531 1.32954 10.692 0.898624 9.96383 0.605271C9.23563 0.311918 8.45479 0.16196 7.66656 0.164085Z"
        fill={props.color || "#666666"}
      />
    </svg>
  );
}

export default LogoutIcon;
