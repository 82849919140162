import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderOLSObstacleFreeZone({
  OLSObstacleFreeZoneList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSObstacleFreeZoneList = chain(OLSObstacleFreeZoneList)
    .filter((ofzObj) => {
      const name = `OFZ_${ofzObj.category}_${ofzObj.rwy}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSObstacleFreeZoneList.map((olsOfz) => {
        const name = `OFZ_${olsOfz.category}_${olsOfz.rwy}`;
        const ofzCoordinates = olsOfz.coordinates.coordinates;
        const ofzCoordinateMap = flatten(ofzCoordinates).map((ofzCoords) => {
          return ofzCoords.map((ofz) => {
            return [ofz[1], ofz[0]];
          });
        });

        return ofzCoordinateMap.map((ofzPolygonPath, i) => (
          <Polygon
            key={i}
            pane="tilePane"
            positions={ofzPolygonPath}
            pathOptions={{
              fillColor: olsColor[name],
              color:
                selectedOverE?.category === olsOfz.category
                  ? areaHighLightColor
                  : olsColor[name],
              weight: selectedOverE?.category === olsOfz.category ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, olsOfz),
              mouseout: () => handlePolygonOut(),
            }}
          />
        ));
      })}
    </Fragment>
  );
}
