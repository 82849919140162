import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

export default function Rescueservices({
  selctedAirport,
  activeCategory,
  rescueservices,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {rescueservices ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Aerodrome category for fire fighting
                </StyledTableCell>
                <StyledTableCell>
                  {rescueservices?.aerodrome_category
                    ? rescueservices.aerodrome_category
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Rescue equipment
                </StyledTableCell>
                <StyledTableCell>
                  {rescueservices.RESCUE_EQPT_DESC
                    ? rescueservices.RESCUE_EQPT_DESC
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Capability for removal of disabled aircraft
                </StyledTableCell>
                <StyledTableCell>
                  {rescueservices.REMOVAL_AIRCRAFT
                    ? rescueservices.REMOVAL_AIRCRAFT
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>Remarks</StyledTableCell>
                <StyledTableCell>
                  {rescueservices?.remarks ? rescueservices?.remarks : "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}
