import { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { Tab, Typography, Divider, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { keys } from "lodash";
import { airportRWYDetailKeys } from "@constants/etodData";
import AirportDetailCard from "@components/core/AirportDetailCard";
import Accordion from "@components/core/Accordion";
import MapWidget from "@components/core/MapWidget";
import AccordionDetails from "@components/core/AccordionDetails";
import { theme } from "@styles/theme";

export default function EtodAirportDetailRightSideBar({
  airportDetail,
  handleSelectRWY,
}) {
  const airportRwys = keys(airportDetail.rwys);
  const [value, setValue] = useState(airportRwys[0]);
  const [expanded, setExpanded] = useState(true);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    handleSelectRWY(newValue);
  };

  const handleExpandAccordain = (name) => {
    setExpanded(name);
  };

  if (!airportDetail) return;

  return (
    <div className="detail-sidebar">
      <Accordion
        sx={{
          backgroundColor: theme.palette.white.light2,
          borderRadius: "5px",
        }}
        expanded={expanded}
      >
        <MapWidget
          sx={{
            backgroundColor: theme.palette.white.light2,
            pt: "5px",
            pb: "5px",
          }}
          expandIcon={
            <FaAngleLeft
              onClick={() => handleExpandAccordain(expanded ? false : true)}
            />
          }
        >
          <Typography component="p" className="sidebar-title">
            Details of {airportDetail.name} Airport
          </Typography>
        </MapWidget>
        <AccordionDetails
          sx={{
            p: "12px 15px 10px 15px",
            backgroundColor: theme.palette.white.light2,
          }}
        >
          <div className="inr-detail-sidebar">
            <AirportDetailCard
              title="Airport ICAO Code"
              data={airportDetail.icao}
              className="airport-code"
            />
            <Divider
              sx={{ mb: 1.5, mt: 1.5, borderColor: theme.palette.grey.light }}
            />
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box>
                  <TabList
                    className="main-tab-list"
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                  >
                    {airportDetail.rwys &&
                      airportRwys.map((key) => {
                        return (
                          <Tab
                            key={key}
                            value={key}
                            label={
                              <Box component="div" sx={{ width: "100%" }}>
                                <Typography component="p" className="tab-title">
                                  RWY Direction
                                </Typography>
                                <Typography
                                  component="p"
                                  className="tab-title date-direction"
                                >
                                  {key}
                                </Typography>
                              </Box>
                            }
                          />
                        );
                      })}
                  </TabList>
                </Box>
                {airportRwys.map((key) => {
                  const rwyDetail = airportDetail.rwys[key];
                  return (
                    <TabPanel
                      key={key}
                      value={key}
                      className="detail-tab"
                      sx={{ pt: "10px !important" }}
                    >
                      {rwyDetail.map((detail, i) => {
                        const detailKeys = Object.keys(detail);
                        detailKeys.splice(detailKeys.indexOf("designator"), 1);
                        detailKeys.splice(detailKeys.indexOf("tora"), 1);
                        detailKeys.splice(detailKeys.indexOf("category"), 1);
                        let rwyColors = ["#FF30A0", "#FF782D"];
                        if (i === 1) {
                          rwyColors = ["#FF782D", "#FF30A0"];
                        }
                        return detailKeys.map((key) => {
                          if (key === "thr") {
                            return (
                              <AirportDetailCard
                                className="airport-detail-card"
                                key={key}
                                isDotShow
                                dotColor={rwyColors[0]}
                                title={`${airportRWYDetailKeys[key]}: ${detail.designator}`}
                                data={`${detail[key][1].toFixed(5)}, ${detail[
                                  key
                                ][0].toFixed(5)}`}
                              />
                            );
                          }

                          if (key === "end") {
                            return (
                              <AirportDetailCard
                                className="airport-detail-card"
                                key={key}
                                isDotShow
                                dotColor={rwyColors[1]}
                                title={`${airportRWYDetailKeys[key]}: ${detail.designator}`}
                                data={`${detail[key][1].toFixed(5)}, ${detail[
                                  key
                                ][0].toFixed(5)}`}
                              />
                            );
                          }

                          return (
                            <AirportDetailCard
                              className="airport-detail-card"
                              key={key}
                              title={`${
                                airportRWYDetailKeys[key] ||
                                airportRWYDetailKeys
                              }: ${detail.designator}`}
                              data={detail[key] || "-"}
                            />
                          );
                        });
                      })}
                    </TabPanel>
                  );
                })}
              </TabContext>
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
