import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tertiary.main,
    color: "#95969D",
    fontWeight:700,
    fontSize:12
  },
  padding: "6px 10px",
}));

const StyledTableCellV12 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tertiary.main,
  },
  padding: "6px 10px",
  fontSize: "12px",
}));

const StyledTableCellV2 = styled(TableCell)(() => ({
  fontSize: "11px",
}));

const StyledTableCellV3 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tertiary.main,
  },
  width: 200,
  padding: "6px 10px",
}));

const StyledTableCellV4 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tertiary.main,
  },
  padding: "6px 7px",
}));

export {
  StyledTableCellV2,
  StyledTableCellV12,
  StyledTableCellV3,
  StyledTableCellV4,
};
export default StyledTableCell;
