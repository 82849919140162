import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { FeatureGroup } from "react-leaflet";
import { Polygon, Popup } from "react-leaflet";
import { chain, flatten, split } from "lodash";
import { Box, Stack } from "@mui/material";
import { LightButton } from "@components/button";
import { areaHighLightColor } from "@constants/colors";
import { modifyCategories } from "@constants/amdbData";
import { roles } from "@constants/constant";
import { styles } from "@styles/amdbStyles";

export default function RenderApnShape({
  isEditShape,
  selectedFeatures,
  apnElementList,
  handleEditShap,
  handleDeleteShape,
}) {
  const popupElRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [apnShapes, setApnShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const apnList = chain(apnElementList)
      .filter((apnObj) => {
        const apnKey = `apn_${apnObj.apn}`;
        const fsdfd = selectedFeatures.includes(
          `${apnKey}__${areaColorT[apnKey]}`
        );
        return fsdfd;
      })
      .value();

    setAreaColor(areaColorT);
    setApnShapes(apnList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.apron);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const handlePolygonDelete = (data) => {
    handleDeleteShape(data, modifyCategories.apron);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const apronShaps = useMemo(() => {
    return (
      <FeatureGroup pane="mapPane">
        {apnShapes.map((apnGeometry) => {
          const apnCoordinates = flatten(apnGeometry.coordinates.coordinates);
          const apnCoordinateMap = apnCoordinates.map((apnCoords) => {
            return apnCoords.map((reml) => {
              return {
                lng: reml[0],
                lat: reml[1],
              };
            });
          });

          return apnCoordinateMap.map((apnPolygonPath, i) => {
            const apnKey = `apn_${apnGeometry.apn}`;

            return (
              <Polygon
                key={apnKey}
                positions={apnPolygonPath}
                pathOptions={{
                  fillColor: areaColor[apnKey],
                  color:
                    selectedOverE?.apn === apnGeometry.apn
                      ? areaHighLightColor
                      : areaColor[apnKey],
                  weight: selectedOverE?.apn === apnGeometry.apn ? 3 : 2,
                }}
                eventHandlers={{
                  mouseover: () => handlePolygonOver(apnGeometry),
                  mouseout: () => handlePolygonOut(),
                }}
              >
                {!isEditShape && (
                  <Popup ref={popupElRef} className="polygon-popup">
                    <Box sx={styles.apnShapPolygonPop}>
                      Apron: {apnGeometry?.apn}
                    </Box>
                    {isAdmin && (
                      <Stack
                        sx={{ width: 200 }}
                        direction="row"
                        flexDirection="column"
                      >
                        <LightButton
                          label="Modify Polygon"
                          onClick={() => handlePolygonEdit(apnGeometry)}
                          sx={styles.modifyShapeBtn}
                        />
                        <LightButton
                          label="Delete Polygon"
                          onClick={() => handlePolygonDelete(apnGeometry)}
                          sx={{ p: 0, mr: 7 }}
                        />
                      </Stack>
                    )}
                  </Popup>
                )}
              </Polygon>
            );
          });
        })}
      </FeatureGroup>
    );
  }, [selectedFeatures, apnShapes, selectedOverE]); // eslint-disable-line

  return <Fragment>{apronShaps}</Fragment>;
}
