import { useEffect } from "react";
import { find } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { PrimaryButton, LightButton } from "@components/button";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd217ValidationSchema,
  hoursOfOperation,
} from "@constants/ad2Data";
import { AD217initialValue } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad217Form({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;
    createAd217Form.setFieldValue(
      "callsign",
      AAIAirport?.callsign ? AAIAirport?.callsign : ""
    );
    createAd217Form.setFieldValue(
      "classification",
      AAIAirport?.classification ? AAIAirport?.classification : ""
    );
    createAd217Form.setFieldValue(
      "hours",
      AAIAirport?.hours ? AAIAirport?.hours : ""
    );
    createAd217Form.setFieldValue(
      "language",
      AAIAirport?.language ? AAIAirport?.language : ""
    );
    createAd217Form.setFieldValue(
      "lateral_limit",
      AAIAirport?.lateral_limit ? AAIAirport?.lateral_limit : ""
    );
    const transitionAltitude = AAIAirport?.transition_altitude
      ? AAIAirport?.transition_altitude.replace("FT", "")
      : "";
    createAd217Form.setFieldValue(
      "transition_altitude",
      Number(transitionAltitude)
    );
    createAd217Form.setFieldValue(
      "upper_limit",
      AAIAirport?.upper_limit ? AAIAirport?.upper_limit : ""
    );
    createAd217Form.setFieldValue(
      "remark",
      AAIAirport?.remark ? AAIAirport?.remark : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "airspaceservice",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd217Form.resetForm();
    createAd217Form.setSubmitting(false);
  };

  const createAd217Form = useFormik({
    enableReinitialize: true,
    initialValues: AD217initialValue,
    validationSchema: createAd217ValidationSchema,
    onSubmit: (values) => {
      const payload = {
        callsign: values.callsign || "",
        classification: values.classification || "",
        hours: values.hours || "",
        language: values.language || "",
        lateral_limit: values.lateral_limit || "",
        transition_altitude: values.transition_altitude
          ? `${values.transition_altitude} FT`
          : "",
        upper_limit: values.upper_limit || "",
        remark: values.remark || "",
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "airspaceservice",
            payload,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Call Sign</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="callsign"
                name="callsign"
                value={createAd217Form.values.callsign}
                onChange={createAd217Form.handleChange}
                error={
                  createAd217Form.touched.callsign &&
                  Boolean(createAd217Form.errors.callsign)
                }
                helperText={
                  createAd217Form.touched.callsign &&
                  createAd217Form.errors.callsign
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Classification</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="classification"
                name="classification"
                value={createAd217Form.values.classification}
                onChange={createAd217Form.handleChange}
                error={
                  createAd217Form.touched.classification &&
                  Boolean(createAd217Form.errors.classification)
                }
                helperText={
                  createAd217Form.touched.classification &&
                  createAd217Form.errors.classification
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mb: 2, mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Hours</Box>
            <Box sx={styles.adInput}>
              <SelectInput
                name="hours"
                value={find(hoursOfOperation, {
                  value: createAd217Form.values.hours,
                })}
                onChange={(option) => {
                  createAd217Form.setFieldValue("hours", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Language</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="language"
                name="language"
                value={createAd217Form.values.language}
                onChange={createAd217Form.handleChange}
                error={
                  createAd217Form.touched.language &&
                  Boolean(createAd217Form.errors.language)
                }
                helperText={
                  createAd217Form.touched.language &&
                  createAd217Form.errors.language
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Lateral Limit</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd217Form.touched?.lateral_limit &&
                  Boolean(createAd217Form.errors?.lateral_limit)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="lateral_limit"
                  name="lateral_limit"
                  value={createAd217Form.values.lateral_limit}
                  onChange={createAd217Form.handleChange}
                  aria-describedby="aerodrome-lateral_limit"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-lateral_limit">
                  {createAd217Form.touched?.lateral_limit &&
                    createAd217Form.errors?.lateral_limit}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Transition Altitude</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="transition_altitude"
                name="transition_altitude"
                type="number"
                value={createAd217Form.values.transition_altitude}
                onChange={createAd217Form.handleChange}
                error={
                  createAd217Form.touched.transition_altitude &&
                  Boolean(createAd217Form.errors.transition_altitude)
                }
                helperText={
                  createAd217Form.touched.transition_altitude &&
                  createAd217Form.errors.transition_altitude
                }
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">FT</InputAdornment>
                  ),
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Upper Limit</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="upper_limit"
                name="upper_limit"
                value={createAd217Form.values.upper_limit}
                onChange={createAd217Form.handleChange}
                error={
                  createAd217Form.touched.upper_limit &&
                  Boolean(createAd217Form.errors.upper_limit)
                }
                helperText={
                  createAd217Form.touched.upper_limit &&
                  createAd217Form.errors.upper_limit
                }
                InputProps={{
                  readOnly: !isReadOnly,
                  endAdornment: (
                    <InputAdornment position="end">FT</InputAdornment>
                  ),
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd217Form.touched?.remark &&
                  Boolean(createAd217Form.errors?.remark)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="remark"
                  name="remark"
                  value={createAd217Form.values.remark}
                  onChange={createAd217Form.handleChange}
                  aria-describedby="aerodrome-remark"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-remark">
                  {createAd217Form.touched?.remark &&
                    createAd217Form.errors?.remark}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd217Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd217Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad217Form;
