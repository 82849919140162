import { toast } from "react-toastify";
import {
  apiCall,
  fileUploadApicall,
  downloadReadableStreamFile,
  Timeout,
  headers,
} from "@utils/connect";
import {
  GET_ALL_NEW_AIRPORT_URL,
  CREATE_NEW_AIRPORT_URL,
  CHANGE_STATUS_AIRPORT_URL,
  DOWNLOAD_XML_AD2_URL,
  GET_AD_RUNWAY_INFO_URL,
  GET_EDIT_AAI_AIRPORT_URL,
  UPLOAD_CHARTS_AAI_AIRPORT_URL,
} from "@constants/urls";
import {
  setLoadingAllAirports,
  setAllAirports,
  setLoadingPostCreateNewAirport,
  setLoadingAirportStatusChange,
  setExportXMLAD2Loading,
  setLoadingAdRunwayInfo,
  setAdRunwayInfo,
  setLoadingAAIAirport,
  setLoadingAddAAIAirportDetail,
  setLoadingAddAAIAirportDetailUpload,
  setLoadingDeleteAD2AAIAirport,
  setLoadingAD28AAIAirportDetail,
} from ".";

export const getAllNewAirports = (token) => (dispatch) => {
  try {
    dispatch(setLoadingAllAirports(true));

    const onSuccess = (response) => {
      dispatch(setAllAirports(response.data));
      dispatch(setLoadingAllAirports(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAllAirports(false));
    };

    apiCall("GET", GET_ALL_NEW_AIRPORT_URL, "", onSuccess, onFailure, token);
  } catch (error) {
    dispatch(setLoadingAllAirports(false));
    toast.error(error.message);
  }
};

export const getAAIAirport =
  (aerodomName, airportId, versionId, callback) => (dispatch) => {
    try {
      dispatch(setLoadingAAIAirport(true));
      const onSuccess = (response) => {
        if (callback) callback(response.data);
        dispatch(setLoadingAAIAirport(false));
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAAIAirport(false));
      };

      apiCall(
        "GET",
        GET_EDIT_AAI_AIRPORT_URL(aerodomName, airportId, versionId),
        "",
        onSuccess,
        onFailure,
        ""
      );
    } catch (error) {
      dispatch(setLoadingAAIAirport(false));
      toast.error(error.message);
    }
  };

export const addEditAAIAirportDetail =
  (aerodomName, payload, airportId, versionId, token, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingAddAAIAirportDetail(true));

      const onSuccess = (response) => {
        toast.success(response.data?.message);
        dispatch(setLoadingAddAAIAirportDetail(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAddAAIAirportDetail(false));
      };

      apiCall(
        "PUT",
        GET_EDIT_AAI_AIRPORT_URL(aerodomName, airportId, versionId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingAddAAIAirportDetail(false));
      toast.error(error.message);
    }
  };

export const deleteAD2AAIAirport =
  (aerodomName, airportId, versionId, payload, callback, token) =>
  (dispatch) => {
    try {
      dispatch(setLoadingDeleteAD2AAIAirport(true));

      const onSuccess = (response) => {
        toast.success(response.data?.message);
        dispatch(setLoadingDeleteAD2AAIAirport(false));
        if (callback) callback(response.status);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingDeleteAD2AAIAirport(false));
      };

      apiCall(
        "DELETE",
        GET_EDIT_AAI_AIRPORT_URL(aerodomName, airportId, versionId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingDeleteAD2AAIAirport(false));
      toast.error(error.message);
    }
  };

export const addAAIAirportDetailUpload =
  (aerodomName, airportId, versionId, file, token, callback, fileName) =>
  (dispatch) => {
    try {
      dispatch(setLoadingAddAAIAirportDetailUpload(true));

      const onSuccess = (response) => {
        toast.success(response.data?.message);
        dispatch(setLoadingAddAAIAirportDetailUpload(false));
        if (callback) callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAddAAIAirportDetailUpload(false));
      };
      if (aerodomName === "aixmobstacles") {
        fileUploadApicall(
          GET_EDIT_AAI_AIRPORT_URL(aerodomName, airportId, versionId),
          file,
          onSuccess,
          onFailure,
          token
        );
      } else if (aerodomName === "charts") {
        fileUploadApicall(
          UPLOAD_CHARTS_AAI_AIRPORT_URL(
            aerodomName,
            airportId,
            versionId,
            fileName
          ),
          file,
          onSuccess,
          onFailure,
          token
        );
      }
    } catch (error) {
      dispatch(setLoadingAddAAIAirportDetailUpload(false));
      toast.error(error.message);
    }
  };

export const addAD2AAIAirportDetail =
  (aerodomName, payload, airportId, versionId, token, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingAD28AAIAirportDetail(true));

      const onSuccess = (response) => {
        toast.success(response.data?.message);
        dispatch(setLoadingAD28AAIAirportDetail(false));
        if (callback) callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAD28AAIAirportDetail(false));
      };

      apiCall(
        "POST",
        GET_EDIT_AAI_AIRPORT_URL(aerodomName, airportId, versionId),
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingAD28AAIAirportDetail(false));
      toast.error(error.message);
    }
  };

export const getAdRunwayInfo = (airportId, version, token) => (dispatch) => {
  try {
    dispatch(setLoadingAdRunwayInfo(true));

    const onSuccess = (response) => {
      dispatch(setAdRunwayInfo(response.data));
      dispatch(setLoadingAdRunwayInfo(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAdRunwayInfo(false));
    };

    apiCall(
      "GET",
      GET_AD_RUNWAY_INFO_URL(airportId, version),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAdRunwayInfo(false));
    toast.error(error.message);
  }
};

export const postCreateNewAirport =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingPostCreateNewAirport(true));

      const onSuccess = () => {
        toast.success("Airport created successfully");
        dispatch(getAllNewAirports(token));
        dispatch(setLoadingPostCreateNewAirport(false));
        callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingPostCreateNewAirport(false));
      };

      apiCall(
        "POST",
        CREATE_NEW_AIRPORT_URL,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingPostCreateNewAirport(false));
      toast.error(error.message);
    }
  };

export const changeStatusAirport = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirportStatusChange(true));

    const onSuccess = () => {
      dispatch(getAllNewAirports());
      dispatch(setLoadingAirportStatusChange(false));
      callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirportStatusChange(false));
    };

    apiCall(
      "POST",
      CHANGE_STATUS_AIRPORT_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirportStatusChange(false));
    toast.error(error.message);
  }
};

export const exportXMLAD2Data = (type, airport, token) => (dispatch) => {
  try {
    dispatch(setExportXMLAD2Loading(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split('.')[0].replace('-','')+'.xml'
          : `${airport.name ?? airport.designator}_${type}.xml`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportXMLAD2Loading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportXMLAD2Loading(false));
    };

    downloadReadableStreamFile(
      "GET",
      DOWNLOAD_XML_AD2_URL(airport?.version_id, airport?.id, type),
      "",
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setExportXMLAD2Loading(false));
    toast.error(error.message);
  }
};

export const exportAllXMLAD2Data = async (types, airport, token) => {
  const updateHeader = headers;
  updateHeader["Authorization"] = token;
  const promises = types.map((type) => {
    const formData = {
      method: "GET",
      headers: updateHeader,
      signal: Timeout(100).signal,
    };

    return fetch(
      DOWNLOAD_XML_AD2_URL(airport?.version_id, airport?.id, type.value),
      formData,
      500
    )
      .then((response) => {
        return response
          .blob()
          .then((blob) => {
            const newBlob = new Blob([blob]);
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute(
              "download",
              `${airport.name ?? airport.designator}_${type.value}.xml`
            );
            document.body.appendChild(link);
            link.click();

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);

            return blob;
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        return error;
      });
  });

  return Promise.all(promises)
    .then(() => {})
    .catch(() => {
      toast.error("Download failed");
    });
};
