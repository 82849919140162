import React,{ useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Tooltip } from "@mui/material";
import PolygonIcon from "@assets/svg/PolygonIcon";
import LocationIcon from "@assets/svg/LocationIcon";
import PolylineIcon from "@assets/svg/PolylineIcon";
import { LightTooltip } from "@components/core/LightTooltip";
import { setAMDBdrawHighlighter } from "@store/amdb";

function CustomDrawComtrolAction({
  handlePolygonDrawClick,
  handleLocationDrawClick,
  handlePolylineDrawClick,
}) {
  const dispatch = useDispatch();
  const isAMDBdrawHighlighter = useSelector(
    (state) => state.amdb.isAMDBdrawHighlighter
  );

  useEffect(() => {
    if (isAMDBdrawHighlighter) return;
    setTimeout(() => {
      dispatch(setAMDBdrawHighlighter(true));
    }, 1500);
  }, [isAMDBdrawHighlighter]); // eslint-disable-line

  return (
    <Tooltip
      classes={{ tooltip: "draw-control-tooltip" }}
      title="Click here to add a new feature"
      open={!isAMDBdrawHighlighter}
      placement="left-start"
    >
      <Box
        className={`${
          !isAMDBdrawHighlighter
            ? "custom-draw-control remove-tooltip"
            : "custom-draw-control"
        }`}
      >
        <LightTooltip title="Add Polygon" placement="left">
          <Button onClick={() => handlePolygonDrawClick()}>
            <PolygonIcon color="#000" />
          </Button>
        </LightTooltip>
        <LightTooltip title="Add Point" placement="left">
          <Button onClick={() => handleLocationDrawClick()}>
            <LocationIcon />
          </Button>
        </LightTooltip>
        <LightTooltip title="Add Line" placement="left">
          <Button onClick={() => handlePolylineDrawClick()}>
            <PolylineIcon />
          </Button>
        </LightTooltip>
      </Box>
    </Tooltip>
  );
}

export default CustomDrawComtrolAction;
