import React from "react";

function QuestionIcon(props) {
  return (
    <svg
      width={props.width || "10"}
      height={props.height || "16"}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C7.757 0 10 2.243 10 5C10 7.414 8.279 9.435 6 9.898V11C6 11.553 5.553 12 5 12C4.447 12 4 11.553 4 11V9C4 8.447 4.447 8 5 8C6.654 8 8 6.654 8 5C8 3.346 6.654 2 5 2C3.346 2 2 3.346 2 5C2 5.553 1.553 6 1 6C0.447 6 0 5.553 0 5C0 2.243 2.243 0 5 0ZM4 15C4 14.447 4.447 14 5 14C5.553 14 6 14.447 6 15C6 15.553 5.553 16 5 16C4.447 16 4 15.553 4 15Z"
        fill={props.color || "#42444A"}
      />
    </svg>
  );
}

export default QuestionIcon;
