import React from "react";
import { theme } from "@styles/theme";

const EchartIcon = (props) => {
  return (
    <svg
      width={props.width || "76"}
      height={props.height || "55"}
      viewBox="0 0 76 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M68.6117 13V9.4H69.0617L70.9392 12.2125V9.4H71.3892V13H70.9392L69.0617 10.185V13H68.6117Z"
        fill={props.color || theme.palette.grey.dark}
      />
      <path
        d="M72 54H4C2.34315 54 1 52.6569 1 51V5V4C1 2.34315 2.34314 1 4 1H72C73.6569 1 75 2.34315 75 4V51C75 52.6569 73.6569 54 72 54Z"
        stroke={theme.palette.grey.dark}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6H75"
        stroke={theme.palette.grey.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8701 40L51.773 18.5156"
        stroke={theme.palette.grey.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M30.3701 27.5V24.5"
        stroke={theme.palette.grey.dark}
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.8701 25V22"
        stroke={theme.palette.grey.dark}
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8701 23V20"
        stroke={theme.palette.grey.dark}
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2509 18.9059L41.8701 18"
        stroke={theme.palette.grey.dark}
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10.683"
        y="38.8393"
        width="48.3142"
        height="6"
        rx="0.5"
        transform="rotate(-30 10.683 38.8393)"
        stroke={theme.palette.grey.dark}
        strokeLinejoin="round"
      />
      <path
        d="M26.3701 37L26.3701 40L20.3701 43.5L17.8701 42"
        stroke={theme.palette.grey.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8701 34V31L24.8701 27.5L27.3701 29"
        stroke={theme.palette.grey.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="54.25"
        y="38.25"
        width="17.5"
        height="12.5"
        rx="0.75"
        stroke={theme.palette.grey.dark}
        strokeWidth="0.5"
      />
      <path d="M54.5 40.5H71.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M54.5 42.5H71.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M54.5 44.5H71.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M54.5 46.5H71.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M54.5 48.5H71.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M58 38.5V50.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path d="M63.5 38.5V50.5" stroke={theme.palette.grey.dark} strokeWidth="0.5" />
      <path
        d="M70 15L68.5566 17.5L71.4434 17.5L70 15ZM69.75 17.25L69.75 21L70.25 21L70.25 17.25L69.75 17.25Z"
        fill={props.color || theme.palette.grey.dark}
      />
    </svg>
  );
};

export default EchartIcon;
