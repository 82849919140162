import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Select from "react-select";
import { find, isEmpty, uniq, filter, chain, orderBy } from "lodash";
import axios from "axios";
import { booleanIntersects, point, circle } from "@turf/turf";
import { toast } from "react-toastify";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { LeftArrowIcon } from "@assets/svg/LeftArrow";
import { GET_ROUTE_DATA_URL } from "@constants/urls";
import {
  defaultLocationCenter,
  ARRIVAL_AIRPORT_RADIUS,
} from "@constants/constant";
import { colorTray } from "@constants/colors";
import { airspaceType } from "@constants/airspaceData";
import Header from "@components/Header";
import { PrimaryButton } from "@components/button";
import AirspaceMap from "@components/airspaceOSMMap/MapContainer";
import AirspaceSideBar from "@components/AirspaceSideBar";
import {
  // selectControlAirspaceOption,
  // selectADIZAirspaceOption,
  // selectPDRAirspaceOption,
  // selectTraTsaAirspaceOption,
  selectFIRAirspaceGeometry,
  selectFIRAirspaceOption,
  selectENRRNAVFirOption,
  selectENRCNAVFirOption,
  setAreaNavigationalRouteData,
  setConventionalRouteData,
  selectENRAllNavaidOptions,
  selectENRAllSignificantPointOptions,
  selectFilterNavaidData,
  selectfilterSignificantPoints,
  selectTraTsaAirspaceGeometry,
  setWaypointRouteData,
  setRestrictiveAirspaceDetails,
  selectMilitryTraTsaAUPOption,
  selectRouteCNAVUPOption,
  selectRouteRNAVUPOption,
  currentLiveTrackerMilitryTimelinedata,
  currentLiveTrackerRNAVTimelinedata,
  currentLiveTrackerCNAVTimelinedata,
  startPointRNAVRoutePlanOptions,
  startPointCNAVRoutePlanOptions,
  CNAVRoutePlanOptions,
  RNAVRoutePlanOptions,
  flightLvlMinMaxRNAVRoutePlan,
  flightLvlMinMaxCNAVRoutePlan,
  routeBufferSignificantPointsGeometry,
  routeBufferNavaidPointsGeometry,
  setArrvalDepAirportRouteData,
  setRouteThroughPointRPData,
  getOnlyCNAVRoutePlanData,
  getSelectedENROptions,
  setENRADIZRCoordsData,
  setENRMTRCoordsData,
  setENROTHERCoordsData,
  setENRPDRCoordsData,
  setENRTMACoordsData,
  setENRTRATSACoordsData,
  setENRAD217CoordsData,
  selectedFIRCTRLOptions,
  selectedFIRCTRLGeometry,
} from "@store/airspace";
import {
  getFIRData,
  getRestrictiveairspaceGeometry,
  // getADIZGeoData,
  getENRTypeAirspaceGeometry,
  getAllRNAVRoutes,
  getAllCNAVRoutes,
  getAreaNavigationalRouteData,
  getConventionalRouteData,
  getAllNavaid,
  getAllSignificantPoint,
  getWaypointRouteData,
  getAirspaceUsePlanData,
  getAirspaceUsePlanDataById,
  calculateFlightPlanValidation,
  getAirspaceRouteBufferPoints,
  getArrivalDepartureAirportRoutePlan,
  getRouteThroughPointRP,
  getAllTRA_TSA_ADIZ,
  getENRAirspaceGeometry,
  getENRAirspaceGeometryFetch,
} from "@store/airspace/actions";
import { setSelectedVersion } from "@store/aixm";
import { getAixmDataVersion, getAllAIXMAirport } from "@store/aixm/actions";
import { commonStyles } from "@styles/commonStyles";
import { styles } from "@styles/airspaceStyles";

export default function Airspace() {
  const dispatch = useDispatch();
  const {
    airspaceUsePlanDates,
    isLoadingRestrictiveAirspaceGeometry,
    isLoadingFIRAirspaceGeometry,
    isLoadingRNAVRoutesGeometry,
    isLoadingCNAVRoutesGeometry,
    isLoadingRNAVRoutes,
    isLoadingCNAVRoutes,
    isLoadingAreaNavigationalRouteData,
    isLoadingConventionalRouteData,
    isLoadingGetAllNavaid,
    isLoadingGetAllsignificantPoint,
    isLoadingWaypointRouteData,
    areaNavigationalRouteData,
    conventionalRouteData,
    isLoadingRoutePlanValidation,
    routeThroughPointRPData,
    isAirspaceRouteBufferPointsLoading,
    isArrivalDepartureAirportRoutePlanLoading,
    isRouteThroughPointRPLoading,
    isENRAirspaceGeometryLoading,
  } = useSelector((state) => state.airspace);
  const {
    allAirports,
    isLoadingAirport,
    aixmDataVersions,
    isLoadingGetAixmDataVersion,
    selectedVersion,
  } = useSelector((state) => state.aixm);

  const [isAUPActiveLiveTracker, setAUPActiveLiveTracker] = useState(false);
  const [isLoadingLivetrackerData, setLoadingLivetrackerData] = useState(false);
  const [filterPdrAirspace, setFilterPdrAirspace] = useState([]);
  const [currentLiveTime, setCurrentLiveTime] = useState(null);
  const [filterControlAirspace, setFilterControlAirspace] = useState([]);
  const [filterENRTraTsaAirspace, setFilterENRTraTsaAirspace] = useState([]);
  const [filterTraTsaAirspace, setFilterTraTsaAirspace] = useState([]);
  const [filterTMAAirspace, setFilterTMAAirspace] = useState([]);
  const [filterMTRAirspace, setFilterMTRAirspace] = useState([]);
  const [filterAD217Airspace, setFilterAD217Airspace] = useState([]);
  const [filterOtherRegulatedAirspace, setFilterOtherRegulatedAirspace] =
    useState([]);
  const [filterFIRAirspace, setFilterFIRAirspace] = useState([]);
  const [filterAUPFIRAirspace, setFilterAUPFIRAirspace] = useState([]);
  const [filterADIZAirspace, setFilterADIZAirspace] = useState([]);
  const [filterRNAVAirspace, setFilterRNAVAirspace] = useState([]);
  const [filterCNAVAirspace, setFilterCNAVAirspace] = useState([]);
  const [filterNavidAirspace, setFilterNavidAirspace] = useState([]);
  const [filterWayPointsAirspace, setFilterWayPointsAirspace] = useState([]);
  const [selectedEffectiveDate, setSelectedAirspaceEffectiveDate] =
    useState(null);
  const [selectedRoutePlanEffectiveDate, setSelectedRoutePlanEffectiveDate] =
    useState(null);
  const [currentTab, setCurrentTab] = useState("1");
  const [selectedAirspacePlanDetail, setSelectedAirspacePlanDetail] =
    useState(null);
  // const pdrAirspaceGeometry = useSelector((state) =>
  //   selectPDRAirspaceGeometry(state, filterPdrAirspace, filterFIRAirspace)
  // );
  const traTsaAUPAirspaceGeometry = useSelector((state) =>
    selectTraTsaAirspaceGeometry(
      state,
      filterTraTsaAirspace,
      filterAUPFIRAirspace
    )
  );
  const FIRAirspaceGeometry = useSelector((state) =>
    selectFIRAirspaceGeometry(state, filterFIRAirspace)
  );
  const AUPFIRAirspaceGeometry = useSelector((state) =>
    selectFIRAirspaceGeometry(state, filterAUPFIRAirspace)
  );
  // const ADIZAirspaceGeometry = useSelector((state) =>
  //   selectADIZAirspaceGeometry(state, filterADIZAirspace)
  // );
  const navaidOptions = useSelector((state) =>
    selectENRAllNavaidOptions(state, filterFIRAirspace)
  );
  const navidAirspaceGeometry = useSelector((state) =>
    selectFilterNavaidData(
      state,
      navaidOptions,
      filterNavidAirspace,
      filterFIRAirspace
    )
  );
  const significantPointOptions = useSelector((state) =>
    selectENRAllSignificantPointOptions(state, filterFIRAirspace)
  );
  const wayPointsAirspaceGeometry = useSelector((state) =>
    selectfilterSignificantPoints(
      state,
      significantPointOptions,
      filterWayPointsAirspace,
      filterFIRAirspace
    )
  );
  const waypointRouteGeometry = useSelector(
    (state) => state.airspace.waypointRouteData
  );
  // const pdrAirspaceOption = useSelector((state) =>
  //   selectPDRAirspaceOption(state, filterFIRAirspace)
  // );
  const pdrENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "PDR", filterFIRAirspace)
  );
  const tratsaENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "TRATSA", filterFIRAirspace)
  );
  const otherRegulatedENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "OTHER_REGULATED_AIRSPACE", filterFIRAirspace)
  );
  const mtrENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "MTR", filterFIRAirspace)
  );
  const ad217ENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "ad217", filterFIRAirspace)
  );
  const tmaENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "TMA", filterFIRAirspace)
  );
  const adizENRAirspaceOptions = useSelector((state) =>
    getSelectedENROptions(state, "ADIZ", filterFIRAirspace)
  );
  const FIRAirspaceOption = useSelector(selectFIRAirspaceOption);
  // const traTsaAirspaceOption = useSelector(selectTraTsaAirspaceOption);
  const ctrlAirspaceOption = useSelector((state) =>
    selectedFIRCTRLOptions(state, filterFIRAirspace)
  );
  const ctrlAirspaceGeometry = useSelector((state) =>
    selectedFIRCTRLGeometry(
      state,
      ctrlAirspaceOption,
      filterControlAirspace,
      filterFIRAirspace
    )
  );
  // const ADIZAirspaceOption = useSelector(selectADIZAirspaceOption);
  const RNAVAirspaceOption = useSelector((state) =>
    selectENRRNAVFirOption(state, filterFIRAirspace)
  );
  const CNAVAirspaceOption = useSelector((state) =>
    selectENRCNAVFirOption(state, filterFIRAirspace)
  );
  const militryAUPOptionData = useSelector((state) =>
    selectMilitryTraTsaAUPOption(state, filterAUPFIRAirspace)
  );
  const currentLivemilitryTimelinedata = useSelector((state) =>
    currentLiveTrackerMilitryTimelinedata(
      state,
      militryAUPOptionData,
      currentLiveTime
    )
  );
  const routeCNAVAUPOptionData = useSelector((state) =>
    selectRouteCNAVUPOption(state, filterAUPFIRAirspace)
  );

  const routeRNAVAUPOptionData = useSelector((state) =>
    selectRouteRNAVUPOption(state, filterAUPFIRAirspace)
  );
  const currentLiveRNAVTimelinedata = useSelector((state) =>
    currentLiveTrackerRNAVTimelinedata(
      state,
      routeRNAVAUPOptionData,
      currentLiveTime
    )
  );
  const currentLiveCNAVTimelinedata = useSelector((state) =>
    currentLiveTrackerCNAVTimelinedata(
      state,
      routeCNAVAUPOptionData,
      currentLiveTime
    )
  );
  const [zoomLevel, setZoomLevel] = useState(4.7);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [isShowMoreViewContent, setShowMoreViewContent] = useState(false);
  const [isShowMoreView, setShowMoreView] = useState(false);
  const [isShowAirspacePlanDetail, setShowAirspacePlanDetail] = useState(false);
  const [selectedAUPfeatureType, setSelectedAUPfeatureType] = useState(null);
  const [RNAVRouteurls, setRNAVRouteUrls] = useState([]);
  const [RNAVRouteLiveGeometryData, setRNAVRouteLiveGeometryData] = useState(
    []
  );
  const [selectedRNAVRouteLiveData, setSelectedRNAVRouteLiveData] = useState(
    []
  );

  const [CNAVRouteurls, setCNAVRouteUrls] = useState([]);
  const [CNAVRouteLiveGeometryData, setCNAVRouteLiveGeometryData] = useState(
    []
  );
  const [selectedCNAVRouteLiveData, setSelectedCNAVRouteLiveData] = useState(
    []
  );
  const [
    liveTracerRestrictedAirspaceDetails,
    setLiveTracerRestrictedAirspaceDetails,
  ] = useState(null);
  // Route Plan Start
  const RouteplanCNAVOpts = useSelector((state) => CNAVRoutePlanOptions(state));
  const RouteplanRNAVOpts = useSelector((state) => RNAVRoutePlanOptions(state));
  const startPointCNAVPlanOpts = useSelector((state) =>
    startPointCNAVRoutePlanOptions(state, filterCNAVAirspace)
  );
  const startPointRNAVPlanOpts = useSelector((state) =>
    startPointRNAVRoutePlanOptions(state, filterRNAVAirspace)
  );
  const minMaxRNAVFlightLvl = useSelector((state) =>
    flightLvlMinMaxRNAVRoutePlan(state, filterRNAVAirspace)
  );
  const minMaxCNAVFlightLvl = useSelector((state) =>
    flightLvlMinMaxCNAVRoutePlan(state, filterCNAVAirspace)
  );

  const [arrivalAirportRoutePlan, setArrivalAirportRoutePlan] = useState(null);
  const [departureAirportRoutePlan, setDepartureAirportRoutePlan] =
    useState(null);
  const [isBufferPointRoutePlan, setBufferPointRoutePlan] = useState(false);
  const [isShowCNAVRoute, setShowCNAVRoute] = useState(false);
  const ShowCNAVRoutePlanData = useSelector((state) =>
    getOnlyCNAVRoutePlanData(state, isShowCNAVRoute)
  );
  const [deptTimeRoutePlan, setDeptTimeRoutePlan] = useState(null);
  const [startPointRoutePlan, setStartPointRoutePlan] = useState(null);
  const [arpSpeedRoutePlan, setArpSpeedRoutePlan] = useState(undefined);
  const [arpFlightLevelRoutePlan, setArpFlightLevelRoutePlan] =
    useState(undefined);
  const [flightLevelMin, setFlightLevelMin] = useState(0);
  const [flightLevelMax, setFlightLevelMax] = useState(999);
  const [arpFlightPlanErrMssg, setArpFlightPlanErrMssg] = useState(null);
  const [arpFlightPlanArrivalTime, setArpFlightPlanArrivalTime] =
    useState(null);
  const [arpFlightPlanErrorsRoute, setArpFlightPlanErrorsRoute] = useState([]);
  const [arpFlightPlanErrorsAirspace, setArpFlightPlanErrorsAirspace] =
    useState([]);
  const [arpFlightPlanErrStatus, setArpFlightPlanErrStatus] = useState(false);
  const [arpFlightPlanSuccessStatus, setArpFlightPlanSuccessStatus] =
    useState(false);
  const [openRoutePlanMssgDialog, setOpenRoutePlanMssgDialog] = useState(false);
  const [
    routePlanBufferSignificantPointIds,
    setRoutePlanBufferSignificantPointIds,
  ] = useState([]);
  const [routePlanBufferNavaidIds, setRoutePlanBufferNavaidIds] = useState([]);
  const routeBufferSignificantPointGeometry = useSelector((state) =>
    routeBufferSignificantPointsGeometry(
      state,
      routePlanBufferSignificantPointIds
    )
  );
  const routeBufferNavaidGeometry = useSelector((state) =>
    routeBufferNavaidPointsGeometry(state, routePlanBufferNavaidIds)
  );
  const [selectedNewRoutePlanPointData, setSelectedNewRoutePlanPointData] =
    useState([]);
  const [
    selectedRoutePlanBufferPointData,
    setSelectedRoutePlanBufferPointData,
  ] = useState(null);
  const [selectedRoutePlanRouteIds, setSelectedRoutePlanRouteIds] = useState(
    []
  );
  const [selectedRoutePlanRouteNames, setSelectedRoutePlanRouteNames] =
    useState([]);
  const [selectedRouteEndPointData, setSelectedRouteEndPointData] =
    useState(null);
  const [
    isAirspaceRouteBufferPointsInSideCircle,
    setIsAirspaceRouteBufferPointsInSideCircle,
  ] = useState(false);
  // Route Plan End
  const [isShowAIRACCycleDate, setShowAIRACCycleDate] = useState(false);
  // eslint-disable-next-line
  const [ENRAirspaceOptIds, setENRAirspaceOptIds] = useState([]);

  useEffect(() => {
    dispatch(
      getAixmDataVersion((versionData) => {
        const data = orderBy(versionData, "id", "desc");
        const versions = orderBy(data, ["id"], "desc");
        dispatch(getFIRData(versions[0]?.id));
        dispatch(getENRTypeAirspaceGeometry("FIR"));
        dispatch(getENRTypeAirspaceGeometry("CTRL"));
        dispatch(getAllRNAVRoutes(versions[0]?.id));
        dispatch(getAllCNAVRoutes(versions[0]?.id));
        dispatch(getAllNavaid(versions[0]?.id));
        dispatch(getAllSignificantPoint(versions[0]?.id));
        dispatch(getAllAIXMAirport());
        dispatch(getENRAirspaceGeometry(versions[0]?.id));
        // not in use start
        // dispatch(getControlAirspaceGeometry());
        // dispatch(getADIZGeoData());
        // not in use end
      })
    );
    dispatch(setAreaNavigationalRouteData([]));
    dispatch(setConventionalRouteData([]));
    // RNAV
    setRNAVRouteUrls([]);
    setRNAVRouteLiveGeometryData([]);
    // CNAV
    setCNAVRouteUrls([]);
    setCNAVRouteLiveGeometryData([]);
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
  }, []); // eslint-disable-line

  useEffect(() => {
    setSelectedAirspacePlanDetail(null);
    setShowAirspacePlanDetail(false);
    setFilterFIRAirspace([]);
    setFilterNavidAirspace([]);
    // setFilterAUPFIRAirspace([]);
    setFilterTraTsaAirspace([]);
    setFilterCNAVAirspace([]);
    setFilterRNAVAirspace([]);
    setSelectedAUPfeatureType(null);
  }, [selectedEffectiveDate]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(minMaxCNAVFlightLvl)) {
      setFlightLevelMin(minMaxCNAVFlightLvl.min);
      setFlightLevelMax(minMaxCNAVFlightLvl.max);
    } else if (!isEmpty(minMaxRNAVFlightLvl)) {
      setFlightLevelMin(minMaxRNAVFlightLvl.min);
      setFlightLevelMax(minMaxRNAVFlightLvl.max);
    }
  }, [minMaxCNAVFlightLvl, minMaxRNAVFlightLvl]); // eslint-disable-line

  useEffect(() => {
    if (isAUPActiveLiveTracker) {
      setInterval(async () => {
        await handleAUPActiveLiveTracker(true);
      }, 900000);
    }
  }, [isAUPActiveLiveTracker, currentLiveTime]); // eslint-disable-line

  // RNAV Live tracker data handling START
  useEffect(() => {
    if (!isEmpty(currentLiveRNAVTimelinedata)) {
      const rnavUrls = [];
      currentLiveRNAVTimelinedata.map((body) => {
        rnavUrls.push(GET_ROUTE_DATA_URL(body.id));
        return true;
      });
      setRNAVRouteUrls(rnavUrls);
    }
  }, [currentLiveRNAVTimelinedata]); // eslint-disable-line

  useEffect(() => {
    let timeout;
    if (isAUPActiveLiveTracker && !isEmpty(RNAVRouteurls)) {
      setLoadingLivetrackerData(true);
      let finalData = {};
      timeout = setTimeout(async () => {
        const rpromise = RNAVRouteurls.map((url) => {
          return axios.get(url);
        });
        await Promise.all(rpromise).then((response) => {
          response.map((data) => {
            const params = new URL(data.config.url).searchParams;
            const routeId = params.get("route_id");
            finalData = {
              ...finalData,
              [`${String(routeId)}`]: data.data.data,
            };
            return true;
          });
        });
        setRNAVRouteLiveGeometryData(finalData);
        setLoadingLivetrackerData(false);
      }, 2500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isAUPActiveLiveTracker, RNAVRouteurls]); // eslint-disable-line
  //  RNAV Live tracker data handling END

  // CNAV Live tracker data handling START
  useEffect(() => {
    if (!isEmpty(currentLiveCNAVTimelinedata)) {
      const CnavUrls = [];
      currentLiveCNAVTimelinedata.map((body) => {
        CnavUrls.push(GET_ROUTE_DATA_URL(body.id));
        return true;
      });
      setCNAVRouteUrls(CnavUrls);
    }
  }, [currentLiveCNAVTimelinedata]); // eslint-disable-line

  useEffect(() => {
    let timeout;
    if (isAUPActiveLiveTracker && !isEmpty(CNAVRouteurls)) {
      setLoadingLivetrackerData(true);
      let finalData = {};
      timeout = setTimeout(async () => {
        const rpromise = CNAVRouteurls.map((url) => {
          return axios.get(url);
        });
        await Promise.all(rpromise).then((response) => {
          response.map((data) => {
            const params = new URL(data.config.url).searchParams;
            const routeId = params.get("route_id");
            finalData = {
              ...finalData,
              [`${String(routeId)}`]: data.data.data,
            };
            return true;
          });
        });
        setCNAVRouteLiveGeometryData(finalData);
        setLoadingLivetrackerData(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isAUPActiveLiveTracker, CNAVRouteurls]); // eslint-disable-line
  // CNAV Live tracker data handling END

  const handleShowContent = (isShow) => {
    setShowMoreView(isShow);
  };

  const clearDataDateFilter = () => {
    setFilterADIZAirspace([]);
    setFilterPdrAirspace([]);
    setFilterTMAAirspace([]);
    setFilterMTRAirspace([]);
    setFilterOtherRegulatedAirspace([]);
    setFilterENRTraTsaAirspace([]);
    setFilterControlAirspace([]);
    setFilterRNAVAirspace([]);
    setFilterCNAVAirspace([]);
    setFilterWayPointsAirspace([]);
    setFilterNavidAirspace([]);
    setFilterAD217Airspace([]);
    dispatch(setENRMTRCoordsData([]));
    dispatch(setENROTHERCoordsData([]));
    dispatch(setENRPDRCoordsData([]));
    dispatch(setENRTMACoordsData([]));
    dispatch(setENRTRATSACoordsData([]));
    dispatch(setENRADIZRCoordsData([]));
    dispatch(setENRAD217CoordsData([]));
  };

  const handleCloseAirspaceUsePlanDetail = () => {
    setSelectedAirspacePlanDetail(null);
    setShowAirspacePlanDetail(false);
    setSelectedAUPfeatureType(null);
  };

  const handleClearEnrouteAUPData = () => {
    dispatch(setAreaNavigationalRouteData([]));
    dispatch(setConventionalRouteData([]));
    dispatch(setWaypointRouteData([]));
    setSelectedAirspacePlanDetail(null);
    setShowAirspacePlanDetail(false);
    clearDataDateFilter();
    setFilterFIRAirspace([]);
    setFilterAUPFIRAirspace([]);
    setFilterTraTsaAirspace([]);
    setSelectedAUPfeatureType(null);
    setSelectedAirspaceEffectiveDate(null);
    setSelectedRoutePlanEffectiveDate(null);
    setAUPActiveLiveTracker(false);
    // RNAV
    setRNAVRouteUrls([]);
    setRNAVRouteLiveGeometryData([]);
    // CNAV
    setCNAVRouteUrls([]);
    setCNAVRouteLiveGeometryData([]);
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
  };

  const handleChangeCenter = () => {
    setAirportLocation(null);
    setZoomLevel(4.6);
    setTimeout(() => {
      setAirportLocation(defaultLocationCenter);
      setZoomLevel(4.7);
    }, 600);
  };

  const handlePdrAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      dispatch(setENRPDRCoordsData([]));
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterPdrAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = pdrENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "PDR");
      return;
    }
    setFilterPdrAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "PDR");
    }
  };

  const handleControlAirspaceFilter = (option) => {
    if (option.length === 0) {
      setShowMoreViewContent(false);
      setShowMoreView(false);
    } else {
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterControlAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      return;
    }
    setFilterControlAirspace(option);
  };

  const handleENRTraTsaAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      setShowMoreView(false);
      setSelectedAirspacePlanDetail(null);
      setShowAirspacePlanDetail(false);
      setSelectedAUPfeatureType(null);
      dispatch(setENRTRATSACoordsData([]));
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterENRTraTsaAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = tratsaENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "TRATSA");
      return;
    }
    setFilterENRTraTsaAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "TRATSA");
    }
  };

  const handletraTsaAirspaceFilter = (option) => {
    if (option.length === 0) {
      setShowMoreViewContent(false);
      setShowMoreView(false);
      setSelectedAirspacePlanDetail(null);
      setShowAirspacePlanDetail(false);
      setSelectedAUPfeatureType(null);
    } else {
      setShowMoreViewContent(true);
    }
    setFilterTraTsaAirspace(option);
  };

  const handleCallENRGeometryDataApi = (ids, catType) => {
    const payload = {
      id_list: ids,
    };
    dispatch(getENRAirspaceGeometryFetch(payload, catType));
  };

  const handleOtherRegulatedAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      setShowMoreView(false);
      dispatch(setENROTHERCoordsData([]));
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterOtherRegulatedAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = otherRegulatedENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "OTHER");
      return;
    }
    setFilterOtherRegulatedAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "OTHER");
    }
  };

  const handleTMAAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      dispatch(setENRTMACoordsData([]));
      setShowMoreView(false);
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterTMAAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);

      const allids = tmaENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "TMA");
      return;
    }

    setFilterTMAAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "TMA");
    }
  };

  const handleMTRAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      dispatch(setENRMTRCoordsData([]));
      setShowMoreView(false);
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterMTRAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = mtrENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "MTR");
      return;
    }
    setFilterMTRAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "MTR");
    }
  };

  const handleENRAD217AirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      dispatch(setENRAD217CoordsData([]));
      setShowMoreView(false);
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    if (find(option, ["designator", "All"])) {
      setFilterAD217Airspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = ad217ENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "ad217");
      return;
    }
    setFilterAD217Airspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "ad217");
    }
  };

  const handleFIRAirspaceFilter = (option) => {
    clearDataDateFilter();
    dispatch(setConventionalRouteData([]));
    dispatch(setAreaNavigationalRouteData([]));
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      setShowMoreView(false);
      setSelectedAirspacePlanDetail(null);
      setShowAirspacePlanDetail(false);
      setSelectedAUPfeatureType(null);
      setFilterTraTsaAirspace([]);
    } else {
      setShowMoreViewContent(true);
    }
    handleChangeCenter();
    if (find(option, ["designator", "All"])) {
      setFilterFIRAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);

      return;
    }

    setFilterFIRAirspace(option);
  };

  const handleAUPFIRAirspaceFilter = (option) => {
    if (option.length === 0) {
      setAUPActiveLiveTracker(false);
      setRNAVRouteUrls([]);
      setCNAVRouteUrls([]);
      setSelectedAirspacePlanDetail(null);
      setShowMoreViewContent(false);
      setShowMoreView(false);
      setShowAirspacePlanDetail(false);
      setSelectedAUPfeatureType(null);
      setFilterTraTsaAirspace([]);
      setFilterRNAVAirspace([]);
      setFilterCNAVAirspace([]);
      setFilterAUPFIRAirspace([]);
      setCurrentLiveTime(null);
    } else {
      setShowMoreViewContent(true);
    }

    handleChangeCenter();
    if (find(option, ["designator", "All"])) {
      setFilterAUPFIRAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      return;
    }
    setFilterAUPFIRAirspace(option);
  };

  const handleADIZAirspaceFilter = (option) => {
    let ids = [];
    if (option.length === 0) {
      // setShowMoreViewContent(false);
      setShowMoreView(false);
      dispatch(setENRADIZRCoordsData([]));
    } else {
      ids = !find(option, ["designator", "All"]) ? option.map((d) => d.id) : [];
      setShowMoreViewContent(true);
    }
    handleChangeCenter();
    if (find(option, ["designator", "All"])) {
      setFilterADIZAirspace([
        { name: "All", designation: "All", designator: "All" },
      ]);
      const allids = adizENRAirspaceOptions.map((d) => d.id);
      handleCallENRGeometryDataApi(allids, "ADIZ");
      return;
    }
    setFilterADIZAirspace(option);
    if (!isEmpty(ids)) {
      handleCallENRGeometryDataApi(ids, "ADIZ");
    }
  };

  const handleCNAVAirspaceFilter = (option) => {
    handleChangeStartPointRoutePlan(null);
    dispatch(setConventionalRouteData([]));
    handleDeptTimeRoutePlan(null);
    handleChangeStartPointRoutePlan(null);
    handleChangeARPFlightLevel("");
    handleChangeARPSpeed(undefined);
    setOpenRoutePlanMssgDialog(false);
    setArpFlightPlanErrorsRoute([]);
    setArpFlightPlanErrorsAirspace([]);
    setFilterCNAVAirspace(option);
    setSelectedAirspacePlanDetail(null);
    if (option?.id) {
      dispatch(getConventionalRouteData(option.id, false));
      setShowMoreViewContent(true);
      setShowMoreView(false);
    } else {
      setShowMoreViewContent(false);
      setShowAirspacePlanDetail(false);
    }
    setSelectedAUPfeatureType(null);
    handleChangeCenter();
  };

  const handleRNAVAirspaceFilter = (option) => {
    handleChangeStartPointRoutePlan(null);
    dispatch(setAreaNavigationalRouteData([]));
    handleDeptTimeRoutePlan(null);
    handleChangeStartPointRoutePlan(null);
    handleChangeARPFlightLevel("");
    handleChangeARPSpeed(undefined);
    setOpenRoutePlanMssgDialog(false);
    setArpFlightPlanErrorsRoute([]);
    setArpFlightPlanErrorsAirspace([]);
    setFilterRNAVAirspace(option);
    if (option?.id) {
      dispatch(getAreaNavigationalRouteData(option.id, false));
      setShowMoreViewContent(true);
      setShowMoreView(false);
    } else {
      setShowMoreViewContent(false);
      setShowAirspacePlanDetail(false);
    }
    setSelectedAUPfeatureType(null);
    setSelectedAirspacePlanDetail(null);
    handleChangeCenter();
  };

  const handleNavidAirspaceFilter = (option) => {
    if (option.length === 0) {
      setShowMoreViewContent(false);
      setShowMoreView(false);
    } else {
      setShowMoreViewContent(true);
    }
    setFilterNavidAirspace(option);
    handleChangeCenter();
  };

  const handleWayPointAirspaceFilter = (option) => {
    dispatch(setWaypointRouteData([]));
    if (!option) {
      setFilterWayPointsAirspace([]);
      setShowMoreViewContent(false);
      setShowMoreView(false);
      return;
    } else {
      setShowMoreViewContent(true);
    }

    if (option?.name !== "All") {
      dispatch(getWaypointRouteData(option.id));
    }

    setFilterWayPointsAirspace([option]);
    handleChangeCenter();
  };

  const handleAirspacePlanEffectiveDate = async (option) => {
    setLiveTracerRestrictedAirspaceDetails(null);
    if (option?.id) {
      setSelectedAirspaceEffectiveDate(option);
      await dispatch(getAirspaceUsePlanDataById(option.id));
    } else {
      setSelectedAirspacePlanDetail(null);
      setSelectedAirspaceEffectiveDate(null);
      setSelectedAUPfeatureType(null);
      setFilterFIRAirspace([]);
      // setFilterAUPFIRAirspace([]);
      setFilterADIZAirspace([]);
      setFilterPdrAirspace([]);
      setFilterTraTsaAirspace([]);
      setFilterTMAAirspace([]);
      setFilterMTRAirspace([]);
      setFilterOtherRegulatedAirspace([]);
      setFilterENRTraTsaAirspace([]);
      setFilterCNAVAirspace([]);
      setFilterRNAVAirspace([]);
      setFilterNavidAirspace([]);
      setFilterWayPointsAirspace([]);
      dispatch(setRestrictiveAirspaceDetails(null));
    }
    handleDeptTimeRoutePlan(null);
    handleChangeStartPointRoutePlan(null);
    handleChangeARPFlightLevel("");
    handleChangeARPSpeed(undefined);
    handleRNAVAirspaceFilter(null);
    handleCNAVAirspaceFilter(null);
  };

  const handleRoutePlanEffectiveDate = async (option) => {
    handleDeptTimeRoutePlan(null);
    handleChangeStartPointRoutePlan(null);
    handleChangeARPFlightLevel("");
    handleChangeARPSpeed(undefined);
    handleRNAVAirspaceFilter(null);
    handleCNAVAirspaceFilter(null);
    setArpFlightPlanErrStatus(false);
    setArpFlightPlanArrivalTime(null);
    setArpFlightPlanSuccessStatus(false);
    setOpenRoutePlanMssgDialog(false);
    setArpFlightPlanErrorsRoute([]);
    setArpFlightPlanErrorsAirspace([]);
    setLiveTracerRestrictedAirspaceDetails(null);
    setArrivalAirportRoutePlan(null);
    setDepartureAirportRoutePlan(null);
    if (option?.id) {
      setSelectedRoutePlanEffectiveDate(option);
      await dispatch(getAirspaceUsePlanDataById(option.id));
    } else {
      setSelectedAirspacePlanDetail(null);
      setSelectedRoutePlanEffectiveDate(null);
      setSelectedAUPfeatureType(null);
      setFilterFIRAirspace([]);
      setFilterAUPFIRAirspace([]);
      setFilterADIZAirspace([]);
      setFilterPdrAirspace([]);
      setFilterTraTsaAirspace([]);
      setFilterTMAAirspace([]);
      setFilterMTRAirspace([]);
      setFilterOtherRegulatedAirspace([]);
      setFilterENRTraTsaAirspace([]);
      setFilterCNAVAirspace([]);
      setFilterRNAVAirspace([]);
      setFilterNavidAirspace([]);
      setFilterWayPointsAirspace([]);
      dispatch(setRestrictiveAirspaceDetails(null));
    }
  };

  const handleAUPActiveLiveTracker = async (isChecked) => {
    setAUPActiveLiveTracker(false);
    setLoadingLivetrackerData(false);
    setFilterTraTsaAirspace([]);
    setFilterCNAVAirspace([]);
    setFilterRNAVAirspace([]);
    setShowMoreViewContent(false);
    setCurrentLiveTime(null);
    handleCloseAirspaceUsePlanDetail();
    setSelectedAUPfeatureType(null);
    setCNAVRouteLiveGeometryData([]);
    setRNAVRouteLiveGeometryData([]);
    setSelectedCNAVRouteLiveData([]);
    setSelectedRNAVRouteLiveData([]);
    setLiveTracerRestrictedAirspaceDetails(null);
    if (isChecked) {
      setAUPActiveLiveTracker(isChecked);
      const currentTime = moment().format("HHmm");
      setCurrentLiveTime(currentTime);
      const currentDate = moment().format("YYYY-MM-DD");
      const findDateByCurrentDayDate = find(airspaceUsePlanDates, [
        "effective_date",
        currentDate,
      ]);
      if (findDateByCurrentDayDate) {
        await handleAirspacePlanEffectiveDate(findDateByCurrentDayDate);
      } else {
        const effectiveOptCurrentDate = new Date(
          Math.max(
            ...airspaceUsePlanDates.map((e) => new Date(e.effective_date))
          )
        );
        const date = moment(effectiveOptCurrentDate).format("YYYY-MM-DD");
        const existingDatesCurrentDate = find(airspaceUsePlanDates, {
          effective_date: date,
        });
        await handleAirspacePlanEffectiveDate(existingDatesCurrentDate);
      }
    }
  };

  const handleLivetrackeRestrictedAirspaceDetail = (data) => {
    setLiveTracerRestrictedAirspaceDetails(data);
  };

  // Route Planning Start
  const handleDeptTimeRoutePlan = (time) => {
    setDeptTimeRoutePlan(time || null);
    setOpenRoutePlanMssgDialog(false);
  };

  const handleRouteDepartureAirport = (option) => {
    setDepartureAirportRoutePlan(option);
  };

  const handleRouteArrivalAirport = (option) => {
    setArrivalAirportRoutePlan(option);
  };

  const handleChangeStartPointRoutePlan = (startpoint) => {
    setStartPointRoutePlan(startpoint || null);
    handleChangeARPFlightLevel("");
    handleCloseRoutePlanMssg();
  };

  const handleChangeARPFlightLevel = (flLevel) => {
    setArpFlightLevelRoutePlan(flLevel || "");
    setOpenRoutePlanMssgDialog(false);
  };

  const handleChangeARPSpeed = (speed) => {
    setArpSpeedRoutePlan(speed || "");
    setOpenRoutePlanMssgDialog(false);
  };

  const handleCloseRoutePlanMssg = () => {
    // setArpFlightPlanErrMssg(null);
    // setArpFlightPlanErrorsRoute([]);
    // setArpFlightPlanErrorsAirspace([]);
    setOpenRoutePlanMssgDialog(false);
    // setArpFlightPlanErrStatus(false);
    // setArpFlightPlanArrivalTime(null);
    // setArpFlightPlanSuccessStatus(false);
    // setRoutePlanBufferSignificantPointIds([]);
    // setRoutePlanBufferNavaidIds([]);
  };

  const handleCalculateRoutePlanCallback = (res) => {
    setArpFlightPlanArrivalTime(res?.arrival_time || null);
    if (res && !res?.status) {
      setArpFlightPlanSuccessStatus(false);
      if (!isEmpty(res?.msg)) {
        toast.error(res.msg);
        setArpFlightPlanErrMssg(null);
        setOpenRoutePlanMssgDialog(false);
        setArpFlightPlanErrStatus(false);
      } else {
        setArpFlightPlanErrMssg("error");
        setOpenRoutePlanMssgDialog(true);
        setArpFlightPlanErrStatus(true);
        if (res?.errors && res?.errors?.length > 0) {
          const routeErr = [];
          const airspaceErr = [];
          res?.errors.map((err) => {
            if (err.type === "route_segment") {
              routeErr.push(err);
            } else if (err.type === "airspace") {
              airspaceErr.push(err);
            }
            return true;
          });
          setArpFlightPlanErrorsRoute(routeErr);
          setArpFlightPlanErrorsAirspace(airspaceErr);
        }
      }
    } else {
      setArpFlightPlanErrStatus(false);
      setArpFlightPlanSuccessStatus(res?.status);
      setArpFlightPlanErrMssg(null);
      setOpenRoutePlanMssgDialog(true);
      setArpFlightPlanErrorsRoute([]);
      setArpFlightPlanErrorsAirspace([]);
    }
  };

  const handleCalculateRoutePlan = () => {
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
    const isData = Boolean(
      !isEmpty(arpFlightLevelRoutePlan) &&
        !isEmpty(arpSpeedRoutePlan) &&
        deptTimeRoutePlan &&
        selectedRoutePlanEffectiveDate
    );

    const RS_ids = [];
    chain(selectedNewRoutePlanPointData)
      .uniqBy("new_RS_id")
      .orderBy(["orderid"], ["asc"])
      .map((data) => {
        if (data.new_RS_id) {
          RS_ids.push(data.new_RS_id);
        }
        return data.new_RS_id;
      })
      .value();
    if (isData) {
      const payload = {
        aup_id: Number(selectedRoutePlanEffectiveDate?.id),
        departure_time: String(deptTimeRoutePlan),
        aircraft_speed: Number(arpSpeedRoutePlan),
        aircraft_flight_level: Number(arpFlightLevelRoutePlan),
        start_point_name: !isEmpty(selectedNewRoutePlanPointData)
          ? selectedNewRoutePlanPointData[0]?.name
          : "",
        route_segment_ids: RS_ids,
      };
      if (!isEmpty(RS_ids)) {
        handleCloseRoutePlanMssg(true);

        dispatch(
          calculateFlightPlanValidation(payload, (res) => {
            handleCalculateRoutePlanCallback(res);
          })
        );
      }
    } else {
      toast.error("Some fields Missing!");
    }
  };

  const handleSearchArrivalDepartureAirportRoute = async () => {
    setSelectedNewRoutePlanPointData([]);
    if (!departureAirportRoutePlan || !arrivalAirportRoutePlan) {
      toast.error("Arrival and Departure Airport is required.");
      return;
    }
    const arrivalId = arrivalAirportRoutePlan.id;
    const departureId = departureAirportRoutePlan.id;
    const payload = {
      departure_airport_id: departureId,
      arrival_airport_id: arrivalId,
    };

    dispatch(getArrivalDepartureAirportRoutePlan(payload));
  };

  const handleClearRoutePlanDepArrvlData = () => {
    setShowCNAVRoute(false);
    setBufferPointRoutePlan(false);
    dispatch(setArrvalDepAirportRouteData([]));
    dispatch(setRouteThroughPointRPData([]));
    setSelectedRoutePlanBufferPointData(null);
    setSelectedRoutePlanRouteIds([]);
    setSelectedRoutePlanRouteNames([]);
    setSelectedRouteEndPointData(null);
    handleRoutePlanEffectiveDate(null);
    handleDeptTimeRoutePlan(null);
    handleChangeStartPointRoutePlan(null);
    handleChangeARPFlightLevel("");
    handleChangeARPSpeed(undefined);
    handleRNAVAirspaceFilter(null);
    handleCNAVAirspaceFilter(null);
    setOpenRoutePlanMssgDialog(false);
    setArpFlightPlanErrorsRoute([]);
    setArpFlightPlanErrorsAirspace([]);
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
    setSelectedNewRoutePlanPointData([]);
    setDepartureAirportRoutePlan(null);
    setArrivalAirportRoutePlan(null);
    handleRoutePlanEffectiveDate(null);
    setIsAirspaceRouteBufferPointsInSideCircle(false);
  };

  const handleSelectedNewRoutePlanningPoint = (pointData) => {
    let routeIds = [...selectedRoutePlanRouteIds];
    let routeNames = [...selectedRoutePlanRouteNames];
    routeIds.push(pointData.id);
    routeNames.push(
      `${pointData.designatorSecondLetter}${pointData.designatorNumber}`
    );
    setSelectedRoutePlanRouteIds(uniq([...routeIds]));
    setSelectedRoutePlanRouteNames(uniq([...routeNames]));

    if (pointData) {
      try {
        dispatch(
          getRouteThroughPointRP(
            pointData.name,
            pointData.designator,
            (res) => {
              const selectedSegmentData = [...selectedNewRoutePlanPointData];

              if (selectedSegmentData?.length !== 0) {
                const prevData =
                  selectedSegmentData[selectedSegmentData.length - 1];
                const findRoutedata = find(res.data, [
                  "name",
                  pointData.routeName,
                ]);

                if (findRoutedata) {
                  const findSegments = filter(
                    findRoutedata.segments,
                    (d) =>
                      (d[pointData.key] === pointData.name ||
                        d[pointData.oppkey] === pointData.name) &&
                      (d[pointData.oppkey] === prevData.name ||
                        d[pointData.key] === prevData.name)
                  );

                  if (findSegments.length === 0) {
                    toast.error("Please select next segment point.");
                    return;
                  }
                  selectedSegmentData.push({
                    ...pointData,
                    new_RS_id: findSegments[0].RS_id,
                    orderid: selectedSegmentData.length + 1,
                  });
                  setSelectedNewRoutePlanPointData([...selectedSegmentData]);
                }
              } else {
                selectedSegmentData.push({
                  ...pointData,
                  orderid: selectedSegmentData.length + 1,
                });
                setSelectedNewRoutePlanPointData([...selectedSegmentData]);
              }
            }
          )
        );
      } catch (error) {
        toast.error("Please select next segment point.");
      }
    }
  };

  const handleCheckMarkerIntoArrivalAirportRadius = (lat, lng) => {
    if (isEmpty(arrivalAirportRoutePlan)) return;
    const arrivalCircle = circle(
      [
        arrivalAirportRoutePlan.coordinates.lng,
        arrivalAirportRoutePlan.coordinates.lat,
      ],
      ARRIVAL_AIRPORT_RADIUS
    );

    const lastPoint = point([lng, lat]);
    const isIntersect = booleanIntersects(arrivalCircle, lastPoint);

    setIsAirspaceRouteBufferPointsInSideCircle(isIntersect);
  };

  const handleSelectedNewRoutePlanningEndPoint = (endpointData) => {
    setSelectedRouteEndPointData(endpointData);
    handleSelectedNewRoutePlanningPoint(endpointData);
  };

  const handleRoutePlanBufferPointData = (data) => {
    setSelectedRoutePlanBufferPointData(data);
  };

  const handleSuccessCallbackBufferPoints = (data) => {
    setRoutePlanBufferSignificantPointIds(data?.designated_point_ids || []);
    setRoutePlanBufferNavaidIds(data?.navaid_ids || []);
  };

  const handleRouteBufferPoint = (isChecked) => {
    setBufferPointRoutePlan(isChecked);
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
    if (isEmpty(selectedRoutePlanBufferPointData)) return;
    const payload = {
      coordinates: [
        selectedRoutePlanBufferPointData.lng,
        selectedRoutePlanBufferPointData.lat,
      ],
    };
    if (isChecked) {
      dispatch(
        getAirspaceRouteBufferPoints(payload, handleSuccessCallbackBufferPoints)
      );
    }
  };

  const handleShowCNAVRoutePlan = (isChecked) => {
    setShowCNAVRoute(isChecked);
  };

  const handleUndoSelectedRoutePoint = () => {
    const data = [...selectedNewRoutePlanPointData];

    data.splice(-1);
    if (data.length === 0) {
      dispatch(setRouteThroughPointRPData([]));
      setSelectedNewRoutePlanPointData([]);
      setSelectedRoutePlanRouteIds([]);
      setSelectedRoutePlanRouteNames([]);
      handleRouteBufferPoint(false);
    } else if (data.length >= 1) {
      dispatch(
        getRouteThroughPointRP(
          data[data.length - 1].name,
          data[data.length - 1].designator
        )
      );
      handleCheckMarkerIntoArrivalAirportRadius(
        data[data.length - 1].lat,
        data[data.length - 1].lng
      );
      setSelectedNewRoutePlanPointData([...data]);
      if (!isEmpty(selectedRouteEndPointData)) {
        setSelectedRouteEndPointData(null);
        handleDeptTimeRoutePlan(null);
        handleChangeARPFlightLevel(undefined);
        handleChangeARPSpeed(undefined);
      }
    }
  };

  // Route Planning End

  const handleCurrentTab = (currebtTabValue) => {
    setCurrentTab(currebtTabValue);
    handleShowContent(false);
    dispatch(setRestrictiveAirspaceDetails(null));
    handleClearEnrouteAUPData();
    handleCloseRoutePlanMssg(false);
    setLiveTracerRestrictedAirspaceDetails(null);
    setRoutePlanBufferSignificantPointIds([]);
    setRoutePlanBufferNavaidIds([]);
    handleClearRoutePlanDepArrvlData();
    const versions = [...aixmDataVersions];
    dispatch(setSelectedVersion(versions[0]));
    if (currebtTabValue === "2") {
      dispatch(getAirspaceUsePlanData());
      dispatch(getAllTRA_TSA_ADIZ());
      dispatch(getRestrictiveairspaceGeometry());
    }
  };

  const handleAirspacePlanDetail = (data) => {
    setSelectedAirspacePlanDetail(data);
    if (isAUPActiveLiveTracker && data && airspaceType.cnav === data.type) {
      const id = data.selectedPolygon.id;
      setSelectedRNAVRouteLiveData([]);
      setSelectedCNAVRouteLiveData(CNAVRouteLiveGeometryData[`${id}`]);
    } else if (
      isAUPActiveLiveTracker &&
      data &&
      airspaceType.rnav === data.type
    ) {
      const id = data.selectedPolygon.id;
      setSelectedCNAVRouteLiveData([]);
      setSelectedRNAVRouteLiveData(RNAVRouteLiveGeometryData[`${id}`]);
    }
  };

  const handleShowClosePlanDetailViewMore = (isShowHide) => {
    setShowAirspacePlanDetail(isShowHide);
  };

  const handleSelectedFeatureType = (data) => {
    setSelectedAUPfeatureType(data);
  };

  const handleShowAIRACCycleDate = () => {
    setShowAIRACCycleDate(!isShowAIRACCycleDate);
  };

  const handleVersion = (option) => {
    dispatch(setSelectedVersion(option));
  };

  const handleDateFilter = () => {
    clearDataDateFilter();
    dispatch(getFIRData(selectedVersion?.id));
    setFilterControlAirspace([]);
    dispatch(getENRAirspaceGeometry(selectedVersion?.id));
    dispatch(getAllNavaid(selectedVersion?.id));
    dispatch(getAllSignificantPoint(selectedVersion?.id));
    dispatch(getAllRNAVRoutes(selectedVersion?.id));
    dispatch(getAllCNAVRoutes(selectedVersion?.id));
  };

  return (
    <div className="wrapper">
      <Header active="airspace" />
      <div className="main-content">
        <AirspaceSideBar
          isLoadingAirport={isLoadingAirport}
          allAirports={allAirports}
          isLoadingRestrictiveAirspaceGeometry={
            isLoadingRestrictiveAirspaceGeometry
          }
          isLoadingFIRAirspaceGeometry={isLoadingFIRAirspaceGeometry}
          isLoadingRNAVRoutesGeometry={isLoadingRNAVRoutesGeometry}
          isLoadingCNAVRoutesGeometry={isLoadingCNAVRoutesGeometry}
          isLoadingRNAVRoutes={isLoadingRNAVRoutes}
          isLoadingCNAVRoutes={isLoadingCNAVRoutes}
          isLoadingAreaNavigationalRouteData={
            isLoadingAreaNavigationalRouteData
          }
          isLoadingConventionalRouteData={isLoadingConventionalRouteData}
          isLoadingGetAllNavaid={isLoadingGetAllNavaid}
          isLoadingGetAllsignificantPoint={isLoadingGetAllsignificantPoint}
          filterPdrAirspace={filterPdrAirspace}
          filterTraTsaAirspace={filterTraTsaAirspace}
          filterFIRAirspace={filterFIRAirspace}
          filterADIZAirspace={filterADIZAirspace}
          FIRAirspaceOption={FIRAirspaceOption}
          RNAVAirspaceOption={RNAVAirspaceOption}
          filterRNAVAirspace={filterRNAVAirspace}
          CNAVAirspaceOption={CNAVAirspaceOption}
          filterCNAVAirspace={filterCNAVAirspace}
          filterAD217Airspace={filterAD217Airspace}
          filterControlAirspace={filterControlAirspace}
          controlAirspaceOption={ctrlAirspaceOption}
          ad217ENRAirspaceOptions={ad217ENRAirspaceOptions}
          handleENRAD217AirspaceFilter={handleENRAD217AirspaceFilter}
          handleControlAirspaceFilter={handleControlAirspaceFilter}
          filterENRTraTsaAirspace={filterENRTraTsaAirspace}
          handlePdrAirspaceFilter={handlePdrAirspaceFilter}
          handletraTsaAirspaceFilter={handletraTsaAirspaceFilter}
          handleENRTraTsaAirspaceFilter={handleENRTraTsaAirspaceFilter}
          handleFIRAirspaceFilter={handleFIRAirspaceFilter}
          handleADIZAirspaceFilter={handleADIZAirspaceFilter}
          handleRNAVAirspaceFilter={handleRNAVAirspaceFilter}
          handleCNAVAirspaceFilter={handleCNAVAirspaceFilter}
          navaidOptions={navaidOptions}
          filterNavidAirspace={filterNavidAirspace}
          handleNavidAirspaceFilter={handleNavidAirspaceFilter}
          significantPointOptions={significantPointOptions}
          filterWayPointsAirspace={filterWayPointsAirspace}
          handleWayPointAirspaceFilter={handleWayPointAirspaceFilter}
          isLoadingWaypointRouteData={isLoadingWaypointRouteData}
          selectedEffectiveDate={selectedEffectiveDate}
          selectedAirspacePlanDetail={selectedAirspacePlanDetail}
          handleAirspacePlanEffectiveDate={handleAirspacePlanEffectiveDate}
          handlecurrentTab={handleCurrentTab}
          isShowAirspacePlanDetail={isShowAirspacePlanDetail}
          airspaceUsePlanDates={airspaceUsePlanDates}
          militryAUPOptionData={militryAUPOptionData}
          routeCNAVAUPOptionData={routeCNAVAUPOptionData}
          routeRNAVAUPOptionData={routeRNAVAUPOptionData}
          handleAUPFIRAirspaceFilter={handleAUPFIRAirspaceFilter}
          filterAUPFIRAirspace={filterAUPFIRAirspace}
          isAUPActiveLiveTracker={isAUPActiveLiveTracker}
          handleAUPActiveLiveTracker={handleAUPActiveLiveTracker}
          currentLiveTime={currentLiveTime}
          handleRoutePlanEffectiveDate={handleRoutePlanEffectiveDate}
          selectedRoutePlanEffectiveDate={selectedRoutePlanEffectiveDate}
          deptTimeRoutePlan={deptTimeRoutePlan}
          handleDeptTimeRoutePlan={handleDeptTimeRoutePlan}
          arpFlightLevelRoutePlan={arpFlightLevelRoutePlan}
          handleChangeARPFlightLevel={handleChangeARPFlightLevel}
          arpSpeedRoutePlan={arpSpeedRoutePlan}
          handleChangeARPSpeed={handleChangeARPSpeed}
          startPointRoutePlan={startPointRoutePlan}
          handleChangeStartPointRoutePlan={handleChangeStartPointRoutePlan}
          startPointCNAVPlanOpts={startPointCNAVPlanOpts}
          startPointRNAVPlanOpts={startPointRNAVPlanOpts}
          handleCalculateRoutePlan={handleCalculateRoutePlan}
          isLoadingRoutePlanValidation={isLoadingRoutePlanValidation}
          RouteplanCNAVOpts={RouteplanCNAVOpts}
          RouteplanRNAVOpts={RouteplanRNAVOpts}
          flightLevelMin={flightLevelMin}
          flightLevelMax={flightLevelMax}
          departureAirportRoutePlan={departureAirportRoutePlan}
          arrivalAirportRoutePlan={arrivalAirportRoutePlan}
          handleRouteArrivalAirport={handleRouteArrivalAirport}
          handleRouteDepartureAirport={handleRouteDepartureAirport}
          isBufferPointRoutePlan={isBufferPointRoutePlan}
          handleRouteBufferPoint={handleRouteBufferPoint}
          isShowCNAVRoute={isShowCNAVRoute}
          handleShowCNAVRoutePlan={handleShowCNAVRoutePlan}
          handleSearchArrivalDepartureAirportRoute={
            handleSearchArrivalDepartureAirportRoute
          }
          handleClearRoutePlanDepArrvlData={handleClearRoutePlanDepArrvlData}
          arrvalDepAirportRouteData={ShowCNAVRoutePlanData}
          selectedNewRoutePlanPointData={selectedNewRoutePlanPointData}
          selectedRouteEndPointData={selectedRouteEndPointData}
          isAirspaceRouteBufferPointsInSideCircle={
            isAirspaceRouteBufferPointsInSideCircle
          }
          isENRAirspaceGeometryLoading={isENRAirspaceGeometryLoading}
          pdrENRAirspaceOptions={pdrENRAirspaceOptions}
          tratsaENRAirspaceOptions={tratsaENRAirspaceOptions}
          adizENRAirspaceOptions={adizENRAirspaceOptions}
          mtrENRAirspaceOptions={mtrENRAirspaceOptions}
          tmaENRAirspaceOptions={tmaENRAirspaceOptions}
          otherRegulatedENRAirspaceOptions={otherRegulatedENRAirspaceOptions}
          filterTMAAirspace={filterTMAAirspace}
          filterMTRAirspace={filterMTRAirspace}
          filterOtherRegulatedAirspace={filterOtherRegulatedAirspace}
          handleOtherRegulatedAirspaceFilter={
            handleOtherRegulatedAirspaceFilter
          }
          handleMTRAirspaceFilter={handleMTRAirspaceFilter}
          handleTMAAirspaceFilter={handleTMAAirspaceFilter}
        />
        <div className="main">
          <div className="content-area">
            <AirspaceMap
              airportLocation={airportLocation}
              zoomLevel={zoomLevel}
              colorTray={colorTray.sort()}
              filterFIRAirspace={filterFIRAirspace}
              arrvalDepAirportRouteData={ShowCNAVRoutePlanData}
              departureAirportRoutePlan={departureAirportRoutePlan}
              arrivalAirportRoutePlan={arrivalAirportRoutePlan}
              // pdrAirspaceGeometry={pdrAirspaceGeometry}
              traTsaAUPAirspaceGeometry={traTsaAUPAirspaceGeometry}
              controlAirspaceGeometry={ctrlAirspaceGeometry}
              FIRAirspaceGeometry={FIRAirspaceGeometry}
              // ADIZAirspaceGeometry={ADIZAirspaceGeometry}
              areaNavigationalRouteData={areaNavigationalRouteData}
              conventionalRouteData={conventionalRouteData}
              navidAirspaceGeometry={navidAirspaceGeometry}
              wayPointsAirspaceGeometry={wayPointsAirspaceGeometry}
              waypointRouteGeometry={waypointRouteGeometry}
              isShowMoreView={isShowMoreView}
              isShowMoreViewContent={isShowMoreViewContent}
              currentTab={currentTab}
              selectedAirspacePlanDetail={selectedAirspacePlanDetail}
              isShowAirspacePlanDetail={isShowAirspacePlanDetail}
              selectedEffectiveDate={selectedEffectiveDate}
              filterTraTsaAirspace={filterTraTsaAirspace}
              filterCNAVAirspace={filterCNAVAirspace}
              filterRNAVAirspace={filterRNAVAirspace}
              selectedAUPfeatureType={selectedAUPfeatureType}
              handleShowContent={handleShowContent}
              handleAirspacePlanDetail={handleAirspacePlanDetail}
              handleSelectedFeatureType={handleSelectedFeatureType}
              AUPFIRAirspaceGeometry={AUPFIRAirspaceGeometry}
              handleShowClosePlanDetailViewMore={
                handleShowClosePlanDetailViewMore
              }
              handleCloseAirspaceUsePlanDetail={
                handleCloseAirspaceUsePlanDetail
              }
              isAUPActiveLiveTracker={isAUPActiveLiveTracker}
              currentLivemilitryTimelinedata={currentLivemilitryTimelinedata}
              currentLiveRNAVTimelinedata={currentLiveRNAVTimelinedata}
              currentLiveCNAVTimelinedata={currentLiveCNAVTimelinedata}
              RNAVRouteLiveGeometryData={RNAVRouteLiveGeometryData}
              CNAVRouteLiveGeometryData={CNAVRouteLiveGeometryData}
              currentLiveTime={currentLiveTime}
              isLoadingLivetrackerData={isLoadingLivetrackerData}
              startPointRoutePlan={startPointRoutePlan}
              arpFlightPlanErrMssg={arpFlightPlanErrMssg}
              arpFlightPlanErrStatus={arpFlightPlanErrStatus}
              arpFlightPlanSuccessStatus={arpFlightPlanSuccessStatus}
              openRoutePlanMssgDialog={openRoutePlanMssgDialog}
              handleCloseRoutePlanMssg={handleCloseRoutePlanMssg}
              selectedCNAVRouteLiveData={selectedCNAVRouteLiveData}
              selectedRNAVRouteLiveData={selectedRNAVRouteLiveData}
              arpFlightPlanErrorsAirspace={arpFlightPlanErrorsAirspace}
              arpFlightPlanErrorsRoute={arpFlightPlanErrorsRoute}
              handleLivetrackeRestrictedAirspaceDetail={
                handleLivetrackeRestrictedAirspaceDetail
              }
              liveTracerRestrictedAirspaceDetails={
                liveTracerRestrictedAirspaceDetails
              }
              arpFlightPlanArrivalTime={arpFlightPlanArrivalTime}
              routeBufferSignificantPointGeometry={
                routeBufferSignificantPointGeometry
              }
              routeBufferNavaidGeometry={routeBufferNavaidGeometry}
              handleSelectedNewRoutePlanningPoint={
                handleSelectedNewRoutePlanningPoint
              }
              selectedNewRoutePlanPointData={selectedNewRoutePlanPointData}
              selectedRoutePlanRouteIds={selectedRoutePlanRouteIds}
              handleRoutePlanBufferPointData={handleRoutePlanBufferPointData}
              handleSelectedNewRoutePlanningEndPoint={
                handleSelectedNewRoutePlanningEndPoint
              }
              routeThroughPointRPData={routeThroughPointRPData}
              handleUndoSelectedRoutePoint={handleUndoSelectedRoutePoint}
              selectedRouteEndPointData={selectedRouteEndPointData}
              selectedRoutePlanEffectiveDate={selectedRoutePlanEffectiveDate}
              handleCheckMarkerIntoArrivalAirportRadius={
                handleCheckMarkerIntoArrivalAirportRadius
              }
            />
            {currentTab === "1" && (
              <Box sx={styles.AIRACCycle}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: 1,
                    cursor: "pointer",
                    svg: {
                      transform: isShowAIRACCycleDate
                        ? "rotate(-180deg)"
                        : "rotate(0deg)",
                    },
                  }}
                  onClick={handleShowAIRACCycleDate}
                >
                  <LeftArrowIcon />
                </Box>
                <Box
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#42444A",
                    paddingRight: 2,
                    width: 42,
                    cursor: "pointer",
                  }}
                >
                  AIRAC Cycle
                </Box>
                {isShowAIRACCycleDate && (
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ ...styles.filterDate, marginRight: 6 }}
                  >
                    <Box
                      className="filter-date"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: 12 }}>
                        Published Date
                      </Typography>
                      <Select
                        isClearable
                        isDisabled={isLoadingGetAixmDataVersion}
                        value={selectedVersion || null}
                        onChange={handleVersion}
                        placeholder="Publication Date"
                        options={aixmDataVersions}
                        getOptionLabel={(option) => option.published_date}
                        getOptionValue={(option) => option.published_date}
                        styles={commonStyles.selectDateScrollbar}
                      />
                    </Box>
                    <Box
                      className="filter-date"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: 12 }}>
                        Effective Date
                      </Typography>
                      <Select
                        isClearable
                        isDisabled={isLoadingGetAixmDataVersion}
                        value={selectedVersion || null}
                        placeholder="Effective Date"
                        onChange={handleVersion}
                        options={aixmDataVersions}
                        getOptionLabel={(option) => option.effective_date}
                        getOptionValue={(option) => option.effective_date}
                        styles={commonStyles.selectDateScrollbar}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                      <PrimaryButton
                        label="Apply Filter"
                        disabled={
                          isLoadingGetAixmDataVersion ||
                          !Boolean(selectedVersion)
                        }
                        onClick={handleDateFilter}
                        sx={{ ...styles.filterBtn, width: "121px" }}
                      />
                    </Box>
                  </Stack>
                )}
              </Box>
            )}
            {(isLoadingLivetrackerData ||
              isLoadingRoutePlanValidation ||
              isAirspaceRouteBufferPointsLoading ||
              isArrivalDepartureAirportRoutePlanLoading ||
              isRouteThroughPointRPLoading) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  zIndex: "9999",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#6236c638",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
