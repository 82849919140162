import { isEmpty, upperFirst } from "lodash";

export const getEtodErea = (info) => {
  if (info && info.rwy_area) {
    return info.rwy_area.slice(-2);
  }

  return "-";
};

export const shortLabel = (string) => {
  const label = string
    .split(" ")
    .map((word) => upperFirst(word[0]))
    .join("");

  return label;
};
export const shortScheduleLabel = (string) => {
  const label = string
    .split("_")
    .map((word) => upperFirst(word[0]))
    .join("");

  return label;
};

export const replaceOTHER = (string) => {
  if (isEmpty(string)) return "";
  const label = string.replace("OTHER:", "").replace(/_/g, " ").toUpperCase();

  return label;
};

export const replaceUnderScore = (string) => {
  if (isEmpty(string)) return "-";
  const label = string.replace("_", " ").toUpperCase();

  return label;
};

export const cleanString = (string) => {
  if (isEmpty(string)) return "-";
  const label = string.replace(/^(?:[^_]*_){2}/g, (txt) => {
    return txt.split("_").join(" ").toUpperCase();
  });

  return label;
};

export const splitCleanString = (string) => {
  if (isEmpty(string)) return [];
  const label = string
    .replace(/^(?:[^_]*_){2}/g, (txt) => {
      return txt.split("_").join(" ").toUpperCase();
    })
    .split(" ");

  return label;
};
