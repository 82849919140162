import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";

export default function BackButton({ handleBack, label = "Back",icon, ...props }) {
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      size="small"
      startIcon={icon || <ArrowleftIcon />}
      onClick={() => {
        handleBack ? handleBack() : navigate(-1);
      }}
      {...props}
    >
      {label}
    </Button>
  );
}
