import React from "react";

function PolylineIcon(props) {
  return (
    <svg
      width={props.width || "13"}
      height={props.height || "13"}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.16797 2.16406L10.8337 10.8298" stroke={props.color || "#42444A"} />
      <circle cx="1.44576" cy="1.44429" r="1.44429" fill={props.color || "#42444A"} />
      <circle cx="11.5556" cy="11.5537" r="1.44429" fill={props.color || "#42444A"} />
    </svg>
  );
}

export default PolylineIcon;
