import React from "react";
import { theme } from "@styles/theme";

export const DownloadBoldIcon = ({ disabled, ...props }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13.25C1.41421 13.25 1.75 13.5858 1.75 14V16C1.75 16.1381 1.86193 16.25 2 16.25H14C14.1381 16.25 14.25 16.1381 14.25 16V14C14.25 13.5858 14.5858 13.25 15 13.25C15.4142 13.25 15.75 13.5858 15.75 14V16C15.75 16.9665 14.9665 17.75 14 17.75H2C1.0335 17.75 0.25 16.9665 0.25 16V14C0.25 13.5858 0.585786 13.25 1 13.25Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
    <path
      d="M6.73795 0.75C6.22356 0.75 5.79447 1.14312 5.74955 1.65555C5.59591 3.40826 5.56881 5.16959 5.66838 6.92584C5.42139 6.93949 5.1745 6.95531 4.92773 6.9733L3.43822 7.08187C2.86392 7.12374 2.54288 7.7641 2.85293 8.24932C3.91497 9.91141 5.28343 11.3563 6.88538 12.5071L7.48212 12.9358C7.79162 13.1581 8.20849 13.1581 8.51799 12.9358L9.11473 12.5071C10.7167 11.3563 12.0851 9.91141 13.1472 8.24932C13.4572 7.7641 13.1362 7.12374 12.5619 7.08188L11.0724 6.9733C10.8256 6.95531 10.5787 6.93949 10.3317 6.92584C10.4313 5.16959 10.4042 3.40826 10.2506 1.65554C10.2056 1.14312 9.77655 0.75 9.26216 0.75H6.73795Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
  </svg>
);
