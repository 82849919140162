import React from "react";
import { Popup } from "react-leaflet";
import { styles } from "@styles/mapStyles";
import { getObstacleSymbol } from "@utils/obstacleSymbols";
import PopupBox from "./PopupBox";
import PopupItem from "./PopupItem";

export default function AnalysisObstaclePopup({ info }) {
  return (
    <Popup>
      <div style={styles.obstaclePopBox}>
        {getObstacleSymbol(info.obstacle_type)}
      </div>
      <PopupBox>
        <PopupItem>Point Name</PopupItem>
        <PopupItem>{info.point_name || "-"}</PopupItem>
        <PopupItem>Object</PopupItem>
        <PopupItem>{info.object || "-"}</PopupItem>
        <PopupItem>Latitude</PopupItem>
        <PopupItem>{info.latitude || "-"}</PopupItem>
        <PopupItem>Longitude</PopupItem>
        <PopupItem>{info.longitude || "-"}</PopupItem>
        <PopupItem>Affected Area</PopupItem>
        <PopupItem>{info.affected_area || "-"}</PopupItem>
        <PopupItem>UOM Elevation</PopupItem>
        <PopupItem>{info.uom_elevation || "-"}</PopupItem>
        <PopupItem>Top Elevation</PopupItem>
        <PopupItem>{info.top_elev || "-"}</PopupItem>
        <PopupItem>Permissible Elevation</PopupItem>
        <PopupItem>{info.permissible_elevation || "-"}</PopupItem>
        <PopupItem>Difference</PopupItem>
        <PopupItem>{info.difference || "-"}</PopupItem>
        <PopupItem>Horizontal Datum</PopupItem>
        <PopupItem>{info.horizontal_datum || "-"}</PopupItem>
        <PopupItem>Vertical datum</PopupItem>
        <PopupItem>{info.vertical_datum || "-"}</PopupItem>
        <PopupItem>Marking LGT</PopupItem>
        <PopupItem>{info.marking_lgt || "-"}</PopupItem>
      </PopupBox>
    </Popup>
  );
}
