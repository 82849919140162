import React, { Fragment, useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp, FaArrowUp, FaArrowDown } from "react-icons/fa";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Box,
  Stack,
  Collapse,
} from "@mui/material";
import { LightButton } from "@components/button";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableRowLoader from "@components/tabel-component/TableRowLoader";
import { theme } from "@styles/theme";
import { convertToDms } from "@utils/geocoords";

export default function RouteTable({ isLoading, routeData }) {
  
  const [open, setOpen] = useState(false);
  const arrLength = routeData.length;
  const lastRow = routeData[arrLength - 1];
  const remarks = routeData[0]?.remarks;
  const handleRowExpand = () => setOpen(!open);

  useEffect(() => {
    if (isLoading) {
      setOpen(false);
    }
  }, [isLoading]); // eslint-disable-line

  return (
    <TableContainer sx={{ maxHeight: 300, overflowX: "unset" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Box>Route Designator (RNP Type)</Box>
              <Box>Name of Significant Points</Box>
              <Box>Coordinates</Box>
            </StyledTableCell>
            <StyledTableCell>
              <Box>Track Magnetic/Geo VOR Radial</Box>
              <Box>Distance</Box>
            </StyledTableCell>
            <StyledTableCell>
              <Box>Upper Limit</Box>
              <Box>Lower Limit</Box>
            </StyledTableCell>
            <StyledTableCell>
              <Box>Lateral</Box>
              <Box>Limits</Box>
            </StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>
              <Box>Direction of Cruising Levels</Box>
              <Stack direction="row" justifyContent="space-around">
                <Box>Odd</Box>
                <Box>Even</Box>
              </Stack>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: theme.palette.white.main }}>
          <TableRowLoader isLoading={isLoading} data={routeData} colSpan={6} />
          {!isLoading && routeData.length > 0 && (
            <TableRow>
              <StyledTableCell
                colSpan={6}
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                <Box>
                  {routeData[0].designatorSecondLetter}
                  {routeData[0].designatorNumber}
                  {routeData[0].multipleIdentifier}{" "}
                  {`(${routeData[0]?.navigationType}${
                    routeData[0]?.RNP ? " " : ""
                  }${routeData[0]?.RNP ? routeData[0]?.RNP : ""})`.trim()}
                </Box>
                <Box>
                  ({routeData[0].startpoint_name}
                  {" - "}
                  {lastRow.endpoint_name}){" "}
                </Box>
              </StyledTableCell>
            </TableRow>
          )}
          {!isLoading &&
            routeData.map((r) => (
              <Fragment key={r.RS_id}>
                <TableRow>
                  <StyledTableCell>
                    <Box>{r.startpoint_name}</Box>
                    <Box>
                      {r.startpoint_navaid_type}{" "}
                      {r.startpoint_designator
                        ? `(${r.startpoint_designator})`
                        : ""}
                    </Box>
                    <Box>
                      {convertToDms(r.DD_startpoint_coordinates.lat)},{" "}
                      {convertToDms(r.DD_startpoint_coordinates.lng, true)}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                </TableRow>
                <TableRow>
                  <StyledTableCell />
                  <StyledTableCell>
                    <Box>
                      {r.magneticTrack || "-"}
                      {"/"}
                      {r.reverseMagneticTrack || "-"}
                    </Box>
                    <Box>{r.length}</Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box>{r.upperLimit}</Box>
                    <Box>{r.lowerLimit}</Box>
                  </StyledTableCell>
                  <StyledTableCell>{r.Lateral_Limits}</StyledTableCell>
                  <StyledTableCell align="center">
                    {r.ODD ? (
                      r.ODD === "FORWARD" ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : (
                      "-"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {r.EVEN ? (
                      r.EVEN === "FORWARD" ? (
                        <FaArrowDown />
                      ) : (
                        <FaArrowUp />
                      )
                    ) : (
                      "-"
                    )}
                  </StyledTableCell>
                </TableRow>
              </Fragment>
            ))}
          {!isLoading && routeData.length > 0 && lastRow && (
            <Fragment>
              <TableRow>
                <StyledTableCell>
                  <Box>{lastRow.endpoint_name}</Box>
                  <Box>
                    {" "}
                    {lastRow.endpoint_navaid_type}{" "}
                    {lastRow.endpoint_designator
                      ? `(${lastRow.endpoint_designator})`
                      : ""}
                  </Box>
                  <Box>
                    {convertToDms(lastRow.DD_endpoint_coordinates.lat)},{" "}
                    {convertToDms(lastRow.DD_endpoint_coordinates.lng, true)}
                  </Box>
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </TableRow>
            </Fragment>
          )}
          {!isLoading && remarks && (
            <TableRow>
              <StyledTableCell colSpan={6}>
                <Stack>
                  <Box>
                    <LightButton
                      label="REMARKS"
                      size="small"
                      onClick={() => handleRowExpand()}
                      endIcon={open ? <FaAngleUp /> : <FaAngleDown />}
                    />
                  </Box>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>{remarks}</Box>
                  </Collapse>
                </Stack>
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
