import React, { Fragment } from "react";
import { flatten, chain, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3dOLSConicalSurface({
  OLSConicalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSConicalSurfaceList = chain(OLSConicalSurfaceList)
    .filter((csObj) => {
      return selectedOls.includes(
        `${csObj.category}__${olsColor[csObj.category]}`
      );
    })
    .value();

  return (
    <Fragment>
      {visibleOLSConicalSurfaceList.map((olsCs, i) => {
        const hslCoordinates = olsCs.coordinates.coordinates;
        const hslCoordinateMap = flatten(hslCoordinates).map((hslCoords) => {
          return hslCoords.map((hsl) => {
            return [hsl[0], hsl[1], hsl[2] * ZvalueMultiplyby];
          });
        });
        const outerArea = hslCoordinateMap[0];
        const holeArea = hslCoordinateMap[1];

        return (
          <Entity
            key={i}
            name={olsCs.category}
            polygon={{
              hierarchy: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                  flattenDeep(outerArea)
                ),
                holes: [
                  {
                    positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                      flattenDeep(holeArea)
                    ),
                  },
                ],
              },
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[olsCs.category]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
