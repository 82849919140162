import React, { Fragment } from "react";
import { Polyline, Marker } from "react-leaflet";
import { isEmpty } from "lodash";
import { CivilAerodomeIcon } from "@utils/mapIcon";

const RenderRoutePlanArrivalDepartureAirport = ({
  arrivalArpCoord,
  departureArpCoord,
}) => {
  const linedata =
    !isEmpty(arrivalArpCoord) && !isEmpty(departureArpCoord)
      ? [
          [arrivalArpCoord.lat, arrivalArpCoord.lng],
          [departureArpCoord.lat, departureArpCoord.lng],
        ]
      : [];

  return (
    <Fragment>
      {!isEmpty(linedata) && (
        <Polyline
          pane="tilePane"
          positions={linedata}
          pathOptions={{
            color: "#fff",
            weight: 1,
            dashArray: "5 7",
          }}
        />
      )}
      {!isEmpty(arrivalArpCoord) && (
        <Marker
          pane="tilePane"
          icon={CivilAerodomeIcon}
          position={[arrivalArpCoord.lat, arrivalArpCoord.lng]}
        />
      )}
      {!isEmpty(departureArpCoord) && (
        <Marker
          pane="tilePane"
          icon={CivilAerodomeIcon}
          position={[departureArpCoord.lat, departureArpCoord.lng]}
        />
      )}
    </Fragment>
  );
};

export default RenderRoutePlanArrivalDepartureAirport;
