import React from "react";
import TableRow from "@mui/material/TableRow";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function FilterTableHeadRow({ isShowBaseElev }) {
  return (
    <TableRow>
      <StyledTableCell align="center">UID</StyledTableCell>
      <StyledTableCell align="center">POINT NAME</StyledTableCell>
      <StyledTableCell align="center">LATITUDE</StyledTableCell>
      <StyledTableCell align="center">LONGITUDE</StyledTableCell>
      <StyledTableCell align="center">TOP ELEV. IN m. (EGM 08)</StyledTableCell>
      {isShowBaseElev && (
        <StyledTableCell align="center">
          BASE ELEV. IN m. (EGM 08)
        </StyledTableCell>
      )}
      {!isShowBaseElev && (
        <StyledTableCell align="center">
          ELLIPSOIDAL ELEV. IN m.
        </StyledTableCell>
      )}
    </TableRow>
  );
}
