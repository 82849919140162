import React from "react";

const DeleteIcon = ({ disabled = false, ...props }) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.9375 1.25C3.9375 0.93934 4.18934 0.6875 4.5 0.6875H7.5C7.81066 0.6875 8.0625 0.93934 8.0625 1.25V1.8125H11.25C11.5607 1.8125 11.8125 2.06434 11.8125 2.375C11.8125 2.68566 11.5607 2.9375 11.25 2.9375H0.75C0.43934 2.9375 0.1875 2.68566 0.1875 2.375C0.1875 2.06434 0.43934 1.8125 0.75 1.8125H3.9375V1.25Z"
        fill={disabled ? "#D9D9D9" : "#E91414"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.67992 4.95859C1.70102 4.76868 1.86155 4.625 2.05263 4.625H9.94736C10.1384 4.625 10.299 4.76868 10.3201 4.95859L10.4702 6.3096C10.7408 8.74483 10.7408 11.2025 10.4702 13.6378L10.4554 13.7708C10.3569 14.6577 9.66946 15.3649 8.78569 15.4887C6.93757 15.7474 5.06242 15.7474 3.2143 15.4887C2.33053 15.3649 1.64314 14.6577 1.54459 13.7708L1.52981 13.6378C1.25923 11.2025 1.25923 8.74483 1.52981 6.3096L1.67992 4.95859ZM5.0625 7.55C5.0625 7.23934 4.81066 6.9875 4.5 6.9875C4.18934 6.9875 3.9375 7.23934 3.9375 7.55L3.9375 12.8C3.9375 13.1107 4.18934 13.3625 4.5 13.3625C4.81066 13.3625 5.0625 13.1107 5.0625 12.8L5.0625 7.55ZM8.0625 7.55C8.0625 7.23934 7.81066 6.9875 7.5 6.9875C7.18934 6.9875 6.9375 7.23934 6.9375 7.55V12.8C6.9375 13.1107 7.18934 13.3625 7.5 13.3625C7.81066 13.3625 8.0625 13.1107 8.0625 12.8V7.55Z"
        fill={disabled ? "#D9D9D9" : "#E91414"}
      />
    </svg>
  );
};

export default DeleteIcon;
