import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import { Polyline, Marker } from "react-leaflet";
import { getRouteWayPointIcon } from "@utils/routeWayPointIcon";
import { LightTriangleIcon } from "@utils/mapIcon";

const RenderDepArrvlAirportRouteThroughPointData = ({
  routeData,
  routeId,
  routeName,
  selectedRoutePlanRouteIds,
  handleSelectedNewRoutePlanningPoint,
  handleSelectedNewRoutePlanningEndPoint,
  handleRoutePlanBufferPointData,
  handleCheckMarkerIntoArrivalAirportRadius,
}) => {
  // const arrLength = routeData?.length || 0;
  // const lastRowIndex = arrLength === 0 ? 0 : arrLength - 1;
  // const lastRow = routeData[lastRowIndex];
  const firstRow = !isEmpty(routeData) ? routeData[0] : [];

  // eslint-disable-next-line
  const handlePolygonOver = (e) => {
    e.target.openPopup();
  };

  const handlePointClick = (d) => {
    const payload = {
      routeName: `${d.designatorSecondLetter}${d.designatorNumber}`,
      RS_id: d.RS_id,
      id: d.id,
      name: d.startpoint_name,
      designator: d.startpoint_designator,
      reportingATC: d.startpoint_reportingATC,
      lng: d.DD_startpoint_coordinates.lng,
      lat: d.DD_startpoint_coordinates.lat,
      navigationType: d.navigationType,
      key: "startpoint_name",
      oppkey: "endpoint_name",
    };
    handleSelectedNewRoutePlanningPoint(payload);
    handleRoutePlanBufferPointData(payload);
    handleCheckMarkerIntoArrivalAirportRadius(payload.lat, payload.lng);
  };

  // eslint-disable-next-line
  const handleEndPointClick = (d) => {
    const payload = {
      routeName: `${d.designatorSecondLetter}${d.designatorNumber}`,
      RS_id: d.RS_id,
      id: d.id,
      name: d.endpoint_name,
      designator: d.endpoint_designator,
      reportingATC: d.endpoint_reportingATC,
      lng: d.DD_endpoint_coordinates.lng,
      lat: d.DD_endpoint_coordinates.lat,
      navigationType: d.navigationType,
      key: "endpoint_name",
      oppkey: "startpoint_name",
    };
    handleRoutePlanBufferPointData(payload);
    handleSelectedNewRoutePlanningEndPoint(payload);
    handleCheckMarkerIntoArrivalAirportRadius(payload.lat, payload.lng);
  };

  if (isEmpty(routeData)) return null;

  return (
    <Fragment>
      {!isEmpty(routeData) &&
        routeData.map((d, i) => {
          const path = [
            [d.DD_startpoint_coordinates.lat, d.DD_startpoint_coordinates.lng],
            [d.DD_endpoint_coordinates.lat, d.DD_endpoint_coordinates.lng],
          ];

          return (
            <Polyline
              key={i}
              pane="markerPane"
              positions={path}
              pathOptions={{
                color: selectedRoutePlanRouteIds?.includes(routeId)
                  ? d.navigationType === "RNAV"
                    ? "#F2994A"
                    : "#EFFF34"
                  : d.navigationType === "RNAV"
                  ? "#F2994A66"
                  : "#EFFF3466",
                weight: selectedRoutePlanRouteIds?.includes(routeId) ? 2 : 1.5,
                dashArray: d.navigationType !== "RNAV" ? "6 10" : "0 0",
              }}
              // eventHandlers={{
              //   mouseover: (e) => handlePolygonOver(e),
              //   click: () => handlePolygonClick(cnav),
              // }}
            />
          );
        })}

      {!isEmpty(routeData) && Boolean(firstRow) && (
        <Marker
          pane="tooltipPane"
          icon={getRouteWayPointIcon(firstRow.startpoint_reportingATC)}
          position={[
            firstRow.DD_startpoint_coordinates.lat,
            firstRow.DD_startpoint_coordinates.lng,
          ]}
          eventHandlers={{
            // mouseover: (e) => handlePolygonOver(e),
            click: () => {
              handlePointClick(firstRow);
              // handleEndPointClick(firstRow);
            },
          }}
        />
      )}
      {!isEmpty(routeData) &&
        routeData.map((d, i) => {
          return (
            <Marker
              key={i}
              pane="tooltipPane"
              icon={
                selectedRoutePlanRouteIds?.includes(routeId)
                  ? getRouteWayPointIcon(d.endpoint_reportingATC)
                  : LightTriangleIcon
              }
              position={[
                d.DD_endpoint_coordinates.lat,
                d.DD_endpoint_coordinates.lng,
              ]}
              eventHandlers={{
                // mouseover: (e) => handlePolygonOver(e),
                click: () => {
                  handleEndPointClick(d);
                },
              }}
            />
          );
        })}
    </Fragment>
  );
};

export default RenderDepArrvlAirportRouteThroughPointData;
