import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import Select from "react-select";
import {
  Box,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";
import { Typography } from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import AntSwitch from "@components/core/AntSwitch";
import { theme } from "@styles/theme";
import { getStorageValue } from "@utils/localStorage";
import EChartExportButton from "./EChartExportButton";
import DropDownLabel from "./DropDownLabel";

const ElecronicChartSideBar = ({
  isLoadingAlleChartAirport,
  isLoadingechartPDFData,
  isLoadingechartData,
  isLoadingAlleChartExportUrl,
  allAirports = [],
  selctedAirport,
  handleAirport,
  eChartOptions,
  handleeChartValueChange,
  selectedEchart,
  isShowLabel,
  handleLabelToggle,
  isShowTable,
  handleTableToggle,
  handleChartExport,
  atocaData,
  alleChartExportUrls,
  handleShowMaxTerrainToggle,
  isShowMaxTerrain,
  handleExpandClear,
}) => {
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const pdcOptions = eChartOptions.filter((option) => option?.value !== "ADC");
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    setExpanded([]);
  }, [selctedAirport]);

  const handleExpand = (exp) => {
    setExpanded(exp);
    handleExpandClear();
  };

  return (
    <div className="sidebar">
      <Box sx={{ pb: 1.5, borderBottom: "0.5px solid #B1B1B1" }}>
        <DropDownLabel label="Airport" isRequired />
        <Select
          isClearable
          isLoading={isLoadingAlleChartAirport}
          isDisabled={isLoadingechartPDFData || isLoadingechartData}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleAirport}
          options={allAirports}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
        />
      </Box>
      {(isLoadingechartPDFData || isLoadingechartData) && <LinearProgress />}
      <div className="check-list echart-check-list">
        {eChartOptions.map((option, i) => {
          return (
            option.value === "ADC" && (
              <TreeView
                aria-label="ADC-features"
                sx={{ mb: "18px !important", mt: "18px !important" }}
                className="echart-option-tree"
                defaultCollapseIcon={
                  <FaAngleDown
                    color={theme.palette.primary.main}
                    onClick={() => handleExpand([])}
                  />
                }
                defaultExpandIcon={
                  <FaAngleRight
                    color={theme.palette.primary.main}
                    onClick={() => handleExpand(["ADC"])}
                  />
                }
                key={i}
                expanded={expanded}
              >
                <TreeItem
                  key={option.label}
                  nodeId="ADC"
                  label={option.value}
                  className="echart-option-tree-item"
                >
                  <RadioGroup
                    aria-labelledby="eChart-radio-buttons"
                    name="eChart-radio-buttons-group"
                    value={selectedEchart || ""}
                    onChange={handleeChartValueChange}
                  >
                    <FormControlLabel
                      key={i}
                      className="radio-label"
                      label={option.label}
                      value={option.value}
                      disabled={!selctedAirport}
                      control={<Radio />}
                    />
                  </RadioGroup>
                  <Box className="box-footer">
                    <Box className="box-footer-label">
                      <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                        Label
                      </Typography>
                      <AntSwitch
                        checked={isShowLabel}
                        disabled={!selectedEchart}
                        onChange={handleLabelToggle}
                      />
                    </Box>
                    <Box className="box-footer-tabel">
                      <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                        Table
                      </Typography>
                      <AntSwitch
                        disabled={
                          !selectedEchart || selectedEchart.startsWith("ATOCA")
                        }
                        checked={isShowTable}
                        onChange={handleTableToggle}
                      />
                    </Box>
                  </Box>
                </TreeItem>
              </TreeView>
            )
          );
        })}
        {pdcOptions && pdcOptions.length > 0 && (
          <TreeView
            aria-label="PDC-features"
            className="echart-option-tree"
            defaultCollapseIcon={
              <FaAngleDown
                color={theme.palette.primary.main}
                onClick={() => handleExpand([])}
              />
            }
            defaultExpandIcon={
              <FaAngleRight
                color={theme.palette.primary.main}
                onClick={() => handleExpand(["PDC"])}
              />
            }
            sx={{ mb: "18px !important" }}
            expanded={expanded}
          >
            <TreeItem
              nodeId="PDC"
              label="PDC"
              className="echart-option-tree-item"
            >
              <RadioGroup
                aria-labelledby="eChart-radio-buttons"
                name="eChart-radio-buttons-group"
                value={selectedEchart || ""}
                onChange={handleeChartValueChange}
              >
                {pdcOptions.map((option, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      label={option.label}
                      value={option.value}
                      disabled={!selctedAirport}
                      control={<Radio />}
                    />
                  );
                })}
              </RadioGroup>
              <Box className="box-footer">
                <Box className="box-footer-label">
                  <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                    Label
                  </Typography>
                  <AntSwitch
                    disabled={!selectedEchart}
                    checked={isShowLabel}
                    onChange={handleLabelToggle}
                  />
                </Box>
                <Box className="box-footer-tabel">
                  <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                    Table
                  </Typography>
                  <AntSwitch
                    disabled={
                      !selectedEchart || selectedEchart.startsWith("ATOCA")
                    }
                    checked={isShowTable}
                    onChange={handleTableToggle}
                  />
                </Box>
              </Box>
            </TreeItem>
          </TreeView>
        )}
        {eChartOptions.length > 0 &&
          atocaData &&
          atocaData.Runway_list.length > 0 && (
            <TreeView
              aria-label="ATOCA-features"
              className="echart-option-tree"
              sx={{ mb: "12px !important" }}
              defaultCollapseIcon={
                <FaAngleDown
                  color={theme.palette.primary.main}
                  onClick={() => handleExpand([""])}
                />
              }
              defaultExpandIcon={
                <FaAngleRight
                  color={theme.palette.primary.main}
                  onClick={() => handleExpand(["ATOCA"])}
                />
              }
              expanded={expanded}
            >
              <TreeItem
                nodeId="ATOCA"
                label="ATOCA"
                className="echart-option-tree-item"
              >
                <RadioGroup
                  aria-labelledby="eChart-radio-buttons"
                  name="eChart-radio-buttons-group"
                  value={selectedEchart || ""}
                  onChange={handleeChartValueChange}
                >
                  {atocaData.Runway_list.map((rwy, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        label={`ATOCA-RWY-${rwy}`}
                        value={`ATOCA-RWY-${rwy}`}
                        disabled={!selctedAirport}
                        control={<Radio />}
                      />
                    );
                  })}
                </RadioGroup>
                <Box className="box-footer">
                  <Box className="box-footer-label">
                    <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                      Label
                    </Typography>
                    <AntSwitch
                      disabled={!selectedEchart}
                      checked={isShowLabel}
                      onChange={handleLabelToggle}
                    />
                  </Box>
                  <Box className="box-footer-tabel">
                    <Typography variant="span" sx={{ pt: 1, pb: 1 }}>
                      Terrain
                    </Typography>
                    <AntSwitch
                      disabled={
                        !selectedEchart ||
                        !selectedEchart.startsWith("ATOCA") ||
                        (atocaData &&
                          atocaData.ProfileView.length > 0 &&
                          atocaData.ProfileView.maxterrainprofile_list
                            .length === 0)
                      }
                      checked={isShowMaxTerrain}
                      onChange={handleShowMaxTerrainToggle}
                    />
                  </Box>
                </Box>
              </TreeItem>
            </TreeView>
          )}
      </div>
      {alleChartExportUrls && alleChartExportUrls.length > 0 && (
        <EChartExportButton
          label="Export Chart"
          selectedEchart={selectedEchart}
          isLoading={isLoadingAlleChartExportUrl}
          isDisabled={
            !Boolean(accessToken) || !currentUser?.user_access?.export
          }
          handleExport={handleChartExport}
        />
      )}
    </div>
  );
};

export default ElecronicChartSideBar;
