import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderOLSOTransitionalSurface({
  OLSOuterTransitionalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSTransitionalSurfaceList = chain(
    OLSOuterTransitionalSurfaceList
  )
    .filter((tslObj) => {
      const name = `${tslObj.category}_${tslObj.rwy}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSTransitionalSurfaceList.map((tslObj) => {
        const tslCoordinates = tslObj.coordinates.coordinates;
        const name = `${tslObj.category}_${tslObj.rwy}`;
        const tslCoordinateMap = flatten(tslCoordinates).map((tslCoords) => {
          return tslCoords.map((tsl) => {
            return [tsl[1], tsl[0]];
          });
        });

        return tslCoordinateMap.map((tslPolygonPath, i) => (
          <Polygon
            key={i}
            pane="tilePane"
            positions={tslPolygonPath}
            pathOptions={{
              fillColor: olsColor[name],
              color:
                selectedOverE?.rwy === tslObj.rwy
                  ? areaHighLightColor
                  : olsColor[name],
              weight: selectedOverE?.rwy === tslObj.rwy ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, tslObj),
              mouseout: () => handlePolygonOut(),
            }}
          />
        ));
      })}
    </Fragment>
  );
}
