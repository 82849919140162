import React, { Fragment } from "react";
import { BASE_URL } from "@constants/urls";

export default function DemLegends({ etodDems }) {
  const legendUrl = `${BASE_URL}/etod/geoserver/layer-legend?layer_name=${etodDems[0].dem_gs_layer_name}`;

  return (
    <Fragment>
      <img
        src={legendUrl}
        srcSet={legendUrl}
        alt="Legend"
        loading="lazy"
        style={{ width: 60 }}
      />
    </Fragment>
  );
}
