import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { TbDots } from "react-icons/tb";
import { TreeView, TreeItem } from "@mui/lab";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import {
  Checkbox,
  IconButton,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { DownloadIcon } from "@assets/svg/DownloadIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import { getStorageValue } from "@utils/localStorage";
import { theme } from "@styles/theme";
import AccordionSummary from "./AccordionSummary";
import AccordionDetails from "./AccordionDetails";

const StyledAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(0.8),
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export default function ETODAccordion({
  isExpand,
  isExpandIconEmpty,
  isLoadingExport,
  isDisabled,
  isWithNoColor,
  title,
  optionTrees,
  detailStyle,
  selectedOptions,
  isDisabledOption,
  handleExpandAccordain,
  isAllChecked,
  handleSelectAll,
  handleExport,
  handleParentCheckbox,
  handleCheckbox,
}) {
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const handleClickTreeLabel = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledAccordion expanded={isExpand} disabled={isDisabled}>
      <AccordionSummary
        expandIcon={
          isExpandIconEmpty ? null : (
            <FaAngleRight onClick={() => handleExpandAccordain()} />
          )
        }
        aria-controls="areas-content"
        id="areas-header"
        sx={{ backgroundColor: theme.palette.white.main }}
      >
        <Checkbox checked={isAllChecked} onChange={handleSelectAll} />
        <div className="ols-summary">
          <Typography>{title}</Typography>
          <IconButton
            onClick={() => handleExport()}
            aria-label="download"
            disabled={
              isLoadingExport ||
              !currentUser?.user_access?.export ||
              !accessToken
            }
          >
            {isLoadingExport ? (
              <TbDots size={12} />
            ) : (
              <DownloadIcon
                disabled={!currentUser?.user_access?.export || !accessToken}
              />
            )}
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails
        className="ols-accordian-detail"
        sx={{ ...detailStyle }}
      >
        <Fragment>
          <TreeView
            aria-label="area-features"
            defaultCollapseIcon={<FaAngleDown />}
            defaultExpandIcon={<FaAngleRight />}
          >
            {optionTrees.map((tree) => {
              return (
                <TreeItem
                  key={tree.id.toString()}
                  nodeId={tree.id.toString()}
                  label={
                    <FormControlLabel
                      label={tree.name}
                      disabled={isDisabledOption}
                      onClick={(e) => handleClickTreeLabel(e)}
                      control={
                        <Checkbox
                          checked={selectedOptions.includes(
                            isWithNoColor
                              ? tree.value
                              : `${tree.value}__${tree.color}`
                          )}
                          onChange={(event) => {
                            handleParentCheckbox(event, tree);
                          }}
                          sx={{
                            color: tree.color,
                            "&.Mui-checked": {
                              color: tree.color,
                            },
                          }}
                        />
                      }
                    />
                  }
                >
                  {tree.sub &&
                    tree.sub.map((layer1) => (
                      <TreeItem
                        key={layer1.id.toString()}
                        nodeId={layer1.id.toString()}
                        label={
                          <FormControlLabel
                            label={layer1.name}
                            disabled={isDisabledOption}
                            control={
                              <Checkbox
                                checked={selectedOptions.includes(
                                  isWithNoColor
                                    ? layer1.value
                                    : `${layer1.value}__${layer1.color}`
                                )}
                                onChange={() => {
                                  handleCheckbox(layer1);
                                }}
                                sx={{
                                  color: layer1.color,
                                  "&.Mui-checked": {
                                    color: layer1.color,
                                  },
                                }}
                              />
                            }
                          />
                        }
                      />
                    ))}
                </TreeItem>
              );
            })}
          </TreeView>
        </Fragment>
      </AccordionDetails>
    </StyledAccordion>
  );
}
