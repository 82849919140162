import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { includes } from "lodash";
import AixmIcon from "@assets/svg/AixmIcon";
import AmdbIcon from "@assets/svg/AmdbIcon";
import DataManagementIcon from "@assets/svg/DataManagementIcon";
import EchartIcon from "@assets/svg/EchartIcon";
import EnRouteIcon from "@assets/svg/EnRouteIcon";
import ETodIcon from "@assets/svg/ETodIcon";
import ObstacleIcon from "@assets/svg/ObstacleIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import MainHeader from "@components/MainHeader";
import MenuItem from "@components/core/MenuItem";
import { getStorageValue } from "@utils/localStorage";

export default function Home() {
  const navigate = useNavigate();
  const accesstoken = getStorageValue(ACCESS_TOKEN);
  const currentUser = useSelector((state) => state.userSession.user);

  return (
    <Box>
      <MainHeader />
      <Box className="main-details">
        <Grid container className="main-grid">
          {currentUser && includes(currentUser.module_access, "aixm") && (
            <MenuItem
              icon={<AixmIcon height={66} />}
              title="AERONAUTICAL INFORMATION EXCHANGE MODEL (AIXM)"
              description="AIXM aims to achieve global interoperability through database
            structure compliance by ICAO"
              handleOnClick={() => navigate(routeNames.Aixm)}
            />
          )}
          {currentUser && includes(currentUser.module_access, "airspace") && (
            <MenuItem
              icon={<EnRouteIcon height={66} />}
              title="EN-ROUTE"
              description="En-Route provides a spatial vector representation of all airspace
              related data from the eAIP."
              handleOnClick={() => navigate(routeNames.Airspace)}
            />
          )}
          {currentUser &&
            includes(currentUser.module_access, "data-management") && (
              <MenuItem
                icon={<DataManagementIcon height={66} />}
                title="DATA MANAGEMENT"
                description="A multi user platform for uploading and verification of ground
            survey data with integrated redundancy checks and automatic
              spatial and temporal comparison."
                handleOnClick={() =>
                  !accesstoken
                    ? toast.error("No Access! Login first.")
                    : navigate(routeNames.UserDashboard)
                }
                isPrivate={!accesstoken ? true : false}
              />
            )}
          {currentUser &&
            includes(currentUser.module_access, "electronic-chart") && (
              <MenuItem
                icon={<EchartIcon height={66} />}
                title="eCHART"
                description="eCharting is a comprehensive geo- referenced cartographic
            representation of the airport which includes obstacle analysis and
              major aerodrome features."
                handleOnClick={() => navigate(routeNames.eChart)}
              />
            )}
          {currentUser &&
            includes(currentUser.module_access, "etod") && (
              <MenuItem
                icon={<ETodIcon height={66} />}
                title="ELECTRONIC TERRAIN AND OBSTACLE DATA (eTOD)"
                description="eTOD provides critical insights of terrain and ground obstacles
            through digital representation for airborne and ground
            applications."
                handleOnClick={() => navigate(routeNames.Etod)}
              />
            )}
          {currentUser &&
            includes(currentUser.module_access, "obstacle-analysis") && (
              <MenuItem
                icon={<ObstacleIcon height={66} />}
                title="OBSTACLE ANALYSIS"
                description="Identifies and evaluates obstacles by determining minimum obstacle
            clearance requirements in the airport vicinity to ensure safe
            aircraft operations."
                handleOnClick={() => navigate(routeNames.ObstacleAnalysis)}
              />
            )}
          {currentUser && includes(currentUser.module_access, "amdb") && (
            <MenuItem
              icon={<AmdbIcon height={66} />}
              title="Airport Mapping Database (AMDB)"
              description="AMDB is a spatial representation of all the major features in an
              aerodrome through geo-referenced vector data."
              handleOnClick={() => navigate(routeNames.Amdb)}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
