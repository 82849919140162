import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { roles } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import Home from "@containers/Home";
import Aixm from "@containers/Aixm";
import Amdb from "@containers/Amdb";
import Etod from "@containers/Etod";
import EtodAirportDetail from "@containers/EtodAirportDetail";
import Airspace from "@containers/Airspace";
import ElecronicChart from "@containers/ElecronicChart";
import Login from "@containers/Login";
import CRCCheck from "@containers/CRCCheck";
import ObstacleAnalysis from "@containers/ObstacleAnalysis";
import AirportReview from "@containers/AirportReview";
import UserDashboard from "@containers/dataManagement/UserDashboard";
import SurveyorReportList from "@containers/dataManagement/SurveyorReportList";
import ReviewSurveyData from "@containers/dataManagement/ReviewSurveyData";
import ManageUsers from "@containers/dataManagement/ManageUsers";
import NewPasswordRequest from "@containers/dataManagement/NewPasswordRequest";
import AirportReviewList from "@containers/dataManagement/AirportReviewList";
import AddAirportData from "@containers/dataManagement/AddAirportData";
import AD2DataEntry from "@containers/dataManagement/AD2DataEntry";
import SurveyorRevalidation from "@containers/dataManagement/SurveyorRevalidation";
import AdminReviewPage from "@containers/dataManagement/AdminReviewPage";
import CreateNewAirport from "@containers/dataManagement/CreateNewAirport";
import EtodAirportGenerateSurface from "@containers/EtodAirportGenerateSurface";
import Authentication from "@containers/Authentication";
import NotFound from "@components/core/NotFound";
import ForgetPassword from "@containers/ForgetPassword";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const WebRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path={routeNames.Login} element={<Login />} />
        <Route
          exact
          path={routeNames.Forgotpass}
          element={<ForgetPassword />}
        />
        <Route
          exact
          path={routeNames.Authentication}
          element={
            <PublicRoute>
              <Authentication />
            </PublicRoute>
          }
        />
        <Route exact path={routeNames.Home} element={<Home />} />
        <Route exact path={routeNames.CRCCheck} element={<CRCCheck />} />
        <Route
          exact
          path={routeNames.Aixm}
          element={
            <PrivateRoute access="aixm">
              <Aixm />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.Amdb}
          element={
            <PrivateRoute access="amdb">
              <Amdb />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.Etod}
          element={
            <PrivateRoute access="etod">
              <Etod />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.Airspace}
          element={
            <PrivateRoute access="airspace">
              <Airspace />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.eChart}
          element={
            <PrivateRoute access="electronic-chart">
              <ElecronicChart />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.ObstacleAnalysis}
          element={
            <PrivateRoute access="obstacle-analysis">
              <ObstacleAnalysis />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.UserDashboard}
          element={
            <PrivateRoute access="data-management">
              <UserDashboard />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.NewPassReq}
          element={
            <PrivateRoute roles={[roles.admin]}>
              <NewPasswordRequest />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.ManageUsers}
          element={
            <PrivateRoute roles={[roles.admin]}>
              <ManageUsers />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.AdminReview}
          element={
            <PrivateRoute roles={[roles.admin]}>
              <AdminReviewPage />
            </PrivateRoute>
          }
        >
          <Route
            exact
            path={routeNames.SurveyorReportList}
            element={
              <PrivateRoute roles={[roles.admin]}>
                <SurveyorReportList />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path={routeNames.AirportReviewList}
            element={
              <PrivateRoute roles={[roles.admin]}>
                <AirportReviewList />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path={routeNames.NewAirport}
          element={
            <PrivateRoute roles={[roles.admin]}>
              <CreateNewAirport />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.ReviewSurveyData}
          element={
            <PrivateRoute roles={[roles.admin]}>
              <ReviewSurveyData />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.SurveyorRevalidation}
          element={
            <PrivateRoute roles={[roles.surveyor]}>
              <SurveyorRevalidation />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.AD2DataEntry}
          element={
            <PrivateRoute access="data-management">
              <AD2DataEntry />
            </PrivateRoute>
          }
        >
          <Route
            exact
            path={routeNames.AddAirportData}
            element={
              <PrivateRoute access="data-management">
                <AddAirportData />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path={routeNames.AirportReview}
          element={
            <PrivateRoute access="data-management">
              <AirportReview />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.EtodAirportDetail}
          element={
            <PrivateRoute>
              <EtodAirportDetail />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={routeNames.EtodAirportGenerateSurface}
          element={
            <PrivateRoute>
              <EtodAirportGenerateSurface />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
