import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCellV2 } from "@components/tabel-component/StyledTableCell";
import StyledTableRow from "@components/tabel-component/StyledTableRow";
import { selecteChartTableData } from "@store/electronicChart";
import { styles } from "@styles/aixmStyles";

export default function PDCChartTable({ selectedEchart, selectedMapLabel }) {
  const data = useSelector((state) =>
    selecteChartTableData(state, selectedEchart)
  );

  useEffect(() => {
    if (!selectedMapLabel) return;
    const rowElm = document.getElementById(
      `table-row-${selectedMapLabel.text}`
    );

    if (rowElm) {
      rowElm.scrollIntoView(true);
    }
  }, [selectedMapLabel]);

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellV2>Stand Num</StyledTableCellV2>
            <StyledTableCellV2>Latitude</StyledTableCellV2>
            <StyledTableCellV2>Longitude</StyledTableCellV2>
            <StyledTableCellV2>Elev</StyledTableCellV2>
            <StyledTableCellV2>Suitability</StyledTableCellV2>
            <StyledTableCellV2>PCN</StyledTableCellV2>
            <StyledTableCellV2>Status</StyledTableCellV2>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {orderBy(data, ["stand_num"], ["asc"]).map((row) => (
            <StyledTableRow
              hover
              selected={selectedMapLabel?.text === `PB${row.stand_num}`}
              key={row.id}
              id={`table-row-PB${row.stand_num}`}
              sx={styles.tableRowLastBorderNone}
            >
              <StyledTableCellV2>{row.stand_num}</StyledTableCellV2>
              <StyledTableCellV2>{row.latitude}</StyledTableCellV2>
              <StyledTableCellV2>{row.longitude}</StyledTableCellV2>
              <StyledTableCellV2>{row.elev}</StyledTableCellV2>
              <StyledTableCellV2>{row.suitability}</StyledTableCellV2>
              <StyledTableCellV2>{row.pcn}</StyledTableCellV2>
              <StyledTableCellV2>{row.status}</StyledTableCellV2>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
