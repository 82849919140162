import React from "react";
import { drop, orderBy } from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { surveyCategory } from "@constants/surveyorData";

export default function PreviewDataTable({ data, selctedCategory }) {
  const isShowBaseElev = selctedCategory?.value === surveyCategory.navaids;
  let indexKey = "";

  if (selctedCategory?.value === surveyCategory.ltp_fpap_garp) {
    indexKey = "LTP, FPAP & GARP";
  }
  if (selctedCategory?.value === surveyCategory.ihp) {
    indexKey = "INTERMEDIATE HOLDING POSITION";
  }
  if (selctedCategory?.value === surveyCategory.pacs_sacs) {
    indexKey = "PACS & SACS";
  }
  if (selctedCategory?.value === surveyCategory.parking_bay) {
    indexKey = "PARKING BAY DETAILS";
  }
  if (selctedCategory?.value === surveyCategory.rwy_points) {
    indexKey = "RWY POINTS";
  }
  if (selctedCategory?.value === surveyCategory.rwy_twy_intersection) {
    indexKey = "TWY POINTS";
  }
  if (selctedCategory?.value === surveyCategory.navaids) {
    indexKey = "NAVIGATIONAL AIDS";
  }

  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>UID</StyledTableCell>
            <StyledTableCell>POINT NAME</StyledTableCell>
            <StyledTableCell>LATITUDE</StyledTableCell>
            <StyledTableCell>LONGITUDE</StyledTableCell>
            <StyledTableCell>TOP ELEV. IN m. (EGM 08)</StyledTableCell>
            {isShowBaseElev && (
              <StyledTableCell>BASE ELEV. IN m. (EGM 08)</StyledTableCell>
            )}
            {!isShowBaseElev && (
              <StyledTableCell>ELLIPSOIDAL ELEV. IN m.</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(drop(data, 1), [indexKey], ["asc"]).map((row) => {
            return (
              <TableRow key={row[indexKey]}>
                <StyledTableCell>{row[indexKey]}</StyledTableCell>
                <StyledTableCell>{row["__EMPTY"]}</StyledTableCell>
                <StyledTableCell>{row["__EMPTY_1"]}</StyledTableCell>
                <StyledTableCell>{row["__EMPTY_2"]}</StyledTableCell>
                <StyledTableCell>{row["__EMPTY_3"]}</StyledTableCell>
                {isShowBaseElev && (
                  <StyledTableCell>{row["__EMPTY_4"] || "-"}</StyledTableCell>
                )}
                {!isShowBaseElev && (
                  <StyledTableCell>{row["__EMPTY_4"]}</StyledTableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
