import { theme } from "./theme";

export const styles = {
  categoryCard: {
    width: "100%",
    padding: "7px 4px 7px 7px",
    textAlign: "start",
    justifyContent: "flex-start",
    alignItems: "baseline",
    border: "0.5px solid #696B72",
    borderRadius: "4px",
    mb: 1,
  },
  filterDate: {
    backgroundColor: theme.palette.white.main,
    pt: 1,
    pb: 1,
    pr: 2,
  },
  filterBtn: {
    widh: 100,
    mr: 1,
    mt: 2.8,
  },
  aixmBanner: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  airportTable: {
    height: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
    ".MuiPaper-elevation": {
      mb: 0,
    },
  },
  AD1Label: {
    backgroundColor: theme.palette.secondary.main,
    height: "36px",
    fontSize: 14,
    color: theme.palette.white.main,
    display: "flex",
    alignItems: "center",
    pl: 1.5,
  },
  AD1Data: {
    ml: 1.5,
    pt: 1.5,
    mt: 1,
    whiteSpace: "pre-line",
  },
  noData: {
    m: 1,
    textAlign: "center",
  },
  GENLabel: {
    backgroundColor: theme.palette.secondary.main,
    height: "36px",
    fontSize: 14,
    color: theme.palette.white.main,
    display: "flex",
    alignItems: "center",
    pl: 1.5,
  },
  AmdtTableHead: {
    backgroundColor: theme.palette.secondary.light,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  AD2Category: {
    mt: 1,
    height: "calc(100vh - 437px)",
    overflow: "auto",
    paddingRight: "2px",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
  },
  exportBtn: {
    width: "100%",
    mt: 1,
  },
  tableRowLastBorderNone: {
    borderRadius: 0,
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  administrativeAuthority: {
    borderBottom: "0.5px solid #828282",
    fontSize: "14px",
    p: "6px 0px",
  },
  administrativePhone: {
    display: "flex",
    justifyContent: "flex-start",
    p: "3px 0px",
    borderBottom: "0.5px solid #828282",
  },
  administrativeEmail: {
    display: "flex",
    justifyContent: "flex-start",
    p: "3px 0px",
  },
  detailLoc: {
    fontSize: "12px",
    textAlign: "left",
    p: "0px !important",
  },
  ilsType: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white.main,
    fontWeight: 500,
  },
  RWYTable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  exportDataModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 559,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: "24px 30px",
  },
  exportDataModalTitle: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    mb: 0.5,
  },
  exportDataModalDesc: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "19px",
  },
  downloadData: {
    color: theme.palette.grey.light,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  exportDataModalExportBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  enrouteExportTable: {
    borderBottom: "0.5px solid #C7C7CC",
    p: 0,
    width: "33px",
    span: {
      pl: 0,
    },
  },
  airportExportBtn: {
    width: "240px",
    backgroundColor: "#f3f3f3",
    color: "hsl(0, 0%, 50%);",
  },
};
