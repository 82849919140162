import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function Taxiway({ selctedAirport, activeCategory, taxiway }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={11}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Width</StyledTableCell>
              <StyledTableCell>Width Shoulder</StyledTableCell>
              <StyledTableCell>Length</StyledTableCell>
              <StyledTableCell>Composition</StyledTableCell>
              <StyledTableCell>Class PCN</StyledTableCell>
              <StyledTableCell>Pavement Type PCN</StyledTableCell>
              <StyledTableCell>Pavement Subgrade PCN</StyledTableCell>
              <StyledTableCell>Max Tyre Pressure PCN</StyledTableCell>
              <StyledTableCell>Evaluation Method PCN</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(taxiway) ? (
              taxiway.map((txy, index) => (
                <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                  <StyledTableCell>
                    {txy.name ? txy.name : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "50px" }}>
                    {txy.type ? txy.type : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.width ? txy.width : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.widthShoulder ? txy.widthShoulder : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.length ? txy.length : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.composition ? txy.composition : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.classPCN ? txy.classPCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.pavementTypePCN ? txy.pavementTypePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.pavementSubgradePCN ? txy.pavementSubgradePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.maxTyrePressurePCN ? txy.maxTyrePressurePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {txy.evaluationMethodPCN ? txy.evaluationMethodPCN : "NIL"}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  Refer Aircraft Parking / Docking Charts
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Taxiway;
