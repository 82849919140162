import React, { useRef } from "react";
import { Polyline, Popup, FeatureGroup } from "react-leaflet";
import Box from "@mui/material/Box";
import PopupBox from "@components/map/PopupBox";

const RenderTerrainProfileSlop = ({ data }) => {
  const shapeRef = useRef(null);

  return (
    <FeatureGroup ref={shapeRef}>
      {data.map((geometry, i) => {
        const coordinates = geometry.geometry_json.coordinates;
        const coordinateMap = coordinates.map((c) => {
          return c.map((c1) => {
            return c1.map((c2) => {
              return {
                lng: c2[0],
                lat: c2[1],
              };
            });
          });
        });

        return (
          <Polyline
            key={`${geometry.id}_${i}`}
            positions={coordinateMap}
            pathOptions={{
              color: "red",
              weight: 3,
              opacity: 1,
            }}
          >
            <Popup>
              <PopupBox>
                <Box>Terrain Profile</Box>
                <Box></Box>
                <Box>Rwy Direction:</Box>
                <Box>{geometry.rwy_direction}</Box>
              </PopupBox>
            </Popup>
          </Polyline>
        );
      })}
    </FeatureGroup>
  );
};

export default RenderTerrainProfileSlop;
