import React from "react";
import { useSelector } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import { PrimaryButtonV2 } from "./button";

export default function AixmAirportExportButton({
  label,
  items = [],
  handleExportXML,
  isExportXMLLoading,
  isExportXMLDisabled,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.userSession.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnExport = (item) => {
    handleClose();
    handleExportXML(item.value);
  };

  return (
    <>
      <PrimaryButtonV2
        label={label}
        id="aixm-airportexport-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        isLoading={isExportXMLLoading}
        disabled={
          isExportXMLDisabled || isExportXMLLoading || !user?.user_access?.export
        }
        onClick={handleClick}
      />
      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "aixm-airportexport-button",
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.id} onClick={() => handleOnExport(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
