import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
  Box,
  InputLabel,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SatsureSkies from "@assets/svg/SatsureSkies";
import SatsureImage from "@assets/svg/SatsureIcon";
import { routeNames } from "@constants/pageRoutes.constants";
import {
  loginValidationSchema,
  requestNewPasswordValidationSchema,
} from "@constants/surveyorData";
import { LightButton, PrimaryButton } from "@components/button";
import {
  userLogin,
  getRSA,
  requestNewPassword,
} from "@store/userSession/actions";
import { styles } from "@styles/loginStyles";
import { rsaEnc } from "@utils/decodeEncodeData";

function Authentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { rsaKey, isLoginLoading } = useSelector((state) => state.userSession);

  useEffect(() => {
    if (!rsaKey) {
      dispatch(getRSA());
    }
  }, []); // eslint-disable-line

  const loginForm = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      dispatch(
        userLogin(
          {
            username: rsaEnc(values?.username, rsaKey),
            password: rsaEnc(values?.password, rsaKey),
          },
          () => navigate(routeNames.Home, { replace: true })
        )
      );
    },
  });

  const newPasswordRequestForm = useFormik({
    initialValues: {
      user_email: "",
    },
    validationSchema: requestNewPasswordValidationSchema,
    onSubmit: (values) => {
      dispatch(requestNewPassword(values, handleCloseRequestPasswordDialog));
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseRequestPasswordDialog = () => {
    newPasswordRequestForm.resetForm();
    newPasswordRequestForm.setSubmitting(false);
  };

  return (
    <Box className="auth-form-box">
      <Box sx={{ position: "absolute", top: "96px", left: "90px" }}>
        <SatsureSkies />
      </Box>
      <Box className="form-main">
        <Box className="welcome-label">
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            Welcome to
          </Typography>
          <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
            SatSure iNetra
          </Typography>
        </Box>
        <Box>
          <form onSubmit={(e) => e.preventDefault()} className="auth-form">
            <InputLabel htmlFor="username" required className="field-label">
              Email
            </InputLabel>
            <TextField
              sx={{ width: "100%" }}
              className="text-field"
              autoComplete="current-email"
              name="username"
              value={loginForm.values.username}
              onChange={loginForm.handleChange}
              placeholder="Enter your mail address"
              variant="standard"
              helperText={
                loginForm.touched.username && loginForm.errors.username
              }
              error={
                loginForm.touched.username && Boolean(loginForm.errors.username)
              }
            />
            <InputLabel htmlFor="password" required className="field-label">
              Password
            </InputLabel>
            <TextField
              sx={{ width: "100%" }}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              autoComplete="current-password"
              value={loginForm.values.password}
              onChange={loginForm.handleChange}
              variant="standard"
              error={
                loginForm.touched.password && Boolean(loginForm.errors.password)
              }
              helperText={
                loginForm.touched.password && loginForm.errors.password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LightButton
              label="Request Password?"
              className="forget-pass-btn"
              sx={styles.forgetPassBtn}
              onClick={() => navigate(routeNames.Forgotpass)}
            />
            <PrimaryButton
              label="Log In"
              type="submit"
              onClick={() => loginForm.handleSubmit()}
              className="login-btn"
              isLoading={isLoginLoading}
              disabled={isLoginLoading}
            />
          </form>
          {/* <OutlinedButton
            label="Continue as Guest User"
            type="submit"
            className="guest-btn"
            onClick={() => navigate(routeNames.Home, { replace: true })}
          /> */}
        </Box>
        <Box className="footer-div">
          <Typography className="powered">Powered By</Typography>
          <Box className="footer-img">
            <SatsureImage />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Authentication;
