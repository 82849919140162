import React from "react";

const AmdbIcon = (props) => {
  return (
    <svg
      width={props.width || "76"}
      height={props.height || "58"}
      viewBox="0 0 76 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72 54H4C2.34315 54 1 52.6569 1 51V5V4C1 2.34315 2.34314 1 4 1H72C73.6569 1 75 2.34315 75 4V51C75 52.6569 73.6569 54 72 54Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6H75"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="64" cy="4" r="1" fill="#333333" />
      <circle cx="67" cy="4" r="1" fill="#333333" />
      <circle cx="70" cy="4" r="1" fill="#333333" />
      <path d="M17 6V54" stroke="#333333" />
      <mask id="path-7-inside-1_3035_38616" fill="white">
        <rect x="3" y="9" width="12" height="3" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="9"
        width="12"
        height="3"
        rx="0.5"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-7-inside-1_3035_38616)"
      />
      <mask id="path-8-inside-2_3035_38616" fill="white">
        <rect x="3" y="14" width="12" height="3" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="14"
        width="12"
        height="3"
        rx="0.5"
        stroke="#333333"
        strokeWidth="2"
        mask="url(#path-8-inside-2_3035_38616)"
      />
      <path
        d="M29.0994 16.4922L59.4029 46.5771"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
      <path
        d="M45.1825 28.2296L48.0803 27.4531"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.5031 30.964L51.4009 30.1875"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4704 34.3077L54.3682 33.5312"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3352 40.3828V36.8828"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="29.135"
        y="12.1524"
        width="48.3142"
        height="6"
        rx="0.5"
        transform="rotate(45 29.135 12.1524)"
        stroke="#333333"
        strokeLinejoin="round"
      />
      <path
        d="M34.9709 26.8255L32.0731 27.6019L27.1395 22.7123L27.9413 19.9092"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9275 18.8073L38.8253 18.0309L43.759 22.9206L42.9571 25.7236"
        stroke="#333333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.7081 24L47.9998 26.7083L58.2914 37L60.9998 34.2917L50.7081 24Z"
        stroke="#333333"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AmdbIcon;
