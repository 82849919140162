import { createSelector } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import moment from "moment";
import { survey } from "./slice";

export const {
  setLoadingSurveyAirportLoadingRequest,
  setTemplateDownloadRequest,
  setUplodSurveyFileRequest,
  setAllSurveyReportRequest,
  setSurveyReportDetailRequest,
  setSurveyVerificationRequest,
  setAllSurveyAirport,
  setUploadFileData,
  setAllSurveyReports,
  setSurveyReportDetail,
  setSelectedSurveyReport,
  setReviewFeaturesdataLoading,
  setReviewBackFeaturesData,
  setReviewBackSurveyDataLoading,
  setReviewBackSurveyData,
  setGetAllSurveyorDataLoading,
  setAllSurveyorList,
  setPostSurveyorRegistrationLoading,
  setEditSurveyorRegistrationLoading,
  setDisableSurveyorLoading,
  setEnableSurveyorLoading,
  setResetingMasterPasswordLoading,
  setLoadingGetallReqPassword,
  setAllReqPassword,
  setLoadingPostEditReviewData,
  setLoadingCompareData,
  setCompareData,
  setSurveyorAirportLoading,
  setSurveyorAirportData,
  setExportReviewReportLoading,
} = survey.actions;

export default survey.reducer;

const selectSearchReviewId = (state, reviewId) => reviewId;
const selectAllReviewBackSurveyData = (state) =>
  state.survey.reviewBackSurveyData;
const selectAllSurveyorData = (state) => state.survey.allSurveyorList;
const selectAllReqPasswordData = (state) => state.survey.allReqPassword;

export const selectAllSurveyReports = (state) => {
  const allSurveyReports = state.survey.allSurveyReports;

  if (allSurveyReports && allSurveyReports.length > 0) {
    const formatedReports = allSurveyReports.map((report) => {
      return {
        ...report,
        created_datetime: moment
          .utc(report.created_datetime)
          .format("MM/DD/YYYY, h:mm:ss a"),
      };
    });

    return orderBy(formatedReports, ["created_datetime"], ["desc"]);
  }

  return [];
};

export const selectReviewBackSurveyData = createSelector(
  [selectAllReviewBackSurveyData, selectSearchReviewId],
  (allReviewBackSurveyData, reviewId) => {
    if (reviewId) {
      return allReviewBackSurveyData && allReviewBackSurveyData[reviewId]
        ? orderBy(allReviewBackSurveyData[reviewId], ["id"], ["asc"])
        : [];
    }

    return allReviewBackSurveyData;
  }
);

const selectUserType = (state, userType) => userType;
export const selectAllSurveyorList = createSelector(
  [selectAllSurveyorData, selectUserType],
  (surveyors, userType) => {
    if (surveyors && surveyors.length > 0) {
      const filteredUsers = surveyors.filter((user) => user.role === userType);
      return orderBy(filteredUsers, ["emp_id"], ["asc"]);
    }

    return [];
  }
);

export const selectAllReqPasswordList = createSelector(
  [selectAllReqPasswordData],
  (allPasswordRequest) => {
    if (allPasswordRequest && allPasswordRequest.length > 0) {
      return orderBy(allPasswordRequest, ["emp_id"], ["asc"]);
    }

    return [];
  }
);


