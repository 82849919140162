import React from "react";
import { capitalize } from "lodash";
import { Button, Typography } from "@mui/material";
import { theme } from "@styles/theme";
import { styles } from "@styles/aixmStyles";

export default function CategoryCard({
  categoryData,
  handleActiveCategory,
  activeCategory,
  selectedCategories = [],
  ...props
}) {
  return (
    <Button
      variant="outlined"
      sx={{
        ...styles.categoryCard,
        backgroundColor:
          (activeCategory && activeCategory.value === categoryData.value) ||
          selectedCategories.includes(categoryData)
            ? theme.palette.primary.main
            : theme.palette.white.main,
        "&:hover": {
          color:
            (activeCategory && activeCategory.value === categoryData.value) ||
            selectedCategories.includes(categoryData)
              ? theme.palette.white.main
              : "#696B72",
          backgroundColor:
            (activeCategory && activeCategory.value === categoryData.value) ||
            selectedCategories.includes(categoryData)
              ? theme.palette.primary.main
              : theme.palette.white.main,
        },
      }}
      disabled={activeCategory && activeCategory.value === categoryData.value}
      onClick={() => handleActiveCategory(categoryData)}
    >
      <Typography
        variant="span"
        sx={{
          color:
            (activeCategory && activeCategory.value === categoryData.value) ||
            selectedCategories.includes(categoryData)
              ? theme.palette.white.main
              : "#696B72",
          fontSize: 12,
          fontWeight: 500,
          minWidth: 55,
          textTransform: "unset",
        }}
      >
        {categoryData.code}
      </Typography>
      <Typography
        variant="div"
        sx={{
          color:
            (activeCategory && activeCategory.value === categoryData.value) ||
            selectedCategories.includes(categoryData)
              ? theme.palette.white.main
              : "#696B72",
          fontSize: 12,
          fontWeight: 500,
          textTransform: "capitalize",
        }}
      >
        {capitalize(categoryData.label)}
      </Typography>
    </Button>
  );
}
