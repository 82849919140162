import { createSelector } from "@reduxjs/toolkit";
import { chain, orderBy, uniqBy, remove, find } from "lodash";
import {
  OLSOptionList,
  ofzOptionLabel,
  areaOptionList,
  demOptionList,
} from "@constants/etodData";
import { colorMap, darkColor } from "@constants/colors";
import { etod } from "./slice";

export const {
  setEtodAirportLoadingRequest,
  setEtodArea1LoadingRequest,
  setAllEtodAirport,
  setArea1Details,
  setLoadingArea1Obstacles,
  setArea1Obstacles,
  setLoadingExportObstacle,
  setLoadingEtodLayers,
  setEtodLayers,
  setLoadingEtodAixmAirports,
  setEtodAixmAirports,
  setLoadingGenerateEtodAirport,
  setLoadingEtodDems,
  setEtodDems,
  setUplodeeTodDemFileRequest,
  setLoadingExportAreaOls,
  setVisualiseObstacles,
  setAnalysisObstacles,
  setLoadingAnalysisObstacles,
  setLoadingProcessObstacleAnalysis,
  setLoadingUploadObstacleAnalysis,
  setLoadingAirportCreatelayers,
  setLoadingAirportlayer,
  setLoadingExportAirportLayers,
  setLoadingDeleteAirportlayer,
  setCreatelayerAirportData,
} = etod.actions;

export default etod.reducer;

export const selectEtodAixmAirports = (state) => {
  const etodAixmAirports = state.etod.etodAixmAirports;

  if (etodAixmAirports && etodAixmAirports.length > 0) {
    return chain(etodAixmAirports).orderBy(["name"], ["asc"]).value();
  }

  return [];
};

export const selectEtodAirports = (state) => {
  const etodAirports = state.etod.etodAllAirports;

  if (etodAirports && etodAirports.length > 0) {
    return chain(etodAirports).orderBy(["name"], ["asc"]).value();
  }

  return [];
};

const selectEtodAirportDetailID = (state, id) => id;
const selectAllEtodAirportDetails = (state) => state.etod.etodAixmAirports;

export const selectEtodAirportDetail = createSelector(
  [selectAllEtodAirportDetails, selectEtodAirportDetailID],
  (airportDetailsData, airportId) => {
    if (airportId) {
      return find(airportDetailsData, { id: Number(airportId) });
    }

    return null;
  }
);

export const selectEtodAreaList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter((layer) => layer.type === "AREA");
  }

  return [];
};

export const selectedEtodOLSFeature = (state) => {
  const etodLayers = state.etod.etodLayers;
  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter((layer) => layer.type === "OLS");
  }

  return [];
};

export const selectedEtodAirportAreaFilter = createSelector(
  [selectEtodAreaList, selectedEtodOLSFeature],
  (areaFeature, olsFeature) => {
    let etodAirportFeature = [];
    if (areaFeature.length > 0) {
      etodAirportFeature.push({
        id: 1,
        label: "Area",
        value: "AREA",
      });
    }
    if (olsFeature.length > 0) {
      etodAirportFeature.push({
        id: 2,
        label: "OLS",
        value: "OLS",
      });
    }

    return etodAirportFeature;
  }
);

export const selectEtodArea1 = (state) => {
  const area1Detail = state.etod.area1;

  if (area1Detail?.Areas_List && area1Detail.Areas_List.length > 0) {
    return area1Detail.Areas_List;
  }

  return [];
};

export const selectEtodArea2 = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "AREA" && layer.category === "AREA2"
    );
  }

  return [];
};

export const selectEtodArea2A = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) =>
        layer.type === "AREA" &&
        layer.category === "AREA2" &&
        layer.sub_category === "2A"
    );
  }

  return [];
};

export const selectEtodArea2B = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) =>
        layer.type === "AREA" &&
        layer.category === "AREA2" &&
        layer.sub_category === "2B"
    );
  }

  return [];
};

export const selectEtodArea2C = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) =>
        layer.type === "AREA" &&
        layer.category === "AREA2" &&
        layer.sub_category === "2C"
    );
  }

  return [];
};

export const selectEtodArea2D = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) =>
        layer.type === "AREA" &&
        layer.category === "AREA2" &&
        layer.sub_category === "2D"
    );
  }

  return [];
};

export const selectEtodArea4 = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "AREA" && layer.category === "AREA4"
    );
  }

  return [];
};

export const selectAreaOptions = createSelector(
  [
    selectEtodArea1,
    selectEtodArea2A,
    selectEtodArea2B,
    selectEtodArea2C,
    selectEtodArea2D,
    selectEtodArea4,
  ],
  (area1, area2A, area2B, area2C, area2D, area4) => {
    const area2AOptions = uniqBy(area2A, "rwy").map((area, i) => {
      return {
        id: "area2a" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
        color: colorMap[i],
      };
    });

    const area2BOptions = uniqBy(area2B, "rwy").map((area, i) => {
      return {
        id: "area2b" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
        color: colorMap[i + area2AOptions.length],
      };
    });

    const area2COptions = uniqBy(area2C, "rwy").map((area, i) => {
      return {
        id: "area2c" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
        color: colorMap[i + area2AOptions.length + area2BOptions.length],
      };
    });

    const area4Options = uniqBy(area4, "rwy").map((area, i) => {
      return {
        id: "area4" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.category}`,
        color:
          colorMap[
            i +
              area2AOptions.length +
              area2BOptions.length +
              area2COptions.length
          ],
      };
    });

    const areaFetures = areaOptionList.map((area) => {
      if (area.value === "Area2A") {
        area.sub = orderBy(area2AOptions, ["name"], ["asc"]);
      }

      if (area.value === "Area2B") {
        area.sub = orderBy(area2BOptions, ["name"], ["asc"]);
      }

      if (area.value === "Area2C") {
        area.sub = orderBy(area2COptions, ["name"], ["asc"]);
      }

      if (area.value === "Area4") {
        area.sub = orderBy(area4Options, ["name"], ["asc"]);
      }

      return area;
    });

    if (area1.length === 0) {
      remove(areaFetures, (f) => f.value === "Area1");
    }
    if (area2AOptions.length === 0) {
      remove(areaFetures, (f) => f.value === "Area2A");
    }
    if (area2BOptions.length === 0) {
      remove(areaFetures, (f) => f.value === "Area2B");
    }
    if (area2COptions.length === 0) {
      remove(areaFetures, (f) => f.value === "Area2C");
    }
    if (area2D.length === 0) {
      remove(areaFetures, (f) => f.value === "Area2D");
    }
    if (area4.length === 0) {
      remove(areaFetures, (f) => f.value === "Area4");
    }

    return areaFetures;
  }
);

export const selectOLSApproachList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "APPROACH"
    );
  }

  return [];
};

export const selectOLSHorizontalSurfaceList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "HS"
    );
  }

  return [];
};

export const selectOLSConicalSurfaceList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "Conical"
    );
  }

  return [];
};

export const selectOLSTransitionalSurfaceList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "TS"
    );
  }

  return [];
};

export const selectOLSOuterTransitionalSurfaceList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "OTS"
    );
  }

  return [];
};

export const selectOLSObstacleFreeZoneList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) =>
        layer.type === "OLS" &&
        (layer.category === "IA" ||
          layer.category === "ITS" ||
          layer.category === "BL")
    );
  }

  return [];
};

export const selectOLSRwyStripList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "RWYSTRIP"
    );
  }

  return [];
};

export const selectOLSTakeOffList = (state) => {
  const etodLayers = state.etod.etodLayers;

  if (etodLayers && etodLayers.length > 0) {
    return etodLayers.filter(
      (layer) => layer.type === "OLS" && layer.category === "TAKEOFF"
    );
  }

  return [];
};

export const selectOLSOptions = createSelector(
  [
    selectOLSApproachList,
    selectOLSHorizontalSurfaceList,
    selectOLSTransitionalSurfaceList,
    selectOLSOuterTransitionalSurfaceList,
    selectOLSTakeOffList,
    selectOLSRwyStripList,
    selectOLSConicalSurfaceList,
    selectOLSObstacleFreeZoneList,
  ],
  (
    approachList,
    horizontalSurfaceList,
    transitionalSurfacelist,
    outerTransitionalSurfacelist,
    takeOffList,
    rwyStriplist,
    conicalSurfaceList,
    obstacleFreeZonelist
  ) => {
    const approachOptions = uniqBy(approachList, "rwy_dir").map(
      (approach, i) => {
        return {
          id: "approach" + i,
          name: approach.rwy_dir,
          value: `${approach.category}_${approach.rwy_dir}`,
          color: darkColor[i],
        };
      }
    );
    const hslOptions = horizontalSurfaceList.map((hsl, i) => {
      return {
        id: "hsl" + i,
        name: hsl.sub_category,
        value: hsl.sub_category,
        color: darkColor[i + approachOptions.length],
      };
    });
    const tslOptions = uniqBy(transitionalSurfacelist, "rwy").map((tsl, i) => {
      return {
        id: "tsl" + i,
        name: `${tsl.rwy}`,
        value: `${tsl.category}_${tsl.rwy}`,
        color: darkColor[i + approachOptions.length + hslOptions.length],
      };
    });
    const rwyOptions = uniqBy(rwyStriplist, "rwy").map((rwy, i) => {
      return {
        id: "rwy" + i,
        name: rwy.rwy,
        value: `${rwy.category}_${rwy.rwy}`,
        color:
          darkColor[
            i + approachOptions.length + hslOptions.length + tslOptions.length
          ],
      };
    });
    const takeOffOptions = uniqBy(takeOffList, "rwy_dir").map((takeOff, i) => {
      return {
        id: "takeOff" + i,
        name: takeOff.rwy_dir,
        value: `${takeOff.category}_${takeOff.rwy_dir}`,
        color:
          darkColor[
            i +
              approachOptions.length +
              hslOptions.length +
              tslOptions.length +
              rwyOptions.length
          ],
      };
    });
    const ofzOptions = uniqBy(obstacleFreeZonelist, "category").map(
      (ofz, i) => {
        return {
          id: "ofz" + i,
          name: ofzOptionLabel[ofz.category],
          value: `OFZ_${ofz.category}_${ofz.rwy}`,
          color:
            darkColor[
              i +
                approachOptions.length +
                hslOptions.length +
                tslOptions.length +
                rwyOptions.length +
                takeOffOptions.length
            ],
        };
      }
    );
    const outertsOptions = uniqBy(outerTransitionalSurfacelist, "rwy").map(
      (ots, i) => {
        return {
          id: "ots" + i,
          name: `${ots.rwy}`,
          value: `${ots.category}_${ots.rwy}`,
          color:
            darkColor[
              i +
                approachOptions.length +
                hslOptions.length +
                tslOptions.length +
                rwyOptions.length +
                takeOffOptions.length +
                ofzOptions.length
            ],
        };
      }
    );

    const OLSFeatures = OLSOptionList.map((ols) => {
      if (ols.value === "OLS_RwyStrip_List") {
        ols.sub = orderBy(rwyOptions, ["name"], ["asc"]);
      }
      if (ols.value === "OLS_Approach_List") {
        ols.sub = orderBy(approachOptions, ["name"], ["asc"]);
      }
      if (ols.value === "OLS_TakeOffClimb_List") {
        ols.sub = orderBy(takeOffOptions, ["name"], ["asc"]);
      }
      if (ols.value === "OLS_HorizontalSurface_List") {
        ols.sub = orderBy(hslOptions, ["name"], ["asc"]);
      }
      if (ols.value === "Transitional_Surface") {
        ols.sub = orderBy(tslOptions, ["name"], ["asc"]);
      }
      if (ols.value === "Outer_Transitional_Surface") {
        ols.sub = orderBy(outertsOptions, ["name"], ["asc"]);
      }
      if (ols.value === "OLS_ObstacleFreeZone_List") {
        ols.sub = orderBy(ofzOptions, ["name"], ["asc"]);
      }

      return ols;
    });

    if (rwyOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "OLS_RwyStrip_List");
    }
    if (approachOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "OLS_Approach_List");
    }
    if (takeOffOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "OLS_TakeOffClimb_List");
    }
    if (hslOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "OLS_HorizontalSurface_List");
    }
    if (tslOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "Transitional_Surface");
    }
    if (outertsOptions.length === 0) {
      remove(OLSFeatures, (f) => f.value === "Outer_Transitional_Surface");
    }
    if (obstacleFreeZonelist.length === 0) {
      remove(OLSFeatures, (f) => f.value === "OLS_ObstacleFreeZone_List");
    }
    if (conicalSurfaceList.length === 0) {
      remove(OLSFeatures, (f) => f.value === "Conical");
    }

    return OLSFeatures;
  }
);

export const selectEtodDem2A = (state) => {
  const etodDems = state.etod.etodDems;

  if (etodDems && etodDems.length > 0) {
    return etodDems.filter(
      (layer) => layer.category === "AREA2" && layer.sub_category === "2A"
    );
  }

  return [];
};

export const selectEtodDem2B = (state) => {
  const etodDems = state.etod.etodDems;

  if (etodDems && etodDems.length > 0) {
    return etodDems.filter(
      (layer) => layer.category === "AREA2" && layer.sub_category === "2B"
    );
  }

  return [];
};

export const selectEtodDem2C = (state) => {
  const etodDems = state.etod.etodDems;

  if (etodDems && etodDems.length > 0) {
    return etodDems.filter(
      (layer) => layer.category === "AREA2" && layer.sub_category === "2C"
    );
  }

  return [];
};

export const selectEtodDem2D = (state) => {
  const etodDems = state.etod.etodDems;

  if (etodDems && etodDems.length > 0) {
    return etodDems.filter(
      (layer) => layer.category === "AREA2" && layer.sub_category === "2D"
    );
  }

  return [];
};

export const selectDemOptions = createSelector(
  [selectEtodDem2A, selectEtodDem2B, selectEtodDem2C, selectEtodDem2D],
  (area2A, area2B, area2C, area2D) => {
    const dem2AOptions = uniqBy(area2A, "rwy").map((area, i) => {
      return {
        id: "area2a" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
      };
    });

    const dem2BOptions = uniqBy(area2B, "rwy").map((area, i) => {
      return {
        id: "area2b" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
      };
    });

    const dem2COptions = uniqBy(area2C, "rwy").map((area, i) => {
      return {
        id: "area2c" + i,
        name: area.rwy,
        value: `${area.rwy}_${area.sub_category}`,
      };
    });

    const demFetures = demOptionList.map((area) => {
      if (area.value === "Area2A") {
        area.sub = orderBy(dem2AOptions, ["name"], ["asc"]);
      }
      if (area.value === "Area2B") {
        area.sub = orderBy(dem2BOptions, ["name"], ["asc"]);
      }
      if (area.value === "Area2C") {
        area.sub = orderBy(dem2COptions, ["name"], ["asc"]);
      }

      return area;
    });

    if (dem2AOptions.length === 0) {
      remove(demFetures, (f) => f.value === "Area2A");
    }
    if (dem2BOptions.length === 0) {
      remove(demFetures, (f) => f.value === "Area2B");
    }
    if (dem2COptions.length === 0) {
      remove(demFetures, (f) => f.value === "Area2C");
    }
    if (area2D.length === 0) {
      remove(demFetures, (f) => f.value === "Area2D");
    }

    return demFetures;
  }
);

export const selectAreaAnalysisObstacle = (state) => {
  const analysisObstacles = state.etod.analysisObstacles;

  if (analysisObstacles && analysisObstacles.length > 0) {
    return analysisObstacles.filter(
      (obstacle) => obstacle.layer_type === "AREA"
    );
  }

  return [];
};

export const selectOlsAnalysisObstacle = (state) => {
  const analysisObstacles = state.etod.analysisObstacles;

  if (analysisObstacles && analysisObstacles.length > 0) {
    return analysisObstacles.filter(
      (obstacle) => obstacle.layer_type === "OLS"
    );
  }

  return [];
};
