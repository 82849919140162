import React, { Fragment } from "react";
import * as Cesium from "cesium";
import { Entity, EntityDescription } from "resium";
import PopupItem from "@components/map/PopupItem";
import { ZvalueMultiplyby } from "@constants/constant";
import { staticColorMap } from "@constants/colors";
import { styles } from "@styles/etodStyles";

export default function Render3DVisualiseObstacles({ obstacles }) {
  const obstacleColor = staticColorMap["Obstacles"];

  return (
    <Fragment>
      {obstacles.map((obstacle, i) => {
        return (
          <Entity
            key={`${i}-${obstacle.id}`}
            name={obstacle.REMARKS}
            position={Cesium.Cartesian3.fromDegrees(
              obstacle.longitude,
              obstacle.latitude,
              obstacle.Elevation * ZvalueMultiplyby
            )}
            ellipse={{
              semiMinorAxis: 10,
              semiMajorAxis: 10,
              height: 0,
              extrudedHeight: obstacle.Elevation * ZvalueMultiplyby,
              material:
                Cesium.Color.fromCssColorString(obstacleColor).withAlpha(0.5),
            }}
          >
            <EntityDescription>
              <div style={styles.etodObstaclesLabel}>
                <PopupItem>ICAO - {obstacle.Airport}</PopupItem>
                <PopupItem>
                  OBJECT - {obstacle["OBSTACLE TYPE"] || "-"}
                </PopupItem>
                <PopupItem>IDENTIFIER - {obstacle.IDENTIFIER}</PopupItem>
                <PopupItem>LATITIUDE - {obstacle.Latitude || "-"}</PopupItem>
                <PopupItem>LONGITUDE - {obstacle.Longitude || "-"}</PopupItem>
                <PopupItem>
                  RWY Direction - {obstacle["RWY Direction"] || "-"}
                </PopupItem>
                <PopupItem>ELEVATION - {obstacle.Elevation || "-"}</PopupItem>
                <PopupItem>
                  Horizontal Datum - {obstacle["Horizontal Datum"] || "-"}
                </PopupItem>
                <PopupItem>
                  Vertical Datum - {obstacle["Vertical Datum"] || "-"}
                </PopupItem>
                <PopupItem>
                  UOM Elevation - {obstacle["UOM Elevation"] || "-"}
                </PopupItem>
                <PopupItem>
                  RWY / AREA AFFECTED - {obstacle["RWY /AREA AFFECTED"] || "-"}
                </PopupItem>
                <PopupItem>
                  MARKING / LGT - {obstacle["MARKING /LGT"] || "-"}
                </PopupItem>
                <PopupItem>REMARK - {obstacle.REMARKS || "-"}</PopupItem>
              </div>
            </EntityDescription>
          </Entity>
        );
      })}
    </Fragment>
  );
}
