import React, { Fragment } from "react";
import { chain, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";

export default function Render3DOLSRwyStrip({
  OLSRwyStripList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSRwyStripList = chain(OLSRwyStripList)
    .filter((rwy) => {
      const name = `${rwy.category}_${rwy.rwy}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleOLSRwyStripList.map((olsRsl, i) => {
        const rslCoordinates = olsRsl.coordinates.coordinates;
        const name = `${olsRsl.category}_${olsRsl.rwy}`;

        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(rslCoordinates)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
