import { useState } from "react";
import { isEmpty } from "lodash";
import UTMLatLng from "utm-latlng";
import { FaAngleLeft } from "react-icons/fa";
import { Box, Divider, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Accordion from "@components/core/Accordion";
import MapWidget from "@components/core/MapWidget";
import AccordionDetails from "@components/core/AccordionDetails";
import AirportRWYCard from "@components/core/AirportRWYCard";
import RWYTitle from "@components/core/RWYTitle";
import { styles } from "@styles/etodStyles";
import { theme } from "@styles/theme";

export default function EtodAirportGenerateSurfaceRightSideBar({
  airportData,
}) {
  const utmHandler = new UTMLatLng();
  const [expanded, setExpanded] = useState(true);
  const [tabValue, setTabValue] = useState("1");

  const handleExpandAccordain = (name) => {
    setExpanded(name);
  };

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="detail-sidebar">
      <Accordion
        sx={{
          backgroundColor: theme.palette.white.light,
          borderRadius: "5px",
        }}
        expanded={expanded}
      >
        <MapWidget
          sx={{
            backgroundColor: theme.palette.white.light,
            pt: "5px",
            pb: "5px",
          }}
          expandIcon={
            <FaAngleLeft
              onClick={() => handleExpandAccordain(expanded ? false : true)}
            />
          }
        >
          <Typography component="p" className="sidebar-title">
            Details entered
          </Typography>
        </MapWidget>
        <AccordionDetails
          sx={{ padding: 0, backgroundColor: theme.palette.white.light }}
        >
          <Box sx={styles.etodGenSurfContent}>
            <TabContext value={tabValue}>
              <Box mt={1} sx={{ pt: 0, pr: "15px", pl: "15px" }}>
                <TabList
                  className="main-tab-list rwy-tab-list"
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons={false}
                >
                  {!isEmpty(airportData) &&
                    airportData.rwys.length > 0 &&
                    airportData.rwys.map((data, index) => (
                      <Tab
                        key={index}
                        value={String(index + 1)}
                        label={
                          <Box component="div" sx={{ width: "100%" }}>
                            <Typography
                              component="p"
                              className="tab-title date-direction"
                            >
                              Runway {index + 1}
                            </Typography>
                          </Box>
                        }
                      />
                    ))}
                </TabList>
              </Box>
              {!isEmpty(airportData) &&
                airportData.rwys.length > 0 &&
                airportData.rwys.map((data, index) => {
                  // const width = data.details.width * 3.28084;
                  const utmApproachData = utmHandler.convertLatLngToUtm(
                    data.approach.lat,
                    data.approach.lng,
                    2
                  );
                  const utmApproachLat = utmApproachData?.Easting;
                  const utmApproachLng = utmApproachData?.Northing;

                  const utmTakeoffData =
                    data && data?.takeoff
                      ? utmHandler.convertLatLngToUtm(
                          data.takeoff.lat,
                          data.takeoff.lng,
                          2
                        )
                      : "";
                  const utmTakeoffLat = utmTakeoffData
                    ? utmTakeoffData?.Easting
                    : "";
                  const utmTakeoffLng = utmTakeoffData?.Northing;
                  return (
                    <TabPanel
                      key={index + 2}
                      value={String(index + 1)}
                      className="detail-tab"
                      sx={{ maxHeight: "calc(100vh - 238px)", mt: 1.5 }}
                    >
                      <Box
                        sx={{ border: 0, pt: 0, pr: "15px", pb: 0, pl: "25px" }}
                      >
                        <RWYTitle
                          label="Runway Details"
                          className="rwy-title-main"
                        />
                        <AirportRWYCard
                          data={{
                            title: "Runway Width",
                            value: `${data.details.width} m`,
                          }}
                          sx={{
                            border: 0,
                          }}
                        />
                      </Box>
                      <Divider
                        sx={{ mb: 1, borderColor: theme.palette.grey.main }}
                      />
                      <Box sx={styles.etodGenSurfLabel}>
                        <RWYTitle label="Runway Direction A" />
                        <AirportRWYCard
                          data={{
                            title: "Co-ordinates",
                            value: `${
                              utmApproachLat ? utmApproachLat + "N" : ""
                            }, ${utmApproachLng ? utmApproachLng + "E" : ""}`,
                          }}
                        />
                        <AirportRWYCard
                          data={{
                            title: "Clearway Approach/Takeoff",
                            value: `${data.approach.cwy} Deg`,
                          }}
                        />
                        <AirportRWYCard
                          data={{
                            title: "Bearing",
                            value: `${data.approach.bearing} Deg`,
                          }}
                        />
                        <AirportRWYCard
                          data={{
                            title: "Tora",
                            value: `${data.approach.tora} Deg`,
                          }}
                        />
                        <AirportRWYCard
                          data={{
                            title: "Rd",
                            value: `${data.approach.rd} Deg`,
                          }}
                        />
                        <AirportRWYCard
                          data={{
                            title: "Category",
                            value: `${data.approach.category} Deg`,
                          }}
                        />
                      </Box>
                      {data && data?.takeoff && (
                        <>
                          <Divider
                            sx={{ mb: 1, borderColor: theme.palette.grey.main }}
                          />
                          <Box sx={styles.etodGenSurfLabel}>
                            <RWYTitle label="Runway Direction B" />
                            <AirportRWYCard
                              data={{
                                title: "Co-ordinates",
                                value: `${
                                  utmTakeoffLat ? utmTakeoffLat + "N" : ""
                                }, ${utmTakeoffLng ? utmTakeoffLng + "E" : ""}`,
                              }}
                            />
                            <AirportRWYCard
                              data={{
                                title: "Clearway Approach/Takeoff",
                                value: `${data.takeoff.cwy} Deg`,
                              }}
                            />
                            <AirportRWYCard
                              data={{
                                title: "Bearing",
                                value: `${data.takeoff.bearing} Deg`,
                              }}
                            />
                            <AirportRWYCard
                              data={{
                                title: "Tora",
                                value: `${data.takeoff.tora} Deg`,
                              }}
                            />
                            <AirportRWYCard
                              data={{
                                title: "Rd",
                                value: `${data.takeoff.rd} Deg`,
                              }}
                            />
                            <AirportRWYCard
                              data={{
                                title: "Category",
                                value: `${data.takeoff.category} Deg`,
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </TabPanel>
                  );
                })}
            </TabContext>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
