import React from 'react'

const DataManagementIcon = (props) => {
  return (
    <svg
      width={props.width || "66"}
      height={props.height || "63"}
      viewBox="0 0 66 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 34.0398V28.4045C1 25.0745 3.71864 22.321 7.07696 22.321C12.8661 22.321 15.2329 18.2226 12.3223 13.1957C10.6592 10.314 11.6507 6.56776 14.5612 4.90278L20.0945 1.73292C22.6212 0.22803 25.8836 1.12456 27.3868 3.65405L27.7386 4.26241C30.6172 9.28937 35.3508 9.28937 38.2614 4.26241L38.6132 3.65405C40.1164 1.12456 43.3788 0.22803 45.9055 1.73292L51.4388 4.90278C54.3493 6.56776 55.3408 10.314 53.6777 13.1957C50.7671 18.2226 53.1339 22.321 58.923 22.321C62.2494 22.321 65 25.0425 65 28.4045V34.0398C65 37.3698 62.2814 40.1234 58.923 40.1234C53.1339 40.1234 50.7671 44.2218 53.6777 49.2488C55.3408 52.1625 54.3493 55.8767 51.4388 57.5417L45.9055 60.7115C43.3788 62.2164 40.1164 61.3199 38.6132 58.7904L38.2614 58.182C35.3828 53.1551 30.6492 53.1551 27.7386 58.182L27.3868 58.7904C25.8836 61.3199 22.6212 62.2164 20.0945 60.7115L14.5612 57.5417C11.6507 55.8767 10.6592 52.1305 12.3223 49.2488C15.2329 44.2218 12.8661 40.1234 7.07696 40.1234C3.71864 40.1234 1 37.3698 1 34.0398Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 24.5714C43 25.0404 42.7413 25.5048 42.2388 25.9382C41.7362 26.3715 40.9997 26.7652 40.0711 27.0968C39.1425 27.4284 38.0401 27.6915 36.8268 27.871C35.6136 28.0505 34.3132 28.1429 33 28.1429C30.3478 28.1429 27.8043 27.7666 25.9289 27.0968C24.0536 26.427 23 25.5186 23 24.5714C23 24.1024 23.2587 23.638 23.7612 23.2047C24.2638 22.7714 25.0003 22.3777 25.9289 22.046C26.8575 21.7144 27.9599 21.4513 29.1732 21.2719C30.3864 21.0924 31.6868 21 33 21C34.3132 21 35.6136 21.0924 36.8268 21.2719C38.0401 21.4513 39.1425 21.7144 40.0711 22.046C40.9997 22.3777 41.7362 22.7714 42.2388 23.2047C42.7413 23.638 43 24.1024 43 24.5714Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 37.4375C43 38.0644 42.5379 38.6803 41.6603 39.2232C40.7826 39.7661 39.5202 40.217 38 40.5304C36.4798 40.8439 34.7554 41.0089 33 41.0089C31.2446 41.0089 29.5202 40.8439 28 40.5304C26.4798 40.217 25.2174 39.7661 24.3397 39.2232C23.4621 38.6803 23 38.0644 23 37.4375"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 33.1484C43 33.7754 42.5379 34.3912 41.6603 34.9342C40.7826 35.4771 39.5202 35.9279 38 36.2414C36.4798 36.5548 34.7554 36.7199 33 36.7199C31.2446 36.7199 29.5202 36.5548 28 36.2414C26.4798 35.9279 25.2174 35.4771 24.3397 34.9342C23.4621 34.3912 23 33.7754 23 33.1484"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 28.8516C43 29.4785 42.5379 30.0944 41.6603 30.6373C40.7826 31.1802 39.5202 31.6311 38 31.9445C36.4798 32.258 34.7554 32.423 33 32.423C31.2446 32.423 29.5202 32.258 28 31.9445C26.4798 31.6311 25.2174 31.1802 24.3397 30.6373C23.4621 30.0944 23 29.4785 23 28.8516"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 24.5625V37.4196"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 24.5625V37.4196"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DataManagementIcon
