import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderOLSConicalSurface({
  OLSConicalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSConicalSurfaceList = chain(OLSConicalSurfaceList)
    .filter((csObj) => {
      return selectedOls.includes(
        `${csObj.category}__${olsColor[csObj.category]}`
      );
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSConicalSurfaceList.map((olscs, i) => {
        const hslCoordinates = olscs.coordinates.coordinates;
        const hslCoordinateMap = flatten(hslCoordinates).map((hslCoords) => {
          return hslCoords.map((hsl) => {
            return [hsl[1], hsl[0]];
          });
        });

        return (
          <Polygon
            key={i}
            pane="tilePane"
            positions={hslCoordinateMap}
            pathOptions={{
              fillColor: olsColor[olscs.category],
              color:
                selectedOverE?.category === olscs.category
                  ? areaHighLightColor
                  : olsColor[olscs.category],
              weight: selectedOverE?.category === olscs.category ? 4 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, olscs),
              mouseout: () => handlePolygonOut(),
            }}
          />
        );
      })}
    </Fragment>
  );
}
