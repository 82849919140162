import React from "react";
import { snakeCase, toUpper } from "lodash";
import { Box, TableRow, Typography } from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { dataChangedRowColorMap } from "@constants/colors";

export default function TableFooterRow({
  colspan = 6,
  selctedAirport,
  selctedCategory,
}) {
  const fileName = snakeCase(
    `SURVEYOR ${selctedCategory?.value} ${selctedAirport?.name}`
  );

  return (
    <TableRow>
      <StyledTableCell colSpan={colspan}>
        <Box>{toUpper(fileName)}</Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.new_obstacle,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">New entry</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.deviation_detected,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">
              Spatial/Numerical Deviation detected
            </Typography>
          </Box>
        </Box>
      </StyledTableCell>
    </TableRow>
  );
}
