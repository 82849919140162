import React, { Fragment, useRef, useEffect } from "react";
import { Polygon, FeatureGroup, useMap } from "react-leaflet";
import L from "leaflet";
import { flattenDeep } from "lodash";
import TextMarker from "@components/map/TextMarker";

export default function RenderGeometryGridLine({
  data,
  zoomLevel,
  handleChartGridExport,
}) {
  const map = useMap();
  const gridRef = useRef(null);

  useEffect(() => {
    const flattenCoordinates = flattenDeep(
      data.flatMap((x) => x.geometry_json.coordinates)
    );
    let lats = flattenCoordinates
      .map((c) => c.lat)
      .reduce((previous, current) => (current += previous), 0.0);
    let longs = flattenCoordinates
      .map((c) => c.lng)
      .reduce((previous, current) => (current += previous), 0.0);

    const position = [
      lats / flattenCoordinates.length,
      longs / flattenCoordinates.length,
    ];
    map.setView(position, zoomLevel, { animate: false });
    map.fitBounds(gridRef.current.getBounds());
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {data.map((geometry, i) => {
        const flattenCoordinates = flattenDeep(
          geometry.geometry_json.coordinates
        );
        const position = L.polygon(flattenCoordinates).getBounds().getCenter();

        return (
          <TextMarker
            key={i}
            label={geometry.name}
            position={position}
            className="text-marker"
          />
        );
      })}
      <FeatureGroup ref={gridRef}>
        {data.map((geometry) => {
          const coordinates = geometry.geometry_json.coordinates;

          return coordinates.map((coord, i) => {
            return (
              <Polygon
                key={i}
                positions={coord}
                pathOptions={{
                  color: "red",
                  weight: 5,
                  fillColor: "red",
                  fillOpacity: 0.1,
                }}
                eventHandlers={{
                  click: () => handleChartGridExport(geometry.name),
                }}
              />
            );
          });
        })}
      </FeatureGroup>
    </Fragment>
  );
}
