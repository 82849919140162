import React, { Fragment } from "react";
import {Table, TableBody, TableHead, Toolbar, Typography} from "@mui/material";
import InReviewTableHeaderRow from "./InReviewTableHeaderRow";
import InReviewDataTableRow from "./InReviewDataTableRow";

export default function InReviewDataTable({ data, isShowBaseElev }) {
  return (
    <Fragment>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          In Review
        </Typography>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead>
          <InReviewTableHeaderRow isShowBaseElev={isShowBaseElev} />
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <InReviewDataTableRow
              key={i}
              row={row}
              isShowBaseElev={isShowBaseElev}
            />
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
}
