import { useMemo } from "react";
import { ImageryLayer } from "resium";
import { WebMapTileServiceImageryProvider } from "cesium";
import { GET_GEOSERVER_WMTS_URL } from "@constants/urls";

const Render3DDems = ({ etodDems, selectedDems }) => {
  const dems = etodDems.filter((dem) => {
    if (dem.sub_category === "2D") {
      return selectedDems.includes("Area2D");
    }

    return selectedDems.includes(`${dem.rwy}_${dem.sub_category}`);
  });

  const layers = useMemo(() => {
    return dems.map((dem) => {
      return (
        <ImageryLayer
          key={dem.dem_gs_layer_name}
          imageryProvider={
            new WebMapTileServiceImageryProvider({
              url: GET_GEOSERVER_WMTS_URL,
              layer: `inetra:${dem.dem_gs_layer_name}`,
              style: "",
              tileMatrixSetID: "EPSG:900913",
              tileMatrixLabels: [
                "EPSG:900913:0",
                "EPSG:900913:1",
                "EPSG:900913:2",
                "EPSG:900913:3",
                "EPSG:900913:4",
                "EPSG:900913:5",
                "EPSG:900913:6",
                "EPSG:900913:7",
                "EPSG:900913:8",
                "EPSG:900913:9",
                "EPSG:900913:10",
                "EPSG:900913:11",
                "EPSG:900913:12",
                "EPSG:900913:13",
                "EPSG:900913:14",
                "EPSG:900913:15",
              ],
              maximumLevel: 15,
              format: "image/png8",
              transparent: true,
            })
          }
        />
      );
    });

    // eslint-disable-next-line
  }, [selectedDems]);

  return <>{layers}</>;
};

export default Render3DDems;
