import React, { useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, ButtonGroup, IconButton, Typography } from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import Header from "@components/Header";
import { getAllSurveyReport } from "@store/survey/actions";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function AdminReviewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isSurveyorReview =
    location.pathname === "/data-management/review/surveyor-report-list";
  const isAirportReview =
    location.pathname === "/data-management/review/airport-review-list";

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyReport(token));
    });
  }, []); // eslint-disable-line

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div className="content-area">
          <div
            className="surveyor-category-table white-bg"
            style={{ padding: 24, paddingTop: 0 }}
          >
            <Box sx={styles.reviewDatasetBar}>
              <Box sx={styles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() =>
                    navigate(routeNames.UserDashboard, { replace: true })
                  }
                  sx={{ p: 0, ml: "-8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  variant="span"
                  color="primary"
                  sx={{ fontWeight: 600, fontSize: 18, pl: 1 }}
                >
                  Review Datasets
                </Typography>
              </Box>
              <ButtonGroup aria-label="outlined button group">
                <Button
                  variant={isAirportReview ? "contained" : "outlined"}
                  sx={styles.reviewDatasetBtn}
                  onClick={() => navigate(routeNames.AirportReviewList)}
                >
                  Airport Datasets
                </Button>
                <Button
                  variant={isSurveyorReview ? "contained" : "outlined"}
                  sx={styles.reviewDatasetBtn}
                  onClick={() => navigate(routeNames.SurveyorReportList)}
                >
                  Surveyor Datasets
                </Button>
              </ButtonGroup>
            </Box>
            <Box
              sx={{
                ...styles.arpReviewCategoryList,
                margin: 0,
                height: "calc(100% - 73px)",
              }}
            >
              <Outlet />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
