import React from "react";
import { airspaceFilterType } from "@constants/airspaceData";
import FIRTable from "./FIRTable";
import TMATable from "./TMATable";
import CTATable from "./CTATable";
import RATable from "./RATable";
import RNTable from "./RNTable";
import SPTable from "./SPTable";
import DPRTable from "./DPRTable";
import METATable from "./METATable";
import RouteTable from "./RouteTable";

export default function AirspaceTables({
  selctedAirspaceFilter,
  selectedAirspaceRows,
  selectedRoute,
  routeData,
  allNavaidData,
  allSignificantPoints,
  DPRDataList,
  METADataList,
  isLoadingFIRData,
  FIRData,
  isLoadingTMAData,
  TMAData,
  isLoadingCTAData,
  CTAData,
  isLoadingRegulatedAirspaceData,
  regulatedAirspaceData,
  isLoadingGetAllNavaid,
  isLoadingGetAllsignificantPoint,
  isLoadingGetAllDPR,
  isLoadingGetAllTRA_TSA_ADIZ,
  selctedMETAFilter,
  isLoadingGetRouteData,
}) {
  if (selectedAirspaceRows.length <= 0 && !selectedRoute) {
    return null;
  }

  return (
    <div className="airspace-category-table">
      {selctedAirspaceFilter?.value === airspaceFilterType.firuir && (
        <FIRTable
          isLoading={isLoadingFIRData}
          selectedFIR={selectedAirspaceRows}
          FIRData={FIRData}
        />
      )}
      {selctedAirspaceFilter?.value === airspaceFilterType.tma && (
        <TMATable
          isLoading={isLoadingTMAData}
          selectedTMA={selectedAirspaceRows}
          TMAData={TMAData}
        />
      )}
      {selctedAirspaceFilter?.value === airspaceFilterType.controlArea && (
        <CTATable
          isLoading={isLoadingCTAData}
          selectedCTA={selectedAirspaceRows}
          CTAData={CTAData}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace && (
        <RATable
          isLoading={isLoadingRegulatedAirspaceData}
          selectedRA={selectedAirspaceRows}
          RAData={regulatedAirspaceData}
        />
      )}
      {selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation && (
        <RNTable
          isLoading={isLoadingGetAllNavaid}
          selectedRN={selectedAirspaceRows}
          RNData={allNavaidData}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.significantPoints && (
        <SPTable
          isLoading={isLoadingGetAllsignificantPoint}
          selectedSP={selectedAirspaceRows}
          SPData={allSignificantPoints}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas && (
        <DPRTable
          isLoading={isLoadingGetAllDPR}
          selectedDPR={selectedAirspaceRows}
          DPRDataList={DPRDataList}
        />
      )}
      {selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas && (
        <METATable
          isLoading={isLoadingGetAllTRA_TSA_ADIZ}
          selectedMETA={selectedAirspaceRows}
          METADataList={METADataList}
          selctedMETAFilter={selctedMETAFilter}
        />
      )}
      {selectedRoute && (
        <RouteTable isLoading={isLoadingGetRouteData} routeData={routeData} />
      )}
    </div>
  );
}
