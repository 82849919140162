import { Polygon, Popup, useMap } from "react-leaflet";
import "leaflet.pattern";
import L, { point } from "leaflet";
import { find, flatten, isEmpty } from "lodash";
import { Button, Stack } from "@mui/material";
import { airspaceType } from "@constants/airspaceData";

export default function RenderRoutePlanningAirspace({
  restrictiveAirspaceGeometry,
  arpFlightPlanErrorsAirspace,
  handleAirspaceUsePlanDetails,
  handleShoWDetails,
}) {
  const map = useMap();
  if (isEmpty(arpFlightPlanErrorsAirspace)) return null;

  const stripes = new L.StripePattern({
    height: 10,
    width: 10,
    color: "#fff",
    spaceColor: "#EB5757",
    weight: 2,
    spaceWeight: 10,
    angle: 115,
    opacity: 0.55,
    spaceOpacity: 1,
  });
  stripes.addTo(map);

  return arpFlightPlanErrorsAirspace.map((data) => {
    const name = data.name;
    const airspaceData = find(restrictiveAirspaceGeometry, {
      designator: name,
    });
    const msg = `Restricted Airspace : ${data.msg}`;
    return (
      <Polygon
        key={data.name}
        positions={flatten(airspaceData.coordinates)}
        pathOptions={{ fillColor: "#EB5757", fillOpacity: 1, color: "#fff" }}
        fillPattern={stripes}
      >
        <Popup
          className="route-plan-airspace"
          offset={point([-209, 70])}
          minWidth={234}
        >
          <span
            style={{
              color: "#fff",
              fontSize: 12,
              fontWeight: 400,
              whiteSpace: "pre-wrap",
            }}
          >
            {msg}
          </span>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            sx={{ mt: 1, pt: 1, borderTop: "0.1px solid #fff" }}
          >
            <Button
              variant="text"
              sx={{
                color: "#fff",
                textTransform: "none",
                fontSize: 12,
                fontWeight: 600,
                p: 0,
              }}
              onClick={() =>
                handleAirspaceUsePlanDetails(
                  airspaceData,
                  airspaceType.restrictedAirspace
                )
              }
            >
              View AUP
            </Button>
            {/* <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ borderColor: "#fff" }}
            />
            <Button
              variant="text"
              sx={{
                color: "#fff",
                textTransform: "none",
                fontSize: 12,
                fontWeight: 600,
                p: 0,
              }}
              onClick={handleShoWDetails}
            >
              View Airspace Details
            </Button> */}
          </Stack>
        </Popup>
      </Polygon>
    );
  });
}
