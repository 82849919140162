import React from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";

const Input = styled("input")({
  display: "none",
});

export const PrimaryButton = ({ label, isLoading, ...props }) => (
  <LoadingButton
    variant="contained"
    loading={isLoading}
    sx={{ width: 150 }}
    {...props}
  >
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const PrimaryButtonV2 = ({ label, isLoading, ...props }) => (
  <LoadingButton
    variant="contained"
    loading={isLoading}
    sx={{ width: "100%", mr: 1 }}
    {...props}
  >
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const LightButton = ({ label, isLoading, ...props }) => (
  <LoadingButton variant="text" loading={isLoading} {...props}>
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const OutlinedButton = ({ label, isLoading, ...props }) => (
  <LoadingButton variant="outlined" loading={isLoading} {...props}>
    <Typography variant="button" sx={{ textTransform: "none" }}>
      {label}
    </Typography>
  </LoadingButton>
);

export const UploadButton = React.forwardRef(
  ({ label, isLoading, accept, handleUpload, ...props }, ref) => {
    return (
      <label htmlFor="contained-button-file">
        <Input
          ref={ref}
          accept={accept}
          id="contained-button-file"
          type="file"
          onChange={handleUpload}
          disabled={props.disabled}
        />
        <LoadingButton
          variant="contained"
          component="span"
          loading={isLoading}
          {...props}
        >
          <Typography variant="button" sx={{ textTransform: "none" }}>
            {label}
          </Typography>
        </LoadingButton>
      </label>
    );
  }
);
