import React from "react";
import { theme } from "@styles/theme";

function ProceedOlsIcon(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3327 48.6666V31.3366L47.696 40L38.3327 48.6666ZM41.1293 24.8433C39.4693 23.3033 37.046 22.9033 34.9593 23.82C32.926 24.7066 31.666 26.6033 31.666 28.7666V51.2366C31.666 53.4 32.926 55.2966 34.9593 56.1833C35.7027 56.51 36.4927 56.6666 37.2694 56.6666C38.6793 56.6666 40.0627 56.15 41.126 55.1633L53.266 43.93C54.3694 42.91 54.9994 41.48 54.9994 40C54.9994 38.5233 54.3694 37.09 53.266 36.0733L41.1293 24.8433ZM39.9993 66.668C25.296 66.668 13.3327 54.7046 13.3327 40.0013C13.3327 25.298 25.296 13.3346 39.9993 13.3346C54.7027 13.3346 66.666 25.298 66.666 40.0013C66.666 54.7046 54.7027 66.668 39.9993 66.668ZM39.9994 6.66797C21.6193 6.66797 6.66602 21.6213 6.66602 40.0013C6.66602 58.3813 21.6193 73.3346 39.9994 73.3346C58.3794 73.3346 73.3327 58.3813 73.3327 40.0013C73.3327 21.6213 58.3794 6.66797 39.9994 6.66797Z"
        fill={props.color || theme.palette.primary.main}
      />
    </svg>
  );
}

export default ProceedOlsIcon;
