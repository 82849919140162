import React, { Fragment } from "react";
import { flatten } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";

export default function Render3DArea1({ area1Data, getAreaColors }) {
  const area1coordinates = area1Data[0]?.coordinates.coordinates || [];
  const area1CoordinateMap = flatten(area1coordinates);
  const name = "AREA 1";
  const areaColor = getAreaColors();

  return (
    <Fragment>
      {area1CoordinateMap.map((coords, i) => {
        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArray(flatten(coords)),
              extrudedHeight: 0,
              material: Cesium.Color.fromCssColorString(
                areaColor["Area1"]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
