import React from "react";

function LocationIcon(props) {
  return (
    <svg
      width={props.width || "13"}
      height={props.height || "15"}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27091 6.43526C8.27091 5.45685 7.47812 4.66406 6.50044 4.66406C5.52204 4.66406 4.72925 5.45685 4.72925 6.43526C4.72925 7.41294 5.52204 8.20573 6.50044 8.20573C7.47812 8.20573 8.27091 7.41294 8.27091 6.43526Z"
        stroke={props.color || "#42444A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49965 13.875C5.65073 13.875 1.1875 10.2613 1.1875 6.48233C1.1875 3.52387 3.56545 1.125 6.49965 1.125C9.43386 1.125 11.8125 3.52387 11.8125 6.48233C11.8125 10.2613 7.34857 13.875 6.49965 13.875Z"
        stroke={props.color || "#42444A"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LocationIcon;
