import React from "react";
import { theme } from "@styles/theme";

function PolygonIcon(props) {
  return (
    <svg
      width={props.width || "16"}
      height={props.height || "15"}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.7075 0.83387C7.88265 0.706442 8.11992 0.706275 8.29525 0.833455L14.7322 5.50273C14.9075 5.62993 14.981 5.85558 14.9142 6.06165L12.4622 13.6257C12.3954 13.8317 12.2035 13.9713 11.9869 13.9715L4.03444 13.9771C3.81783 13.9773 3.62575 13.8379 3.55867 13.632L1.09595 6.07141C1.02885 5.86544 1.10203 5.63969 1.2772 5.51224L7.7075 0.83387Z"
        stroke={props.color || theme.palette.white.main}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PolygonIcon;
