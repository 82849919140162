import { orderBy } from 'lodash';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableCell from "@components/tabel-component/StyledTableCell";

const AixmAD15Table = ({ AD1_5Aerodrome }) => {
  return (
    <Box className="selected-general-data">
      <Box className="selected-ad-data" sx={{ height: "100%" }}>
        <TableContainer sx={{ height: "100%" }}>
          <Table
            stickyHeader
            size="small"
            className="ad-table"
            sx={{ height: "100%" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Aerodrome Name</StyledTableCell>
                <StyledTableCell>ICAO Location Indicator</StyledTableCell>
                <StyledTableCell>
                  Date of issue of license (dd mm yyy)
                </StyledTableCell>
                <StyledTableCell>
                  Validity date of license (dd mm yyy)
                </StyledTableCell>
                <StyledTableCell>Remarks if any License no.</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(AD1_5Aerodrome, ["airport_name"], ["asc"]).map(
                (ADdata, i) => {
                  return (
                    <TableRow key={i}>
                      <StyledTableCell>{i + 1}</StyledTableCell>
                      <StyledTableCell>{ADdata.airport_name}</StyledTableCell>
                      <StyledTableCell>{ADdata.icao}</StyledTableCell>
                      <StyledTableCell>
                        {ADdata.certification_date}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ADdata.certificate_expiration_date}
                      </StyledTableCell>
                      <StyledTableCell>{ADdata.remark}</StyledTableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AixmAD15Table;