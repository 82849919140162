import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
} from "@mui/material";
import {PrimaryButton, LightButton } from "@components/button";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd29ValidationSchema } from "@constants/ad2Data";
import { AD29initialValue } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function AD29SurfacmovementsForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd29Form.setFieldValue(
      "aircraftstands",
      AAIAirport?.aircraftstands ? AAIAirport?.aircraftstands : ""
    );
    createAd29Form.setFieldValue(
      "markings",
      AAIAirport?.markings ? AAIAirport?.markings : ""
    );
    createAd29Form.setFieldValue(
      "stopbars",
      AAIAirport?.stopbars ? AAIAirport?.stopbars : ""
    );
    createAd29Form.setFieldValue(
      "remarks",
      AAIAirport?.remarks ? AAIAirport?.remarks : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "surfacmovements",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd29Form.resetForm();
    createAd29Form.setSubmitting(false);
  };

  const createAd29Form = useFormik({
    enableReinitialize: true,
    initialValues: AD29initialValue,
    validationSchema: createAd29ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "surfacmovements",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2, pt:1 }}>
            <Box sx={{ width: "30%" }}>
              Use of aircraft stand identification signs, taxiway guidelines and
              visual docking/parking guidance system at aircraft stands
            </Box>
            <Box sx={{ pl: 2, minWidth: "400px" }}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd29Form.touched?.aircraftstands &&
                  Boolean(createAd29Form.errors?.aircraftstands)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="aircraftstands"
                  name="aircraftstands"
                  value={createAd29Form.values.aircraftstands}
                  onChange={createAd29Form.handleChange}
                  aria-describedby="aerodrome-aircraftstands"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-aircraftstands">
                  {createAd29Form.touched?.aircraftstands &&
                    createAd29Form.errors?.aircraftstands}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>
              Runway and taxiway markings and lights
            </Box>
            <Box sx={{ pl: 2, minWidth: "400px" }}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd29Form.touched?.markings &&
                  Boolean(createAd29Form.errors?.markings)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="markings"
                  name="markings"
                  value={createAd29Form.values.markings}
                  onChange={createAd29Form.handleChange}
                  aria-describedby="aerodrome-markings"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-markings">
                  {createAd29Form.touched?.markings &&
                    createAd29Form.errors?.markings}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Stop bars</Box>
            <Box sx={{ pl: 2, minWidth: "400px" }}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd29Form.touched?.stopbars &&
                  Boolean(createAd29Form.errors?.stopbars)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="stopbars"
                  name="stopbars"
                  value={createAd29Form.values.stopbars}
                  onChange={createAd29Form.handleChange}
                  aria-describedby="aerodrome-stopbars"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-stopbars">
                  {createAd29Form.touched?.stopbars &&
                    createAd29Form.errors?.stopbars}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={{ pl: 2, minWidth: "400px" }}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd29Form.touched?.remarks &&
                  Boolean(createAd29Form.errors?.remarks)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="remarks"
                  name="remarks"
                  value={createAd29Form.values.remarks}
                  onChange={createAd29Form.handleChange}
                  aria-describedby="aerodrome-remarks"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-remarks">
                  {createAd29Form.touched?.remarks &&
                    createAd29Form.errors?.remarks}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd29Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd29Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default AD29SurfacmovementsForm;
