import React from "react";

export const DownloadIcon = ({disabled, props}) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25213 7.05692C5.25072 7.03813 5.25 7.01915 5.25 7V1C5.25 0.586 5.58525 0.25 6 0.25C6.41475 0.25 6.75 0.586 6.75 1V6.99987L8.55 5.65C8.8815 5.40025 9.35175 5.4685 9.6 5.8C9.849 6.1315 9.7815 6.60175 9.45 6.85L6.45 9.1C6.31725 9.19975 6.15825 9.25 6 9.25C5.84925 9.25 5.6985 9.20425 5.56875 9.1135L2.56875 7.003C2.22975 6.7645 2.148 6.2965 2.3865 5.95825C2.625 5.61925 3.09225 5.5375 3.43125 5.776L5.25213 7.05692ZM1.5 10.75V11.5H10.5V10.75C10.5 10.3375 10.8375 10 11.25 10C11.6625 10 12 10.3375 12 10.75V12.25C12 12.6625 11.6625 13 11.25 13H0.75C0.3375 13 0 12.6625 0 12.25V10.75C0 10.3375 0.3375 10 0.75 10C1.1625 10 1.5 10.3375 1.5 10.75Z"
        fill={disabled ? "#D9D9D9" : "#000"}
      />
    </svg>
  );
};
