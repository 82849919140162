import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { isEmpty } from "lodash";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { EditIcon } from "@assets/svg/edit";
import { ACCESS_TOKEN } from "@constants/constant";
import { AD214bodyDetail } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad214Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const { AAIAirport, AD212RWYDetail, isLoadingAddAAIAirportDetail } =
    useSelector((state) => state.airport);
  const [editId, setEditId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    if (!isEmpty(AAIAirport)) {
      AAIAirport.map((data, index) => {
        createAd214Form.setFieldValue(
          `rwylights[${index}].rd`,
          data.rd ? data.rd : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].approachlightingsystems.classICAO`,
          data.approachlightingsystems?.classICAO
            ? data.approachlightingsystems.classICAO
            : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].approachlightingsystems.intensityLevel`,
          data.approachlightingsystems.intensityLevel
            ? data.approachlightingsystems.intensityLevel
            : ""
        );
        const approachLength = data.approachlightingsystems.length
          ? data.approachlightingsystems.length.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].approachlightingsystems.length`,
          approachLength
        );
        const clrwyLength = data.clrunwaylights.length
          ? data.clrunwaylights.length.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].clrunwaylights.length`,
          clrwyLength
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].clrunwaylights.intensityLevel`,
          data.clrunwaylights.intensityLevel
            ? data.clrunwaylights.intensityLevel
            : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].clrunwaylights.colour`,
          data.clrunwaylights.colour ? data.clrunwaylights.colour : ""
        );
        const clrwySpacing = data.clrunwaylights.spacing
          ? data.clrunwaylights.spacing.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].clrunwaylights.spacing`,
          clrwySpacing
        );
        const edgeLength = data.edgerunwaylights.length
          ? data.edgerunwaylights.length.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].edgerunwaylights.length`,
          edgeLength
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].edgerunwaylights.intensityLevel`,
          data.edgerunwaylights.intensityLevel
            ? data.edgerunwaylights.intensityLevel
            : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].edgerunwaylights.colour`,
          data.edgerunwaylights.colour ? data.edgerunwaylights.colour : ""
        );
        const edgeSpacing = data.edgerunwaylights.spacing
          ? data.edgerunwaylights.spacing.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].edgerunwaylights.spacing`,
          edgeSpacing
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].endrunwaylights.colour`,
          data.endrunwaylights.colour ? data.endrunwaylights.colour : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].stopwaylights.colour`,
          data.stopwaylights.colour ? data.stopwaylights.colour : ""
        );
        const stopwayLength = data.stopwaylights.length
          ? data.stopwaylights.length.replace("M", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].stopwaylights.length`,
          stopwayLength
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].thrrunwaylights.colour`,
          data.thrrunwaylights.colour ? data.thrrunwaylights.colour : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].thrrunwaylights.wingbar`,
          data.thrrunwaylights.wingbar ? data.thrrunwaylights.wingbar : ""
        );
        const minthrHeight = data.visualglideslopeindicators
          .minimumEyeHeightOverThreshold
          ? data.visualglideslopeindicators.minimumEyeHeightOverThreshold
              .replace("FT", "")
              .replace("M", "")
              .trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].visualglideslopeindicators.minimumEyeHeightOverThreshold`,
          minthrHeight
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].visualglideslopeindicators.position`,
          data.visualglideslopeindicators.position
            ? data.visualglideslopeindicators.position
            : ""
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].visualglideslopeindicators.type`,
          data.visualglideslopeindicators.type
            ? data.visualglideslopeindicators.type
            : ""
        );
        const minthrSlopeAngle = data.visualglideslopeindicators.slopeAngle
          ? data.visualglideslopeindicators.slopeAngle.replace("DEG", "").trim()
          : "";
        createAd214Form.setFieldValue(
          `rwylights[${index}].visualglideslopeindicators.slopeAngle`,
          minthrSlopeAngle
        );
        createAd214Form.setFieldValue(
          `rwylights[${index}].remarks`,
          data.remarks ? data.remarks : ""
        );
        return true;
      });
    }
  }, [AAIAirport, createAd214Form]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "rwylights",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleSuccessCallback = () => {
    createAd214Form.resetForm();
    createAd214Form.setSubmitting(false);
  };

  const createAd214Form = useFormik({
    enableReinitialize: true,
    initialValues: { rwylights: [AD214bodyDetail] },
    onSubmit: (values) => {
      if (editId) {
        const payload = {
          rd: values.rwylights[editIndex].rd,
          approachlightingsystems: {
            classICAO:
              values.rwylights[editIndex].approachlightingsystems.classICAO,
            intensityLevel:
              values.rwylights[editIndex].approachlightingsystems
                .intensityLevel,
            length: values.rwylights[editIndex].approachlightingsystems.length
              ? `${values.rwylights[editIndex].approachlightingsystems.length} M`
              : "",
          },
          clrunwaylights: {
            colour: values.rwylights[editIndex].clrunwaylights.colour,
            intensityLevel:
              values.rwylights[editIndex].clrunwaylights.intensityLevel,
            length: values.rwylights[editIndex].clrunwaylights.length
              ? `${values.rwylights[editIndex].clrunwaylights.length} M`
              : "",
            spacing: values.rwylights[editIndex].clrunwaylights.spacing
              ? `${values.rwylights[editIndex].clrunwaylights.spacing} M`
              : "",
          },
          edgerunwaylights: {
            colour: values.rwylights[editIndex].edgerunwaylights.colour,
            intensityLevel:
              values.rwylights[editIndex].edgerunwaylights.intensityLevel,
            length: values.rwylights[editIndex].edgerunwaylights.length
              ? `${values.rwylights[editIndex].edgerunwaylights.length} M`
              : "",
            spacing: values.rwylights[editIndex].edgerunwaylights.spacing
              ? `${values.rwylights[editIndex].edgerunwaylights.spacing} M`
              : "",
          },
          endrunwaylights: {
            colour: values.rwylights[editIndex].endrunwaylights.colour,
          },
          stopwaylights: {
            colour: values.rwylights[editIndex].stopwaylights.colour,
            length: values.rwylights[editIndex].stopwaylights.length
              ? `${values.rwylights[editIndex].stopwaylights.length} M`
              : "",
          },
          thrrunwaylights: {
            colour: values.rwylights[editIndex].thrrunwaylights.colour,
            wingbar: values.rwylights[editIndex].thrrunwaylights.wingbar,
          },
          visualglideslopeindicators: {
            minimumEyeHeightOverThreshold: values.rwylights[editIndex]
              .visualglideslopeindicators.minimumEyeHeightOverThreshold
              ? `${values.rwylights[editIndex].visualglideslopeindicators.minimumEyeHeightOverThreshold} FT`
              : "",
            position:
              values.rwylights[editIndex].visualglideslopeindicators.position,
            slopeAngle: values.rwylights[editIndex].visualglideslopeindicators
              .slopeAngle
              ? `${values.rwylights[editIndex].visualglideslopeindicators.slopeAngle} DEG`
              : "",
            type: values.rwylights[editIndex].visualglideslopeindicators.type,
          },
          remarks: values.rwylights[editIndex].remarks,
        };
        if (isEmpty(payload)) return;
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "rwylights",
              payload,
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditId(null);
                setEditIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      }
    },
  });

  const handleEditAprondetail = (id) => {
    setEditId(id);
  };

  const handleEditDoneApron = (editId, index) => {
    setEditId(editId);
    setEditIndex(index);
  };

  return (
    <Paper className="add-airport-data-form">
      {!isEmpty(AD212RWYDetail) && AD212RWYDetail.length > 0 ? (
        <FormikProvider value={createAd214Form}>
          <form
            onSubmit={createAd214Form.handleSubmit}
            style={{ height: "calc(100% - 0px)" }}
          >
            <Box sx={{ height: "calc(100% - 10px)", overflow: "auto", ml: 2 }}>
              <FieldArray
                name="rwylights"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {createAd214Form.values.rwylights.map((_, index) => {
                        const isTouched = createAd214Form.touched?.rwylights
                          ? createAd214Form.touched?.rwylights[index]
                          : false;
                        const isErrors = createAd214Form.errors?.rwylights
                          ? createAd214Form.errors?.rwylights[index]
                          : false;

                        const ddIds = !isEmpty(AAIAirport)
                          ? Array.isArray(AAIAirport) &&
                            AAIAirport.map((data) => data.rd)
                          : [];

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={styles.adVORMain}>
                                <Typography
                                  variant="span"
                                  sx={{ width: "85%", display: "inline-block" }}
                                >
                                  Runway lighting {index + 1} (Designator:
                                  {createAd214Form.values.rwylights[index].rd})
                                </Typography>
                                <Box sx={styles.adFuelDeleteBtn}>
                                  {!isEmpty(AAIAirport) &&
                                    createAd214Form.values.rwylights[index]
                                      .rd &&
                                    (editId === ddIds[index] ? (
                                      <button
                                        type="button"
                                        style={styles.ad28Btn}
                                        onClick={() => {
                                          createAd214Form.handleSubmit();
                                          handleEditDoneApron(editId, index);
                                        }}
                                        disabled={isLoadingAddAAIAirportDetail}
                                      >
                                        Done
                                      </button>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0 }}
                                        onClick={() =>
                                          handleEditAprondetail(ddIds[index])
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    ))}
                                </Box>
                              </Box>
                            </Stack>
                            <input
                              hidden
                              name={`rwylights[${index}].rd`}
                              onChange={createAd214Form.handleChange}
                              value={createAd214Form.values.rwylights[index].rd}
                            />
                            <Box sx={styles.ad214Label}>
                              Type, length and intensity of approach lighting
                              system
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Class ICAO</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][approachlightingsystems][classICAO]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .approachlightingsystems?.classICAO
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .approachlightingsystems?.classICAO &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .approachlightingsystems?.classICAO
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .approachlightingsystems?.classICAO
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Intensity Level</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][approachlightingsystems][intensityLevel]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index].asda
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .approachlightingsystems.intensityLevel &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .approachlightingsystems.intensityLevel
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .approachlightingsystems
                                          .intensityLevel
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][approachlightingsystems][length]`}
                                  type="number"
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .approachlightingsystems.length
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .approachlightingsystems.length &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .approachlightingsystems.length
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .approachlightingsystems.length
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Runway threshold lights, colour and wing bars
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Colour</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][thrrunwaylights][colour]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .thrrunwaylights.colour
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .thrrunwaylights.colour &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .thrrunwaylights.colour
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .thrrunwaylights.colour
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Wingbar</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][thrrunwaylights][wingbar]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .thrrunwaylights.wingbar
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .thrrunwaylights.wingbar &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .thrrunwaylights.wingbar
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .thrrunwaylights.wingbar
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Type of visual slope indicator system
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Minimum Eye Height Over Threshold
                              </Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][visualglideslopeindicators][minimumEyeHeightOverThreshold]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        FT
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .visualglideslopeindicators
                                      .minimumEyeHeightOverThreshold
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .visualglideslopeindicators
                                      .minimumEyeHeightOverThreshold &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .visualglideslopeindicators
                                        .minimumEyeHeightOverThreshold
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .visualglideslopeindicators
                                          .minimumEyeHeightOverThreshold
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Position</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][visualglideslopeindicators][position]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .visualglideslopeindicators.position
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .visualglideslopeindicators.position &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .visualglideslopeindicators.position
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .visualglideslopeindicators.position
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Slope Angle</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][visualglideslopeindicators][slopeAngle]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        DEG
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .visualglideslopeindicators.slopeAngle
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .visualglideslopeindicators.slopeAngle &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .visualglideslopeindicators.slopeAngle
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .visualglideslopeindicators.slopeAngle
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Type</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][visualglideslopeindicators][type]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .visualglideslopeindicators.type
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .visualglideslopeindicators.type &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .visualglideslopeindicators.type
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .visualglideslopeindicators.type
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Length, spacing, colour and intensity of runway
                              centre line lights
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Colour</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][clrunwaylights][colour]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .clrunwaylights.colour
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .clrunwaylights.colour &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .clrunwaylights.colour
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .clrunwaylights.colour
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Intensity Level</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][clrunwaylights][intensityLevel]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .clrunwaylights.intensityLevel
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .clrunwaylights.intensityLevel &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .clrunwaylights.intensityLevel
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .clrunwaylights.intensityLevel
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][clrunwaylights][length]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .clrunwaylights.length
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .clrunwaylights.length &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .clrunwaylights.length
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .clrunwaylights.length
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Spacing</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][clrunwaylights][spacing]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .clrunwaylights.spacing
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .clrunwaylights.spacing &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .clrunwaylights.spacing
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .clrunwaylights.spacing
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Length, spacing, colour and intensity of runway
                              edge lights
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Colour</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][edgerunwaylights][colour]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .edgerunwaylights.colour
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .edgerunwaylights.colour &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .edgerunwaylights.colour
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .edgerunwaylights.colour
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Intensity Level</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][edgerunwaylights][intensityLevel]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .edgerunwaylights.intensityLevel
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .edgerunwaylights.intensityLevel &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .edgerunwaylights.intensityLevel
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .edgerunwaylights.intensityLevel
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][edgerunwaylights][length]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .edgerunwaylights.length
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .edgerunwaylights.length &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .edgerunwaylights.length
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .edgerunwaylights.length
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Spacing</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][edgerunwaylights][spacing]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .edgerunwaylights.spacing
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .edgerunwaylights.spacing &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .edgerunwaylights.spacing
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .edgerunwaylights.spacing
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Colour of runway end lights and wing bars
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Colour</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][endrunwaylights][colour]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .endrunwaylights.colour
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .endrunwaylights.colour &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .endrunwaylights.colour
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .endrunwaylights.colour
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Box sx={styles.ad214Label}>
                              Length and colour of stopway lights
                            </Box>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Colour</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`rwylights[${index}][stopwaylights][colour]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .stopwaylights.colour
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .stopwaylights.colour &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .stopwaylights.colour
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .stopwaylights.colour
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Length</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`rwylights[${index}][stopwaylights][length]`}
                                  InputProps={{
                                    readOnly: !createAd214Form.values.rwylights[
                                      index
                                    ].rd
                                      ? false
                                      : !Boolean(
                                          ddIds[index] === editId && editId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        M
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={
                                    createAd214Form.values.rwylights[index]
                                      .stopwaylights.length
                                  }
                                  onChange={createAd214Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .stopwaylights.length &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .stopwaylights.length
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .stopwaylights.length
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: "100%" }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd214Form.touched?.rwylights[index]
                                      .remarks &&
                                    Boolean(
                                      createAd214Form.errors?.rwylights[index]
                                        .remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    id={`rwylights[${index}].remarks`}
                                    name={`rwylights[${index}].remarks`}
                                    value={
                                      createAd214Form.values.rwylights[index]
                                        .remarks
                                    }
                                    onChange={createAd214Form.handleChange}
                                    aria-describedby="aerodrome-dd-remarks"
                                    readOnly={
                                      !createAd214Form.values.rwylights[index]
                                        .rd
                                        ? false
                                        : !Boolean(
                                            ddIds[index] === editId && editId
                                          )
                                    }
                                  />
                                  <FormHelperText id="aerodrome-dd-remarks">
                                    {isErrors && isTouched
                                      ? createAd214Form.errors?.rwylights[index]
                                          .remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Divider sx={{ mb: 1 }} />
                          </Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </Box>
          </form>
        </FormikProvider>
      ) : (
        <Box sx={styles.ad214RwyLabel}>Runway detail not added.</Box>
      )}
    </Paper>
  );
}

export default Ad214Form;
