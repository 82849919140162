import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { Button, Menu, MenuItem } from "@mui/material";
import { userType, userLabel } from "@constants/constant";
import { theme } from "@styles/theme";

export default function CreateUserButton({
  handleOpenAddDialog,
  classname = "",
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUser = (userType) => {
    handleClose();
    handleOpenAddDialog(userType);
  };

  return (
    <div>
      <Button
        id="user-create-button"
        aria-controls={open ? "user-create-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        className={classname}
        onClick={handleClick}
        sx={{ width: 142, textTransform: "none", mt: 2 }}
      >
        Add New User
      </Button>
      <StyledMenu
        id="user-create-menu"
        MenuListProps={{
          "aria-labelledby": "user-create-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleUser(userType.aai)}
          sx={{ borderBottom: `1px solid ${theme.palette.grey.main}` }}
        >
          {userLabel.AAI_USER}
        </MenuItem>
        <MenuItem onClick={() => handleUser(userType.surveyor)}>
          {userLabel.SURVEYOR}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 240,
    left: "177px !important",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
