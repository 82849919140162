import React from "react";

const AixmIcon = (props) => {
  return (
    <svg
      width={props.width || "66"}
      height={props.height || "66"}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.2574 29.0938L18.8291 31.97C18.7914 31.985 18.7572 32.0077 18.7288 32.0367C18.7003 32.0657 18.6783 32.1003 18.6641 32.1384L16.4253 38.1715C16.4063 38.2226 16.4022 38.278 16.4134 38.3313C16.4245 38.3847 16.4505 38.4337 16.4884 38.4729C16.5262 38.5121 16.5744 38.5398 16.6273 38.5528C16.6802 38.5658 16.7357 38.5636 16.7874 38.5464L30.1316 33.6425L30.886 40.6815L26.7531 45.5118C26.7449 45.5213 26.7392 45.5327 26.7364 45.5449C26.7336 45.5572 26.7338 45.5699 26.7371 45.582L27.5236 48.5319C27.527 48.5448 27.5337 48.5565 27.543 48.5661C27.5524 48.5756 27.564 48.5826 27.5768 48.5863C27.5896 48.59 27.6032 48.5903 27.6162 48.5872C27.6291 48.5841 27.6411 48.5777 27.6509 48.5687L32.947 43.6714C32.9614 43.658 32.9802 43.6506 32.9998 43.6506C33.0195 43.6506 33.0383 43.658 33.0527 43.6714L38.3488 48.5687C38.3586 48.5777 38.3706 48.5841 38.3835 48.5872C38.3965 48.5903 38.4101 48.59 38.4229 48.5863C38.4357 48.5826 38.4473 48.5756 38.4567 48.5661C38.466 48.5565 38.4727 48.5448 38.4761 48.5319L39.2626 45.582C39.2659 45.5699 39.2661 45.5572 39.2633 45.5449C39.2605 45.5327 39.2548 45.5213 39.2466 45.5118L35.1028 40.6843"
        stroke="#333333"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.4427 27.1577L28.9976 21.5909C28.9976 20.5308 29.4187 19.5141 30.1683 18.7645C30.9179 18.0149 31.9346 17.5938 32.9947 17.5938C34.0548 17.5938 35.0715 18.0149 35.8211 18.7645C36.5707 19.5141 36.9918 20.5308 36.9918 21.5909L36.542 27.2119L47.1706 31.9686C47.2083 31.9836 47.2425 32.0063 47.2709 32.0353C47.2994 32.0643 47.3214 32.0989 47.3356 32.137L49.5744 38.1692C49.5934 38.2203 49.5975 38.2757 49.5863 38.329C49.5752 38.3823 49.5492 38.4314 49.5113 38.4706C49.4735 38.5098 49.4253 38.5375 49.3724 38.5505C49.3195 38.5635 49.264 38.5612 49.2123 38.5441L35.8554 33.6369L35.5461 36.5245"
        stroke="#333333"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M57.5632 12.4889C51.6931 5.46682 42.8684 1 33 1C15.3269 1 1 15.3269 1 33C1 35.185 1.21901 37.3191 1.63629 39.3812M64.1484 25.6349C64.7052 27.9995 65 30.4652 65 33C65 50.673 50.673 65 33 65C22.8188 65 13.748 60.2452 7.88725 52.8353"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M59.2855 5V13.5714H50.7141"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2855 52.4297H6.71411V61.0011"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AixmIcon;
