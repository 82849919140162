import { isEmpty } from "lodash";

export const airspaceType = {
  firuir: "FIR / UIR",
  adiz: "ADIZ",
  restrictedAirspace: "Restricted Airspace",
  controlledAirspace: "Controlled Airspace",
  rnav: "Area Navigational Routes",
  cnav: "Conventional Routes",
  navaids: "Navaids",
  wayPoints: "Way Points",
  wayPointRoutes: "Way Point Routes",
};

export const airspaceFilterType = {
  firuir: "FIR / UIR",
  tma: "TMA",
  controlArea: "Control Area",
  otherRegulatedAirspace: "Other Regulated Airspace",
  routes: "Routes",
  radioNavigation: "Radio Navigation",
  significantPoints: "Significant Points",
  prohibitedRestrictedandDangerAreas: "Prohibited, Restricted and Danger Areas",
  militaryExecriseandTrainingAreas: "Military Execrise and Training Areas",
};

export const airspaceFilterSortType = {
  "FIR / UIR": "FIR",
  TMA: "TMA",
  "Control Area": "CTA",
  "Other Regulated Airspace": "ATZ_CTR",
  "Radio Navigation": "Navaids",
  "Significant Points": "SignificantPoints",
};

export const FIRUIRKeyMap = {
  "Mumbai FIR": "Mumbai FIR",
  "Kolkata FIR": "Kolkata FIR",
  "Delhi FIR": "Delhi FIR",
  "Chennai FIR": "Chennai Flight Information Region",
  "Guwahati Sub FIR": "Guwahati SUB FIR",
  "Under Colombo FIR": "Under Colombo FIR",
  "Kolkata Bangladesh": "Kolkata Bangladesh",
  "Under Udhampur ACC": "Under Udhampur ACC",
};

export const ADIZKeyMap = {
  South: "ADIZ South",
  Subsoutheast: "Sub ADIZ South-East",
  Central: "ADIZ Central",
  West: "ADIZ West",
  East: "ADIZ East",
  North: "ADIZ North",
};

export const airspaceFilterOptions = [
  {
    id: 2.1,
    label: "2.1 - FIR / UIR",
    value: "FIR / UIR",
    enr: "ENR2",
    isEnrApi: false,
  },
  {
    id: 2.2,
    label: "2.1 - TMA",
    value: "TMA",
    enr: "ENR2",
    isEnrApi: false,
  },
  {
    id: 2.3,
    label: "2.1 - Control Area",
    value: "Control Area",
    enr: "ENR2",
    isEnrApi: false,
  },
  {
    id: 2.4,
    label: "2.2 - Other Regulated Airspace",
    value: "Other Regulated Airspace",
    enr: "ENR2",
    isEnrApi: false,
  },
  {
    id: 3,
    label: "3 - Routes",
    value: "Routes",
    enr: "ENR3",
    isEnrApi: false,
  },
  {
    id: 4.1,
    label: "4.1 - Radio Navigation Aids - En Route",
    value: "Radio Navigation",
    enr: "ENR4",
    isEnrApi: false,
  },
  {
    id: 4.4,
    label: "4.4 - Name Code Designators For Significant Points",
    value: "Significant Points",
    enr: "ENR4",
    isEnrApi: false,
  },
  {
    id: 5.1,
    label: "5.1 - Prohibited, Restricted and Danger Areas",
    value: "Prohibited, Restricted and Danger Areas",
    enr: "ENR5",
    isEnrApi: false,
  },
  {
    id: 5.2,
    label: "5.2 - Military Execrise and Training Areas",
    value: "Military Execrise and Training Areas",
    enr: "ENR5",
    isEnrApi: false,
  },
];

export const airspaceOptions = [
  {
    id: 1,
    label: "Restricted Airspace",
    value: "Restricted Airspace",
  },
  {
    id: 2,
    label: "Controlled Airspace",
    value: "Controlled Airspace",
  },
  {
    id: 3,
    label: "FIR / UIR",
    value: "FIR / UIR",
  },
  {
    id: 4,
    label: "ADIZ",
    value: "ADIZ",
  },
  {
    id: 5,
    label: "Area Navigational Routes",
    value: "Area Navigational Routes",
  },
  {
    id: 6,
    label: "Conventional Routes",
    value: "Conventional Routes",
  },
  {
    id: 7,
    label: "Navaids",
    value: "Navaids",
  },
  {
    id: 8,
    label: "Way Points",
    value: "Way Points",
  },
];

export const DPRTypeFilterOptions = [
  {
    id: 1,
    label: "D",
    value: "D",
  },
  {
    id: 2,
    label: "P",
    value: "P",
  },
  {
    id: 3,
    label: "R",
    value: "R",
  },
];

export const airspaceSelectExportType = [
  { id: 1, label: "FIR / UIR", value: "FIR" },
  { id: 2, label: "ADIZ", value: "ADIZ" },
  {
    id: 3,
    label: "Prohibited, Restricted and Danger Airspace",
    value: "DPR",
  },
  {
    id: 4,
    label: "Military Exercise and Training Airspace",
    value: "Execrise",
  },
  { id: 5, label: "Controlled Airspace", value: "Ctrl" },
  { id: 6, label: "Conventional Routes", value: "CNAV" },
  { id: 7, label: "Area Navigational Routes", value: "RNAV" },
  { id: 8, label: "Navaids", value: "Navaids" },
  { id: 9, label: "Reporting Points", value: "Waypoints" },
];

export const airspaceSelectExportTypeExcept = ["ADIZ", "DPR", "RNAV", "Ctrl"];

export const aupAfterStyle = (lowerFl) => {
  if (isEmpty(lowerFl)) return {};

  return {
    content: `'Lower FL - ${lowerFl}'`,
    color: "#42444A",
    position: "absolute",
    top: "11px",
    left: "40px",
    width: 140,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "start",
  };
};

export const aupBeforeStyle = (upperFl) => {
  if (isEmpty(upperFl)) return {};

  return {
    content: `'Upper FL - ${upperFl}'`,
    color: "#42444A",
    position: "absolute",
    top: "-39px",
    left: "40px",
    width: 140,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "start",
  };
};

export const activeTrackStyle = {
  backgroundColor: "#27ae60",
  height: 7,
  top: 3,
};

export const activeHandleStyle = {
  backgroundColor: "#27ae60",
};

export const activeMilitryTrackStyle = {
  backgroundColor: "#E91414",
  height: 7,
  top: 3,
};

export const activeMilitryHandleStyle = {
  backgroundColor: "#E91414",
};

export const deactiveTrackStyle = {
  backgroundColor: "#515151",
};

export const routePlanNavtype = {
  CONV: "Conventional Routes",
  RNAV: "Area Navigational Routes",
};

export const ENRshapePane = {
  P: "overlayPane",
  D: "overlayPane",
  R: "overlayPane",
  TRA: "shadowPane",
  TSA: "shadowPane",
  MTR: "markerPane",
  TMA: "markerPane",
  ADIZ: "tilePane",
};
