import React, { Fragment, useEffect, useState } from "react";
import { orderBy, filter } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Checkbox,
  TableBody,
} from "@mui/material";
import { PrimaryButton } from "@components/button";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import DiffTableCell from "@components/tabel-component/DiffTableCell";
import FullContentLoader from "@components/loader/FullContentLoader";
// import TableLoader from "@components/tabel-component/TableLoader";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import ValueItem from "@components/tabel-component/ValueItem";
import { surveyReportStatus } from "@constants/surveyorData";
import { dataChangedRowColorMap } from "@constants/colors";
import { theme } from "@styles/theme";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import DataTableFooterRow from "./DataTableFooterRow";
import ObstacleAcceptTable from "./ObstacleAcceptTable";
import ObstacleInReviewTable from "./ObstacleInReviewTable";

export default function ObstacleReviewTable({
  isLoadingVerification,
  isLoadingCompareData,
  selected,
  selectedReport,
  surveyReportDetail,
  handleSelectAllClick,
  handleClick,
  handleAccept,
  handleReview,
}) {
  const [isOpenReviewModal, setOpenReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const notVerified = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.notVerified,
  ]);
  const acceptedData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.accepted,
  ]);
  const inReviewData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.inReview,
  ]);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    if (selected.length === 0) {
      setOpenReviewModal(false);
    }

    const initReviewData = filter(notVerified, (d) =>
      selected.includes(d.id1)
    ).map((d) => ({
      ...d,
      id: d.id1,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    setReviewData(initReviewData);
  }, [selected]); // eslint-disable-line

  const handleCellClick = (key, id) => {
    const updateReviewData = reviewData.map((review) => {
      if (id === review.id1) {
        return {
          ...review,
          [key]: !review[key],
        };
      }

      return review;
    });

    setReviewData(updateReviewData);
  };

  const handleSubmitReviewData = () => {
    const rData = reviewData.map((r) => ({
      id: r.id,
      latitude: r.latitude,
      longitude: r.longitude,
      top_elve: r.top_elve,
    }));

    handleReview(rData);
  };

  const handleCloseInReviewModal = () => {
    setOpenReviewModal(false);
  };

  return (
    <Fragment>
      {/* <SurveyorTopTableRow
        serveyorName={
          selectedReport ? decryptStatic(selectedReport?.user_name, aesKey) : ""
        }
        userId={selectedReport?.user_empid}
      />
      
      <AirportDetailTableRow selectedReport={selectedReport} /> */}
      <div style={{ marginTop: "78px" }} />
      {/* {(isLoadingCompareData || surveyReportDetail.length === 0) && (
        <TableLoader
          isLoading={isLoadingCompareData}
          data={surveyReportDetail}
        />
      )} */}
      {isLoadingCompareData && surveyReportDetail.length > 0 && (
        <FullContentLoader />
      )}

      <TableContainer sx={{ width: "100%" }}>
        {notVerified.length > 0 && (
          <Fragment>
            {selected.length > 0 && (
              <EnhancedTableToolbar numSelected={selected.length} />
            )}
            <Table
              stickyHeader
              size="small"
              sx={{
                mb: 10,
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < notVerified.length
                      }
                      checked={
                        notVerified.length > 0 &&
                        selected.length === notVerified.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    UID
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "2vw" }}>
                    <Box>LATITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "2vw" }}>
                    <Box>LONGITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Affected Area</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Point Name</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>TOP ELEV. IN m. (EGM 08)</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                        <StyledBoxV2>DIFF</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>UOM Elevation</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Vertical Datum</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Horizontal Datum</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Object</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ width: "1vw" }}>
                    <Stack>
                      <Box>Marking lgt</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(notVerified, ["uid1"], ["asc"]).map((row, i) => {
                  const isItemSelected = isSelected(row.id1);
                  const labelId = `enhanced-table-checkbox-${i}`;

                  return (
                    <TableRow
                      key={i}
                      hover
                      onClick={() => handleClick(row.id1)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        background:
                          dataChangedRowColorMap[row.result] ??
                          theme.palette.white.main,
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      <DiffTableCell>{row["uid1"]}</DiffTableCell>
                      <DiffTableCell isdiff={row["latdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["latitude1"]} />
                          <ValueItem value={row["latitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell isdiff={row["longdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["longitude1"]} />
                          <ValueItem value={row["longitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["affected_areadev"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["affected_area1"]} />
                          <ValueItem value={row["affected_area2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["point_name1"]} />
                          <ValueItem value={row["point_name2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["top_elevdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["top_elev1"]} />
                          <ValueItem value={row["top_elev2"]} />
                          <ValueItem value={row["top_elevdiff"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["uom_elevation1"]} />
                          <ValueItem value={row["uom_elevation2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["vertical_datum1"]} />
                          <ValueItem value={row["vertical_datum2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["horizontal_datum1"]} />
                          <ValueItem value={row["horizontal_datum2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["object1"]} />
                          <ValueItem value={row["object2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell align="center">
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["marking_lgt1"]} />
                          <ValueItem value={row["marking_lgt1"]} />
                        </Stack>
                      </DiffTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {acceptedData.length > 0 && <ObstacleAcceptTable data={acceptedData} />}
        {inReviewData.length > 0 && (
          <ObstacleInReviewTable data={inReviewData} />
        )}
      </TableContainer>
      <Box
        sx={{
          position: "absolute",
          zIndex: 111,
          bottom: 0,
          width: "100%",
          backgroundColor: "#f3f3f3",
        }}
      >
        {notVerified.length > 0 && (
          <DataTableFooterRow
            isLoadingVerification={isLoadingVerification}
            selectedReport={selectedReport}
            selected={selected}
            handleAccept={handleAccept}
            handleReview={() => setOpenReviewModal(true)}
          />
        )}
      </Box>
      <Dialog fullWidth open={isOpenReviewModal} maxWidth="xl">
        <DialogTitle>
          <Box sx={{ mb: -2 }}>In Review Data</Box>
          <Typography variant="caption">
            Select the data set where deviation is found before submit.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  UID
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "2vw" }}>
                  <Box>LATITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "2vw" }}>
                  <Box>LONGITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Affected Area</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Point Name</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>TOP ELEV. IN m. (EGM 08)</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                      <StyledBoxV2>DIFF</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>UOM Elevation</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Vertical Datum</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Horizontal Datum</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Object</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "1vw" }}>
                  <Stack>
                    <Box>Marking lgt</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(reviewData, ["uid1"], ["asc"]).map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      background:
                        dataChangedRowColorMap[row.result] ??
                        theme.palette.white.main,
                    }}
                  >
                    <DiffTableCell>{row["uid1"]}</DiffTableCell>
                    <DiffTableCell
                      isdiff={row["latdev"]}
                      isselected={row.latitude.toString()}
                      onClick={() => handleCellClick("latitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["latitude1"]} />
                        <ValueItem value={row["latitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      isdiff={row["longdev"]}
                      isselected={row.longitude.toString()}
                      onClick={() => handleCellClick("longitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["longitude1"]} />
                        <ValueItem value={row["longitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["affected_areadev"])}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["affected_area1"]} />
                        <ValueItem value={row["affected_area2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["point_name1"]} />
                        <ValueItem value={row["point_name2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["top_elevdiff"])}
                      isselected={row.top_elve.toString()}
                      onClick={() => handleCellClick("top_elve", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["top_elev1"]} />
                        <ValueItem value={row["top_elev2"]} />
                        <ValueItem value={row["top_elevdiff"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["uom_elevation1"]} />
                        <ValueItem value={row["uom_elevation2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["vertical_datum1"]} />
                        <ValueItem value={row["vertical_datum2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["horizontal_datum1"]} />
                        <ValueItem value={row["horizontal_datum2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["object1"]} />
                        <ValueItem value={row["object2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell align="center">
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["marking_lgt1"]} />
                        <ValueItem value={row["marking_lgt1"]} />
                      </Stack>
                    </DiffTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            size="small"
            label="Cancel"
            onClick={handleCloseInReviewModal}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
          <PrimaryButton
            label="Submit"
            size="small"
            onClick={handleSubmitReviewData}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
