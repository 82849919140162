import React, { Fragment } from "react";
import { Marker } from "react-leaflet";
import { ReportingPointImgIcon } from "@utils/mapIcon";

export default function RenderSignificantBufferPointsAirspace({
  wayPointsAirspaceGeometry,
}) {
  return (
    <Fragment>
      {wayPointsAirspaceGeometry.map((waypoint, i) => {
        return (
          <Marker
            key={i}
            pane="popupPane"
            icon={ReportingPointImgIcon}
            position={{
              lat: waypoint.dd_latitude,
              lng: waypoint.dd_longitude,
            }}
          />
        );
      })}
    </Fragment>
  );
}
