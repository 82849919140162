import React from 'react';
import {Table, TableBody, TableCell, TableRow} from '@mui/material';

const TableLoader = ({ isLoading, data, colSpan = 2 }) => {
  return (
    <Table>
      <TableBody>
        {isLoading && (
          <TableRow>
            <TableCell align="center" colSpan={colSpan} sx={{ fontWeight: 'bold' }}>
              Loading...
            </TableCell>
          </TableRow>
        )}
        {!isLoading && data?.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={colSpan}>
              No Record Found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TableLoader;