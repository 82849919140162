import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { OutlinedButton, PrimaryButton } from "@components/button";

export default function ConfirmationDialog2({
  title,
  content,
  saveButtonLabel = "Save",
  saveButtonColor = "primary",
  cancelText = "Cancel",
  isLoading = false,
  isDisabled = false,
  handleSave,
  handleCancel,
  ...props
}) {
  return (
    <Dialog {...props} className="confirmation-dialog-2">
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <PrimaryButton
          label={saveButtonLabel}
          onClick={handleSave}
          color={saveButtonColor}
          disabled={isDisabled}
          isLoading={isLoading}
          sx={{ minWidth: 85 }}
        />
        <OutlinedButton
          label={cancelText}
          onClick={handleCancel}
          disabled={isDisabled}
          sx={{ minWidth: 85 }}
        />
      </DialogActions>
    </Dialog>
  );
}
