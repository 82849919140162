import { createSelector } from "@reduxjs/toolkit";
import {
  chain,
  orderBy,
  uniqBy,
  isEmpty,
  keys,
  filter,
  includes,
  find,
  uniq,
  flatten,
} from "lodash";
import { FIRUIRKeyMap, ADIZKeyMap } from "@constants/airspaceData";
import { airspace } from "./slice";

export const {
  setFIRdataLoadingRequest,
  setTMAdataLoadingRequest,
  setCTAdataLoadingRequest,
  setRegulatedAirspaceDataLoadingRequest,
  setRestrictiveAirspaceGeometryLoadingRequest,
  setFIRDetails,
  setTMADetails,
  setCTADetails,
  setRegulatedAirspaceDetails,
  setRestrictiveAirspaceGeometryData,
  setFIRAirspaceGeometryLoadingRequest,
  setFIRAirspaceGeometryData,
  setLoadingGetAllRoute,
  setAllRoutes,
  setLoadingGetRouteData,
  setRouteData,
  setLoadingGetAllNavaid,
  setAllNavaid,
  setLoadingGetAllsignificantPoint,
  setAllsignificantPoint,
  setLoadingGetAllDPR,
  setAllDPR,
  setLoadingGetAllTRA_TSA_ADIZ,
  setAllTRA_TSA_ADIZ,
  setLoadingGetADIZGeoData,
  setADIZGeoData,
  setLoadingRestrictiveAirspaceDetails,
  setRestrictiveAirspaceDetails,
  setLoadingRNAVRoutesGeometry,
  setLoadingCNAVRoutesGeometry,
  setLoadingRNAVRoutes,
  setRNAVRoutesData,
  setLoadingCNAVRoutes,
  setCNAVRoutesData,
  setLoadingAreaNavigationalRouteData,
  setAreaNavigationalRouteData,
  setLoadingConventionalRouteData,
  setConventionalRouteData,
  setLoadingAirspaceExport,
  setLoadingWaypointRouteData,
  setWaypointRouteData,
  setLoadingEnrouteExportJson,
  setAirspaceUsePlanDataLoading,
  setAirspaceUsePlanDates,
  setAirspaceUsePlanDataByIdLoading,
  setMilitryAirspaceUsePlanData,
  setRouteAirspaceUsePlanData,
  setConventionalLiveTrackRouteData,
  setAreaNavigationalLiveTrackRouteData,
  setLoadingRoutePlanValidation,
  setAirspaceRouteBufferPointsLoading,
  setArrivalDepartureAirportRoutePlanLoading,
  setArrvalDepAirportRouteData,
  setRouteThroughPointRPLoading,
  setRouteThroughPointRPData,
  setENRAirspaceGeometryLoading,
  setENRAirspaceGeometryFetchLoading,
  setENRAirspaceGeometryInfoLoading,
  setENRAirspaceGeometryOptions,
  setENRAirspaceGeometryInfoData,
  setENRTMACoordsData,
  setENRMTRCoordsData,
  setENRPDRCoordsData,
  setENROTHERCoordsData,
  setENRADIZRCoordsData,
  setENRTRATSACoordsData,
  setENRAD217CoordsData,
  setCTRLAirspaceGeometryData,
} = airspace.actions;

export default airspace.reducer;

const selectSearchQuery = (state, searchQuery) => searchQuery;
const selectAllTMAData = (state) => state.airspace.TMAData;
const selectAllFIRData = (state) => state.airspace.FIRData;
const selectAllCTAData = (state) => state.airspace.CTAData;
const selectAllRestrictiveAirspaceGeometry = (state) =>
  state.airspace.restrictiveAirspaceGeometry;
const selectAllRegulatedAirspaceData = (state) =>
  state.airspace.regulatedAirspaceData;
const selectAllFIRAirspaceData = (state) => state.airspace.FIRAirspaceGeometry;
const FIRAUPSearchQuery = (state, firAUPSearchQuery) => firAUPSearchQuery;
const FIRAUPMilitrySearchQuery = (a, b, firAUPSearchQuery) => firAUPSearchQuery;
const selectedMilitryAirspaceUsePlanData = (state) =>
  state.airspace.militryAirspaceUsePlanData;
const selectedRouteAirspaceUsePlanData = (state) =>
  state.airspace.routeAirspaceUsePlanData;

export const selectFIRData = createSelector(
  [selectAllFIRData, selectSearchQuery],
  (firData, searchQuery) => {
    if (searchQuery) {
      return chain(firData)
        .filter((fir) => {
          return fir.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(firData, ["name"], ["asc"]);
  }
);

const selectFindQuery = (state, findQuery) => findQuery;

export const findFIRData = createSelector(
  [selectAllFIRData, selectFindQuery],
  (firData, findQuery) => {
    if (findQuery) {
      const name = FIRUIRKeyMap[`${findQuery}`];

      return firData.find(
        (fir) => fir?.name && fir?.name.toLowerCase() === name.toLowerCase()
      );
    }

    return null;
  }
);

export const selectTMAData = createSelector(
  [selectAllTMAData, selectSearchQuery],
  (tmaData, searchQuery) => {
    if (searchQuery) {
      return chain(tmaData)
        .filter((tma) => {
          return tma.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(tmaData, ["name"], ["asc"]);
  }
);

export const selectCTAData = createSelector(
  [selectAllCTAData, selectSearchQuery],
  (ctaData, searchQuery) => {
    if (searchQuery) {
      return chain(ctaData)
        .filter((cta) => {
          return cta.name.toLowerCase().includes(searchQuery);
        })
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(ctaData, ["name"], ["asc"]);
  }
);

export const selectRegulatedAirspaceData = createSelector(
  [selectAllRegulatedAirspaceData, selectSearchQuery],
  (raData, searchQuery) => {
    if (searchQuery) {
      return chain(raData)
        .filter((ra) => {
          return ra.Aerodrome.toLowerCase().includes(searchQuery);
        })
        .orderBy(["Aerodrome"], ["asc"])
        .value();
    }

    return orderBy(raData, ["Aerodrome"], ["asc"]);
  }
);

export const selectRestrictiveAirspaceGeometry = createSelector(
  [selectAllRestrictiveAirspaceGeometry, selectSearchQuery],
  (restrictiveAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(
          uniqBy(restrictiveAirspaceGeometry, "name"),
          ["name"],
          ["asc"]
        );
      }

      return chain(restrictiveAirspaceGeometry)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectRegulatedAirspaceOption = (state) => {
  const restrictiveAirspaceData = state.airspace.restrictiveAirspaceGeometry;

  if (restrictiveAirspaceData.length > 0) {
    return orderBy(uniqBy(restrictiveAirspaceData, "name"), ["name"], ["asc"]);
  }

  return [];
};

const searchQueryFIR = (a, b, fir) => fir;
export const selectPDRAirspaceGeometry = createSelector(
  [selectAllRestrictiveAirspaceGeometry, selectSearchQuery, searchQueryFIR],
  (restrictiveAirspaceGeometry, searchQuery, selectedfir) => {
    if (searchQuery.length > 0) {
      const firDesignations = [];
      const serachValue = searchQuery.map((s) => s.name);
      selectedfir.forEach((fir) => {
        if (fir.designation === "All") {
          if (fir.designation) {
            firDesignations.push(fir.designation);
          }
          return;
        }
        if (fir.designation) {
          const code = fir.designation.substring(0, 2);
          firDesignations.push(code);
        }
      });
      const pdrAirspaceGeometry = restrictiveAirspaceGeometry.filter((a) => {
        if (firDesignations.length > 0 && !firDesignations.includes("All")) {
          return (
            ["P", "D", "R"].includes(a.type) && firDesignations.includes(a.fir)
          );
        }

        return ["P", "D", "R"].includes(a.type);
      });

      if (serachValue.includes("All")) {
        return orderBy(uniqBy(pdrAirspaceGeometry, "name"), ["name"], ["asc"]);
      }

      return chain(pdrAirspaceGeometry)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

const searchOptionFIR = (a, fir) => fir;
export const selectPDRAirspaceOption = createSelector(
  [selectAllRestrictiveAirspaceGeometry, searchOptionFIR],
  (restrictiveAirspaceData, selectedfir) => {
    if (restrictiveAirspaceData.length > 0) {
      const firDesignations = [];

      selectedfir.forEach((fir) => {
        if (fir.designation === "All") {
          if (fir.designation) {
            firDesignations.push(fir.designation);
          }
          return;
        }
        if (fir.designation) {
          const code = fir.designation.substring(0, 2);
          firDesignations.push(code);
        }
      });

      const pdrAirspace = restrictiveAirspaceData.filter((a) => {
        if (firDesignations.length > 0 && !firDesignations.includes("All")) {
          return (
            ["P", "D", "R"].includes(a.type) && firDesignations.includes(a.fir)
          );
        }

        return ["P", "D", "R"].includes(a.type);
      });

      return orderBy(uniqBy(pdrAirspace, "name"), ["name"], ["asc"]);
    }

    return [];
  }
);

export const selectTraTsaAirspaceOption = (state) => {
  const restrictiveAirspaceData = state.airspace.restrictiveAirspaceGeometry;

  if (restrictiveAirspaceData.length > 0) {
    const traTsaAirspace = restrictiveAirspaceData.filter((a) => {
      return ["TRA", "TSA"].includes(a.type);
    });

    return orderBy(uniqBy(traTsaAirspace, "name"), ["name"], ["asc"]);
  }

  return [];
};

const militryAUPlanData = (state) => state.airspace.militryAirspaceUsePlanData;

export const selectMilitryTraTsaAUPOption = createSelector(
  [selectTraTsaAirspaceOption, militryAUPlanData, FIRAUPSearchQuery],
  (traTsaOptionList, militrydata, selectedFIR) => {
    if (traTsaOptionList.length === 0) return [];
    if (isEmpty(selectedFIR)) return [];
    if (Boolean(find(selectedFIR, ["designator", "All"]))) {
      const militryKeys = keys(militrydata);
      if (militryKeys.length === 0) return [];

      return filter(traTsaOptionList, (data) => {
        return includes(militryKeys, data.designator);
      });
    } else if (
      selectedFIR.length > 0 &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const selectedFirIds = selectedFIR.map((data) => {
        return data.id;
      });
      const finddata = [];
      selectedFirIds.map((id) =>
        filter(traTsaOptionList, (data) => {
          if (data.fir_ids.includes(id)) {
            return finddata.push(data);
          }
        })
      );

      const militryKeys = keys(militrydata);
      if (militryKeys.length === 0) return [];
      return filter(finddata, (data) => {
        return includes(militryKeys, data.designator);
      });
    }
  }
);

export const selectTraTsaAirspaceGeometry = createSelector(
  [
    selectAllRestrictiveAirspaceGeometry,
    selectSearchQuery,
    FIRAUPMilitrySearchQuery,
  ],
  (restrictiveAirspaceGeometry, searchQuery, selectedAUPFIR) => {
    if (searchQuery.length > 0) {
      const traTsaAirspace = restrictiveAirspaceGeometry.filter((a) => {
        return ["TRA", "TSA"].includes(a.type);
      });
      const serachValue = searchQuery.map((s) => s.name);
      if (serachValue.includes("All")) {
        if (
          selectedAUPFIR.length > 0 &&
          !Boolean(find(selectedAUPFIR, ["designator", "All"]))
        ) {
          const selectedFirIds = selectedAUPFIR.map((data) => {
            return data.id;
          });
          const finddata = [];
          selectedFirIds.map((id) =>
            filter(uniqBy(traTsaAirspace, "name"), (data) => {
              if (data.fir_ids.includes(id)) {
                return finddata.push(data);
              }
            })
          );

          return orderBy(finddata, ["name"], ["asc"]);
        } else if (Boolean(find(selectedAUPFIR, ["designator", "All"]))) {
          return orderBy(uniqBy(traTsaAirspace, "name"), ["name"], ["asc"]);
        }
        return orderBy(uniqBy(traTsaAirspace, "name"), ["name"], ["asc"]);
      }

      return chain(traTsaAirspace)
        .filter((rag) => {
          return serachValue.includes(rag.name);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectFIRAirspaceGeometry = createSelector(
  [selectAllFIRAirspaceData, selectSearchQuery],
  (FIRAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.name);

      if (serachValue.includes("All")) {
        return orderBy(FIRAirspaceGeometry, ["name"], ["asc"]).map((d) => {
          return {
            ...d,
            type: "Feature",
            properties: { name: d.name },
          };
        });
      }

      const data = chain(FIRAirspaceGeometry)
        .filter((cag) => {
          return serachValue.includes(cag.name);
        })
        .orderBy(["name"], ["asc"])
        .map((d) => {
          return {
            ...d,
            type: "Feature",
            properties: { name: d.name },
          };
        })
        .value();

      return data;
    }

    return [];
  }
);

// export const selectAUPFIRAirspaceGeometry = createSelector(
//   [selectAllFIRAirspaceData, selectSearchQuery],
//   (FIRAirspaceGeometry, searchQuery) => {
//     if (searchQuery) {
//       return chain(FIRAirspaceGeometry)
//         .filter((cag) => {
//           return searchQuery.name === cag.name;
//         })
//         .orderBy(["name"], ["asc"])
//         .value();
//     }

//     return [];
//   }
// );

export const selectFIRAirspaceOption = (state) => {
  const FIRAirspaceData = state.airspace.FIRAirspaceGeometry;

  if (FIRAirspaceData.length > 0) {
    return orderBy(uniqBy(FIRAirspaceData, "name"), ["name"], ["asc"]);
  }

  return [];
};

export const selectAllRoutesOptions = (state) => {
  const allRoutes = state.airspace.allRoutes;

  if (!isEmpty(allRoutes)) {
    const options = keys(allRoutes).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllRoutes = (state) => state.airspace.allRoutes;
const selectSearchRoutes = (state, route) => route;
const selectsearchQueryRoute = (state, route, searchQuery) => searchQuery;

export const selectRoutesNameList = createSelector(
  [selectAllRoutes, selectSearchRoutes, selectsearchQueryRoute],
  (allRoutes, name, searchQuery) => {
    if (allRoutes && name && allRoutes[name]) {
      if (searchQuery) {
        return chain(allRoutes[name])
          .filter((r) => {
            const name = `${r.designatorSecondLetter}${r.multipleIdentifier}${r.designatorNumber}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["id"], ["asc"])
          .value();
      }

      return orderBy(allRoutes[name], ["id"], ["asc"]);
    }

    return [];
  }
);

const selectAllNavaid = (state) => state.airspace.allNavaid;
export const selectENRAllNavaidOptions = createSelector(
  [selectAllNavaid, selectSearchQuery],
  (allNavid, selectedFIR) => {
    if (Boolean(find(selectedFIR, ["designator", "All"]))) {
      return allNavid;
    } else if (
      !isEmpty(selectedFIR) &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(allNavid, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );

      return flatten(data);
    }
  }
);

export const selectAllNavaidOptions = createSelector(
  [selectAllNavaid, selectSearchQuery],
  (allNavid, searchQuery) => {
    if (searchQuery) {
      return chain(allNavid)
        .filter((o) => {
          return o.designator.toLowerCase().includes(searchQuery);
        })
        .uniqBy("designator")
        .orderBy(["designator"], ["asc"])
        .value();
    }

    return orderBy(uniqBy(allNavid, "designator"), ["designator"], ["asc"]);
  }
);

export const selectAllNavaidData = createSelector(
  [selectAllNavaid],
  (allNavid) => {
    return orderBy(allNavid, ["name"], ["asc"]);
  }
);
export const navaidOptions = (a, b) => b;
const selectSearch2Query = (a, b, c) => c;
const selectSearch3Query = (a, b, c, d) => d;
export const selectFilterNavaidData = createSelector(
  [selectAllNavaid, navaidOptions, selectSearch2Query, selectSearch3Query],
  (allNavid, navidOptions, searchNavidQuery, searchFIRQuery) => {
    if (isEmpty(searchFIRQuery)) return [];
    if (isEmpty(allNavid)) return [];
    if (isEmpty(searchNavidQuery)) return [];

    const serachValue = searchNavidQuery.map((s) => s.designator);

    if (serachValue.includes("All")) {
      return navidOptions;
    }

    const navidData = chain(navidOptions)
      .filter((navid) => {
        return serachValue.includes(navid.designator);
      })
      .value();

    const data = searchFIRQuery.map((fir) =>
      filter(navidData, (data) => {
        return data.fir_ids.includes(fir.id);
      })
    );

    return flatten(data);
  }
);

const selectAllSignificantPoint = (state) => state.airspace.allsignificantPoint;
export const selectENRAllSignificantPointOptions = createSelector(
  [selectAllSignificantPoint, selectSearchQuery],
  (allSignificantPoint, selectedFIR) => {
    if (Boolean(find(selectedFIR, ["designator", "All"]))) {
      return allSignificantPoint;
    } else if (
      !isEmpty(selectedFIR) &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(allSignificantPoint, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );
      return flatten(data);
    }
  }
);

export const selectAllSignificantPointOptions = createSelector(
  [selectAllSignificantPoint, selectSearchQuery],
  (allSignificantPoint, searchQuery) => {
    if (searchQuery) {
      return chain(allSignificantPoint)
        .filter((o) => {
          return o.name.toLowerCase().includes(searchQuery);
        })
        .uniqBy("name")
        .orderBy(["name"], ["asc"])
        .value();
    }

    return orderBy(uniqBy(allSignificantPoint, "name"), ["name"], ["asc"]);
  }
);

export const pointOptions = (a, b) => b;
export const selectfilterSignificantPoints = createSelector(
  [
    selectAllSignificantPoint,
    pointOptions,
    selectSearch2Query,
    selectSearch3Query,
  ],
  (allSignificantPoint, pointsOpts, searchPointQuery, searchFIRQuery) => {
    if (isEmpty(searchFIRQuery)) return [];
    if (isEmpty(allSignificantPoint)) return [];
    if (isEmpty(searchPointQuery)) return [];

    const serachValue = searchPointQuery.map((s) => s.name);

    if (serachValue.includes("All")) {
      return pointsOpts;
    }

    const pointData = chain(pointsOpts)
      .filter((sp) => {
        return serachValue.includes(sp.name);
      })
      .value();

    const data = searchFIRQuery.map((fir) =>
      filter(pointData, (data) => {
        return data.fir_ids.includes(fir.id);
      })
    );

    return flatten(data);
  }
);

export const selectAllSignificantPoints = createSelector(
  [selectAllSignificantPoint],
  (allSignificantPoint) => {
    return orderBy(allSignificantPoint, ["name"], ["asc"]);
  }
);

export const selectAllDPROptions = (state) => {
  const allDPR = state.airspace.allDPR;

  if (!isEmpty(allDPR)) {
    const options = keys(allDPR).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllDPR = (state) => state.airspace.allDPR;
const selectSearchDPR = (state, dpr) => dpr;
const selectSearchDPRType = (state, dpr, type) => type;
const selectsearchQueryDPR = (state, route, type, searchQuery) => searchQuery;

export const selectDPRNameList = createSelector(
  [selectAllDPR, selectSearchDPR, selectSearchDPRType, selectsearchQueryDPR],
  (allDPR, dpr, type, searchQuery) => {
    if (allDPR && dpr && type && allDPR[dpr][type]) {
      if (searchQuery) {
        return chain(allDPR[dpr][type])
          .filter((r) => {
            const name = `${r.Airspace_name}${r.designator}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["Airspace_name", "designator"], ["asc", "asc"])
          .value();
      }

      return orderBy(
        allDPR[dpr][type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectDPRDataList = createSelector(
  [selectAllDPR, selectSearchDPR, selectSearchDPRType],
  (allDPR, dpr, type) => {
    if (allDPR && dpr && type && allDPR[dpr][type]) {
      return orderBy(
        allDPR[dpr][type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectAllTRA_TSA_ADIZOptions = (state) => {
  const allTRA_TSA_ADIZ = state.airspace.allTRA_TSA_ADIZ;

  if (!isEmpty(allTRA_TSA_ADIZ)) {
    const options = keys(allTRA_TSA_ADIZ).map((key, i) => ({
      id: i + 1,
      label: key,
      value: key,
    }));
    return orderBy(options, ["label"], ["asc"]);
  }

  return [];
};

const selectAllTRA_TSA_ADIZ = (state) => state.airspace.allTRA_TSA_ADIZ;
const selectSearchTRA_TSA_ADIZ = (state, type) => type;
const selectSearchQueryTRA_TSA_ADIZ = (state, route, searchQuery) =>
  searchQuery;

export const selectTRA_TSA_ADIZNameList = createSelector(
  [
    selectAllTRA_TSA_ADIZ,
    selectSearchTRA_TSA_ADIZ,
    selectSearchQueryTRA_TSA_ADIZ,
  ],
  (allTRA_TSA_ADIZ, type, searchQuery) => {
    if (allTRA_TSA_ADIZ && type && allTRA_TSA_ADIZ[type]) {
      if (searchQuery) {
        return chain(allTRA_TSA_ADIZ[type])
          .filter((r) => {
            const name = `${r.Airspace_name}${r.designator}`;
            return name.toLowerCase().includes(searchQuery);
          })
          .orderBy(["Airspace_name", "designator"], ["asc", "asc"])
          .value();
      }

      return orderBy(
        allTRA_TSA_ADIZ[type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

export const selectTRA_TSA_ADIZDataList = createSelector(
  [selectAllTRA_TSA_ADIZ, selectSearchTRA_TSA_ADIZ],
  (allTRA_TSA_ADIZ, type) => {
    if (allTRA_TSA_ADIZ && type && allTRA_TSA_ADIZ[type]) {
      return orderBy(
        allTRA_TSA_ADIZ[type],
        ["Airspace_name", "designator"],
        ["asc", "asc"]
      );
    }

    return [];
  }
);

const findQueryADIZ = (state, findQuery) => findQuery;
export const findADIZDataList = createSelector(
  [selectAllTRA_TSA_ADIZ, findQueryADIZ],
  (allTRA_TSA_ADIZ, findQuery) => {
    if (findQuery && allTRA_TSA_ADIZ && allTRA_TSA_ADIZ["ADIZ"]) {
      const ADIZData = allTRA_TSA_ADIZ["ADIZ"];
      const designator = ADIZKeyMap[findQuery];
      return ADIZData.find(
        (adiz) => adiz.designator.toLowerCase() === designator.toLowerCase()
      );
    }

    return null;
  }
);

const selectAllADIZGeoData = (state) => state.airspace.adizGeoData;
const selectSearchQueryADIZGeoData = (state, searchQuery) => searchQuery;
export const selectADIZAirspaceGeometry = createSelector(
  [selectAllADIZGeoData, selectSearchQueryADIZGeoData],
  (adizAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map((s) => s.designation);

      if (serachValue.includes("All")) {
        return orderBy(
          uniqBy(adizAirspaceGeometry, "designation"),
          ["designation"],
          ["asc"]
        );
      }
      return chain(adizAirspaceGeometry)
        .filter((adiz) => {
          return serachValue.includes(adiz.designation);
        })
        .uniqBy("designation")
        .orderBy(["designation"], ["asc"])
        .value();
    }

    return [];
  }
);

export const selectADIZAirspaceOption = (state) => {
  const ADIZAirspaceData = state.airspace.adizGeoData;

  if (ADIZAirspaceData.length > 0) {
    return orderBy(
      uniqBy(ADIZAirspaceData, "designation"),
      ["designation"],
      ["asc"]
    );
  }

  return [];
};

const selectAllRNAVGeoData = (state) => state.airspace.RNAVRoutesGeometryData;
const selectSearchQueryRNAVGeoData = (state, searchQuery) => searchQuery;
export const selectRNAVAirspaceGeometry = createSelector(
  [selectAllRNAVGeoData, selectSearchQueryRNAVGeoData],
  (rnavAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map(
        (s) => `${s.designatorLetter}${s.designatorNumber}`
      );
      return chain(rnavAirspaceGeometry)
        .filter((rnav) => {
          const key = `${rnav.designatorLetter}${rnav.designatorNumber}`;
          return serachValue.includes(key);
        })
        .orderBy(["designatorLetter", "designatorNumber"], ["asc", "asc"])
        .value();
    }

    return orderBy(
      rnavAirspaceGeometry,
      "designatorNumber",
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }
);

export const selectRNAVAirspaceOption = (state) => {
  const RNAVAirspaceData = state.airspace.RNAVRoutesGeometryData;

  if (RNAVAirspaceData.length > 0) {
    return orderBy(
      uniqBy(RNAVAirspaceData, "designatorNumber"),
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }

  return [];
};

const selectAllCNAVGeoData = (state) => state.airspace.CNAVRoutesGeometryData;
const selectSearchQueryCNAVGeoData = (state, searchQuery) => searchQuery;
export const selectCNAVAirspaceGeometry = createSelector(
  [selectAllCNAVGeoData, selectSearchQueryCNAVGeoData],
  (cnavAirspaceGeometry, searchQuery) => {
    if (searchQuery.length > 0) {
      const serachValue = searchQuery.map(
        (s) => `${s.designatorLetter}${s.designatorNumber}`
      );
      return chain(cnavAirspaceGeometry)
        .filter((cnav) => {
          const key = `${cnav.designatorLetter}${cnav.designatorNumber}`;
          return serachValue.includes(key);
        })
        .orderBy(["designatorLetter", "designatorNumber"], ["asc", "asc"])
        .value();
    }

    return orderBy(
      cnavAirspaceGeometry,
      "designatorNumber",
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }
);

export const selectCNAVAirspaceOption = (state) => {
  const CNAVAirspaceData = state.airspace.CNAVRoutesGeometryData;

  if (CNAVAirspaceData.length > 0) {
    return orderBy(
      uniqBy(CNAVAirspaceData, "designatorNumber"),
      ["designatorLetter", "designatorNumber"],
      ["asc", "asc"]
    );
  }

  return [];
};

export const selectRNAVOptions = (state) => {
  const RNAVRoutesList = state.airspace.RNAVRoutesData;

  if (RNAVRoutesList.length > 0) {
    return orderBy(RNAVRoutesList, ["id"], ["asc"]);
  }

  return [];
};

export const selectCNAVOptions = (state) => {
  const CNAVRoutesList = state.airspace.CNAVRoutesData;

  if (CNAVRoutesList.length > 0) {
    return orderBy(CNAVRoutesList, ["id"], ["asc"]);
  }

  return [];
};
export const selectedENRFIR = (a, b) => b;
export const selectENRRNAVFirOption = createSelector(
  [selectRNAVOptions, selectedENRFIR],
  (rnavOptions, selectedFIR) => {
    if (rnavOptions.length === 0 || selectedFIR.length === 0) return [];

    if (
      Boolean(find(selectedFIR, ["designator", "All"])) ||
      (selectedFIR.length === 4 &&
        !Boolean(find(selectedFIR, ["designator", "All"])))
    ) {
      return rnavOptions;
    } else if (
      selectedFIR.length > 0 &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(rnavOptions, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );
      return flatten(data);
    }
  }
);

export const selectENRCNAVFirOption = createSelector(
  [selectCNAVOptions, selectedENRFIR],
  (cnavOptions, selectedFIR) => {
    if (cnavOptions.length === 0 || selectedFIR.length === 0) return [];

    if (Boolean(find(selectedFIR, ["designator", "All"]))) {
      return cnavOptions;
    } else if (
      selectedFIR.length > 0 &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(cnavOptions, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );
      return flatten(data);
    }
  }
);

export const selectRouteCNAVUPOption = createSelector(
  [selectCNAVOptions, selectedRouteAirspaceUsePlanData, FIRAUPSearchQuery],
  (cnavOptions, routeAUPData, selectedFIR) => {
    if (cnavOptions.length === 0 || selectedFIR.length === 0) return [];

    if (
      Boolean(find(selectedFIR, ["designator", "All"])) ||
      (selectedFIR.length === 4 &&
        !Boolean(find(selectedFIR, ["designator", "All"])))
    ) {
      const routeKeys = keys(routeAUPData);
      if (routeKeys.length === 0) return [];
      return filter(cnavOptions, (data) => {
        return includes(
          routeKeys,
          `${data.designatorSecondLetter}${data.designatorNumber}`
        );
      });
    } else if (
      selectedFIR.length > 0 &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(cnavOptions, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );
      const routeKeys = keys(routeAUPData);
      if (routeKeys.length === 0) return [];
      return filter(uniq(flatten(data)), (data) => {
        return includes(
          routeKeys,
          `${data.designatorSecondLetter}${data.designatorNumber}`
        );
      });
    }
  }
);

export const selectRouteRNAVUPOption = createSelector(
  [selectRNAVOptions, selectedRouteAirspaceUsePlanData, FIRAUPSearchQuery],
  (rnavOptions, routeAUPData, selectedFIR) => {
    if (rnavOptions.length === 0 || selectedFIR.length === 0) return [];

    if (
      Boolean(find(selectedFIR, ["designator", "All"])) ||
      (selectedFIR.length === 4 &&
        !Boolean(find(selectedFIR, ["designator", "All"])))
    ) {
      const routeKeys = keys(routeAUPData);
      if (routeKeys.length === 0) return [];
      return filter(rnavOptions, (data) => {
        return includes(
          routeKeys,
          `${data.designatorSecondLetter}${data.designatorNumber}`
        );
      });
    } else if (
      selectedFIR.length > 0 &&
      !Boolean(find(selectedFIR, ["designator", "All"]))
    ) {
      const data = selectedFIR.map((fir) =>
        filter(rnavOptions, (data) => {
          return data.fir_ids.includes(fir.id);
        })
      );
      const routeKeys = keys(routeAUPData);
      if (routeKeys.length === 0) return [];
      return filter(uniq(flatten(data)), (data) => {
        return includes(
          routeKeys,
          `${data.designatorSecondLetter}${data.designatorNumber}`
        );
      });
    }
  }
);

const searchMilitryAirspaceAupOption = (a, b) => b;
const searchRNAVAUPOption = (a, b) => b;
const searchCNAVAUPOption = (a, b) => b;
const searchCurrentTime = (a, b, currentTime) => currentTime;

export const currentLiveTrackerMilitryTimelinedata = createSelector(
  [
    selectedMilitryAirspaceUsePlanData,
    searchMilitryAirspaceAupOption,
    searchCurrentTime,
  ],
  (militryTimedata, militryAUPOptionData, currentTime) => {
    if (
      isEmpty(militryTimedata) ||
      isEmpty(militryAUPOptionData) ||
      isEmpty(currentTime)
    )
      return [];
    let datacol = [];
    for (const key in militryTimedata) {
      const element = militryTimedata[key];
      const datafilter = filter(element, (data) => {
        return (
          Number(data.time_fm) < Number(currentTime) &&
          Number(data.time_to) > Number(currentTime)
        );
      });
      if (datafilter.length > 0) {
        datacol.push(key);
      }
    }

    const finalData = filter(militryAUPOptionData, (data) => {
      return datacol.includes(data.designator);
    });
    return finalData;
  }
);

export const currentLiveTrackerRNAVTimelinedata = createSelector(
  [selectedRouteAirspaceUsePlanData, searchRNAVAUPOption, searchCurrentTime],
  (routeTimedata, RNAVAUPOption, currentTime) => {
    if (
      isEmpty(routeTimedata) ||
      isEmpty(RNAVAUPOption) ||
      isEmpty(currentTime)
    )
      return [];
    let datacol = [];
    for (const key in routeTimedata) {
      const element = routeTimedata[key];
      const datafilter = filter(element, (data) => {
        return (
          Number(data.time_fm) < Number(currentTime) &&
          Number(data.time_to) > Number(currentTime)
        );
      });
      if (datafilter.length > 0) {
        datacol.push(key);
      }
    }

    const finalData = filter(RNAVAUPOption, (data) => {
      return datacol.includes(
        `${data.designatorSecondLetter}${data.designatorNumber}`
      );
    });
    return finalData;
  }
);

export const currentLiveTrackerCNAVTimelinedata = createSelector(
  [selectedRouteAirspaceUsePlanData, searchCNAVAUPOption, searchCurrentTime],
  (routeTimedata, CNAVAUPOption, currentTime) => {
    if (
      isEmpty(routeTimedata) ||
      isEmpty(CNAVAUPOption) ||
      isEmpty(currentTime)
    )
      return [];
    let datacol = [];
    for (const key in routeTimedata) {
      const element = routeTimedata[key];
      const datafilter = filter(element, (data) => {
        return (
          Number(data.time_fm) < Number(currentTime) &&
          Number(data.time_to) > Number(currentTime)
        );
      });
      if (datafilter.length > 0) {
        datacol.push(key);
      }
    }

    const finalData = filter(CNAVAUPOption, (data) => {
      return datacol.includes(
        `${data.designatorSecondLetter}${data.designatorNumber}`
      );
    });
    return finalData;
  }
);

const CNAVRouteData = (state) => state.airspace.conventionalRouteData;
const selectedCNAVOption = (a, b) => b;

export const startPointCNAVRoutePlanOptions = createSelector(
  [CNAVRouteData, selectedCNAVOption],
  (CNAVData, CNAVOption) => {
    if (isEmpty(CNAVData) || isEmpty(CNAVOption)) return [];

    const data = [];

    CNAVData.map((item, i) => {
      if (i === 0) {
        return data.push({
          id: item.id,
          RS_id: item.RS_id,
          startpoint_name: item.startpoint_name,
          isEndpoint: false,
          DD_startpoint_coordinates: item.DD_startpoint_coordinates,
        });
      }
      if (CNAVData.length > 1 && CNAVData.length - 1 === i) {
        return data.push({
          id: item.id,
          RS_id: item.RS_id,
          isEndpoint: true,
          startpoint_name: item.endpoint_name,
          DD_startpoint_coordinates: item.DD_startpoint_coordinates,
        });
      }
      return true;
    });

    return orderBy(uniqBy(data, "RS_id"), ["RS_id"], ["asc"]);
  }
);

export const flightLvlMinMaxCNAVRoutePlan = createSelector(
  [CNAVRouteData, selectedCNAVOption],
  (CNAVData, CNAVOption) => {
    if (isEmpty(CNAVData) || isEmpty(CNAVOption)) return {};

    const dataMin = [];
    const dataMax = [];
    CNAVData.map((item, i) => {
      if (!isEmpty(item.lowerLimit)) {
        const ll = item.lowerLimit;
        dataMin.push(ll.replace(/[^0-9]/gi, ""));
      }
      if (!isEmpty(item.upperLimit)) {
        const ul = item.upperLimit;
        dataMax.push(ul.replace(/[^0-9]/gi, ""));
      }
      return true;
    });

    // const minNumArray = dataMin.map((a) => Number(a));
    // const maxNumArray = dataMax.map((a) => Number(a));
    return {
      min: Math.max(...dataMin),
      max: Math.max(...dataMax),
    };
  }
);

const RNAVRouteData = (state) => state.airspace.areaNavigationalRouteData;
const selectedRNAVOption = (a, b) => b;

export const startPointRNAVRoutePlanOptions = createSelector(
  [RNAVRouteData, selectedRNAVOption],
  (RNAVData, RNAVOption) => {
    if (isEmpty(RNAVData) || isEmpty(RNAVOption)) return [];

    const data = [];
    RNAVData.map((item, i) => {
      if (i === 0) {
        return data.push({
          id: item.id,
          RS_id: item.RS_id,
          isEndpoint: false,
          startpoint_name: item.startpoint_name,
          DD_startpoint_coordinates: item.DD_startpoint_coordinates,
        });
      }
      if (RNAVData.length > 1 && RNAVData.length - 1 === i) {
        return data.push({
          id: item.id,
          RS_id: item.RS_id,
          isEndpoint: true,
          startpoint_name: item.endpoint_name,
          DD_startpoint_coordinates: item.DD_startpoint_coordinates,
        });
      }
      return true;
    });

    return orderBy(data, ["RS_id"], ["asc"]);
  }
);

export const flightLvlMinMaxRNAVRoutePlan = createSelector(
  [RNAVRouteData, selectedRNAVOption],
  (RNAVData, RNAVOption) => {
    if (isEmpty(RNAVData) || isEmpty(RNAVOption)) return {};

    const dataMin = [];
    const dataMax = [];
    RNAVData.map((item, i) => {
      if (!isEmpty(item.lowerLimit)) {
        const ll = item.lowerLimit;
        dataMin.push(ll.replace(/[^0-9]/gi, ""));
      }
      if (!isEmpty(item.upperLimit)) {
        const ul = item.upperLimit;
        dataMax.push(ul.replace(/[^0-9]/gi, ""));
      }
      return true;
    });

    // const minNumArray = dataMin.map((a) => Number(a));
    // const maxNumArray = dataMax.map((a) => Number(a));
    return {
      min: Math.max(...dataMin),
      max: Math.max(...dataMax),
    };
  }
);

export const RNAVRoutePlanOptions = createSelector(
  [selectRNAVOptions, selectedRouteAirspaceUsePlanData],
  (rnavOptions, routeAUPData) => {
    if (rnavOptions.length === 0 || isEmpty(routeAUPData)) return [];
    const routeKeys = keys(routeAUPData);
    if (routeKeys.length === 0) return [];
    return filter(rnavOptions, (data) => {
      return includes(
        routeKeys,
        `${data.designatorSecondLetter}${data.designatorNumber}`
      );
    });
  }
);

export const CNAVRoutePlanOptions = createSelector(
  [selectCNAVOptions, selectedRouteAirspaceUsePlanData],
  (rnavOptions, routeAUPData) => {
    if (rnavOptions.length === 0 || isEmpty(routeAUPData)) return [];
    const routeKeys = keys(routeAUPData);
    if (routeKeys.length === 0) return [];
    return filter(rnavOptions, (data) => {
      return includes(
        routeKeys,
        `${data.designatorSecondLetter}${data.designatorNumber}`
      );
    });
  }
);

// selectAllNavaid
const selectedSignificantPointsIds = (a, b) => b;
const selectedNavaidPointsIds = (a, b) => b;
export const routeBufferSignificantPointsGeometry = createSelector(
  [selectAllSignificantPoint, selectedSignificantPointsIds],
  (significantPoints, selectedpointIds) => {
    if (significantPoints.length === 0 || isEmpty(selectedpointIds)) return [];

    return filter(significantPoints, (data) => {
      return includes(selectedpointIds, data.id);
    });
  }
);

export const routeBufferNavaidPointsGeometry = createSelector(
  [selectAllNavaid, selectedNavaidPointsIds],
  (navaidPoints, selectedpointIds) => {
    if (navaidPoints.length === 0 || isEmpty(selectedpointIds)) return [];

    return filter(navaidPoints, (data) => {
      return includes(selectedpointIds, data.id);
    });
  }
);

const ARRvlDepAirportRoutePlanData = (state) =>
  state.airspace.arrvalDepAirportRouteData;
const isCNAVPointDesignator = (a, b) => b;
export const getOnlyCNAVRoutePlanData = createSelector(
  [ARRvlDepAirportRoutePlanData, isCNAVPointDesignator],
  (routedata, isCNAVData) => {
    if (isEmpty(routedata)) return [];
    if (isCNAVData) {
      const d = filter(routedata, (data) => {
        if (data.segments[0].navigationType === "CONV") {
          return data;
        }
      });
      return d;
    }
    return routedata;
  }
);

const allEnrAirspaceGeometryOpts = (state) =>
  state.airspace.ENRAirspaceGeometryOptions;
const ENRSearchQuery = (a, b) => b;
const ENRsearchqueryFIR = (a, b, c) => c;

export const getSelectedENROptions = createSelector(
  [allEnrAirspaceGeometryOpts, ENRSearchQuery, ENRsearchqueryFIR],
  (allEnrData, searchquery, searchqueryFIR) => {
    if (isEmpty(allEnrData)) return [];
    if (isEmpty(searchquery)) return [];
    let finalData = [];
    if (searchquery === "PDR" && !isEmpty(searchqueryFIR)) {
      const pData = allEnrData?.P;
      const dData = allEnrData?.D;
      const rData = allEnrData?.R;
      finalData = pData.concat(dData).concat(rData);
    }
    if (searchquery === "TRATSA" && !isEmpty(searchqueryFIR)) {
      const traData = allEnrData?.TRA;
      const tsaData = allEnrData?.TSA;
      finalData = traData.concat(tsaData);
    }
    if (searchquery === "MTR" && !isEmpty(searchqueryFIR)) {
      finalData = allEnrData?.MTR;
    }
    if (searchquery === "TMA" && !isEmpty(searchqueryFIR)) {
      finalData = allEnrData?.TMA;
    }
    if (searchquery === "ADIZ") {
      finalData = allEnrData?.ADIZ;
    }
    if (
      searchquery === "OTHER_REGULATED_AIRSPACE" &&
      !isEmpty(searchqueryFIR)
    ) {
      const key = "OTHER:OTHER_REGULATED_AIRSPACE";
      finalData = allEnrData[`${key}`];
    }
    if (searchquery === "ad217" && !isEmpty(searchqueryFIR)) {
      const key = "ad_2_17";
      finalData = allEnrData[`${key}`];
    }
    if (find(searchqueryFIR, ["designator", "All"]) || searchquery === "ADIZ") {
      return finalData;
    }
    const data = searchqueryFIR.map((fir) =>
      filter(finalData, (data) => {
        return data.fir_ids.includes(fir.id);
      })
    );
    return flatten(data);
  }
);

const EnrCTRLAirspaceGeometryOpts = (state) =>
  state.airspace.CTRLAirspaceGeometry;
export const selectedFIRCTRLOptions = createSelector(
  [EnrCTRLAirspaceGeometryOpts, ENRSearchQuery],
  (allCTRLData, searchqueryFIR) => {
    if (isEmpty(allCTRLData)) return [];
    if (isEmpty(searchqueryFIR)) return [];

    if (find(searchqueryFIR, ["designator", "All"])) {
      return orderBy(uniqBy(allCTRLData, "name"), ["label"], ["asc"]);
    }
    const data = searchqueryFIR.map((fir) =>
      filter(allCTRLData, (data) => {
        return data.fir_ids.includes(fir.id);
      })
    );

    return orderBy(uniqBy(flatten(data), "name"), ["label"], ["asc"]);
  }
);

export const CTRLOptions = (a, b) => b;
export const selectedFIRCTRLGeometry = createSelector(
  [
    EnrCTRLAirspaceGeometryOpts,
    CTRLOptions,
    selectSearch2Query,
    selectSearch3Query,
  ],
  (allCTRLData, ctrlOptions, searchCTRLQuery, searchqueryFIR) => {
    if (isEmpty(searchqueryFIR)) return [];
    if (isEmpty(allCTRLData)) return [];
    if (isEmpty(searchCTRLQuery)) return [];

    const serachValue = searchCTRLQuery.map((s) => s.name);

    if (serachValue.includes("All")) {
      return ctrlOptions.map((d) => {
        return {
          ...d,
          features: {
            geometry: d.geometry,
            type: "Feature",
            properties: { name: d.name },
          },
        };
      });
    }

    const navidData = chain(ctrlOptions)
      .filter((ctrl) => {
        return serachValue.includes(ctrl.name);
      })
      .value();

    const data = searchqueryFIR.map((fir) =>
      filter(navidData, (data) => {
        return data.fir_ids.includes(fir.id);
      })
    );

    return flatten(data).map((d) => {
      return {
        ...d,
        features: {
          geometry: d.geometry,
          type: "Feature",
          properties: { name: d.name },
        },
      };
    });
  }
);
