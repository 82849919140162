import { Fragment } from "react";
import { isEmpty } from "lodash";
import { Box, Paper, Table, TableHead, TableRow } from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import { theme } from "@styles/theme";

function AerodromeRegulation({
  selctedAirport,
  activeCategory,
  aerodromeregulationinfo,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
        </Table>
        {aerodromeregulationinfo ? (
          <Box
            dangerouslySetInnerHTML={{ __html: aerodromeregulationinfo }}
            sx={{
              pl: 2,
              whiteSpace: "pre-line",
              p: { color: theme.palette.primary.main },
            }}
          />
        ) : (
          <Box sx={{ textAlign: "center", pt: 2, pb: 2 }}>No data found.</Box>
        )}
      </Paper>
    </Fragment>
  );
}

export default AerodromeRegulation;
