import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingAMDBData: false,
  amdbDetails: null,
  isLoadingAllAirportamdb: false,
  allamdbAirports: [],
  isExportGeoJSONLoading: false,
  isLoadingAddEditDeletePolygon: false,
  isAMDBdrawHighlighter: false,
};

export const amdb = createSlice({
  name: "amdb",
  initialState,
  reducers: {
    setAMDBDataLoadingRequest: (state, action) => {
      state.isLoadingAMDBData = action.payload;
    },
    setAMDBDetails: (state, action) => {
      state.amdbDetails = action.payload;
    },
    setLoadingAllAirportamdb: (state, action) => {
      state.isLoadingAllAirportamdb = action.payload;
    },
    setAllamdbAirports: (state, action) => {
      state.allamdbAirports = action.payload;
    },
    setExportGeoJSONLoading: (state, action) => {
      state.isExportGeoJSONLoading = action.payload;
    },
    setLoadingAddEditDeletePolygon: (state, action) => {
      state.isLoadingAddEditDeletePolygon = action.payload;
    },
    setAMDBdrawHighlighter: (state, action) => {
      state.isAMDBdrawHighlighter = action.payload;
    },
  },
});
