import React, { useEffect, useRef, useState } from "react";
import { Polyline, FeatureGroup, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { split } from "lodash";
import { Box, Stack } from "@mui/material";
import { LightButton } from "@components/button";
import { roles } from "@constants/constant";
import { styles } from "@styles/amdbStyles";

export default function RenderParkingBayLine({
  selectedFeatures,
  parkingBayLineList,
  handleEditShap,
  handleDeleteShape,
}) {
  const popupElRef = useRef(null);
  const featureKey = "PARKING_BAYLINE_List";
  const user = useSelector((state) => state.userSession.user);
  const [areaColor, setAreaColor] = useState(null);
  const [isVisibleParkingBayLine, setVisibleParkingBayLine] = useState(false);
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const isVisible = selectedFeatures.includes(
      `${featureKey}__${areaColorT[featureKey]}`
    );

    setAreaColor(areaColorT);
    setVisibleParkingBayLine(isVisible);
  }, [selectedFeatures]); // eslint-disable-line

  const handleBayPolylineEdit = (data, index) => {
    handleEditShap(data, "bayline", index);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const handleBayPolylineDelete = (data, index) => {
    handleDeleteShape(data, "bayline", index);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  if (!isVisibleParkingBayLine || !areaColor) {
    return null;
  }

  return (
    <FeatureGroup>
      {parkingBayLineList.map((pblGeometry) => {
        const pblCoordinates = pblGeometry.coordinates.coordinates;
        const pblCoordinateMap = pblCoordinates.map((pblCoords) => {
          return pblCoords.map((pbl) => {
            return {
              lng: pbl[0],
              lat: pbl[1],
            };
          });
        });

        return pblCoordinateMap.map((pblLinePath, i) => (
          <Polyline
            key={i}
            pane="mapPane"
            positions={pblLinePath}
            pathOptions={{
              color: areaColor[featureKey],
              weight: 2,
              zIndex: 4,
            }}
          >
            <Popup ref={popupElRef} className="polygon-popup">
              <Box sx={styles.apnShapPolygonPop}>
                Bayline: {pblGeometry?.id}
              </Box>
              {isAdmin && (
                <Stack
                  sx={{ width: 200 }}
                  direction="row"
                  flexDirection="column"
                >
                  <LightButton
                    label="Modify Polyline"
                    onClick={() => handleBayPolylineEdit(pblGeometry, i)}
                    sx={styles.modifyShapeBtn}
                  />
                  <LightButton
                    label="Delete Polyline"
                    onClick={() => handleBayPolylineDelete(pblGeometry, i)}
                    sx={{ p: 0, mr: 7 }}
                  />
                </Stack>
              )}
            </Popup>
          </Polyline>
        ));
      })}
    </FeatureGroup>
  );
}
