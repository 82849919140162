import React from "react";
import { drop, orderBy } from "lodash";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function ApproachPreviewTable({ data }) {
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell
              colSpan={7}
              sx={{ width: "1vw" }}
            ></StyledTableCell>
            <StyledTableCell colSpan={4} align="center" sx={{ width: "1vw" }}>
              280M RUNWAY STRIP
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell rowSpan={2}>S.NO</StyledTableCell>
            <StyledTableCell rowSpan={2}>UID</StyledTableCell>
            <StyledTableCell rowSpan={2}>OBJECT</StyledTableCell>
            <StyledTableCell rowSpan={2}>LATITUDE</StyledTableCell>
            <StyledTableCell rowSpan={2}>LONGITUDE</StyledTableCell>
            <StyledTableCell rowSpan={2}>TOP ELEV. IN M.</StyledTableCell>
            <StyledTableCell rowSpan={2}>BASE ELEV. IN M.</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>
              <Stack sx={{ width: "5vw" }}>
                <StyledBoxV2>DISTANCE (IN M)</StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>X</StyledBoxV2>
                  <StyledBoxV2>Y</StyledBoxV2>
                  <StyledBoxV2>YY</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell>
              <Stack sx={{ width: "10vw" }}>
                <StyledBoxV2>
                  W.R.T 1:50 IN APP FUNNEL {"&"} 1:7 IN T.S. (IN M.)
                </StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                  <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell>
              <Stack sx={{ width: "10vw", m: 0 }}>
                <StyledBoxV2>W.R.T. I.H.S./C.S./O.H.S. (IN M.)</StyledBoxV2>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                  <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell sx={{ typography: "caption", sx: "1vw" }}>
              REMARKS
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(drop(data, 3), ["S.NO"], ["asc"]).map((row, i) => {
            return (
              <TableRow key={i}>
                <StyledTableCell>{row["S.NO"]}</StyledTableCell>
                <StyledTableCell>{row["UID "]}</StyledTableCell>
                <StyledTableCell>{row["OBJECT"]}</StyledTableCell>
                <StyledTableCell>{row["LATITUDE"]}</StyledTableCell>
                <StyledTableCell>{row["LONGITUDE"]}</StyledTableCell>
                <StyledTableCell>{row["TOP ELEV. IN M."]}</StyledTableCell>
                <StyledTableCell>{row["BASE ELEV. IN M."]}</StyledTableCell>
                <StyledTableCell>
                  <Stack
                    sx={{ width: "6vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["280M RUNWAY STRIP"]}</Box>
                    <Box>{row["__EMPTY"]}</Box>
                    <Box>{row["__EMPTY_1"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack
                    sx={{ width: "10vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["__EMPTY_2"]}</Box>
                    <Box>{row["__EMPTY_3"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack
                    sx={{ width: "10vw" }}
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <Box>{row["__EMPTY_4"]}</Box>
                    <Box>{row["__EMPTY_5"]}</Box>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell sx={{ sx: "2vw" }}>
                  {row["__EMPTY_6"]}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
