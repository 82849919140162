import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { filter, find, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { EditIcon } from "@assets/svg/edit";
import { LightButton, PrimaryButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import { navidTypes, hoursOfOperation } from "@constants/ad2Data";
import { navidDetail } from "@constants/ad2DataForm.constant";
import { createAd219NavidValidationSchema } from "@constants/ad2Data";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  deleteAD2AAIAirport,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad219NavidForm({
  selectedAirport,
  selectedCategory,
  adRunwayInfo,
}) {
  const dispatch = useDispatch();
  const {
    isLoadingAddAAIAirportDetail,
    isLoadingTaxiwayUpload,
    AAIAirport,
    isLoadingDeleteAD210AAIAirport,
  } = useSelector((state) => state.airport);
  const [editId, setEditId] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const [isLoadingData, setLoadingData] = useState(false);

  const runwayDirection = adRunwayInfo
    ? adRunwayInfo.Runway_Direction_List
    : [];

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    AAIAirport.map((data, index) => {
      createAd219NavidForm.setFieldValue(
        `navids[${index}].id`,
        data.id ? data.id : ""
      );
      createAd219NavidForm.setFieldValue(
        `navids[${index}].identification`,
        data.identification ? data.identification : ""
      );
      createAd219NavidForm.setFieldValue(
        `navids[${index}].type`,
        data.type ? data.type : ""
      );
      createAd219NavidForm.setFieldValue(
        `navids[${index}].rwy_dir`,
        data.rwy_dir ? data.rwy_dir : ""
      );

      if (data.type === "ILS_DME") {
        // DME
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.channel`,
          data?.details?.DME?.channel ? data?.details?.DME?.channel : ""
        );
        const DMEelevation = data?.details?.DME?.elevation
          ? data?.details?.DME?.elevation.replace("FT", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.elevation`,
          Number(DMEelevation)
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.latitude`,
          data?.details?.DME?.latitude ? data?.details?.DME?.latitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.longitude`,
          data?.details?.DME?.longitude ? data?.details?.DME?.longitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.hours_of_operation`,
          data?.details?.DME?.hours_of_operation
            ? data?.details?.DME?.hours_of_operation
            : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].DME.remarks`,
          data?.details?.DME?.remarks ? data?.details?.DME?.remarks : ""
        );
      }
      if (data.type === "ILS" || data.type === "ILS_DME") {
        createAd219NavidForm.setFieldValue(
          `navids[${index}].signalPerformance`,
          data?.signalPerformance ? data?.signalPerformance : ""
        );
        // LOC
        const locfrequency = data?.details?.LOC?.frequency
          ? data?.details?.LOC?.frequency.replace("MHz", "").replace("MHZ", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.frequency`,
          Number(locfrequency)
        );
        const locelevation = data?.details?.LOC?.elevation
          ? data?.details?.LOC?.elevation.replace("FT", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.elevation`,
          Number(locelevation)
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.latitude`,
          data?.details?.LOC?.latitude ? data?.details?.LOC?.latitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.longitude`,
          data?.details?.LOC?.longitude ? data?.details?.LOC?.longitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.hours_of_operation`,
          data?.details?.LOC?.hours_of_operation
            ? data?.details?.LOC?.hours_of_operation
            : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].LOC.remarks`,
          data?.details?.LOC?.remarks ? data?.details?.LOC?.remarks : ""
        );
        // GP
        const GPfrequency = data?.details?.GP?.frequency
          ? data?.details?.GP?.frequency.replace("MHz", "").replace("MHZ", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.frequency`,
          Number(GPfrequency)
        );
        const GPelevation = data?.details?.GP?.elevation
          ? data?.details?.GP?.elevation.replace("FT", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.elevation`,
          Number(GPelevation)
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.latitude`,
          data?.details?.GP?.latitude ? data?.details?.GP?.latitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.longitude`,
          data?.details?.GP?.longitude ? data?.details?.GP?.longitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.hours_of_operation`,
          data?.details?.GP?.hours_of_operation
            ? data?.details?.GP?.hours_of_operation
            : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].GP.remarks`,
          data?.details?.GP?.remarks ? data?.details?.GP?.remarks : ""
        );
      }
      if (data.type === "NDB") {
        createAd219NavidForm.setFieldValue(
          `navids[${index}].class`,
          data?.class ? data?.class : ""
        );
      }
      if (data.type === "DME" || data.type === "VOR_DME") {
        createAd219NavidForm.setFieldValue(
          `navids[${index}].channel`,
          data?.channel ? data.channel : ""
        );
      }
      if (
        data.type === "NDB" ||
        data.type === "VOR_DME" ||
        data.type === "MKR"
      ) {
        const frequency = data?.frequency
          ? data?.frequency.replace("MHz", "").replace("MHZ", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].frequency`,
          Number(frequency)
        );
      }
      if (
        data.type === "NDB" ||
        data.type === "VOR_DME" ||
        data.type === "DME" ||
        data.type === "MKR"
      ) {
        const elevation = data?.elevation
          ? data?.elevation.replace("FT", "")
          : "";
        createAd219NavidForm.setFieldValue(
          `navids[${index}].elevation`,
          Number(elevation)
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].hours_of_operation`,
          data?.hours_of_operation ? data?.hours_of_operation : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].latitude`,
          data?.latitude ? data?.latitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].longitude`,
          data?.longitude ? data?.longitude : ""
        );
        createAd219NavidForm.setFieldValue(
          `navids[${index}].remarks`,
          data?.remarks ? data?.remarks : ""
        );
      }
      return true;
    });
  }, [AAIAirport, createAd219NavidForm]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "navaids",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleUploadSuccessCallback = () => {
    createAd219NavidForm.resetForm();
    createAd219NavidForm.setSubmitting(false);
  };

  const createAd219NavidForm = useFormik({
    enableReinitialize: true,
    initialValues: { navids: [navidDetail] },
    validationSchema: createAd219NavidValidationSchema,
    onSubmit: (values) => {
      let navidValsData = [];
      if (editId) {
        navidValsData = values.navids;
      } else {
        navidValsData = filter(values.navids, ["id", ""]);
      }
      const navidValues = navidValsData.map((navid) => {
        if (navid.type === "ILS") {
          return {
            signalPerformance: navid.signalPerformance,
            identification: navid.identification,
            type: navid.type,
            rwy_dir: navid.rwy_dir,
            details: {
              LOC: {
                frequency: navid.LOC.frequency
                  ? `${navid.LOC.frequency} MHZ`
                  : "",
                hours_of_operation: navid.LOC.hours_of_operation,
                latitude: navid.LOC.latitude,
                longitude: navid.LOC.longitude,
                elevation: navid.LOC.elevation
                  ? `${navid.LOC.elevation} FT`
                  : "",
                remarks: navid.LOC.remarks,
              },
              GP: {
                frequency: navid.GP.frequency
                  ? `${navid.GP.frequency} MHZ`
                  : "",
                hours_of_operation: navid.GP.hours_of_operation,
                latitude: navid.GP.latitude,
                longitude: navid.GP.longitude,
                elevation: navid.GP.elevation ? `${navid.GP.elevation} FT` : "",
                remarks: navid.GP.remarks,
              },
            },
          };
        }
        if (navid.type === "ILS_DME") {
          return {
            signalPerformance: navid.signalPerformance,
            identification: navid.identification,
            type: navid.type,
            rwy_dir: navid.rwy_dir,
            details: {
              LOC: {
                frequency: navid.LOC.frequency
                  ? `${navid.LOC.frequency} MHZ`
                  : "",
                hours_of_operation: navid.LOC.hours_of_operation,
                latitude: navid.LOC.latitude,
                longitude: navid.LOC.longitude,
                elevation: navid.LOC.elevation
                  ? `${navid.LOC.elevation} FT`
                  : "",
                remarks: navid.LOC.remarks,
              },
              GP: {
                frequency: navid.GP.frequency
                  ? `${navid.GP.frequency} MHZ`
                  : "",
                hours_of_operation: navid.GP.hours_of_operation,
                latitude: navid.GP.latitude,
                longitude: navid.GP.longitude,
                elevation: navid.GP.elevation ? `${navid.GP.elevation} FT` : "",
                remarks: navid.GP.remarks,
              },
              DME: {
                channel: navid.DME.channel,
                hours_of_operation: navid.DME.hours_of_operation,
                latitude: navid.DME.latitude,
                longitude: navid.DME.longitude,
                elevation: navid.DME.elevation
                  ? `${navid.DME.elevation} FT`
                  : "",
                remarks: navid.DME.remarks,
              },
            },
          };
        }
        const payload = {
          identification: navid.identification,
          type: navid.type,
          rwy_dir: navid.rwy_dir,
          hours_of_operation: navid.hours_of_operation,
          latitude: navid.latitude,
          longitude: navid.longitude,
          elevation: navid.elevation ? `${navid.elevation} FT` : "",
          remarks: navid.remarks,
        };

        if (navid.type === "NBD") {
          payload.class = navid.class;
        }

        if (navid.type !== "DME") {
          payload.frequency = navid.frequency ? `${navid.frequency} MHZ` : "";
        }

        if (navid.type === "VOR_DME" || navid.type === "DME") {
          payload.channel = navid.channel;
        }
        return payload;
      });

      if (editId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "navaids",
              {
                ...navidValues[editIndex],
                id: editId,
              },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditId(null);
                setEditIndex(null);
                handleUploadSuccessCallback();
                setOpenDeleteConfirmDialog(false);
                handleGetADCallBack();
              }
            )
          );
        });
      } else {
        if (navidValues && navidValues.length > 0) {
          setLoadingData(true);
          getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
            const promise = navidValues.map((body) => {
              return dispatch(
                addAD2AAIAirportDetail(
                  "navaids",
                  body,
                  selectedAirport.id,
                  selectedAirport.version_id,
                  token,
                  () => {
                    handleUploadSuccessCallback();
                  }
                )
              );
            });

            Promise.all(promise)
              .then(() => {
                setEditId(null);
                setEditIndex(null);
                setOpenDeleteConfirmDialog(false);
                setLoadingData(false);
                handleGetADCallBack();
              })
              .catch(() => {
                setLoadingData(false);
                toast.error("Something went wrong!");
              });
          });
        }
      }
    },
  });

  const handleEditdetail = (id) => {
    setEditId(id);
  };

  const handleEditDone = (editId, index) => {
    setEditId(editId);
    setEditIndex(index);
  };

  const handleDelete = (deleteId) => {
    let id;
    if (editId) {
      id = editId;
    } else {
      id = deleteId;
    }
    if (!id) return;
    setDeleteId(id);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleSaveDeleteDialog = () => {
    if (!deleteId) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "navaids",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            ids: [String(deleteId)],
          },
          () => {
            setEditId(null);
            setEditIndex(null);
            handleUploadSuccessCallback();
            setOpenDeleteConfirmDialog(false);
            handleGetADCallBack();
          },
          token
        )
      );
    });
  };

  return (
    <Paper className="add-airport-data-form">
      <FormikProvider value={createAd219NavidForm}>
        <form
          onSubmit={createAd219NavidForm.handleSubmit}
          style={{ height: "100%" }}
        >
          <Box sx={{ ...styles.adFormField, height: "calc(100% - 54px)" }}>
            <FieldArray
              name="navids"
              render={(arrayHelpers) => (
                <div>
                  {createAd219NavidForm.values.navids.map((_, index) => {
                    const isTouched = createAd219NavidForm.touched?.navids
                      ? createAd219NavidForm.touched?.navids[index]
                      : false;
                    const isErrors = createAd219NavidForm.errors?.navids
                      ? createAd219NavidForm.errors?.navids[index]
                      : false;

                    const navaidIds = !isEmpty(AAIAirport)
                      ? Array.isArray(AAIAirport) &&
                        AAIAirport.map((data) => data.id)
                      : [];

                    return (
                      <Fragment key={index}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={styles.adVORMain}>
                            <Typography
                              variant="span"
                              sx={{ width: "85%", display: "inline-block" }}
                            >
                              Navaid {index + 1}
                            </Typography>
                            <Box sx={styles.adFuelDeleteBtn}>
                              {!isEmpty(AAIAirport) &&
                                createAd219NavidForm.values.navids[index].id &&
                                (editId === navaidIds[index] ? (
                                  <button
                                    type="button"
                                    style={styles.ad28Btn}
                                    onClick={() => {
                                      createAd219NavidForm.handleSubmit();
                                      handleEditDone(editId, index);
                                    }}
                                    disabled={isLoadingAddAAIAirportDetail}
                                  >
                                    Done
                                  </button>
                                ) : (
                                  <IconButton
                                    color="primary"
                                    sx={{ p: 0 }}
                                    onClick={() =>
                                      handleEditdetail(navaidIds[index])
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                ))}
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (editId) {
                                    handleDelete();
                                  } else if (!isEmpty(AAIAirport)) {
                                    if (
                                      navaidIds.includes(
                                        createAd219NavidForm.values.navids[
                                          index
                                        ].id
                                      )
                                    ) {
                                      handleDelete(navaidIds[index]);
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  } else {
                                    arrayHelpers.remove(index);
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        </Stack>
                        <input
                          hidden
                          name={`aprons[${index}].id`}
                          onChange={createAd219NavidForm.handleChange}
                          value={createAd219NavidForm.values.navids[index].id}
                        />
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Identification</Box>
                          <Box
                            sx={styles.adInput}
                            display="flex"
                            alignItems="center"
                          >
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`navids[${index}].identification`}
                              value={
                                createAd219NavidForm.values.navids[index]
                                  .identification
                              }
                              onChange={createAd219NavidForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd219NavidForm.touched?.navids[index]
                                  .identification &&
                                Boolean(
                                  createAd219NavidForm.errors?.navids[index]
                                    .identification
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd219NavidForm.errors?.navids[index]
                                      .identification
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Type</Box>
                          <Box sx={styles.adInput}>
                            <FormControl
                              fullWidth
                              size="small"
                              sx={{ mb: 2 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd219NavidForm.touched?.navids[index]
                                  .type &&
                                Boolean(
                                  createAd219NavidForm.errors?.navids[index]
                                    .type
                                )
                              }
                            >
                              <MuiSelect
                                name={`navids[${index}].type`}
                                value={
                                  createAd219NavidForm.values.navids[index].type
                                }
                                onChange={createAd219NavidForm.handleChange}
                              >
                                {navidTypes.map((type) => (
                                  <MenuItem key={type.id} value={type.value}>
                                    {type.label}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {isErrors && isTouched
                                  ? createAd219NavidForm.errors?.navids[index]
                                      .type
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Runway Direction</Box>
                          <Box sx={{ pl: 2, width: "260px" }}>
                            <FormControl
                              fullWidth
                              size="small"
                              disabled={runwayDirection.length === 0}
                              sx={{ mb: 2 }}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd219NavidForm.touched?.navids[index]
                                  .rwy_dir &&
                                Boolean(
                                  createAd219NavidForm.errors?.navids[index]
                                    .rwy_dir
                                )
                              }
                            >
                              <MuiSelect
                                name={`navids[${index}].rwy_dir`}
                                value={
                                  createAd219NavidForm.values.navids[index]
                                    .rwy_dir
                                }
                                onChange={createAd219NavidForm.handleChange}
                              >
                                {runwayDirection.map((type) => (
                                  <MenuItem
                                    key={type.id}
                                    value={type.rwy_dir_designator}
                                  >
                                    {type.rwy_dir_designator}
                                  </MenuItem>
                                ))}
                              </MuiSelect>
                              <FormHelperText>
                                {runwayDirection.length === 0 &&
                                  "No runway direction found. Please upload runway direction first."}
                                {isErrors && isTouched
                                  ? createAd219NavidForm.errors?.navids[index]
                                      .rwy_dir
                                  : ""}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Stack>
                        {(createAd219NavidForm.values.navids[index].type ===
                          "ILS" ||
                          createAd219NavidForm.values.navids[index].type ===
                            "ILS_DME") && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Signal Performance</Box>
                            <Box
                              sx={styles.adInput}
                              display="flex"
                              alignItems="center"
                            >
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`navids[${index}].signalPerformance`}
                                value={
                                  createAd219NavidForm.values.navids[index]
                                    .signalPerformance
                                }
                                onChange={createAd219NavidForm.handleChange}
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd219NavidForm.touched?.navids[index]
                                    .signalPerformance &&
                                  Boolean(
                                    createAd219NavidForm.errors?.navids[index]
                                      .signalPerformance
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd219NavidForm.errors?.navids[index]
                                        .signalPerformance
                                    : ""
                                }
                                sx={{ mb: 2, mt: 2 }}
                              />
                            </Box>
                          </Stack>
                        )}
                        {(createAd219NavidForm.values.navids[index].type ===
                          "ILS" ||
                          createAd219NavidForm.values.navids[index].type ===
                            "ILS_DME") && (
                          <Accordion
                            sx={{ boxShadow: "unset", m: "0px !important" }}
                          >
                            <AccordionSummary
                              expandIcon={<MdOutlineKeyboardArrowDown />}
                              aria-controls="loc-content"
                              id="loc-header"
                              className="navaid-summary"
                            >
                              <Typography>Localizer</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Frequency</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].LOC.frequency`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.frequency
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.LOC?.frequency &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.frequency
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.LOC?.frequency
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          MHZ
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Latitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].LOC.latitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.latitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.LOC?.latitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.latitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.LOC?.latitude
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Longitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].LOC.longitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.longitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.LOC?.longitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.longitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.LOC?.longitude
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Hours of Operation
                                </Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <SelectInput
                                    name={`navids[${index}].LOC.hours_of_operation`}
                                    value={find(hoursOfOperation, {
                                      value:
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.LOC?.hours_of_operation,
                                    })}
                                    onChange={(option) => {
                                      createAd219NavidForm.setFieldValue(
                                        `navids[${index}].LOC.hours_of_operation`,
                                        option.value
                                      );
                                    }}
                                    options={hoursOfOperation}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Elevation</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].LOC.elevation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.LOC?.elevation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.LOC?.elevation &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.elevation
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.LOC?.elevation
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          FT
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Remarks</Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <FormControl
                                    variant="outlined"
                                    sx={{ mt: 2, width: "100%" }}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.LOC?.remarks &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.LOC?.remarks
                                      )
                                    }
                                  >
                                    <TextareaAutosize
                                      minRows={2}
                                      name={`navids[${index}].LOC.remarks`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.LOC?.remarks
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      aria-describedby="aerodrome-loc-remarks"
                                    />
                                    <FormHelperText id="aerodrome-loc-remarks">
                                      {isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.LOC?.remarks
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {(createAd219NavidForm.values.navids[index].type ===
                          "ILS" ||
                          createAd219NavidForm.values.navids[index].type ===
                            "ILS_DME") && (
                          <Accordion
                            sx={{
                              boxShadow: "unset",
                              m: "0px !important",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<MdOutlineKeyboardArrowDown />}
                              aria-controls="loc-content"
                              id="loc-header"
                              className="navaid-summary"
                            >
                              <Typography>Glide Path</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Frequency</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].GP.frequency`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.frequency
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.GP?.frequency &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.frequency
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.GP?.frequency
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          MHZ
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Latitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].GP.latitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.latitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.GP?.latitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.latitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.GP?.latitude
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Longitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].GP.longitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.longitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.GP?.longitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.longitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.GP?.longitude
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Hours of Operation
                                </Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <SelectInput
                                    name={`navids[${index}].GP.hours_of_operation`}
                                    value={find(hoursOfOperation, {
                                      value:
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.GP?.hours_of_operation,
                                    })}
                                    onChange={(option) => {
                                      createAd219NavidForm.setFieldValue(
                                        `navids[${index}].GP.hours_of_operation`,
                                        option.value
                                      );
                                    }}
                                    options={hoursOfOperation}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Elevation</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].GP.elevation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.GP?.elevation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.GP?.elevation &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.elevation
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.GP?.elevation
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          FT
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Remarks</Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <FormControl
                                    variant="outlined"
                                    sx={{ mt: 2, width: "100%" }}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.GP?.remarks &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.GP?.remarks
                                      )
                                    }
                                  >
                                    <TextareaAutosize
                                      minRows={2}
                                      name={`navids[${index}].GP.remarks`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.GP?.remarks
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      aria-describedby="aerodrome-loc-remarks"
                                    />
                                    <FormHelperText id="aerodrome-loc-remarks">
                                      {isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.GP?.remarks
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {createAd219NavidForm.values.navids[index].type ===
                          "ILS_DME" && (
                          <Accordion
                            sx={{ boxShadow: "unset", m: "0px !important" }}
                          >
                            <AccordionSummary
                              expandIcon={<MdOutlineKeyboardArrowDown />}
                              aria-controls="loc-content"
                              id="loc-header"
                              className="navaid-summary"
                            >
                              <Typography>DME</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Channel</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].DME.channel`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.channel
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.DME?.channel &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.channel
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.DME?.channel
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      readOnly: false,
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Latitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].DME.latitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.latitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.DME?.latitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.latitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.DME?.latitude
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Longitude</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].DME.longitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.longitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.DME?.longitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.longitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.DME?.longitude
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Hours of Operation
                                </Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <SelectInput
                                    name={`navids[${index}].DME.hours_of_operation`}
                                    value={find(hoursOfOperation, {
                                      value:
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.DME?.hours_of_operation,
                                    })}
                                    onChange={(option) => {
                                      createAd219NavidForm.setFieldValue(
                                        `navids[${index}].DME.hours_of_operation`,
                                        option.value
                                      );
                                    }}
                                    options={hoursOfOperation}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Elevation</Box>
                                <Box
                                  sx={{ pl: 1, minWidth: "260px" }}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].DME.elevation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.DME?.elevation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.DME?.elevation &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.elevation
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.DME?.elevation
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          FT
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Remarks</Box>
                                <Box sx={{ pl: 1, minWidth: "260px" }}>
                                  <FormControl
                                    variant="outlined"
                                    sx={{ mt: 2, width: "100%" }}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.DME?.remarks &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.DME?.remarks
                                      )
                                    }
                                  >
                                    <TextareaAutosize
                                      minRows={2}
                                      name={`navids[${index}].DME.remarks`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.DME?.remarks
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      aria-describedby="aerodrome-loc-remarks"
                                    />
                                    <FormHelperText id="aerodrome-loc-remarks">
                                      {isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.DME?.remarks
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Stack>
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {createAd219NavidForm.values.navids[index].type &&
                          createAd219NavidForm.values.navids[index].type !==
                            "ILS" &&
                          createAd219NavidForm.values.navids[index].type !==
                            "ILS_DME" && (
                            <Fragment>
                              {createAd219NavidForm.values.navids[index]
                                .type !== "DME" && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ pl: 2, pr: 2 }}
                                >
                                  <Box sx={{ width: "30%" }}>Frequency</Box>
                                  <Box
                                    sx={styles.adInput}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      type="number"
                                      name={`navids[${index}].frequency`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.frequency
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      error={
                                        isTouched &&
                                        isErrors &&
                                        createAd219NavidForm.touched?.navids[
                                          index
                                        ]?.frequency &&
                                        Boolean(
                                          createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.frequency
                                        )
                                      }
                                      helperText={
                                        isErrors && isTouched
                                          ? createAd219NavidForm.errors?.navids[
                                              index
                                            ]?.frequency
                                          : ""
                                      }
                                      sx={{ mt: 2 }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            MHZ
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              )}
                              {(createAd219NavidForm.values.navids[index]
                                .type === "DME" ||
                                createAd219NavidForm.values.navids[index]
                                  .type === "VOR_DME") && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ pl: 2, pr: 2 }}
                                >
                                  <Box sx={{ width: "30%" }}>Channel</Box>
                                  <Box
                                    sx={styles.adInput}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name={`navids[${index}].channel`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.channel
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      error={
                                        isTouched &&
                                        isErrors &&
                                        createAd219NavidForm.touched?.navids[
                                          index
                                        ]?.channel &&
                                        Boolean(
                                          createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.channel
                                        )
                                      }
                                      helperText={
                                        isErrors && isTouched
                                          ? createAd219NavidForm.errors?.navids[
                                              index
                                            ]?.channel
                                          : ""
                                      }
                                      sx={{ mt: 2 }}
                                      InputProps={{
                                        readOnly: false,
                                      }}
                                    />
                                  </Box>
                                </Stack>
                              )}
                              {createAd219NavidForm.values.navids[index]
                                .type === "NDB" && (
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ pl: 2, pr: 2 }}
                                >
                                  <Box sx={{ width: "30%" }}>Class</Box>
                                  <Box
                                    sx={styles.adInput}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      name={`navids[${index}].class`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.class
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      error={
                                        isTouched &&
                                        isErrors &&
                                        createAd219NavidForm.touched?.navids[
                                          index
                                        ]?.class &&
                                        Boolean(
                                          createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.class
                                        )
                                      }
                                      helperText={
                                        isErrors && isTouched
                                          ? createAd219NavidForm.errors?.navids[
                                              index
                                            ]?.class
                                          : ""
                                      }
                                      sx={{ mt: 2 }}
                                    />
                                  </Box>
                                </Stack>
                              )}
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Latitude</Box>
                                <Box
                                  sx={styles.adInput}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].latitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.latitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.latitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.latitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.latitude
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Longitude</Box>
                                <Box
                                  sx={styles.adInput}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`navids[${index}].longitude`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.longitude
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.longitude &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.longitude
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.longitude
                                        : ""
                                    }
                                    sx={{ mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2, mt: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Hours of Operation
                                </Box>
                                <Box sx={styles.adInput}>
                                  <SelectInput
                                    name={`navids[${index}].hours_of_operation`}
                                    value={find(hoursOfOperation, {
                                      value:
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.hours_of_operation,
                                    })}
                                    onChange={(option) => {
                                      createAd219NavidForm.setFieldValue(
                                        `navids[${index}].hours_of_operation`,
                                        option.value
                                      );
                                    }}
                                    options={hoursOfOperation}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2, mt: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Elevation</Box>
                                <Box
                                  sx={styles.adInput}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    name={`navids[${index}].elevation`}
                                    value={
                                      createAd219NavidForm.values.navids[index]
                                        ?.elevation
                                    }
                                    onChange={createAd219NavidForm.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.elevation &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.elevation
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.elevation
                                        : ""
                                    }
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          FT
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2, mt: 2, mb: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>Remarks</Box>
                                <Box sx={styles.adInput}>
                                  <FormControl
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd219NavidForm.touched?.navids[
                                        index
                                      ]?.remarks &&
                                      Boolean(
                                        createAd219NavidForm.errors?.navids[
                                          index
                                        ]?.remarks
                                      )
                                    }
                                  >
                                    <TextareaAutosize
                                      minRows={2}
                                      name={`navids[${index}].remarks`}
                                      value={
                                        createAd219NavidForm.values.navids[
                                          index
                                        ]?.remarks
                                      }
                                      onChange={
                                        createAd219NavidForm.handleChange
                                      }
                                      aria-describedby="aerodrome-loc-remarks"
                                    />
                                    <FormHelperText id="aerodrome-loc-remarks">
                                      {isErrors && isTouched
                                        ? createAd219NavidForm.errors?.navids[
                                            index
                                          ]?.remarks
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Stack>
                            </Fragment>
                          )}
                        <Divider />
                      </Fragment>
                    );
                  })}
                  <PrimaryButton
                    label="Add Navid"
                    variant="outlined"
                    sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                    disabled={runwayDirection.length === 0}
                    onClick={() => arrayHelpers.push(navidDetail)}
                  />
                </div>
              )}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={styles.adFormBtn}
          >
            <LightButton
              label="Clear"
              sx={{ mr: 4 }}
              isLoading={false}
              onClick={() => {
                createAd219NavidForm.resetForm();
              }}
            />
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingTaxiwayUpload}
              disabled={
                isLoadingTaxiwayUpload || isLoadingData || Boolean(editId)
              }
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Paper>
  );
}
