export const styles = {
  userAvatar: {
    color: "#59178A",
    lineHeight: "22px",
    fontWeight: 500,
  },
  userName: {
    display: "flex",
    alignItems: "center",
    pb: 1,
    borderBottom: "1px solid",
    width: "100%",
  },
  userRegion: {
    color: "#59178A",
    lineHeight: "19px",
    fontSize: "14px",
  },
  logoutIcon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  userMenu: {
    top: "42px",
    left: "5px",
    borderRadius: "2px",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
