import React from "react";
import { orderBy } from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function VerticalSignificancePreviewTable({ data }) {
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>SNO</StyledTableCell>
            <StyledTableCell>UID</StyledTableCell>
            <StyledTableCell>LATITUDE</StyledTableCell>
            <StyledTableCell>LONGITUDE</StyledTableCell>
            <StyledTableCell>OBJECT NAME</StyledTableCell>
            <StyledTableCell>TOP ELEV. IN m. (EGM 08)</StyledTableCell>
            <StyledTableCell>DISTANCE IN M. FROM NEW ARP</StyledTableCell>
            <StyledTableCell>MAG. BRG.FROM NEW ARP</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["SNO"], ["asc"]).map((row, i) => {
            return (
              <TableRow key={i}>
                <StyledTableCell>{row["SNO"]}</StyledTableCell>
                <StyledTableCell>{row["UID"]}</StyledTableCell>
                <StyledTableCell>{row["LATITUDE"]}</StyledTableCell>
                <StyledTableCell>{row["LONGITUDE"]}</StyledTableCell>
                <StyledTableCell>{row["OBJECT NAME"]}</StyledTableCell>
                <StyledTableCell>
                  {row["TOP ELEV. IN M (EGM-08)"]}
                </StyledTableCell>
                <StyledTableCell>
                  {row["DISTANCE IN M. FROM NEW ARP"]}
                </StyledTableCell>
                <StyledTableCell>
                  {row["MAG. BRG.FROM NEW ARP"]}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
