import React from "react";
import {Box, Typography} from "@mui/material";

export default function DropDownLabel({ label, isRequired = true, ...props }) {
  return (
    <Box display="flex">
      <Typography variant="subtitle2" {...props}>
        {label}
      </Typography>
      {isRequired && <Typography color="error">*</Typography>}
    </Box>
  );
}
