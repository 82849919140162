import React, { Fragment, useEffect, useState } from "react";
import { Marker, CircleMarker, useMap } from "react-leaflet";
import { ReportingPointImgIcon } from "@utils/mapIcon";

export default function RenderWayPointsAirspace({ wayPointsAirspaceGeometry }) {
  const map = useMap();
  const [isShowIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (wayPointsAirspaceGeometry?.length === 1) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [wayPointsAirspaceGeometry]);

  map.on("zoomend", function (e) {
    if (wayPointsAirspaceGeometry?.length === 1) {
      return;
    }

    const zoomLevel = e.target.getZoom();
    if (zoomLevel >= 7) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  });

  const handlePolygonOver = (e) => {
    e.target.openPopup();
  };

  return (
    <Fragment>
      {!isShowIcon &&
        wayPointsAirspaceGeometry.map((waypoint, i) => {
          return (
            <CircleMarker
              key={i}
              pane="tooltipPane"
              center={{ lat: waypoint.dd_latitude, lng: waypoint.dd_longitude }}
              pathOptions={{
                color: "#8b0000",
                fillOpacity: 0.8,
                weight: 1,
              }}
              radius={4}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e),
              }}
            />
          );
        })}
      {isShowIcon &&
        wayPointsAirspaceGeometry.map((waypoint, i) => {
          return (
            <Marker
              key={i}
              pane="tooltipPane"
              icon={ReportingPointImgIcon}
              position={{
                lat: waypoint.dd_latitude,
                lng: waypoint.dd_longitude,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e),
              }}
            />
          );
        })}
    </Fragment>
  );
}
