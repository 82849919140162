/* eslint-disable */
import L from "leaflet";

/*
 * L.NonTiledLayer is an addon for leaflet which renders dynamic image overlays
 */

/*
 * L.NonTiledLayer.WMS is used for putting WMS non tiled layers on the map.
 */
// @ts-ignore
L.TileLayer.WMTS = L.TileLayer.extend({
  defaultWmtsParams: {
    service: "WMTS",
    request: "GetTile",
    version: "1.0.0",
    layer: "",
    style: "",
    tilematrixset: "",
    format: "image/jpeg",
    transparent: true,
  },

  initialize: function (url, options) {
    // (String, Object)
    this._url = url;
    var lOptions = {};
    var cOptions = Object.keys(options);
    cOptions.forEach((element) => {
      lOptions[element.toLowerCase()] = options[element];
    });
    var wmtsParams = L.extend({}, this.defaultWmtsParams);
    var tileSize = lOptions.tileSize || this.options.tileSize;
    if (lOptions.detectRetina && L.Browser.retina) {
      wmtsParams.width = wmtsParams.height = tileSize * 2;
    } else {
      wmtsParams.width = wmtsParams.height = tileSize;
    }
    for (var i in lOptions) {
      // all keys that are in defaultWmtsParams options go to WMTS params
      if (wmtsParams.hasOwnProperty(i) && i != "matrixIds") {
        wmtsParams[i] = lOptions[i];
      }
    }
    this.wmtsParams = wmtsParams;
    this.matrixIds = options.matrixIds || this.getDefaultMatrix();
    L.setOptions(this, options);
  },

  onAdd: function (map) {
    this._crs = this.options.crs || map.options.crs;
    L.TileLayer.prototype.onAdd.call(this, map);
    map.on("click", this.getFeatureInfo, this);
  },

  onRemove: function (map) {
    // Triggered when the layer is removed from a map.
    //   Unregister a click listener, then do all the upstream WMS things
    L.TileLayer.prototype.onRemove.call(this, map);
    map.off("click", this.getFeatureInfo, this);
  },

  getFeatureInfo: function (evt) {
    var url = this.getFeatureInfoUrl(evt);
    var showResults = L.Util.bind(this.showGetFeatureInfo, this);

    if (!url) return;
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        showResults(false, evt.latlng, data);
      })
      .catch((error) => {
        showResults("Error: grey index", evt.latlng, null);
      });
  },

  getFeatureInfoUrl: function (evt) {
    var tileSize = this.options.tileSize;
    var nwPoint = evt.sourceTarget._pixelOrigin;
    // nwPoint.x += 1;
    // nwPoint.y -= 1;
    var sePoint = nwPoint.add(new L.Point(tileSize, tileSize));
    var zoom = this._tileZoom;
    var nw = this._crs.project(this._map.unproject(nwPoint, zoom));
    var se = this._crs.project(this._map.unproject(sePoint, zoom));
    const tilewidth = se.x - nw.x;
    if (!this.matrixIds[zoom]) return null;
    var ident = this.matrixIds[zoom].identifier;
    var tilematrix = this.wmtsParams.tilematrixset + ":" + ident;
    var X0 = this.matrixIds[zoom].topLeftCorner.lng;
    var Y0 = this.matrixIds[zoom].topLeftCorner.lat;
    var nwx = (nw.x - X0) / tilewidth;
    var tilecol = Math.floor(nwx);
    var nwy = (nw.y - Y0) / tilewidth;
    var tilerow = -Math.floor(nwy);
    var tileI = Math.floor((nwx - tilecol) * tileSize);
    var tileJ = Math.floor((nwy - -tilerow) * tileSize);
    var params = {
      version: "1.0.0",
      request: "GetFeatureInfo",
      service: "WMTS",
      style: "",
      tilematrix: tilematrix,
      tilematrixset: this.wmtsParams.tilematrixset,
      layer: this.wmtsParams.layer,
      format: this.wmtsParams.format,
      infoformat: "application/json",
      TileCol: tilecol + 1,
      TileRow: tilerow,
      i: tileI,
      j: tileJ,
    };

    return this._url + L.Util.getParamString(params, this._url, true);
  },

  showGetFeatureInfo: function (err, latlng, content) {
    if (err) {
      console.log(err);
      return;
    } // do nothing if there's an error

    if (content && content.features && content.features.length > 0) {
      if (content.features[0].properties) {
        var data = content.features[0].properties.GRAY_INDEX;
        L.popup({ maxWidth: 800 })
          .setLatLng(latlng)
          .setContent(`<p>Elevation (m):${data}</p>`)
          .openOn(this._map);
      }
    }
  },

  getTileUrl: function (coords) {
    // (Point, Number) -> String
    var tileSize = this.options.tileSize;
    var nwPoint = coords.multiplyBy(tileSize);
    nwPoint.x += 1;
    nwPoint.y -= 1;
    var sePoint = nwPoint.add(new L.Point(tileSize, tileSize));
    var zoom = this._tileZoom;
    var nw = this._crs.project(this._map.unproject(nwPoint, zoom));
    var se = this._crs.project(this._map.unproject(sePoint, zoom));
    const tilewidth = se.x - nw.x;
    var ident = this.matrixIds[zoom].identifier;
    var tilematrix = this.wmtsParams.tilematrixset + ":" + ident;
    var X0 = this.matrixIds[zoom].topLeftCorner.lng;
    var Y0 = this.matrixIds[zoom].topLeftCorner.lat;
    var tilecol = Math.floor((nw.x - X0) / tilewidth);
    var tilerow = -Math.floor((nw.y - Y0) / tilewidth);
    var url = L.Util.template(this._url, { s: this._getSubdomain(coords) });
    return (
      url +
      L.Util.getParamString(this.wmtsParams, url) +
      "&tilematrix=" +
      tilematrix +
      "&tilerow=" +
      tilerow +
      "&tilecol=" +
      tilecol
    );
  },

  setParams: function (params, noRedraw) {
    L.extend(this.wmtsParams, params);
    if (!noRedraw) {
      this.redraw();
    }
    return this;
  },

  getDefaultMatrix: function () {
    /**
     * the matrix3857 represents the projection
     * for in the IGN WMTS for the google coordinates.
     */
    var matrixIds3857 = new Array(22);
    for (var i = 0; i < 22; i++) {
      matrixIds3857[i] = {
        identifier: "" + i,
        topLeftCorner: new L.LatLng(20037508.3428, -20037508.3428),
      };
    }
    return matrixIds3857;
  },
});

// @ts-ignore
L.tileLayer.wmts = function (url, options) {
  // @ts-ignore
  return new L.TileLayer.WMTS(url, options);
};
