import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const AntSwitch = styled(Switch)(
  ({
    theme,
    trackcolor,
    width,
    height,
    thumbwidth,
    thumbheight,
    basepadding,
    translatethumb,
    bordercolor,
  }) => ({
    width: width || 28,
    height: height || 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: basepadding || 2,
      "&.Mui-checked": {
        transform: `translateX(${translatethumb}px)`,
        color: "#ffffff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: trackcolor || "#6E3BD1",
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: bordercolor ? "#fff" : bordercolor,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: thumbwidth || 12,
      height: thumbheight || 12,
      borderRadius: "50%",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
      backgroundColor: bordercolor ? bordercolor : "#fff",
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
      backgroundColor: bordercolor ? "#fff" : "rgba(0,0,0,.25)",
      opacity: 0.6,
      boxSizing: "border-box",
      border: bordercolor ? `0.5px solid ${bordercolor}` : 0,
    },
  })
);

export default AntSwitch;
