import { useState } from "react";
import { useSelector } from "react-redux";
import { FaAngleLeft } from "react-icons/fa";
import { Box } from "@mui/material";
import Accordion from "@components/core/Accordion";
import AirportDetailCard from "@components/core/AirportDetailCard";
import AccordionDetails from "@components/core/AccordionDetails";
import MapWidget from "@components/core/MapWidget";

export default function AirportLegend({ selectedAirportDetail }) {
  const visualiseObstacles = useSelector(
    (state) => state.etod.visualiseObstacles || []
  );
  const analysisObstacles = useSelector(
    (state) => state.etod.analysisObstacles || []
  );
  const [expanded, setExpanded] = useState(true);

  const handleExpandAccordain = (name) => {
    setExpanded(name);
  };

  return (
    <Box variant="div" sx={{ width: "230px", mb: 1 }}>
      <Accordion
        sx={{ backgroundColor: "#ffffffcc", borderRadius: "5px" }}
        expanded={expanded}
      >
        <MapWidget
          expandIcon={
            <FaAngleLeft
              onClick={() => handleExpandAccordain(expanded ? false : true)}
            />
          }
          sx={{ backgroundColor: "#ffffff66", pt: "5px", pb: "5px" }}
        >
          Details of Airport
        </MapWidget>
        <AccordionDetails sx={{ backgroundColor: "#ffffff66" }}>
          <AirportDetailCard
            className="airport-detail-card"
            title="Airport ICAO Code"
            isTooltip={false}
            data={selectedAirportDetail.icao}
          />
          <AirportDetailCard
            className="airport-detail-card"
            title="Total Number of Obstacles"
            isTooltip={false}
            data={
              analysisObstacles?.length || visualiseObstacles?.length || "00"
            }
          />
          <AirportDetailCard
            className="airport-detail-card"
            title="ARP"
            isTooltip={false}
            data={selectedAirportDetail.icao}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
