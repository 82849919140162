import { useEffect } from "react";
import * as Cesium from "cesium";
import { useCesium } from "resium";
import { defaultLocationCenter } from "@constants/constant";

export default function Control3DMapCenter({
  airportLocation = defaultLocationCenter,
  zoomLevel,
}) {
  const { viewer } = useCesium();

  useEffect(() => {
    viewer.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(
        airportLocation[1],
        airportLocation[0],
        zoomLevel + 7000
      ),
      
    });
  }, [airportLocation, zoomLevel]); // eslint-disable-line

  return null;
}
