import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(0.8),
  "&:before": {
    display: "none",
  },
}));

export default Accordion;
