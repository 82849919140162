import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { styles } from "@styles/dataManagementStyles";

function Ad2DataEntrySubHeader({ isParentSorveyorPath, selectedAirport }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ ...styles.reviewDatasetBar, m: "0px", height: "72px" }}>
      <Box sx={styles.reviewDatasetBarinr}>
        <IconButton aria-label="back" onClick={() => navigate(-1)} sx={{p:0,ml:'-8px'}}>
          <ArrowleftIcon />
        </IconButton>
        <Typography
          variant="span"
          color={isParentSorveyorPath ? "primary" : "black"}
          sx={{pl:1,
            fontWeight: isParentSorveyorPath ? 600 : 500,
            fontSize: isParentSorveyorPath ? 18 : 16,
          }}
        >
          {isParentSorveyorPath ? "Add Data to Airport" : "back to selection"}
        </Typography>
      </Box>
      {!isParentSorveyorPath && (
        <>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "20px", fontWeight: 600 }}
            >
              {selectedAirport?.name} ({selectedAirport?.designator})
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.reviewDatasetBarinr,
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                Publication Date
              </Typography>
              <Typography variant="body2">
                {selectedAirport?.published_date}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                Effective Date
              </Typography>
              <Typography variant="body2">
                {selectedAirport?.effective_date}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Ad2DataEntrySubHeader;
