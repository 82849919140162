import React from "react";
import { orderBy } from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function ObstaclePreviewDataTable({ data }) {
  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>IDENTIFIER</StyledTableCell>
            <StyledTableCell>RWY /AREA AFFECTED</StyledTableCell>
            <StyledTableCell>OBSTACLE TYPE</StyledTableCell>
            <StyledTableCell>Latitude</StyledTableCell>
            <StyledTableCell>Longitude</StyledTableCell>
            <StyledTableCell>Elevation</StyledTableCell>
            <StyledTableCell>MARKING /LGT</StyledTableCell>
            <StyledTableCell>Horizontal Datum</StyledTableCell>
            <StyledTableCell>Vertical Datum</StyledTableCell>
            <StyledTableCell>UOM Elevation</StyledTableCell>
            <StyledTableCell>RWY Direction</StyledTableCell>
            <StyledTableCell>Airport</StyledTableCell>
            <StyledTableCell>REMARKS</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["IDENTIFIER"], ["asc"]).map((row) => {
            return (
              <TableRow key={row["IDENTIFIER"]}>
                <StyledTableCell>{row["IDENTIFIER"]}</StyledTableCell>
                <StyledTableCell>{row["RWY /AREA AFFECTED"]}</StyledTableCell>
                <StyledTableCell>{row["OBSTACLE TYPE"]}</StyledTableCell>
                <StyledTableCell>{row["Latitude"]}</StyledTableCell>
                <StyledTableCell>{row["Longitude"]}</StyledTableCell>
                <StyledTableCell>{row["Elevation"]}</StyledTableCell>
                <StyledTableCell>{row["MARKING /LGT"]}</StyledTableCell>
                <StyledTableCell>{row["Horizontal Datum"]}</StyledTableCell>
                <StyledTableCell>{row["Vertical Datum"]}</StyledTableCell>
                <StyledTableCell>{row["UOM Elevation"]}</StyledTableCell>
                <StyledTableCell>{row["RWY Direction"]}</StyledTableCell>
                <StyledTableCell>{row["Airport"]}</StyledTableCell>
                <StyledTableCell>{row["REMARKS"]}</StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
