import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Table,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function HandlingServices({
  activeCategory,
  selctedAirport,
  handlingservice,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;
  const fuel =
    handlingservice && handlingservice.Fuel && handlingservice.Fuel.length > 0
      ? handlingservice.Fuel.map((f) => {
          return f.category;
        })
      : [];
  const oil =
    handlingservice && handlingservice.Oil && handlingservice.Oil.length > 0
      ? handlingservice.Oil.map((f) => {
          return f.category;
        })
      : [];

  const fuelOil = `${fuel.join(",")} ${oil.join(",")}`;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {handlingservice ? (
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Cargo-handling facilities
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.Cargo_handling_facilities || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Fuel and Oil types
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {fuelOil.length > 0 ? fuelOil : "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Fuelling facilities and capacity
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.Fuelling_facilities || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  De-icing facilities
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.Deicing || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Hangar space for visiting aircraft
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.HANGAR || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Repair facilities for visiting aircraft
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.REPAIR || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>Remarks</StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {handlingservice.HANDLING_SERVICES_REMARK || "NIL"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}
