import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingAlleChartAirport: false,
  alleChartAirports: [],
  isLoadingechartPDFData: false,
  echartPDFData: [],
  isLoadingechartData: false,
  echartData: null,
  isLoadingEditDeletePolyline: false,
  isLoadingAlleChartExportUrl: false,
  alleChartExportUrls: [],
  isLoadingATOCAData: false,
  atocaData: null,
  isLoadingEchartTableData: false,
  chartTableData: null,
};

export const eChart = createSlice({
  name: "eChart",
  initialState,
  reducers: {
    setLoadingAlleChartAirport: (state, action) => {
      state.isLoadingAlleChartAirport = action.payload;
    },
    setAlleChartAirports: (state, action) => {
      state.alleChartAirports = action.payload;
    },
    setLoadingechartPDFData: (state, action) => {
      state.isLoadingechartPDFData = action.payload;
    },
    setechartPDFData: (state, action) => {
      state.echartPDFData = action.payload;
    },
    setLoadingechartData: (state, action) => {
      state.isLoadingechartData = action.payload;
    },
    setechartData: (state, action) => {
      state.echartData = action.payload;
    },
    setLoadingEditDeletePolyline: (state, action) => {
      state.isLoadingEditDeletePolyline = action.payload;
    },
    setLoadingAlleChartExportUrl: (state, action) => {
      state.isLoadingAlleChartExportUrl = action.payload;
    },
    setAlleChartExportUrls: (state, action) => {
      state.alleChartExportUrls = action.payload;
    },
    setLoadingATOCAData: (state, action) => {
      state.isLoadingATOCAData = action.payload;
    },
    setATOCAData: (state, action) => {
      state.atocaData = action.payload;
    },
    setLoadingEchartTableData: (state, action) => {
      state.isLoadingEchartTableData = action.payload;
    },
    setTableData: (state, action) => {
      state.chartTableData = action.payload;
    },
  },
});
