import { btnHeight48, theme } from "./theme";

export const styles = {
  mainNosurveyor: {
    width: "100vw",
    height: "100%",
    justifyContent: "center",
  },
  reviewListTableStatus: {
    padding: 5,
    color: theme.palette.white.main,
    fontWeight: "bold",
    borderRadius: 5,
    width: 100,
    textAlign: "center",
    margin: "3px 0",
  },
  reviewSidebarCat: {
    width: "230px",
    height: "100%",
    borderRight: "0.5px solid #BDBDBD",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
  },
  catTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: "#030303",
    height: "43px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mb: 1,
    "::after": {
      content: "''",
      width: "230px",
      height: "1px",
      background: "#BDBDBD",
      position: "absolute",
      bottom: "0",
      left: "0",
    },
  },
  reviewFilterDate: {
    zIndex: 6,
    pt: 1,
    pb: 1,
    pr: 2,
  },
  reviewCat: {
    paddingLeft: "10px",
    height: "56px",
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  airportDetailTable: {
    height: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
    ".MuiPaper-elevation": {
      mb: 0,
    },
  },
  addAirportName: {
    height: "40px",
    backgroundColor: theme.palette.secondary.light,
    pl: "13px",
  },
  addAirportCatMain: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  addAirportCat: {
    borderRadius: "5px 5px 0px 0px",
    // boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
    height: "48px",
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.tertiary.main,
  },
  addAirportCatLabel: {
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.black.light2,
    mr: 1,
  },
  createAirportLabel: {
    pt: 1,
    pb: 0.5,
    pl: 2,
  },
  airportExpDownload: {
    width: "100%",
    borderTop: "1px solid #828282",
    paddingTop: 1,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  // crcCheckFileLabel: {
  //   backgroundColor: theme.palette.secondary.light,
  //   pt: 1,
  //   pb: 0.5,
  //   pl: 2,
  //   display: "flex",
  //   justifyContent: "flex-start",
  //   alignItems: "center",
  // },
  adForm: {
    height: "calc(100% - 56px)",
    overflow: "auto",
    paddingLeft: "24px",
  },
  adFormBtn: {
    pr: 2,
    // boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.15)",
    borderRadius: "0px 0px 5px 5px",
    height: "56px",
    backgroundColor: theme.palette.tertiary.main,
  },
  adFuelMain: {
    width: "57.5%",
    borderBottom: `0.5px solid ${theme.palette.grey.borderclr}`,
    pb: 1,
    pt: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  adVORMain: {
    width: "65%",
    borderBottom: `0.5px solid ${theme.palette.grey.borderclr}`,
    pb: 1,
    pt: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  adFuelLabel: {
    width: "85%",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  adFuelDeleteBtn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "15%",
  },
  ad28Btn: {
    width: 58,
    fontSize: "12px",
    backgroundColor: theme.palette.primary.main,
    border: 0,
    color: theme.palette.white.main,
    height: 18,
    borderRadius: 3,
    cursor: "pointer",
  },
  ad210Label: {
    mr: 4,
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.black.main,
  },
  ad210EditForm: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  },
  ad210SubCanBtn: {
    pt: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: "0.5px solid #96909061",
  },
  ad214Label: {
    pl: 2,
    pr: 2,
    mb: 2,
    mt: 2,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  ad214RwyLabel: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.black.main,
    pt: 1,
  },
  ad224Chart: {
    display: "flex",
    justifyContent: "space-between",
    pl: 2,
    pr: 2,
    mt: 2,
    mb: 2,
  },
  adInput: {
    pl: 2,
    minWidth: "260px",
  },
  adTaxiway: {
    width: "65%",
    borderBottom: `0.5px solid ${theme.palette.grey.borderclr}`,
    pb: 1,
    pt: 1,
  },
  adFormField: {
    height: "calc(100% - 18px)",
    overflow: "auto",
    ml: 3,
  },
  aaiUserDashboard: {
    color: theme.palette.black.light,
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  UserDashboardBtn: {
    padding: "5px 30px",
    minWidth: "250px",
    height: btnHeight48,
    lineHeight: "1.9",
    fontSize: "22px",
    fontWeight: "600",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    letterSpacing: "0",
  },
  ad212RwyDetailLabel: {
    pt: 1,
    pb: 1,
    backgroundColor: theme.palette.secondary.light,
  },
  adminWelcomeLabel: {
    marginBottom: "125px",
    textAlign: "center",
    color: theme.palette.black.light,
  },
  badgeStyle: {
    "& .MuiBadge-standard": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.red.main,
      fontSize: "10px",
    },
  },
  dataRevalidbadgeStyle: {
    "& .MuiBadge-standard": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.red.main,
      minWidth: "15px",
      height: "15px",
      top: "14px",
      fontSize: "10px",
    },
  },
  ad2dataEntryCat: {
    mt: 1,
    height: "calc(100vh - 171px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
  },
  reviewAcceptRow: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
  },
  reviewDatasetBar: {
    mt: "19px",
    mb: "19px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  reviewDatasetBarinr: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  arpReviewCategoryList: {
    marginTop: "0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    border: "0.5px solid #BDBDBD",
    borderRadius: 0.5,
  },
  aixmCatTable: {
    width: "calc(100% - 300px)",
    padding: "8px",
  },
  aerodromeOLStable: {
    height: "calc(100% - 44px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: theme.palette.white.main,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "gray",
      borderRadius: "5px",
    },
  },
  reviewDatasetBtn: {
    textTransform: "none",
    height: "32px",
    fontSize: "12px",
    pr: 3.2,
    pl: 3.2,
  },
  surSubheadermain: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "83%",
  },
  surSubheaderinr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  surheadtitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  surheadvalue: {
    fontSize: "14px",
    fontWeight: 400,
    color: theme.palette.black.main,
  },
  surheadvaluebtn: {
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.white.main,

    p: "1px 18px",
    borderRadius: "3px",
  },
};
