import React from "react";
import { theme } from "@styles/theme";

export const UploadIcon = ({ disabled, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.97 3.75122C2.90288 3.68433 2.84962 3.60486 2.81328 3.51736C2.77694 3.42985 2.75823 3.33604 2.75823 3.24129C2.75823 3.14654 2.77694 3.05273 2.81328 2.96522C2.84962 2.87772 2.90288 2.79825 2.97 2.73137L5.49 0.211737C5.55689 0.144623 5.63637 0.0913726 5.72389 0.0550381C5.81141 0.0187036 5.90524 0 6 0C6.09476 0 6.18859 0.0187036 6.27611 0.0550381C6.36363 0.0913726 6.44311 0.144623 6.51 0.211737L9.03 2.73137C9.09712 2.79825 9.15038 2.87772 9.18672 2.96522C9.22306 3.05273 9.24177 3.14654 9.24177 3.24129C9.24177 3.33604 9.22306 3.42985 9.18672 3.51736C9.15038 3.60486 9.09712 3.68433 9.03 3.75122C8.89413 3.8853 8.71091 3.96049 8.52 3.96049C8.32909 3.96049 8.14587 3.8853 8.01 3.75122L6.72 2.46141V7.44067C6.72 7.6316 6.64414 7.81471 6.50912 7.94971C6.37409 8.08472 6.19096 8.16057 6 8.16057C5.80904 8.16057 5.62591 8.08472 5.49088 7.94971C5.35586 7.81471 5.28 7.6316 5.28 7.44067V2.46141L3.99 3.75122C3.92311 3.81833 3.84363 3.87158 3.75611 3.90791C3.66859 3.94425 3.57476 3.96295 3.48 3.96295C3.38524 3.96295 3.29141 3.94425 3.20389 3.90791C3.11637 3.87158 3.03689 3.81833 2.97 3.75122ZM11.28 6.72078C11.089 6.72078 10.9059 6.79662 10.7709 6.93163C10.6359 7.06664 10.56 7.24974 10.56 7.44067V10.5602H1.44V7.44067C1.44 7.24974 1.36414 7.06664 1.22912 6.93163C1.09409 6.79662 0.910956 6.72078 0.72 6.72078C0.529044 6.72078 0.345909 6.79662 0.210883 6.93163C0.0758569 7.06664 0 7.24974 0 7.44067V10.8002C0.00157707 11.1179 0.128512 11.4222 0.353214 11.6468C0.577917 11.8715 0.882226 11.9984 1.2 12H10.8C11.1178 11.9984 11.4221 11.8715 11.6468 11.6468C11.8715 11.4222 11.9984 11.1179 12 10.8002V7.44067C12 7.24974 11.9241 7.06664 11.7891 6.93163C11.6541 6.79662 11.471 6.72078 11.28 6.72078Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
  </svg>
);
