import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Box,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { theme } from "@styles/theme";

export default function FIRUIRDetail({ isLoadingFIRData, FIRData }) {
  const firDescription = FIRData?.description;
  const firRemark = FIRData?.remarks;
  const firRowSpan =
    FIRData?.extracol.length === 0 ? 2 : FIRData?.extracol.length + 1;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ borderRadius: "5px 0px 0px 0px" }}>
              Name
            </StyledTableCell>
            <StyledTableCell>Lateral Limits, Class of airspace</StyledTableCell>
            <StyledTableCell>Vertical Limits</StyledTableCell>
            <StyledTableCell>Unit providing service</StyledTableCell>
            <StyledTableCell>
              Call sign, Languages, Hours of service
            </StyledTableCell>
            <StyledTableCell>Frequency/Purpose</StyledTableCell>
            <StyledTableCell sx={{ borderRadius: "0px 5px 0px 0px" }}>
              Remarks
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: theme.palette.white.main }}>
          {isLoadingFIRData && (
            <TableRow>
              <TableCell align="center" colSpan={7}>
                Loading...
              </TableCell>
            </TableRow>
          )}
          {!isLoadingFIRData && !FIRData && (
            <TableRow>
              <TableCell align="center" colSpan={7}>
                No Record Found.
              </TableCell>
            </TableRow>
          )}
          {FIRData && (
            <Fragment>
              <TableRow>
                <StyledTableCell rowSpan={firRowSpan}>
                  {FIRData?.name || "-"}
                </StyledTableCell>
                <StyledTableCell rowSpan={firRowSpan}>
                  {firDescription || "-"}
                  <Box>{FIRData?.Airspace_class_note}</Box>
                </StyledTableCell>
                <StyledTableCell rowSpan={firRowSpan}>
                  {FIRData?.AV_upperLimit || "-"} /{" "}
                  {FIRData?.AV_lowerLimit || "-"}
                </StyledTableCell>
              </TableRow>
              {FIRData?.extracol.length > 0 &&
                FIRData?.extracol.map((col, j) => {
                  return (
                    <TableRow key={`${j}_${FIRData?.name}`}>
                      <StyledTableCell>{col.unit}</StyledTableCell>
                      <StyledTableCell>{`${col.call_sign}, ${col.languages}, ${col.hour}`}</StyledTableCell>
                      <StyledTableCell>{col.frequency}</StyledTableCell>
                      {j === 0 && (
                        <StyledTableCell rowSpan={FIRData?.extracol.length}>
                          {firRemark || "-"}
                        </StyledTableCell>
                      )}
                    </TableRow>
                  );
                })}
              {FIRData?.extracol.length === 0 &&
                [1].map((col, j) => {
                  return (
                    <TableRow key={`${j}_${FIRData?.name}`}>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      <StyledTableCell>-</StyledTableCell>
                      {j === 0 && (
                        <StyledTableCell rowSpan={firRowSpan}>
                          -
                        </StyledTableCell>
                      )}
                    </TableRow>
                  );
                })}
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
