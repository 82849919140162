import React, { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaBell } from "react-icons/fa";
import Select from "react-select";
import { Badge, Box, Divider, Typography } from "@mui/material";
import { roles } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import { surveyorCategories } from "@constants/surveyorData";
import { styles } from "@styles/dataManagementStyles";
import DropDownLabel from "./DropDownLabel";
import { PrimaryButton, UploadButton } from "./button";

export default function DataManagementSideBar({
  isUploadSurveyFile,
  isLoadingCompareData,
  isActiveAirport,
  selctedCategory,
  isActiveCategory,
  handleCategory,
  handleTemplateDownload,
  handleFileUpload,
}) {
  const navigate = useNavigate();
  const uploadRef = useRef();
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;
  const isAAI = user?.role === roles.aai;
  const isSurveyor = user?.role === roles.surveyor;
  const {
    isDownloadTemplate,
    isLoadingReviewFeaturesdata,
    isLoadingGetallReqPassword,
    reviewBackFeaturesData,
    allReqPassword,
  } = useSelector((state) => state.survey);

  const handleCRC = () => {
    navigate(routeNames.CRCCheck);
  };

  return (
    <div className="sidebar">
      {isAdmin && (
        <Fragment>
          <div className="admin-sidebar-footer">
            <Badge badgeContent={allReqPassword.length} sx={styles.badgeStyle}>
              <PrimaryButton
                label="Password Requests"
                onClick={() => navigate(routeNames.NewPassReq)}
                startIcon={<FaBell />}
                disabled={
                  isLoadingGetallReqPassword || allReqPassword.length === 0
                }
                isLoading={isLoadingGetallReqPassword}
                sx={{ width: 250 }}
              />
            </Badge>
            <PrimaryButton
              label="Check CRC32Q"
              onClick={handleCRC}
              sx={{ width: 250, mt: 2 }}
            />
          </div>
        </Fragment>
      )}
      {isAAI && (
        <Fragment>
          <div className="admin-sidebar-footer">
            <Badge badgeContent={0} sx={styles.badgeStyle}>
              <PrimaryButton
                label="Data Re-Validation"
                onClick={() => {}}
                disabled={true}
                isLoading={false}
                sx={{ width: 250 }}
              />
            </Badge>
            <PrimaryButton
              label="Check CRC32Q"
              onClick={handleCRC}
              sx={{ width: 250, mt: 2 }}
            />
          </div>
        </Fragment>
      )}
      {isSurveyor && (
        <Fragment>
          <Box sx={{ mb: 1 }}>
            <DropDownLabel label="Category" isRequired />
            <Select
              isClearable
              isDisabled={isActiveAirport}
              value={selctedCategory || null}
              placeholder="Select..."
              onChange={handleCategory}
              options={surveyorCategories}
              styles={{
                menuList: (provided) => ({ ...provided, overflow: "hidden" }),
              }}
            />
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <div className="surveyor-sidebar-footer">
            <Box sx={{ width: "100%" }}>
              <UploadButton
                ref={uploadRef}
                label="Upload"
                accept=".xlsx"
                variant="contained"
                loadingIndicator={
                  isLoadingCompareData ? "Comparing..." : "Uploading..."
                }
                disabled={
                  isActiveCategory || isUploadSurveyFile || isLoadingCompareData
                }
                isLoading={isUploadSurveyFile || isLoadingCompareData}
                handleUpload={(event) => handleFileUpload(event, uploadRef)}
                sx={{ width: "100%" }}
              />
              <Typography
                variant="caption"
                display="block"
                align="center"
                gutterBottom
              >
                File should be less than 10 MB.
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <PrimaryButton
                label="Download Template"
                onClick={() => handleTemplateDownload(selctedCategory.template)}
                disabled={isActiveCategory || isDownloadTemplate}
                isLoading={isDownloadTemplate}
                sx={{ width: "100%"}}
              />
              <Badge
                badgeContent={reviewBackFeaturesData.length}
                sx={styles.dataRevalidbadgeStyle}
              >
                <PrimaryButton
                  label="Data Re-Validation"
                  onClick={() => navigate(routeNames.SurveyorRevalidation)}
                  disabled={
                    isLoadingReviewFeaturesdata ||
                    reviewBackFeaturesData.length === 0
                  }
                  isLoading={isLoadingReviewFeaturesdata}
                  sx={{ width: "100%", mt: 2 }}
                />
              </Badge>
              <PrimaryButton
                label="Check CRC32Q"
                onClick={handleCRC}
                sx={{ width: "100%", mt: 2 }}
              />
            </Box>
          </div>
        </Fragment>
      )}
    </div>
  );
}
