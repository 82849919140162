import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderArea({
  etodAreaList,
  selectedAreas,
  getAreaColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const areaColor = getAreaColors();
  const visibleAreaList = chain(etodAreaList)
    .filter((area) => {
      let name = `${area.rwy}_${area.sub_category}`;
      if (area.category === "AREA4") {
        name = `${area.rwy}_${area.category}`;
      }
      return selectedAreas.includes(`${name}__${areaColor[name]}`);
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleAreaList.map((area, i) => {
        let name = `${area.rwy}_${area.sub_category}`;
        let selectedName = `${selectedOverE?.rwy}_${selectedOverE?.sub_category}`;
        if (area.category === "AREA4") {
          name = `${area.rwy}_${area.category}`;
          selectedName = `${selectedOverE?.rwy}_${selectedOverE?.category}`;
        }
        const areaCoordinates = area.coordinates.coordinates;
        const areaCoordinateMap = flatten(areaCoordinates).map((areaCoords) => {
          return areaCoords.map((areal) => {
            return [areal[1], areal[0]];
          });
        });

        return (
          <Polygon
            key={i}
            pane="tilePane"
            positions={areaCoordinateMap}
            pathOptions={{
              fillColor: areaColor[name],
              color:
                selectedName === name ? areaHighLightColor : areaColor[name],
              weight: selectedName === name ? 3 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, area),
              mouseout: () => handlePolygonOut(),
            }}
          />
        );
      })}
    </Fragment>
  );
}
