import React, { Fragment, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CoordinateInput from "react-coordinate-input";
import {
  MapContainer,
  ZoomControl,
  Marker,
  useMapEvents,
  Popup,
} from "react-leaflet";
import CopyToClipboard from "react-copy-to-clipboard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  Box,
  IconButton,
  InputAdornment,
  Select,
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { FaCopy } from "react-icons/fa";
import DeleteIcon from "@assets/svg/DeleteIcon";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { CirclePlusIcon } from "@assets/svg/circle-plus";
import { RoundRightArrowIcon } from "@assets/svg/RoundRightArrowIcon";
import { defaultLocationCenter, ACCESS_TOKEN } from "@constants/constant";
import { createAirportGenerateSurfaceSchema } from "@constants/etodData";
import {
  rwySurfaceCategory800,
  rwySurfaceCategory8001200,
  rwySurfaceCategory12001800,
  rwySurfaceCategoryUp1800,
} from "@constants/etodData";
import { PrimaryButton, OutlinedButton } from "@components/button";
import Header from "@components/Header";
import GoogleMapLayer from "@components/map/GoogleMapLayer";
import ControlMapCenter from "@components/map/ControlMapCenter";
import {
  postEtodAirportCreateLayer,
  getEtodAirportLayer,
} from "@store/etod/actions";
import { styles } from "@styles/etodStyles";
import { styles as dataMngStyles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";
import { convertToDms } from "@utils/geocoords";
import { getAsyncStorageValue } from "@utils/localStorage";
import { LocationImgIcon, EmptyImgIcon } from "@utils/mapIcon";

const runwayDetail = {
  rwywidth: "",
  thrLatitudeA: "",
  thrLongitudeA: "",
  thrElvA: "",
  endLatitudeA: "",
  endLongitudeA: "",
  endElvA: "",
  cwyA: "",
  bearingA: "",
  toraA: "",
  rdA: "",
  categoryA: "",
  thrLatitudeB: "",
  thrLongitudeB: "",
  thrElvB: "",
  endLatitudeB: "",
  endLongitudeB: "",
  endElvB: "",
  cwyB: "",
  bearingB: "",
  toraB: "",
  rdB: "",
  categoryB: "",
};

export default function EtodAirportGenerateSurface() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const coordInputRef = useRef();
  const markerRef = useRef(null);
  const isLoadingPostCreateNewAirport = useSelector(
    (state) => state.airport.isLoadingPostCreateNewAirport
  );
  const [airportLocation, setAirportLocation] = useState(null);
  const [isCopied, setCopied] = useState(false);
  const [latLng, setLatLng] = useState([]);

  const createRunwaysForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      arp_tmpname: "",
      arp_latitude: "",
      arp_longitude: "",
      arp_elevation: "",
      runways: [runwayDetail],
    },
    validationSchema: createAirportGenerateSurfaceSchema,
    onSubmit: (values) => {
      let payloadObj = {
        airport_name: String(values.arp_tmpname),
        arp_elevation: Number(values.arp_elevation),
        arp_latitude: String(values.arp_latitude),
        arp_longitude: String(values.arp_longitude),
        rwys: [],
      };

      const rwyPayload = values.runways.map((runway) => {
        if (
          runway.thrLatitudeB &&
          runway.thrLongitudeB &&
          runway.endLatitudeB &&
          runway.endLongitudeB &&
          runway.thrElvB &&
          runway.endElvB &&
          runway.bearingB &&
          runway.rdB &&
          runway.toraB &&
          runway.categoryB
        ) {
          return {
            approach: {
              lat: Number(runway.thrLatitudeA),
              lng: Number(runway.thrLongitudeA),
              elat: Number(runway.endLatitudeA),
              elng: Number(runway.endLongitudeA),
              thr_el: Number(runway.thrElvA),
              end_el: Number(runway.endElvA),
              bearing: Number(runway.bearingA),
              rd: String(runway.rdA),
              tora: String(runway.toraA),
              category: Number(runway.categoryA),
              cwy: Number(runway.cwyA),
            },
            takeoff: {
              lat: Number(runway.thrLatitudeB),
              lng: Number(runway.thrLongitudeB),
              elat: Number(runway.endLatitudeB),
              elng: Number(runway.endLongitudeB),
              thr_el: Number(runway.thrElvB),
              end_el: Number(runway.endElvB),
              bearing: Number(runway.bearingB),
              rd: String(runway.rdB),
              tora: String(runway.toraB),
              category: Number(runway.categoryB),
              cwy: Number(runway.cwyB),
            },
            details: { width: runway.rwywidth },
          };
        }
        return {
          approach: {
            lat: Number(runway.thrLatitudeA),
            lng: Number(runway.thrLongitudeA),
            elat: Number(runway.endLatitudeA),
            elng: Number(runway.endLongitudeA),
            thr_el: Number(runway.thrElvA),
            end_el: Number(runway.endElvA),
            bearing: Number(runway.bearingA),
            rd: String(runway.rdA),
            tora: String(runway.toraA),
            category: Number(runway.categoryA),
            cwy: Number(runway.cwyA),
          },
          details: { width: runway.rwywidth },
        };
      });
      payloadObj.rwys = rwyPayload;
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          postEtodAirportCreateLayer(payloadObj, token, handleSuccessCallback)
        );
      });
    },
  });

  const handleSuccessCallback = (airportId, status) => {
    if (status && airportId) {
      dispatch(getEtodAirportLayer(airportId));
      navigate(-1);
    }
  };

  const handleSeeOnMap = (latitude, longitude) => {
    setLatLng([]);
    if (latitude && longitude) {
      setAirportLocation([latitude, longitude]);
    }
  };

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setLatLng([e.latlng.lat, e.latlng.lng]);
        setCopied(false);
        if (markerRef) {
          markerRef.current.openPopup();
        }
      },
    });
    return false;
  };

  const mapContainer = useMemo(
    () => (
      <MapContainer
        center={defaultLocationCenter}
        zoom={4}
        zoomControl={false}
        style={{ width: "100%", height: "100%" }}
      >
        <ControlMapCenter
          airportLocation={
            airportLocation
              ? airportLocation
              : [defaultLocationCenter.lat, defaultLocationCenter.lng]
          }
          zoomLevel={airportLocation ? 10 : 4}
        />
        {airportLocation && (
          <Marker icon={LocationImgIcon} position={airportLocation} />
        )}
        {latLng && latLng.length > 0 && (
          <Marker position={latLng} ref={markerRef} icon={EmptyImgIcon}>
            <Popup permanent position={latLng} style={{ zIndex: 99999 }}>
              <Box sx={{ display: "inline-flex" }}>
                <Typography
                  variant="span"
                  sx={{
                    color: `${
                      isCopied
                        ? theme.palette.primary.main
                        : theme.palette.black.main
                    }`,
                  }}
                >
                  {convertToDms(latLng[0]) +
                    ", " +
                    convertToDms(latLng[1], true)}
                </Typography>
                <CopyToClipboard
                  onCopy={() => setCopied(true)}
                  text={
                    convertToDms(latLng[0]) +
                    ", " +
                    convertToDms(latLng[1], true)
                  }
                >
                  <IconButton
                    edge="end"
                    color={isCopied ? "primary" : "default"}
                    sx={{ ml: 1, mr: 0.5 }}
                  >
                    <FaCopy size={16} />
                  </IconButton>
                </CopyToClipboard>
              </Box>
            </Popup>
          </Marker>
        )}
        <ZoomControl position="bottomright" />
        <GoogleMapLayer maptype="satellite" />
        <MapEvents />
      </MapContainer>
    ),
    [airportLocation, latLng, isCopied]
  );

  return (
    <div className="wrapper">
      <Header active="etod" />
      <div className="main-content">
        <div className="main" style={{ width: "100%" }}>
          <div className="content-area">
            <div style={styles.etodContent}>
              <Box sx={dataMngStyles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  color="black"
                  sx={{ fontWeight: 500, fontSize: 16, pl: 1 }}
                >
                  back to selection
                </Typography>
              </Box>
              <div style={{ height: "calc(100% - 95px)", marginTop: "16px" }}>
                <Stack direction="row" sx={styles.etodUserlabelMain}>
                  <Typography sx={styles.etodUserlabel}>
                    USER DEFINED SURFACE GENERATION
                  </Typography>
                </Stack>
                <Box sx={styles.createRwyForm}>
                  <FormikProvider value={createRunwaysForm}>
                    <form
                      style={{ padding: "16px 24px " }}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Stack direction="row" alignItems="center" mb={2}>
                        <Box sx={{ width: "100%" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter Temporary Airport Name"
                            name="arp_tmpname"
                            size="small"
                            value={createRunwaysForm.values.arp_tmpname}
                            onChange={createRunwaysForm.handleChange}
                            error={
                              createRunwaysForm.touched.arp_tmpname &&
                              Boolean(createRunwaysForm.errors.arp_tmpname)
                            }
                            helperText={
                              createRunwaysForm.touched.arp_tmpname &&
                              createRunwaysForm.errors.arp_tmpname
                            }
                          />
                        </Box>
                      </Stack>
                      <Typography sx={styles.etodARP}>
                        Enter Aerodrome Reference Point (ARP)
                      </Typography>
                      <Stack direction="row" alignItems="center" mb={2}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            input: { width: "100%" },
                          }}
                        >
                          <CoordinateInput
                            ref={coordInputRef}
                            ddPrecision={8}
                            dmsPrecision={2}
                            style={{ padding: "8px 14px", fontSize: 20 }}
                            onChange={(_, { dd }) => {
                              createRunwaysForm.setFieldValue(
                                "arp_latitude",
                                dd[0]
                              );
                              createRunwaysForm.setFieldValue(
                                "arp_longitude",
                                dd[1]
                              );
                            }}
                          />
                        </Box>
                      </Stack>
                      <FormHelperText
                        sx={{ mb: 1 }}
                        error={
                          Boolean(createRunwaysForm.touched?.arp_latitude) &&
                          Boolean(createRunwaysForm.errors?.arp_latitude)
                        }
                      >
                        {createRunwaysForm.touched?.arp_latitude &&
                          createRunwaysForm.errors?.arp_latitude}
                      </FormHelperText>
                      <Stack direction="row" alignItems="center" mb={1}>
                        <Box sx={{ width: "100%" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Enter Airport Elevation"
                            name="arp_elevation"
                            size="small"
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  M
                                </InputAdornment>
                              ),
                            }}
                            value={createRunwaysForm.values.arp_elevation}
                            onChange={createRunwaysForm.handleChange}
                            error={
                              createRunwaysForm.touched.arp_elevation &&
                              Boolean(createRunwaysForm.errors.arp_elevation)
                            }
                            helperText={
                              createRunwaysForm.touched.arp_elevation &&
                              createRunwaysForm.errors.arp_elevation
                            }
                          />
                        </Box>
                      </Stack>
                      <FieldArray
                        name="runways"
                        render={(arrayHelpers) => {
                          let categoryOptionsA = rwySurfaceCategory800;
                          let categoryOptionsB = rwySurfaceCategory800;

                          return (
                            <div>
                              {createRunwaysForm.values.runways.map(
                                (rwy, index) => {
                                  const toraA = rwy.toraA;
                                  const toraB = rwy.toraB;
                                  if (
                                    Number(toraA) >= 0 &&
                                    Number(toraA) < 800
                                  ) {
                                    categoryOptionsA = rwySurfaceCategory800;
                                  } else if (
                                    Number(toraA) >= 800 &&
                                    Number(toraA) < 1200
                                  ) {
                                    categoryOptionsA =
                                      rwySurfaceCategory8001200;
                                  } else if (
                                    Number(toraA) >= 1200 &&
                                    Number(toraA) < 1800
                                  ) {
                                    categoryOptionsA =
                                      rwySurfaceCategory12001800;
                                  } else if (Number(toraA) >= 1800) {
                                    categoryOptionsA = rwySurfaceCategoryUp1800;
                                  }

                                  if (
                                    Number(toraB) >= 0 &&
                                    Number(toraB) < 800
                                  ) {
                                    categoryOptionsB = rwySurfaceCategory800;
                                  } else if (
                                    Number(toraB) >= 800 &&
                                    Number(toraB) < 1200
                                  ) {
                                    categoryOptionsB =
                                      rwySurfaceCategory8001200;
                                  } else if (
                                    Number(toraB) >= 1200 &&
                                    Number(toraB) < 1800
                                  ) {
                                    categoryOptionsB =
                                      rwySurfaceCategory12001800;
                                  } else if (Number(toraB) >= 1800) {
                                    categoryOptionsB = rwySurfaceCategoryUp1800;
                                  }
                                  const isTouched = createRunwaysForm.touched
                                    ?.runways
                                    ? createRunwaysForm.touched?.runways[index]
                                    : false;
                                  const isErrors = createRunwaysForm.errors
                                    ?.runways
                                    ? createRunwaysForm.errors?.runways[index]
                                    : false;

                                  return (
                                    <Fragment key={index}>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <Box sx={styles.runWay}>
                                          <Typography
                                            variant="span"
                                            sx={styles.runWayLabel}
                                          >
                                            Runway {index + 1}
                                          </Typography>
                                          {index === 0 ? null : (
                                            <DeleteIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            />
                                          )}
                                        </Box>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mt: 2, mb: 1.5 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <Typography sx={styles.runWayDetails}>
                                            Runway Details
                                          </Typography>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter Runway Width"
                                            name={`runways[${index}].rwywidth`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].rwywidth
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].rwywidth &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].rwywidth
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].rwywidth
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Typography sx={styles.runWayDetails}>
                                        Runway Direction - A
                                      </Typography>
                                      <Typography sx={styles.thrCoordinates}>
                                        THR Co-ordinates
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{ mt: 1, mb: 2 }}
                                      >
                                        <CoordinateInput
                                          ref={coordInputRef}
                                          ddPrecision={8}
                                          dmsPrecision={2}
                                          style={styles.thrLatitude}
                                          onChange={(_, { dd }) => {
                                            createRunwaysForm.setFieldValue(
                                              `runways.${index}.thrLatitudeA`,
                                              dd[0]
                                            );
                                            createRunwaysForm.setFieldValue(
                                              `runways.${index}.thrLongitudeA`,
                                              dd[1]
                                            );
                                          }}
                                        />
                                        <OutlinedButton
                                          label="See on Map"
                                          disabled={
                                            !createRunwaysForm.values?.runways[
                                              index
                                            ].thrLatitudeA ||
                                            !createRunwaysForm?.values?.runways[
                                              index
                                            ].thrLongitudeA
                                          }
                                          endIcon={
                                            <RoundRightArrowIcon
                                              disabled={
                                                createRunwaysForm.values
                                                  .runways[index]
                                                  .thrLatitudeA ||
                                                createRunwaysForm.values
                                                  .runways[index].thrLongitudeA
                                              }
                                              color={
                                                createRunwaysForm.values
                                                  ?.runways[index]
                                                  .thrLatitudeA ||
                                                createRunwaysForm.values
                                                  ?.runways[index].thrLongitudeA
                                                  ? theme.palette.primary.main
                                                  : "#00000042"
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            handleSeeOnMap(
                                              createRunwaysForm.values?.runways[
                                                index
                                              ].thrLatitudeA,
                                              createRunwaysForm.values?.runways[
                                                index
                                              ].thrLongitudeA
                                            )
                                          }
                                          sx={{ width: 250 }}
                                        />
                                      </Stack>
                                      <FormHelperText>
                                        Add latitude longitude format - ddmmss
                                        N/S dddmmss W/E
                                      </FormHelperText>
                                      <FormHelperText sx={{ mb: 1 }}>
                                        Ex: 04° 08′ 15″ N 162° 03′ 42″ E
                                      </FormHelperText>
                                      <FormHelperText
                                        sx={{ mb: 1 }}
                                        error={
                                          isErrors &&
                                          isTouched &&
                                          Boolean(
                                            createRunwaysForm.touched?.runways[
                                              index
                                            ].thrLatitudeA
                                          ) &&
                                          Boolean(
                                            createRunwaysForm.errors?.runways[
                                              index
                                            ].thrLatitudeA
                                          )
                                        }
                                      >
                                        {isErrors &&
                                          isTouched &&
                                          createRunwaysForm.touched?.runways[
                                            index
                                          ].thrLatitudeA &&
                                          createRunwaysForm.errors?.runways[
                                            index
                                          ].thrLatitudeA}
                                      </FormHelperText>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 2, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter THR Elevation"
                                            name={`runways[${index}].thrElvA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].thrElvA
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].thrElvA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].thrElvA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].thrElvA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Typography sx={styles.thrCoordinates}>
                                        END Co-ordinates
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{ mt: 1, mb: 2 }}
                                      >
                                        <CoordinateInput
                                          ref={coordInputRef}
                                          ddPrecision={8}
                                          dmsPrecision={2}
                                          style={styles.thrLatitude}
                                          onChange={(_, { dd }) => {
                                            createRunwaysForm.setFieldValue(
                                              `runways.${index}.endLatitudeA`,
                                              dd[0]
                                            );
                                            createRunwaysForm.setFieldValue(
                                              `runways.${index}.endLongitudeA`,
                                              dd[1]
                                            );
                                          }}
                                        />
                                        <OutlinedButton
                                          label="See on Map"
                                          disabled={
                                            !createRunwaysForm.values?.runways[
                                              index
                                            ].endLatitudeA ||
                                            !createRunwaysForm?.values?.runways[
                                              index
                                            ].endLongitudeA
                                          }
                                          endIcon={
                                            <RoundRightArrowIcon
                                              disabled={
                                                createRunwaysForm.values
                                                  .runways[index]
                                                  .endLatitudeA ||
                                                createRunwaysForm.values
                                                  .runways[index].endLongitudeA
                                              }
                                              color={
                                                createRunwaysForm.values
                                                  ?.runways[index]
                                                  .endLatitudeA ||
                                                createRunwaysForm.values
                                                  ?.runways[index].endLongitudeA
                                                  ? theme.palette.primary.main
                                                  : "#00000042"
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            handleSeeOnMap(
                                              createRunwaysForm.values?.runways[
                                                index
                                              ].endLatitudeA,
                                              createRunwaysForm.values?.runways[
                                                index
                                              ].endLongitudeA
                                            )
                                          }
                                          sx={{ width: 250 }}
                                        />
                                      </Stack>
                                      <FormHelperText>
                                        Add latitude longitude format - ddmmss
                                        N/S dddmmss W/E
                                      </FormHelperText>
                                      <FormHelperText sx={{ mb: 1 }}>
                                        Ex: 04° 08′ 15″ N 162° 03′ 42″ E
                                      </FormHelperText>
                                      <FormHelperText
                                        sx={{ mb: 1 }}
                                        error={
                                          isErrors &&
                                          isTouched &&
                                          Boolean(
                                            createRunwaysForm.touched?.runways[
                                              index
                                            ].endLatitudeA
                                          ) &&
                                          Boolean(
                                            createRunwaysForm.errors?.runways[
                                              index
                                            ].endLatitudeA
                                          )
                                        }
                                      >
                                        {isErrors &&
                                          isTouched &&
                                          createRunwaysForm.touched?.runways[
                                            index
                                          ].endLatitudeA &&
                                          createRunwaysForm.errors?.runways[
                                            index
                                          ].endLatitudeA}
                                      </FormHelperText>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 3, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter END Elevation"
                                            name={`runways[${index}].endElvA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].endElvA
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].endElvA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].endElvA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].endElvA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 2, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter Clearway"
                                            name={`runways[${index}].cwyA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].cwyA
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].cwyA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].cwyA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].cwyA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 2, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter Bearing"
                                            name={`runways[${index}].bearingA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  DEG
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].bearingA
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].bearingA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].bearingA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].bearingA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 2, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter Tora"
                                            name={`runways[${index}].toraA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].toraA
                                            }
                                            onChange={(e) => {
                                              createRunwaysForm.setFieldValue(
                                                `runways.${index}.toraA`,
                                                e.target.value
                                              );
                                            }}
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].toraA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].toraA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].toraA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{ mb: 2, mt: 2 }}
                                      >
                                        <Box sx={{ width: "100%" }}>
                                          <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Enter RD"
                                            name={`runways[${index}].rdA`}
                                            size="small"
                                            type="number"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  M
                                                </InputAdornment>
                                              ),
                                            }}
                                            value={
                                              createRunwaysForm.values.runways[
                                                index
                                              ].rdA
                                            }
                                            onChange={
                                              createRunwaysForm.handleChange
                                            }
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].rdA &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].rdA
                                              )
                                            }
                                            helperText={
                                              isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].rdA
                                                : ""
                                            }
                                          />
                                        </Box>
                                      </Stack>
                                      <FormControl
                                        fullWidth
                                        sx={{ mb: 2 }}
                                        error={
                                          isTouched &&
                                          isErrors &&
                                          createRunwaysForm.touched?.runways[
                                            index
                                          ].categoryA &&
                                          Boolean(
                                            createRunwaysForm.errors?.runways[
                                              index
                                            ].categoryA
                                          )
                                        }
                                      >
                                        <InputLabel
                                          style={{ top: "-5px" }}
                                          id="categoryA-select-helper-label"
                                        >
                                          Category
                                        </InputLabel>
                                        <Select
                                          labelId="categoryA-select-helper-label"
                                          id="categoryA-select-helper"
                                          size="small"
                                          defaultValue=""
                                          disabled={
                                            !createRunwaysForm.values.runways[
                                              index
                                            ].toraA
                                          }
                                          value={
                                            createRunwaysForm.values.runways[
                                              index
                                            ].categoryA
                                          }
                                          label="Category"
                                          onChange={(e) => {
                                            createRunwaysForm.setFieldValue(
                                              `runways.${index}.categoryA`,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {categoryOptionsA.map((category) => (
                                            <MenuItem
                                              key={`${category.id}-${
                                                index + 1
                                              }-${category.value}`}
                                              value={category.value}
                                            >
                                              {category.label}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        <FormHelperText>
                                          {isErrors && isTouched
                                            ? createRunwaysForm.errors?.runways[
                                                index
                                              ].categoryA
                                            : ""}
                                        </FormHelperText>
                                      </FormControl>
                                      <Typography sx={styles.RWYDirection}>
                                        Runway Direction - B
                                        {!createRunwaysForm.values.runways[
                                          index
                                        ].isRwyB ? (
                                          <Typography
                                            variant="span"
                                            sx={styles.circleIcon}
                                          >
                                            <CirclePlusIcon
                                              color={theme.palette.primary.main}
                                            />
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="span"
                                            sx={styles.circleIcon}
                                          >
                                            <DeleteIcon />
                                          </Typography>
                                        )}
                                        <TextField
                                          variant="standard"
                                          label=""
                                          name={`runways[${index}].isRwyB`}
                                          size="small"
                                          type="checkbox"
                                          sx={{
                                            position: "absolute",
                                            width: "100%",
                                            left: "0",
                                            opacity: "0",
                                            ".MuiTextField-root": {
                                              cursor: "pointer",
                                            },
                                          }}
                                          value={
                                            createRunwaysForm.values.runways[
                                              index
                                            ].isRwyB
                                          }
                                          onChange={
                                            createRunwaysForm.handleChange
                                          }
                                        />
                                      </Typography>

                                      {createRunwaysForm.values.runways[index]
                                        .isRwyB && (
                                        <div>
                                          <Typography
                                            sx={styles.thrCoordinates}
                                          >
                                            THR Co-ordinates
                                          </Typography>
                                          <Stack
                                            direction="row"
                                            sx={{ mt: 1, mb: 2 }}
                                          >
                                            <CoordinateInput
                                              ref={coordInputRef}
                                              ddPrecision={8}
                                              dmsPrecision={2}
                                              style={styles.thrLatitude}
                                              onChange={(_, { dd }) => {
                                                createRunwaysForm.setFieldValue(
                                                  `runways.${index}.thrLatitudeB`,
                                                  dd[0]
                                                );
                                                createRunwaysForm.setFieldValue(
                                                  `runways.${index}.thrLongitudeB`,
                                                  dd[1]
                                                );
                                              }}
                                            />
                                            <OutlinedButton
                                              label="See on Map"
                                              disabled={
                                                !createRunwaysForm.values
                                                  ?.runways[index]
                                                  .thrLatitudeB ||
                                                !createRunwaysForm?.values
                                                  ?.runways[index].thrLongitudeB
                                              }
                                              endIcon={
                                                <RoundRightArrowIcon
                                                  disabled={
                                                    createRunwaysForm.values
                                                      .runways[index]
                                                      .thrLatitudeB ||
                                                    createRunwaysForm.values
                                                      .runways[index]
                                                      .thrLongitudeB
                                                  }
                                                  color={
                                                    createRunwaysForm.values
                                                      ?.runways[index]
                                                      .thrLatitudeB ||
                                                    createRunwaysForm.values
                                                      ?.runways[index]
                                                      .thrLongitudeB
                                                      ? theme.palette.primary
                                                          .main
                                                      : "#00000042"
                                                  }
                                                />
                                              }
                                              onClick={() =>
                                                handleSeeOnMap(
                                                  createRunwaysForm.values
                                                    ?.runways[index]
                                                    .thrLatitudeB,
                                                  createRunwaysForm.values
                                                    ?.runways[index]
                                                    .thrLongitudeB
                                                )
                                              }
                                              sx={{ width: 250 }}
                                            />
                                          </Stack>
                                          <FormHelperText>
                                            Add latitude longitude format -
                                            ddmmss N/S dddmmss W/E
                                          </FormHelperText>
                                          <FormHelperText sx={{ mb: 1 }}>
                                            Ex: 04° 08′ 15″ N 162° 03′ 42″ E
                                          </FormHelperText>
                                          <FormHelperText
                                            sx={{ mb: 1 }}
                                            error={
                                              isErrors &&
                                              isTouched &&
                                              Boolean(
                                                createRunwaysForm.touched
                                                  ?.runways[index].thrLatitudeB
                                              ) &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].thrLatitudeB
                                              )
                                            }
                                          >
                                            {isErrors &&
                                              isTouched &&
                                              createRunwaysForm.touched
                                                ?.runways[index].thrLatitudeB &&
                                              createRunwaysForm.errors?.runways[
                                                index
                                              ].thrLatitudeB}
                                          </FormHelperText>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter THR Elevation"
                                                name={`runways[${index}].thrElvB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      M
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].thrElvB
                                                }
                                                onChange={
                                                  createRunwaysForm.handleChange
                                                }
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].thrElvB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].thrElvB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index].thrElvB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <Typography
                                            sx={styles.thrCoordinates}
                                          >
                                            END Co-ordinates
                                          </Typography>
                                          <Stack
                                            direction="row"
                                            sx={{ mt: 1, mb: 2 }}
                                          >
                                            <CoordinateInput
                                              ref={coordInputRef}
                                              ddPrecision={8}
                                              dmsPrecision={2}
                                              style={styles.thrLatitude}
                                              onChange={(_, { dd }) => {
                                                createRunwaysForm.setFieldValue(
                                                  `runways.${index}.endLatitudeB`,
                                                  dd[0]
                                                );
                                                createRunwaysForm.setFieldValue(
                                                  `runways.${index}.endLongitudeB`,
                                                  dd[1]
                                                );
                                              }}
                                            />
                                            <OutlinedButton
                                              label="See on Map"
                                              disabled={
                                                !createRunwaysForm.values
                                                  ?.runways[index]
                                                  .endLatitudeB ||
                                                !createRunwaysForm?.values
                                                  ?.runways[index].endLongitudeB
                                              }
                                              endIcon={
                                                <RoundRightArrowIcon
                                                  disabled={
                                                    createRunwaysForm.values
                                                      .runways[index]
                                                      .endLatitudeB ||
                                                    createRunwaysForm.values
                                                      .runways[index]
                                                      .endLongitudeB
                                                  }
                                                  color={
                                                    createRunwaysForm.values
                                                      ?.runways[index]
                                                      .endLatitudeB ||
                                                    createRunwaysForm.values
                                                      ?.runways[index]
                                                      .endLongitudeB
                                                      ? theme.palette.primary
                                                          .main
                                                      : "#00000042"
                                                  }
                                                />
                                              }
                                              onClick={() =>
                                                handleSeeOnMap(
                                                  createRunwaysForm.values
                                                    ?.runways[index]
                                                    .endLatitudeB,
                                                  createRunwaysForm.values
                                                    ?.runways[index]
                                                    .endLongitudeB
                                                )
                                              }
                                              sx={{ width: 250 }}
                                            />
                                          </Stack>
                                          <FormHelperText>
                                            Add latitude longitude format -
                                            ddmmss N/S dddmmss W/E
                                          </FormHelperText>
                                          <FormHelperText sx={{ mb: 1 }}>
                                            Ex: 04° 08′ 15″ N 162° 03′ 42″ E
                                          </FormHelperText>
                                          <FormHelperText
                                            sx={{ mb: 1 }}
                                            error={
                                              isErrors &&
                                              isTouched &&
                                              Boolean(
                                                createRunwaysForm.touched
                                                  ?.runways[index].endLatitudeB
                                              ) &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].endLatitudeB
                                              )
                                            }
                                          >
                                            {isErrors &&
                                              isTouched &&
                                              createRunwaysForm.touched
                                                ?.runways[index].endLatitudeB &&
                                              createRunwaysForm.errors?.runways[
                                                index
                                              ].endLatitudeB}
                                          </FormHelperText>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 3, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter END Elevation"
                                                name={`runways[${index}].endElvB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      M
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].endElvB
                                                }
                                                onChange={
                                                  createRunwaysForm.handleChange
                                                }
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].endElvB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].endElvB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index].endElvB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter Clearway"
                                                name={`runways[${index}].cwyB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      M
                                                    </InputAdornment>
                                                  ),
                                                  inputProps: {
                                                    min: 0,
                                                  },
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].cwyB
                                                }
                                                onChange={
                                                  createRunwaysForm.handleChange
                                                }
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].cwyB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].cwyB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index].cwyB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter Bearing"
                                                name={`runways[${index}].bearingB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      DEG
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].bearingB
                                                }
                                                onChange={
                                                  createRunwaysForm.handleChange
                                                }
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].bearingB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].bearingB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index]
                                                        .bearingB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter Tora"
                                                name={`runways[${index}].toraB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      M
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].toraB
                                                }
                                                onChange={(e) => {
                                                  createRunwaysForm.setFieldValue(
                                                    `runways.${index}.toraB`,
                                                    e.target.value
                                                  );
                                                }}
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].toraB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].toraB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index].toraB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            sx={{ mb: 2, mt: 2 }}
                                          >
                                            <Box sx={{ width: "100%" }}>
                                              <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Enter RD"
                                                name={`runways[${index}].rdB`}
                                                size="small"
                                                type="number"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      M
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                value={
                                                  createRunwaysForm.values
                                                    .runways[index].rdB
                                                }
                                                onChange={
                                                  createRunwaysForm.handleChange
                                                }
                                                error={
                                                  isTouched &&
                                                  isErrors &&
                                                  createRunwaysForm.touched
                                                    ?.runways[index].rdB &&
                                                  Boolean(
                                                    createRunwaysForm.errors
                                                      ?.runways[index].rdB
                                                  )
                                                }
                                                helperText={
                                                  isErrors && isTouched
                                                    ? createRunwaysForm.errors
                                                        ?.runways[index].rdB
                                                    : ""
                                                }
                                              />
                                            </Box>
                                          </Stack>
                                          <FormControl
                                            fullWidth
                                            sx={{ mb: 2 }}
                                            error={
                                              isTouched &&
                                              isErrors &&
                                              createRunwaysForm.touched
                                                ?.runways[index].categoryB &&
                                              Boolean(
                                                createRunwaysForm.errors
                                                  ?.runways[index].categoryB
                                              )
                                            }
                                          >
                                            <InputLabel
                                              style={{ top: "-5px" }}
                                              id="categoryB-select-helper-label"
                                            >
                                              Category
                                            </InputLabel>
                                            <Select
                                              labelId="categoryB-select-helper-label"
                                              id="categoryB-select-helper"
                                              size="small"
                                              defaultValue=""
                                              disabled={
                                                !createRunwaysForm.values
                                                  .runways[index].toraB
                                              }
                                              value={
                                                createRunwaysForm.values
                                                  .runways[index].categoryB
                                              }
                                              label="Category"
                                              onChange={(e) => {
                                                createRunwaysForm.setFieldValue(
                                                  `runways.${index}.categoryB`,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {categoryOptionsB.map(
                                                (category, index) => (
                                                  <MenuItem
                                                    key={`${category.id}-${
                                                      index + 2
                                                    }`}
                                                    value={category.value}
                                                  >
                                                    {category.label}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                            <FormHelperText>
                                              {isErrors && isTouched
                                                ? createRunwaysForm.errors
                                                    ?.runways[index].categoryB
                                                : ""}
                                            </FormHelperText>
                                          </FormControl>
                                        </div>
                                      )}
                                    </Fragment>
                                  );
                                }
                              )}
                              <PrimaryButton
                                label="Add More"
                                variant="outlined"
                                sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                                onClick={() => arrayHelpers.push(runwayDetail)}
                              />
                            </div>
                          );
                        }}
                      />
                    </form>
                  </FormikProvider>
                </Box>
              </div>
              <Box sx={styles.proceedBtnMain}>
                <PrimaryButton
                  label="Proceed"
                  onClick={() => createRunwaysForm.handleSubmit()}
                  isLoading={isLoadingPostCreateNewAirport}
                  disabled={isLoadingPostCreateNewAirport}
                  sx={styles.proceedBtn}
                />
              </Box>
            </div>
            <div style={styles.etodMapContainer}>{mapContainer}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
