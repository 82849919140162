import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "@styles/live-tracker.css";
import "@styles/home.css";
import "@styles/login.css";
import "@styles/aixm.css";
import "@styles/etod.css";
import "@styles/obstacleanalysis.css";
import "@styles/surveyor.css";
import "@styles/data-management.css";
import "@styles/nav-button.css";
import "@styles/not-found.css";
import "@styles/airspace.css";
import "@styles/rcslider.css";
import "@styles/echart.css";
import "@styles/leaflet.linear-measurement.css";
import "react-toastify/dist/ReactToastify.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./App.css";
import Routes from "./router";

export default function App() {
  return (
    <Fragment>
      <ToastContainer theme="colored" />
      <Routes />
    </Fragment>
  );
}
