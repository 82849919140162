import React, { Fragment, useMemo, useState } from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { isEmpty, split } from "lodash";
import { Box } from "@mui/system";
import AirportLegend from "@components/map/AirportLegend";
import ControlMapCenter from "@components/map/ControlMapCenter";
import EtodAirportGenerateSurfaceRightSideBar from "@components/EtodAirportGenerateSurfaceRightSideBar";
import FullScreenControl from "@components/map/FullScreenControl";
import MapLayers from "@components/map/MapLayers";
import MeasurementControl from "@components/map/MeasurementControl";
import ObstacleLegends from "@components/map/ObstacleLegends";
import { mapStyles } from "@constants/constant";
import DemLegends from "@components/map/DemLegends";
import { styles } from "@styles/etodStyles";
import RenderArea1 from "./RenderArea1";
import RenderArea from "./RenderArea";
import RenderArea2D from "./RenderArea2D";
import RenderOLSTakeOff from "./RenderOLSTakeOff";
import RenderOLSApproach from "./RenderOLSApproach";
import RenderOLSHorizontalSurface from "./RenderOLSHorizontalSurface";
import RenderOLSTransitionalSurface from "./RenderOLSTransitionalSurface";
import RenderOLSOTransitionalSurface from "./RenderOLSOTransitionalSurface";
import RenderOLSRwyStrip from "./RenderOLSRwyStrip";
import RenderOLSObstacleFreeZone from "./RenderOLSObstacleFreeZone";
import RenderOLSConicalSurface from "./RenderOLSConicalSurface";
import RenderDems from "./RenderDems";
import RenderVisualiseObstacles from "./RenderVisualiseObstacles";
import RenderAnalysisAreaObstacles from "./RenderAnalysisAreaObstacles";
import RenderAnalysisOlsObstacles from "./RenderAnalysisOlsObstacles";

export default function EtodMap({
  isEtod,
  airportLocation,
  zoomLevel,
  selectedAreas,
  etodAreaList,
  etodArea2D,
  selectedOls,
  OLSApproachList,
  OLSHorizontalSurfaceList,
  OLSTransitionalSurfaceList,
  OLSOuterTransitionalSurfaceList,
  OLSObstacleFreeZoneList,
  OLSRwyStripList,
  OLSTakeOffList,
  OLSConicalSurfaceList,
  area1Data,
  isShowPenetrationObstacle,
  etodDems,
  selectedDems,
  selectedAirportDetail,
  visualiseObstacles,
  areaAnalysisObstacle,
  olsAnalysisObstacle,
  createlayerAirportData,
  isShowRWYrightSidebar,
}) {
  const [isAbovePermissibleClick, setAbovePermissibleClick] = useState(true);
  const [isBelowPermissibleClick, setBelowPermissibleClick] = useState(true);

  const getAreaColors = () => {
    const areaColor = {};

    selectedAreas.forEach((a) => {
      const splitArray = split(a, "__");
      areaColor[splitArray[0]] = splitArray[1];
    });

    return areaColor;
  };

  const getOLSColors = () => {
    const olsColor = {};

    selectedOls.forEach((a) => {
      const splitArray = split(a, "__");
      olsColor[splitArray[0]] = splitArray[1];
    });

    return olsColor;
  };

  const handleAbovePermissible = (event) => {
    setAbovePermissibleClick(event.target.checked);
  };

  const handleBelowPermissible = (event) => {
    setBelowPermissibleClick(event.target.checked);
  };

  const eTodMapE = useMemo(() => {
    const selectedAreasT = selectedAreas.map((a) => a.split("__")[0]);
    const isSelectedArea1 = Boolean(selectedAreasT.includes("Area1"));
    const isSelectedArea2D = Boolean(selectedAreasT.includes("Area2D"));

    return (
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        {Boolean(isSelectedArea1) && (
          <RenderArea1 area1Data={area1Data} getAreaColors={getAreaColors} />
        )}
        <RenderArea
          etodAreaList={etodAreaList}
          selectedAreas={selectedAreas}
          getAreaColors={getAreaColors}
        />
        {isSelectedArea2D && (
          <RenderArea2D etodArea2D={etodArea2D} getAreaColors={getAreaColors} />
        )}
        <RenderOLSTakeOff
          OLSTakeOffList={OLSTakeOffList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSApproach
          OLSApproachList={OLSApproachList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSHorizontalSurface
          OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSConicalSurface
          OLSConicalSurfaceList={OLSConicalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSTransitionalSurface
          OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSOTransitionalSurface
          OLSOuterTransitionalSurfaceList={OLSOuterTransitionalSurfaceList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSRwyStrip
          OLSRwyStripList={OLSRwyStripList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        <RenderOLSObstacleFreeZone
          OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
          selectedOls={selectedOls}
          getOLSColors={getOLSColors}
        />
        {etodDems && etodDems.length > 0 && (
          <RenderDems etodDems={etodDems} selectedDems={selectedDems} />
        )}
        {visualiseObstacles && visualiseObstacles.length > 0 && (
          <RenderVisualiseObstacles obstacles={visualiseObstacles} />
        )}
        {areaAnalysisObstacle &&
          areaAnalysisObstacle.length > 0 &&
          selectedAreas?.length > 0 && (
            <RenderAnalysisAreaObstacles
              obstacles={areaAnalysisObstacle}
              selectedAreas={selectedAreas}
              isShowPenetrationObstacle={isShowPenetrationObstacle}
              isAbovePermissibleClick={isAbovePermissibleClick}
              isBelowPermissibleClick={isBelowPermissibleClick}
            />
          )}
        {olsAnalysisObstacle &&
          olsAnalysisObstacle.length > 0 &&
          selectedOls?.length > 0 && (
            <RenderAnalysisOlsObstacles
              obstacles={olsAnalysisObstacle}
              selectedOls={selectedOls}
              isShowPenetrationObstacle={isShowPenetrationObstacle}
              isAbovePermissibleClick={isAbovePermissibleClick}
              isBelowPermissibleClick={isBelowPermissibleClick}
            />
          )}
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <MeasurementControl />
        <MapLayers type={mapStyles.satellite} />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selectedOls,
    selectedAreas,
    selectedDems,
    etodDems,
    visualiseObstacles,
    areaAnalysisObstacle,
    olsAnalysisObstacle,
    isShowPenetrationObstacle,
    isAbovePermissibleClick,
    isBelowPermissibleClick,
    isEtod,
  ]);

  return (
    <Fragment>
      {eTodMapE}
      <Box sx={styles.etod3DMap}>
        {!isEtod && selectedAirportDetail && (
          <AirportLegend selectedAirportDetail={selectedAirportDetail} />
        )}
        <ObstacleLegends
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          handleAbovePermissible={handleAbovePermissible}
          handleBelowPermissible={handleBelowPermissible}
          isAbovePermissibleClick={isAbovePermissibleClick}
          isBelowPermissibleClick={isBelowPermissibleClick}
        />
        {etodDems && etodDems.length > 0 && selectedDems.length > 0 && (
          <DemLegends etodDems={etodDems} />
        )}
      </Box>
      {!isEmpty(createlayerAirportData) && isShowRWYrightSidebar && (
        <EtodAirportGenerateSurfaceRightSideBar
          airportData={createlayerAirportData}
        />
      )}
    </Fragment>
  );
}
