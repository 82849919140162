import { uniq } from "lodash";
import AUPCenterMarker from "@components/core/AirspaceAUP/AUPCenterMarker";
import AUPActivemarker from "@components/core/AirspaceAUP/AUPActivemarker";
import AUPLiveTrackerMarker from "@components/core/AirspaceAUP/AUPLiveTrackerMarker";
import {
  activeHandleStyle,
  activeTrackStyle,
  deactiveTrackStyle,
  activeMilitryHandleStyle,
  activeMilitryTrackStyle,
} from "@constants/airspaceData";

export const AUPMilitrysliderdata = (
  selectedTypeKey,
  selectedAUPDetail,
  AUPdata,
  currentLiveTime
) => {
  let selectedData = [];
  let showtooltipData = [];
  let showtooltipText = [];
  let timeData = [];
  let handleStyleData = [];
  let trackStyleData = [];
  let selectedMarkobj = {};
  AUPdata[selectedAUPDetail?.selectedPolygon?.designator].map((data) => {
    timeData.push(Number(data.time_fm));
    timeData.push(Number(data.time_to));
    showtooltipText.push({
      lower_fl: data.lower_fl,
      upper_fl: data.upper_fl,
      time_fm: Number(data.time_fm),
    });
    return uniq([...timeData]);
  });
  // eslint-disable-next-line
  showtooltipData = uniq([...timeData]).filter((num, index) => {
    if (index % 2 === 0) return num;
  });
  selectedData = [
    0,
    ...timeData.slice(0, Math.round(timeData.length / 2)),
    1200,
    ...timeData.slice(Math.round(timeData.length / 2)),
    2359,
  ];
  if (currentLiveTime) {
    selectedData.push(Number(currentLiveTime));
  }
  uniq(selectedData).forEach((element) => {
    if (element === 0) {
      selectedMarkobj[element] = "0000";
    } else if (element === 1200) {
      selectedMarkobj[element] = {
        label: <AUPCenterMarker element={element} />,
      };
    } else if (element === 2359) {
      selectedMarkobj[element] = "2359";
    } else if (element === Number(currentLiveTime)) {
      selectedMarkobj[element] = {
        label: <AUPLiveTrackerMarker element={element} />,
      };
    } else {
      selectedMarkobj[element] = {
        label: (
          <AUPActivemarker
            element={element}
            showtooltipData={showtooltipData}
            showtooltipText={showtooltipText}
            classname="militry-active-marker"
          />
        ),
      };
    }
  });
  handleStyleData = uniq(timeData).map(() => {
    return activeMilitryHandleStyle;
  });
  trackStyleData = uniq(timeData).map((data, index) => {
    return index % 2 === 0 ? activeMilitryTrackStyle : deactiveTrackStyle;
  });
  return {
    timeData: [...timeData],
    handleStyleData: [...handleStyleData],
    trackStyleData: [...trackStyleData],
    selectedMarkobj: { ...selectedMarkobj },
  };
};

export const AUPRoutesliderdata = (
  selectedTypeKey,
  AUPdata,
  currentLiveTime
) => {
  let selectedData = [];
  let showtooltipData = [];
  let showtooltipText = [];
  let timeData = [];
  let handleStyleData = [];
  let trackStyleData = [];
  let selectedMarkobj = {};
  AUPdata[selectedTypeKey].map((data) => {
    timeData.push(Number(data.time_fm));
    timeData.push(Number(data.time_to));
    showtooltipText.push({
      lower_fl: data.lower_fl,
      upper_fl: data.upper_fl,
      time_fm: Number(data.time_fm),
    });
    return uniq([...timeData]);
  });
  // eslint-disable-next-line
  showtooltipData = uniq([...timeData]).filter((num, index) => {
    if (index % 2 === 0) return num;
  });
  selectedData = [
    0,
    ...timeData.slice(0, Math.round(timeData.length / 2)),
    1200,
    ...timeData.slice(Math.round(timeData.length / 2)),
    2359,
  ];

  if (currentLiveTime) {
    selectedData.push(Number(currentLiveTime));
  }
  uniq(selectedData).forEach((element, index) => {
    if (element === 0) {
      selectedMarkobj[element] = "0000";
    } else if (element === 1200) {
      selectedMarkobj[element] = {
        label: <AUPCenterMarker element={element} />,
      };
    } else if (element === 2359) {
      selectedMarkobj[element] = "2359";
    } else if (element === Number(currentLiveTime)) {
      selectedMarkobj[element] = {
        label: <AUPLiveTrackerMarker element={element} />,
      };
    } else {
      selectedMarkobj[element] = {
        label: (
          <AUPActivemarker
            element={element}
            showtooltipData={showtooltipData}
            showtooltipText={showtooltipText}
            classname="route-active-marker"
          />
        ),
      };
    }
  });

  handleStyleData = uniq(timeData.sort((a, b) => a - b)).map(() => {
    return activeHandleStyle;
  });
  trackStyleData = uniq(timeData.sort((a, b) => a - b)).map((data, index) => {
    return index % 2 === 0 ? activeTrackStyle : deactiveTrackStyle;
  });
  return {
    timeData: uniq([...timeData].sort((a, b) => a - b)),
    handleStyleData: [...handleStyleData],
    trackStyleData: [...trackStyleData],
    selectedMarkobj: { ...selectedMarkobj },
  };
};
