import React from "react";
import { Box } from "@mui/material";

const AUPLiveTrackerMarker = ({ element }) => {
  return (
    <Box className="livetracker-marker">
      <div className="inner">
        <div className="inner1">
          <p />
        </div>
      </div>
    </Box>
  );
};

export default AUPLiveTrackerMarker;
