import React from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { Box, Card, CardContent, Tooltip } from "@mui/material";

function AirportDetailCard({ title, data, dotColor, isDotShow, ...props }) {
  const color = dotColor ? dotColor : "#000";
  return (
    <Card className={`detail-card ${props.className}`}>
      <CardContent
        className="detail-card-content"
        sx={{
          paddingLeft: `${!isDotShow ? "18px !important" : "0px !important"} `,
        }}
      >
        <Box component="div" className="top-card-data">
          {isDotShow && (
            <GoPrimitiveDot
              style={{ marginRight: "5px" }}
              color={color}
              size={18}
            />
          )}
          <Box component="span" className="inr-data-title">
            {title}
          </Box>
        </Box>
        <Tooltip title={props.isTooltip === false ? "" : data} placement="top">
          <Box
            component="p"
            className="card-data"
            sx={{ paddingLeft: `${isDotShow ? "23px" : "0px"} ` }}
          >
            {data}
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default AirportDetailCard;
