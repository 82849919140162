import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { filter, isEmpty } from "lodash";
import { toast } from "react-toastify";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { EditIcon } from "@assets/svg/edit";
import { LightButton, PrimaryButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd28CheckpointsValidationSchema } from "@constants/ad2Data";
import { INSDetail, VORDetail } from "@constants/ad2DataForm.constant";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  getAAIAirport,
  deleteAD2AAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { getAsyncStorageValue } from "@utils/localStorage";
import { styles } from "@styles/dataManagementStyles";

function AD28CheckpointsForm({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const {
    isLoadingAD28AAIAirportDetail,
    AAIAirport,
    isLoadingDeleteAD210AAIAirport,
  } = useSelector((state) => state.airport);
  const [editCheckpointId, setEditCheckpointId] = useState(null);
  const [editCheckpointType, setEditCheckpointType] = useState(null);
  const [editCheckpointIndex, setEditCheckpointIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const [isLoadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    if (AAIAirport.INS && AAIAirport.INS.length > 0) {
      AAIAirport.INS.map((INS, index) => {
        const lat =
          INS.coordinates && INS.coordinates.length > 0
            ? INS.coordinates[0]
            : "";
        const lng =
          INS.coordinates && INS.coordinates.length > 0
            ? INS.coordinates[1]
            : "";
        const elv = INS.elevation ? INS.elevation.split(" ")[0] : "";
        createAd28CheckpointsForm.setFieldValue(
          `INS[${index}].id`,
          INS.id || ""
        );
        createAd28CheckpointsForm.setFieldValue(`INS[${index}].lattitude`, lat);
        createAd28CheckpointsForm.setFieldValue(`INS[${index}].longitude`, lng);
        createAd28CheckpointsForm.setFieldValue(`INS[${index}].elevation`, elv);
        return true;
      });
    }
    if (AAIAirport.VOR && AAIAirport.VOR.length > 0) {
      AAIAirport.VOR.map((VOR, index) => {
        const lat =
          VOR.coordinates && VOR.coordinates.length > 0
            ? VOR.coordinates[0]
            : "";
        const lng =
          VOR.coordinates && VOR.coordinates.length > 0
            ? VOR.coordinates[1]
            : "";
        const elv = VOR.elevation ? VOR.elevation.split(" ")[0] : "";
        createAd28CheckpointsForm.setFieldValue(
          `VOR[${index}].id`,
          VOR.id || ""
        );
        createAd28CheckpointsForm.setFieldValue(`VOR[${index}].lattitude`, lat);
        createAd28CheckpointsForm.setFieldValue(`VOR[${index}].longitude`, lng);
        createAd28CheckpointsForm.setFieldValue(`VOR[${index}].elevation`, elv);
        return true;
      });
    }
  }, [AAIAirport]); // eslint-disable-line

  const handleSuccessCallback = () => {
    createAd28CheckpointsForm.resetForm();
    createAd28CheckpointsForm.setSubmitting(false);
  };

  const handleGetCheckpointsCallBack = () => {
    dispatch(
      getAAIAirport(
        "checkpoints",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const createAd28CheckpointsForm = useFormik({
    enableReinitialize: true,
    initialValues: { INS: [INSDetail], VOR: [VORDetail] },
    validationSchema: createAd28CheckpointsValidationSchema,
    onSubmit: (values) => {
      if (editCheckpointId) {
        let editData = null;
        if (editCheckpointType === "INS") {
          editData = values.INS.at(editCheckpointIndex);
        }
        if (editCheckpointType === "VOR") {
          editData = values.VOR.at(editCheckpointIndex);
        }
        if (!editData) return true;
        const payload = {
          lat: editData.lattitude,
          lng: editData.longitude,
          elevation: String(editData.elevation),
          type: editCheckpointType,
          id: editData.id,
        };
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "checkpoints",
              payload,
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditCheckpointId(null);
                setEditCheckpointIndex(null);
                setEditCheckpointType(null);
                handleSuccessCallback();
                handleGetCheckpointsCallBack();
              }
            )
          );
        });
      } else {
        let INSVORdata = [];
        let INSdata = [];
        let VORdata = [];
        if (values.INS && values.INS.length > 0) {
          INSdata = filter(values.INS, ["id", ""]).map((ins) => {
            return {
              lat: ins.lattitude,
              lng: ins.longitude,
              elevation: String(ins.elevation),
              type: "INS",
            };
          });
        }
        if (values.VOR && values.VOR.length > 0) {
          VORdata = filter(values.VOR, ["id", ""]).map((vor) => {
            return {
              lat: vor.lattitude,
              lng: vor.longitude,
              elevation: String(vor.elevation),
              type: "VOR",
            };
          });
        }
        INSVORdata = INSdata.concat(VORdata);
        if (INSVORdata && INSVORdata.length > 0) {
          setLoadingData(true);
          getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
            const promise = INSVORdata.map((body) => {
              return dispatch(
                addAD2AAIAirportDetail(
                  "checkpoints",
                  body,
                  selectedAirport.id,
                  selectedAirport.version_id,
                  token,
                  () => {
                    handleSuccessCallback();
                  }
                )
              );
            });

            Promise.all(promise)
              .then(() => {
                handleGetCheckpointsCallBack();
                setLoadingData(false);
              })
              .catch(() => {
                setLoadingData(false);
                toast.error("Something went wrong!");
              });
          });
        }
      }
    },
  });

  const handleEditCheckpointdetail = (id, type) => {
    setEditCheckpointId(id);
    setEditCheckpointType(type);
  };

  const handleEditDoneCheckpoint = (editId, index, type) => {
    setEditCheckpointId(editId);
    setEditCheckpointIndex(index);
    setEditCheckpointType(type);
  };

  const handleDeleteCheckpoints = (deleteId) => {
    let id;
    if (editCheckpointId) {
      id = editCheckpointId;
    } else {
      id = deleteId;
    }
    if (!id) return;
    setDeleteId(id);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
    setDeleteId(null);
  };

  const handleSaveDeleteDialog = () => {
    if (!deleteId) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "checkpoints",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            checkpointsId: String(deleteId),
          },
          () => {
            setEditCheckpointId(null);
            setEditCheckpointIndex(null);
            setEditCheckpointType(null);
            handleSuccessCallback();
            handleGetCheckpointsCallBack();
            setOpenDeleteConfirmDialog(false);
          },
          token
        )
      );
    });
  };

  return (
    <Paper className="add-airport-data-form">
      <FormikProvider value={createAd28CheckpointsForm}>
        <form
          onSubmit={createAd28CheckpointsForm.handleSubmit}
          style={{ height: "100%" }}
        >
          <Box sx={{ ...styles.adFormField, height: "calc(100% - 54px)" }}>
            <FieldArray
              name="INS"
              render={(arrayHelpersINS) => {
                return (
                  <div>
                    {createAd28CheckpointsForm.values.INS.map((_, index) => {
                      const isTouched = createAd28CheckpointsForm.touched?.INS
                        ? createAd28CheckpointsForm.touched?.INS[index]
                        : false;
                      const isErrors = createAd28CheckpointsForm.errors?.INS
                        ? createAd28CheckpointsForm.errors?.INS[index]
                        : false;

                      const insCheckpointIds =
                        !isEmpty(AAIAirport) && AAIAirport.INS.length > 0
                          ? AAIAirport.INS.map((insData) => insData.id)
                          : [];

                      return (
                        <Fragment key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={styles.adVORMain}>
                              <Typography
                                variant="span"
                                sx={styles.adFuelLabel}
                              >
                                INS {index + 1}
                              </Typography>
                              <Box sx={styles.adFuelDeleteBtn}>
                                {!isEmpty(AAIAirport) &&
                                  createAd28CheckpointsForm.values.INS[index]
                                    .id &&
                                  (editCheckpointId ===
                                  insCheckpointIds[index] ? (
                                    <button
                                      type="button"
                                      style={styles.ad28Btn}
                                      onClick={() => {
                                        createAd28CheckpointsForm.handleSubmit();
                                        handleEditDoneCheckpoint(
                                          editCheckpointId,
                                          index,
                                          "INS"
                                        );
                                      }}
                                    >
                                      Done
                                    </button>
                                  ) : (
                                    <IconButton
                                      color="primary"
                                      sx={{ p: 0 }}
                                      onClick={() =>
                                        handleEditCheckpointdetail(
                                          insCheckpointIds[index],
                                          "INS"
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  ))}
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (editCheckpointId) {
                                      handleDeleteCheckpoints();
                                    } else if (
                                      !isEmpty(AAIAirport) &&
                                      !isEmpty(AAIAirport.INS) &&
                                      insCheckpointIds.length > 0
                                    ) {
                                      handleDeleteCheckpoints(
                                        insCheckpointIds[index]
                                      );

                                      if (
                                        insCheckpointIds.includes(
                                          createAd28CheckpointsForm.values.INS[
                                            index
                                          ].id
                                        )
                                      ) {
                                        handleDeleteCheckpoints(
                                          insCheckpointIds[index]
                                        );
                                      } else {
                                        arrayHelpersINS.remove(index);
                                      }
                                    } else {
                                      arrayHelpersINS.remove(index);
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          </Stack>
                          <input
                            hidden
                            name={`INS[${index}].id`}
                            onChange={createAd28CheckpointsForm.handleChange}
                            value={
                              createAd28CheckpointsForm.values.INS[index].id ||
                              ""
                            }
                          />
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Lattitude</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`INS[${index}].lattitude`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        insCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.INS[index]
                                    .lattitude
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.INS[index]
                                    .lattitude &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.INS[index]
                                      .lattitude
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.INS[
                                        index
                                      ].lattitude
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Longitude</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`INS[${index}].longitude`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        insCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.INS[index]
                                    .longitude
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.INS[index]
                                    .longitude &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.INS[index]
                                      .longitude
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.INS[
                                        index
                                      ].longitude
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Elevation</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="number"
                                name={`INS[${index}].elevation`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        insCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      FT
                                    </InputAdornment>
                                  ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.INS[index]
                                    .elevation
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.INS[index]
                                    .elevation &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.INS[index]
                                      .elevation
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.INS[
                                        index
                                      ].elevation
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Divider sx={{ mb: 1, mt: 1 }} />
                        </Fragment>
                      );
                    })}
                    <PrimaryButton
                      label="Add More INS"
                      variant="outlined"
                      sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                      onClick={() => arrayHelpersINS.push(INSDetail)}
                    />
                  </div>
                );
              }}
            />
            <FieldArray
              name="VOR"
              render={(arrayHelpersVOR) => {
                return (
                  <div>
                    {createAd28CheckpointsForm.values.VOR.map((_, index) => {
                      const isTouched = createAd28CheckpointsForm.touched?.VOR
                        ? createAd28CheckpointsForm.touched?.VOR[index]
                        : false;
                      const isErrors = createAd28CheckpointsForm.errors?.VOR
                        ? createAd28CheckpointsForm.errors?.VOR[index]
                        : false;

                      const vorCheckpointIds =
                        !isEmpty(AAIAirport) && AAIAirport.VOR.length > 0
                          ? AAIAirport.VOR.map((vorData) => vorData.id)
                          : [];

                      return (
                        <Fragment key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={styles.adVORMain}>
                              <Typography
                                variant="span"
                                sx={styles.adFuelLabel}
                              >
                                VOR {index + 1}
                              </Typography>
                              <Box sx={styles.adFuelDeleteBtn}>
                                {!isEmpty(AAIAirport) &&
                                  createAd28CheckpointsForm.values.VOR[index]
                                    .id &&
                                  (editCheckpointId ===
                                  vorCheckpointIds[index] ? (
                                    <button
                                      type="button"
                                      style={styles.ad28Btn}
                                      onClick={() => {
                                        createAd28CheckpointsForm.handleSubmit();
                                        handleEditDoneCheckpoint(
                                          editCheckpointId,
                                          index,
                                          "VOR"
                                        );
                                      }}
                                    >
                                      Done
                                    </button>
                                  ) : (
                                    <IconButton
                                      color="primary"
                                      sx={{ p: 0 }}
                                      onClick={() =>
                                        handleEditCheckpointdetail(
                                          vorCheckpointIds[index],
                                          "VOR"
                                        )
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  ))}
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (editCheckpointId) {
                                      handleDeleteCheckpoints();
                                    } else if (
                                      !isEmpty(AAIAirport) &&
                                      !isEmpty(AAIAirport.VOR) &&
                                      vorCheckpointIds.length > 0
                                    ) {
                                      if (
                                        vorCheckpointIds.includes(
                                          createAd28CheckpointsForm.values.VOR[
                                            index
                                          ].id
                                        )
                                      ) {
                                        handleDeleteCheckpoints(
                                          vorCheckpointIds[index]
                                        );
                                      } else {
                                        arrayHelpersVOR.remove(index);
                                      }
                                    } else {
                                      arrayHelpersVOR.remove(index);
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          </Stack>
                          <input
                            hidden
                            name={`VOR[${index}].id`}
                            onChange={createAd28CheckpointsForm.handleChange}
                            value={
                              createAd28CheckpointsForm.values.VOR[index].id ||
                              ""
                            }
                          />
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Lattitude</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`VOR[${index}].lattitude`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        vorCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.VOR[index]
                                    .lattitude
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.VOR[index]
                                    .lattitude &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.VOR[index]
                                      .lattitude
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.VOR[
                                        index
                                      ].lattitude
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Longitude</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                name={`VOR[${index}].longitude`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        vorCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.VOR[index]
                                    .longitude
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.VOR[index]
                                    .longitude &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.VOR[index]
                                      .longitude
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.VOR[
                                        index
                                      ].longitude
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ pl: 2, pr: 2 }}
                          >
                            <Box sx={{ width: "30%" }}>Elevation</Box>
                            <Box sx={styles.adInput}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="number"
                                name={`VOR[${index}].elevation`}
                                InputProps={{
                                  readOnly: !createAd28CheckpointsForm.values
                                    .VOR[index].id
                                    ? false
                                    : !Boolean(
                                        vorCheckpointIds[index] ===
                                          editCheckpointId && editCheckpointId
                                      ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      FT
                                    </InputAdornment>
                                  ),
                                }}
                                value={
                                  createAd28CheckpointsForm.values.VOR[index]
                                    .elevation
                                }
                                onChange={
                                  createAd28CheckpointsForm.handleChange
                                }
                                error={
                                  isTouched &&
                                  isErrors &&
                                  createAd28CheckpointsForm.touched?.VOR[index]
                                    .elevation &&
                                  Boolean(
                                    createAd28CheckpointsForm.errors?.VOR[index]
                                      .elevation
                                  )
                                }
                                helperText={
                                  isErrors && isTouched
                                    ? createAd28CheckpointsForm.errors?.VOR[
                                        index
                                      ].elevation
                                    : ""
                                }
                                sx={{ mt: 2 }}
                              />
                            </Box>
                          </Stack>
                          <Divider sx={{ mb: 1, mt: 1 }} />
                        </Fragment>
                      );
                    })}
                    <PrimaryButton
                      label="Add More VOR"
                      variant="outlined"
                      sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                      onClick={() => arrayHelpersVOR.push(VORDetail)}
                    />
                  </div>
                );
              }}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={styles.adFormBtn}
          >
            <LightButton
              label="Clear"
              sx={{ mr: 4 }}
              isLoading={false}
              onClick={() => {
                createAd28CheckpointsForm.resetForm();
              }}
            />
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingAD28AAIAirportDetail || isLoadingData}
              disabled={
                isLoadingAD28AAIAirportDetail ||
                Boolean(editCheckpointId) ||
                isLoadingData
              }
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Paper>
  );
}

export default AD28CheckpointsForm;
