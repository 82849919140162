export const routeNames = {
  Authentication: "/",
  Login: "/login",
  Forgotpass: "/forgot-password",
  Home: "/home",
  CRCCheck: "/data-management/crc-check",
  Aixm: "/aixm",
  Amdb: "/amdb",
  Etod: "/etod",
  Airspace: "/airspace",
  eChart: "/electronic-chart",
  ObstacleAnalysis: "/obstacle-analysis",
  UserDashboard: "/data-management",
  NewPassReq: "/data-management/new-password-request",
  ManageUsers: "/data-management/manage-users",
  AdminReview: "/data-management/review",
  SurveyorReportList: "surveyor-report-list",
  AirportReviewList: "airport-review-list",
  NewAirport: "/data-management/new-airport",
  ReviewSurveyData: "/data-management/review/surveyor-report-list/:reportId",
  SurveyorRevalidation: "/data-management/surveyor-revalidation",
  AD2DataEntry: "/ad-2-data-entry",
  AddAirportData: "add-airport-data",
  AirportReview: "/review-airport-data/:airportId",
  EtodAirportDetail: "/etod/airport-detail/:airportId",
  EtodAirportGenerateSurface: "/etod/generate-surface",
};
