import React from "react";
import { flatten, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DArea2D({ etodArea2D, getAreaColors }) {
  const a2dcoordinates = etodArea2D[0].coordinates.coordinates || [];
  const area2DMap = flatten(a2dcoordinates).map((coordinate) => {
    return coordinate.map((area) => {
      return [area[0], area[1], area[2] * ZvalueMultiplyby];
    });
  });
  const a2d = area2DMap[0];
  const holeArea = area2DMap[1];
  const areaColor = getAreaColors();

  return (
    <Entity
      name="2D"
      polygon={{
        hierarchy: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(
            flattenDeep(a2d)
          ),
          holes: [
            {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(holeArea)
              ),
            },
          ],
        },
        extrudedHeight: 0,
        perPositionHeight: true,
        material: Cesium.Color.fromCssColorString(
          areaColor["Area2D"]
        ).withAlpha(0.5),
        outline: true,
      }}
    />
  );
}
