import React, { Fragment } from "react";
import { orderBy } from "lodash";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import DiffTableCell from "@components/tabel-component/DiffTableCell";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import ValueItem from "@components/tabel-component/ValueItem";
import { dataChangedRowColorMap } from "@constants/colors";
import { theme } from "@styles/theme";

export default function VerticalSignificanceInReviewTable({ data }) {
  return (
    <Fragment>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          In Review
        </Typography>
      </Toolbar>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" sx={{ width: "1vw" }}>
              UID
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "1vw" }}>
              OBJECT NAME
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "2vw" }}>
              <Box>LATITUDE</Box>
              <Stack
                direction="row"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
              >
                <StyledBoxV2>NEW</StyledBoxV2>
                <StyledBoxV2>OLD</StyledBoxV2>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "2vw" }}>
              <Box>LONGITUDE</Box>
              <Stack
                direction="row"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={1}
              >
                <StyledBoxV2>NEW</StyledBoxV2>
                <StyledBoxV2>OLD</StyledBoxV2>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "1vw" }}>
              <Stack>
                <Box>TOP ELEV. IN m. (EGM 08)</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>NEW</StyledBoxV2>
                  <StyledBoxV2>OLD</StyledBoxV2>
                  <StyledBoxV2>DIFF</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "1vw" }}>
              <Stack>
                <Box>DISTANCE IN M. FROM NEW ARP</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>NEW</StyledBoxV2>
                  <StyledBoxV2>OLD</StyledBoxV2>
                  <StyledBoxV2>DIFF</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ width: "1vw" }}>
              <Stack>
                <Box>MAG. BRG.FROM NEW ARP</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>NEW</StyledBoxV2>
                  <StyledBoxV2>OLD</StyledBoxV2>
                  <StyledBoxV2>DIFF</StyledBoxV2>
                </Stack>
              </Stack>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(data, ["uid1"], ["asc"]).map((row, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  background:
                    dataChangedRowColorMap[row.result] ??
                    theme.palette.white.main,
                }}
              >
                <DiffTableCell align="center">{row["uid1"]}</DiffTableCell>
                <DiffTableCell align="center">
                  {row["object1"] || "-"}
                </DiffTableCell>
                <DiffTableCell align="center" isdiff={row["latdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["latitude1"]} />
                    <ValueItem value={row["latitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center" isdiff={row["longdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["longitude1"]} />
                    <ValueItem value={row["longitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["top_elevdiff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["top_elev1"]} />
                    <ValueItem value={row["top_elev2"]} />
                    <ValueItem value={row["top_elevdiff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["distancediff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["distance1"]} />
                    <ValueItem value={row["distance2"]} />
                    <ValueItem value={row["distancediff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["mag_brgdiff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["mag_brg1"]} />
                    <ValueItem value={row["mag_brg2"]} />
                    <ValueItem value={row["mag_brgdiff"]} />
                  </Stack>
                </DiffTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
}
