import React, { Fragment } from "react";
import { isEmpty, sortBy } from "lodash";
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Stack,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";

export default function AerodromeObstacles({
  selctedAirport,
  activeCategory,
  aixmobstacles,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          ...styles.addAirportName,
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.black.main,
        }}
      >
        {selctedAirport.name} {">"} {activeCategory.label}
      </Stack>
      <Paper sx={styles.aerodromeOLStable}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={6} align="center">
                In Approach/Take-off/Circling Area and at AD
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>RWY/Area affected</StyledTableCell>
              <StyledTableCell>Obstacle type</StyledTableCell>
              <StyledTableCell>Coordinates</StyledTableCell>
              <StyledTableCell>Elevation (EGM 08)</StyledTableCell>
              <StyledTableCell>Marking/LGT</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aixmobstacles && aixmobstacles.length > 0 ? (
              sortBy(aixmobstacles, ["uuid"]).map((data, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell>
                      {data.rwy_designator ? data.rwy_designator : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data.obstacle_type ? data.obstacle_type : "NIL"}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "150px" }}>
                      {/* <Typography>{utmLat ? `${utmLat}N` : "NIL"}</Typography>
                      <Typography>{utmLng ? `${utmLng}E` : "NIL"}</Typography> */}
                      <Typography>{data.lat ? data.lat : "NIL"}</Typography>
                      <Typography>{data.lng ? data.lng : "NIL"}</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "150px" }}>
                      {data.elevation || "NIL"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data.marking ? data.marking : "-"} /{" "}
                      {data.lighted ? data.lighted : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {data.remarks ? data.remarks : "NIL"}
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
