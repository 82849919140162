import React, { Fragment } from "react";
import { chain, flatten, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DOLSObstacleFreeZone({
  OLSObstacleFreeZoneList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSObstacleFreeZoneList = chain(OLSObstacleFreeZoneList)
    .filter((ofzObj) => {
      const name = `OFZ_${ofzObj.category}_${ofzObj.rwy}`;
      return selectedOls.includes(`${name}__${olsColor[name]}`);
    })
    .value();

  return (
    <Fragment>
      {visibleOLSObstacleFreeZoneList.map((olsOfz, i) => {
        const name = `OFZ_${olsOfz.category}_${olsOfz.rwy}`;
        const ofzCoordinates = olsOfz.coordinates.coordinates;
        const ofzCoordinateMap = flatten(ofzCoordinates).map((ofzCoords) => {
          return ofzCoords.map((ofz) => {
            return [ofz[0], ofz[1], ofz[2] * ZvalueMultiplyby];
          });
        });

        return (
          <Entity
            key={i}
            name={name}
            polygon={{
              hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(
                flattenDeep(ofzCoordinateMap)
              ),
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[name]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
