import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { IoIosArrowUp } from "react-icons/io";
import { styled } from "@mui/material/styles";

const MapWidget = styled((props) => (
  <MuiAccordionSummary expandIcon={<IoIosArrowUp size={18} />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-expandIconWrapper": {
    backgroundColor: "#E0E0E0",
    height: "20px",
    width: "20px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export default MapWidget;
