import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { filter, find, isEmpty } from "lodash";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { EditIcon } from "@assets/svg/edit";
import { LightButton, PrimaryButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd28TaxiwayValidationSchema,
  Ad212SurfaceTypes,
  Ad212PavementTypes,
  Ad212EveluationTypes,
  Ad212MaxTyrePressureTypes,
  Ad212PavementSubgradeTypes,
} from "@constants/ad2Data";
import { taxiwayDetail } from "@constants/ad2DataForm.constant";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  deleteAD2AAIAirport,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad28TaxiwayForm({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const {
    isLoadingAD28AAIAirportDetail,
    AAIAirport,
    isLoadingDeleteAD210AAIAirport,
  } = useSelector((state) => state.airport);
  const [editTxyId, setEditTxyId] = useState(null);
  const [editTxyIndex, setEditTxyIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    if (Array.isArray(AAIAirport)) {
      AAIAirport.map((txwys, index) => {
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].id`,
          txwys.id ? txwys.id : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].txwyname`,
          txwys.name ? txwys.name : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].type`,
          txwys.type ? txwys.type : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].width`,
          txwys.width ? txwys.width : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].widthShoulder`,
          txwys.widthShoulder ? txwys.widthShoulder : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].length`,
          txwys.length ? txwys.length : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].composition`,
          txwys.composition ? txwys.composition : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].classPCN`,
          txwys.classPCN ? Number(txwys.classPCN) : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].pavementTypePCN`,
          txwys.pavementTypePCN ? txwys.pavementTypePCN : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].pavementSubgradePCN`,
          txwys.pavementSubgradePCN ? txwys.pavementSubgradePCN : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].maxTyrePressurePCN`,
          txwys.maxTyrePressurePCN ? txwys.maxTyrePressurePCN : ""
        );
        createAd28TaxiwayForm.setFieldValue(
          `taxiways[${index}].evaluationMethodPCN`,
          txwys.evaluationMethodPCN ? txwys.evaluationMethodPCN : ""
        );

        return true;
      });
    }
  }, [AAIAirport, createAd28TaxiwayForm]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "taxiway",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleSuccessCallback = () => {
    createAd28TaxiwayForm.resetForm();
    createAd28TaxiwayForm.setSubmitting(false);
  };

  const createAd28TaxiwayForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      taxiways: [taxiwayDetail],
    },
    validationSchema: createAd28TaxiwayValidationSchema,
    onSubmit: (values) => {
      const taxiwayValues = values.taxiways.map((txwy) => {
        return {
          name: txwy.txwyname ? txwy.txwyname : "",
          type: txwy.type ? txwy.type : "",
          width: txwy.width ? txwy.width : "",
          widthShoulder: txwy.widthShoulder ? txwy.widthShoulder : "",
          length: txwy.length ? txwy.length : "",
          composition: txwy.composition ? txwy.composition : "",
          classPCN: txwy.classPCN ? txwy.classPCN : "",
          pavementTypePCN: txwy.pavementTypePCN ? txwy.pavementTypePCN : "",
          pavementSubgradePCN: txwy.pavementSubgradePCN
            ? txwy.pavementSubgradePCN
            : "",
          maxTyrePressurePCN: txwy.maxTyrePressurePCN
            ? txwy.maxTyrePressurePCN
            : "",
          evaluationMethodPCN: txwy.evaluationMethodPCN
            ? txwy.evaluationMethodPCN
            : "",
        };
      });

      if (editTxyId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "taxiway",
              { ...taxiwayValues[editTxyIndex], id: editTxyId },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditTxyId(null);
                setEditTxyIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      } else {
        const taxiwayData = filter(values.taxiways, ["id", ""]).map((txwy) => {
          return {
            name: txwy.txwyname ? txwy.txwyname : "",
            type: txwy.type ? txwy.type : "",
            width: txwy.width ? txwy.width : "",
            widthShoulder: txwy.widthShoulder ? txwy.widthShoulder : "",
            length: txwy.length ? txwy.length : "",
            composition: txwy.composition ? txwy.composition : "",
            classPCN: txwy.classPCN ? txwy.classPCN : "",
            pavementTypePCN: txwy.pavementTypePCN ? txwy.pavementTypePCN : "",
            pavementSubgradePCN: txwy.pavementSubgradePCN
              ? txwy.pavementSubgradePCN
              : "",
            maxTyrePressurePCN: txwy.maxTyrePressurePCN
              ? txwy.maxTyrePressurePCN
              : "",
            evaluationMethodPCN: txwy.evaluationMethodPCN
              ? txwy.evaluationMethodPCN
              : "",
          };
        });
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addAD2AAIAirportDetail(
              "taxiway",
              {
                form: taxiwayData,
              },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditTxyId(null);
                setEditTxyIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      }
    },
  });

  const handleDeleteTxwy = (deleteId) => {
    let id;
    if (editTxyId) {
      id = editTxyId;
    } else {
      id = deleteId;
    }
    if (!id) return;
    setDeleteId(id);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleSaveDeleteDialog = () => {
    if (!deleteId) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "taxiway",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            taxiwayId: String(deleteId),
          },
          () => {
            setEditTxyId(null);
            setEditTxyIndex(null);
            handleSuccessCallback();
            setOpenDeleteConfirmDialog(false);
            handleGetADCallBack();
          },
          token
        )
      );
    });
  };

  const handleEditTaxiwaydetail = (id) => {
    setEditTxyId(id);
  };

  const handleEditDoneTaxiway = (editId, index) => {
    setEditTxyId(editId);
    setEditTxyIndex(index);
  };

  return (
    <Paper className="add-airport-data-form">
      <FormikProvider value={createAd28TaxiwayForm}>
        <form
          onSubmit={createAd28TaxiwayForm.handleSubmit}
          style={{ height: "100%" }}
        >
          <Box sx={{ ...styles.adFormField, height: "calc(100% - 54px)" }}>
            <FieldArray
              name="taxiways"
              render={(arrayHelpers) => (
                <div>
                  {createAd28TaxiwayForm.values.taxiways.map((_, index) => {
                    const isTouched = createAd28TaxiwayForm.touched?.taxiways
                      ? createAd28TaxiwayForm.touched?.taxiways[index]
                      : false;
                    const isErrors = createAd28TaxiwayForm.errors?.taxiways
                      ? createAd28TaxiwayForm.errors?.taxiways[index]
                      : false;

                    const txwysIds = !isEmpty(AAIAirport)
                      ? Array.isArray(AAIAirport) &&
                        AAIAirport.map((txwydata) => txwydata.id)
                      : [];

                    return (
                      <Fragment key={index}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={styles.adTaxiway}>
                            <Typography
                              variant="span"
                              sx={{ width: "85%", display: "inline-block" }}
                            >
                              Taxiway {index + 1}
                            </Typography>
                            <Box sx={styles.adFuelDeleteBtn}>
                              {!isEmpty(AAIAirport) &&
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .id &&
                                (editTxyId === txwysIds[index] ? (
                                  <button
                                    type="button"
                                    style={styles.ad28Btn}
                                    onClick={() => {
                                      createAd28TaxiwayForm.handleSubmit();
                                      handleEditDoneTaxiway(editTxyId, index);
                                    }}
                                  >
                                    Done
                                  </button>
                                ) : (
                                  <IconButton
                                    color="primary"
                                    sx={{ p: 0 }}
                                    onClick={() =>
                                      handleEditTaxiwaydetail(txwysIds[index])
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                ))}
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (editTxyId) {
                                    handleDeleteTxwy();
                                  } else if (!isEmpty(AAIAirport)) {
                                    if (
                                      txwysIds.includes(
                                        createAd28TaxiwayForm.values.taxiways[
                                          index
                                        ].id
                                      )
                                    ) {
                                      handleDeleteTxwy(txwysIds[index]);
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  } else {
                                    arrayHelpers.remove(index);
                                  }
                                }}
                              />
                            </Box>
                          </Box>
                        </Stack>
                        <input
                          hidden
                          name={`taxiways[${index}].id`}
                          onChange={createAd28TaxiwayForm.handleChange}
                          value={
                            createAd28TaxiwayForm.values.taxiways[index].id
                          }
                        />
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Name</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`taxiways[${index}].txwyname`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .txwyname
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .txwyname &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .txwyname
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].txwyname
                                  : ""
                              }
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                              }}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Type</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              name={`taxiways[${index}].type`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .type
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .type &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .type
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].type
                                  : ""
                              }
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                              }}
                              sx={{ mt: 2 }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Width</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`taxiways[${index}].width`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .width
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .width &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .width
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].width
                                  : ""
                              }
                              sx={{ mt: 2 }}
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    FT
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Width Shoulder</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`taxiways[${index}].widthShoulder`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .widthShoulder
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .widthShoulder &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .widthShoulder
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].widthShoulder
                                  : ""
                              }
                              sx={{ mt: 2 }}
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Length</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`taxiways[${index}].length`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .length
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .length &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .length
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].length
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    M
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Composition</Box>
                          <Box sx={styles.adInput}>
                            <SelectInput
                              name={`taxiways[${index}].composition`}
                              value={find(Ad212SurfaceTypes, {
                                value:
                                  createAd28TaxiwayForm.values.taxiways[index]
                                    .composition,
                              })}
                              onChange={(option) => {
                                createAd28TaxiwayForm.setFieldValue(
                                  `taxiways[${index}].composition`,
                                  option.value
                                );
                              }}
                              options={Ad212SurfaceTypes}
                              isDisabled={
                                !createAd28TaxiwayForm.values.taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    )
                              }
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Class PCN</Box>
                          <Box sx={styles.adInput}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              type="number"
                              name={`taxiways[${index}].classPCN`}
                              value={
                                createAd28TaxiwayForm.values.taxiways[index]
                                  .classPCN
                              }
                              onChange={createAd28TaxiwayForm.handleChange}
                              error={
                                isTouched &&
                                isErrors &&
                                createAd28TaxiwayForm.touched?.taxiways[index]
                                  .classPCN &&
                                Boolean(
                                  createAd28TaxiwayForm.errors?.taxiways[index]
                                    .classPCN
                                )
                              }
                              helperText={
                                isErrors && isTouched
                                  ? createAd28TaxiwayForm.errors?.taxiways[
                                      index
                                    ].classPCN
                                  : ""
                              }
                              sx={{ mb: 2, mt: 2 }}
                              InputProps={{
                                readOnly: !createAd28TaxiwayForm.values
                                  .taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    NUM
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Pavement Type PCN</Box>
                          <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                            <SelectInput
                              name={`taxiways[${index}].pavementTypePCN`}
                              value={find(Ad212PavementTypes, {
                                value:
                                  createAd28TaxiwayForm.values.taxiways[index]
                                    .pavementTypePCN,
                              })}
                              onChange={(option) => {
                                createAd28TaxiwayForm.setFieldValue(
                                  `taxiways[${index}].pavementTypePCN`,
                                  option.value
                                );
                              }}
                              options={Ad212PavementTypes}
                              isDisabled={
                                !createAd28TaxiwayForm.values.taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    )
                              }
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Pavement Subgrade PCN</Box>
                          <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                            <SelectInput
                              name={`taxiways[${index}].pavementSubgradePCN`}
                              value={find(Ad212PavementSubgradeTypes, {
                                value:
                                  createAd28TaxiwayForm.values.taxiways[index]
                                    .pavementSubgradePCN,
                              })}
                              onChange={(option) => {
                                createAd28TaxiwayForm.setFieldValue(
                                  `taxiways[${index}].pavementSubgradePCN`,
                                  option.value
                                );
                              }}
                              options={Ad212PavementSubgradeTypes}
                              isDisabled={
                                !createAd28TaxiwayForm.values.taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    )
                              }
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Max Tyre Pressure</Box>
                          <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                            <SelectInput
                              name={`taxiways[${index}].maxTyrePressurePCN`}
                              value={find(Ad212MaxTyrePressureTypes, {
                                value:
                                  createAd28TaxiwayForm.values.taxiways[index]
                                    .maxTyrePressurePCN,
                              })}
                              onChange={(option) => {
                                createAd28TaxiwayForm.setFieldValue(
                                  `taxiways[${index}].maxTyrePressurePCN`,
                                  option.value
                                );
                              }}
                              options={Ad212MaxTyrePressureTypes}
                              isDisabled={
                                !createAd28TaxiwayForm.values.taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    )
                              }
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{ pl: 2, pr: 2 }}
                        >
                          <Box sx={{ width: "30%" }}>Evaluation Method PCN</Box>
                          <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                            <SelectInput
                              name={`taxiways[${index}].evaluationMethodPCN`}
                              value={find(Ad212EveluationTypes, {
                                value:
                                  createAd28TaxiwayForm.values.taxiways[index]
                                    .evaluationMethodPCN,
                              })}
                              onChange={(option) => {
                                createAd28TaxiwayForm.setFieldValue(
                                  `taxiways[${index}].evaluationMethodPCN`,
                                  option.value
                                );
                              }}
                              options={Ad212EveluationTypes}
                              isDisabled={
                                !createAd28TaxiwayForm.values.taxiways[index].id
                                  ? false
                                  : !Boolean(
                                      txwysIds[index] === editTxyId && editTxyId
                                    )
                              }
                            />
                          </Box>
                        </Stack>
                        <Divider
                          sx={{
                            mb: 1,
                          }}
                        />
                      </Fragment>
                    );
                  })}
                  <PrimaryButton
                    label="Add More"
                    variant="outlined"
                    sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                    onClick={() => arrayHelpers.push(taxiwayDetail)}
                  />
                </div>
              )}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={styles.adFormBtn}
          >
            <LightButton
              label="Clear"
              sx={{ mr: 4 }}
              isLoading={false}
              onClick={() => {
                createAd28TaxiwayForm.resetForm();
              }}
            />
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingAD28AAIAirportDetail}
              disabled={isLoadingAD28AAIAirportDetail || Boolean(editTxyId)}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Paper>
  );
}
