import * as yup from "yup";
import { theme } from "@styles/theme";

export const eTodArea = {
  area: "AREA",
  ols: "OLS",
};

export const areaFilter = [
  {
    id: 1,
    label: "Area",
    value: "AREA",
  },
  {
    id: 2,
    label: "OLS",
    value: "OLS",
  },
];

export const areaOptionList = [
  {
    id: 1,
    name: "Area 1",
    value: "Area1",
    color: "#ffa500",
    sub: [],
  },
  {
    id: 2,
    name: "Area 2A",
    value: "Area2A",
    color: "#469990",
    sub: [],
  },
  {
    id: 3,
    name: "Area 2B",
    value: "Area2B",
    color: "#469990",
    sub: [],
  },
  {
    id: 4,
    name: "Area 2C",
    value: "Area2C",
    color: "#469990",
    sub: [],
  },
  {
    id: 5,
    name: "Area 2D",
    value: "Area2D",
    color: "#992407",
    sub: [],
  },
  // {
  //   id: 6,
  //   name: "Area 3",
  //   value: "Area3",
  //   color: "#f58231",
  //   sub: [],
  // },
  {
    id: 7,
    name: "Area 4",
    value: "Area4",
    color: theme.palette.primary.main,
    sub: [],
  },
];

export const demOptionList = [
  {
    id: 1,
    name: "Area 2A",
    value: "Area2A",
    color: "#469990",
    sub: [],
  },
  {
    id: 2,
    name: "Area 2B",
    value: "Area2B",
    color: "#469990",
    sub: [],
  },
  {
    id: 3,
    name: "Area 2C",
    value: "Area2C",
    color: "#469990",
    sub: [],
  },
  {
    id: 4,
    name: "Area 2D",
    value: "Area2D",
    color: "#992407",
    sub: [],
  },
  // {
  //   id: 5,
  //   name: "Area 3",
  //   value: "Area3",
  //   color: "#f58231",
  //   sub: [],
  // },
  // {
  //   id: 6,
  //   name: "Area 4",
  //   value: "Area4",
  //   color: theme.palette.primary.main,
  //   sub: [],
  // },
];

export const ofzOptionLabel = {
  BL: "Balked Landing",
  IA: "Inner Approach",
  ITS: "Inner Transitional Surface",
};

export const OLSOptionList = [
  {
    id: 1,
    name: "RWY Strip",
    value: "OLS_RwyStrip_List",
    color: theme.palette.black.main,
    sub: [],
  },
  {
    id: 2,
    name: "Approach",
    value: "OLS_Approach_List",
    color: "#469990",
    sub: [],
  },
  {
    id: 3,
    name: "Take off",
    value: "OLS_TakeOffClimb_List",
    color: "#f58231",
    sub: [],
  },
  {
    id: 4,
    name: "Horizontal Surface",
    value: "OLS_HorizontalSurface_List",
    color: theme.palette.primary.main,
    sub: [],
  },
  {
    id: 5,
    name: "Transitional Surface",
    value: "Transitional_Surface",
    color: theme.palette.primary.main,
    sub: [],
  },
  {
    id: 6,
    name: "Outer Transitional Surface",
    value: "Outer_Transitional_Surface",
    color: theme.palette.primary.main,
    sub: [],
  },
  {
    id: 7,
    name: "Conical Surface",
    value: "Conical",
    color: theme.palette.primary.main,
    sub: [],
  },
  {
    id: 8,
    name: "Obstacle Free Zone",
    value: "OLS_ObstacleFreeZone_List",
    color: theme.palette.primary.main,
    sub: [],
  },
];

export const airportRWYDetailKeys = {
  thr: "Threshold",
  end: "End Points",
  trueBearing: "True Bearing",
  cwy: "Clear Way Distance",
  displacement: "Displacement",
  widthStrip: "Width Strip",
  elevthr: "Elevation Threshold",
};

export const rwySurfaceCategory800 = [
  {
    id: 1,
    label: "VFR",
    value: 1,
  },
  {
    id: 2,
    label: "IFR (VOR / DME / NDB)",
    value: 5,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I)",
    value: 8,
  },
];

export const rwySurfaceCategory8001200 = [
  {
    id: 1,
    label: "VFR",
    value: 2,
  },
  {
    id: 2,
    label: "IFR (VOR / DME / NDB)",
    value: 5,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I)",
    value: 8,
  },
];

export const rwySurfaceCategory12001800 = [
  {
    id: 1,
    label: "VFR",
    value: 3,
  },
  {
    id: 2,
    label: "IFR (VOR / DME / NDB)",
    value: 6,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I)",
    value: 9,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I/II)",
    value: 10,
  },
];

export const rwySurfaceCategoryUp1800 = [
  {
    id: 1,
    label: "VFR",
    value: 4,
  },
  {
    id: 2,
    label: "IFR (VOR / DME / NDB)",
    value: 7,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I)",
    value: 9,
  },
  {
    id: 2,
    label: "IFR (ILS / LOC - I/II)",
    value: 10,
  },
];

export const createAirportGenerateSurfaceSchema = yup.object({
  arp_tmpname: yup.string().required("Required"),
  arp_latitude: yup.string().required("Required"),
  arp_longitude: yup.string().required("Required"),
  arp_elevation: yup.string().required("Required"),
  runways: yup.array().of(
    yup.object().shape({
      rwywidth: yup.number().required("Required"),
      thrLatitudeA: yup.string().required("Required"),
      thrLongitudeA: yup.string().required("Required"),
      thrElvA: yup.number().required("Required"),
      endLatitudeA: yup.string().required("Required"),
      endLongitudeA: yup.string().required("Required"),
      endElvA: yup.number().required("Required"),
      cwyA: yup.number().required("Required"),
      bearingA: yup.number().required("Required"),
      toraA: yup.number().required("Required"),
      rdA: yup.number().required("Required"),
      categoryA: yup.string().required("Required"),
    })
  ),
});
