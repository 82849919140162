import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

export default function AirTrafficControlServiceItem({
  selctedAirport,
  activeCategory,
  airtrafficservices,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={7}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Service Designation</StyledTableCell>
              <StyledTableCell>Call sign</StyledTableCell>
              <StyledTableCell>Channel(s)</StyledTableCell>
              <StyledTableCell>SATVOICE Number(s)</StyledTableCell>
              <StyledTableCell>Logon address</StyledTableCell>
              <StyledTableCell>Hours of operation</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(airtrafficservices) ? (
              airtrafficservices.map((dd, index) => (
                <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                  <StyledTableCell>
                    {dd.designation ? dd.designation : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dd.callsign ? dd.callsign : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dd.frequency ? dd.frequency : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dd.satvoiceNumber ? dd.satvoiceNumber : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dd.logon ? dd.logon : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dd.operationalstatus ? dd.operationalstatus : "NIL"}
                  </StyledTableCell>

                  <StyledTableCell>
                    {dd.remarks ? dd.remarks : "NIL"}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
