import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRSALoading: false,
  isLoginLoading: false,
  isLogoutLoading: false,
  rsaKey: null,
  user: null,
  isRequestNewPassowrdLoading: false,
  isCRC32QGenerateRequesting: false,
  loginCallbakUrl: null,
};

export const userSession = createSlice({
  name: "userSession",
  initialState,
  reducers: {
    setRsaRequest: (state, action) => {
      state.isRSALoading = action.payload;
    },
    setLoginRequest: (state, action) => {
      state.isLoginLoading = action.payload;
    },
    setLogoutRequest: (state, action) => {
      state.isLogoutLoading = action.payload;
    },
    setRSA: (state, action) => {
      state.rsaKey = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRequestNewPassowrdLoading: (state, action) => {
      state.isRequestNewPassowrdLoading = action.payload;
    },
    setCRC32QGenerateRequest: (state, action) => {
      state.isCRC32QGenerateRequesting = action.payload;
    },
    setLoginCallbakUrl: (state, action) => {
      state.loginCallbakUrl = action.payload;
    },
  },
});
