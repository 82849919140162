import React, { Fragment } from "react";
import { flatten, chain, flattenDeep } from "lodash";
import * as Cesium from "cesium";
import { Entity } from "resium";
import { ZvalueMultiplyby } from "@constants/constant";

export default function Render3DOLSHorizontalSurface({
  OLSHorizontalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const olsColor = getOLSColors();
  const visibleOLSHorizontalSurfaceList = chain(OLSHorizontalSurfaceList)
    .filter((hslObj) => {
      return selectedOls.includes(
        `${hslObj.sub_category}__${olsColor[hslObj.sub_category]}`
      );
    })
    .value();

  return (
    <Fragment>
      {visibleOLSHorizontalSurfaceList.map((olsHsl, i) => {
        const hslCoordinates = olsHsl.coordinates.coordinates;
        const hslCoordinateMap = flatten(hslCoordinates).map((hslCoords) => {
          return hslCoords.map((hsl) => {
            return [hsl[0], hsl[1], hsl[2] * ZvalueMultiplyby];
          });
        });
        const outerArea = hslCoordinateMap[0];
        hslCoordinateMap.shift();

        return (
          <Entity
            key={i}
            name={olsHsl.sub_category}
            polygon={{
              hierarchy: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                  flattenDeep(outerArea)
                ),
                holes: hslCoordinateMap.map((coord) => ({
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(
                    flattenDeep(coord)
                  ),
                })),
              },
              extrudedHeight: 0,
              perPositionHeight: true,
              material: Cesium.Color.fromCssColorString(
                olsColor[olsHsl.sub_category]
              ).withAlpha(0.5),
              outline: true,
            }}
          />
        );
      })}
    </Fragment>
  );
}
