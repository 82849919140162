import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, sortBy } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import { EditIcon } from "@assets/svg/edit";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { PrimaryButton, UploadButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import { LightTooltip } from "@components/core/LightTooltip";
import StyledTableCell, {
  StyledTableCellV4,
} from "@components/tabel-component/StyledTableCell";
import { ACCESS_TOKEN } from "@constants/constant";
import { AD210initialValue } from "@constants/ad2DataForm.constant";
import { adRunwayInfoOptions, setAAIAirport } from "@store/airport";
import { generateCRC32Q } from "@store/userSession/actions";
import {
  addAAIAirportDetailUpload,
  addEditAAIAirportDetail,
  deleteAD2AAIAirport,
  getAAIAirport,
} from "@store/airport/actions";
import { downloadCategoryTemplate } from "@store/survey/actions";
import { sheetValidation } from "@utils/sheetValidation";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad210Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const {
    isLoadingAAIAirport,
    isLoadingAddAAIAirportDetail,
    isLoadingDeleteAD210AAIAirport,
    AAIAirport,
    isUplodeObstacleFileRequest,
  } = useSelector((state) => state.airport);

  const adRwyDrctnIdsData = useSelector((state) => adRunwayInfoOptions(state));

  const isCRC32QGenerateRequesting = useSelector(
    (state) => state.userSession.isCRC32QGenerateRequesting
  );
  const isDownloadTemplate = useSelector(
    (state) => state.survey.isDownloadTemplate
  );
  const [isObstacleUploading, setObstacleUploading] = useState(false);
  const [obstacleData, setObstacleData] = useState([]);
  const [obstacleFile, setObstacleFile] = useState(null);
  const [CRCcode, setCRCcode] = useState(null);
  const [isOpenCrcPopup, setOpenCrcPopup] = useState(false);
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const [obstacleId, setObstacleId] = useState(null);

  useEffect(() => {
    if (!CRCcode) return;
    setOpenCrcPopup(true);
  }, [CRCcode]);

  const handleAddSuccessCallback = () => {
    dispatch(
      getAAIAirport(
        "aixmobstacles",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleObstacleFileUpload = (event) => {
    event.preventDefault();
    setObstacleUploading(true);

    const files = event.target.files;
    const size = (files[0].size / 1024 / 1024).toFixed(2);
    setObstacleFile(files);

    if (size > 10) {
      toast.error("File should be less than 10 MB.");
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws);
      const sheetCoulmns = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const validation = sheetValidation(selectedCategory, sheetCoulmns);
      setObstacleUploading(false);
      if (validation.isValid) {
        setObstacleData(dataParse);
        dispatch(
          generateCRC32Q(files[0], (crcCode) => {
            setCRCcode(crcCode);
          })
        );
      } else {
        setObstacleFile(null);
        toast.error(validation.message);
      }
    };

    reader.readAsBinaryString(files[0]);
  };

  const handleEditRow = (data) => {
    const elv = data?.elevation ? data?.elevation.split(" ")[0] : 0;
    createAd210Form.setValues({
      elevation: elv ? elv : 0,
      horizontalDatum: data?.horizontalDatum ? data?.horizontalDatum : "",
      verticalDatum: data?.verticalDatum ? data?.verticalDatum : "",
      lat: data?.lat ? data?.lat : "",
      lng: data?.lng ? data?.lng : "",
      lighted: data?.lighted ? data?.lighted : "",
      marking: data?.marking ? data?.marking : "",
      uuid: data?.uuid ? data?.uuid : "",
      obstacle_type: data?.obstacle_type ? data?.obstacle_type : "",
      runwaydirection_id: data?.runwaydirection_id
        ? data?.runwaydirection_id
        : "",
      remarks: data?.remarks ? data?.remarks : "",
    });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteSuccessCallBack = () => {
    handleAddSuccessCallback();
    handleCloseDeleteDialog();
  };

  const handleSaveDeleteDialog = () => {
    if (!obstacleId) return;
    setOpenDeleteConfirmDialog(true);
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "aixmobstacles",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            obstacleId: String(obstacleId),
          },
          handleDeleteSuccessCallBack,
          token
        )
      );
    });
  };

  const handleSubmitObstacleFile = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        addAAIAirportDetailUpload(
          "aixmobstacles",
          selectedAirport.id,
          selectedAirport.version_id,
          obstacleFile[0],
          token,
          handleAddSuccessCallback
        )
      );
    });
  };

  const createAd210Form = useFormik({
    enableReinitialize: true,
    initialValues: AD210initialValue,
    onSubmit: (values) => {
      if (!obstacleId) return;
      const payload = {
        data_type: "OLS",
        elevation: values.elevation ? `${values.elevation} FEET` : "",
        horizontalDatum: values.horizontalDatum ? values.horizontalDatum : "",
        obstacleId,
        lat: values.lat,
        lighted: values.lighted ? values.lighted : "",
        lng: values.lng,
        marking: values.marking ? values.marking : "",
        obstacle_type: values.obstacle_type ? values.obstacle_type : "",
        remarks: values.remarks ? values.remarks : "",
        runwaydirection_id: values.runwaydirection_id
          ? values.runwaydirection_id
          : null,
        uuid: values.uuid ? values.uuid : "",
        verticalDatum: values.verticalDatum ? values.verticalDatum : "",
      };
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "aixmobstacles",
            payload,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            handleAddSuccessCallback
          )
        );
      });
    },
  });

  const handleDownloadObstacleTemplate = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(downloadCategoryTemplate("Objects/Obstacles", token));
    });
  };
  if (isLoadingAAIAirport) return true;
  return (
    <Fragment>
      {obstacleData.length === 0 && isEmpty(AAIAirport) && (
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          mt={5}
        >
          <Box sx={{ paddingLeft: 4 }}>
            <Typography variant="span" sx={styles.ad210Label}>
              Upload a xls file for entering the data of Aerodrome Obstacles.
              Click on Upload button to upload.
            </Typography>
            <UploadButton
              ref={uploadRef}
              label="Upload"
              accept=".xlsx"
              loadingIndicator="Uploading..."
              isLoading={isObstacleUploading || isCRC32QGenerateRequesting}
              disabled={isObstacleUploading || isCRC32QGenerateRequesting}
              handleUpload={handleObstacleFileUpload}
              sx={{ width: 200, mb: 2 }}
            />
          </Box>
          <Box sx={{ paddingLeft: 4 }}>
            <Typography variant="span" sx={styles.ad210Label}>
              Download a template for uploading the data.
            </Typography>
            <PrimaryButton
              label="Download Template"
              isLoading={isDownloadTemplate}
              disabled={isDownloadTemplate}
              onClick={handleDownloadObstacleTemplate}
              sx={{ width: 200 }}
              variant="outlined"
            />
          </Box>
        </Stack>
      )}
      {obstacleData && obstacleData.length > 0 && isEmpty(AAIAirport) && (
        <TableContainer
          sx={{ height: "calc(100% - 58px)", width: "100%", overflow: "auto" }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY /AREA AFFECTED
                </StyledTableCell>
                <StyledTableCell>OBSTACLE TYPE</StyledTableCell>
                <StyledTableCell>Latitude</StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>Longitude</StyledTableCell>
                <StyledTableCell>Elevation</StyledTableCell>
                <StyledTableCell>MARKING /LGT</StyledTableCell>
                <StyledTableCell sx={{ width: 250 }}>REMARKS</StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  IDENTIFIER
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Horizontal Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Vertical Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 150 }}>
                  UOM Elevation
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY Direction
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>Airport</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {obstacleData.map((row, i) => {
                return (
                  <TableRow key={`${i}_${row?.IDENTIFIER}`}>
                    <StyledTableCell>
                      {row["RWY /AREA AFFECTED"]}
                    </StyledTableCell>
                    <StyledTableCell>{row["OBSTACLE TYPE"]}</StyledTableCell>
                    <StyledTableCell>{row["Latitude"]}</StyledTableCell>
                    <StyledTableCell>{row["Longitude"]}</StyledTableCell>
                    <StyledTableCell>{row["Elevation"]}</StyledTableCell>
                    <StyledTableCell>{row["MARKING /LGT"]}</StyledTableCell>
                    <StyledTableCell>{row["REMARKS"]}</StyledTableCell>
                    <StyledTableCell>{row["IDENTIFIER"]}</StyledTableCell>
                    <StyledTableCell>{row["Horizontal Datum"]}</StyledTableCell>
                    <StyledTableCell>{row["Vertical Datum"]}</StyledTableCell>
                    <StyledTableCell>{row["UOM Elevation"]}</StyledTableCell>
                    <StyledTableCell>{row["RWY Direction"]}</StyledTableCell>
                    <StyledTableCell>{row["Airport"]}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isEmpty(AAIAirport) && (
        <TableContainer sx={{ height: "100%", overflow: "auto" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY /AREA AFFECTED
                </StyledTableCell>
                <StyledTableCell>OBSTACLE TYPE</StyledTableCell>
                <StyledTableCell>Latitude</StyledTableCell>
                <StyledTableCell sx={{ width: 150 }}>Longitude</StyledTableCell>
                <StyledTableCell>Elevation</StyledTableCell>
                <StyledTableCell>MARKING /LGT</StyledTableCell>
                <StyledTableCell sx={{ width: 250 }}>REMARKS</StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  IDENTIFIER
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Horizontal Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  Vertical Datum
                </StyledTableCell>
                <StyledTableCell sx={{ width: 150 }}>
                  UOM Elevation
                </StyledTableCell>
                <StyledTableCell sx={{ width: 200 }}>
                  RWY Direction
                </StyledTableCell>
                {/* <StyledTableCell sx={{ width: 200 }}>Airport</StyledTableCell> */}
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AAIAirport &&
                sortBy(AAIAirport, ["uuid"]).map((data, index) => {
                  const elv = data.elevation
                    ? data.elevation.split(" ")[0]
                    : "-";
                  const elvUnit = data.elevation
                    ? data.elevation.split(" ")[1]
                    : "-";

                  return (
                    <TableRow key={index}>
                      <StyledTableCellV4>
                        {data.rwy_designator ? data.rwy_designator : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.obstacle_type ? data.obstacle_type : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {/* {utmLat ? `${utmLat}N` : "-"} */}
                        {data?.lat ? data.lat : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {/* {utmLng ? `${utmLng}E` : "-"} */}
                        {data?.lng ? data.lng : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>{elv}</StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.marking ? data.marking : "-"} /{" "}
                        {data.lighted ? data.lighted : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.remarks ? data.remarks : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.uuid ? data.uuid : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.horizontalDatum ? data.horizontalDatum : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.verticalDatum ? data.verticalDatum : "-"}
                      </StyledTableCellV4>
                      <StyledTableCellV4>{elvUnit}</StyledTableCellV4>
                      <StyledTableCellV4>
                        {data.runwaydirection_id
                          ? data.runwaydirection_id
                          : "-"}
                      </StyledTableCellV4>
                      {/* <StyledTableCellV4>
                          {selectedAirport.designator}
                        </StyledTableCellV4> */}
                      <StyledTableCellV4>
                        <Box sx={{ display: "flex" }}>
                          <LightTooltip title="Edit">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenEditModal(true);
                                setObstacleId(data?.id);
                                handleEditRow(data);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Delete">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setOpenDeleteConfirmDialog(true);
                                setObstacleId(data?.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </LightTooltip>
                        </Box>
                      </StyledTableCellV4>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {obstacleData && obstacleData.length > 0 && isEmpty(AAIAirport) && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ pr: 2, pt: 2 }}
        >
          <PrimaryButton
            label="Submit"
            isLoading={
              isUplodeObstacleFileRequest || isLoadingAddAAIAirportDetail
            }
            disabled={
              isUplodeObstacleFileRequest || isLoadingAddAAIAirportDetail
            }
            onClick={() => handleSubmitObstacleFile()}
            sx={{ width: 200 }}
          />
        </Stack>
      )}
      <Snackbar
        open={isOpenCrcPopup}
        autoHideDuration={6000}
        message={`CRC32Q code is ${CRCcode}`}
        onClose={() => setOpenCrcPopup(false)}
        action={
          <CopyToClipboard text={CRCcode}>
            <Button color="inherit" size="small">
              Copy
            </Button>
          </CopyToClipboard>
        }
      />
      {isOpenEditModal && (
        <Modal
          open={isOpenEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.ad210EditForm}>
            <Typography sx={{ borderBottom: "0.5px solid #c7cccc" }}>
              Edit Aerodrome obstacles
            </Typography>
            <Box sx={{ height: "500px", overflow: "auto" }}>
              <form>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Obstacle Type</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="obstacle_type"
                      name="obstacle_type"
                      value={createAd210Form.values.obstacle_type}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.obstacle_type &&
                        Boolean(createAd210Form.errors.obstacle_type)
                      }
                      helperText={
                        createAd210Form.touched.obstacle_type &&
                        createAd210Form.errors.obstacle_type
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Runway Direction Id</Box>
                  <Box sx={styles.adInput}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ mb: 1, mt: 1 }}
                      error={
                        createAd210Form.touched.runwaydirection_id &&
                        Boolean(createAd210Form.errors.runwaydirection_id)
                      }
                    >
                      <MuiSelect
                        name="runwaydirection_id"
                        value={createAd210Form.values.runwaydirection_id}
                        onChange={createAd210Form.handleChange}
                      >
                        {adRwyDrctnIdsData.map((types) => (
                          <MenuItem key={types.id} value={types.value}>
                            {types.label}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                      <FormHelperText>
                        {createAd210Form.touched.runwaydirection_id &&
                          createAd210Form.errors.runwaydirection_id}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Elevation</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="elevation"
                      name="elevation"
                      type="number"
                      value={createAd210Form.values.elevation}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.elevation &&
                        Boolean(createAd210Form.errors.elevation)
                      }
                      helperText={
                        createAd210Form.touched.elevation &&
                        createAd210Form.errors.elevation
                      }
                      sx={{ mb: 1, mt: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">FT</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Horizontal Datum</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="horizontalDatum"
                      name="horizontalDatum"
                      value={createAd210Form?.values.horizontalDatum}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.horizontalDatum &&
                        Boolean(createAd210Form.errors.horizontalDatum)
                      }
                      helperText={
                        createAd210Form.touched.horizontalDatum &&
                        createAd210Form.errors.horizontalDatum
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Vertical Datum</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="verticalDatum"
                      name="verticalDatum"
                      value={createAd210Form?.values.verticalDatum}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.verticalDatum &&
                        Boolean(createAd210Form.errors.verticalDatum)
                      }
                      helperText={
                        createAd210Form.touched.verticalDatum &&
                        createAd210Form.errors.verticalDatum
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Lattitude</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="lat"
                      name="lat"
                      value={createAd210Form.values.lat}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.lat &&
                        Boolean(createAd210Form.errors.lat)
                      }
                      helperText={
                        createAd210Form.touched.lat &&
                        createAd210Form.errors.lat
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Longitude</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="lng"
                      name="lng"
                      value={createAd210Form.values.lng}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.lng &&
                        Boolean(createAd210Form.errors.lng)
                      }
                      helperText={
                        createAd210Form.touched.lng &&
                        createAd210Form.errors.lng
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Lighted</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="lighted"
                      name="lighted"
                      value={createAd210Form.values.lighted}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.lighted &&
                        Boolean(createAd210Form.errors.lighted)
                      }
                      helperText={
                        createAd210Form.touched.lighted &&
                        createAd210Form.errors.lighted
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Marking</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="marking"
                      name="marking"
                      value={createAd210Form.values.marking}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.marking &&
                        Boolean(createAd210Form.errors.marking)
                      }
                      helperText={
                        createAd210Form.touched.marking &&
                        createAd210Form.errors.marking
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Identifier</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="uuid"
                      name="uuid"
                      value={createAd210Form.values.uuid}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.uuid &&
                        Boolean(createAd210Form.errors.uuid)
                      }
                      helperText={
                        createAd210Form.touched.uuid &&
                        createAd210Form.errors.uuid
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ width: "30%" }}>Remarks</Box>
                  <Box sx={styles.adInput}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      id="remarks"
                      name="remarks"
                      value={createAd210Form.values.remarks}
                      onChange={createAd210Form.handleChange}
                      error={
                        createAd210Form.touched.remarks &&
                        Boolean(createAd210Form.errors.remarks)
                      }
                      helperText={
                        createAd210Form.touched.remarks &&
                        createAd210Form.errors.remarks
                      }
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Box>
                </Stack>
              </form>
            </Box>

            <Box sx={styles.ad210SubCanBtn}>
              <PrimaryButton
                label="Submit"
                onClick={() => createAd210Form.handleSubmit()}
                isLoading={isLoadingAddAAIAirportDetail}
                disabled={isLoadingAddAAIAirportDetail}
                sx={{ width: 100, mr: 2 }}
              />
              <PrimaryButton
                label="Cancel"
                variant="outlined"
                onClick={() => {
                  setOpenEditModal(false);
                  setObstacleId(null);
                }}
                sx={{ width: 100 }}
              />
            </Box>
          </Box>
        </Modal>
      )}
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this Obstacle data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Fragment>
  );
}
