import React, { useEffect, useRef, useState } from "react";
import { FeatureGroup, CircleMarker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { split } from "lodash";
import { Stack } from "@mui/material";
import { LightButton } from "@components/button";
import PopupBox from "@components/map/PopupBox";
import PopupItem from "@components/map/PopupItem";
import { roles } from "@constants/constant";
import { areaHighLightColor } from "@constants/colors";
import { styles } from "@styles/amdbStyles";
import { convertToDms } from "@utils/geocoords";

export default function RenderParkingBayPoints({
  selectedFeatures,
  parkingBayPointsList,
  handleEditShap,
  handleDeleteShape,
}) {
  const popupElRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const featureKey = "PARKING_BAY_POINTS_List";
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [areaColor, setAreaColor] = useState(null);
  const [isVisibleParkingBayPoints, setVisibleParkingBayPoints] =
    useState(false);
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const isVisible = selectedFeatures.includes(
      `${featureKey}__${areaColorT[featureKey]}`
    );

    setAreaColor(areaColorT);
    setVisibleParkingBayPoints(isVisible);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  if (!isVisibleParkingBayPoints || !areaColor) {
    return null;
  }

  const handleBaypointEdit = (data) => {
    handleEditShap(data, "baypoint");
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
    
  };

  const handleBaypointDelete = (data) => {
    handleDeleteShape(data, "baypoint");
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  return (
    <FeatureGroup pane="popupPane">
      {parkingBayPointsList.map((pbplGeometry, i) => {
        const position = {
          lat: Number(pbplGeometry.latitude),
          lng: Number(pbplGeometry.longitude),
        };

        return (
          <CircleMarker
            key={i}
            center={position}
            radius={6}
            pathOptions={{
              fillColor: areaColor[featureKey],
              color:
                selectedOverE?.point_name === pbplGeometry.point_name
                  ? areaHighLightColor
                  : areaColor[featureKey],
              weight:
                selectedOverE?.point_name === pbplGeometry.point_name ? 5 : 2,
              fillOpacity: 1,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, pbplGeometry),
              mouseout: () => handlePolygonOut(),
            }}
          >
            <Popup ref={popupElRef} className="polygon-popup">
              <PopupBox>
                <PopupItem>Name:</PopupItem>
                <PopupItem>{pbplGeometry?.point_name}</PopupItem>
                <PopupItem>Latitude:</PopupItem>
                <PopupItem>{convertToDms(pbplGeometry?.latitude)}</PopupItem>
                <PopupItem>Longitude:</PopupItem>
                <PopupItem>
                  {convertToDms(pbplGeometry?.longitude, true)}
                </PopupItem>
                <PopupItem>Elevation:</PopupItem>
                <PopupItem>{pbplGeometry?.top_elev}</PopupItem>
              </PopupBox>
              {isAdmin && (
                <Stack
                  sx={{ width: 200 }}
                  direction="row"
                  flexDirection="column"
                >
                  <LightButton
                    label="Modify Point"
                    onClick={() => handleBaypointEdit(pbplGeometry)}
                    sx={styles.modifyShapeBtn}
                  />
                  <LightButton
                    label="Delete Point"
                    onClick={() => handleBaypointDelete(pbplGeometry)}
                    sx={{ p: 0, mr: 7 }}
                  />
                </Stack>
              )}
            </Popup>
          </CircleMarker>
        );
      })}
    </FeatureGroup>
  );
}
