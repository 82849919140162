/*
 * Helper methods to access local cookie storage.
 */
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN, REFRESH_TOKEN, AES_KEY } from "@constants/constant";
import { REFRESH_BOTH_TOKEN } from "@constants/urls";
import { refreshAccessToken } from "./connect";
import { toast } from "react-toastify";

const cookies = new Cookies();

export const getAsyncStorageValue = async (storageKey) => {
  if (storageKey === ACCESS_TOKEN) {
    const currentTime = Math.round(new Date().getTime() / 1000);
    const aesKey = getStorageValue(AES_KEY);
    const refreshToken = cookies.get(REFRESH_TOKEN);
    const accessToken = cookies.get(ACCESS_TOKEN);
    const decodeRefreshToken = jwt_decode(refreshToken);
    const decodeAccessToken = jwt_decode(accessToken);

    if (decodeRefreshToken.exp <= currentTime) {
      await removeAllCookies();
      window.location.reload();
      return null;
    }

    if (decodeAccessToken.exp <= currentTime) {
      return refreshAccessToken("POST", REFRESH_BOTH_TOKEN, refreshToken)
        .then(async (response) => {
          if (response.status === false) {
            await removeAllCookies();
            window.location.reload();
            return null;
          }

          await setAsyncStorageValue(ACCESS_TOKEN, response.data.accessToken);
          await setAsyncStorageValue(REFRESH_TOKEN, response.data.refreshToken);
          await setAsyncStorageValue(AES_KEY, aesKey);

          return response.data.accessToken;
        })
        .catch(() => {
          toast.error("Access token expired");
          return null;
        });
    }

    return cookies.get(storageKey);
  }

  return cookies.get(storageKey);
};

export const getStorageValue = (storageKey) => {
  return cookies.get(storageKey);
};

export const setAsyncStorageValue = async (key, value) => {
  document.cookie = `${key}=; Path=/; Expires=${new Date().toUTCString()};`;
  await cookies.set(key, value);

  return value;
};

export const setStorageValue = (key, value) => {
  cookies.set(key, value);
};

export const removeAllCookies = async () => {
  document.cookie = `${ACCESS_TOKEN}=; Path=/; Expires=${new Date().toUTCString()};`;
  document.cookie = `${REFRESH_TOKEN}=; Path=/; Expires=${new Date().toUTCString()};`;
  document.cookie = `${AES_KEY}=; Path=/; Expires=${new Date().toUTCString()};`;

  await cookies.remove(ACCESS_TOKEN);
  await cookies.remove(REFRESH_TOKEN);
  await cookies.remove(AES_KEY);

  localStorage.clear();

  return null;
};
