import React, { Fragment } from "react";
import {
  Box,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import DiffTableCell from "@components/tabel-component/DiffTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import ValueItem from "@components/tabel-component/ValueItem";
import { dataChangedRowColorMap } from "@constants/colors";
import { theme } from "@styles/theme";
import TopTableHeaderRow from "./TopTableHeaderRow";
import TableSheetHeaderRow from "./TableSheetHeaderRow";
import TableFooterRow from "./TableFooterRow";

export default function ObstacleCompareTable({
  rows,
  selctedAirport,
  selctedCategory,
}) {
  return (
    <Fragment>
      <TopTableHeaderRow />
      <TableSheetHeaderRow
        selctedAirport={selctedAirport}
        selctedCategory={selctedCategory}
      />
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                UID
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "2vw" }}>
                <Box>LATITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>NEW</StyledBoxV2>
                  <StyledBoxV2>OLD</StyledBoxV2>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "2vw" }}>
                <Box>LONGITUDE</Box>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  <StyledBoxV2>NEW</StyledBoxV2>
                  <StyledBoxV2>OLD</StyledBoxV2>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Affected Area</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Point Name</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>TOP ELEV. IN m. (EGM 08)</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                    <StyledBoxV2>DIFF</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>UOM Elevation</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Vertical Datum</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Horizontal Datum</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Object</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "1vw" }}>
                <Stack>
                  <Box>Marking lgt</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  background:
                    dataChangedRowColorMap[row.result] ??
                    theme.palette.white.main,
                }}
              >
                <DiffTableCell>{row["uid1"]}</DiffTableCell>
                <DiffTableCell isdiff={row["latdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["latitude1"]} />
                    <ValueItem value={row["latitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell isdiff={row["longdev"]}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["longitude1"]} />
                    <ValueItem value={row["longitude2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["affected_areadev"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["affected_area1"]} />
                    <ValueItem value={row["affected_area2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["point_name1"]} />
                    <ValueItem value={row["point_name2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell
                  align="center"
                  isdiff={Boolean(row["top_elevdiff"])}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["top_elev1"]} />
                    <ValueItem value={row["top_elev2"]} />
                    <ValueItem value={row["top_elevdiff"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["uom_elevation1"]} />
                    <ValueItem value={row["uom_elevation2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["vertical_datum1"]} />
                    <ValueItem value={row["vertical_datum2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["horizontal_datum1"]} />
                    <ValueItem value={row["horizontal_datum2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["object1"]} />
                    <ValueItem value={row["object2"]} />
                  </Stack>
                </DiffTableCell>
                <DiffTableCell align="center">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <ValueItem value={row["marking_lgt1"]} />
                    <ValueItem value={row["marking_lgt1"]} />
                  </Stack>
                </DiffTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterRow
              colspan={7}
              selctedAirport={selctedAirport}
              selctedCategory={selctedCategory}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
