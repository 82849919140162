import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { EditIcon } from "@assets/svg/edit";
import { EyeIcon } from "@assets/svg/eye";
import { SubmitIcon } from "@assets/svg/SubmitIcon";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import { statusColorMap } from "@constants/surveyorData";
import { AirportStatus, ad2ExportCategoryTypes } from "@constants/ad2Data";
import { LightTooltip } from "@components/core/LightTooltip";
import {
  getAllNewAirports,
  changeStatusAirport,
  exportXMLAD2Data,
  exportAllXMLAD2Data,
  getAdRunwayInfo,
} from "@store/airport/actions";
import { setSelectedAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import ConfirmationDialog from "./core/ConfirmationDialog";
import StyledTableCell from "./tabel-component/StyledTableCell";
import TableRowLoader from "./tabel-component/TableRowLoader";
import AirportExportButton from "./AirportExportButton";

export default function AirportReviewListTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoadingAllAirports,
    isLoadingAirportStatusChange,
    isExportXMLAD2Loading,
    allAirports,
  } = useSelector((state) => state.airport);
  const user = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const [isConfirmToFinish, setConfirmToFinish] = useState(false);
  const [isDownloadAll, setDownloadAll] = useState(false);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const isAdmin = user?.role === roles.admin;
  const isAAIUser = user?.role === roles.aai;

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllNewAirports(token));
    });
  }, []); // eslint-disable-line

  const handleActionEdit = (airport) => {
    if (!isAAIUser || airport.status === AirportStatus.closed) return;

    dispatch(setSelectedAAIAirport(airport));
    if (airport.status === AirportStatus.notStarted) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          changeStatusAirport(
            {
              airport_id: airport.id,
              status: AirportStatus.inProcess,
            },
            token,
            handleCloseConfirmDialog
          )
        );
      });
    }
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAdRunwayInfo(airport?.id, airport?.version_id));
    });
    navigate(`add-airport-data?airportId=${airport.id}`);
  };

  const handleActionReview = (airport) => {
    if (!isAdmin) return;

    navigate(`/review-airport-data/${airport.id}`);
  };

  const handleActionFinished = (airport) => {
    setSelectedAirport(airport);
    setConfirmToFinish(true);
  };

  const handleSaveConfirmDialog = () => {
    if (selectedAirport.status === AirportStatus.inProcess) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          changeStatusAirport(
            {
              airport_id: selectedAirport.id,
              status: AirportStatus.closed,
            },
            token,
            handleCloseConfirmDialog
          )
        );
      });
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmToFinish(false);
  };

  const handleExportXML = (type, airport) => {
    setSelectedAirport(airport);
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (user?.user_access?.export) {
        dispatch(exportXMLAD2Data(type, airport, token));
      }
    });
  };

  const handleExportAllXML = async (types, airport) => {
    setDownloadAll(true);
    setSelectedAirport(airport);
    getAsyncStorageValue(ACCESS_TOKEN).then(async (token) => {
      if (user?.user_access?.export || accessToken) {
        await exportAllXMLAD2Data(types, airport, token);
      }
    });
    setDownloadAll(false);
  };

  return (
    <Fragment>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{ backgroundColor: theme.palette.tertiary.main }}
              >
                AIRPORT NAME
              </StyledTableCell>
              <StyledTableCell align="center">AIRPORT CODE</StyledTableCell>
              <StyledTableCell>EFFECTIVE DATE</StyledTableCell>
              <StyledTableCell>PUBLISHED DATE</StyledTableCell>
              <StyledTableCell align="center">STATUS</StyledTableCell>
              <StyledTableCell align="center">
                {isAdmin ? "REVIEW DATA" : "ENTER DATA"}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRowLoader
              isLoading={isLoadingAllAirports}
              data={allAirports}
              colSpan={6}
            />
            {allAirports?.map((airport) => {
              return (
                <TableRow key={airport.id} onClick={() => {}} tabIndex={-1}>
                  <StyledTableCell>{airport.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {airport.designator}
                  </StyledTableCell>
                  <StyledTableCell>{airport.effective_date}</StyledTableCell>
                  <StyledTableCell>{airport.published_date}</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      component="div"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <p
                        style={{
                          ...styles.reviewListTableStatus,
                          backgroundColor: statusColorMap[airport.status],
                        }}
                      >
                        {airport.status}
                      </p>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {isAAIUser && (
                      <LightTooltip title="Edit">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionEdit(airport)}
                        >
                          <EditIcon
                            disabled={airport.status === AirportStatus.closed}
                          />
                        </IconButton>
                      </LightTooltip>
                    )}
                    {isAAIUser && (
                      <LightTooltip title="Submit">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionFinished(airport)}
                        >
                          <SubmitIcon
                            disabled={airport.status === AirportStatus.closed}
                          />
                        </IconButton>
                      </LightTooltip>
                    )}
                    {isAdmin && (
                      <LightTooltip title="Review">
                        <IconButton
                          color="primary"
                          disabled={airport.status === AirportStatus.closed}
                          onClick={() => handleActionReview(airport)}
                        >
                          <EyeIcon
                            disabled={airport.status === AirportStatus.closed}
                          />
                        </IconButton>
                      </LightTooltip>
                    )}
                    <AirportExportButton
                      items={ad2ExportCategoryTypes}
                      selectedAirport={selectedAirport}
                      airport={airport}
                      handleExportXML={(type) => handleExportXML(type, airport)}
                      handleExportAllXML={(types) =>
                        handleExportAllXML(types, airport)
                      }
                      isExportXMLAD2Loading={
                        isExportXMLAD2Loading || isDownloadAll
                      }
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        fullWidth
        open={isConfirmToFinish}
        title="Review Request"
        content={`Are you sure you want to finish airport "${selectedAirport?.name}"?`}
        saveButtonLabel="Finish"
        isLoading={isLoadingAirportStatusChange}
        isDisabled={isLoadingAirportStatusChange}
        handleSave={handleSaveConfirmDialog}
        handleCancel={handleCloseConfirmDialog}
      />
    </Fragment>
  );
}
