import React, { Fragment, useEffect, useState } from "react";
import { filter, isEmpty } from "lodash";
import {
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Table,
} from "@mui/material";
import StyledTypography from "@components/core/StyledTypography";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell,{ StyledTableCellV12 } from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

export default function NavaidItem({
  navaids,
  activeCategory,
  selctedAirport,
}) {
  const [ils, setILS] = useState([]);
  const [ilsdme, setILSDME] = useState([]);
  const [ndb, setNDB] = useState([]);
  const [mkr, setMKR] = useState([]);
  const [dme, setDME] = useState([]);
  const [vorDME, setVORDME] = useState([]);

  useEffect(() => {
    if (!isEmpty(navaids)) {
      const ils = filter(navaids, ["type", "ILS"]) || [];
      const ilsdme = filter(navaids, ["type", "ILS_DME"]) || [];
      const ndb = filter(navaids, ["type", "NDB"]) || [];
      const mkr = filter(navaids, ["type", "MKR"]) || [];
      const dme = filter(navaids, ["type", "DME"]) || [];
      const vorDME = filter(navaids, ["type", "VOR_DME"]) || [];
      setILS(ils);
      setILSDME(ilsdme);
      setNDB(ndb);
      setMKR(mkr);
      setDME(dme);
      setVORDME(vorDME);
    }
  }, [navaids]);
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={8}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                Type of aids, magnetic variation and type of supported operation
                for ILS/MLS, basic GNSS, SBAS and GBAS, and for VOR/ILS/MLS
                station used for technical lineup of the aid
              </StyledTableCell>
              <StyledTableCell>Identification</StyledTableCell>
              <StyledTableCell>
                Frequency(ies), Channel number(s), Service provider, and
                reference path identifier(s) (RPI)
              </StyledTableCell>
              <StyledTableCell>Hours of operation</StyledTableCell>
              <StyledTableCell>
                Geographical coordinates of the position of the transmitting
                antenna
              </StyledTableCell>
              <StyledTableCell>
                Elevation of transmitting antenna of DME/ elevation of GBAS
                reference point
              </StyledTableCell>
              <StyledTableCell>
                Service volume radius from the GBAS reference point
              </StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(ils) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {ils[0].type}
                  </StyledTableCell>
                </TableRow>
                {ils.map((data, index) => (
                  <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                    <StyledTableCell>{data.type || ""}</StyledTableCell>
                    <StyledTableCellV12>
                      {data.identification || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCell sx={{ width: 80 }}>
                      <Table>
                        <TableBody>
                          {data.details?.LOC?.channel && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                LOC:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.LOC.channel}
                              </StyledTableCell>
                            </TableRow>
                          )}
                          {data.details?.GP?.channel && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                GP:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.GP.channel}
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: 100 }}>
                      <Table>
                        <TableBody>
                          {data.details?.LOC?.hours_of_operation && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                LOC:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.LOC.hours_of_operation}
                              </StyledTableCell>
                            </TableRow>
                          )}
                          {data.details?.GP?.hours_of_operation && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                GP:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.GP.hours_of_operation}
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: 150 }}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell sx={styles.detailLoc}>
                              LOC:
                            </StyledTableCell>
                            <StyledTableCell sx={styles.detailLoc}>
                              <StyledTypography>
                                {data.details.LOC.latitude || "-"}
                              </StyledTypography>
                              <StyledTypography>
                                {data.details.LOC.longitude || "-"}
                              </StyledTypography>
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell sx={styles.detailLoc}>
                              GP:
                            </StyledTableCell>
                            <StyledTableCell sx={styles.detailLoc}>
                              <StyledTypography>
                                {data.details.GP.latitude || "-"}
                              </StyledTypography>
                              <StyledTypography>
                                {data.details.GP.longitude || "-"}
                              </StyledTypography>
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell sx={styles.detailLoc}>
                              DME:
                            </StyledTableCell>
                            <StyledTableCell sx={styles.detailLoc}>
                              <StyledTypography>
                                {data.details.DME.latitude || "-"}
                              </StyledTypography>
                              <StyledTypography>
                                {data.details.DME.longitude || "-"}
                              </StyledTypography>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {data.details?.LOC?.elevation && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                LOC:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.LOC.elevation}
                              </StyledTableCell>
                            </TableRow>
                          )}
                          {data.details?.GP?.elevation && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                GP:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.GP.elevation}
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTypography>{data.rwy_dir || ""}</StyledTypography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {data.details?.LOC?.remarks && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                LOC:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.LOC.remarks}
                              </StyledTableCell>
                            </TableRow>
                          )}
                          {data.details?.GP?.remarks && (
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                GP:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                {data.details.GP.remarks}
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </Fragment>
            )}
            {!isEmpty(ilsdme) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {ilsdme[0].type}
                  </StyledTableCell>
                </TableRow>
                {ilsdme.map((data, index) => {
                  return (
                    <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                      <StyledTableCell>{data.type || ""}</StyledTableCell>
                      <StyledTableCellV12>
                        {data.identification || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCell sx={{ width: 80 }}>
                        <Table>
                          <TableBody>
                            {data.details?.LOC?.channel && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  LOC:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.LOC.channel}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.GP?.channel && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  GP:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.GP.channel}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.DME?.channel && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  DME:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.DME.channel}
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: 100 }}>
                        <Table>
                          <TableBody>
                            {data.details?.LOC?.hours_of_operation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  LOC:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.LOC.hours_of_operation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.GP?.hours_of_operation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  GP:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.GP.hours_of_operation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.DME?.hours_of_operation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  DME:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.DME.hours_of_operation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                      <StyledTableCell sx={{ width: 150 }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                LOC:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                <StyledTypography>
                                  {data.details.LOC.latitude || "-"}
                                </StyledTypography>
                                <StyledTypography>
                                  {data.details.LOC.longitude || "-"}
                                </StyledTypography>
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                GP:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                <StyledTypography>
                                  {data.details.GP.latitude || "-"}
                                </StyledTypography>
                                <StyledTypography>
                                  {data.details.GP.longitude || "-"}
                                </StyledTypography>
                              </StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell sx={styles.detailLoc}>
                                DME:
                              </StyledTableCell>
                              <StyledTableCell sx={styles.detailLoc}>
                                <StyledTypography>
                                  {data.details.DME.latitude || "-"}
                                </StyledTypography>
                                <StyledTypography>
                                  {data.details.DME.longitude || "-"}
                                </StyledTypography>
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Table>
                          <TableBody>
                            {data.details?.LOC?.elevation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  LOC:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.LOC.elevation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.GP?.elevation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  GP:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.GP.elevation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.DME?.elevation && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  DME:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.DME.elevation}
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                      <StyledTableCell>
                        <StyledTypography>
                          {data.rwy_dir || ""}
                        </StyledTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Table>
                          <TableBody>
                            {data.details?.LOC?.remarks && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  LOC:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.LOC.remarks}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.GP?.remarks && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  GP:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.GP.remarks}
                                </StyledTableCell>
                              </TableRow>
                            )}
                            {data.details?.DME?.remarks && (
                              <TableRow>
                                <StyledTableCell sx={styles.detailLoc}>
                                  DME:
                                </StyledTableCell>
                                <StyledTableCell sx={styles.detailLoc}>
                                  {data.details.DME.remarks}
                                </StyledTableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            )}
            {!isEmpty(ndb) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {ndb[0].type}
                  </StyledTableCell>
                </TableRow>
                {ndb.map((data, index) => {
                  return (
                    <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                      <StyledTableCell>{data.class || "NIL"}</StyledTableCell>
                      <StyledTableCellV12>
                        {data.identification || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {data?.frequency || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {data?.hours_of_operation || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCell>
                        <StyledTypography>
                          {data.latitude || ""}
                        </StyledTypography>
                        <StyledTypography>
                          {data.longitude || ""}
                        </StyledTypography>
                      </StyledTableCell>
                      <StyledTableCellV12>
                        {data.elevation || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {data?.rwy_dir || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {data?.remarks || "NIL"}
                      </StyledTableCellV12>
                    </TableRow>
                  );
                })}
              </Fragment>
            )}
            {!isEmpty(mkr) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {mkr[0].type}
                  </StyledTableCell>
                </TableRow>
                {mkr.map((data, index) => (
                  <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                    <StyledTableCell>{data.type || "NIL"}</StyledTableCell>
                    <StyledTableCellV12>
                      {data.identification || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data.frequency || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.hours_of_operation || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCell>
                      <StyledTypography>{data.latitude || ""}</StyledTypography>
                      <StyledTypography>
                        {data.longitude || ""}
                      </StyledTypography>
                    </StyledTableCell>
                    <StyledTableCellV12>
                      {data.elevation || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.rwy_dir || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.remarks || "NIL"}
                    </StyledTableCellV12>
                  </TableRow>
                ))}
              </Fragment>
            )}
            {!isEmpty(dme) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {dme[0].type}
                  </StyledTableCell>
                </TableRow>
                {dme.map((data, index) => (
                  <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                    <StyledTableCell>{data.type || "NIL"}</StyledTableCell>
                    <StyledTableCellV12>
                      {data.identification || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data.channel || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.hours_of_operation || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCell>
                      <StyledTypography>{data.latitude || ""}</StyledTypography>
                      <StyledTypography>
                        {data.longitude || ""}
                      </StyledTypography>
                    </StyledTableCell>
                    <StyledTableCellV12>
                      {data.elevation || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.rwy_dir || "NIL"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {data?.remarks || "NIL"}
                    </StyledTableCellV12>
                  </TableRow>
                ))}
              </Fragment>
            )}
            {!isEmpty(vorDME) && (
              <Fragment>
                <TableRow>
                  <StyledTableCell sx={styles.ilsType} colSpan={8}>
                    Type {">"} {vorDME[0].type}
                  </StyledTableCell>
                </TableRow>
                {vorDME.map((data, index) => {
                  return (
                    <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                      <StyledTableCell>{data.type || "NIL"}</StyledTableCell>
                      <StyledTableCellV12>
                        {data.identification || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCell>
                        <StyledTypography>
                          {data.channel || ""}
                        </StyledTypography>
                        <StyledTypography>
                          {data.frequency || ""}
                        </StyledTypography>
                      </StyledTableCell>
                      <StyledTableCellV12>
                        {data?.hours_of_operation || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCell>
                        <StyledTypography>
                          {data.latitude || ""}
                        </StyledTypography>
                        <StyledTypography>
                          {data.longitude || ""}
                        </StyledTypography>
                      </StyledTableCell>
                      <StyledTableCellV12>
                        {data.elevation || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {data?.rwy_dir || "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCell sx={{ width: 150, fontSize: "12px" }}>
                        {data?.remarks || "NIL"}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            )}
            {isEmpty(ils) &&
              isEmpty(ilsdme) &&
              isEmpty(ndb) &&
              isEmpty(mkr) &&
              isEmpty(dme) &&
              isEmpty(vorDME) && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No data found.
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
