import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { dataChangedRowColorMap } from "@constants/colors";

const DiffStack = styled(Stack)(({ theme, isdiff = undefined }) => ({
  backgroundColor: isdiff
    ? dataChangedRowColorMap.deviation_detected
    : dataChangedRowColorMap.transparent,
}));

export default DiffStack;
