import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircleMarker, FeatureGroup, Polygon, Polyline } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { flatten } from "lodash";
import ConfirmationDialog2 from "@components/core/ConfirmationDialog2";
import { ACCESS_TOKEN } from "@constants/constant";
import { addEditDeleteAMDBCreateGeometry } from "@store/amdb/actions";
import { theme } from "@styles/theme";
import { getAsyncStorageValue } from "@utils/localStorage";
import AddAmdbShapModal from "./AddAmdbShapModal";
import EditAmdbShapModal from "./EditAmdbShapModal";

const polygonOptions = {
  allowIntersection: false,
  shapeOptions: {
    fillColor: theme.palette.lightgreen.main,
    color: theme.palette.lightgreen.main,
    fillOpacity: 0.3,
    weight: 2,
  },
  showArea: true,
};

const polylineOptions = {
  allowIntersection: false,
  shapeOptions: {
    color: theme.palette.lightgreen.main,
    fillColor: theme.palette.lightgreen.main,
    fillOpacity: 0.3,
    weight: 1.5,
  },
  showLength: true,
};

const circleMarkerOptions = {
  radius: 5,
  fillColor: theme.palette.lightgreen.main,
  color: theme.palette.lightgreen.main,
  opacity: 0.6,
};

export default function AMDBDrawControl({
  editLineIndex,
  deleteLineIndex,
  selctedAirport,
  editableLayer,
  editableMarkerLayer,
  editableLineLayer,
  editabletype,
  shapeType,
  deleteLayer,
  deleteType,
  handleCallback,
  handleonMountedControl,
  isPolygonMarkingMode,
  isPointMarkingMode,
  isPolylineMarkingMode,
  isOpenDeleteConfirmDialog,
  handleDeleteCallback,
}) {
  const dispatch = useDispatch();
  const featureGroupRef = useRef();
  const isLoadingAddEditDeletePolygon = useSelector(
    (state) => state.amdb.isLoadingAddEditDeletePolygon
  );
  const [isOpenAddShapDialog, setOpenAddShapDialog] = useState(false);
  const [isOpenEditShapDialog, setOpenEditShapDialog] = useState(false);
  const [newCoords, setNewCoords] = useState([]);
  const [editedCoords, setEditedCoords] = useState([]);

  const coordinates = editableLayer
    ? flatten(editableLayer.coordinates.coordinates)
    : [];
  const coordinateMap = coordinates.map((coords) => {
    return coords.map((coord) => {
      return {
        lng: coord[0],
        lat: coord[1],
      };
    });
  });

  const markerPosition = editableMarkerLayer
    ? {
        lat: Number(editableMarkerLayer.latitude),
        lng: Number(editableMarkerLayer.longitude),
      }
    : null;

  const polylineCoords =
    editableLineLayer &&
    editableLineLayer?.coordinates?.coordinates[editLineIndex].map(
      (linedata) => {
        return [linedata[1], linedata[0]];
      }
    );

  const onCreated = (e) => {
    const geoJOSN = e.layer.toGeoJSON();
    if (e.layerType === "circlemarker") {
      setNewCoords(geoJOSN.geometry.coordinates);
      setOpenAddShapDialog(true);
    } else if (e.layerType === "polyline") {
      setNewCoords(geoJOSN.geometry.coordinates);
      setOpenAddShapDialog(true);
    } else if (e.layerType === "polygon") {
      setNewCoords(geoJOSN.geometry.coordinates[0]);
      setOpenAddShapDialog(true);
    }
  };

  const handleCloseAddShapDialog = () => {
    featureGroupRef.current.clearLayers();
    setOpenAddShapDialog(false);
    setOpenEditShapDialog(false);
    handleCallback();
  };

  const onEdited = (e) => {
    let coords = [];
    e.layers.eachLayer(function (layer) {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    setEditedCoords(coords);
    setOpenEditShapDialog(true);
  };

  const handleSaveDeleteDialog = () => {
    let payload;
    let feature;
    let method;
    if (deleteType === "APN_ELE") {
      method = "DELETE";
      feature = "apron";
      payload = {
        polyId: deleteLayer?.id,
      };
    }
    if (deleteType === "RWY_ELE") {
      method = "DELETE";
      feature = "runway";
      payload = {
        polyId: deleteLayer?.id,
      };
    }
    if (deleteType === "TWY_ELE") {
      method = "DELETE";
      feature = "taxiway";
      payload = {
        polyId: deleteLayer?.id,
      };
    }
    if (deleteType === "baypoint") {
      method = "DELETE";
      feature = "baypoint";
      payload = {
        polyId: deleteLayer?.id,
      };
    }
    if (deleteType === "bayline") {
      const oldCoords = deleteLayer?.coordinates?.coordinates;
      const deletedCoords = [...oldCoords];
      deletedCoords.splice(deleteLineIndex, 1);
      method = "PUT";
      feature = "bayline";
      payload = {
        data: {
          geometry: deletedCoords,
        },
        name: "",
        details: {},
        polyId: deleteLayer?.id,
      };
    }

    if (!payload) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        addEditDeleteAMDBCreateGeometry(
          method,
          payload,
          selctedAirport?.id,
          feature,
          token,
          handleCloseDeleteDialog
        )
      );
    });
  };

  const handleCloseDeleteDialog = () => {
    featureGroupRef.current.clearLayers();
    handleCallback();
    handleDeleteCallback();
  };

  return (
    <Fragment>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onCreated={onCreated}
          onEdited={onEdited}
          onMounted={(e) => handleonMountedControl(e)}
          draw={{
            rectangle: false,
            polyline:
              (shapeType === "polyline" || shapeType === "multipolyline") &&
              isPolylineMarkingMode
                ? polylineOptions
                : false,
            circle: false,
            circlemarker:
              shapeType === "circlemarker" && isPointMarkingMode
                ? circleMarkerOptions
                : false,
            marker: false,
            polygon:
              shapeType === "polygon" && isPolygonMarkingMode
                ? polygonOptions
                : false,
          }}
          edit={{
            edit:
              isPolygonMarkingMode ||
              isPointMarkingMode ||
              isPolylineMarkingMode
                ? true
                : false,
            remove: false,
          }}
        />
        {coordinateMap.map((apnPolygonPath) => {
          return (
            <Polygon
              key={`${editabletype}_${editableLayer?.id}`}
              positions={apnPolygonPath}
              pathOptions={{
                fillColor: theme.palette.lightgreen.main,
                color: theme.palette.lightgreen.main,
                weight: 2,
              }}
            />
          );
        })}
        {markerPosition && (
          <CircleMarker
            center={markerPosition}
            pathOptions={{
              fillColor: theme.palette.lightgreen.main,
              color: theme.palette.lightgreen.main,
              radius: 5,
            }}
          />
        )}
        {polylineCoords && polylineCoords.length > 0 && (
          <Polyline
            pane="mapPane"
            positions={polylineCoords}
            pathOptions={polylineOptions}
          />
        )}
      </FeatureGroup>
      {isOpenAddShapDialog && (
        <AddAmdbShapModal
          isOpenAddShapDialog={isOpenAddShapDialog}
          selctedAirport={selctedAirport}
          coords={newCoords}
          layerType={shapeType}
          handleClose={handleCloseAddShapDialog}
        />
      )}
      {isOpenEditShapDialog && (
        <EditAmdbShapModal
          isOpen={isOpenEditShapDialog}
          selctedAirport={selctedAirport}
          coords={editedCoords}
          editLineIndex={editLineIndex}
          layerType={shapeType}
          editabletype={editabletype}
          editableMarkerLayer={editableMarkerLayer}
          editableLineLayer={editableLineLayer}
          editableLayer={editableLayer}
          handleClose={handleCloseAddShapDialog}
        />
      )}
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog2
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete Shape"
          content="Are you sure you want to delete the shape?"
          saveButtonLabel="Yes"
          cancelText="No"
          isLoading={isLoadingAddEditDeletePolygon}
          isDisabled={isLoadingAddEditDeletePolygon}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Fragment>
  );
}
