import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import generator from "generate-password";
import { FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { ACCESS_TOKEN, AES_KEY } from "@constants/constant";
import { masterResetPasswordValidationSchema } from "@constants/surveyorData";
import { OutlinedButton, PrimaryButton, LightButton } from "@components/button";
import Header from "@components/Header";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { selectAllReqPasswordList } from "@store/survey";
import {
  getAllRequestPassword,
  masterResetPassword,
} from "@store/survey/actions";
import { styles } from "@styles/dataManagementStyles";
import { decryptStatic, encryptStatic } from "@utils/decodeEncodeData";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";

export default function NewPasswordRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingGetallReqPassword, isResetingMasterPassword } = useSelector(
    (state) => state.survey
  );
  const allPasswordRequestList = useSelector((state) =>
    selectAllReqPasswordList(state)
  );
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const aesKey = getStorageValue(AES_KEY);
  const passwordResetForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
    },
    validationSchema: masterResetPasswordValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          masterResetPassword(
            {
              user_id: values.user_id,
              new_password: encryptStatic(values.new_password, aesKey),
            },
            token,
            handleCloseResetPasswordDialog
          )
        );
      });
    },
  });

  useEffect(() => {
    dispatch(getAllRequestPassword());
  }, []); // eslint-disable-line

  const handleResetPassword = (payload) => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    passwordResetForm.setValues({
      user_id: payload.userid,
      new_password: password,
    });
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setOpenResetPasswordDialog(false);
    passwordResetForm.resetForm();
    passwordResetForm.setSubmitting(false);
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div className="content-area">
          <div
            className="surveyor-category-table white-bg"
            style={{ padding: 24, paddingTop: 0 }}
          >
            <Box sx={styles.reviewDatasetBar}>
              <Box sx={styles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "-8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  variant="span"
                  color="primary"
                  sx={{ fontWeight: 600, fontSize: 18, pl: 1 }}
                >
                  Reset Passwords
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                ...styles.arpReviewCategoryList,
                margin: 0,
                height: "calc(100% - 73px)",
              }}
            >
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Surveyor Name</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell align="center">ACTION</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingGetallReqPassword && (
                      <TableRow>
                        <StyledTableCell align="center" colSpan={5}>
                          Loading...
                        </StyledTableCell>
                      </TableRow>
                    )}
                    {!isLoadingGetallReqPassword &&
                      allPasswordRequestList.length === 0 && (
                        <TableRow>
                          <StyledTableCell align="center" colSpan={5}>
                            No record found.
                          </StyledTableCell>
                        </TableRow>
                      )}
                    {allPasswordRequestList.map((request) => {
                      return (
                        <TableRow key={request.emp_id}>
                          <StyledTableCell>{request.emp_id}</StyledTableCell>
                          <StyledTableCell>
                            {decryptStatic(request.name, aesKey)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {decryptStatic(request.email, aesKey) || "-"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <LightButton
                              label="RESET PASSWORD"
                              onClick={() => handleResetPassword(request)}
                              disabled={isResetingMasterPassword}
                              isLoading={isResetingMasterPassword}
                            />
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Dialog fullWidth open={openResetPasswordDialog}>
              <DialogTitle>Reset Password</DialogTitle>
              <DialogContent>
                <form>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="new_password"
                    name="new_password"
                    label="New Password"
                    value={passwordResetForm.values.new_password}
                    onChange={passwordResetForm.handleChange}
                    error={
                      passwordResetForm.touched.new_password &&
                      Boolean(passwordResetForm.errors.new_password)
                    }
                    helperText={
                      passwordResetForm.touched.new_password &&
                      passwordResetForm.errors.new_password
                    }
                    sx={{ mb: 2, mt: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard
                            text={passwordResetForm.values.new_password}
                            onCopy={() => setCopied(true)}
                          >
                            <IconButton
                              edge="end"
                              color={isCopied ? "primary" : "default"}
                            >
                              <FaCopy />
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <OutlinedButton
                  label="Cancel"
                  color="secondary"
                  onClick={handleCloseResetPasswordDialog}
                  sx={{ width: 120, p: "2.75px 15px" }}
                />
                <PrimaryButton
                  label="Reset Password"
                  sx={{ p: "3.75px 15px" }}
                  onClick={() => passwordResetForm.handleSubmit()}
                  disabled={isResetingMasterPassword}
                  isLoading={isResetingMasterPassword}
                />
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
