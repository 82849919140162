import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  CircleMarker,
  MapContainer,
  ScaleControl,
  ZoomControl,
} from "react-leaflet";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { keys } from "lodash";
import { defaultLocationCenter } from "@constants/constant";
import Header from "@components/Header";
import EtodAirportDetailRightSideBar from "@components/EtodAirportDetailRightSideBar";
import EtodAirportDetailSideBar from "@components/EtodAirportDetailSideBar";
import ControlMapCenter from "@components/map/ControlMapCenter";
import MapLayers from "@components/map/MapLayers";
import { selectEtodAirportDetail } from "@store/etod";

export default function EtodAirportDetail() {
  const airportDetailId = useParams();
  const etodAirportDetail = useSelector((state) =>
    selectEtodAirportDetail(state, airportDetailId.airportId)
  );
  const airportRwys = keys(etodAirportDetail.rwys);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [selectedRWY, setSelectedRWY] = useState(airportRwys[0]);

  useEffect(() => {
    setZoomLevel(14);
    setAirportLocation([etodAirportDetail.arp[1], etodAirportDetail.arp[0]]);
  }, [etodAirportDetail]);

  const handleSelectRWY = (rwy) => {
    setSelectedRWY(rwy);
  };

  const mapElement = useMemo(() => {
    const rwyDetail = etodAirportDetail.rwys[selectedRWY];
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        {selectedRWY &&
          rwyDetail &&
          rwyDetail.map((detail, j) => {
            const end = detail.end;
            const thr = detail.thr;
            let rwyColors = ["#FF30A0", "#FF782D"];
            if (j === 1) {
              rwyColors = ["#FF782D", "#FF30A0"];
            }

            return (
              <Fragment key={`${selectedRWY}-${j}`}>
                <CircleMarker
                  pane="tooltipPane"
                  center={[Number(thr[1]), Number(thr[0])]}
                  pathOptions={{
                    fillColor: rwyColors[0],
                    color: rwyColors[0],
                    weight: 1,
                    fillOpacity: 1,
                  }}
                  radius={4}
                />
                <CircleMarker
                  pane="tooltipPane"
                  center={[Number(end[1]), Number(end[0])]}
                  pathOptions={{
                    fillColor: rwyColors[1],
                    color: rwyColors[1],
                    weight: 1,
                    fillOpacity: 1,
                  }}
                  radius={4}
                />
              </Fragment>
            );
          })}
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <ZoomControl position="bottomright" />
        <MapLayers />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );
  }, [airportLocation, etodAirportDetail, selectedRWY]); // eslint-disable-line

  return (
    <div className="wrapper">
      <Header active="etod" />
      <div className="main-content">
        <EtodAirportDetailSideBar airportId={airportDetailId.airportId} />
        <div className="main">
          <div className="content-area ">
            {mapElement}
            <EtodAirportDetailRightSideBar
              airportDetail={etodAirportDetail}
              handleSelectRWY={handleSelectRWY}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
