import React from "react";
import { Box, Typography } from "@mui/material";

export default function AirportRWYCard({ data, className, ...props }) {
  return (
    <Box className={`airport-rwy-card ${className}`} {...props}>
      <Typography variant="span" className="surface-data-title">
        {data.title}
      </Typography>
      <Typography variant="span" className="surface-data-value">
        {data.value}
      </Typography>
    </Box>
  );
}
