import React from "react";
import { snakeCase, toUpper } from "lodash";
import { Box, Stack, Typography } from "@mui/material";
import { PrimaryButton } from "@components/button";
import { dataChangedRowColorMap } from "@constants/colors";
import { styles } from "@styles/dataManagementStyles";

export default function DataTableFooterRow({
  isLoadingVerification,
  selectedReport,
  selected,
  handleAccept,
  handleReview,
}) {
  const fileName = snakeCase(
    `SURVEYOR ${selectedReport?.feature_name} ${selectedReport?.airport_name}`
  );

  return (
    <Stack sx={styles.reviewAcceptRow}>
      <Box>
        <Box>{toUpper(fileName)}</Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.new_obstacle,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">New entry</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 12,
                height: 12,
                mr: 1,
                backgroundColor: dataChangedRowColorMap.deviation_detected,
                borderRadius: 50,
              }}
            />
            <Typography variant="body2">
              Spatial/Numerical Deviation detected
            </Typography>
          </Box>
        </Box>
      </Box>
      <Stack direction="row" spacing={5}>
        <PrimaryButton
          size="small"
          label="Accept"
          onClick={handleAccept}
          isLoading={isLoadingVerification}
          disabled={selected.length === 0 || isLoadingVerification}
        />
        <PrimaryButton
          label="Review"
          size="small"
          onClick={handleReview}
          isLoading={isLoadingVerification}
          disabled={selected.length === 0 || isLoadingVerification}
        />
      </Stack>
    </Stack>
  );
}
