import React from "react";
import { theme } from "@styles/theme";

export const LeftArrowIcon = ({ disabled, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4998 7.00073C13.7558 7.00073 14.0118 7.09873 14.2068 7.29373C14.5978 7.68473 14.5978 8.31673 14.2068 8.70773L10.9018 12.0127L14.0818 15.3057C14.4648 15.7037 14.4538 16.3367 14.0568 16.7197C13.6587 17.1027 13.0258 17.0917 12.6428 16.6957L8.78075 12.6957C8.40175 12.3027 8.40675 11.6797 8.79275 11.2937L12.7928 7.29373C12.9878 7.09873 13.2438 7.00073 13.4998 7.00073Z"
      fill={disabled ? "#D9D9D9" : theme.palette.grey.dark}
    />
  </svg>
);
