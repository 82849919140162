import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { UploadButton, PrimaryButton } from "@components/button";
import Header from "@components/Header";
import { generateCRC32Q } from "@store/userSession/actions";
import { styles } from "@styles/dataManagementStyles";

export default function CRCCheck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uploadRef = useRef();
  const isCRC32QGenerateRequesting = useSelector(
    (state) => state.userSession.isCRC32QGenerateRequesting
  );
  const [generatedCrcCode, setGeneratedCrcCode] = useState(null);
  const [file, setFile] = useState(null);
  const [result, setCheckResult] = useState(null);
  const [values, setValues] = React.useState({
    crccode: "",
  });

  const handleFileUpload = (event) => {
    event.preventDefault();

    const files = event.target.files;
    setFile(files[0]);
    dispatch(
      generateCRC32Q(files[0], (crcCode) => {
        setGeneratedCrcCode(crcCode);
      })
    );
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckCRC = () => {
    setCheckResult({
      isValid: generatedCrcCode.toString() === values.crccode.toString(),
      fileName: file.name,
      servercode: generatedCrcCode,
    });
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      {/* <div className="main-content"> */}
      {/* <div className="sidebar">
          <Button
            variant="text"
            size="small"
            startIcon={<FaArrowLeft />}
            onClick={() => navigate(-1)}
          >
            back
          </Button>
          <Divider sx={{ mb: 2 }} />
        </div> */}
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div className="crc-content">
          <div style={{ width: "100%" }}>
            <Box sx={styles.reviewDatasetBar}>
              <Box sx={styles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  variant="span"
                  color="primary"
                  sx={{ fontWeight: 600, fontSize: 18, pl: 1 }}
                >
                  Check File CRC32Q Value
                </Typography>
              </Box>
            </Box>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <UploadButton
              ref={uploadRef}
              label="Upload File"
              handleUpload={handleFileUpload}
              isLoading={isCRC32QGenerateRequesting}
              sx={{ width: "100%" }}
            />
            {file && <Typography>{file.name}</Typography>}
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
              <InputLabel>CRC32Q CODE</InputLabel>
              <OutlinedInput
                required
                value={values.crccode}
                onChange={handleChange("crccode")}
                label="crccode"
              />
            </FormControl>
            <Stack
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <PrimaryButton
                label="Check"
                disabled={!values.crccode || isCRC32QGenerateRequesting}
                onClick={() => handleCheckCRC()}
                sx={{ width: 200 }}
              />
            </Stack>
            {result && (
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{ mt: 2 }}
              >
                <Box>
                  <Typography>ATTACHED FILE NAME</Typography>
                  <Typography>{result.fileName}</Typography>
                </Box>
                <Box>
                  <Typography>SERVER CRC32Q CODE</Typography>
                  <Typography>{result.servercode}</Typography>
                </Box>
                <Box>
                  <Typography>STATUS</Typography>
                  {result.isValid ? (
                    <Typography color="secondary">Valid</Typography>
                  ) : (
                    <Typography color="error">invalid</Typography>
                  )}
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
