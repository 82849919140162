import React, { Fragment, useState } from "react";
import Select from "react-select";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CircleCheckedIcon from "@assets/svg/CircleCheckedIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import { black, primary } from "@constants/colors";
import EnrouteExportDataModal from "@components/Modals/EnrouteExportDataModal";
import { PrimaryButton } from "@components/button";
import EnrouteTabLoader from "@components/core/EnrouteTabLoader";
import AntSwitch from "@components/core/AntSwitch";
import { commonStyles } from "@styles/commonStyles";
import { styles as airspaceStyles } from "@styles/airspaceStyles";
import { theme } from "@styles/theme";
import { getStorageValue } from "@utils/localStorage";
import DropDownLabel from "./DropDownLabel";

export default function AirspaceSideBar({
  allAirports = [],
  isLoadingAirport,
  isLoadingRestrictiveAirspaceGeometry,
  isLoadingFIRAirspaceGeometry,
  isLoadingRNAVRoutes,
  isLoadingCNAVRoutes,
  isLoadingAreaNavigationalRouteData,
  isLoadingConventionalRouteData,
  isLoadingGetAllNavaid,
  isLoadingGetAllsignificantPoint,
  filterPdrAirspace,
  filterControlAirspace,
  filterTraTsaAirspace,
  filterTMAAirspace,
  filterMTRAirspace,
  filterOtherRegulatedAirspace,
  filterFIRAirspace,
  filterADIZAirspace,
  FIRAirspaceOption,
  RNAVAirspaceOption,
  controlAirspaceOption,
  filterRNAVAirspace,
  CNAVAirspaceOption,
  filterCNAVAirspace,
  handlePdrAirspaceFilter,
  handletraTsaAirspaceFilter,
  filterENRTraTsaAirspace,
  handleENRTraTsaAirspaceFilter,
  handleFIRAirspaceFilter,
  handleADIZAirspaceFilter,
  handleRNAVAirspaceFilter,
  handleCNAVAirspaceFilter,
  navaidOptions,
  filterNavidAirspace,
  handleNavidAirspaceFilter,
  significantPointOptions,
  filterWayPointsAirspace,
  handleWayPointAirspaceFilter,
  isLoadingWaypointRouteData,
  selectedEffectiveDate,
  handleAirspacePlanEffectiveDate,
  handlecurrentTab,
  airspaceUsePlanDates,
  militryAUPOptionData,
  routeCNAVAUPOptionData,
  routeRNAVAUPOptionData,
  filterAUPFIRAirspace,
  handleAUPFIRAirspaceFilter,
  handleAUPActiveLiveTracker,
  isAUPActiveLiveTracker,
  currentLiveTime,
  handleRoutePlanEffectiveDate,
  selectedRoutePlanEffectiveDate,
  deptTimeRoutePlan,
  handleDeptTimeRoutePlan,
  arpFlightLevelRoutePlan,
  handleChangeARPFlightLevel,
  arpSpeedRoutePlan,
  handleChangeARPSpeed,
  startPointRoutePlan,
  handleChangeStartPointRoutePlan,
  startPointCNAVPlanOpts,
  startPointRNAVPlanOpts,
  handleCalculateRoutePlan,
  isLoadingRoutePlanValidation,
  RouteplanCNAVOpts,
  RouteplanRNAVOpts,
  flightLevelMin,
  flightLevelMax,
  departureAirportRoutePlan,
  arrivalAirportRoutePlan,
  handleRouteArrivalAirport,
  handleRouteDepartureAirport,
  isBufferPointRoutePlan,
  isShowCNAVRoute,
  handleRouteBufferPoint,
  handleShowCNAVRoutePlan,
  handleSearchArrivalDepartureAirportRoute,
  handleClearRoutePlanDepArrvlData,
  arrvalDepAirportRouteData,
  selectedNewRoutePlanPointData,
  selectedRouteEndPointData,
  isAirspaceRouteBufferPointsInSideCircle,
  isENRAirspaceGeometryLoading,
  pdrENRAirspaceOptions,
  tratsaENRAirspaceOptions,
  adizENRAirspaceOptions,
  mtrENRAirspaceOptions,
  tmaENRAirspaceOptions,
  otherRegulatedENRAirspaceOptions,
  handleMTRAirspaceFilter,
  handleTMAAirspaceFilter,
  handleControlAirspaceFilter,
  handleOtherRegulatedAirspaceFilter,
  filterAD217Airspace,
  ad217ENRAirspaceOptions,
  handleENRAD217AirspaceFilter,
}) {
  const accesstoken = getStorageValue(ACCESS_TOKEN);
  const {
    isAirspaceUsePlanDataLoading,
    isAirspaceUsePlanDataByIdLoading,
    isArrivalDepartureAirportRoutePlanLoading,
    isLoadingGetAllTRA_TSA_ADIZ,
    isENRAirspaceGeometryFetchLoading,
  } = useSelector((state) => state.airspace);

  const [isOpenExportDataModal, setOpenExportDataModal] = useState(false);
  const [tabvalue, setTabValue] = useState("1");

  const [radioValue, setRadioValue] = React.useState("enroute");

  const handleChangeRadio = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "enroute") {
      setTabValue("1");
      handlecurrentTab("1");
    } else if (event.target.value === "aup") {
      setTabValue("2");
      handlecurrentTab("2");
    } else if (event.target.value === "routeplan") {
      setTabValue("3");
      handlecurrentTab("3");
    }
  };

  const slectAllName = [{ name: "All", designation: "All", designator: "All" }];

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  //   handlecurrentTab(newValue);
  // };

  const handleEnrouteExportDataModal = () => {
    if (Boolean(accesstoken)) setOpenExportDataModal(true);
  };

  const handleCancelExportDataModal = () => {
    setOpenExportDataModal(false);
  };

  return (
    <div className="sidebar enroute-sidebar">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          className={`${
            tabvalue === "2"
              ? "check-list airspace-check-list-5 airspace-check-list-5-5"
              : "check-list airspace-check-list-5"
          }`}
        >
          <Box className="enroute-main">
            <FormControl className="enroute-radio-div">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value="enroute"
                  sx={{
                    color:
                      radioValue === "enroute"
                        ? `${theme.palette.primary.main} !important`
                        : `${theme.palette.grey.light1} !important`,
                    ml: 0,
                    mr: 0,
                    ".MuiFormControlLabel-label": {
                      fontWeight:
                        radioValue === "enroute"
                          ? `600 !important`
                          : `500 !important`,
                      textShadow: "none",
                    },
                    ".MuiRadio-root": {
                      padding: `8px !important`,
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        ".MuiSvgIcon-root": {
                          width: 14,
                          height: 14,
                        },
                      }}
                      checkedIcon={<CircleCheckedIcon />}
                    />
                  }
                  label="En-Route"
                />
                <FormControlLabel
                  value="aup"
                  sx={{
                    color:
                      radioValue === "aup"
                        ? `${theme.palette.primary.main} !important`
                        : `${theme.palette.grey.light1} !important`,

                    ml: 0,
                    mr: 0,
                    ".MuiFormControlLabel-label": {
                      fontWeight:
                        radioValue === "aup"
                          ? `600 !important`
                          : `500 !important`,
                      textShadow: "none",
                    },
                    ".MuiRadio-root": {
                      padding: `8px !important`,
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        ".MuiSvgIcon-root": {
                          width: 14,
                          height: 14,
                        },
                      }}
                      disabled={
                        isLoadingGetAllNavaid ||
                        isLoadingRestrictiveAirspaceGeometry
                      }
                      checkedIcon={<CircleCheckedIcon />}
                    />
                  }
                  label="Airspace Use Plan"
                />
                <FormControlLabel
                  value="routeplan"
                  disabled={
                    isLoadingGetAllNavaid ||
                    isLoadingRestrictiveAirspaceGeometry
                  }
                  sx={{
                    color:
                      radioValue === "routeplan"
                        ? `${theme.palette.primary.main} !important`
                        : `${theme.palette.grey.light1} !important`,
                    ".MuiFormControlLabel-label": {
                      fontWeight:
                        radioValue === "routeplan"
                          ? `600 !important`
                          : `500 !important`,
                      textShadow: "none",
                    },
                    ml: 0,
                    mr: 0,
                    ".MuiRadio-root": {
                      padding: `8px !important`,
                    },
                  }}
                  control={
                    <Radio
                      sx={{
                        ".MuiSvgIcon-root": {
                          width: 14,
                          height: 14,
                        },
                      }}
                      checkedIcon={<CircleCheckedIcon />}
                    />
                  }
                  label="Route Planning"
                />
              </RadioGroup>
              {(isLoadingRestrictiveAirspaceGeometry ||
                isENRAirspaceGeometryLoading ||
                isLoadingFIRAirspaceGeometry ||
                isLoadingGetAllTRA_TSA_ADIZ) && <EnrouteTabLoader />}
            </FormControl>
            <TabContext value={tabvalue}>
              {/* <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
                className="enroute-tablist"
              >
                <Tab
                  label="EnRoute Data"
                  value="1"
                  className={`${
                    tabvalue === "1"
                      ? "enroute-airspace-tab enroute-btn enroute-active-tab"
                      : "enroute-airspace-tab"
                  }`}
                />
                <Tab
                  label="Airspace Use Plan"
                  value="2"
                  className={`${
                    tabvalue === "2"
                      ? "enroute-airspace-tab airspace-btn enroute-active-tab"
                      : "enroute-airspace-tab"
                  }`}
                />
              </TabList> */}
              <TabPanel
                value="1"
                className="enroute-airspace-tabpanel enroute-tabpanel"
              >
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 2.1.1) FIR / UIR" isRequired />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isLoadingFIRAirspaceGeometry}
                    isDisabled={isLoadingFIRAirspaceGeometry}
                    value={filterFIRAirspace}
                    placeholder="Select..."
                    onChange={handleFIRAirspaceFilter}
                    options={slectAllName.concat(FIRAirspaceOption)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 5.2)ADIZ" isRequired />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={isENRAirspaceGeometryFetchLoading}
                    value={filterADIZAirspace}
                    placeholder="Select..."
                    onChange={handleADIZAirspaceFilter}
                    options={
                      !isEmpty(adizENRAirspaceOptions)
                        ? slectAllName.concat(adizENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.designator}
                    getOptionValue={(option) => option.designator}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme.palette.grey.borderclr,
                    mb: 1,
                  }}
                />
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(ENR 2.1.2)Controlled Airspace"
                    isRequired
                  />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isLoadingFIRAirspaceGeometry}
                    isDisabled={
                      isLoadingFIRAirspaceGeometry || isEmpty(filterFIRAirspace)
                    }
                    value={filterControlAirspace}
                    placeholder="Select..."
                    onChange={handleControlAirspaceFilter}
                    options={
                      !isEmpty(controlAirspaceOption)
                        ? slectAllName.concat(controlAirspaceOption)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 2.1.3)TMA" isRequired />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterTMAAirspace}
                    placeholder="Select..."
                    onChange={handleTMAAirspaceFilter}
                    options={
                      !isEmpty(tmaENRAirspaceOptions)
                        ? slectAllName.concat(tmaENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 2.1.4)MTR" isRequired />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterMTRAirspace}
                    placeholder="Select..."
                    onChange={handleMTRAirspaceFilter}
                    options={
                      !isEmpty(mtrENRAirspaceOptions)
                        ? slectAllName.concat(mtrENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(ENR 2.2)Other regulated airspace"
                    isRequired
                  />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterOtherRegulatedAirspace}
                    placeholder="Select..."
                    onChange={handleOtherRegulatedAirspaceFilter}
                    options={
                      !isEmpty(otherRegulatedENRAirspaceOptions)
                        ? slectAllName.concat(otherRegulatedENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme.palette.grey.borderclr,
                    mb: 1,
                  }}
                />
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(ENR 3.1)Conventional Routes"
                    isRequired
                  />
                  <Select
                    isClearable
                    isLoading={
                      isLoadingCNAVRoutes || isLoadingConventionalRouteData
                    }
                    isDisabled={
                      isLoadingCNAVRoutes ||
                      isLoadingConventionalRouteData ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterCNAVAirspace}
                    placeholder="Select..."
                    onChange={handleCNAVAirspaceFilter}
                    options={CNAVAirspaceOption}
                    getOptionLabel={(option) =>
                      `${option.designatorSecondLetter}${
                        option.designatorNumber
                      }${
                        option.multipleIdentifier
                          ? option.multipleIdentifier
                          : ""
                      }`
                    }
                    getOptionValue={(option) =>
                      `${option.designatorSecondLetter}_${
                        option.designatorNumber
                      }_${
                        option.multipleIdentifier
                          ? option.multipleIdentifier
                          : ""
                      }`
                    }
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(ENR 3.2)Area Navigational Routes"
                    isRequired
                  />
                  <Select
                    isClearable
                    isLoading={
                      isLoadingRNAVRoutes || isLoadingAreaNavigationalRouteData
                    }
                    isDisabled={
                      isLoadingRNAVRoutes ||
                      isLoadingAreaNavigationalRouteData ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterRNAVAirspace}
                    placeholder="Select..."
                    onChange={handleRNAVAirspaceFilter}
                    options={RNAVAirspaceOption}
                    getOptionLabel={(option) =>
                      `${option.designatorSecondLetter}${
                        option.designatorNumber
                      }${
                        option.multipleIdentifier
                          ? option.multipleIdentifier
                          : ""
                      }`
                    }
                    getOptionValue={(option) =>
                      `${option.designatorSecondLetter}_${
                        option.designatorNumber
                      }_${
                        option.multipleIdentifier
                          ? option.multipleIdentifier
                          : ""
                      }`
                    }
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme.palette.grey.borderclr,
                    mb: 1,
                  }}
                />
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 4.1)Navaids" isRequired />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isLoadingGetAllNavaid}
                    isDisabled={
                      isLoadingGetAllNavaid || isEmpty(filterFIRAirspace)
                    }
                    value={filterNavidAirspace}
                    placeholder="Select..."
                    onChange={handleNavidAirspaceFilter}
                    options={
                      !isEmpty(navaidOptions)
                        ? slectAllName.concat(navaidOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.designator}
                    getOptionValue={(option) => option.designator}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel label="(ENR 4.4)Reporting Points" isRequired />
                  <Select
                    isClearable
                    isLoading={
                      isLoadingGetAllsignificantPoint ||
                      isLoadingWaypointRouteData
                    }
                    isDisabled={
                      isLoadingGetAllsignificantPoint ||
                      isLoadingWaypointRouteData ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterWayPointsAirspace}
                    placeholder="Select..."
                    onChange={handleWayPointAirspaceFilter}
                    options={
                      !isEmpty(significantPointOptions)
                        ? slectAllName.concat(significantPointOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme.palette.grey.borderclr,
                    mb: 1,
                  }}
                />
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(ENR 5.1)"
                    sx={{ fontSize: 12 }}
                    isRequired={false}
                  />
                  <DropDownLabel
                    label="Prohibited,Restricted And Danger Airspace"
                    sx={{ fontSize: 11 }}
                    isRequired
                  />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterPdrAirspace}
                    placeholder="Select..."
                    onChange={handlePdrAirspaceFilter}
                    options={
                      !isEmpty(pdrENRAirspaceOptions)
                        ? slectAllName.concat(pdrENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.designator === "All"
                        ? "All"
                        : `${option.name} ${option?.designator}`
                    }
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Box>
                  <DropDownLabel
                    label="(ENR 5.2)"
                    sx={{ fontSize: 12 }}
                    isRequired={false}
                  />
                  <DropDownLabel
                    label="Military Exercise and Training Airspace"
                    sx={{ fontSize: 11 }}
                    isRequired
                  />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterENRTraTsaAirspace}
                    placeholder="Select..."
                    onChange={handleENRTraTsaAirspaceFilter}
                    options={
                      !isEmpty(tratsaENRAirspaceOptions)
                        ? slectAllName.concat(tratsaENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) =>
                      option.designator === "All"
                        ? "All"
                        : `${option.name} ${option?.designator}`
                    }
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
                <Divider
                  sx={{
                    backgroundColor: theme.palette.grey.borderclr,
                    mt: 1,
                    mb: 1,
                  }}
                />
                <Box sx={{ mb: 2 }}>
                  <DropDownLabel
                    label="(AD 2.17)"
                    sx={{ fontSize: 12 }}
                    isRequired={false}
                  />
                  <DropDownLabel
                    label="Air Traffic Service Airspace"
                    sx={{ fontSize: 11 }}
                    isRequired
                  />
                  <Select
                    isClearable
                    isMulti
                    isLoading={isENRAirspaceGeometryFetchLoading}
                    isDisabled={
                      isENRAirspaceGeometryFetchLoading ||
                      isEmpty(filterFIRAirspace)
                    }
                    value={filterAD217Airspace}
                    placeholder="Select..."
                    onChange={handleENRAD217AirspaceFilter}
                    options={
                      !isEmpty(ad217ENRAirspaceOptions)
                        ? slectAllName.concat(ad217ENRAirspaceOptions)
                        : []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    styles={commonStyles.selectScrollbar}
                  />
                </Box>
              </TabPanel>
              {/* AUP Tab data start */}
              <TabPanel
                value="2"
                className="enroute-airspace-tabpanel airspace-tabpanel"
              >
                <Box sx={{ height: "100%" }}>
                  <Box className="airspace-plan-data">
                    <Box sx={{ mb: 2 }}>
                      <DropDownLabel label="Effective Date" isRequired />
                      <Select
                        isClearable
                        isLoading={isAirspaceUsePlanDataLoading}
                        isDisabled={
                          isAirspaceUsePlanDataLoading || isAUPActiveLiveTracker
                        }
                        value={selectedEffectiveDate || null}
                        placeholder="Effective Date"
                        onChange={handleAirspacePlanEffectiveDate}
                        options={airspaceUsePlanDates}
                        getOptionLabel={(option) =>
                          moment(option.effective_date).format("DD MMM YYYY")
                        }
                        getOptionValue={(option) => option.effective_date}
                        styles={commonStyles.selectScrollbar}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <DropDownLabel label="FIR / UIR" isRequired />
                      <Select
                        isClearable
                        isMulti
                        isLoading={
                          isLoadingFIRAirspaceGeometry ||
                          isAirspaceUsePlanDataLoading
                        }
                        isDisabled={
                          isLoadingFIRAirspaceGeometry ||
                          isEmpty(selectedEffectiveDate) ||
                          isAirspaceUsePlanDataLoading
                        }
                        value={filterAUPFIRAirspace}
                        placeholder="Select..."
                        onChange={handleAUPFIRAirspaceFilter}
                        options={slectAllName.concat(FIRAirspaceOption)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        styles={commonStyles.selectScrollbar}
                      />
                    </Box>
                    <Divider
                      sx={{
                        backgroundColor: theme.palette.grey.borderclr,
                        mb: 1,
                      }}
                    />
                    {!isEmpty(filterAUPFIRAirspace) && (
                      <Fragment>
                        <Box sx={{ mb: 2 }}>
                          <DropDownLabel
                            label="Military Execrise and Training Airspace"
                            sx={{ fontSize: 12 }}
                            isRequired
                          />
                          <Select
                            isClearable
                            isMulti
                            isLoading={
                              isLoadingRestrictiveAirspaceGeometry ||
                              isAirspaceUsePlanDataByIdLoading
                            }
                            isDisabled={
                              isLoadingRestrictiveAirspaceGeometry ||
                              isEmpty(filterAUPFIRAirspace) ||
                              isAirspaceUsePlanDataByIdLoading ||
                              isAUPActiveLiveTracker
                            }
                            value={filterTraTsaAirspace}
                            placeholder="Select..."
                            onChange={handletraTsaAirspaceFilter}
                            options={
                              !isEmpty(militryAUPOptionData)
                                ? slectAllName.concat(militryAUPOptionData)
                                : []
                            }
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            styles={commonStyles.selectScrollbar}
                          />
                        </Box>
                        <Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <DropDownLabel
                              label="Conventional Routes"
                              isRequired
                            />
                            <Typography
                              variant="span"
                              sx={{
                                display: "inline-block",
                                width: 10,
                                height: 10,
                                backgroundColor: "#F2994A",
                                ml: 1,
                                borderRadius: 5,
                              }}
                            />
                          </Box>
                          <Select
                            isClearable
                            isLoading={
                              isLoadingCNAVRoutes ||
                              isLoadingConventionalRouteData ||
                              isAirspaceUsePlanDataByIdLoading
                            }
                            isDisabled={
                              isLoadingCNAVRoutes ||
                              isLoadingConventionalRouteData ||
                              isEmpty(filterAUPFIRAirspace) ||
                              isAirspaceUsePlanDataByIdLoading ||
                              isAUPActiveLiveTracker
                            }
                            value={filterCNAVAirspace}
                            placeholder="Select..."
                            onChange={handleCNAVAirspaceFilter}
                            options={routeCNAVAUPOptionData || []}
                            getOptionLabel={(option) =>
                              `${option.designatorSecondLetter}${
                                option.designatorNumber
                              }${
                                option.multipleIdentifier
                                  ? option.multipleIdentifier
                                  : ""
                              }`
                            }
                            getOptionValue={(option) =>
                              `${option.designatorSecondLetter}_${
                                option.designatorNumber
                              }_${
                                option.multipleIdentifier
                                  ? option.multipleIdentifier
                                  : ""
                              }`
                            }
                            styles={commonStyles.selectScrollbar}
                          />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <DropDownLabel
                              label="Area Navigational Routes"
                              isRequired
                            />
                            <Typography
                              variant="span"
                              sx={{
                                display: "inline-block",
                                width: 10,
                                height: 10,
                                backgroundColor: "#2F80ED",
                                ml: 1,
                                borderRadius: 5,
                              }}
                            />
                          </Box>
                          <Select
                            isClearable
                            isLoading={
                              isLoadingRNAVRoutes ||
                              isLoadingAreaNavigationalRouteData ||
                              isAirspaceUsePlanDataByIdLoading
                            }
                            isDisabled={
                              isLoadingRNAVRoutes ||
                              isLoadingAreaNavigationalRouteData ||
                              isAirspaceUsePlanDataByIdLoading ||
                              isAUPActiveLiveTracker
                            }
                            value={filterRNAVAirspace}
                            placeholder="Select..."
                            onChange={handleRNAVAirspaceFilter}
                            options={routeRNAVAUPOptionData || []}
                            getOptionLabel={(option) =>
                              `${option.designatorSecondLetter}${
                                option.designatorNumber
                              }${
                                option.multipleIdentifier
                                  ? option.multipleIdentifier
                                  : ""
                              }`
                            }
                            getOptionValue={(option) =>
                              `${option.designatorSecondLetter}_${
                                option.designatorNumber
                              }_${
                                option.multipleIdentifier
                                  ? option.multipleIdentifier
                                  : ""
                              }`
                            }
                            styles={commonStyles.selectScrollbar}
                          />
                        </Box>
                        <Divider
                          sx={{
                            backgroundColor: theme.palette.grey.borderclr,
                            mt: 1,
                            mb: 1,
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: black,
                              fontSize: 14,
                              fontWeight: 500,
                            }}
                          >
                            Live Tracker
                          </Typography>
                          <AntSwitch
                            width={35}
                            height={20}
                            thumbwidth={12.5}
                            thumbheight={12.5}
                            basepadding={3.5}
                            translatethumb={15}
                            trackcolor={`${primary}`}
                            checked={isAUPActiveLiveTracker}
                            onChange={() =>
                              handleAUPActiveLiveTracker(
                                !isAUPActiveLiveTracker
                              )
                            }
                          />
                        </Box>
                        {currentLiveTime && isAUPActiveLiveTracker && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#4F4F4F",
                                fontSize: 12,
                                fontWeight: 500,
                              }}
                            >
                              Last Updated Time
                            </Typography>
                            <Typography
                              sx={{
                                color: "#42444A",
                                fontSize: 12,
                                fontWeight: 500,
                              }}
                            >
                              {currentLiveTime
                                ? `${currentLiveTime
                                    .match(/.{1,2}/g)
                                    .join(":")}`
                                : "-"}
                            </Typography>
                          </Box>
                        )}
                      </Fragment>
                    )}
                  </Box>
                </Box>
              </TabPanel>
              {/* AUP Tab data end */}
              <TabPanel
                value="3"
                className="enroute-airspace-tabpanel route-plan-tabpanel"
              >
                <Box sx={{ height: "100%" }}>
                  <Box className="airspace-plan-data">
                    <Box sx={{ mb: 2 }}>
                      <DropDownLabel label="Effective Date" isRequired />
                      <Select
                        isClearable
                        isLoading={
                          isAirspaceUsePlanDataLoading ||
                          isAirspaceUsePlanDataByIdLoading ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        isDisabled={
                          isAirspaceUsePlanDataLoading ||
                          isAUPActiveLiveTracker ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        value={selectedRoutePlanEffectiveDate || null}
                        placeholder="Effective Date"
                        onChange={handleRoutePlanEffectiveDate}
                        options={airspaceUsePlanDates}
                        getOptionLabel={(option) =>
                          moment(option.effective_date).format("DD MMM YYYY")
                        }
                        getOptionValue={(option) => option.effective_date}
                        styles={commonStyles.selectScrollbar}
                      />
                    </Box>
                    <Divider
                      sx={{
                        backgroundColor: theme.palette.grey.borderclr,
                        mb: 1,
                      }}
                    />
                    <Box sx={{ mb: 2 }}>
                      <DropDownLabel
                        label="Departure Airport"
                        isRequired={false}
                      />
                      <Select
                        isClearable
                        isLoading={
                          isLoadingAirport ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        isDisabled={
                          isEmpty(selectedRoutePlanEffectiveDate) ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        placeholder="Select"
                        options={allAirports}
                        value={departureAirportRoutePlan}
                        onChange={handleRouteDepartureAirport}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isOptionDisabled={(option) =>
                          option.id === arrivalAirportRoutePlan?.id
                        }
                        styles={commonStyles.selectScrollbar}
                      />
                    </Box>
                    <Box sx={{ mb: 1.5 }}>
                      <DropDownLabel
                        label="Arrival Airport"
                        isRequired={false}
                      />
                      <Select
                        isClearable
                        isLoading={
                          isLoadingAirport ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        isDisabled={
                          isEmpty(selectedRoutePlanEffectiveDate) ||
                          isArrivalDepartureAirportRoutePlanLoading
                        }
                        placeholder="Select"
                        options={allAirports}
                        value={arrivalAirportRoutePlan}
                        onChange={handleRouteArrivalAirport}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isOptionDisabled={(option) =>
                          option.id === departureAirportRoutePlan?.id
                        }
                        styles={commonStyles.selectScrollbar}
                      />
                    </Box>
                    <Box className="flex-between-align">
                      <PrimaryButton
                        label="SEARCH"
                        variant="text"
                        sx={{
                          minWidth: 54,
                          p: 0,
                          justifyContent: "flex-start",
                          "& span": {
                            fontSize: 10,
                            fontWeight: 600,
                            textShadow: "none",
                          },
                        }}
                        isLoading={isArrivalDepartureAirportRoutePlanLoading}
                        onClick={handleSearchArrivalDepartureAirportRoute}
                      />
                      <PrimaryButton
                        label="CLEAR"
                        variant="text"
                        sx={{
                          minWidth: 43,
                          p: 0,
                          justifyContent: "flex-end",
                          "& span": {
                            fontSize: 10,
                            fontWeight: 600,
                            textShadow: "none",
                          },
                        }}
                        disabled={isArrivalDepartureAirportRoutePlanLoading}
                        onClick={handleClearRoutePlanDepArrvlData}
                      />
                    </Box>
                    {!isEmpty(arrvalDepAirportRouteData) && (
                      <Box
                        className="flex-between-align"
                        sx={{ mt: 1, mb: 1.5 }}
                      >
                        <Typography
                          variant="span"
                          sx={airspaceStyles.RoutestartpointText}
                        >
                          show only conventional route
                        </Typography>
                        <AntSwitch
                          width={30}
                          height={16}
                          thumbwidth={10}
                          thumbheight={10}
                          basepadding={3}
                          translatethumb={15}
                          trackcolor={`${primary}`}
                          bordercolor="#696B72"
                          checked={isShowCNAVRoute}
                          disabled={
                            isEmpty(arrvalDepAirportRouteData) ||
                            !isEmpty(selectedNewRoutePlanPointData)
                          }
                          onChange={() =>
                            handleShowCNAVRoutePlan(!isShowCNAVRoute)
                          }
                        />
                      </Box>
                    )}
                    <Divider
                      sx={{
                        backgroundColor: theme.palette.grey.borderclr,
                        mb: 1,
                        mt: 0.5,
                      }}
                    />
                    {!isEmpty(selectedNewRoutePlanPointData) && (
                      <>
                        {" "}
                        <Box className="flex-between-align" sx={{ mb: 2 }}>
                          <Typography
                            variant="span"
                            sx={airspaceStyles.RoutestartpointText}
                          >
                            Start Point
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              ...airspaceStyles.RoutestartpointText,
                              fontWeight: 600,
                            }}
                          >
                            {!isEmpty(selectedNewRoutePlanPointData)
                              ? selectedNewRoutePlanPointData[0].name
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="flex-between-align" sx={{ mb: 2 }}>
                          <Typography
                            variant="span"
                            sx={airspaceStyles.RoutestartpointText}
                          >
                            Buffer Points
                          </Typography>
                          <AntSwitch
                            width={35}
                            height={20}
                            thumbwidth={12}
                            thumbheight={12}
                            basepadding={3.8}
                            translatethumb={15}
                            trackcolor={`${primary}`}
                            bordercolor="#696B72"
                            checked={isBufferPointRoutePlan}
                            onChange={() =>
                              handleRouteBufferPoint(!isBufferPointRoutePlan)
                            }
                          />
                        </Box>
                        {isAirspaceRouteBufferPointsInSideCircle === true && (
                          <>
                            {" "}
                            <Box sx={{ mb: 2 }}>
                              <DropDownLabel
                                label="Aircraft Speed (kts)"
                                isRequired={false}
                              />
                              <TextField
                                fullWidth
                                placeholder="Enter speed"
                                type="number"
                                variant="outlined"
                                size="small"
                                id="arpspeed"
                                name="arpspeed"
                                inputProps={{ min: 0 }}
                                sx={{
                                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                    {
                                      appearance: "none",
                                      margin: 0,
                                    },
                                }}
                                value={arpSpeedRoutePlan || ""}
                                onChange={(v) =>
                                  handleChangeARPSpeed(v.target.value)
                                }
                                disabled={
                                  !isAirspaceRouteBufferPointsInSideCircle
                                }
                              />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <DropDownLabel
                                label="Aircraft Flight Level (FL)"
                                isRequired={false}
                              />
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="arpfl"
                                placeholder="Enter flight level"
                                name="arpflightlevel"
                                inputProps={{
                                  // min: flightLevelMin,
                                  // max: flightLevelMax,
                                  type: "number",
                                }}
                                sx={{
                                  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                                    {
                                      appearance: "none",
                                      margin: 0,
                                    },
                                }}
                                disabled={
                                  !isAirspaceRouteBufferPointsInSideCircle
                                }
                                // error={
                                //   Number(arpFlightLevelRoutePlan) <
                                //     Number(flightLevelMin) ||
                                //   Number(arpFlightLevelRoutePlan) >
                                //     Number(flightLevelMax)
                                // }
                                value={arpFlightLevelRoutePlan || ""}
                                onChange={(v) =>
                                  handleChangeARPFlightLevel(v.target.value)
                                }
                              />
                              {/* {startPointRoutePlan && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InfoCircleIcon
                            width={15}
                            height={15}
                            color={
                              Number(arpFlightLevelRoutePlan) <
                                Number(flightLevelMin) ||
                              Number(arpFlightLevelRoutePlan) >
                                Number(flightLevelMax)
                                ? theme.palette.red.main
                                : "#696B72"
                            }
                          />
                          <Typography
                            variant="span"
                            sx={{
                              fontSize: 12,
                              color:
                                Number(arpFlightLevelRoutePlan) <
                                  Number(flightLevelMin) ||
                                Number(arpFlightLevelRoutePlan) >
                                  Number(flightLevelMax)
                                  ? theme.palette.red.main
                                  : "#696B72",
                              fontWeight: 600,
                              pl: 0.5,
                            }}
                          >
                            Flight level min: {flightLevelMin}, max:{" "}
                            {flightLevelMax}.
                          </Typography>
                        </Box>
                      )} */}
                            </Box>
                            <Box>
                              <DropDownLabel
                                label="Est. Departure Time (24 hrs)"
                                isRequired={false}
                              />
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimeField
                                  label=""
                                  size="small"
                                  fullWidth
                                  disabled={
                                    !isAirspaceRouteBufferPointsInSideCircle
                                  }
                                  value={
                                    deptTimeRoutePlan
                                      ? moment(deptTimeRoutePlan, "HH:mm")
                                      : undefined
                                  }
                                  onChange={(newValue) =>
                                    handleDeptTimeRoutePlan(
                                      moment(newValue).format("HHmm")
                                    )
                                  }
                                  format="HH:mm"
                                  placeholde="HH:MM"
                                />
                              </LocalizationProvider>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {tabvalue === "1" && (
          <PrimaryButton
            label="Export GeoJSON"
            sx={{ minWidth: "100%", mt: 1.5 }}
            disabled={!Boolean(accesstoken)}
            onClick={handleEnrouteExportDataModal}
          />
        )}
        {tabvalue === "3" && (
          <PrimaryButton
            label="Calculate"
            sx={{ minWidth: "100%", mt: 1.5 }}
            isLoading={isLoadingRoutePlanValidation}
            disabled={!isAirspaceRouteBufferPointsInSideCircle}
            onClick={handleCalculateRoutePlan}
          />
        )}
      </div>
      <EnrouteExportDataModal
        isOpen={isOpenExportDataModal}
        handleCancel={handleCancelExportDataModal}
      />
    </div>
  );
}
