import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import parseQueryParameters from "parse-url-query-params";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Box, CircularProgress, Typography } from "@mui/material";
import { roles } from "@constants/constant";
import { airportDataCategories } from "@constants/ad2Data";
import CategoryCard from "@components/core/CategoryCard";
import {
  setSelctedAirportFormCategories,
  setAAIAirport,
  setAAIAD212RWYDetail,
  setAllAirportsCharts,
} from "@store/airport";
import { getAAIAirport } from "@store/airport/actions";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";

export default function AD2DataEntrySideBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.userSession.user);
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const isAAI = user?.role === roles.aai;
  const isAddAirportDataPath =
    location.pathname === "/ad-2-data-entry/add-airport-data";
  const { isLoadingAddAirportDetail, selctedAirportFormCategories } =
    useSelector((state) => state.airport);

  const prsedUrl = parseQueryParameters(
    decodeURIComponent(`${location.pathname}${location.search}`)
  );
  const selectedAirport = allNewAirports.find(
    (airport) => airport.id === Number(prsedUrl.airportId)
  );
  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    if (isEmpty(selctedAirportFormCategories)) {
      setActiveCategory(null);
    }
  }, [selctedAirportFormCategories]);

  const selectedAdcategoryData = (catType, arpOption) => {
    dispatch(
      getAAIAirport(catType, arpOption.id, arpOption.version_id, (data) => {
        dispatch(setAAIAirport(data));
      })
    );
  };

  const handleAirportFromCategory = (activeCategoryData) => {
    if (!selectedAirport) return;
    setActiveCategory(activeCategoryData);

    dispatch(setAAIAirport(null));
    switch (activeCategoryData.codeId) {
      case "AD2.2":
        selectedAdcategoryData("geoadministrative", selectedAirport);
        break;
      case "AD2.3":
        selectedAdcategoryData("operationalhours", selectedAirport);
        break;
      case "AD2.4":
        selectedAdcategoryData("handlingservice", selectedAirport);
        break;
      case "AD2.5":
        selectedAdcategoryData("passengerfacilities", selectedAirport);
        break;
      case "AD2.6":
        selectedAdcategoryData("rescueservices", selectedAirport);
        break;
      case "AD2.7":
        selectedAdcategoryData("seasonalservice", selectedAirport);
        break;
      case "AD2.8apr":
        selectedAdcategoryData("apron", selectedAirport);
        break;
      case "AD2.8txy":
        selectedAdcategoryData("taxiway", selectedAirport);
        break;
      case "AD2.8chk":
        selectedAdcategoryData("checkpoints", selectedAirport);
        break;
      case "AD2.9":
        selectedAdcategoryData("surfacmovements", selectedAirport);
        break;
      case "AD2.10":
        selectedAdcategoryData("aixmobstacles", selectedAirport);
        break;
      case "AD2.11":
        selectedAdcategoryData("meteorologicalinfo", selectedAirport);
        break;
      case "AD2.12rwydetail":
        selectedAdcategoryData("runwayphysicals", selectedAirport);
        break;
      case "AD2.13":
        dispatch(
          getAAIAirport(
            "runwayphysicals",
            selectedAirport.id,
            selectedAirport.version_id,
            (data) => {
              dispatch(setAAIAD212RWYDetail(data));
            }
          )
        );
        selectedAdcategoryData("declareddistaces", selectedAirport);
        break;
      case "AD2.14":
        dispatch(
          getAAIAirport(
            "runwayphysicals",
            selectedAirport.id,
            selectedAirport.version_id,
            (data) => {
              dispatch(setAAIAD212RWYDetail(data));
            }
          )
        );
        selectedAdcategoryData("rwylights", selectedAirport);
        break;
      case "AD2.15":
        selectedAdcategoryData("powerlights", selectedAirport);
        break;
      case "AD2.17":
        selectedAdcategoryData("airspaceservice", selectedAirport);
        break;
      case "AD2.18":
        selectedAdcategoryData("airtrafficservices", selectedAirport);
        break;
      case "AD2.19":
        selectedAdcategoryData("navaids", selectedAirport);
        break;
      case "AD2.20":
        selectedAdcategoryData("aerodromeregulationinfo", selectedAirport);
        break;
      case "AD2.21":
        selectedAdcategoryData("noiseflight", selectedAirport);
        break;
      case "AD2.22":
        selectedAdcategoryData("noiseflight", selectedAirport);
        break;
      case "AD2.23":
        selectedAdcategoryData("aerodromeregulationinfo", selectedAirport);
        break;
      case "AD2.24":
        dispatch(
          getAAIAirport(
            "charts",
            selectedAirport.id,
            selectedAirport.version_id,
            (data) => {
              dispatch(setAAIAirport(data));
              dispatch(setAllAirportsCharts(data));
            }
          )
        );
        break;
      default:
        break;
    }
    dispatch(setSelctedAirportFormCategories(activeCategoryData));
  };

  return (
    isAAI && (
      <Fragment>
        {isAddAirportDataPath && (
          <>
            {isLoadingAddAirportDetail ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ ...styles.reviewSidebarCat, pt: 0 }}>
                <Typography sx={styles.catTitle}>Category</Typography>
                <Box
                  sx={{
                    height: "calc(100vh - 163px - 47px)",
                    overflow: "auto",
                    m: 1,
                    "&::-webkit-scrollbar": {
                      width: "0px",
                      backgroundColor: theme.palette.white.main,
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "gray",
                      borderRadius: "5px",
                    },
                  }}
                >
                  {airportDataCategories &&
                    airportDataCategories.map((category, index) => (
                      <CategoryCard
                        key={index}
                        categoryData={category}
                        activeCategory={activeCategory}
                        selectedCategories={[]}
                        handleActiveCategory={handleAirportFromCategory}
                      />
                    ))}
                </Box>
              </Box>
            )}
          </>
        )}
      </Fragment>
    )
  );
}
