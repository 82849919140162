import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, Typography } from "@mui/material";
import AixmAD13Table from "@components/aixm-table-item/AixmAD13Table";
import AixmAD15Table from "@components/aixm-table-item/AixmAD15Table";
import { styles } from "@styles/aixmStyles";

const AixmAD1 = ({ selectedAerodrome, selectedAD1Category }) => {
  const { AD1Aerodrome, AD1_3Aerodrome, AD1_5Aerodrome } = useSelector(
    (state) => state.aixm
  );

  if (isEmpty(selectedAD1Category)) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography sx={styles.AD1Label}>
        {selectedAerodrome?.label} &gt;&gt; {selectedAD1Category?.label}
      </Typography>
      {!isEmpty(AD1Aerodrome) &&
        selectedAD1Category.value !== "AD1.5" &&
        selectedAD1Category.value !== "AD1.3" && (
          <Box className="selected-general-data">
            <Box
              sx={styles.AD1Data}
              dangerouslySetInnerHTML={{
                __html: AD1Aerodrome[selectedAD1Category.value],
              }}
            />
          </Box>
        )}
      {!isEmpty(AD1_3Aerodrome) && selectedAD1Category.value === "AD1.3" && (
        <AixmAD13Table AD1_3Aerodrome={AD1_3Aerodrome} />
      )}
      {!isEmpty(AD1_5Aerodrome) && selectedAD1Category.value === "AD1.5" && (
        <AixmAD15Table AD1_5Aerodrome={AD1_5Aerodrome} />
      )}
      {(isEmpty(AD1Aerodrome) ||
        isEmpty(AD1_3Aerodrome) ||
        isEmpty(AD1_5Aerodrome)) && (
        <Box className="selected-general-data">
          <Typography sx={styles.noData}>No data found!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default AixmAD1;
