import React, { Fragment, useEffect, useState } from "react";
import { orderBy, filter } from "lodash";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "@components/button";
import FullContentLoader from "@components/loader/FullContentLoader";
import DiffTableCell from "@components/tabel-component/DiffTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableLoader from "@components/tabel-component/TableLoader";
import ValueItem from "@components/tabel-component/ValueItem";
import { AES_KEY } from "@constants/constant";
import { surveyCategory, surveyReportStatus } from "@constants/surveyorData";
import { dataChangedRowColorMap } from "@constants/colors";
import { theme } from "@styles/theme";
import { decryptStatic } from "@utils/decodeEncodeData";
import { getStorageValue } from "@utils/localStorage";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import CompareTableHeaderRow from "./CompareTableHeaderRow";
import SurveyorTopTableRow from "./SurveyorTopTableRow";
import AirportDetailTableRow from "./AirportDetailTableRow";
import AcceptDataTable from "./AcceptDataTable";
import InReviewDataTable from "./InReviewDataTable";
import CompareDataTableRow from "./CompareDataTableRow";
import DataTableFooterRow from "./DataTableFooterRow";
import InReviewTableHeaderRow from "./InReviewTableHeaderRow";

export default function CompareReviewDataTable({
  isLoadingVerification,
  isLoadingCompareData,
  selected,
  selectedReport,
  surveyReportDetail,
  headerName,
  handleSelectAllClick,
  handleClick,
  handleAccept,
  handleReview,
}) {
  const aesKey = getStorageValue(AES_KEY);
  const [isOpenReviewModal, setOpenReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const notVerified = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.notVerified,
  ]);
  const acceptedData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.accepted,
  ]);
  const inReviewData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.inReview,
  ]);
  const isShowBaseElev =
    selectedReport?.feature_name === surveyCategory.objects ||
    selectedReport?.feature_name === surveyCategory.navaids;
  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (selected.length === 0) {
      setOpenReviewModal(false);
    }

    const initReviewData = filter(notVerified, (d) =>
      selected.includes(d.id1)
    ).map((d) => ({
      ...d,
      id: d.id1,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    setReviewData(initReviewData);
  }, [selected]); // eslint-disable-line

  const handleCellClick = (key, id) => {
    const updateReviewData = reviewData.map((review) => {
      if (id === review.id1) {
        return {
          ...review,
          [key]: !review[key],
        };
      }

      return review;
    });

    setReviewData(updateReviewData);
  };

  const handleSubmitReviewData = () => {
    const rData = reviewData.map((r) => ({
      id: r.id,
      latitude: r.latitude,
      longitude: r.longitude,
      top_elve: r.top_elve,
    }));

    handleReview(rData);
  };

  const handleCloseInReviewModal = () => {
    setOpenReviewModal(false);
  };

  return (
    <Fragment>
      <SurveyorTopTableRow
        serveyorName={
          selectedReport
            ? decryptStatic(selectedReport?.user_name, aesKey)
            : "-"
        }
        userId={selectedReport?.user_empid}
      />
      {selectedReport && (
        <AirportDetailTableRow selectedReport={selectedReport} />
      )}
      <div style={{ marginTop: "78px" }} />
      {(isLoadingCompareData || surveyReportDetail.length === 0) && (
        <TableLoader
          isLoading={isLoadingCompareData}
          data={surveyReportDetail}
        />
      )}
      {isLoadingCompareData && surveyReportDetail.length > 0 && (
        <FullContentLoader />
      )}
      <TableContainer>
        {notVerified.length > 0 && (
          <Fragment>
            {selected.length > 0 && (
              <EnhancedTableToolbar numSelected={selected.length} />
            )}
            <Table stickyHeader size="small">
              <TableHead>
                {headerName && (
                  <TableRow>
                    <StyledTableCell align="center" colSpan={7}>
                      {headerName}
                    </StyledTableCell>
                  </TableRow>
                )}
                <CompareTableHeaderRow
                  selectedReport={selectedReport}
                  isShowBaseElev={isShowBaseElev}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={notVerified.length}
                />
              </TableHead>
              <TableBody>
                {orderBy(notVerified, ["uid1"], ["asc"]).map((row, i) => {
                  const isItemSelected = isSelected(row.id1);
                  const labelId = `enhanced-table-checkbox-${i}`;

                  return (
                    <CompareDataTableRow
                      key={i}
                      row={row}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      isShowBaseElev={isShowBaseElev}
                      handleClick={handleClick}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {acceptedData.length > 0 && (
          <AcceptDataTable
            data={acceptedData}
            isShowBaseElev={isShowBaseElev}
          />
        )}
        {inReviewData.length > 0 && (
          <InReviewDataTable
            data={inReviewData}
            isShowBaseElev={isShowBaseElev}
          />
        )}
      </TableContainer>
      {notVerified.length > 0 && (
        <DataTableFooterRow
          isLoadingVerification={isLoadingVerification}
          selected={selected}
          selectedReport={selectedReport}
          handleAccept={handleAccept}
          handleReview={() => setOpenReviewModal(true)}
        />
      )}
      1212121
      <Dialog fullWidth open={isOpenReviewModal} maxWidth="xl">
        <DialogTitle>
          <Box sx={{ mb: -2 }}>In Review Data</Box>
          <Typography variant="caption">
            Select the data set where deviation is found before submit.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table stickyHeader size="small">
            <TableHead>
              <InReviewTableHeaderRow isShowBaseElev={isShowBaseElev} />
            </TableHead>
            <TableBody>
              {orderBy(reviewData, ["uid1"], ["asc"]).map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor:
                        dataChangedRowColorMap[row.result] ??
                        theme.palette.white.main,
                    }}
                  >
                    <DiffTableCell align="center">{row["uid1"]}</DiffTableCell>
                    <DiffTableCell align="center">
                      {row["point_name1"]}
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={row["latdev"]}
                      isselected={row.latitude.toString()}
                      onClick={() => handleCellClick("latitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["latitude1"]} />
                        <ValueItem value={row["latitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={row["longdev"]}
                      isselected={row.longitude.toString()}
                      onClick={() => handleCellClick("longitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["longitude1"]} />
                        <ValueItem value={row["longitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["top_elevdiff"])}
                      isselected={row.top_elve.toString()}
                      onClick={() => handleCellClick("top_elve", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["top_elev1"]} />
                        <ValueItem value={row["top_elev2"]} />
                        <ValueItem value={row["top_elevdiff"]} />
                      </Stack>
                    </DiffTableCell>
                    {isShowBaseElev && (
                      <DiffTableCell isdiff={Boolean(row["base_elevdiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["base_elev1"]} />
                          <ValueItem value={row["base_elev2"]} />
                          <ValueItem value={row["base_elevdiff"]} />
                        </Stack>
                      </DiffTableCell>
                    )}
                    {!isShowBaseElev && (
                      <DiffTableCell isdiff={Boolean(row["ellipsoidaldiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["ellipsoidal_elev1"]} />
                          <ValueItem value={row["ellipsoidal_elev2"]} />
                          <ValueItem value={row["ellipsoidaldiff"]} />
                        </Stack>
                      </DiffTableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            size="small"
            label="Cancel"
            onClick={handleCloseInReviewModal}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
          <PrimaryButton
            label="Submit"
            size="small"
            onClick={handleSubmitReviewData}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
