import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)(() => ({
  fontSize: "12px",
}));

const StyledTypographyV2 = styled(Typography)(() => ({
  fontSize: "14px",
  minWidth: "100px",
}));

export { StyledTypographyV2 };
export default StyledTypography;
