import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTypography from "@components/core/StyledTypography";
import StyledTableCell, {
  StyledTableCellV12,
} from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";
import { styles as dataMngStyles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";

function RWYPhysicalCharacteristics({
  selctedAirport,
  activeCategory,
  runways,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          ...dataMngStyles.addAirportName,
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.black.main,
        }}
      >
        {selctedAirport.name} {">"} {activeCategory.label}
      </Stack>
      <Paper sx={{ ...dataMngStyles.aerodromeOLStable, mb: 5 }}>
        {!isEmpty(runways) ? (
          <>
            <Table>
              <TableHead>
                {/* <TableRow>
                  <HeaderTableCell colSpan={7}>
                    {selctedAirport.name} {">"} {activeCategory.label}
                  </HeaderTableCell>
                </TableRow> */}
                <TableRow>
                  <StyledTableCell>Designations</StyledTableCell>
                  <StyledTableCell>TRUE Bearings</StyledTableCell>
                  <StyledTableCell>Dimensions of RWY (M)</StyledTableCell>
                  <StyledTableCell>
                    Strength of pavement (PCN) and associated data and surface
                    of runway and associated stopways
                  </StyledTableCell>
                  <StyledTableCell>
                    Geographical coordinates for threshold and runway end
                  </StyledTableCell>
                  <StyledTableCell>
                    THR elevation and highest elevation of TDZ of precision APP
                    RWY
                  </StyledTableCell>
                  <StyledTableCell>
                    Slope of runway and associated stopway
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {runways.map((rwy, index) => {
                  return (
                    <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                      <StyledTableCellV12>
                        {rwy.designator ? rwy.designator : "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {rwy.trueBearing ? rwy.trueBearing : "NIL"}
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {rwy.nominalLength ? rwy.nominalLength : "-"}
                        {rwy.nominalWidth ? ` x ${rwy.nominalWidth}` : "-"}
                      </StyledTableCellV12>
                      <StyledTableCell>
                        <Box sx={styles.RWYTable}>
                          <StyledTypography>
                            {rwy.classPCN ? rwy.classPCN : "-"}
                            {rwy.pavementSubgradePCN
                              ? ` / ${rwy.pavementSubgradePCN}`
                              : "-"}
                            {rwy.maxTyrePressurePCN
                              ? ` / ${rwy.maxTyrePressurePCN}`
                              : "-"}
                            {rwy.evaluationMethodPCN
                              ? ` / ${rwy.evaluationMethodPCN}`
                              : "-"}
                          </StyledTypography>
                          <StyledTypography>
                            {rwy.pavementTypePCN || ""}
                          </StyledTypography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCellV12>
                        <Box sx={styles.RWYTable}>
                          <StyledTypography>
                            THR: {rwy.thr_lat ? rwy.thr_lat : "-"}
                            {rwy.thr_lng ? ` / ${rwy.thr_lng}` : "-"}
                          </StyledTypography>

                          <StyledTypography>
                            RWY END: {rwy.end_lat ? rwy.end_lat : "-"}
                            {rwy.end_lng ? ` / ${rwy.end_lng}` : "-"}
                          </StyledTypography>
                        </Box>
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        <Box sx={styles.RWYTable}>
                          <StyledTypography>
                            THR: {rwy.thr_el ? rwy.thr_el : "-"}
                          </StyledTypography>

                          <StyledTypography>
                            TDZ: {rwy.tdz_el ? rwy.tdz_el : "-"}
                          </StyledTypography>
                        </Box>
                      </StyledTableCellV12>
                      <StyledTableCellV12>
                        {rwy.slopeTDZ ? rwy.slopeTDZ : "-"}
                      </StyledTableCellV12>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Dimensions of stopway (M)</StyledTableCell>
                  <StyledTableCell>Dimensions of clearway (M)</StyledTableCell>
                  <StyledTableCell>Dimensions of strips (M)</StyledTableCell>
                  <StyledTableCell>
                    Dimensions of runway end safety areas
                  </StyledTableCell>
                  <StyledTableCell>
                    Location and description of arresting system (if any)
                  </StyledTableCell>
                  <StyledTableCell>
                    Existence of an obstacle-free zone
                  </StyledTableCell>
                  <StyledTableCell>Remarks</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {runways.map((rwy, index) => (
                  <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                    <StyledTableCellV12>
                      {rwy.stopwaylength ? rwy.stopwaylength : ""}
                      {rwy.stopwaywidth ? ` x ${rwy.stopwaywidth}` : "-"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {rwy.clearwaylength ? rwy.clearwaylength : ""}
                      {rwy.clearwaywidth ? ` x ${rwy.clearwaywidth}` : "-"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {rwy.lengthStrip ? rwy.lengthStrip : ""}
                      {rwy.widthStrip ? ` x ${rwy.widthStrip}` : "-"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {rwy.resalength ? rwy.resalength : ""}
                      {rwy.resawidth ? ` x ${rwy.resawidth}` : "-"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>{"NIL"}</StyledTableCellV12>
                    <StyledTableCellV12>
                      {rwy.ofz ? "True" : "False"}
                    </StyledTableCellV12>
                    <StyledTableCellV12>
                      {rwy.remarks ? rwy.remarks : "NIL"}
                    </StyledTableCellV12>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ width: "100%" }}>
                  No data found.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
    </Fragment>
  );
}

export default RWYPhysicalCharacteristics;
