import React from "react";

const EnRouteIcon = (props) => {
  return (
    <svg
      width={props.width || "90"}
      height={props.height || "102"}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M57.9803 49.8261L46.9585 61.3349C44.3338 64.063 39.7728 61.8549 40.3111 58.1083L40.9121 53.7387C41.0826 52.538 40.6843 51.3016 39.8168 50.4627L36.6904 47.3237C34.0248 44.6628 36.359 40.1205 40.079 40.7586L55.8168 43.3102C58.8958 43.8314 60.154 47.5947 57.9803 49.8261Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.98014 73.7975L6.26442 73.5733L5.8161 75.0048L6.53182 75.2289L6.98014 73.7975ZM24.413 64.8835L25.1568 64.9793L24.413 64.8835ZM40.0575 52.8415C40.4533 52.9636 40.8731 52.7417 40.9952 52.3458C41.1173 51.95 40.8954 51.5302 40.4995 51.4081L40.0575 52.8415ZM6.53182 75.2289C9.76233 76.2407 14.0714 76.0742 17.7366 74.4862C21.4286 72.8866 24.535 69.8074 25.1568 64.9793L23.6691 64.7877C23.1292 68.9799 20.4599 71.6716 17.1403 73.1099C13.7938 74.5597 9.85527 74.698 6.98014 73.7975L6.53182 75.2289ZM25.1568 64.9793C25.9005 59.2052 28.9583 55.9864 32.1629 54.2946C33.7777 53.4422 35.4371 52.9747 36.866 52.7757C38.3142 52.574 39.4587 52.6568 40.0575 52.8415L40.4995 51.4081C39.61 51.1338 38.2216 51.0724 36.6591 51.29C35.0772 51.5103 33.2478 52.0257 31.4626 52.9681C27.8674 54.8662 24.4807 58.4857 23.6691 64.7877L25.1568 64.9793Z"
        fill="#333333"
      />
      <path
        d="M59.2815 47.634C61.3141 48.2757 65.6998 47.1485 67.882 44.6611C70.6098 41.5519 68.7045 28.8668 79.1662 27.9401"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <circle
        cx="5.86116"
        cy="74.0682"
        r="3.5"
        transform="rotate(26.5177 5.86116 74.0682)"
        fill="white"
        stroke="#42444A"
      />
      <circle
        cx="83.6383"
        cy="27.9354"
        r="3.5"
        transform="rotate(26.5177 83.6383 27.9354)"
        fill="white"
        stroke="#42444A"
      />
    </svg>
  );
};

export default EnRouteIcon;
