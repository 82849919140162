import React from "react";
import { Popup } from "react-leaflet";
import { styles } from "@styles/mapStyles";
import { getObstacleSymbol } from "@utils/obstacleSymbols";
import PopupBox from "./PopupBox";
import PopupItem from "./PopupItem";

export default function VisualisationObstaclePopup({ info }) {
  return (
    <Popup>
      <div style={styles.etodGetObstacleSymbol}>
        {getObstacleSymbol(info.obstacle_type)}
      </div>
      <PopupBox>
        <PopupItem>ICAO</PopupItem>
        <PopupItem>{info.Airport}</PopupItem>
        <PopupItem>OBJECT</PopupItem>
        <PopupItem>{info["OBSTACLE TYPE"] || "-"}</PopupItem>
        <PopupItem>IDENTIFIER</PopupItem>
        <PopupItem>{info.IDENTIFIER}</PopupItem>
        <PopupItem>LATITIUDE</PopupItem>
        <PopupItem>{info.Latitude || "-"}</PopupItem>
        <PopupItem>LONGITUDE</PopupItem>
        <PopupItem>{info.Longitude || "-"}</PopupItem>
        <PopupItem>RWY Direction</PopupItem>
        <PopupItem>{info["RWY Direction"] || "-"}</PopupItem>
        <PopupItem>ELEVATION</PopupItem>
        <PopupItem>{info.Elevation || "-"}</PopupItem>
        <PopupItem>Horizontal Datum</PopupItem>
        <PopupItem>{info["Horizontal Datum"] || "-"}</PopupItem>
        <PopupItem>Vertical Datum</PopupItem>
        <PopupItem>{info["Vertical Datum"] || "-"}</PopupItem>
        <PopupItem>UOM Elevation</PopupItem>
        <PopupItem>{info["UOM Elevation"] || "-"}</PopupItem>
        <PopupItem>RWY / AREA AFFECTED</PopupItem>
        <PopupItem>{info["RWY /AREA AFFECTED"] || "-"}</PopupItem>
        <PopupItem>MARKING / LGT</PopupItem>
        <PopupItem>{info["MARKING /LGT"] || "-"}</PopupItem>
        <PopupItem>REMARK</PopupItem>
        <PopupItem>{info.REMARKS || "-"}</PopupItem>
      </PopupBox>
    </Popup>
  );
}
