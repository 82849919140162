import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const MuiBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1),
  borderLeft: "8px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 2,
}));

export default function PageTitle({ title }) {
  return (
    <MuiBox>
      <Typography color="black">{title}</Typography>
    </MuiBox>
  );
}
