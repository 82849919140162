import React, { Fragment, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Slider from "rc-slider";
import { useSelector, useDispatch } from "react-redux";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { chain, concat, isEmpty, uniq } from "lodash";
import { Box, Button, Typography } from "@mui/material";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import CloseIcon from "@assets/svg/CloseIcon";
import { airspaceType, deactiveTrackStyle } from "@constants/airspaceData";
import RouteTable from "@components/airspace/RouteTable";
import MapLayers from "@components/map/MapLayers";
import MeasurementControl from "@components/map/MeasurementControl";
import FullScreenControl from "@components/map/FullScreenControl";
import RoutePlanFlightValidationAlert from "@components/airspace/RoutePlanFlightValidationAlert";
import {
  setRestrictiveAirspaceDetails,
  findADIZDataList,
  findFIRData,
} from "@store/airspace";
import {
  getRestrictiveAirspaceDetails,
  getRestrictiveAirspaceDetailsSilent,
} from "@store/airspace/actions";
import { styles } from "@styles/airspaceStyles";
import { theme } from "@styles/theme";
import { AUPMilitrysliderdata, AUPRoutesliderdata } from "@utils/airspaceAUP";
import RenderFIRAirspace from "./RenderFIRAirspace";
import RestrictedAirspaceDetail from "./RestrictedAirspaceDetail";
import ADIZDetail from "./ADIZDetail";
import FIRUIRDetail from "./FIRUIRDetail";
import RenderNavigationalRouteAirspace from "./RenderNavigationalRouteAirspace";
import RenderConventionalRouteAirspace from "./RenderConventionalRouteAirspace";
import RenderNavidsAirspace from "./RenderNavidsAirspace";
import RenderWayPointsAirspace from "./RenderWayPointsAirspace";
import RenderSignificantBufferPointsAirspace from "./RenderSignificantBufferPointsAirspace";
import RenderWayPointsRouteAirspace from "./RenderWayPointsRouteAirspace";
import NavidDetail from "./NavidDetail";
import ControlledAirspaceDetail from "./ControlledAirspaceDetail";
import RenderTraTsaAirspaceUsePlan from "./RenderTraTsaAirspaceUsePlan";
import RenderConventionalRouteAirspaceUsePlan from "./RenderConventionalRouteAirspaceUsePlan";
import RenderFIRAirspaceUsePlan from "./RenderFIRAirspaceUsePlan";
import RenderNavigationalRouteAirspaceUsePlan from "./RenderNavigationalRouteAirspaceUsePlan";
import RenderTraTsaLiveTrackerAUP from "./RenderTraTsaLiveTrackerAUP";
import RenderRNAVLiveTrackerAUP from "./RenderRNAVLiveTrackerAUP";
import RenderCNAVLiveTrackerAUP from "./RenderCNAVLiveTrackerAUP";
import RenderRoutePlanningAirspace from "./RenderRoutePlanningAirspace";
import RenderRoutePlanArrivalDepartureAirport from "./RenderRoutePlanArrivalDepartureAirport";
import RenderDepArrvlAirportRoutePlan from "./RenderDepArrvlAirportRoutePlan";
import RenderSelectedAirportRoutePlan from "./RenderSelectedAirportRoutePlan";
import RenderDepArrvlAirportRouteThroughPointData from "./RenderDepArrvlAirportRouteThroughPointData";
import RenderPDRTraTsaMtrTmaOtherRegAdizAirspace from "./RenderPDRTraTsaMtrTmaOtherRegAdizAirspace";
import RenderPDRTraTsaMtrTmaOtherRegAdizAirspaceDetail from "./RenderPDRTraTsaMtrTmaOtherRegAdizAirspaceDetail";
import RenderCTRLAirspace from "./RenderCTRLAirspace";

export default function OSMMapContainer({
  airportLocation,
  zoomLevel,
  colorTray,
  filterFIRAirspace,
  // pdrAirspaceGeometry,
  traTsaAUPAirspaceGeometry,
  controlAirspaceGeometry,
  FIRAirspaceGeometry,
  // ADIZAirspaceGeometry,
  areaNavigationalRouteData,
  conventionalRouteData,
  navidAirspaceGeometry,
  wayPointsAirspaceGeometry,
  waypointRouteGeometry,
  isShowMoreViewContent,
  isShowMoreView,
  handleShowContent,
  currentTab,
  handleAirspacePlanDetail,
  selectedAirspacePlanDetail,
  handleShowClosePlanDetailViewMore,
  isShowAirspacePlanDetail,
  selectedEffectiveDate,
  filterTraTsaAirspace,
  filterCNAVAirspace,
  filterRNAVAirspace,
  AUPFIRAirspaceGeometry,
  selectedAUPfeatureType,
  handleSelectedFeatureType,
  handleCloseAirspaceUsePlanDetail,
  currentLivemilitryTimelinedata,
  currentLiveRNAVTimelinedata,
  currentLiveCNAVTimelinedata,
  isAUPActiveLiveTracker,
  RNAVRouteLiveGeometryData,
  CNAVRouteLiveGeometryData,
  currentLiveTime,
  isLoadingLivetrackerData,
  startPointRoutePlan,
  arpFlightPlanErrMssg,
  arpFlightPlanErrStatus,
  arpFlightPlanSuccessStatus,
  openRoutePlanMssgDialog,
  handleCloseRoutePlanMssg,
  selectedCNAVRouteLiveData,
  selectedRNAVRouteLiveData,
  arpFlightPlanErrorsRoute,
  arpFlightPlanErrorsAirspace,
  handleLivetrackeRestrictedAirspaceDetail,
  liveTracerRestrictedAirspaceDetails,
  handleChangeBufferPoints,
  arpFlightPlanArrivalTime,
  routeBufferSignificantPointGeometry,
  routeBufferNavaidGeometry,
  departureAirportRoutePlan,
  arrivalAirportRoutePlan,
  arrvalDepAirportRouteData,
  handleSelectedNewRoutePlanningPoint,
  handleSelectedNewRoutePlanningEndPoint,
  selectedNewRoutePlanPointData,
  selectedRoutePlanRouteIds,
  handleRoutePlanBufferPointData,
  routeThroughPointRPData,
  handleUndoSelectedRoutePoint,
  selectedRouteEndPointData,
  handleCheckMarkerIntoArrivalAirportRadius,
  selectedRoutePlanEffectiveDate,
}) {
  const dispatch = useDispatch();
  const {
    isLoadingRestrictiveAirspaceDetails,
    isAirspaceUsePlanDataByIdLoading,
    militryAirspaceUsePlanData,
    routeAirspaceUsePlanData,
    isLoadingFIRData,
    isLoadingGetAllTRA_TSA_ADIZ,
    restrictiveAirspaceGeometry,
    ENRTMACoordsData,
    ENRMTRCoordsData,
    ENRPDRCoordsData,
    ENROTHERCoordsData,
    ENRADIZRCoordsData,
    ENRTRATSACoordsData,
    ENRAD217CoordsData,
  } = useSelector((state) => state.airspace);
  const ENRAirspaceGeometryCoordsData = concat(
    ENRTMACoordsData,
    ENRMTRCoordsData,
    ENRPDRCoordsData,
    ENROTHERCoordsData,
    ENRADIZRCoordsData,
    ENRTRATSACoordsData,
    ENRAD217CoordsData
  );

  const restrictiveAirspaceDetails = useSelector(
    (state) => state.airspace.restrictiveAirspaceDetails || []
  );
  // FIR name
  const [selectedFIRUIR, setSelectedFIRUIR] = useState(null);
  const FIRData = useSelector((state) => findFIRData(state, selectedFIRUIR));
  const [selectedADIZ, setSelectedADIZ] = useState(null);
  const ADIZData = useSelector((state) =>
    findADIZDataList(state, selectedADIZ)
  );
  const [selectedNavid, setSelectedNavid] = useState(null);
  const [slectedFeatureType, setSlectedFeatureType] = useState(null);
  const [slectedFeatureData, setSlectedFeatureData] = useState(null);
  const [selectedControlledData, setSelectedControlledData] = useState(null);
  const [selectedENRAirspaceData, setSelectedENRAirspaceData] = useState(null);
  const [selectedAirspacePlanData, setSelectedAirspacePlanData] = useState([]);
  const [airspacePlanId, setSelectedAirspacePlanId] = useState(null);
  const [timeFromTo, setTimeFromTo] = useState([]);
  const [selectedMarks, setSelectedMarks] = useState({});
  const [selectedDataTrackStyle, setSelectedDataTrackStyle] = useState([]);
  const [selectedDataHandleStyle, setSelectedDataHandleStyle] = useState([]);
  const [count, setCount] = useState(0);

  const militryRouteAirspaceData = {
    ...militryAirspaceUsePlanData,
    ...routeAirspaceUsePlanData,
  };

  useEffect(() => {
    if (
      airspaceType.cnav === selectedAirspacePlanDetail?.type ||
      airspaceType.rnav === selectedAirspacePlanDetail?.type
    ) {
      const routename = `${selectedAirspacePlanDetail?.selectedPolygon?.designatorSecondLetter}${selectedAirspacePlanDetail?.selectedPolygon?.designatorNumber}`;
      const routesData = AUPRoutesliderdata(
        routename,
        militryRouteAirspaceData,
        currentLiveTime
      );
      setSelectedMarks(routesData.selectedMarkobj);
      setTimeFromTo(uniq(routesData.timeData));
      setSelectedDataHandleStyle(routesData.handleStyleData);
      setSelectedDataTrackStyle(routesData.trackStyleData);
      setSelectedAirspacePlanData(militryRouteAirspaceData[routename]);
      setSelectedAirspacePlanId(routename);
    } else if (
      airspaceType.restrictedAirspace === selectedAirspacePlanDetail?.type
    ) {
      const militryData = AUPMilitrysliderdata(
        selectedAirspacePlanDetail?.selectedPolygon?.designator,
        selectedAirspacePlanDetail,
        militryRouteAirspaceData,
        currentLiveTime
      );
      setTimeFromTo(uniq(militryData.timeData));
      setSelectedMarks(militryData.selectedMarkobj);
      setSelectedDataHandleStyle(militryData.handleStyleData);
      setSelectedDataTrackStyle(militryData.trackStyleData);
      setSelectedAirspacePlanData(
        militryRouteAirspaceData[
          selectedAirspacePlanDetail?.selectedPolygon?.designator
        ]
      );
      setSelectedAirspacePlanId(
        selectedAirspacePlanDetail?.selectedPolygon?.name
      );
    }
    setCount(count + 1);
  }, [selectedAirspacePlanDetail, currentLiveTime]); // eslint-disable-line

  const handleViewMore = (selectedPolygon, type, selectedData) => {
    setSlectedFeatureType(type);

    if (selectedData) {
      setSlectedFeatureData(selectedData);
    }

    if (type === airspaceType.restrictedAirspace) {
      dispatch(getRestrictiveAirspaceDetails(selectedPolygon.airspace_id));
    } else if (type === airspaceType.firuir) {
      setSelectedFIRUIR(selectedPolygon.name);
    } else if (type === airspaceType.adiz) {
      setSelectedADIZ(selectedPolygon.designation);
    } else if (type === airspaceType.navaids) {
      setSelectedNavid(selectedPolygon);
    }
    setSelectedControlledData(null);
    setSelectedENRAirspaceData(null);
    handleShowContent(true);
  };

  const habdleENRAirspaceViewmore = (enrData) => {
    handleShowContent(true);
    setSlectedFeatureType(null);
    setSelectedControlledData(null);
    setSelectedENRAirspaceData(enrData);
  };

  const habdleControlledViewmore = (controlledData) => {
    handleShowContent(true);
    setSlectedFeatureType(null);
    setSelectedENRAirspaceData(null);
    setSelectedControlledData(controlledData);
  };

  const handleCloseViewMore = () => {
    handleShowContent(false);
    setSelectedControlledData(null);
    setSelectedENRAirspaceData(null);
    dispatch(setRestrictiveAirspaceDetails(null));
  };

  const handleCancelPlanDetailViewMore = () => {
    handleShowClosePlanDetailViewMore(false);
  };

  const handleCallBackRestrictedAirspaceLiveTracker = (data) => {
    handleLivetrackeRestrictedAirspaceDetail(data || null);
  };

  const handleAirspaceUsePlanDetails = (selectedPolygon, type) => {
    setSlectedFeatureType(type);
    handleSelectedFeatureType(type);
    if (type === airspaceType.restrictedAirspace) {
      handleAirspacePlanDetail({ selectedPolygon, type });
      if (isAUPActiveLiveTracker) {
        dispatch(
          getRestrictiveAirspaceDetailsSilent(
            selectedPolygon.airspace_id,
            handleCallBackRestrictedAirspaceLiveTracker
          )
        );
      } else {
        dispatch(getRestrictiveAirspaceDetails(selectedPolygon.airspace_id));
      }
    } else if (type === airspaceType.firuir) {
      setSelectedFIRUIR(selectedPolygon.name);
    } else if (airspaceType.cnav === type || airspaceType.rnav === type) {
      handleAirspacePlanDetail({ selectedPolygon, type });
    }
  };

  const handleShoWDetails = () => {
    handleShowClosePlanDetailViewMore(true);
  };

  const airspaceMapE = useMemo(() => {
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
      >
        {/* <RenderPDRAirspace
          pdrAirspaceGeometry={pdrAirspaceGeometry}
          colorTray={colorTray}
          handleViewMore={handleViewMore}
        /> */}
        {/* {currentTab === "1" && !isEmpty(filterFIRAirspace) && (
          <RenderTraTsaAirspace
            traTsaAirspaceGeometry={traTsaAirspaceGeometry}
            colorTray={colorTray}
            handleViewMore={handleViewMore}
          />
        )} */}
        {currentTab === "2" && (
          <RenderTraTsaAirspaceUsePlan
            traTsaAirspaceGeometry={traTsaAUPAirspaceGeometry}
            colorTray={colorTray}
            filterTraTsaAirspace={filterTraTsaAirspace}
            selectedAirspacePlanDetail={selectedAirspacePlanDetail}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )}
        {currentTab === "2" &&
          !isEmpty(currentLivemilitryTimelinedata) &&
          isAUPActiveLiveTracker && (
            <RenderTraTsaLiveTrackerAUP
              colorTray={colorTray}
              filterTraTsaAirspace={currentLivemilitryTimelinedata}
              selectedAirspacePlanDetail={selectedAirspacePlanDetail}
              handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
            />
          )}
        {currentTab === "1" && (
          <RenderPDRTraTsaMtrTmaOtherRegAdizAirspace
            ENRgeometryData={ENRAirspaceGeometryCoordsData}
            colorTray={colorTray}
            handleViewMore={habdleENRAirspaceViewmore}
          />
        )}
        {currentTab === "1" && (
          <RenderCTRLAirspace
            CTRLgeometryData={controlAirspaceGeometry}
            colorTray={colorTray}
            handleViewMore={habdleControlledViewmore}
          />
        )}
        {currentTab === "1" && !isEmpty(filterFIRAirspace) && (
          <RenderFIRAirspace
            FIRAirspaceGeometry={FIRAirspaceGeometry}
            handleViewMore={handleViewMore}
          />
        )}
        {currentTab === "2" && (
          <RenderFIRAirspaceUsePlan
            FIRAirspaceGeometry={AUPFIRAirspaceGeometry}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )}
        {/* <RenderADIZAirspace
          ADIZAirspaceGeometry={ADIZAirspaceGeometry}
          handleViewMore={handleViewMore}
        /> */}
        {currentTab === "1" && !isEmpty(filterFIRAirspace) && (
          <RenderNavigationalRouteAirspace
            routeData={areaNavigationalRouteData}
            handleViewMore={handleViewMore}
          />
        )}
        {currentTab === "2" && (
          <RenderNavigationalRouteAirspaceUsePlan
            routeData={areaNavigationalRouteData}
            filterRNAVAirspace={filterRNAVAirspace}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )}
        {currentTab === "2" &&
          !isEmpty(currentLiveRNAVTimelinedata) &&
          !isEmpty(RNAVRouteLiveGeometryData) &&
          isAUPActiveLiveTracker && (
            <RenderRNAVLiveTrackerAUP
              filterRNAVAUPdata={currentLiveRNAVTimelinedata}
              RNAVRouteLiveGeometryData={RNAVRouteLiveGeometryData}
              handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
            />
          )}
        {/* {currentTab === "3" && (
          <RenderRNAVRoutePlan
            startPointRoutePlan={startPointRoutePlan}
            routeData={areaNavigationalRouteData}
            filterRNAVAirspace={filterRNAVAirspace}
            arpFlightPlanErrStatus={arpFlightPlanErrStatus}
            arpFlightPlanErrorsRoute={arpFlightPlanErrorsRoute}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )} */}
        {currentTab === "1" && !isEmpty(filterFIRAirspace) && (
          <RenderConventionalRouteAirspace
            routeData={conventionalRouteData}
            handleViewMore={handleViewMore}
          />
        )}
        {currentTab === "2" && (
          <RenderConventionalRouteAirspaceUsePlan
            routeData={conventionalRouteData}
            filterCNAVAirspace={filterCNAVAirspace}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )}
        {currentTab === "2" &&
          !isEmpty(currentLiveCNAVTimelinedata) &&
          !isEmpty(CNAVRouteLiveGeometryData) &&
          isAUPActiveLiveTracker && (
            <RenderCNAVLiveTrackerAUP
              filterCNAVAUPdata={currentLiveCNAVTimelinedata}
              CNAVRouteLiveGeometryData={CNAVRouteLiveGeometryData}
              handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
            />
          )}
        {/* {currentTab === "3" && (
          <RenderCNAVRoutePlan
            startPointRoutePlan={startPointRoutePlan}
            routeData={conventionalRouteData}
            filterCNAVAirspace={filterCNAVAirspace}
            arpFlightPlanErrStatus={arpFlightPlanErrStatus}
            arpFlightPlanErrorsRoute={arpFlightPlanErrorsRoute}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )} */}
        {currentTab === "3" && !isEmpty(arpFlightPlanErrorsAirspace) && (
          <RenderRoutePlanningAirspace
            restrictiveAirspaceGeometry={restrictiveAirspaceGeometry}
            arpFlightPlanErrorsAirspace={arpFlightPlanErrorsAirspace}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
            handleShoWDetails={handleShoWDetails}
          />
        )}
        {!isEmpty(navidAirspaceGeometry) && (
          <RenderNavidsAirspace
            navidAirspaceGeometry={navidAirspaceGeometry}
            handleViewMore={handleViewMore}
          />
        )}

        <RenderWayPointsAirspace
          wayPointsAirspaceGeometry={wayPointsAirspaceGeometry}
        />

        <RenderWayPointsRouteAirspace
          routeData={waypointRouteGeometry}
          handleViewMore={handleViewMore}
        />
        {/* Route Plan Buffer Points Start */}
        {!isEmpty(routeBufferNavaidGeometry) && (
          <RenderNavidsAirspace
            navidAirspaceGeometry={routeBufferNavaidGeometry}
            handleViewMore={handleViewMore}
          />
        )}
        {!isEmpty(routeBufferSignificantPointGeometry) && (
          <RenderSignificantBufferPointsAirspace
            wayPointsAirspaceGeometry={routeBufferSignificantPointGeometry}
          />
        )}

        {/* Route Plan Buffer Points End */}
        <RenderRoutePlanArrivalDepartureAirport
          arrivalArpCoord={
            arrivalAirportRoutePlan ? arrivalAirportRoutePlan.coordinates : null
          }
          departureArpCoord={
            departureAirportRoutePlan
              ? departureAirportRoutePlan.coordinates
              : null
          }
        />
        {!isEmpty(arrvalDepAirportRouteData) &&
          arrvalDepAirportRouteData.map((data) => {
            return (
              <RenderDepArrvlAirportRoutePlan
                key={data.id}
                routeId={data.id}
                routeName={data.name}
                routeData={data?.segments}
                selectedRoutePlanRouteIds={selectedRoutePlanRouteIds}
                selectedNewRoutePlanPointData={selectedNewRoutePlanPointData}
                handleSelectedNewRoutePlanningPoint={
                  handleSelectedNewRoutePlanningPoint
                }
                handleSelectedNewRoutePlanningEndPoint={
                  handleSelectedNewRoutePlanningEndPoint
                }
                handleRoutePlanBufferPointData={handleRoutePlanBufferPointData}
                handleCheckMarkerIntoArrivalAirportRadius={
                  handleCheckMarkerIntoArrivalAirportRadius
                }
              />
            );
          })}

        {!isEmpty(routeThroughPointRPData) &&
          routeThroughPointRPData.map((data) => {
            return (
              <RenderDepArrvlAirportRouteThroughPointData
                key={data.id}
                routeData={data.segments}
                routeId={data.id}
                routeName={data.name}
                selectedRoutePlanRouteIds={selectedRoutePlanRouteIds}
                // selectedNewRoutePlanPointData={selectedNewRoutePlanPointData}
                handleSelectedNewRoutePlanningPoint={
                  handleSelectedNewRoutePlanningPoint
                }
                handleSelectedNewRoutePlanningEndPoint={
                  handleSelectedNewRoutePlanningEndPoint
                }
                handleRoutePlanBufferPointData={handleRoutePlanBufferPointData}
                handleCheckMarkerIntoArrivalAirportRadius={
                  handleCheckMarkerIntoArrivalAirportRadius
                }
              />
            );
          })}
        {!isEmpty(selectedNewRoutePlanPointData) && (
          <RenderSelectedAirportRoutePlan
            routeData={selectedNewRoutePlanPointData}
            arpFlightPlanSuccessStatus={arpFlightPlanSuccessStatus}
            arpFlightPlanErrStatus={arpFlightPlanErrStatus}
            arpFlightPlanErrorsRoute={arpFlightPlanErrorsRoute}
            handleUndoSelectedRoutePoint={handleUndoSelectedRoutePoint}
            handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
          />
        )}
        {/* <PrintMap /> */}
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <MeasurementControl />
        <MapLayers />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );

    // eslint-disable-next-line
  }, [
    // pdrAirspaceGeometry,
    currentTab,
    // traTsaAirspaceGeometry,
    controlAirspaceGeometry,
    traTsaAUPAirspaceGeometry,
    arrivalAirportRoutePlan,
    departureAirportRoutePlan,
    FIRAirspaceGeometry,
    // ADIZAirspaceGeometry,
    areaNavigationalRouteData,
    conventionalRouteData,
    navidAirspaceGeometry,
    wayPointsAirspaceGeometry,
    waypointRouteGeometry,
    selectedAirspacePlanDetail,
    militryRouteAirspaceData,
    airspacePlanId,
    selectedAirspacePlanData,
    filterTraTsaAirspace,
    filterCNAVAirspace,
    filterRNAVAirspace,
    selectedEffectiveDate,
    AUPFIRAirspaceGeometry,
    currentLivemilitryTimelinedata,
    currentLiveRNAVTimelinedata,
    currentLiveCNAVTimelinedata,
    isAUPActiveLiveTracker,
    CNAVRouteLiveGeometryData,
    RNAVRouteLiveGeometryData,
    currentLiveTime,
    isLoadingLivetrackerData,
    startPointRoutePlan,
    arpFlightPlanErrStatus,
    arpFlightPlanErrorsRoute,
    arpFlightPlanErrorsAirspace,
    restrictiveAirspaceGeometry,
    routeBufferSignificantPointGeometry,
    routeBufferNavaidGeometry,
    arrvalDepAirportRouteData,
    selectedNewRoutePlanPointData,
    selectedRoutePlanRouteIds,
    routeThroughPointRPData,
    selectedRouteEndPointData,
    ENRAirspaceGeometryCoordsData,
  ]);

  const sliderMemo = useMemo(
    () => {
      const sliderDefaultValue = uniq(timeFromTo);
      return (
        <Slider
          key={count}
          range
          defaultValue={sliderDefaultValue}
          marks={selectedMarks}
          pushable
          min={0}
          max={2359}
          disabled
          trackStyle={selectedDataTrackStyle}
          handleStyle={selectedDataHandleStyle}
          railStyle={deactiveTrackStyle}
        />
      );
    },
    // eslint-disable-next-line
    [timeFromTo, selectedMarks, selectedDataTrackStyle, selectedDataHandleStyle]
  );

  return (
    <Fragment>
      {airspaceMapE}
      {isShowMoreView && isShowMoreViewContent && (
        <div style={styles.EnRouteShowDetails}>
          <div style={{ width: "98%" }}>
            {currentTab === "1" && (
              <div style={{ position: "relative" }}>
                <Fragment>
                  <Box
                    sx={styles.EnRouteDetailsCloseIcon}
                    onClick={handleCloseViewMore}
                  >
                    <CloseIcon
                      color={theme.palette.white.main}
                      height={6}
                      width={6}
                    />
                  </Box>
                  {slectedFeatureType === airspaceType.restrictedAirspace && (
                    <div
                      className="scrollbar-hide"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <RestrictedAirspaceDetail
                        isLoadingRestrictiveAirspaceDetails={
                          isLoadingRestrictiveAirspaceDetails
                        }
                        restrictiveAirspaceDetails={restrictiveAirspaceDetails}
                      />
                    </div>
                  )}
                  {slectedFeatureType === airspaceType.adiz && (
                    <ADIZDetail
                      isLoadingGetAllTRA_TSA_ADIZ={isLoadingGetAllTRA_TSA_ADIZ}
                      ADIZData={ADIZData}
                    />
                  )}
                  {!isEmpty(selectedENRAirspaceData) && currentTab === "1" && (
                    <RenderPDRTraTsaMtrTmaOtherRegAdizAirspaceDetail
                      isLoading={false}
                      airspaceDetails={selectedENRAirspaceData}
                    />
                  )}
                  {!isEmpty(selectedControlledData) && (
                    <ControlledAirspaceDetail
                      cnavData={selectedControlledData}
                    />
                  )}
                  {slectedFeatureType === airspaceType.firuir && (
                    <FIRUIRDetail
                      isLoadingFIRData={isLoadingFIRData}
                      FIRData={FIRData}
                    />
                  )}
                  {slectedFeatureType === airspaceType.rnav && (
                    <div
                      className="scrollbar-hide"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <RouteTable
                        isLoading={false}
                        routeData={areaNavigationalRouteData}
                      />
                    </div>
                  )}
                  {slectedFeatureType === airspaceType.cnav && (
                    <div
                      className="scrollbar-hide"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <RouteTable
                        isLoading={false}
                        routeData={conventionalRouteData}
                      />
                    </div>
                  )}
                  {slectedFeatureType === airspaceType.wayPointRoutes &&
                    slectedFeatureData && (
                      <div
                        className="scrollbar-hide"
                        style={{ height: "300px", overflow: "auto" }}
                      >
                        <RouteTable
                          isLoading={false}
                          routeData={slectedFeatureData}
                        />
                      </div>
                    )}
                  {slectedFeatureType === airspaceType.navaids && (
                    <NavidDetail selectedNavid={selectedNavid} />
                  )}
                </Fragment>
              </div>
            )}
          </div>
        </div>
      )}
      {!isEmpty(selectedAirspacePlanDetail) &&
        !isAirspaceUsePlanDataByIdLoading &&
        slectedFeatureType !== airspaceType.firuir &&
        ((!isEmpty(selectedEffectiveDate) && currentTab === "2") ||
          (!isEmpty(selectedRoutePlanEffectiveDate) && currentTab === "3")) && (
          <Box className="airspace-map-content" sx={styles.airspaceMapContent}>
            <Box sx={styles.AUPCloseBtn}>
              <Box
                className="aup-close-btn"
                onClick={handleCloseAirspaceUsePlanDetail}
              >
                <CloseIcon
                  color={theme.palette.white.main}
                  height={6}
                  width={6}
                />
              </Box>
              <Box sx={styles.AUPMain}>
                <Box sx={styles.AUPDetails}>
                  <Box sx={styles.AUPText}>AIRSPACE USE PLAN</Box>
                  <Box sx={styles.airspacePlanId}>
                    {selectedAirspacePlanDetail?.type === airspaceType.cnav
                      ? "Conventional Route"
                      : selectedAirspacePlanDetail?.type === airspaceType.rnav
                      ? "Area Navigational Route"
                      : "Airspace ID"}{" "}
                    - {airspacePlanId || " -"}
                    {(isAUPActiveLiveTracker ||
                      selectedRoutePlanEffectiveDate) && (
                      <Button
                        variant="text"
                        onClick={handleShoWDetails}
                        sx={styles.AUPSeeDetailBtn}
                      >
                        (See{" "}
                        {selectedAUPfeatureType === "Conventional Routes" ||
                        selectedAUPfeatureType === "Area Navigational Routes"
                          ? "Route "
                          : ""}
                        {selectedAUPfeatureType === "Restricted Airspace"
                          ? "Airspace "
                          : ""}
                        {selectedAUPfeatureType === "FIR / UIR" ? "FIR " : ""}
                        Details)
                      </Button>
                    )}
                  </Box>
                  <Box sx={styles.airspacePlanId}>
                    Effective Date -{" "}
                    {selectedEffectiveDate &&
                      moment(selectedEffectiveDate.effective_date).format(
                        "DD MMM YYYY"
                      )}
                    {selectedRoutePlanEffectiveDate &&
                      moment(
                        selectedRoutePlanEffectiveDate.effective_date
                      ).format("DD MMM YYYY")}
                  </Box>
                </Box>
                <Box sx={{ pb: 1.5, pl: 2, pr: 2 }}>
                  <Box sx={styles.AUPSliderBox}>
                    {!isEmpty(selectedAirspacePlanData) &&
                    !isEmpty(timeFromTo) ? (
                      <Box sx={styles.AUPSlider}>{sliderMemo}</Box>
                    ) : (
                      <Box>Data not found</Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      {currentTab === "2" &&
        !isEmpty(selectedAUPfeatureType) &&
        !isAUPActiveLiveTracker && (
          <Box sx={styles.AirspaceDetailBtn}>
            <Button
              className="aup-detail-btn"
              endIcon={<MdOutlineKeyboardArrowUp />}
              onClick={handleShoWDetails}
            >
              See{" "}
              {selectedAUPfeatureType === "Conventional Routes" ||
              selectedAUPfeatureType === "Area Navigational Routes"
                ? "Route "
                : ""}
              {selectedAUPfeatureType === "Restricted Airspace"
                ? "Airspace "
                : ""}
              {selectedAUPfeatureType === "FIR / UIR" ? "FIR " : ""}
              Details
            </Button>
          </Box>
        )}
      {isShowAirspacePlanDetail && (
        <Box sx={styles.AirspaceDetailMain}>
          <Box
            className="airspace-map-content"
            sx={{ width: "97.5%", position: "absolute" }}
          >
            <Box sx={styles.AirspaceDetails}>
              <Box
                sx={styles.AirspaceDetailCloseBtn}
                onClick={handleCancelPlanDetailViewMore}
              >
                <CloseIcon
                  color={theme.palette.white.main}
                  height={6}
                  width={6}
                />
              </Box>
              <Box>
                {slectedFeatureType === airspaceType.restrictedAirspace && (
                  <div
                    className="scrollbar-hide"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <RestrictedAirspaceDetail
                      isLoadingRestrictiveAirspaceDetails={
                        isLoadingRestrictiveAirspaceDetails
                      }
                      restrictiveAirspaceDetails={
                        liveTracerRestrictedAirspaceDetails
                          ? liveTracerRestrictedAirspaceDetails
                          : restrictiveAirspaceDetails
                      }
                    />
                  </div>
                )}
                {slectedFeatureType === airspaceType.firuir && (
                  <FIRUIRDetail
                    isLoadingFIRData={isLoadingFIRData}
                    FIRData={FIRData}
                  />
                )}
                {slectedFeatureType === airspaceType.cnav &&
                  isEmpty(selectedCNAVRouteLiveData) && (
                    <div
                      className="scrollbar-hide"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <RouteTable
                        isLoading={false}
                        routeData={conventionalRouteData}
                      />
                    </div>
                  )}
                {slectedFeatureType === airspaceType.rnav &&
                  isEmpty(selectedRNAVRouteLiveData) && (
                    <div
                      className="scrollbar-hide"
                      style={{ height: "300px", overflow: "auto" }}
                    >
                      <RouteTable
                        isLoading={false}
                        routeData={areaNavigationalRouteData}
                      />
                    </div>
                  )}
                {!isEmpty(selectedCNAVRouteLiveData) && (
                  <div
                    className="scrollbar-hide"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <RouteTable
                      isLoading={false}
                      routeData={selectedCNAVRouteLiveData}
                    />
                  </div>
                )}
                {!isEmpty(selectedRNAVRouteLiveData) && (
                  <div
                    className="scrollbar-hide"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <RouteTable
                      isLoading={false}
                      routeData={selectedRNAVRouteLiveData}
                    />
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {currentTab === "3" && openRoutePlanMssgDialog && (
        <RoutePlanFlightValidationAlert
          openRoutePlanMssgDialog={openRoutePlanMssgDialog}
          arpFlightPlanErrStatus={arpFlightPlanErrStatus}
          arpFlightPlanSuccessStatus={arpFlightPlanSuccessStatus}
          arpFlightPlanErrMssg={arpFlightPlanErrMssg}
          arpFlightPlanArrivalTime={arpFlightPlanArrivalTime}
          handleCloseRoutePlanMssg={handleCloseRoutePlanMssg}
          handleChangeBufferPoints={handleChangeBufferPoints}
        />
      )}
      {currentTab === "3" && !isEmpty(arrvalDepAirportRouteData) && (
        <Box className="selected-rp-detail">
          <Box className="route-plan-title">Select ROUTE on Map</Box>
          <Box sx={{ maxHeight: "40px", overflow: "auto" }}>
            {!isEmpty(selectedNewRoutePlanPointData) &&
            selectedNewRoutePlanPointData.length > 1 ? (
              <Typography className="rp-route-name">
                {chain(selectedNewRoutePlanPointData)
                  .drop()
                  .map((data) => data.routeName)
                  .uniq()
                  .value()
                  .join(",")}
              </Typography>
            ) : (
              <Typography className="rp-route-name" sx={{ color: "#C7C7CC" }}>
                No Selected
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
