export const aixmFeatureKeyMap = {
  AeronauticalGroundLight_list: "Aeronautical Ground Light",
  AirTrafficControlService_list: "Air Traffic Control Service",
  AirportGroundService_list: "Airport Ground Service",
  AirportHeliport_list: "Airport Heliport",
  AirportHeliportCollocation: "Airport Heliport Collocation",
  AirportHotSpot: "Airport HotSpot",
  Apron_list: "Apron",
  DesignatedPoint: "Designated Point",
  FlightConditionElementChoice: "Flight Condition Element Choice",
  FlightRoutingElementChoice: "Flight Routing Element Choice",
  GroundTrafficControlService: "Ground Traffic Control Service",
  InformationService_list: "Information Service",
  Navaid_list: "Navaid",
  NavigationSystemCheckpoint_list: "Navigation System Checkpoint",
  NonMovementArea: "Non Movement Area",
  notes_list: "Notes",
  Procedur: "Procedur",
  RadarSystem: "Radar System",
  Road: "Road",
  RulesProcedures_list: "Rules Procedures",
  SafeAltitudeArea: "Safe Altitude Area",
  SignificantPoint: "Significant Point",
  SurveyControlPoint: "Survey Control Point",
  Taxiway_list: "Taxiway",
  TouchDownLiftOff: "Touch Down Lift Off",
  unit_list: "Unit",
  WorkArea: "Work Area",
  ObstacleArea_list: "Obstacle Area",
  Runway_List: "Runway",
  RD_List: "Runway Direction",
  MeteorologicalInformation_list: "Meteorological Information Provided",
  Handling_services_list: "Handling Services and Facilites",
  Passenger_facilities_list: "Passenger Facilities",
  Rescue_list: "Rescue and Fire Fighting Services",
  Seasonal_service_list: "Seasonal Availability Clearing",
  Operator_list: "Operational Hours",
  Surface_Movement_Guidance:
    "Surface Movement Guidance and Control System and Markings",
};

export const aixmCategory = {
  aeronauticalGroundLight_list: "AeronauticalGroundLight_list",
  airTrafficControlService: "AirTrafficControlService_list",
  airportGroundService: "AirportGroundService_list",
  airportHeliport_list: "AirportHeliport_list",
  airportHeliportCollocation: "AirportHeliportCollocation",
  airportHotSpot: "AirportHotSpot",
  apron_list: "Apron_list",
  designatedPoint: "DesignatedPoint",
  flightConditionElementChoice: "FlightConditionElementChoice",
  flightRoutingElementChoice: "FlightRoutingElementChoice",
  groundTrafficControlService: "GroundTrafficControlService",
  informationService: "InformationService_list",
  navaid: "Navaid_list",
  navigationSystemCheckpoint_list: "NavigationSystemCheckpoint_list",
  nonMovementArea: "NonMovementArea",
  notes_list: "notes_list",
  procedur: "Procedur",
  radarSystem: "RadarSystem",
  road: "Road",
  rulesProcedures: "RulesProcedures_list",
  safeAltitudeArea: "SafeAltitudeArea",
  significantPoint: "SignificantPoint",
  surveyControlPoint: "SurveyControlPoint",
  taxiway_list: "Taxiway_list",
  touchDownLiftOff: "TouchDownLiftOff",
  unit_list: "unit_list",
  workArea: "WorkArea",
  runway_List: "Runway_List",
  RD_List: "RD_List",
  obstacleArea_list: "ObstacleArea_list",
  meteorologicalInformationList: "MeteorologicalInformation_list",
  handlingServicesList: "Handling_services_list",
  passengerFacilitiesList: "Passenger_facilities_list",
  rescueList: "Rescue_list",
  seasonalServiceList: "Seasonal_service_list",
  operatorList: "Operator_list",
  surfaceMovementGuidance: "Surface_Movement_Guidance",
};

export const aixmFilterType = {
  airport: "Airport",
  airspace: "Airspace",
  general: "General",
};
export const aerodromeFilterType = {
  AD1: "AD1",
  AD2: "AD2",
};

export const generalData = ["gen_0to1", "gen_2to4"];
export const enrData = ["enr_1", "enr_3to5_leftouts"];

export const aixmFilterOptions = [
  {
    id: 1,
    label: "General (GEN)",
    value: "General",
  },
  {
    id: 2,
    label: "En-Route (ENR)",
    value: "Airspace",
  },
  {
    id: 3,
    label: "Aerodrome (AD)",
    value: "Airport",
  },
];
export const ADCategoryOptions = [
  {
    id: 1,
    label: "AD 1",
    value: "AD1",
  },
  {
    id: 2,
    label: "AD 2",
    value: "AD2",
  },
];
export const AD1SubCategories = [
  {
    id: 1,
    label: "1.1 - Aerodrome/Heliport Availability And Conditions Of Use",
    value: "OTHER:AIP_AERODROME_USE",
  },
  {
    id: 2,
    label: "1.2 - Rescue And Fire Fighting Services And Snow Plan",
    value: "OTHER:AIP_RESCUE_AND_FIRE_FIGHTING_SERVICES",
  },
  {
    id: 3,
    label: "AD 1.3 - Index To Aerodromes",
    value: "AD1.3",
  },
  {
    id: 4,
    label: "1.4 - Grouping Of Aerodromes And Heliports",
    value: "OTHER:AIP_AERODROME_GROUPING",
  },
  {
    id: 5,
    label: "AD 1.5 - Status Of Licensing Of Aerodromes",
    value: "AD1.5",
  },
];

export const enrOptions = [
  {
    id: 1,
    label: "ENR 1",
    value: "ENR1",
  },
  {
    id: 2,
    label: "ENR 2",
    value: "ENR2",
  },
  {
    id: 3,
    label: "ENR 3",
    value: "ENR3",
  },
  {
    id: 4,
    label: "ENR 4",
    value: "ENR4",
  },
  {
    id: 5,
    label: "ENR 5",
    value: "ENR5",
  },
];

export const enrnewOptions = [
  {
    id: 1.1,
    label: "1.1 - General Rules",
    value: "OTHER:AIP_FLIGHT_RULES_GENERAL",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.2,
    label: "1.2 -  Visual Flight Rules",
    value: "OTHER:AIP_VISUAL_FLIGHT_RULES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.3,
    label: "1.3 - Instrument Flight Rules",
    value: "OTHER:AIP_INSTRUMENT_FLIGHT_RULES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.4,
    label: "1.4 - ATS Airspace Classification and Description",
    value: "OTHER:AIP_STATE_AIRSPACE_USAGE",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.5,
    label: "1.5 - Approach,Holding and Departure Procedures",
    value:
      "OTHER:AIP_HOLDING_APPROACH_DEPARTURE_PROCEDURES,OTHER:AIP_COMMON_ARRIVAL_PROCEDURES,OTHER:AIP_COMMON_DEPARTURE_PROCEDURES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.6,
    label: "1.6 - ATS Surveillance Services and Procedures",
    value: "OTHER:AIP_ATS_SURVEILLANCE_SERVICES_PROCEDURES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.7,
    label: "1.7 - Altimeter Setting Procedures",
    value: "OTHER:AIP_ALTIMETER_SETTING_PROCEDURES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.8,
    label: "1.8 -  Regional Supplementary Procedures",
    value: "OTHER:AIP_REGIONAL_SUPPLEMENTARY_PROCEDURES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.9,
    label: "1.9 -  Air Traffic Flow Management and Airspace Management",
    value: "OTHER:AIP_AIR_TRAFFIC_FLOW_MANAGEMENT",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.1,
    label: "1.10 - Flight Planning",
    value: "OTHER:AIP_FLIGHT_PLANNING",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.11,
    label: "1.11 - Addressing of Flight Plan Messages",
    value: "OTHER:AIP_ADDRESSING_FLIGHT_PLAN_MESSAGES",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.12,
    label: "1.12 - Interception of Civil Aircraft",
    value: "OTHER:AIP_INTERCEPTION_CIVIL_AIRCRAFT",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.13,
    label: "1.13 - Unlawful Interference",
    value: "OTHER:AIP_UNLAWFUL_INTERFERENCE",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 1.14,
    label: "1.14 - Air Traffic Incidents",
    value: "OTHER:AIP_AIR_TRAFFIC_INCIDENTS",
    enr: "ENR1",
    isEnrApi: true,
  },
  {
    id: 3.1,
    label: "3.0 - ATS ROUTES",
    value: "OTHER:AIP_ATS_ROUTES",
    enr: "ENR3",
    isEnrApi: true,
  },
  {
    id: 3.4,
    label: "3.4 - EN-ROUTE HOLDING",
    value: "enr_3_4",
    enr: "ENR3",
    isEnrApi: true,
  },
  {
    id: 4.3,
    label: "4.3 - Global Navigation Satellite System (GNSS)",
    value: "OTHER:AIP_ENR_4_3_GNSS",
    enr: "ENR4",
    isEnrApi: true,
  },
  {
    id: 4.5,
    label: "4.5 - Aeronautical Ground lights — en-route",
    value: "OTHER:AIP_AERONAUTICAL_GROUND_LIGHTS",
    enr: "ENR4",
    isEnrApi: true,
  },
  {
    id: 5.3,
    label:
      "5.3 - Other Activities Of A Dangerous Nature And Other Potential Hazards",
    value: "OTHER:AIP_EN_5_3_OTHER_POTENTIAL_HAZARDS",
    enr: "ENR5",
    isEnrApi: true,
  },
  {
    id: 5.6,
    label: "5.6 - Aerial Sporting And Recreational Activities",
    value: "OTHER:AIP_BIRD_MIGRATION_AND_AREAS_WITH_SENSITIVE_FAUNA",
    enr: "ENR5",
    isEnrApi: true,
  },
];

export const aixmCategories = [
  {
    id: 1,
    label: "AeronauticalGroundLight",
    value: "AeronauticalGroundLight_list",
  },
  {
    id: 2,
    label: "AirTrafficControlService",
    value: "AirTrafficControlService_list",
  },
  {
    id: 3,
    label: "AirportGroundService",
    value: "AirportGroundService_list",
  },
  {
    id: 4,
    label: "AirportHeliport",
    value: "AirportHeliport_list",
  },
  {
    id: 5,
    label: "AirportHeliportCollocation",
    value: "AirportHeliportCollocation",
  },
  {
    id: 6,
    label: "AirportHotSpot",
    value: "AirportHotSpot",
  },
  {
    id: 7,
    label: "Apron",
    value: "Apron_list",
  },
  {
    id: 8,
    label: "DesignatedPoint",
    value: "DesignatedPoint",
  },
  {
    id: 9,
    label: "FlightConditionElementChoice",
    value: "FlightConditionElementChoice",
  },
  {
    id: 10,
    label: "FlightRoutingElementChoice",
    value: "FlightRoutingElementChoice",
  },
  {
    id: 11,
    label: "GroundTrafficControlService",
    value: "GroundTrafficControlService",
  },
  {
    id: 12,
    label: "InformationService",
    value: "InformationService_list",
  },
  {
    id: 13,
    label: "Navaid",
    value: "Navaid_list",
  },
  {
    id: 14,
    label: "NavigationSystemCheckpoint",
    value: "NavigationSystemCheckpoint_list",
  },
  {
    id: 15,
    label: "NonMovementArea",
    value: "NonMovementArea",
  },
  {
    id: 16,
    label: "Notes",
    value: "notes_list",
  },
  {
    id: 18,
    label: "Procedur",
    value: "Procedur",
  },
  {
    id: 19,
    label: "RadarSystem",
    value: "RadarSystem",
  },
  {
    id: 20,
    label: "Road",
    value: "Road",
  },
  {
    id: 21,
    label: "RulesProcedures",
    value: "RulesProcedures_list",
  },
  {
    id: 23,
    label: "SafeAltitudeArea",
    value: "SafeAltitudeArea",
  },
  {
    id: 24,
    label: "SignificantPoint",
    value: "SignificantPoint",
  },
  {
    id: 25,
    label: "SurveyControlPoint",
    value: "SurveyControlPoint",
  },
  {
    id: 26,
    label: "Taxiway",
    value: "Taxiway_list",
  },
  {
    id: 27,
    label: "TouchDownLiftOff",
    value: "TouchDownLiftOff",
  },
  {
    id: 28,
    label: "Unit",
    value: "unit_list",
  },
  {
    id: 29,
    label: "WorkArea",
    value: "WorkArea",
  },
];

export const aixmAirportExportItems = [
  {
    id: 1,
    label: "Airport Heliport",
    value: "airportheliport",
  },
  {
    id: 2,
    label: "Runway",
    value: "runway",
  },
  {
    id: 3,
    label: "Runway Direction",
    value: "runway_direction",
  },
  {
    id: 4,
    label: "Runway Protect Area",
    value: "runway_protect_area",
  },
  {
    id: 5,
    label: "Obstacles",
    value: "obstacles",
  },
  {
    id: 6,
    label: "Apron",
    value: "apron",
  },
  {
    id: 7,
    label: "Taxiway",
    value: "taxiway",
  },
];

export const catAAiData = {
  geoadministrative: null,
  operationalhours: null,
  handlingservicesandfacilities: null,
  passengerfacilities: null,
  rescueservices: null,
  seasonalservice: null,
  apronstaxiwaysandchecklocationspositionsdata: null,
  surfacemovementguidanceandcontrolsystemandmarkings: null,
  aerodromeobstacles: null,
  meteorologicalinformationprovided: null,
  runwayphysicalcharacteristics: null,
  declareddistances: null,
  approachandrunwaylighting: null,
  otherlightingsecondarypowersupply: null,
  helicopterlandingarea: null,
  airtrafficserviceairspace: null,
  airtrafficservicescommunicationfacilities: null,
  radionavigationandlandingaids: null,
  localaerodromeregulations: null,
  noiseabatementprocedures: null,
  additionalinformation: null,
  chartsrelatedtoanaerodrome: null,
  vsspenetration: null,
};

export const AAigeoadministrativeKey = {
  address: "",
  authority_name: "",
  dateMagneticVariation: "",
  eMail: "",
  facsimile: "",
  fieldElevation: "",
  fieldElevationAccuracy: "",
  latitude: "",
  linkage: "",
  locationDescription: "",
  longitude: "",
  magneticVariation: "",
  magneticVariationAccuracy: "",
  magneticVariationChange: "",
  referencePointDescription: "",
  referenceTemperature: "",
  type_IFRVFR: "",
  verticalDatum: "",
  voice: "",
};

export const enr34options = [
  {
    id: 1,
    label: "Holding ID/FIX/Waypoint",
  },
  {
    id: 2,
    label: "Inbound Track (DEG M)",
  },
  {
    id: 3,
    label: "Direction of Holding Pattern",
  },
  {
    id: 4,
    label: "Max IAS (Kts)",
  },
  {
    id: 5,
    label: "Minimum/Maximum Holding Level",
  },
  {
    id: 6,
    label: "Outbound Time (Min)",
  },
  {
    id: 7,
    label: "Controlling Unit & Frequency",
  },
];
