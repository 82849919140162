import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { isEmpty, orderBy } from "lodash";
import {
  GET_ALL_FIR_DATA_URL,
  GET_ALL_TMA_DATA_URL,
  GET_ALL_CTA_DATA_URL,
  GET_ALL_REGULATED_AIRSPACE_DATA_URL,
  GET_RESTRICTIVE_AIRSPACE_GEOMETRY_URL,
  GET_FIR_AIRSPACE_GEOMETRY_URL,
  GET_ALL_ROUTE_URL,
  GET_ROUTE_DATA_URL,
  GET_ALL_NAVID_URL,
  GET_ALL_SIGNIFICANT_POINT_URL,
  GET_ALL_TRA_TSA_ADIZ_URL,
  GET_ALL_DPR_URL,
  GET_ADIZ_GEODATA_URL,
  GET_RESTRICTIVE_AIRSPACE_DETAILS_URL,
  GET_ALL_RNAV_CNAV_ROUTES_URL,
  GET_WAYPOINT_ROUTE_DATA_URL,
  EXPORT_AIRSPACE_SHEET_URL,
  EXPORT_ROUTE_SHEET_URL,
  EXPORT_DRP_SHEET_URL,
  EXPORT_TRA_SHEET_URL,
  EXPORT_AIRSPACE_GEOJSON,
  AIRSPACE_USAGE_PLAN_URL,
  AIRSPACE_EFFECTIVE_DATE_URL,
  FLIGHT_PLAN_VALIDATION_DATA_URL,
  ROUTE_PLAN_BUFFER_POINTS_DATA_URL,
  GET_ROUTE_PLAN_ARRIVAL_DEPARTURE_AIRPORT_URL,
  GET_ROUTE_THROUGH_POINT_ROUTE_PLAN,
  GET_ENR_AIRSPACES_GEOMETRY_URL,
  POST_ENR_AIRSPACES_GEOMETRY_FETCH_URL,
  GET_ENR_AIRSPACES_GEOMETRY_INFO_URL,
} from "@constants/urls";
import { airspaceFilterType } from "@constants/airspaceData";
import {
  firColumnsData,
  tmaColumnsData,
  ctaColumnsData,
  raColumnsData,
  spColumnsData,
  rnColumnsData,
  dprColumnsData,
  metaColumnsData,
  routeColumnsData,
} from "@utils/airspaceExport";
import { apiCall, downloadReadableStreamFile } from "@utils/connect";
import {
  setFIRdataLoadingRequest,
  setTMAdataLoadingRequest,
  setCTAdataLoadingRequest,
  setRegulatedAirspaceDataLoadingRequest,
  setRestrictiveAirspaceGeometryLoadingRequest,
  setFIRDetails,
  setTMADetails,
  setCTADetails,
  setRegulatedAirspaceDetails,
  setRestrictiveAirspaceGeometryData,
  setFIRAirspaceGeometryLoadingRequest,
  setFIRAirspaceGeometryData,
  setLoadingGetAllRoute,
  setAllRoutes,
  setLoadingGetRouteData,
  setRouteData,
  setLoadingGetAllNavaid,
  setAllNavaid,
  setLoadingGetAllsignificantPoint,
  setAllsignificantPoint,
  setLoadingGetAllDPR,
  setAllDPR,
  setLoadingGetAllTRA_TSA_ADIZ,
  setAllTRA_TSA_ADIZ,
  setLoadingGetADIZGeoData,
  setADIZGeoData,
  setLoadingRestrictiveAirspaceDetails,
  setRestrictiveAirspaceDetails,
  setLoadingRNAVRoutes,
  setRNAVRoutesData,
  setLoadingCNAVRoutes,
  setCNAVRoutesData,
  setLoadingAreaNavigationalRouteData,
  setAreaNavigationalRouteData,
  setLoadingConventionalRouteData,
  setConventionalRouteData,
  setLoadingAirspaceExport,
  setLoadingWaypointRouteData,
  setWaypointRouteData,
  setLoadingEnrouteExportJson,
  setAirspaceUsePlanDataLoading,
  setAirspaceUsePlanDates,
  setAirspaceUsePlanDataByIdLoading,
  setMilitryAirspaceUsePlanData,
  setRouteAirspaceUsePlanData,
  setLoadingRoutePlanValidation,
  setAirspaceRouteBufferPointsLoading,
  setArrivalDepartureAirportRoutePlanLoading,
  setArrvalDepAirportRouteData,
  setRouteThroughPointRPLoading,
  setRouteThroughPointRPData,
  setENRAirspaceGeometryLoading,
  setENRAirspaceGeometryFetchLoading,
  setENRAirspaceGeometryInfoLoading,
  setENRAirspaceGeometryOptions,
  setENRAirspaceGeometryInfoData,
  setENRPDRCoordsData,
  setENRTRATSACoordsData,
  setENROTHERCoordsData,
  setENRMTRCoordsData,
  setENRTMACoordsData,
  setENRADIZRCoordsData,
  setENRAD217CoordsData,
  setCTRLAirspaceGeometryData,
} from ".";

export const exportAirspaceData =
  (selctedAirspaceFilter, selectedAirspaceRows, DPRDataList, METADataList) =>
  (dispatch, getState) => {
    try {
      dispatch(setLoadingAirspaceExport(true));

      const { airspace } = getState();
      const wb = XLSX.utils.book_new();
      let sheet = null;

      if (selctedAirspaceFilter?.value === airspaceFilterType.firuir) {
        const rows = airspace.FIRData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = firColumnsData(rows);
      } else if (selctedAirspaceFilter?.value === airspaceFilterType.tma) {
        const rows = airspace.TMAData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = tmaColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.controlArea
      ) {
        const rows = airspace.CTAData.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = ctaColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.otherRegulatedAirspace
      ) {
        const rows = airspace.regulatedAirspaceData.filter((data) =>
          selectedAirspaceRows.includes(data.Aerodrome)
        );
        sheet = raColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.radioNavigation
      ) {
        const rows = airspace.allNavaid.filter((data) =>
          selectedAirspaceRows.includes(data.designator)
        );
        sheet = rnColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value === airspaceFilterType.significantPoints
      ) {
        const rows = airspace.allsignificantPoint.filter((data) =>
          selectedAirspaceRows.includes(data.name)
        );
        sheet = spColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.prohibitedRestrictedandDangerAreas
      ) {
        const rows = DPRDataList.filter((data) => {
          const dprName = `${data.Airspace_name} ${data.designator}`;
          return selectedAirspaceRows.includes(dprName);
        });
        sheet = dprColumnsData(rows);
      } else if (
        selctedAirspaceFilter?.value ===
        airspaceFilterType.militaryExecriseandTrainingAreas
      ) {
        const rows = METADataList.filter((data) => {
          const metaName = `${data.Airspace_name} ${data.designator}`;
          return selectedAirspaceRows.includes(metaName);
        });
        sheet = metaColumnsData(rows);
      } else if (selctedAirspaceFilter?.value === airspaceFilterType.routes) {
        const rows = airspace.routeData;
        sheet = routeColumnsData(rows);
      }

      if (sheet) {
        const ws = XLSX.utils.json_to_sheet(sheet);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${selctedAirspaceFilter.value}.csv`);
      }

      dispatch(setLoadingAirspaceExport(false));
    } catch (error) {
      dispatch(setLoadingAirspaceExport(false));
      toast.error(error.message);
    }
  };

export const exportAirspaceSheet = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".xls"
          : `${payload.category}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
      if (callback) callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "POST",
      EXPORT_AIRSPACE_SHEET_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportRouteSheet = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".zip"
          : `routes.zip`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
      if (callback) callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "POST",
      EXPORT_ROUTE_SHEET_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportDRPSheet = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".xls"
          : `${payload.airport_icao}_${payload.type}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
      if (callback) callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "POST",
      EXPORT_DRP_SHEET_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const exportTRASheet = (payload, token, callback) => (dispatch) => {
  try {
    dispatch(setLoadingAirspaceExport(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".xls"
          : `tra_${payload.category}.xls`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setLoadingAirspaceExport(false));
      if (callback) callback();
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAirspaceExport(false));
    };

    downloadReadableStreamFile(
      "POST",
      EXPORT_TRA_SHEET_URL,
      payload,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setLoadingAirspaceExport(false));
    toast.error(error.message);
  }
};

export const getFIRData = (version) => (dispatch) => {
  try {
    dispatch(setFIRdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setFIRDetails(response.data));
      dispatch(setFIRdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setFIRdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_FIR_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setFIRdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getTMAData = (version) => (dispatch) => {
  try {
    dispatch(setTMAdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setTMADetails(response.data));
      dispatch(setTMAdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setTMAdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_TMA_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setTMAdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getCTAData = (version) => (dispatch) => {
  try {
    dispatch(setCTAdataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setCTADetails(response.data));
      dispatch(setCTAdataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setCTAdataLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_CTA_DATA_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setCTAdataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getRegulatedAirspaceData = (version) => (dispatch) => {
  try {
    dispatch(setRegulatedAirspaceDataLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setRegulatedAirspaceDetails(response.data));
      dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_ALL_REGULATED_AIRSPACE_DATA_URL(version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setRegulatedAirspaceDataLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getRestrictiveairspaceGeometry = () => (dispatch) => {
  try {
    dispatch(setRestrictiveAirspaceGeometryLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setRestrictiveAirspaceGeometryData(response.data));
      dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    };

    apiCall(
      "GET",
      GET_RESTRICTIVE_AIRSPACE_GEOMETRY_URL,
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setRestrictiveAirspaceGeometryLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getENRTypeAirspaceGeometry =
  (type, version = null) =>
  (dispatch) => {
    try {
      dispatch(setFIRAirspaceGeometryLoadingRequest(true));

      const onSuccess = (response) => {
        if (type === "FIR") {
          dispatch(setFIRAirspaceGeometryData(response.data));
        }
        if (type === "CTRL") {
          dispatch(setCTRLAirspaceGeometryData(response.data));
        }
        dispatch(setFIRAirspaceGeometryLoadingRequest(false));
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setFIRAirspaceGeometryLoadingRequest(false));
      };

      apiCall(
        "GET",
        GET_FIR_AIRSPACE_GEOMETRY_URL(type, version),
        "",
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setFIRAirspaceGeometryLoadingRequest(false));
      toast.error(error.message);
    }
  };

export const getALLRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllRoute(true));

    const onSuccess = (response) => {
      dispatch(setAllRoutes(response.data));
      dispatch(setLoadingGetAllRoute(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllRoute(false));
    };

    apiCall("GET", GET_ALL_ROUTE_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllRoute(false));
    toast.error(error.message);
  }
};

export const getRouteData = (routeId) => (dispatch) => {
  try {
    dispatch(setLoadingGetRouteData(true));

    const onSuccess = (response) => {
      dispatch(setRouteData(response.data));
      dispatch(setLoadingGetRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetRouteData(false));
    };

    apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetRouteData(false));
    toast.error(error.message);
  }
};

export const getAreaNavigationalRouteData =
  (routeId, isLiveTracker = false, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingAreaNavigationalRouteData(true));

      const onSuccess = (response) => {
        dispatch(setLoadingAreaNavigationalRouteData(false));
        if (callback) {
          callback(response);
        }
        if (!isLiveTracker) {
          dispatch(setAreaNavigationalRouteData(response.data));
        } else {
          return response;
        }
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingAreaNavigationalRouteData(false));
      };

      apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
    } catch (error) {
      dispatch(setLoadingAreaNavigationalRouteData(false));
      toast.error(error.message);
    }
  };

export const getConventionalRouteData =
  (routeId, isLiveTracker = false, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingConventionalRouteData(true));

      const onSuccess = (response) => {
        dispatch(setLoadingConventionalRouteData(false));
        if (callback) {
          callback(response);
        }
        if (!isLiveTracker) {
          dispatch(setConventionalRouteData(response.data));
        } else {
          return response;
        }
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingConventionalRouteData(false));
      };

      apiCall("GET", GET_ROUTE_DATA_URL(routeId), "", onSuccess, onFailure);
    } catch (error) {
      dispatch(setLoadingConventionalRouteData(false));
      toast.error(error.message);
    }
  };

export const getAllNavaid = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllNavaid(true));

    const onSuccess = (response) => {
      dispatch(setAllNavaid(response.data));
      dispatch(setLoadingGetAllNavaid(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllNavaid(false));
    };

    apiCall("GET", GET_ALL_NAVID_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllNavaid(false));
    toast.error(error.message);
  }
};

export const getAllSignificantPoint = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllsignificantPoint(true));

    const onSuccess = (response) => {
      dispatch(setAllsignificantPoint(response.data));
      dispatch(setLoadingGetAllsignificantPoint(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllsignificantPoint(false));
    };

    apiCall(
      "GET",
      GET_ALL_SIGNIFICANT_POINT_URL(version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingGetAllsignificantPoint(false));
    toast.error(error.message);
  }
};

export const getAllTRA_TSA_ADIZ = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllTRA_TSA_ADIZ(true));

    const onSuccess = (response) => {
      dispatch(setAllTRA_TSA_ADIZ(response.data));
      dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    };

    apiCall("GET", GET_ALL_TRA_TSA_ADIZ_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllTRA_TSA_ADIZ(false));
    toast.error(error.message);
  }
};

export const getAllDPR = (version) => (dispatch) => {
  try {
    dispatch(setLoadingGetAllDPR(true));

    const onSuccess = (response) => {
      dispatch(setAllDPR(response.data));
      dispatch(setLoadingGetAllDPR(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAllDPR(false));
    };

    apiCall("GET", GET_ALL_DPR_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAllDPR(false));
    toast.error(error.message);
  }
};

export const getADIZGeoData = () => (dispatch) => {
  try {
    dispatch(setLoadingGetADIZGeoData(true));

    const onSuccess = (response) => {
      dispatch(setADIZGeoData(response.data));
      dispatch(setLoadingGetADIZGeoData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetADIZGeoData(false));
    };

    apiCall("GET", GET_ADIZ_GEODATA_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetADIZGeoData(false));
    toast.error(error.message);
  }
};

export const getRestrictiveAirspaceDetails =
  (airspace_id, isLivetracker = false, callback) =>
  (dispatch) => {
    try {
      dispatch(setLoadingRestrictiveAirspaceDetails(true));

      const onSuccess = (response) => {
        if (!isLivetracker) {
          dispatch(setRestrictiveAirspaceDetails(response.data));
        }
        dispatch(setLoadingRestrictiveAirspaceDetails(false));
        if (callback) callback(response.data);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingRestrictiveAirspaceDetails(false));
      };

      apiCall(
        "GET",
        GET_RESTRICTIVE_AIRSPACE_DETAILS_URL(airspace_id),
        "",
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setLoadingRestrictiveAirspaceDetails(false));
      toast.error(error.message);
    }
  };

export const getRestrictiveAirspaceDetailsSilent =
  (airspace_id, callback) => () => {
    try {
      const onSuccess = (response) => {
        if (callback) callback(response.data);
      };
      const onFailure = (error) => {
        toast.error(error.message);
      };

      apiCall(
        "GET",
        GET_RESTRICTIVE_AIRSPACE_DETAILS_URL(airspace_id),
        "",
        onSuccess,
        onFailure
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

export const getAllRNAVRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingRNAVRoutes(true));

    const onSuccess = (response) => {
      dispatch(setRNAVRoutesData(response.data));
      dispatch(setLoadingRNAVRoutes(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingRNAVRoutes(false));
    };

    apiCall(
      "GET",
      GET_ALL_RNAV_CNAV_ROUTES_URL("RNAV", version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingRNAVRoutes(false));
    toast.error(error.message);
  }
};

export const getAllCNAVRoutes = (version) => (dispatch) => {
  try {
    dispatch(setLoadingCNAVRoutes(true));

    const onSuccess = (response) => {
      dispatch(setCNAVRoutesData(response.data));
      dispatch(setLoadingCNAVRoutes(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingCNAVRoutes(false));
    };

    apiCall(
      "GET",
      GET_ALL_RNAV_CNAV_ROUTES_URL("CNAV", version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setLoadingCNAVRoutes(false));
    toast.error(error.message);
  }
};

export const getWaypointRouteData = (id) => (dispatch) => {
  try {
    dispatch(setLoadingWaypointRouteData(true));

    const onSuccess = (response) => {
      dispatch(setWaypointRouteData(response.data));
      dispatch(setLoadingWaypointRouteData(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingWaypointRouteData(false));
    };

    apiCall("GET", GET_WAYPOINT_ROUTE_DATA_URL(id), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingWaypointRouteData(false));
    toast.error(error.message);
  }
};

export const exportAirspaceGeoJSONData =
  (payload, token, callback) => (dispatch) => {
    try {
      dispatch(setLoadingEnrouteExportJson(true));

      const onSuccess = (blob, filename) => {
        const newBlob = new Blob([blob]);
        const blobUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute(
          "download",
          filename
            ? filename.split(".")[0].replace("-", "") + ".zip"
            : `enroute.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setLoadingEnrouteExportJson(false));
        if (callback) callback();
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setLoadingEnrouteExportJson(false));
      };

      downloadReadableStreamFile(
        "POST",
        EXPORT_AIRSPACE_GEOJSON,
        payload,
        onSuccess,
        onFailure,
        token
      );
    } catch (error) {
      dispatch(setLoadingEnrouteExportJson(false));
      toast.error(error.message);
    }
  };

export const getAirspaceUsePlanData = () => (dispatch) => {
  try {
    dispatch(setAirspaceUsePlanDataLoading(true));

    const onSuccess = (response) => {
      dispatch(
        setAirspaceUsePlanDates(
          orderBy(
            response.data,
            (obj) => {
              return obj?.effective_date;
            },
            ["desc"]
          )
        )
      );
      dispatch(setAirspaceUsePlanDataLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAirspaceUsePlanDataLoading(false));
    };

    apiCall("GET", AIRSPACE_USAGE_PLAN_URL, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setAirspaceUsePlanDataLoading(false));
    toast.error(error.message);
  }
};

export const getAirspaceUsePlanDataById = (planId) => (dispatch) => {
  try {
    dispatch(setAirspaceUsePlanDataByIdLoading(true));

    const onSuccess = (response) => {
      dispatch(setMilitryAirspaceUsePlanData(response.data?.military_airspace));
      dispatch(setRouteAirspaceUsePlanData(response.data?.route));
      dispatch(setAirspaceUsePlanDataByIdLoading(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAirspaceUsePlanDataByIdLoading(false));
    };

    apiCall(
      "GET",
      AIRSPACE_EFFECTIVE_DATE_URL(planId),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setAirspaceUsePlanDataByIdLoading(false));
    toast.error(error.message);
  }
};

export const calculateFlightPlanValidation =
  (payload, callback) => (dispatch) => {
    try {
      dispatch(setLoadingRoutePlanValidation(true));

      const onSuccess = (response) => {
        dispatch(setLoadingRoutePlanValidation(false));
        if (callback) callback(response);
      };
      const onFailure = (error) => {
        if (callback) callback(error);
        dispatch(setLoadingRoutePlanValidation(false));
      };

      apiCall(
        "POST",
        FLIGHT_PLAN_VALIDATION_DATA_URL,
        payload,
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setLoadingRoutePlanValidation(false));
      toast.error(error.message);
    }
  };

export const getAirspaceRouteBufferPoints =
  (payload, callback) => (dispatch) => {
    try {
      dispatch(setAirspaceRouteBufferPointsLoading(true));

      const onSuccess = (response) => {
        dispatch(setAirspaceRouteBufferPointsLoading(false));
        if (callback) callback(response?.data);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setAirspaceRouteBufferPointsLoading(false));
      };

      apiCall(
        "POST",
        ROUTE_PLAN_BUFFER_POINTS_DATA_URL,
        payload,
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setAirspaceRouteBufferPointsLoading(false));
      toast.error(error.message);
    }
  };

export const getArrivalDepartureAirportRoutePlan =
  (payload, callback) => (dispatch) => {
    try {
      dispatch(setArrivalDepartureAirportRoutePlanLoading(true));

      const onSuccess = (response) => {
        dispatch(setArrvalDepAirportRouteData(response?.data));
        dispatch(setArrivalDepartureAirportRoutePlanLoading(false));
        if (callback) callback(response?.data);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setArrivalDepartureAirportRoutePlanLoading(false));
      };

      apiCall(
        "POST",
        GET_ROUTE_PLAN_ARRIVAL_DEPARTURE_AIRPORT_URL,
        payload,
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setArrivalDepartureAirportRoutePlanLoading(false));
      toast.error(error.message);
    }
  };

export const getRouteThroughPointRP =
  (pointName, designator, callback) => (dispatch) => {
    try {
      dispatch(setRouteThroughPointRPLoading(true));

      const onSuccess = (response) => {
        dispatch(setRouteThroughPointRPData(response?.data));
        if (callback) callback(response);
        setTimeout(() => {
          dispatch(setRouteThroughPointRPLoading(false));
        }, 1500);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setRouteThroughPointRPLoading(false));
      };

      apiCall(
        "GET",
        GET_ROUTE_THROUGH_POINT_ROUTE_PLAN(pointName, designator),
        "",
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setRouteThroughPointRPLoading(false));
      toast.error(error.message);
    }
  };

export const getENRAirspaceGeometry = (version, callback) => (dispatch) => {
  try {
    dispatch(setENRAirspaceGeometryLoading(true));

    const onSuccess = (response) => {
      dispatch(setENRAirspaceGeometryOptions(response?.data));
      dispatch(setENRAirspaceGeometryLoading(false));
      if (callback) callback(response?.data);
    };
    const onFailure = (error) => {
      dispatch(setENRAirspaceGeometryOptions(null));
      toast.error(error.message);
      dispatch(setENRAirspaceGeometryLoading(false));
    };

    apiCall(
      "GET",
      GET_ENR_AIRSPACES_GEOMETRY_URL(version),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setENRAirspaceGeometryOptions(null));
    dispatch(setENRAirspaceGeometryLoading(false));
    toast.error(error.message);
  }
};

export const getENRAirspaceGeometryFetch =
  (payload, catType, callback) => (dispatch) => {
    try {
      dispatch(setENRAirspaceGeometryFetchLoading(true));

      const onSuccess = (response) => {
        let data = [];
        if (!isEmpty(response?.data)) {
          data = response?.data.map((d) => {
            return {
              ...d,
              catType: d.type,
              type: "Feature",
              properties: { name: d.id },
            };
          });
          if (catType === "PDR") {
            dispatch(setENRPDRCoordsData(data));
          } else if (catType === "TMA") {
            dispatch(setENRTMACoordsData(data));
          } else if (catType === "MTR") {
            dispatch(setENRMTRCoordsData(data));
          } else if (catType === "OTHER") {
            dispatch(setENROTHERCoordsData(data));
          } else if (catType === "TRATSA") {
            dispatch(setENRTRATSACoordsData(data));
          } else if (catType === "ADIZ") {
            dispatch(setENRADIZRCoordsData(data));
          } else if (catType === "ad217") {
            dispatch(setENRAD217CoordsData(data));
          }
        }
        dispatch(setENRAirspaceGeometryFetchLoading(false));
        if (callback) callback(response?.data);
      };
      const onFailure = (error) => {
        toast.error(error.message);
        dispatch(setENRAirspaceGeometryFetchLoading(false));
      };

      apiCall(
        "POST",
        POST_ENR_AIRSPACES_GEOMETRY_FETCH_URL,
        payload,
        onSuccess,
        onFailure
      );
    } catch (error) {
      dispatch(setENRAirspaceGeometryFetchLoading(false));
      toast.error(error.message);
    }
  };

export const getENRAirspaceGeometryInfo = (arpid, callback) => (dispatch) => {
  try {
    dispatch(setENRAirspaceGeometryInfoLoading(true));

    const onSuccess = (response) => {
      dispatch(setENRAirspaceGeometryInfoData(response?.data));
      dispatch(setENRAirspaceGeometryInfoLoading(false));
      if (callback) callback(response?.data);
    };
    const onFailure = (error) => {
      dispatch(setENRAirspaceGeometryInfoData(null));
      toast.error(error.message);
      dispatch(setENRAirspaceGeometryInfoLoading(false));
    };

    apiCall(
      "GET",
      GET_ENR_AIRSPACES_GEOMETRY_INFO_URL(arpid),
      "",
      onSuccess,
      onFailure
    );
  } catch (error) {
    dispatch(setENRAirspaceGeometryInfoData(null));
    dispatch(setENRAirspaceGeometryInfoLoading(false));
    toast.error(error.message);
  }
};
