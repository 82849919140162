import { Navigate, useLocation } from "react-router-dom";
import { getStorageValue } from "@utils/localStorage";
import { ACCESS_TOKEN } from "@constants/constant";

const PublicRoute = ({ children }) => {
  const location = useLocation();
  const accessToken = getStorageValue(ACCESS_TOKEN);

  if (accessToken) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return children;
};

export default PublicRoute;
