import { combineReducers } from "@reduxjs/toolkit";
import airspace from "./airspace";
import userSession from "./userSession";
import amdb from "./amdb";
import etod from "./etod";
import aixm from "./aixm";
import survey from "./survey";
import eChart from "./electronicChart";
import airport from "./airport";

const rootReducer = combineReducers({
  airport,
  airspace,
  userSession,
  amdb,
  etod,
  aixm,
  survey,
  eChart,
});

export default rootReducer;
