import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CoordinateInput from "react-coordinate-input";
import { MapContainer, ZoomControl, Marker } from "react-leaflet";
import { useFormik } from "formik";
import {
  Stack,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Box,
  IconButton,
} from "@mui/material";
import MuiSelect from "@mui/material/Select";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { RightArrowIcon } from "@assets/svg/RightArrow";
import {
  ACCESS_TOKEN,
  defaultLocationCenter,
  mapStyles,
} from "@constants/constant";
import {
  createAirportValidationSchema,
  icaoPrefixTypes,
} from "@constants/ad2Data";
import Header from "@components/Header";
import GoogleMapLayer from "@components/map/GoogleMapLayer";
import ControlMapCenter from "@components/map/ControlMapCenter";
import { PrimaryButton, OutlinedButton } from "@components/button";
import { postCreateNewAirport } from "@store/airport/actions";
import { getAixmDataVersion } from "@store/aixm/actions";
import { styles as etodStyles } from "@styles/etodStyles";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";
import { LocationImgIcon } from "@utils/mapIcon";

export default function CreateNewAirport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coordInputRef = useRef();
  const isLoadingPostCreateNewAirport = useSelector(
    (state) => state.airport.isLoadingPostCreateNewAirport
  );
  const { isLoadingGetAixmDataVersion, aixmDataVersions } = useSelector(
    (state) => state.aixm
  );
  const [airportLocation, setAirportLocation] = useState(null);
  const createAirportForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      icaoPrefix: "",
      icao: "",
      name: "",
      latitude: "",
      longitude: "",
      effective_date: "",
      published_date: "",
    },
    validationSchema: createAirportValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          postCreateNewAirport(
            { ...values, icao: `${values.icaoPrefix}${values.icao}` },
            token,
            handleCallback
          )
        );
      });
    },
  });
  const formValues = createAirportForm.values;

  useEffect(() => {
    dispatch(getAixmDataVersion());
  }, []); // eslint-disable-line

  const handleSeeOnMap = () => {
    if (formValues && formValues.latitude && formValues.longitude) {
      setAirportLocation([formValues.latitude, formValues.longitude]);
    }
  };

  const handleCallback = () => {
    createAirportForm.resetForm();
    createAirportForm.setSubmitting(false);
  };

  const handleEffectiveDateChange = (event) => {
    const option = aixmDataVersions.find(
      (d) => event.target.value === d.effective_date
    );
    createAirportForm.handleChange(event);
    createAirportForm.setFieldValue("published_date", option.published_date);
  };

  const handlePublishedDateChange = (event) => {
    const option = aixmDataVersions.find(
      (d) => event.target.value === d.published_date
    );
    createAirportForm.handleChange(event);
    createAirportForm.setFieldValue("effective_date", option.effective_date);
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div className="main-content">
        <div className="main" style={{ width: "100%" }}>
          <div className="content-area">
            <div style={etodStyles.etodContent}>
              <Box sx={styles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  color="black"
                  sx={{ fontWeight: 500, fontSize: 16, pl:1 }}
                >
                  back to selection
                </Typography>
              </Box>
              <div style={{ height: "calc(100% - 95px)", marginTop: "16px" }}>
                <Stack direction="row" sx={etodStyles.etodUserlabelMain}>
                  <Typography sx={{ color: "#42444A",fontWeight:700, fontSize:14 }}>
                    CREATE NEW AIRPORT
                  </Typography>
                </Stack>
                <Box sx={etodStyles.createRwyForm}>
                  <form style={{ padding: "16px 24px " }}>
                    <Stack direction="row" sx={{ mb: 2 }}>
                      <FormControl
                        fullWidth
                        sx={{ width: 200, mr: "30px" }}
                        error={
                          createAirportForm.touched.icao &&
                          Boolean(createAirportForm.errors.icao)
                        }
                      >
                        <InputLabel id="icaoPrefix">ICAO Prefix</InputLabel>
                        <MuiSelect
                          id="icaoPrefix"
                          name="icaoPrefix"
                          label="icaoPrefix"
                          value={createAirportForm.values.icaoPrefix}
                          onChange={createAirportForm.handleChange}
                        >
                          {icaoPrefixTypes.map((types) => (
                            <MenuItem key={types.id} value={types.value}>
                              {types.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                        <FormHelperText>
                          {createAirportForm.touched.icaoPrefix &&
                            createAirportForm.errors.icaoPrefix}
                        </FormHelperText>
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="icao"
                        name="icao"
                        label="ICAO Code"
                        inputProps={{ maxLength: 2 }}
                        value={createAirportForm.values.icao}
                        onChange={createAirportForm.handleChange}
                        error={
                          createAirportForm.touched.icao &&
                          Boolean(createAirportForm.errors.icao)
                        }
                        helperText={
                          createAirportForm.touched.icao &&
                          createAirportForm.errors.icao
                        }
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="name"
                      name="name"
                      label="Airport Name"
                      value={createAirportForm.values.name}
                      onChange={createAirportForm.handleChange}
                      error={
                        createAirportForm.touched.name &&
                        Boolean(createAirportForm.errors.name)
                      }
                      helperText={
                        createAirportForm.touched.name &&
                        createAirportForm.errors.name
                      }
                      sx={{ mb: 1 }}
                    />
                    <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
                      <CoordinateInput
                        ref={coordInputRef}
                        style={{
                          padding: 10,
                          fontSize: 20,
                          marginRight: "30px",
                          width: "100%",
                        }}
                        onChange={(_, { dd }) => {
                          createAirportForm.setFieldValue("latitude", dd[0]);
                          createAirportForm.setFieldValue("longitude", dd[1]);
                        }}
                      />
                      <OutlinedButton
                        label="See on Map"
                        disabled={
                          !formValues?.latitude || !formValues?.longitude
                        }
                        endIcon={
                          <RightArrowIcon
                            disabled={
                              !formValues?.latitude || !formValues?.longitude
                            }
                          />
                        }
                        onClick={() => handleSeeOnMap()}
                        sx={{ width: 250 }}
                      />
                    </Stack>
                    <FormHelperText>
                      Add latitude longitude format - ddmmss N/S dddmmss W/E
                    </FormHelperText>
                    <FormHelperText sx={{ mb: 1 }}>
                      Ex: 04° 08′ 15″ N 162° 03′ 42″ E
                    </FormHelperText>
                    <FormHelperText
                      sx={{ mb: 1 }}
                      error={
                        createAirportForm.touched.latitude &&
                        Boolean(createAirportForm.errors.latitude)
                      }
                    >
                      {createAirportForm.touched.latitude &&
                        createAirportForm.errors.latitude}
                    </FormHelperText>
                    <FormControl
                      fullWidth
                      sx={{ mb: 2, mt: 2 }}
                      error={
                        createAirportForm.touched.effective_date &&
                        Boolean(createAirportForm.errors.effective_date)
                      }
                    >
                      <InputLabel id="effective_date">
                        Effective Date
                      </InputLabel>
                      <MuiSelect
                        labelId="effective_date"
                        id="effective_date"
                        name="effective_date"
                        label="effective_date"
                        value={createAirportForm.values.effective_date}
                        onChange={handleEffectiveDateChange}
                        disabled={isLoadingGetAixmDataVersion}
                      >
                        {aixmDataVersions.map((date) => (
                          <MenuItem key={date.id} value={date.effective_date}>
                            {date.effective_date}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                      <FormHelperText>
                        {createAirportForm.touched.effective_date &&
                          createAirportForm.errors.effective_date}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      sx={{ mb: 2 }}
                      error={
                        createAirportForm.touched.published_date &&
                        Boolean(createAirportForm.errors.published_date)
                      }
                    >
                      <InputLabel id="published_date">
                        Published Date
                      </InputLabel>
                      <MuiSelect
                        labelId="published_date"
                        id="published_date"
                        name="published_date"
                        label="published_date"
                        value={createAirportForm.values.published_date}
                        onChange={handlePublishedDateChange}
                        disabled={isLoadingGetAixmDataVersion}
                      >
                        {aixmDataVersions.map((date) => (
                          <MenuItem key={date.id} value={date.published_date}>
                            {date.published_date}
                          </MenuItem>
                        ))}
                      </MuiSelect>
                      <FormHelperText>
                        {createAirportForm.touched.published_date &&
                          createAirportForm.errors.published_date}
                      </FormHelperText>
                    </FormControl>
                  </form>
                </Box>
              </div>
              <Box sx={etodStyles.proceedBtnMain}>
                <PrimaryButton
                  label="Submit"
                  onClick={() => createAirportForm.handleSubmit()}
                  isLoading={isLoadingPostCreateNewAirport}
                  disabled={isLoadingPostCreateNewAirport}
                  sx={{ width: 150 }}
                />
              </Box>
            </div>
            <div style={{ flex: 1, borderRadius: 5, overflow: "auto" }}>
              <MapContainer
                center={defaultLocationCenter}
                zoom={4}
                zoomControl={false}
                style={{ width: "100%", height: "100%" }}
              >
                <ControlMapCenter
                  airportLocation={
                    airportLocation
                      ? airportLocation
                      : [defaultLocationCenter.lat, defaultLocationCenter.lng]
                  }
                  zoomLevel={airportLocation ? 14 : 4}
                />
                {airportLocation && (
                  <Marker icon={LocationImgIcon} position={airportLocation} />
                )}
                <ZoomControl position="bottomright" />
                <GoogleMapLayer maptype={mapStyles.hybrid} />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
