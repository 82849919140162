import { Box, Alert, Typography, Snackbar } from "@mui/material";
import { theme } from "@styles/theme";

const RoutePlanFlightValidationAlert = ({
  openRoutePlanMssgDialog,
  arpFlightPlanErrStatus,
  arpFlightPlanSuccessStatus,
  arpFlightPlanErrMssg,
  arpFlightPlanArrivalTime,
  handleCloseRoutePlanMssg,
}) => {
  return (
    <Snackbar
      open={openRoutePlanMssgDialog}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{
        bottom: "24px !important",
        left: "calc(50% + 150px) !important",
      }}
    >
      <Alert
        icon={false}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          handleCloseRoutePlanMssg(false);
        }}
        severity={arpFlightPlanErrStatus ? "error" : "success"}
        sx={{
          position: "relative",
          width: 360,
          minHeight: 100,
          backgroundColor: arpFlightPlanErrStatus
            ? theme.palette.red.main
            : arpFlightPlanSuccessStatus
            ? theme.palette.green.dark
            : theme.palette.white.main,
          paddingTop: 0,
          paddingBottom: 0,
          boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
          ".MuiAlert-action": {
            ".MuiIconButton-root": {
              svg: {
                fill: "#fff !important",
              },
            },
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: theme.palette.white.main,
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {arpFlightPlanErrMssg
              ? "Alert! We regret it is a NO-GO ROUTE"
              : "Success! It is a GO ROUTE"}
          </Typography>
          <Typography sx={{ color: "#F4F4F4", fontSize: 12, fontWeight: 500 }}>
            {arpFlightPlanErrMssg
              ? `The selected route is interfering with one or more restrcited Airspaces.`
              : `There are no restricted airspaces or route interferences as per the relevant AUP`}
          </Typography>
          <Typography
            sx={{ color: "#F4F4F4", fontSize: 12, mt: 1, fontWeight: 600 }}
          >
            Arrival Time at ENR Endpoint :{" "}
            {arpFlightPlanArrivalTime
              ? arpFlightPlanArrivalTime.replace(/.{2}(?!$)/g, "$&:")
              : "-"}
          </Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default RoutePlanFlightValidationAlert;
