import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function Apron({ selctedAirport, activeCategory, apron }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={7}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Composition</StyledTableCell>
              <StyledTableCell>Class PCN</StyledTableCell>
              <StyledTableCell>Pavement Type PCN</StyledTableCell>
              <StyledTableCell>Pavement Subgrade PCN</StyledTableCell>
              <StyledTableCell>Max Tyre Pressure</StyledTableCell>
              <StyledTableCell>Evaluation Method PCN</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEmpty(apron) ? (
              apron.map((apr, index) => (
                <TableRow sx={styles.tableRowLastBorderNone} key={index}>
                  <StyledTableCell>
                    {apr.name ? apr.name : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.composition ? apr.composition : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.classPCN ? apr.classPCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.pavementTypePCN ? apr.pavementTypePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.pavementSubgradePCN ? apr.pavementSubgradePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.maxTyrePressurePCN ? apr.maxTyrePressurePCN : "NIL"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {apr.evaluationMethodPCN ? apr.evaluationMethodPCN : "NIL"}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Refer Aircraft Parking / Docking Charts
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Apron;
