import React from "react";
import { FaLock } from "react-icons/fa";
import { Grid, Box, Typography } from "@mui/material";
import { styles } from "@styles/homeStyles";

function MenuItem({ icon, title, description, handleOnClick, isPrivate }) {
  return (
    <Grid
      item
      xl={3}
      lg={3}
      md={3}
      sm={6}
      xs={12}
      className="grid-col"
      onClick={handleOnClick}
      sx={{
        maxWidth: "350px !important",
        position: "relative",
        overflow: "auto",
      }}
    >
      <Box elevation={0} sx={styles.menucontainer}>
        {icon}
        <Typography className="main-title">{title}</Typography>
        <Typography className="description">{description}</Typography>
      </Box>
      {isPrivate && (
        <Box className="extra-data">
          <FaLock />
        </Box>
      )}
    </Grid>
  );
}

export default MenuItem;
