import React, { Fragment, useState } from "react";
import { point } from "leaflet";
import { Polyline, Marker, Tooltip, Popup } from "react-leaflet";
import { find, isEmpty } from "lodash";
import { IconButton, Typography } from "@mui/material";
import UndoIcon from "@assets/svg/UndoIcon";
import { routePlanNavtype } from "@constants/airspaceData";
import { getRouteWayPointIcon } from "@utils/routeWayPointIcon";
import { RoutePlanPoint } from "@utils/mapIcon";

const RenderSelectedAirportRoutePlan = ({
  routeData,
  arpFlightPlanSuccessStatus,
  arpFlightPlanErrStatus,
  arpFlightPlanErrorsRoute,
  handleUndoSelectedRoutePoint,
  handleAirspaceUsePlanDetails,
}) => {
  const [lineWidth, setLineWidth] = useState(2);

  const handlePolylineOver = () => {
    setLineWidth(4);
  };

  const handlePolylineOut = () => {
    setLineWidth(2);
  };

  return (
    <Fragment>
      {routeData &&
        routeData.map((data, i) => {
          if (i === 0) return null;
          const position = [
            [data.lat, data.lng],
            [routeData[i - 1].lat, routeData[i - 1].lng],
          ];
          const routeErrorSegment = find(arpFlightPlanErrorsRoute, [
            "id",
            data.new_RS_id,
          ]);

          return (
            <Polyline
              key={i}
              pane="tooltipPane"
              positions={position}
              pathOptions={{
                color:
                  arpFlightPlanErrStatus &&
                  !isEmpty(arpFlightPlanErrorsRoute) &&
                  !isEmpty(routeErrorSegment)
                    ? "#EB5757"
                    : arpFlightPlanSuccessStatus
                    ? "#27AE60"
                    : "#2F80ED",
                weight: lineWidth,
              }}
              eventHandlers={{
                mouseover: () => handlePolylineOver(),
                mouseout: () => handlePolylineOut(),
              }}
            >
              {!isEmpty(routeErrorSegment) && (
                <Popup
                  className="route-plan-airspace"
                  offset={point([-209, 50])}
                  minWidth={234}
                >
                  <Typography variant="span">
                    {routePlanNavtype[data.navigationType]}: {data.routeName}
                  </Typography>
                  <br />
                  <span
                    style={{
                      color: "#fff",
                      fontSize: 12,
                      fontWeight: 400,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {routeErrorSegment?.msg}
                  </span>
                  {/* <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    sx={{ mt: 1, pt: 1, borderTop: "0.1px solid #fff" }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        color: "#fff",
                        textTransform: "none",
                        fontSize: 12,
                        fontWeight: 600,
                        p: 0,
                      }}
                      // onClick={() =>
                      //   handleAirspaceUsePlanDetails(
                      //     routePlanNavtype[data.navigationType]
                      //   )
                      // }
                    >
                      View AUP
                    </Button>
                  </Stack> */}
                </Popup>
              )}
            </Polyline>
          );
        })}
      {routeData.map((d, i) => {
        return (
          <Marker
            key={i}
            pane="popupPane"
            icon={
              i === 0 ? RoutePlanPoint : getRouteWayPointIcon(d.reportingATC)
            }
            position={[d.lat, d.lng]}
          >
            {routeData?.length - 1 === i && (
              <Tooltip
                className="route-plan-undo"
                opacity={1}
                permanent
                direction="top"
                interactive
              >
                <IconButton
                  sx={{
                    height: "inherit",
                    width: "inherit",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  aria-label="undo"
                  onClick={() => {
                    handleUndoSelectedRoutePoint();
                  }}
                >
                  <UndoIcon width={16} height={16} />
                </IconButton>
              </Tooltip>
            )}
          </Marker>
        );
      })}
    </Fragment>
  );
};

export default RenderSelectedAirportRoutePlan;
