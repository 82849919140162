import React, { Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FeatureGroup, Polyline, Popup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { Box, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import { editDeleteEchartPolyline } from "@store/electronicChart/actions";
import { getAsyncStorageValue } from "@utils/localStorage";
import { theme } from "@styles/theme";
import { LightButton } from "../button";

export default function EchartDrawControl({
  selctedAirport,
  editableLayer,
  handleCallback,
  handleonMountedControl,
  handleEditShap,
}) {
  const dispatch = useDispatch();
  const map = useMap();
  const featureGroupRef = useRef();
  const popupElRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const isLoadingEditDeletePolyline = useSelector(
    (state) => state.eChart.isLoadingEditDeletePolyline
  );
  const isAdmin = user?.role === roles.admin;
  const [isOpenEditConfirmDialog, setOpenEditConfirmDialog] = useState(false);
  const [editedCoords, setEditedCoords] = useState([]);
  const coordinateMap = editableLayer
    ? editableLayer.geometry_json.coordinates
    : [];

  const onEdited = (e) => {
    let coords = [];
    e.layers.eachLayer(function (layer) {
      const geoJOSN = layer.toGeoJSON();
      coords = geoJOSN.geometry.coordinates;
    });

    setEditedCoords(coords);
    setOpenEditConfirmDialog(true);
  };

  const handleSaveEditDialog = () => {
    const finalEditCoords = editedCoords.map((coord) => {
      return {
        lng: coord[0],
        lat: coord[1],
      };
    });
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        editDeleteEchartPolyline(
          "PUT",
          {
            coord_list: finalEditCoords,
          },
          editableLayer?.id,
          selctedAirport?.id,
          token,
          handleCloseEditDialog
        )
      );
    });
  };

  const handleCloseEditDialog = () => {
    featureGroupRef.current.clearLayers();
    handleCallback();
    setOpenEditConfirmDialog(false);
  };

  const handlePolygonLine = (data) => {
    handleEditShap(data);
    if (!popupElRef.current || !map) return;
    map.closePopup();
  };

  return (
    <Fragment>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onEdited={onEdited}
          onMounted={(e) => handleonMountedControl(e)}
          draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: false,
          }}
          edit={{
            edit: Boolean(!isEmpty(editableLayer)),
            remove: false,
          }}
        />
        {coordinateMap &&
          coordinateMap.map((coord, i) => {
            return (
              <Polyline
                key={`${editableLayer.id}_${i}`}
                positions={coord}
                pathOptions={{
                  color: theme.palette.lightgreen.main,
                  weight: 3,
                  opacity: 1,
                }}
              >
                <Popup ref={popupElRef} className="polygon-popup">
                  <Box
                    sx={{
                      borderBottom: "0.5px solid #42444A",
                      mb: 0.5,
                      p: "0px 0px 12px 10px",
                      fontSize: "14px",
                      color: "#333333",
                    }}
                  >
                    <Box>
                      <Typography variant="span" sx={{ pr: 1.5 }}>
                        ID:
                      </Typography>
                      <Typography variant="span" sx={{ fontWeight: 500 }}>
                        {editableLayer.id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="span" sx={{ pr: 1.5 }}>
                        Layer:
                      </Typography>
                      <Typography variant="span" sx={{ fontWeight: 500 }}>
                        {editableLayer.layer}
                      </Typography>
                    </Box>
                  </Box>
                  {isAdmin && (
                    <Stack
                      sx={{ width: 200 }}
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <LightButton
                        label="Modify Line"
                        onClick={() => handlePolygonLine(editableLayer)}
                        sx={{ p: 0, mt: 1, mb: 1, mr: 7 }}
                      />
                    </Stack>
                  )}
                </Popup>
              </Polyline>
            );
          })}
      </FeatureGroup>
      {isOpenEditConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenEditConfirmDialog}
          title="Edit"
          content="Are you sure you want to edit this polyline?"
          isLoading={isLoadingEditDeletePolyline}
          isDisabled={isLoadingEditDeletePolyline}
          handleSave={handleSaveEditDialog}
          handleCancel={handleCloseEditDialog}
        />
      )}
    </Fragment>
  );
}
