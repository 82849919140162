import { toast } from "react-toastify";
import {
  GET_ALL_AIXM_AIRPORT_URL,
  EXPORT_AIXM_SHEET,
  GET_AIXM_DATA_VERSION,
  AIXM_GENERAL_0TO1_2TO4_URL,
  AIXM_AD1_LEFTOUTS_URL,
  AIXM_AD1_3_URL,
  AIXM_AD1_5_URL,
  AIXM_ENROUTE_URL,
} from "@constants/urls";
import {
  apiCall,
  downloadReadableStreamFile,
  Timeout,
  headers,
} from "@utils/connect";
import {
  setAirportLoadingRequest,
  setExportSheetRequest,
  setAllAirport,
  setLoadingGetAixmDataVersion,
  setAixmDataVersions,
  setGeneralLoadingRequest,
  setAllGeneral,
  setAD1Aerodrome,
  setLoadingAD1Aerodrome,
  setEnrouteLoadingRequest,
  setAllEnroute,
  setAD1_3Aerodrome,
  setAD1_5Aerodrome,
  setLoadingAD1_3Aerodrome,
  setLoadingAD1_5Aerodrome,
} from ".";
import { merge } from "lodash";

export const getAllAIXMAirport = (version, callback) => (dispatch) => {
  try {
    dispatch(setAirportLoadingRequest(true));

    const onSuccess = (response) => {
      dispatch(setAllAirport(response.data));
      dispatch(setAirportLoadingRequest(false));
      if (callback) callback(response.data);
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setAirportLoadingRequest(false));
    };

    apiCall("GET", GET_ALL_AIXM_AIRPORT_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setAirportLoadingRequest(false));
    toast.error(error.message);
  }
};

export const getAixmDataVersion = (callback) => (dispatch) => {
  try {
    dispatch(setLoadingGetAixmDataVersion(true));

    const onSuccess = (response) => {
      dispatch(setAixmDataVersions(response.data));
      dispatch(setLoadingGetAixmDataVersion(false));
      if (callback) callback(response.data);
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingGetAixmDataVersion(false));
    };

    apiCall("GET", GET_AIXM_DATA_VERSION, "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingGetAixmDataVersion(false));
    toast.error(error.message);
  }
};

export const exportAixmSheet = (body, token) => (dispatch) => {
  try {
    dispatch(setExportSheetRequest(true));

    const onSuccess = (blob, filename) => {
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute(
        "download",
        filename
          ? filename.split(".")[0].replace("-", "") + ".xls"
          : "AIXMReport.xls"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
      dispatch(setExportSheetRequest(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setExportSheetRequest(false));
    };

    downloadReadableStreamFile(
      "GET",
      EXPORT_AIXM_SHEET,
      body,
      onSuccess,
      onFailure,
      token
    );
  } catch (error) {
    dispatch(setExportSheetRequest(false));
    toast.error(error.message);
  }
};

export const getAllGeneralFeatureData = (data, version) => (dispatch) => {
  dispatch(setGeneralLoadingRequest(true));
  const promise = data.map((genIndex) => {
    return fetch(
      AIXM_GENERAL_0TO1_2TO4_URL(genIndex, version),
      {
        method: "GET",
        headers: headers,
        signal: Timeout(150).signal,
      },
      500
    ).then((resp) => resp.json().then((responseJson) => responseJson.data));
  });

  Promise.all(promise)
    .then((responseJsonData) => {
      dispatch(setAllGeneral(responseJsonData));
      dispatch(setGeneralLoadingRequest(false));
    })
    .catch(() => {
      dispatch(setGeneralLoadingRequest(false));
      toast.error("Something went wrong!");
    });
};

export const getAD1AerodromeData = (version) => (dispatch) => {
  try {
    dispatch(setLoadingAD1Aerodrome(true));

    const onSuccess = (response) => {
      dispatch(setAD1Aerodrome(response.data));
      dispatch(setLoadingAD1Aerodrome(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAD1Aerodrome(false));
    };

    apiCall("GET", AIXM_AD1_LEFTOUTS_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAD1Aerodrome(false));
    toast.error(error.message);
  }
};

export const getAD1_3AerodromeData = (version) => (dispatch) => {
  try {
    dispatch(setLoadingAD1_3Aerodrome(true));

    const onSuccess = (response) => {
      dispatch(setAD1_3Aerodrome(response.data));
      dispatch(setLoadingAD1_3Aerodrome(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAD1_3Aerodrome(false));
    };

    apiCall("GET", AIXM_AD1_3_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAD1_3Aerodrome(false));
    toast.error(error.message);
  }
};

export const getAD1_5AerodromeData = (version) => (dispatch) => {
  try {
    dispatch(setLoadingAD1_5Aerodrome(true));

    const onSuccess = (response) => {
      dispatch(setAD1_5Aerodrome(response.data));
      dispatch(setLoadingAD1_5Aerodrome(false));
    };
    const onFailure = (error) => {
      toast.error(error.message);
      dispatch(setLoadingAD1_5Aerodrome(false));
    };

    apiCall("GET", AIXM_AD1_5_URL(version), "", onSuccess, onFailure);
  } catch (error) {
    dispatch(setLoadingAD1_5Aerodrome(false));
    toast.error(error.message);
  }
};

export const getEnrouteFeatureData = (data, version) => (dispatch) => {
  dispatch(setEnrouteLoadingRequest(true));
  const promise = data.map((enrIndex) => {
    return fetch(
      AIXM_ENROUTE_URL(enrIndex, version),
      {
        method: "GET",
        headers: headers,
        signal: Timeout(150).signal,
      },
      500
    ).then((resp) => resp.json().then((responseJson) => responseJson.data));
  });

  Promise.all(promise)
    .then(([responseJsonData1, responseJsonData2]) => {
      dispatch(setAllEnroute(merge(responseJsonData1, responseJsonData2)));
      dispatch(setEnrouteLoadingRequest(false));
    })
    .catch(() => {
      dispatch(setEnrouteLoadingRequest(false));
      toast.error("Something went wrong!");
    });
};
