import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { OutlinedButton, PrimaryButton } from "@components/button";
import { styles } from "@styles/aixmStyles";
import { theme } from "@styles/theme";
import { getStorageValue } from "@utils/localStorage";

export default function AixmExportdataModal({
  isOpen,
  loading,
  disabled,
  handleCancel,
  handleExport,
  filtertype,
  title = "Export XML",
  description = "Download any xml file from the below",
}) {
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const [exportSelectedValue, setExportSelectedValue] =
    useState("selectedData");

  const handleChangeExportData = (event) => {
    setExportSelectedValue(event.target.value);
  };

  const handleCancelExportModal = () => {
    handleCancel();
  };

  const handleSuccessExportModal = () => {
    handleExport(exportSelectedValue);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.exportDataModal}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={styles.exportDataModalTitle}
        >
          {title}
        </Typography>
        <Typography
          id="modal-modal-title"
          component="span"
          sx={styles.exportDataModalDesc}
        >
          {description}
        </Typography>
        <Box sx={{ mt: "31px", mb: "56px" }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={exportSelectedValue}
              onChange={handleChangeExportData}
            >
              <FormControlLabel
                sx={{
                  color:
                    exportSelectedValue === "selectedData"
                      ? " #262261"
                      : theme.palette.grey.light,
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "21px",
                }}
                value="selectedData"
                control={<Radio size="small" sx={styles.downloadData} />}
                label="Download  Selected Data"
              />
              <FormControlLabel
                value="allData"
                sx={{
                  color:
                    exportSelectedValue === "allData"
                      ? " #262261"
                      : theme.palette.grey.light,
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "21px",
                }}
                control={<Radio size="small" sx={styles.downloadData} />}
                label={`Download Complete ${filtertype} Data`}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={styles.exportDataModalExportBtn}>
          <PrimaryButton
            label="Export"
            sx={{ width: 98, p: "2.75px 16px", mr: 3 }}
            isLoading={loading}
            disabled={
              exportSelectedValue === "" ||
              !currentUser?.user_access?.export ||
              !accessToken
            }
            onClick={handleSuccessExportModal}
          />
          <OutlinedButton
            label="Cancel"
            disabled={disabled}
            sx={{ width: 98, p: "1.75px 16px" }}
            onClick={handleCancelExportModal}
          />
        </Box>
      </Box>
    </Modal>
  );
}
