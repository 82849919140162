import { isEmpty } from "lodash";
import { airport } from "./slice";

export const {
  setLoadingAllAirports,
  setAllAirports,
  setLoadingPostCreateNewAirport,
  setSelctedAirportFormCategories,
  setLoadingAirportStatusChange,
  setExportXMLAD2Loading,
  setLoadingAdRunwayInfo,
  setAdRunwayInfo,
  setLoadingAAIAirport,
  setAAIAirport,
  setAAIAD212RWYDetail,
  setAAIMuiAirport,
  setSelectedAAIAirport,
  setLoadingAddAAIAirportDetail,
  setLoadingAddAAIAirportDetailUpload,
  setLoadingDeleteAD2AAIAirport,
  setLoadingAD28AAIAirportDetail,
  setAllAirportsCharts,
} = airport.actions;

export default airport.reducer;

export const adRunwayInfoOptions = (state) => {
  const adRunwayInfo = state.airport.adRunwayInfo;
  if (!isEmpty(adRunwayInfo)) {
    if (adRunwayInfo.Runway_Direction_List.length > 0) {
      return adRunwayInfo.Runway_Direction_List.map((data) => {
        return {
          id: data.id,
          label: data.rwy_dir_designator,
          value: data.id,
        };
      });
    }
  }

  return [];
};
