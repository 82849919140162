import React from 'react';
import { styles } from '@styles/mapStyles';

function PopupItem({ children }) {
  return (
    <div style={styles.popItem}>
      {children}
    </div>
  );
};

export default PopupItem;