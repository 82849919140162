import React, { Fragment, useEffect, useRef, useState } from "react";
import { chain, flatten, split } from "lodash";
import { useSelector } from "react-redux";
import { Polygon, Popup } from "react-leaflet";
import {Box,Stack} from "@mui/material";
import { LightButton } from "@components/button";
import { areaHighLightColor } from "@constants/colors";
import { modifyCategories } from "@constants/amdbData";
import { roles } from "@constants/constant";
import { styles } from "@styles/amdbStyles";

export default function RenderTwyShape({
  isEditShape,
  selectedFeatures,
  twyElementList,
  handleEditShap,
  handleDeleteShape,
}) {
  const popupElRef = useRef(null);
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const [twyShapes, setTwyShapes] = useState([]);
  const [areaColor, setAreaColor] = useState({});
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    const areaColorT = {};
    selectedFeatures.forEach((a) => {
      const splitArray = split(a, "__");
      areaColorT[splitArray[0]] = splitArray[1];
    });

    const twyList = chain(twyElementList)
      .filter((twyObj) => {
        const twyKey = `twy_${twyObj.twy}`;
        return selectedFeatures.includes(`${twyKey}__${areaColorT[twyKey]}`);
      })
      .value();

    setAreaColor(areaColorT);
    setTwyShapes(twyList);
  }, [selectedFeatures]); // eslint-disable-line

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = (e) => {
    e.target.bringToBack();
    setSelectedOverE(null);
  };

  const handlePolygonEdit = (data) => {
    handleEditShap(data, modifyCategories.taxiway);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  const handlePolygonDelete = (data) => {
    handleDeleteShape(data, modifyCategories.taxiway);
    if (!popupElRef.current) return;
    popupElRef.current._closeButton.click();
  };

  return (
    <Fragment>
      {twyShapes.map((twyGeometry) => {
        const twyCoordinates = twyGeometry.coordinates.coordinates;
        const twyCoordinateMap = flatten(twyCoordinates).map((twyCoords) => {
          return twyCoords.map((twy) => {
            return {
              lng: twy[0],
              lat: twy[1],
            };
          });
        });

        return twyCoordinateMap.map((twyPolygonPath, i) => {
          const twyKey = `twy_${twyGeometry.twy}`;

          return (
            <Polygon
              key={i}
              positions={twyPolygonPath}
              pathOptions={{
                fillColor: areaColor[twyKey],
                color:
                  selectedOverE?.twy === twyGeometry.twy
                    ? areaHighLightColor
                    : areaColor[twyKey],
                weight: selectedOverE?.twy === twyGeometry.twy ? 3 : 2,
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e, twyGeometry),
                mouseout: (e) => handlePolygonOut(e),
              }}
            >
              {!isEditShape && (
                <Popup ref={popupElRef} className="polygon-popup">
                  <Box sx={styles.apnShapPolygonPop}>
                    Taxiway: {twyGeometry?.twy}
                  </Box>
                  {isAdmin && (
                    <Stack
                      sx={{ width: 200 }}
                      direction="row"
                      flexDirection="column"
                    >
                      <LightButton
                        label="Modify Polygon"
                        onClick={() => handlePolygonEdit(twyGeometry)}
                        sx={styles.modifyShapeBtn}
                      />
                      <LightButton
                        label="Delete Polygon"
                        onClick={() => handlePolygonDelete(twyGeometry)}
                        sx={{ p: 0, mr: 7 }}
                      />
                    </Stack>
                  )}
                </Popup>
              )}
            </Polygon>
          );
        });
      })}
    </Fragment>
  );
}
