import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Box,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { theme } from "@styles/theme";

function ControlledAirspaceDetail({ cnavData }) {
  return (
    <TableContainer>
      <Table>
        <TableBody sx={{ backgroundColor: theme.palette.white.main }}>
          {!cnavData && (
            <TableRow>
              <TableCell align="center">No Record Found.</TableCell>
            </TableRow>
          )}
          {cnavData && (
            <Fragment>
              <TableRow>
                <StyledTableCell
                  sx={{ backgroundColor: theme.palette.secondary.light }}
                >
                  <Box sx={{ typography: "subtitle2" }}>
                    {cnavData?.name || "-"}
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {cnavData?.designation || "-"}
                </StyledTableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ControlledAirspaceDetail;
