import React from "react";

export const ReviewDataIcon = (...props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 45.0007C37.2433 45.0007 35 42.7574 35 40.0007C35 37.2441 37.2433 35.0007 40 35.0007C42.7567 35.0007 45 37.2441 45 40.0007C45 42.7574 42.7567 45.0007 40 45.0007ZM40 28.3341C33.5667 28.3341 28.3333 33.5674 28.3333 40.0007C28.3333 46.4341 33.5667 51.6674 40 51.6674C46.4333 51.6674 51.6667 46.4341 51.6667 40.0007C51.6667 33.5674 46.4333 28.3341 40 28.3341ZM40.7323 56.6594C26.379 56.9927 17.0157 44.7161 13.909 39.9861C17.329 34.6361 25.9423 23.6827 39.269 23.3427C53.5657 22.9794 62.9823 35.2861 66.089 40.0161C62.6723 45.3661 54.0557 56.3194 40.7323 56.6594ZM72.8923 38.3427C70.7657 34.6361 59.019 16.0561 39.099 16.6794C20.6723 17.1461 9.95567 33.3794 7.109 38.3427C6.519 39.3694 6.519 40.6327 7.109 41.6594C9.20567 45.3161 20.539 63.3327 40.0823 63.3327C40.3557 63.3327 40.629 63.3294 40.9023 63.3227C59.3257 62.8527 70.0457 46.6227 72.8923 41.6594C73.479 40.6327 73.479 39.3694 72.8923 38.3427Z"
      fill={props.color || "#42444A"}
    />
  </svg>
);
