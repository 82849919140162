import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  FormHelperText,
  FormControl,
  Typography,
} from "@mui/material";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  AD24initialValue,
  fuelBody,
  oilBody,
} from "@constants/ad2DataForm.constant";
import { createAd24ValidationSchema } from "@constants/ad2Data";
import { PrimaryButton, LightButton } from "@components/button";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function AD24HandlingServiceForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd24Form.setFieldValue(
      "Cargo_handling_facilities",
      AAIAirport?.Cargo_handling_facilities
        ? AAIAirport?.Cargo_handling_facilities
        : ""
    );
    createAd24Form.setFieldValue(
      "Deicing",
      AAIAirport?.Deicing ? AAIAirport?.Deicing : ""
    );
    createAd24Form.setFieldValue(
      "Fuelling_facilities",
      AAIAirport?.Fuelling_facilities ? AAIAirport?.Fuelling_facilities : ""
    );
    createAd24Form.setFieldValue(
      "HANDLING_SERVICES_REMARK",
      AAIAirport?.HANDLING_SERVICES_REMARK
        ? AAIAirport?.HANDLING_SERVICES_REMARK
        : ""
    );
    createAd24Form.setFieldValue(
      "HANGAR",
      AAIAirport?.HANGAR ? AAIAirport?.HANGAR : ""
    );
    createAd24Form.setFieldValue(
      "REPAIR",
      AAIAirport?.REPAIR ? AAIAirport?.REPAIR : ""
    );

    if (AAIAirport.Fuel && AAIAirport.Fuel.length > 0) {
      AAIAirport.Fuel.map((Fuel, index) => {
        createAd24Form.setFieldValue(`Fuel[${index}].category`, Fuel.category);
        createAd24Form.setFieldValue(`Fuel[${index}].remark`, Fuel.remark);
        return true;
      });
    }
    if (AAIAirport.Oil && AAIAirport.Oil.length > 0) {
      AAIAirport.Oil.map((Oil, index) => {
        createAd24Form.setFieldValue(`Oil[${index}].category`, Oil.category);
        createAd24Form.setFieldValue(`Oil[${index}].remark`, Oil.remark);
        return true;
      });
    }
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "handlingservice",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd24Form.resetForm();
    createAd24Form.setSubmitting(false);
  };

  const createAd24Form = useFormik({
    enableReinitialize: true,
    initialValues: AD24initialValue,
    validationSchema: createAd24ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "handlingservice",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <FormikProvider value={createAd24Form}>
          <form>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Cargo-handling facilities</Box>
              <Box sx={styles.adInput}>
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "100%" }}
                  error={
                    createAd24Form.touched?.Cargo_handling_facilities &&
                    Boolean(createAd24Form.errors?.Cargo_handling_facilities)
                  }
                >
                  <TextareaAutosize
                    minRows={2}
                    id="Cargo_handling_facilities"
                    name="Cargo_handling_facilities"
                    value={createAd24Form.values.Cargo_handling_facilities}
                    onChange={createAd24Form.handleChange}
                    aria-describedby="Cargo_handling_facilities"
                    readOnly={!isReadOnly}
                  />
                  <FormHelperText id="Cargo_handling_facilities">
                    {createAd24Form.touched?.Cargo_handling_facilities &&
                      createAd24Form.errors?.Cargo_handling_facilities}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Deicing</Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="Deicing"
                  name="Deicing"
                  value={createAd24Form.values.Deicing}
                  onChange={createAd24Form.handleChange}
                  error={
                    createAd24Form.touched.Deicing &&
                    Boolean(createAd24Form.errors.Deicing)
                  }
                  helperText={
                    createAd24Form.touched.Deicing &&
                    createAd24Form.errors.Deicing
                  }
                  InputProps={{
                    readOnly: !isReadOnly,
                  }}
                  sx={{ mt: 2 }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Fuelling facilities and capacity</Box>
              <Box sx={styles.adInput}>
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "100%" }}
                  error={
                    createAd24Form.touched?.Fuelling_facilities &&
                    Boolean(createAd24Form.errors?.Fuelling_facilities)
                  }
                >
                  <TextareaAutosize
                    minRows={2}
                    id="Fuelling_facilities"
                    name="Fuelling_facilities"
                    value={createAd24Form.values.Fuelling_facilities}
                    onChange={createAd24Form.handleChange}
                    aria-describedby="Fuelling_facilities"
                    readOnly={!isReadOnly}
                  />
                  <FormHelperText id="Fuelling_facilities">
                    {createAd24Form.touched?.Fuelling_facilities &&
                      createAd24Form.errors?.Fuelling_facilities}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>Handling Services Remark</Box>
              <Box sx={styles.adInput}>
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "100%" }}
                  error={
                    createAd24Form.touched?.HANDLING_SERVICES_REMARK &&
                    Boolean(createAd24Form.errors?.HANDLING_SERVICES_REMARK)
                  }
                >
                  <TextareaAutosize
                    minRows={2}
                    id="HANDLING_SERVICES_REMARK"
                    name="HANDLING_SERVICES_REMARK"
                    value={createAd24Form.values.HANDLING_SERVICES_REMARK}
                    onChange={createAd24Form.handleChange}
                    aria-describedby="HANDLING_SERVICES_REMARK"
                    readOnly={!isReadOnly}
                  />
                  <FormHelperText id="HANDLING_SERVICES_REMARK">
                    {createAd24Form.touched?.HANDLING_SERVICES_REMARK &&
                      createAd24Form.errors?.HANDLING_SERVICES_REMARK}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>
                Hangar space for visiting aircraft
              </Box>
              <Box sx={styles.adInput}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="HANGAR"
                  name="HANGAR"
                  value={createAd24Form.values.HANGAR}
                  onChange={createAd24Form.handleChange}
                  error={
                    createAd24Form.touched.HANGAR &&
                    Boolean(createAd24Form.errors.HANGAR)
                  }
                  helperText={
                    createAd24Form.touched.HANGAR &&
                    createAd24Form.errors.HANGAR
                  }
                  InputProps={{
                    readOnly: !isReadOnly,
                  }}
                  sx={{ mt: 2 }}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
              <Box sx={{ width: "30%" }}>
                Repair facilities for visiting aircraft
              </Box>
              <Box sx={styles.adInput}>
                <FormControl
                  variant="outlined"
                  sx={{ mt: 2, width: "100%" }}
                  error={
                    createAd24Form.touched?.REPAIR &&
                    Boolean(createAd24Form.errors?.REPAIR)
                  }
                >
                  <TextareaAutosize
                    minRows={2}
                    id="REPAIR"
                    name="REPAIR"
                    value={createAd24Form.values.REPAIR}
                    onChange={createAd24Form.handleChange}
                    aria-describedby="REPAIR"
                    readOnly={!isReadOnly}
                  />
                  <FormHelperText id="REPAIR">
                    {createAd24Form.touched?.REPAIR &&
                      createAd24Form.errors?.REPAIR}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
            <Box sx={{ pl: 2, pb: 2 }}>Fuel</Box>
            <Box sx={styles.adInput}>
              <FieldArray
                name="Fuel"
                render={(arrayHelpersFuel) => {
                  return (
                    <div>
                      {createAd24Form.values.Fuel.map((_, index) => {
                        const isTouched = createAd24Form.touched?.Fuel
                          ? createAd24Form.touched?.Fuel[index]
                          : false;
                        const isErrors = createAd24Form.errors?.Fuel
                          ? createAd24Form.errors?.Fuel[index]
                          : false;

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pr: 2 }}
                            >
                              <Box sx={styles.adFuelMain}>
                                <Typography
                                  variant="span"
                                  sx={styles.adFuelLabel}
                                >
                                  Fuel {index + 1}
                                </Typography>
                                <Box sx={styles.adFuelDeleteBtn}>
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      arrayHelpersFuel.remove(index);
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                            <input
                              hidden
                              name={`Fuel[${index}].id`}
                              onChange={createAd24Form.handleChange}
                              value={createAd24Form.values.Fuel[index].id || ""}
                            />
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Category</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`Fuel[${index}].category`}
                                  value={
                                    createAd24Form.values.Fuel[index].category
                                  }
                                  onChange={createAd24Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd24Form.touched?.Fuel[index]
                                      .category &&
                                    Boolean(
                                      createAd24Form.errors?.Fuel[index]
                                        .category
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd24Form.errors?.Fuel[index]
                                          .category
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: !isReadOnly,
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mt: 2, width: "100%" }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd24Form.touched?.Fuel[index]
                                      .remark &&
                                    Boolean(
                                      createAd24Form.errors?.Fuel[index].remark
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    id={`Fuel[${index}].remark`}
                                    name={`Fuel[${index}].remark`}
                                    value={createAd24Form.values.remark}
                                    onChange={createAd24Form.handleChange}
                                    aria-describedby="aerodrome-fuel-remark"
                                    readOnly={!isReadOnly}
                                  />
                                  <FormHelperText id="aerodrome-fuel-remark">
                                    {isErrors && isTouched
                                      ? createAd24Form.errors?.Fuel[index]
                                          .remark
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </Fragment>
                        );
                      })}
                      <PrimaryButton
                        label="Add More Fuel"
                        variant="outlined"
                        sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                        onClick={() => arrayHelpersFuel.push(fuelBody)}
                      />
                    </div>
                  );
                }}
              />
            </Box>
            <Box sx={{ pl: 2, pb: 2 }}>Oil</Box>
            <Box sx={styles.adInput}>
              <FieldArray
                name="Oil"
                render={(arrayHelpersOil) => {
                  return (
                    <div>
                      {createAd24Form.values.Oil.map((_, index) => {
                        const isTouched = createAd24Form.touched?.Oil
                          ? createAd24Form.touched?.Oil[index]
                          : false;
                        const isErrors = createAd24Form.errors?.Oil
                          ? createAd24Form.errors?.Oil[index]
                          : false;

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pr: 2 }}
                            >
                              <Box sx={styles.adFuelMain}>
                                <Typography
                                  variant="span"
                                  sx={styles.adFuelLabel}
                                >
                                  Oil {index + 1}
                                </Typography>
                                <Box sx={styles.adFuelDeleteBtn}>
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      arrayHelpersOil.remove(index);
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                            <input
                              hidden
                              name={`Oil[${index}].id`}
                              onChange={createAd24Form.handleChange}
                              value={createAd24Form.values.Oil[index].id || ""}
                            />
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Category</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`Oil[${index}].category`}
                                  value={
                                    createAd24Form.values.Oil[index].category
                                  }
                                  onChange={createAd24Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd24Form.touched?.Oil[index]
                                      .category &&
                                    Boolean(
                                      createAd24Form.errors?.Oil[index].category
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd24Form.errors?.Oil[index]
                                          .category
                                      : ""
                                  }
                                  InputProps={{
                                    readOnly: !isReadOnly,
                                  }}
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mt: 2, width: "100%" }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd24Form.touched?.Oil[index].remark &&
                                    Boolean(
                                      createAd24Form.errors?.Oil[index].remark
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    id={`Oil[${index}].remark`}
                                    name={`Oil[${index}].remark`}
                                    value={createAd24Form.values.remark}
                                    onChange={createAd24Form.handleChange}
                                    aria-describedby="aerodrome-Oil-remark"
                                    readOnly={!isReadOnly}
                                  />
                                  <FormHelperText id="aerodrome-Oil-remark">
                                    {isErrors && isTouched
                                      ? createAd24Form.errors?.Oil[index].remark
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </Fragment>
                        );
                      })}
                      <PrimaryButton
                        label="Add More Oil"
                        variant="outlined"
                        sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                        onClick={() => arrayHelpersOil.push(oilBody)}
                      />
                    </div>
                  );
                }}
              />
            </Box>
          </form>
        </FormikProvider>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd24Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd24Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}
