import React, { useState } from "react";
import { FeatureGroup, CircleMarker } from "react-leaflet";
import VisualisationObstaclePopup from "@components/map/VisualisationObstaclePopup";
import { areaHighLightColor, staticColorMap } from "@constants/colors";

export default function RenderVisualiseObstacles({ obstacles }) {
  const [selectedOverE, setSelectedOverE] = useState(null);

  const handlePolygonOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <FeatureGroup pane="popupPane">
      {obstacles.map((obstacle, i) => {
        return (
          <CircleMarker
            key={i}
            center={{
              lat: Number(obstacle.latitude),
              lng: Number(obstacle.longitude),
            }}
            radius={6}
            pathOptions={{
              fillColor: staticColorMap["Obstacles"],
              color:
                selectedOverE?.id === obstacle.id
                  ? areaHighLightColor
                  : staticColorMap["Obstacles"],
              weight: selectedOverE?.id === obstacle.id ? 4 : 1,
              fillOpacity: 1,
            }}
            eventHandlers={{
              mouseover: () => handlePolygonOver(obstacle),
              mouseout: () => handlePolygonOut(),
            }}
          >
            <VisualisationObstaclePopup info={obstacle} />
          </CircleMarker>
        );
      })}
    </FeatureGroup>
  );
}
