import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import Header from "@components/Header";
import BackButton from "@components/button/BackButton";
import PageTitle from "@components/core/PageTitle";
import { getReviewFeaturesdata } from "@store/survey/actions";
import { getAsyncStorageValue } from "@utils/localStorage";
import SurveyorReviewList from "./SurveyorReviewList";

export default function SurveyorRevalidation() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;
  const reviewBackFeaturesData = useSelector(
    (state) => state.survey.reviewBackFeaturesData
  );

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (!isAdmin) {
        dispatch(getReviewFeaturesdata(token));
      }
    });
  }, []); // eslint-disable-line

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div className="main-content">
        <div className="sidebar">
          <BackButton />
          <Divider sx={{ mb: 2 }} />
          <PageTitle title="Dataset Revalidation" />
        </div>
        <div className="main">
          <div className="content-area ">
            <SurveyorReviewList
              reviewBackFeaturesData={reviewBackFeaturesData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
