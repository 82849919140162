import { chain, orderBy } from "lodash";
import { amdbAirportList } from "@constants/constant";
import { darkColor } from "@constants/colors";
import { airportFeatures } from "@constants/amdbData";
import { amdb } from "./slice";

export const {
  setAMDBDataLoadingRequest,
  setAMDBDetails,
  setLoadingAllAirportamdb,
  setAllamdbAirports,
  setExportGeoJSONLoading,
  setLoadingAddEditDeletePolygon,
  setAMDBdrawHighlighter,
} = amdb.actions;

export default amdb.reducer;

export const selectAmdbAirports = (state) => {
  const amdbAirports = state.amdb.allamdbAirports;

  if (amdbAirports && amdbAirports.length > 0) {
    return chain(amdbAirports)
      .filter((airport) => {
        return amdbAirportList.includes(airport.name);
      })
      .orderBy(["name"], ["asc"])
      .value();
  }

  return [];
};

export const selectAmdbFeature = (state) => {
  const amdbDetail = state.amdb.amdbDetails;
  const rwylist = amdbDetail?.RWY_ELEMENT_List || [];
  const apnList = amdbDetail?.APN_ELEMENT_List || [];
  const twylist = amdbDetail?.TWY_ELEMENT_List || [];
  const rwyOptions = rwylist.map((rwy, i) => {
    return {
      id: "rwy" + i,
      name: rwy.type,
      value: rwy.type,
      color: darkColor[i],
    };
  });
  const apnOptions = apnList.map((apn, i) => {
    return {
      id: "apn" + i,
      name: apn.apn,
      value: "apn_" + apn.apn,
      color: darkColor[i + rwyOptions.length],
    };
  });
  const twyOptions = twylist.map((twy, i) => {
    return {
      id: "twy" + i,
      name: twy.twy,
      value: "twy_" + twy.twy,
      color: darkColor[i + rwyOptions.length + apnOptions.length],
    };
  });

  const amdbFeatures = airportFeatures.map((af) => {
    if (af.value === "RWY_ELEMENT_List") {
      af.sub = orderBy(rwyOptions, ["name"], ["asc"]);
    }
    if (af.value === "APN_ELEMENT_List") {
      af.sub = orderBy(apnOptions, ["name"], ["asc"]);
    }
    if (af.value === "TWY_ELEMENT_List") {
      af.sub = orderBy(twyOptions, ["name"], ["asc"]);
    }

    return af;
  });

  return amdbFeatures;
};

export const selectParkingBayLineList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.PARKING_BAYLINE_List &&
    amdbDetail.PARKING_BAYLINE_List.length > 0
  ) {
    return amdbDetail.PARKING_BAYLINE_List;
  }

  return [];
};

export const selectParkingBayPointsList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.PARKING_BAY_POINTS_List &&
    amdbDetail.PARKING_BAY_POINTS_List.length > 0
  ) {
    return amdbDetail.PARKING_BAY_POINTS_List;
  }

  return [];
};

export const selectRwyElementMarkingList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (
    amdbDetail?.RWY_ELE_MARKING_List &&
    amdbDetail.RWY_ELE_MARKING_List.length > 0
  ) {
    return amdbDetail.RWY_ELE_MARKING_List;
  }

  return [];
};

export const selectApnElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.APN_ELEMENT_List && amdbDetail.APN_ELEMENT_List.length > 0) {
    return amdbDetail.APN_ELEMENT_List;
  }

  return [];
};

export const selectRwyElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.RWY_ELEMENT_List && amdbDetail.RWY_ELEMENT_List.length > 0) {
    return amdbDetail.RWY_ELEMENT_List;
  }

  return [];
};

export const selectTwyElementList = (state) => {
  const amdbDetail = state.amdb.amdbDetails;

  if (amdbDetail?.TWY_ELEMENT_List && amdbDetail.TWY_ELEMENT_List.length > 0) {
    return amdbDetail.TWY_ELEMENT_List;
  }

  return [];
};
