import React from "react";
import { Box, Divider, Grid } from "@mui/material";

function NavButton1({ icon, label, onClick }) {
  return (
    <Grid
      item
      xl={3}
      md={3}
      lg={3}
      sm={6}
      className="nav-button1-container"
      onClick={onClick}
    >
      <Box elevation={0} className="manage-icon">
        {icon}
      </Box>
      <Divider />
      <div className="manage-label">
        <label>{label}</label>
      </div>
    </Grid>
  );
}

export default NavButton1;
