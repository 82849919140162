import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@assets/svg/CloseIcon";
import ControlMapCenter from "@components/map/ControlMapCenter";
import FullScreenControl from "@components/map/FullScreenControl";
import MapLayers from "@components/map/MapLayers";
import MeasurementControl from "@components/map/MeasurementControl";
import { roles } from "@constants/constant";
import RenderApnShape from "./RenderApnShape";
import RenderRunWayShape from "./RenderRunWayShape";
import RenderTwyShape from "./RenderTwyShape";
import RenderParkingBayLine from "./RenderParkingBayLine";
import RenderParkingBayPoints from "./RenderParkingBayPoints";
import RenderRwyElementMarking from "./RenderRwyElementMarking";
import AMDBDrawControl from "./AMDBDrawControl";
import CustomDrawActionButton from "./CustomDrawActionButton";

export default function OSMMapContainer({
  airportLocation,
  isEditShape,
  zoomLevel,
  selctedAirport,
  selectedFeatures,
  apnElementList,
  rwyElementList,
  twyElementList,
  parkingBayLineList,
  parkingBayPointsList,
  rwyElementMarkingList,
  handleDrawCallback,
  handleDisableShapeControll,
}) {
  const drawRef = useRef(null);
  const featureGroupRef = useRef();
  const [editableLayer, setEditableLayer] = useState(null);
  const [editabletype, setEditableType] = useState(null);
  const [editableMarkerLayer, setEditableMarkerLayer] = useState(null);
  const [editableLineLayer, setEditableLineLayer] = useState(null);
  const [deleteLayer, setDeleteLayer] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [isPolygonMarkingMode, setPolygonMarkingMode] = useState(false);
  const [isPolylineMarkingMode, setPolylineMarkingMode] = useState(false);
  const [isPointMarkingMode, setPointMarkingMode] = useState(false);
  const [layerType, setLayerType] = useState(null);
  const [editLineIndex, setEditLineIndex] = useState(null);
  const [deleteLineIndex, setDeleteLineIndex] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const user = useSelector((state) => state.userSession.user);
  const isAdmin = user?.role === roles.admin;

  useEffect(() => {
    if (isEmpty(selctedAirport)) {
      setPolygonMarkingMode(false);
      setPolylineMarkingMode(false);
      setPointMarkingMode(false);
      setLayerType(null);
    }
  }, [selctedAirport]);

  const handleDeleteShape = (data, type, index) => {
    setOpenDeleteConfirmDialog(true);
    setPolylineMarkingMode(false);
    setPointMarkingMode(false);
    setDeleteType(type);
    setDeleteLayer(data);
    if (type === "bayline") {
      setDeleteLineIndex(index);
    } else {
      setDeleteLineIndex(null);
    }
  };

  const handleDeleteCallback = () => {
    setOpenDeleteConfirmDialog(false);
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(false);
    setPointMarkingMode(false);
    setDeleteLayer(null);
    setDeleteType(null);
    handleDisableShapeControll(false);
  };

  const handleDrawControlCallback = () => {
    setEditableType(null);
    setEditableLayer(null);
    setEditableMarkerLayer(null);
    setEditableLineLayer(null);
    handleDrawCallback();
    handleDisableShapeControll(false);
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(false);
    setPointMarkingMode(false);
  };

  const handleCloseMarkingMode = () => {
    setLayerType(null);
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(false);
    setPointMarkingMode(false);
    handleDisableShapeControll(false);
    setEditableType(null);
    setEditableLayer(null);
    setEditableMarkerLayer(null);
    setEditableLineLayer(null);
    setEditLineIndex(null);
  };

  const handleonMountedControl = (ctl) => {
    drawRef.current = ctl;
  };

  const handlePolygonDrawClick = () => {
    handleDisableShapeControll(true);
    setLayerType("polygon");
    setPolygonMarkingMode(true);
    setPolylineMarkingMode(false);
    setPointMarkingMode(false);
    setTimeout(() =>
      drawRef?.current?._toolbars?.draw._modes.polygon.handler.enable()
    );
  };

  const handleLocationDrawClick = () => {
    handleDisableShapeControll(true);
    setLayerType("circlemarker");
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(false);
    setPointMarkingMode(true);
    setTimeout(() =>
      drawRef?.current?._toolbars?.draw._modes.circlemarker.handler.enable()
    );
  };

  const handlePolylineDrawClick = () => {
    handleDisableShapeControll(true);
    setLayerType("polyline");
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(true);
    setPointMarkingMode(false);
    setTimeout(
      () => drawRef?.current?._toolbars?.draw._modes.polyline.handler.enable(),
      500
    );
  };

  const handleEditShap = (data, type, drawType) => {
    if (drawType === "polyline") {
      handleDisableShapeControll(true);
      setPolygonMarkingMode(false);
      setPointMarkingMode(false);
      setPolylineMarkingMode(true);
      setEditableType(type);
      setEditableLayer(data);
      setLayerType(drawType);
      setTimeout(() => {
        drawRef?.current?._toolbars?.edit._modes.edit.handler.enable();
      });
      handleDisableShapeControll(true);
      setPointMarkingMode(false);
      setEditableMarkerLayer(null);
      setEditableLineLayer(null);
      setEditLineIndex(null);
    } else {
      setPolygonMarkingMode(true);
      setPolylineMarkingMode(false);
      setEditableType(type);
      setEditableLayer(data);
      setLayerType("polygon");
      setTimeout(() => {
        drawRef?.current?._toolbars?.edit._modes.edit.handler.enable();
      });
      handleDisableShapeControll(true);
      setPointMarkingMode(false);
      setEditableMarkerLayer(null);
      setEditableLineLayer(null);
      setEditLineIndex(null);
    }
  };

  const handleEditBaypoint = (data, type) => {
    setTimeout(() => {
      drawRef?.current?._toolbars?.edit._modes.edit.handler.enable();
    });
    handleDisableShapeControll(true);
    setPointMarkingMode(true);
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(false);
    setEditableType(type);
    setLayerType("circlemarker");
    setEditableMarkerLayer(data);
    setEditableLineLayer(null);
    setEditableLayer(null);
    setEditLineIndex(null);
  };

  const handleEditBayline = (data, type, index) => {
    setTimeout(() => {
      drawRef?.current?._toolbars?.edit._modes.edit.handler.enable();
    });
    setEditLineIndex(index);
    setPointMarkingMode(false);
    setPolygonMarkingMode(false);
    setPolylineMarkingMode(true);
    setEditableType(type);
    setLayerType("multipolyline");
    setEditableLineLayer(data);
    handleDisableShapeControll(true);
    setEditableMarkerLayer(null);
    setEditableLayer(null);
  };

  const amdbMapE = useMemo(() => {
    return (
      <MapContainer
        center={airportLocation}
        zoom={zoomLevel}
        zoomControl={false}
        className="amdb-map"
      >
        <RenderApnShape
          isEditShape={isEditShape}
          selectedFeatures={selectedFeatures}
          apnElementList={apnElementList}
          handleEditShap={handleEditShap}
          handleDeleteShape={handleDeleteShape}
        />
        <RenderRunWayShape
          isEditShape={isEditShape}
          selectedFeatures={selectedFeatures}
          rwyElementList={rwyElementList}
          handleEditShap={handleEditShap}
          handleDeleteShape={handleDeleteShape}
        />
        <RenderTwyShape
          isEditShape={isEditShape}
          selectedFeatures={selectedFeatures}
          twyElementList={twyElementList}
          handleEditShap={handleEditShap}
          handleDeleteShape={handleDeleteShape}
        />
        <RenderParkingBayLine
          selectedFeatures={selectedFeatures}
          parkingBayLineList={parkingBayLineList}
          handleEditShap={handleEditBayline}
          handleDeleteShape={handleDeleteShape}
        />
        <RenderParkingBayPoints
          selectedFeatures={selectedFeatures}
          parkingBayPointsList={parkingBayPointsList}
          handleEditShap={handleEditBaypoint}
          handleDeleteShape={handleDeleteShape}
        />
        <RenderRwyElementMarking
          selectedFeatures={selectedFeatures}
          rwyElementMarkingList={rwyElementMarkingList}
        />
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <MeasurementControl />
        {isAdmin && (
          <AMDBDrawControl
            isEditShape={isEditShape}
            isPointMarkingMode={isPointMarkingMode}
            isPolygonMarkingMode={isPolygonMarkingMode}
            isPolylineMarkingMode={isPolylineMarkingMode}
            selctedAirport={selctedAirport}
            editableLayer={editableLayer}
            editableMarkerLayer={editableMarkerLayer}
            editableLineLayer={editableLineLayer}
            editabletype={editabletype}
            deleteLayer={deleteLayer}
            deleteType={deleteType}
            shapeType={layerType}
            editLineIndex={editLineIndex}
            deleteLineIndex={deleteLineIndex}
            isOpenDeleteConfirmDialog={isOpenDeleteConfirmDialog}
            handleCallback={handleDrawControlCallback}
            handleonMountedControl={handleonMountedControl}
            handleClearLayer={handleonMountedControl}
            handleDeleteCallback={handleDeleteCallback}
          />
        )}
        <MapLayers />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAdmin,
    isEditShape,
    airportLocation,
    zoomLevel,
    selctedAirport,
    selectedFeatures,
    editableLayer,
    deleteLayer,
    isPointMarkingMode,
    isPolygonMarkingMode,
    isPolylineMarkingMode,
    drawRef,
    featureGroupRef,
    layerType,
    isOpenDeleteConfirmDialog,
    editableLineLayer,
    editableMarkerLayer,
    editLineIndex,
  ]);

  return (
    <Fragment>
      {amdbMapE}
      {!isPolygonMarkingMode &&
        !isPointMarkingMode &&
        !isPolylineMarkingMode &&
        selctedAirport && (
          <CustomDrawActionButton
            handlePolygonDrawClick={handlePolygonDrawClick}
            handleLocationDrawClick={handleLocationDrawClick}
            handlePolylineDrawClick={handlePolylineDrawClick}
          />
        )}
      {user &&
        selctedAirport &&
        (isPointMarkingMode ||
          isPolygonMarkingMode ||
          isPolylineMarkingMode) && (
          <Box className="amdb-marking-mode">
            <Typography>You are in Marking Mode</Typography>
            <IconButton sx={{ p: 0 }} onClick={() => handleCloseMarkingMode()}>
              <CloseIcon color="#fff" width={7} height={7} />
            </IconButton>
          </Box>
        )}
    </Fragment>
  );
}
