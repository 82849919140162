import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { BsArrowLeft } from "react-icons/bs";
import { isEmpty } from "lodash";
import { Box, Divider, Typography } from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { exportObstacleData } from "@store/etod/actions";
import { commonStyles } from "@styles/commonStyles";
import { theme } from "@styles/theme";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import DropDownLabel from "./DropDownLabel";
import { PrimaryButtonV2 } from "./button";
import AirportFeature from "./AirportFeature";

export default function ObstacleAnalysisSidebar({
  isLoadingEtodAirport,
  isLoadingExportObstacle,
  isLoadingEtodLayers,
  isLoadingEtodDems,
  isBack,
  isProceedObstacleAnalysis,
  isVisualSurveyObstacle,
  allAirports = [],
  selctedAirport,
  selectedAirportDetail,
  selctedFeature,
  selectedAreas,
  isActiveAirport,
  areaOptions,
  olsOptions,
  selectedOls,
  selectedAirportFeature,
  handleBack,
  handleFeature,
  handleAirport,
  handleAreaCheck,
  handleSelectAll,
  handleClearAll,
  handleParentOlsCheck,
  handleOlsCheck,
  isShowPenetrationObstacle,
  handlePenetrationObstacleToggle,
  handleParentAreaCheck,
  handle3DMap,
  is3DMap,
  etodDems,
  demOptions,
  selectedDems,
  handleParentDemCheck,
  handleDemCheck,
  handleSelectAllDems,
  handleSelectAllOls,
  handleSelectAllArea,
  isVisualUploadProceedObstacle,
  handleProceedVisualObstacle,
  handleClearAllArea,
  handleClearAlldem,
  handleClearAllOls,
}) {
  const dispatch = useDispatch();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const currentUser = useSelector((state) => state.userSession.user);
  const {
    isLoadingExportAreaOls,
    analysisObstacles,
    isLoadingAnalysisObstacles,
    isUplodeeTodDemFileRequest,
  } = useSelector((state) => state.etod);
  const isAreaLoading = isLoadingEtodLayers;
  const isDisabled = isActiveAirport || isAreaLoading;
  const isEnabledFilter = Boolean(selctedFeature);

  const handleChangeAirport = (option) => {
    handleAirport(option);
  };

  const handleBackAnalysisScreen = () => {
    handleBack();
  };

  const handleProceedVisualSurveys = () => {
    handleProceedVisualObstacle();
  };

  const handleExportObstacle = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      if (currentUser?.user_access?.export|| accessToken) {
      dispatch(exportObstacleData(selctedFeature.value, selctedAirport, token));
      }
    });
  };

  return (
    <div className="sidebar">
      {isBack && (
        <>
          <Box
            className="ols-back"
            sx={{ mb: 1, mt: 1 }}
            onClick={handleBackAnalysisScreen}
          >
            <BsArrowLeft />
            <Typography variant="span" ml={1}>
              back to selection
            </Typography>
          </Box>
          <Divider sx={{ border: "1px solid #828282", mb: 1.5 }} />
          <Typography
            mb={1.5}
            variant="h3"
            sx={{
              color: theme.palette.primary.main,
              fontSize: "13px",
              fontWeight: "600",
            }}
          >
            {isProceedObstacleAnalysis && "Obstacle Analysis"}
            {isVisualSurveyObstacle && "Visualise Survey Data"}
          </Typography>
        </>
      )}
      <Box sx={{ mb: 2 }}>
        <DropDownLabel label="Airport" isRequired />
        <Select
          isClearable
          isDisabled={!isEmpty(selectedAirportDetail)}
          isLoading={isLoadingEtodAirport}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleChangeAirport}
          options={allAirports.filter((airport) => airport.obstacle_analysis)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          styles={commonStyles.selectScrollbar}
        />
      </Box>
      <AirportFeature
        isEtod={false}
        moduleClassname="ols-check-list"
        selectedAirport={selctedAirport}
        selctedFeature={selctedFeature}
        isActiveAirport={isActiveAirport}
        isAreaLoading={isAreaLoading}
        isLoadingEtodLayers={isLoadingEtodLayers}
        isEnabledFilter={isEnabledFilter}
        isShowPenetrationObstacle={isShowPenetrationObstacle}
        isLoadingAnalysisObstacles={isLoadingAnalysisObstacles}
        isDisabled={isDisabled}
        isLoadingExportAreaOls={isLoadingExportAreaOls}
        isLoadingEtodDems={isLoadingEtodDems}
        isUplodeeTodDemFileRequest={isUplodeeTodDemFileRequest}
        selectedAirportFeature={selectedAirportFeature}
        analysisObstacles={analysisObstacles}
        areaOptions={areaOptions}
        selectedAreas={selectedAreas}
        demOptions={demOptions}
        etodDems={etodDems}
        selectedDems={selectedDems}
        olsOptions={olsOptions}
        selectedOls={selectedOls}
        areaOptionStyles={{
          maxHeight: isBack ? `calc(100vh - 655px);` : `calc(100vh - 599px);`,
        }}
        olsOptionStyles={{
          maxHeight: isBack ? `calc(100vh - 599px);` : `calc(100vh - 541px);`,
        }}
        handleFeature={handleFeature}
        handlePenetrationObstacleToggle={handlePenetrationObstacleToggle}
        handleSelectAll={handleSelectAll}
        handleClearAll={handleClearAll}
        handleSelectAllArea={handleSelectAllArea}
        handleClearAllArea={handleClearAllArea}
        handleParentAreaCheck={handleParentAreaCheck}
        handleAreaCheck={handleAreaCheck}
        handleSelectAllDems={handleSelectAllDems}
        handleClearAlldem={handleClearAlldem}
        handleParentDemCheck={handleParentDemCheck}
        handleDemCheck={handleDemCheck}
        handleSelectAllOls={handleSelectAllOls}
        handleClearAllOls={handleClearAllOls}
        handleParentOlsCheck={handleParentOlsCheck}
        handleOlsCheck={handleOlsCheck}
      />
      <div style={{ position: "absolute", bottom: "1.5rem", width: "inherit" }}>
        <div className="etod-sidebar-footer">
          <PrimaryButtonV2
            label={is3DMap ? "2D MAP" : "3D MAP"}
            disabled={!selctedAirport}
            onClick={() => handle3DMap()}
          />
        </div>
        {isVisualUploadProceedObstacle && (
          <div className="etod-sidebar-footer" style={{ marginTop: 5 }}>
            <PrimaryButtonV2
              label="Proceed"
              disabled={!isVisualUploadProceedObstacle}
              onClick={handleProceedVisualSurveys}
            />
          </div>
        )}
        {currentUser && accessToken && !isVisualUploadProceedObstacle && (
          <div className="etod-sidebar-footer" style={{ marginTop: 8 }}>
            <PrimaryButtonV2
              label="Export Obstacles"
              disabled={
                !selctedAirport ||
                analysisObstacles?.length === 0 ||
                !selctedFeature ||
                !currentUser?.user_access?.export || !accessToken
              }
              isLoading={isLoadingExportObstacle}
              onClick={() => handleExportObstacle()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
