import React from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCellV2 } from "@components/tabel-component/StyledTableCell";
import StyledTableRow from "@components/tabel-component/StyledTableRow";
import { selecteChartTableData } from "@store/electronicChart";
import { styles } from "@styles/aixmStyles";

export default function TaxiWayChartTable() {
  const data = useSelector((state) => selecteChartTableData(state, "Taxiway"));

  return (
    <TableContainer id="eChartTable">
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCellV2>Name</StyledTableCellV2>
            <StyledTableCellV2>PCN</StyledTableCellV2>
            <StyledTableCellV2>Width</StyledTableCellV2>
            <StyledTableCellV2>Remarks</StyledTableCellV2>
          </TableRow>
        </TableHead>
        <TableBody id="eChartTableBody">
          {data.map((row) => (
            <StyledTableRow
              key={row.id}
              id={`table-row-${row.id}`}
              sx={styles.tableRowLastBorderNone}
            >
              <StyledTableCellV2>{row.name}</StyledTableCellV2>
              <StyledTableCellV2>{row.pcn}</StyledTableCellV2>
              <StyledTableCellV2>{row.width}</StyledTableCellV2>
              <StyledTableCellV2>{row.remarks}</StyledTableCellV2>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
