import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { useFormik } from "formik";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  ButtonGroup,
  TableContainer,
  Table,
  Toolbar,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  Autocomplete,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { EditIcon } from "@assets/svg/edit";
import { ResetPasswordIcon } from "@assets/svg/reset-password";
import {
  ACCESS_TOKEN,
  AES_KEY,
  userLabel,
  userType,
} from "@constants/constant";
import {
  createUserValidationSchema,
  editUserValidationSchema,
  masterResetPasswordValidationSchema,
} from "@constants/surveyorData";
import { OutlinedButton, PrimaryButton } from "@components/button";
import AntSwitch from "@components/core/AntSwitch";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import { LightTooltip } from "@components/core/LightTooltip";
import CreateUserButton from "@components/CreateUserButton";
import Header from "@components/Header";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableRowLoader from "@components/tabel-component/TableRowLoader";
import { selectFIRAirspaceOption } from "@store/airspace";
import { getENRTypeAirspaceGeometry } from "@store/airspace/actions";
import { selectAllSurveyorList } from "@store/survey";
import {
  getAllSurveyorData,
  getAllSurveyAirport,
  postSurveyorRegistration,
  editSurveyorRegistration,
  disabledSurveyor,
  enableSurveyor,
  masterResetPassword,
} from "@store/survey/actions";
import { styles } from "@styles/dataManagementStyles";
import { decryptStatic, encryptStatic } from "@utils/decodeEncodeData";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";

export default function ManageUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoadingGetAllSurveyor,
    isLoadingPostSurveyorRegistration,
    isLoadingEditSurveyorRegistration,
    isLoadingDisableSurveyor,
    isLoadingEnableSurveyor,
    isResetingMasterPassword,
    surveyAllAirports,
  } = useSelector((state) => state.survey);
  const FIRAirspaceOption = useSelector(selectFIRAirspaceOption);
  const [selectedSurveyor, setSelectedSurveyor] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isEditDialog, setEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [filterUserType, setFilterUserType] = useState(userType.surveyor);
  const allSurveyorList = useSelector((state) =>
    selectAllSurveyorList(state, filterUserType)
  );
  const aesKey = getStorageValue(AES_KEY);
  const userTypeName = selectedUserType ? userLabel[selectedUserType] : "";

  useEffect(() => {
    dispatch(getENRTypeAirspaceGeometry('FIR'));
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllSurveyAirport(token));
      dispatch(getAllSurveyorData(token));
    });
  }, []); // eslint-disable-line

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      airportOrfir: "",
    },
    validationSchema: isEditDialog
      ? editUserValidationSchema
      : createUserValidationSchema,
    onSubmit: (values) => {
      const userPayload = {
        name: encryptStatic(values.name, aesKey),
        email: encryptStatic(values.email, aesKey),
        phone: encryptStatic(values.phone, aesKey),
        airportOrfir: null,
      };

      if (
        selectedUserType === userType.surveyor ||
        (selectedSurveyor && selectedSurveyor.role === userType.surveyor)
      ) {
        userPayload.airportOrfir = values.airportOrfir;
      } else {
        userPayload.airportOrfir = values.airportOrfir.substring(0, 2);
      }

      if (values.user_id) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            editSurveyorRegistration(
              {
                user_id: values.user_id,
                ...userPayload,
              },
              token,
              handleCloseAddDialog
            )
          );
        });
      } else {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            postSurveyorRegistration(
              {
                ...userPayload,
                password: encryptStatic(values.password, aesKey),
                role: selectedUserType,
              },
              token,
              handleCloseAddDialog
            )
          );
        });
      }
    },
  });

  const passwordResetFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
    },
    validationSchema: masterResetPasswordValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          masterResetPassword(
            {
              user_id: values.user_id,
              new_password: encryptStatic(values.new_password, aesKey),
            },
            token,
            handleCloseResetPasswordDialog
          )
        );
      });
    },
  });

  const handleUserTypes = (type) => {
    setFilterUserType(type);
  };

  const handleOpenAddDialog = (userType) => {
    setSelectedUserType(userType);
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    formik.setFieldValue("password", password);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    // add
    setOpenAddDialog(false);
    setCopied(false);
    formik.resetForm();
    formik.setSubmitting(false);

    // edit
    setEditDialog(false);
    setSelectedSurveyor(null);
    setSelectedUserType(null);
  };

  const handleEditSurveyor = (payload) => {
    setEditDialog(true);
    setSelectedSurveyor(payload);
    setOpenAddDialog(true);
    formik.setValues({
      user_id: payload.userid,
      name: decryptStatic(payload.name, aesKey),
      email: decryptStatic(payload.email, aesKey),
      phone: decryptStatic(payload.phone_no, aesKey),
    });

    if (payload.role === userType.surveyor && payload.airport_id) {
      formik.setFieldValue("airportOrfir", payload.airport_id);
    }
  };

  const handleOpenDeleteDialog = (srveyor) => {
    setSelectedSurveyor(srveyor);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSurveyor(null);
  };

  const handleDisableSurveyor = () => {
    if (selectedSurveyor?.enable) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          disabledSurveyor(
            selectedSurveyor.userid,
            token,
            handleCloseDeleteDialog
          )
        );
      });
    } else {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          enableSurveyor(
            { user_id: selectedSurveyor.userid },
            token,
            handleCloseDeleteDialog
          )
        );
      });
    }
  };

  const handleResetPassword = (payload) => {
    setSelectedSurveyor(payload);
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    passwordResetFormik.setValues({
      user_id: payload.userid,
      new_password: password,
    });
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setCopied(false);
    setSelectedSurveyor(null);
    setOpenResetPasswordDialog(false);
    passwordResetFormik.resetForm();
    passwordResetFormik.setSubmitting(false);
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div className="main" style={{ width: "100%" }}>
        <div
          className="surveyor-category-table white-bg"
          style={{ padding: 24, paddingTop: 0, width: "auto", height: "100%" }}
        >
          <Toolbar
            sx={{
              justifyContent: "space-between",
              minHeight: "72px !important",
              p: "0 !important",
            }}
          >
            <Box sx={styles.reviewDatasetBarinr}>
              <IconButton
                aria-label="back"
                onClick={() => navigate(-1)}
                sx={{ p: 0, ml: "-8px" }}
              >
                <ArrowleftIcon />
              </IconButton>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, fontSize: 18, pl: 1,mr:3.5 }}
              >
                Manage User
              </Typography>
              <CreateUserButton
                classname="create-user-btn"
                handleOpenAddDialog={handleOpenAddDialog}
              />
            </Box>
            <ButtonGroup aria-label="outlined button group">
              <Button
                variant={
                  filterUserType === userType.surveyor
                    ? "contained"
                    : "outlined"
                }
                sx={{ textTransform: "none", height: "32px" }}
                onClick={() => handleUserTypes(userType.surveyor)}
              >
                {userLabel.SURVEYOR}
              </Button>
              <Button
                variant={
                  filterUserType === userType.aai ? "contained" : "outlined"
                }
                sx={{ textTransform: "none", height: "32px" }}
                onClick={() => handleUserTypes(userType.aai)}
              >
                {userLabel.AAI_USER}
              </Button>
            </ButtonGroup>
          </Toolbar>
          <Box
            sx={{
              ...styles.arpReviewCategoryList,
              margin: 0,
              height: "calc(100vh - 152px)",
              overflow: "auto",
            }}
          >
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Surveyor Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Phone Number</StyledTableCell>
                    {filterUserType === userType.surveyor && (
                      <StyledTableCell>Airport Assigned</StyledTableCell>
                    )}
                    {filterUserType === userType.aai && (
                      <StyledTableCell>FIR Assigned</StyledTableCell>
                    )}
                    <StyledTableCell align="center">ACTION</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(isLoadingGetAllSurveyor ||
                    allSurveyorList.length === 0) && (
                    <TableRowLoader
                      isLoading={isLoadingGetAllSurveyor}
                      data={allSurveyorList}
                      colSpan={6}
                    />
                  )}
                  {allSurveyorList.map((surveyor) => {
                    return (
                      <TableRow key={surveyor.emp_id}>
                        <StyledTableCell>{surveyor.emp_id}</StyledTableCell>
                        <StyledTableCell>
                          {decryptStatic(surveyor.name, aesKey)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {decryptStatic(surveyor.email, aesKey) || "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {decryptStatic(surveyor.phone_no, aesKey) || "-"}
                        </StyledTableCell>
                        {filterUserType === userType.surveyor && (
                          <StyledTableCell>
                            {surveyor.airport_name
                              ? `${surveyor.airport_name} (${surveyor.location_indicator})`
                              : "-"}
                          </StyledTableCell>
                        )}
                        {filterUserType === userType.aai && (
                          <StyledTableCell>
                            {surveyor.fir ? `${surveyor.fir}` : "-"}
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LightTooltip title="Edit">
                            <IconButton
                              color="primary"
                              onClick={() => handleEditSurveyor(surveyor)}
                            >
                              <EditIcon />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Reset Password">
                            <IconButton
                              color="primary"
                              onClick={() => handleResetPassword(surveyor)}
                            >
                              <ResetPasswordIcon />
                            </IconButton>
                          </LightTooltip>
                          <LightTooltip title="Enable / Disable">
                            <AntSwitch
                              checked={surveyor?.enable}
                              onChange={() => handleOpenDeleteDialog(surveyor)}
                              trackcolor={
                                surveyor?.enable ? "#219653" : "#E91414"
                              }
                              sx={{ ml: 1 }}
                            />
                          </LightTooltip>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Dialog fullWidth open={openAddDialog}>
            <DialogTitle>
              {isEditDialog
                ? `Edit ${userLabel[filterUserType]}`
                : `New ${userTypeName}`}
            </DialogTitle>
            <DialogContent>
              <form>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{ mb: 2, mt: 2 }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{ mb: 2 }}
                />
                <TextField
                  className="phoneno-field"
                  fullWidth
                  variant="outlined"
                  id="phone"
                  name="phone"
                  label="Phone Number"
                  type="number"
                  InputProps={{ inputProps: { min: 10, max: 10 } }}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  sx={{ mb: 2 }}
                />
                {!isEditDialog && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="password"
                    name="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    sx={{ mb: 2 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard
                            text={formik.values.password}
                            onCopy={() => setCopied(true)}
                          >
                            <IconButton
                              edge="end"
                              color={isCopied ? "primary" : "default"}
                            >
                              <FaCopy />
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {(selectedSurveyor?.role === userType.surveyor ||
                  selectedUserType === userType.surveyor) && (
                  <Autocomplete
                    id="airportOrfir"
                    name="airportOrfir"
                    options={surveyAllAirports}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      formik.setFieldValue("airportOrfir", value.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="Airport Id"
                        value={formik.values.airportOrfir}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.airportOrfir &&
                          Boolean(formik.errors.airportOrfir)
                        }
                        helperText={
                          formik.touched.airportOrfir &&
                          formik.errors.airportOrfir
                        }
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                )}
                {(selectedSurveyor?.role === userType.aai ||
                  selectedUserType === userType.aai) && (
                  <Autocomplete
                    id="airportOrfir"
                    name="airportOrfir"
                    options={FIRAirspaceOption}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                      formik.setFieldValue("airportOrfir", value.designation);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label="FIR"
                        value={formik.values.airportOrfir}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.airportOrfir &&
                          Boolean(formik.errors.airportOrfir)
                        }
                        helperText={
                          formik.touched.airportOrfir &&
                          formik.errors.airportOrfir
                        }
                        sx={{ mb: 2 }}
                      />
                    )}
                  />
                )}
              </form>
            </DialogContent>
            <DialogActions>
              <OutlinedButton
                label="Cancel"
                color="secondary"
                onClick={handleCloseAddDialog}
                sx={{ width: 120 }}
              />
              {!isEditDialog && (
                <PrimaryButton
                  label="Create"
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoadingPostSurveyorRegistration}
                  isLoading={isLoadingPostSurveyorRegistration}
                  sx={{ width: 120 }}
                />
              )}
              {isEditDialog && (
                <PrimaryButton
                  label="Update"
                  onClick={() => formik.handleSubmit()}
                  disabled={isLoadingEditSurveyorRegistration}
                  isLoading={isLoadingEditSurveyorRegistration}
                  sx={{ width: 120 }}
                />
              )}
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={openResetPasswordDialog}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
              <form>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="new_password"
                  name="new_password"
                  label="New Password"
                  value={passwordResetFormik.values.new_password}
                  onChange={passwordResetFormik.handleChange}
                  error={
                    passwordResetFormik.touched.new_password &&
                    Boolean(passwordResetFormik.errors.new_password)
                  }
                  helperText={
                    passwordResetFormik.touched.new_password &&
                    passwordResetFormik.errors.new_password
                  }
                  sx={{ mb: 2, mt: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={passwordResetFormik.values.new_password}
                          onCopy={() => setCopied(true)}
                        >
                          <IconButton
                            edge="end"
                            color={isCopied ? "primary" : "default"}
                          >
                            <FaCopy />
                          </IconButton>
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <OutlinedButton
                label="Cancel"
                color="secondary"
                onClick={handleCloseResetPasswordDialog}
                sx={{ width: 120, p: "2.75px 15px" }}
              />
              <PrimaryButton
                label="Reset Password"
                sx={{ p: "3.75px 15px" }}
                onClick={() => passwordResetFormik.handleSubmit()}
                disabled={isResetingMasterPassword}
                isLoading={isResetingMasterPassword}
              />
            </DialogActions>
          </Dialog>
          {openDeleteDialog && (
            <ConfirmationDialog
              fullWidth
              open={openDeleteDialog}
              title={`${selectedSurveyor?.enable ? "Disable" : "Enable"} ${
                userLabel[filterUserType]
              }`}
              content={`Are you sure you want to ${
                selectedSurveyor?.enable ? "disable" : "enable"
              } ${userLabel[filterUserType]} "${decryptStatic(
                selectedSurveyor?.name,
                aesKey
              )}" ?`}
              saveButtonLabel={selectedSurveyor?.enable ? "Disable" : "Enable"}
              isLoading={isLoadingDisableSurveyor || isLoadingEnableSurveyor}
              isDisabled={isLoadingDisableSurveyor || isLoadingEnableSurveyor}
              handleSave={handleDisableSurveyor}
              handleCancel={handleCloseDeleteDialog}
            />
          )}
        </div>
      </div>
    </div>
  );
}
