import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import ArrowleftIcon from "@assets/svg/ArrowleftIcon";
import { ACCESS_TOKEN } from "@constants/constant";
import AixmMap from "@components/AixmOSMMap";
import AixmAirportDetailTables from "@components/aixm-table-item/AixmAirportDetailTables";
import Header from "@components/Header";
import AirportReviewSideBar from "@components/AirportReviewSideBar";
import { getAllNewAirports, getAAIAirport } from "@store/airport/actions";
import { setAAIMuiAirport, setAllAirportsCharts } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function AirportReview() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const navigate = useNavigate();
  const allNewAirports = useSelector((state) => state.airport.allAirports);
  const { AAIMuiAirport, isLoadingAAIAirport } = useSelector(
    (state) => state.airport
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selctedAirport, setSelctedAirport] = useState(null);

  useEffect(() => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(getAllNewAirports(token));
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const selctedAirport = allNewAirports.find(
      (airport) => airport.id === Number(routeParams.airportId)
    );

    setSelctedAirport(selctedAirport);
  }, [allNewAirports]); // eslint-disable-line

  const selectedAdcategoryData = (catType, arpOption) => {
    dispatch(
      getAAIAirport(catType, arpOption.id, arpOption.version_id, (data) => {
        dispatch(
          setAAIMuiAirport({
            ...AAIMuiAirport,
            [`${catType}`]: data,
          })
        );
      })
    );
  };

  const handleAirportSelectedCategory = (category) => {
    setSelectedCategory(category);
    if (isEmpty(category)) return;
    switch (category.codeId) {
      case "AD2.2":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.geoadministrative))
          return null;
        selectedAdcategoryData("geoadministrative", selctedAirport);
        break;
      case "AD2.3":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.operationalhours))
          return null;
        selectedAdcategoryData("operationalhours", selctedAirport);
        break;
      case "AD2.4":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.handlingservice))
          return null;
        selectedAdcategoryData("handlingservice", selctedAirport);
        break;
      case "AD2.5":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.passengerfacilities))
          return null;
        selectedAdcategoryData("passengerfacilities", selctedAirport);
        break;
      case "AD2.6":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rescueservices))
          return null;
        selectedAdcategoryData("rescueservices", selctedAirport);
        break;
      case "AD2.7":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.seasonalservice))
          return null;
        selectedAdcategoryData("seasonalservice", selctedAirport);
        break;
      case "AD2.8apr":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.apron)) return null;
        selectedAdcategoryData("apron", selctedAirport);
        break;
      case "AD2.8txy":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.taxiway)) return null;
        selectedAdcategoryData("taxiway", selctedAirport);
        break;
      case "AD2.8chk":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.checkpoints)) return null;
        selectedAdcategoryData("checkpoints", selctedAirport);
        break;
      case "AD2.9":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.surfacmovements))
          return null;
        selectedAdcategoryData("surfacmovements", selctedAirport);
        break;
      case "AD2.10":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aixmobstacles))
          return null;
        selectedAdcategoryData("aixmobstacles", selctedAirport);
        break;
      case "AD2.11":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.meteorologicalinfo))
          return null;
        selectedAdcategoryData("meteorologicalinfo", selctedAirport);
        break;
      case "AD2.12rwydetail":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.runwayphysicals))
          return null;
        selectedAdcategoryData("runwayphysicals", selctedAirport);
        break;
      case "AD2.13":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.declareddistaces))
          return null;
        selectedAdcategoryData("declareddistaces", selctedAirport);
        break;
      case "AD2.14":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.rwylights)) return null;
        selectedAdcategoryData("rwylights", selctedAirport);
        break;
      case "AD2.15":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.powerlights)) return null;
        selectedAdcategoryData("powerlights", selctedAirport);
        break;
      case "AD2.17":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airspaceservice))
          return null;
        selectedAdcategoryData("airspaceservice", selctedAirport);
        break;
      case "AD2.18":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.airtrafficservices))
          return null;
        selectedAdcategoryData("airtrafficservices", selctedAirport);
        break;
      case "AD2.19":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.navaids)) return null;
        selectedAdcategoryData("navaids", selctedAirport);
        break;
      case "AD2.20":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aerodromeregulationinfo))
          return null;
        selectedAdcategoryData("aerodromeregulationinfo", selctedAirport);
        break;
      case "AD2.21":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight)) return null;
        selectedAdcategoryData("noiseflight", selctedAirport);
        break;
      case "AD2.22":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.noiseflight)) return null;
        selectedAdcategoryData("noiseflight", selctedAirport);
        break;
      case "AD2.23":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.aerodromeregulationinfo))
          return null;
        selectedAdcategoryData("aerodromeregulationinfo", selctedAirport);
        break;
      case "AD2.24":
        if (!AAIMuiAirport && !isEmpty(AAIMuiAirport.charts)) return null;
        dispatch(
          getAAIAirport(
            "charts",
            selctedAirport.id,
            selctedAirport.version_id,
            (data) => {
              dispatch(
                setAAIMuiAirport({
                  ...AAIMuiAirport,
                  charts: data,
                })
              );
              dispatch(setAllAirportsCharts(data));
            }
          )
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="wrapper">
      <Header active="data-management" />
      <div
        className="main"
        style={{ width: "100%", height: "calc(100vh - 54px)" }}
      >
        <div className="content-area">
          <Box
            className="surveyor-category-table"
            style={{ padding: 24, paddingTop: 0 }}
          >
            <Box sx={{ ...styles.reviewDatasetBar, m: "0px", height: "72px" }}>
              <Box sx={styles.reviewDatasetBarinr}>
                <IconButton
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ p: 0, ml: "-8px" }}
                >
                  <ArrowleftIcon />
                </IconButton>
                <Typography
                  variant="span"
                  color="black"
                  sx={{ pl: 1, fontWeight: 500, fontSize: 16 }}
                >
                  back to selection
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "20px", fontWeight: 600 }}
                >
                  {selctedAirport?.name} ({selctedAirport?.designator})
                </Typography>
              </Box>
              <Box
                sx={{
                  ...styles.reviewDatasetBarinr,
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    Publication Date
                  </Typography>
                  <Typography variant="body2">
                    {selctedAirport?.published_date}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                  >
                    Effective Date
                  </Typography>
                  <Typography variant="body2">
                    {selctedAirport?.effective_date}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                ...styles.arpReviewCategoryList,
                margin: 0,
                height: "calc(100% - 73px)",
              }}
            >
              <AirportReviewSideBar
                selctedAirport={selctedAirport}
                selectedCategory={selectedCategory}
                handleAirportSelectedCategory={handleAirportSelectedCategory}
              />
              {selctedAirport && isEmpty(selectedCategory) && (
                <AixmMap
                  classname="airport-review-map"
                  airportLocation={{
                    lat: selctedAirport?.dd_latitude,
                    lng: selctedAirport?.dd_longitude,
                  }}
                  zoomLevel={15}
                  selctedAirport={selctedAirport}
                />
              )}
              {selectedCategory && (
                <div
                  className="aixm-category-table"
                  style={styles.aixmCatTable}
                >
                  <Box sx={styles.airportDetailTable}>
                    {isLoadingAAIAirport &&
                      selectedCategory.codeId !== "AD2.10" && (
                        <LinearProgress />
                      )}
                    {selectedCategory && !isLoadingAAIAirport && (
                      <AixmAirportDetailTables
                        activeCategory={selectedCategory}
                        selctedAirport={selctedAirport}
                      />
                    )}
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
