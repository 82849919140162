import { theme } from "./theme";

export const commonStyles = {
  selectScrollbar: {
    container: (base) => ({ ...base }),
    menuList: (base) => ({
      ...base,
      height: "auto",
      border: `1px solid ${theme.palette.grey.main}`,
      borderRadius: "4px",
      "::-webkit-scrollbar": {
        width: "4px",
        background: theme.palette.white.main,
      },
      "::-webkit-scrollbar-thumb": {
        background: theme.palette.grey.main,
        borderRadius: "3px",
      },
    }),
  },
  selectDateScrollbar: {
    container: (base) => ({ ...base, width: 220 }),
    menuList: (base) => ({
      ...base,
      height: "auto",
      border: `1px solid ${theme.palette.grey.main}`,
      borderRadius: "4px",
      "::-webkit-scrollbar": {
        width: "4px",
        background: theme.palette.white.main,
      },
      "::-webkit-scrollbar-thumb": {
        background: theme.palette.grey.main,
        borderRadius: "3px",
      },
    }),
  },
};
