import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd27ValidationSchema } from "@constants/ad2Data";
import { AD27initialValue } from "@constants/ad2DataForm.constant";
import { PrimaryButton, LightButton } from "@components/button";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad27SeasonalserviceForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd27Form.setFieldValue(
      "CLEARANCE_EQUIPMENT",
      AAIAirport?.CLEARANCE_EQUIPMENT ? AAIAirport?.CLEARANCE_EQUIPMENT : ""
    );
    createAd27Form.setFieldValue(
      "CLEARANCE_PRIORITIES",
      AAIAirport?.CLEARANCE_PRIORITIES ? AAIAirport?.CLEARANCE_PRIORITIES : ""
    );

    createAd27Form.setFieldValue(
      "SEASONAL_REMARKS",
      AAIAirport?.SEASONAL_REMARKS ? AAIAirport?.SEASONAL_REMARKS : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "seasonalservice",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd27Form.resetForm();
    createAd27Form.setSubmitting(false);
  };

  const createAd27Form = useFormik({
    enableReinitialize: true,
    initialValues: AD27initialValue,
    validationSchema: createAd27ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "seasonalservice",
            values,
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Type(s) of clearing equipment</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd27Form.touched?.CLEARANCE_EQUIPMENT &&
                  Boolean(createAd27Form.errors?.CLEARANCE_EQUIPMENT)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="CLEARANCE_EQUIPMENT"
                  name="CLEARANCE_EQUIPMENT"
                  value={createAd27Form.values.CLEARANCE_EQUIPMENT}
                  onChange={createAd27Form.handleChange}
                  aria-describedby="aerodrome-CLEARANCE_EQUIPMENT"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-CLEARANCE_EQUIPMENT">
                  {createAd27Form.touched?.CLEARANCE_EQUIPMENT &&
                    createAd27Form.errors?.CLEARANCE_EQUIPMENT}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Clearance priorities</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd27Form.touched?.CLEARANCE_PRIORITIES &&
                  Boolean(createAd27Form.errors?.CLEARANCE_PRIORITIES)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="CLEARANCE_PRIORITIES"
                  name="CLEARANCE_PRIORITIES"
                  value={createAd27Form.values.CLEARANCE_PRIORITIES}
                  onChange={createAd27Form.handleChange}
                  readOnly={!isReadOnly}
                  aria-describedby="aerodrome-CLEARANCE_PRIORITIES"
                />
                <FormHelperText id="aerodrome-CLEARANCE_PRIORITIES">
                  {createAd27Form.touched?.CLEARANCE_PRIORITIES &&
                    createAd27Form.errors?.CLEARANCE_PRIORITIES}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd27Form.touched?.SEASONAL_REMARKS &&
                  Boolean(createAd27Form.errors?.SEASONAL_REMARKS)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="SEASONAL_REMARKS"
                  name="SEASONAL_REMARKS"
                  value={createAd27Form.values.SEASONAL_REMARKS}
                  onChange={createAd27Form.handleChange}
                  readOnly={!isReadOnly}
                  aria-describedby="aerodrome-SEASONAL_REMARKS"
                />
                <FormHelperText id="aerodrome-SEASONAL_REMARKS">
                  {createAd27Form.touched?.SEASONAL_REMARKS &&
                    createAd27Form.errors?.SEASONAL_REMARKS}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd27Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd27Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad27SeasonalserviceForm;
