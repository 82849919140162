import React from "react";
import Typography from "@mui/material/Typography";
import RowStack from "@components/tabel-component/RowStack";

export default function SurveyorTopTableRow({ serveyorName, userId }) {
  return (
    <RowStack
      direction="row"
      justifyContent="space-between"
      sx={{ position: "fixed", width: "calc(100vw - 312px)" }}
    >
      <Typography variant="body1" gutterBottom>
        SURVEYOR NAME1: {serveyorName ?? "-"}
      </Typography>
      <Typography variant="body1" gutterBottom>
        UNIQUE ID: {userId ?? "-"}
      </Typography>
    </RowStack>
  );
}
