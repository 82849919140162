import { isEmpty } from "lodash";
import RenderNavigationalRouteAirspaceUsePlan from "./RenderNavigationalRouteAirspaceUsePlan";

export default function RenderRNAVLiveTrackerAUP({
  filterRNAVAUPdata,
  RNAVRouteLiveGeometryData,
  handleAirspaceUsePlanDetails,
}) {
  if (isEmpty(filterRNAVAUPdata) || isEmpty(RNAVRouteLiveGeometryData))
    return null;

  return (
    filterRNAVAUPdata &&
    filterRNAVAUPdata.map((data) => {
      const routekey = data.id;

      return (
        <RenderNavigationalRouteAirspaceUsePlan
          key={data.id}
          filterRNAVAirspace={data}
          routeData={RNAVRouteLiveGeometryData[`${routekey}`] || []}
          handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
        />
      );
    })
  );
}
