import { useEffect } from "react";
import { LayersControl, useMap } from "react-leaflet";
import { mapStyles } from "@constants/constant";
import GoogleMapLayer from "./GoogleMapLayer";

const { BaseLayer } = LayersControl;

export default function MapLayers({ type = mapStyles.roadmap }) {
  const map = useMap();

  useEffect(() => {
    map.on("baselayerchange", function (e) {});
  }, []); // eslint-disable-line

  return (
    <LayersControl position="bottomright">
      <BaseLayer checked={type === mapStyles.roadmap} name="Google Maps Roads">
        <GoogleMapLayer />
      </BaseLayer>
      <BaseLayer
        checked={type === mapStyles.satellite}
        name="Google Maps Satellite"
      >
        <GoogleMapLayer maptype={mapStyles.satellite} />
      </BaseLayer>
      {/* <BaseLayer
        checked={type === mapStyles.terrain}
        name="Google Maps Terrain"
      >
        <GoogleMapLayer maptype={mapStyles.terrain} />
      </BaseLayer>
      <BaseLayer checked={type === mapStyles.osm} name="OpenStreetMap">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
      </BaseLayer> */}
    </LayersControl>
  );
}
