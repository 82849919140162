import React, { Fragment, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LightButton, PrimaryButton, UploadButton } from "@components/button";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { ACCESS_TOKEN } from "@constants/constant";
import { createAd228ValidationSchema } from "@constants/ad2Data";
import {
  addAAIAirportDetailUpload,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport, setAllAirportsCharts } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad224ChartsForm({
  selectedAirport,
  allAirportsCharts,
}) {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const { isLoadingAddAAIAirportDetailUpload } = useSelector(
    (state) => state.airport
  );
  const isLoadingAirportChartList = useSelector(
    (state) => state.survey.isLoadingAirportChartList
  );
  const [chartFile, setChartFile] = useState(null);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "charts",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
          dispatch(setAllAirportsCharts(data));
        }
      )
    );
  };

  const createAd228Form = useFormik({
    enableReinitialize: true,
    initialValues: {
      file_name: "",
      file: "",
    },
    validationSchema: createAd228ValidationSchema,
    onSubmit: (values) => {
      if (isEmpty(chartFile)) return;
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addAAIAirportDetailUpload(
            "charts",
            selectedAirport.id,
            selectedAirport.version_id,
            chartFile[0],
            token,
            handleSuccessFileUploadCallback,
            values.file_name
          )
        );
      });
    },
  });

  const handleChartFileUpload = (event) => {
    event.preventDefault();

    const files = event.target.files;
    setChartFile(files);
    createAd228Form.setFieldValue("file", "uploded");
  };

  const handleSuccessFileUploadCallback = () => {
    createAd228Form.resetForm();
    createAd228Form.setSubmitting(false);
    setChartFile(null);
    handleGetADCallBack();
  };

  return (
    <Fragment>
      <Box sx={styles.ad224Chart}>
        <form style={{ width: "80%" }}>
          <Stack alignItems="center">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              id="file_name"
              name="file_name"
              label="File Name"
              sx={{
                ".MuiOutlinedInput-input": {
                  padding: "6.75px 14px",
                },
                
              }}
              value={createAd228Form.values.file_name}
              onChange={createAd228Form.handleChange}
              error={
                createAd228Form.touched.file_name &&
                Boolean(createAd228Form.errors.file_name)
              }
              helperText={
                createAd228Form.touched.file_name &&
                createAd228Form.errors.file_name
              }
            />
          </Stack>
        </form>
        <Stack sx={{ width: 200 }}>
          <UploadButton
            ref={uploadRef}
            label="Select File"
            accept=".pdf"
            disabled={isLoadingAddAAIAirportDetailUpload}
            handleUpload={handleChartFileUpload}
            sx={{ width: "100%" }}
          />
        </Stack>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ pr: 2, mb: 3 }}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd228Form.resetForm();
            createAd228Form.setSubmitting(false);
            setChartFile(null);
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd228Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetailUpload}
          disabled={isLoadingAddAAIAirportDetailUpload}
          sx={{ width: 200 }}
        />
      </Stack>
      {isLoadingAirportChartList && (
        <Stack alignItems="center">Loading...</Stack>
      )}
      {!isEmpty(allAirportsCharts) && allAirportsCharts.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Chart Name</StyledTableCell>
                <StyledTableCell>Download</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAirportsCharts.map((row, i) => {
                return (
                  <TableRow key={`${i}_${row?.id}`}>
                    <StyledTableCell>{row?.chart_name}</StyledTableCell>
                    <StyledTableCell>
                      <a href={row?.url} rel="noopener noreferrer">
                        {row?.chart_name}
                      </a>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
}
