import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Table,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

function AirTrafficAirspaceService({
  selctedAirport,
  activeCategory,
  airspaceservice,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport?.name} {">"} {activeCategory?.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {!isEmpty(airspaceservice) ? (
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Airspace designation, geographical coordinates and lateral
                  limits
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.lateral_limit || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Vertical limits
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.upper_limit || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Airspace classification
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.classification || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Call sign and language(s) of the air traffic services unit
                  providing service
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.callsign || "-"}
                  {" , "}
                  {airspaceservice.language || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Transition altitude
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.transition_altitude || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Hours of applicability
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.hours || "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>Remark</StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {airspaceservice.remark || "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default AirTrafficAirspaceService;
