import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";

export default function Operationalhours({
  selctedAirport,
  activeCategory,
  operationalhours,
}) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {operationalhours ? (
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Aerodrome Operator
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.areodome_op || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Custom and immigration
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.custom || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Health and sanitation
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.health || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  AIS briefing office
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.bof || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  ATS reporting office (ARO)
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.aro || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  MET Briefing office
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.met || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Air Traffic Service
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.air_traffic || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Fuelling
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.fuel || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Handling
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.handling || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  Security
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.security || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>
                  De-icing
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>{"NIL"}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>Remarks</StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {operationalhours.remarks || "NIL"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}
