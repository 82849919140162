import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { includes } from "lodash";
import { getStorageValue } from "@utils/localStorage";
import { ACCESS_TOKEN, roles } from "@constants/constant";

const PrivateRoute = ({
  children,
  uroles = [roles.admin, roles.surveyor, roles.aai],
  access,
}) => {
  const location = useLocation();
  const accessToken = getStorageValue(ACCESS_TOKEN);

  if (!accessToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (accessToken) {
    const decodeAccessToken = jwtDecode(accessToken);

    const isRoleAccess = includes(uroles, decodeAccessToken.user_claims.role);


    if (
      access !== undefined &&
      !includes(decodeAccessToken.user_claims.module_access, access)
    ) {
      return <Navigate to="/" />;
    }

    if (!isRoleAccess) {
      return <Navigate to="/" />;
    }
  }

  return children;
};

export default PrivateRoute;
