import { useMemo } from "react";
import WMTSDemTileLayer from "@components/map/WMTSDemTileLayer";

const RenderDems = ({ etodDems, selectedDems }) => {
  const dems = etodDems.filter((dem) => {
    if (dem.sub_category === "2D") {
      return selectedDems.includes("Area2D");
    }

    return selectedDems.includes(`${dem.rwy}_${dem.sub_category}`);
  });

  const layers = useMemo(() => {
    return dems.map((dem) => {
      return <WMTSDemTileLayer key={dem.dem_gs_layer_name} dem={dem} />;
    });

    // eslint-disable-next-line
  }, [selectedDems]);

  return <>{layers}</>;
};

export default RenderDems;
