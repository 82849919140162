import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalize, includes } from "lodash";
import { toast } from "react-toastify";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import LogoutIcon from "@assets/svg/LogoutIcon";
import UserIcon from "@assets/svg/UserIcon";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import { setLoginCallbakUrl } from "@store/userSession";
import { userLogout } from "@store/userSession/actions";
import { getUserRegion } from "@store/userSession";
import { getSurveyorAirport } from "@store/survey/actions";
import { styles } from "@styles/headerStyles";
import { decryptValue } from "@utils/decodeEncodeData";
import { shortLabel } from "@utils/util";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import NavLink from "./NavLink";
import BrandLogo from "./BrandLogo";
import { PrimaryButton } from "./button";

export default function Header({ active }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSession.user);
  const userRegion = useSelector((state) => getUserRegion(state));
  const accesstoken = getStorageValue(ACCESS_TOKEN);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const open = Boolean(anchorElUser);

  useEffect(() => {
    if (!currentUser) return false;
    if (currentUser?.role === roles.surveyor) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(getSurveyorAirport(token));
      });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavClick = (pathname) => {
    dispatch(setLoginCallbakUrl(pathname));
  };

  const handleLogOut = () => {
    dispatch(userLogout(handleLogoutCallback));
  };

  const handleLogoutCallback = () => {
    navigate(routeNames.Authentication, { replace: true });
    window.location.reload();
  };

  const handleLogin = () => {
    navigate(routeNames.Authentication);
  };

  const handleNoaccess = (e) => {
    e.preventDefault();
    toast.error("No Access! Login first.");
  };

  return (
    <div className="header">
      <BrandLogo />
      <Box sx={{ ...styles.flexBetween, width: "calc(100% - 272px)" }}>
        <Box sx={{ ...styles.flexBetween, width: "85%" }}>
          {currentUser && includes(currentUser.module_access, "aixm") && (
            <NavLink
              to={routeNames.Aixm}
              isActive={active === "aixm"}
              onClick={() => handleNavClick(routeNames.Aixm)}
            >
              AIXM
            </NavLink>
          )}
          {currentUser && includes(currentUser.module_access, "airspace") && (
            <NavLink
              to={routeNames.Airspace}
              isActive={active === "airspace"}
              onClick={() => handleNavClick(routeNames.Airspace)}
            >
              ENROUTE
            </NavLink>
          )}
          {currentUser &&
            includes(currentUser.module_access, "data-management") && (
              <NavLink
                to={accesstoken ? routeNames.UserDashboard : "/noaccess"}
                isActive={active === "data-management"}
                onClick={(e) =>
                  !accesstoken
                    ? handleNoaccess(e)
                    : handleNavClick(routeNames.UserDashboard)
                }
              >
                DATA MANAGEMENT
              </NavLink>
            )}
          {currentUser &&
            includes(currentUser.module_access, "electronic-chart") && (
              <NavLink
                to={routeNames.eChart}
                isActive={active === "electronic-chart"}
                onClick={() => handleNavClick(routeNames.eChart)}
              >
                ELECTRONIC CHART
              </NavLink>
            )}
          {currentUser && includes(currentUser.module_access, "etod") && (
            <NavLink
              to={routeNames.Etod}
              isActive={active === "etod"}
              onClick={() => handleNavClick(routeNames.Etod)}
            >
              eTOD
            </NavLink>
          )}
          {currentUser &&
            includes(currentUser.module_access, "obstacle-analysis") && (
              <NavLink
                to={routeNames.ObstacleAnalysis}
                isActive={active === "obstacle-analysis"}
                onClick={() => handleNavClick(routeNames.ObstacleAnalysis)}
              >
                OBSTACLE ANALYSIS
              </NavLink>
            )}
          {currentUser && includes(currentUser.module_access, "amdb") && (
            <NavLink
              to={routeNames.Amdb}
              isActive={active === "amdb"}
              onClick={() => handleNavClick(routeNames.Amdb)}
            >
              AMDB
            </NavLink>
          )}
        </Box>
        {currentUser && accesstoken ? (
          <Box sx={{ ...styles.flexCenter, width: "13%" }}>
            <IconButton
              onClick={handleOpenUserMenu}
              size="medium"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <UserIcon />
            </IconButton>
          </Box>
        ) : (
          <>
            <PrimaryButton
              label="Log In"
              type="submit"
              onClick={handleLogin}
              className="header-login-btn mr-20"
            />
          </>
        )}
      </Box>
      {currentUser && accesstoken && (
        <Menu
          sx={styles.userMenu}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem sx={{ minWidth: "200px" }}>
            <Box sx={styles.userName}>
              <Avatar sx={{ bgcolor: "#59178A", mr: 1 }}>
                {shortLabel(
                  decryptValue(currentUser.userName).replace(/_/g, " ")
                )}
              </Avatar>
              <Box>
                <Typography sx={styles.userAvatar}>
                  {capitalize(
                    decryptValue(currentUser.userName).replace(/_/g, " ")
                  )}
                </Typography>
                <Typography sx={styles.userRegion}>
                  {userRegion || ""}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleLogOut()}>
            <Box sx={styles.logoutIcon}>
              <LogoutIcon className="logout-icon" />
              <Typography className="icon-text">Log out</Typography>
            </Box>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
