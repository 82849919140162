import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, keys } from "lodash";
import { Box, Typography } from "@mui/material";
import { selectedGeneralCategoryData } from "@store/aixm";
import { styles } from "@styles/aixmStyles";
import { theme } from "@styles/theme";
import { replaceOTHER, replaceUnderScore } from "@utils/util";

function AixmGeneral({ selectedGeneral, selectedGeneralCategory }) {
  const selectedGenCategoryData = useSelector((state) =>
    selectedGeneralCategoryData(state, selectedGeneral, selectedGeneralCategory)
  );
  const [selectedObjKeys, setSelectedObjKeys] = useState([]);

  useEffect(() => {
    setSelectedObjKeys([]);
    if (!isEmpty(selectedGenCategoryData)) {
      if (
        typeof selectedGenCategoryData === "object" &&
        !Array.isArray(selectedGenCategoryData)
      ) {
        setSelectedObjKeys(keys(selectedGenCategoryData));
      }
    }
  }, [selectedGenCategoryData, selectedGeneral, selectedGeneralCategory]);

  return (
    <Box className="aixm-general-main">
      {!isEmpty(selectedGeneral) && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <Typography sx={styles.GENLabel}>
            General &gt;&gt; {selectedGeneral.label}{" "}
            {selectedGeneralCategory &&
              `  >>  ` + selectedGeneralCategory.label}
          </Typography>
          <Box className="selected-general-data">
            {!isEmpty(selectedObjKeys) &&
              !isEmpty(selectedGeneralCategory) &&
              selectedObjKeys.map((data) => {
                if (
                  typeof selectedGenCategoryData[data] === "object" &&
                  !Array.isArray(selectedGenCategoryData[data])
                ) {
                  return keys(selectedGenCategoryData[data]).map((dd) => {
                    if (
                      Array.isArray(selectedGenCategoryData[data][dd]) &&
                      !isEmpty(selectedGenCategoryData[data][dd])
                    ) {
                      return (
                        <Box key={dd} sx={{ ml: 1.5, mt: 1 }}>
                          <table className="AmdtTable">
                            <thead>
                              <tr>
                                {selectedGenCategoryData[data][dd][0] &&
                                  keys(
                                    selectedGenCategoryData[data][dd][0]
                                  ).map((head) => {
                                    return (
                                      <th style={styles.AmdtTableHead}>
                                        {head}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              {selectedGenCategoryData[data][dd] &&
                                selectedGenCategoryData[data][dd].map((d) => {
                                  return (
                                    <tr>
                                      {keys(d).map((inrd) => {
                                        return <td>{d[inrd]}</td>;
                                      })}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </Box>
                      );
                    } else {
                      if (
                        typeof selectedGenCategoryData[data][dd] === "object" &&
                        !Array.isArray(selectedGenCategoryData[data][dd])
                      ) {
                        return keys(selectedGenCategoryData[data][dd]).map(
                          (k) => {
                            return (
                              <Typography
                                key={k}
                                sx={{ ml: 1.5, pt: 1.5, mt: 1 }}
                              >{`${k} : ${selectedGenCategoryData[data][dd][k]}`}</Typography>
                            );
                          }
                        );
                      }
                      return (
                        <Box key={dd} sx={{ ml: 1.5, mt: 1 }}>
                          {!isEmpty(dd) && (
                            <Typography
                              sx={{
                                backgroundColor: theme.palette.secondary.light,
                                mb: 1,
                              }}
                            >
                              {replaceOTHER(dd)}
                            </Typography>
                          )}
                          {!isEmpty(selectedGenCategoryData[data][dd]) && (
                            <Box
                              sx={{ whiteSpace: "pre-line" }}
                              dangerouslySetInnerHTML={{
                                __html: selectedGenCategoryData[data][dd],
                              }}
                            />
                          )}
                        </Box>
                      );
                    }
                  });
                } else {
                  if (
                    Array.isArray(selectedGenCategoryData[data]) &&
                    !isEmpty(selectedGenCategoryData[data])
                  ) {
                    return selectedGenCategoryData[data].map((data, ind) => {
                      if (data?.contact) {
                        return (
                          <Box
                            key={`${data?.name}-${ind}`}
                            sx={{ ml: 1.5, pt: 1.5, mt: 1 }}
                          >
                            <Typography className="general-inr-heading">
                              {data?.name}
                            </Typography>
                            <Box className="general-array-body-table">
                              {data &&
                                typeof data === "object" &&
                                !Array.isArray(data) && (
                                  <table>
                                    <thead>
                                      <tr>
                                        {data?.contact &&
                                          keys(data?.contact).map(
                                            (objkey, ind) => {
                                              return (
                                                <th key={`${objkey}-${ind}`}>
                                                  {objkey}
                                                </th>
                                              );
                                            }
                                          )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        {keys(data?.contact).map((objkey) => {
                                          return (
                                            <td key={`${objkey}-${ind}`}>
                                              {data?.contact[objkey]}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                            </Box>
                          </Box>
                        );
                      }
                      return (
                        <Box
                          className="general-array-body-table"
                          sx={{ pl: 1.5, mt: 1, mb: 1 }}
                        >
                          <table style={{ width: "100%" }}>
                            <thead>
                              <tr>
                                {data &&
                                  keys(data).map((objkey, ind) => {
                                    return (
                                      <th key={`${objkey}-${ind}`}>
                                        {replaceUnderScore(objkey)}
                                      </th>
                                    );
                                  })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {keys(data).map((objkey) => {
                                  return (
                                    <td key={`${objkey}-${ind}`}>
                                      {data[objkey]}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </Box>
                      );
                    });
                  }

                  return (
                    <Box key={data} sx={{ mt: 0.5 }}>
                      {!isEmpty(data) && (
                        <Typography
                          sx={{
                            backgroundColor: theme.palette.secondary.light,
                            mb: 1,
                            pl: 1.5,
                          }}
                        >
                          {replaceOTHER(data)}
                        </Typography>
                      )}
                      {!isEmpty(selectedGenCategoryData[data]) && (
                        <Box
                          sx={{ whiteSpace: "pre-line", pl: 1.5 }}
                          dangerouslySetInnerHTML={{
                            __html: selectedGenCategoryData[data],
                          }}
                        />
                      )}
                    </Box>
                  );
                }
              })}
            {Array.isArray(selectedGenCategoryData) &&
              selectedGenCategoryData.map((data, ind) => {
                return (
                  Array.isArray(data?.contacts) && (
                    <Box
                      key={`${data?.name}-${ind}`}
                      sx={{ ml: 1.5, pt: 1.5, mt: 1 }}
                    >
                      <Typography className="general-inr-heading">
                        {data?.name}
                      </Typography>
                      <Box className="general-array-body-table">
                        <table>
                          <thead>
                            <tr>
                              {data?.contacts &&
                                data?.contacts.length > 0 &&
                                keys(data?.contacts[0]).map((objkey, i) => {
                                  return (
                                    <th key={`${objkey}-${i}`}>{objkey}</th>
                                  );
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.contacts.map((d, i) => {
                              return (
                                <tr>
                                  {keys(d).map((objkey, j) => {
                                    return (
                                      <td key={`${objkey}-${i}-${j}`}>
                                        {d[objkey]}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  )
                );
              })}

            {isEmpty(selectedGenCategoryData) && (
              <Typography sx={{ m: 1, textAlign: "center" }}>
                No data found!
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AixmGeneral;
