import React from "react";
import { theme } from "@styles/theme";

export const SubmitIcon = ({ disabled, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.3855 7.0785C12.5065 7.1285 12.6165 7.2015 12.7075 7.2935L15.7075 10.2935C16.0975 10.6835 16.0975 11.3165 15.7075 11.7075C15.5115 11.9025 15.2555 12.0005 14.9995 12.0005C14.7445 12.0005 14.4885 11.9025 14.2925 11.7075L12.9995 10.4145V16.0005C12.9995 16.5525 12.5525 17.0005 11.9995 17.0005C11.4475 17.0005 10.9995 16.5525 10.9995 16.0005V10.3365L9.6905 11.5855C9.2905 11.9665 8.6585 11.9525 8.2765 11.5525C7.8955 11.1525 7.9105 10.5205 8.3095 10.1385L11.3095 7.2765C11.3333 7.25393 11.3608 7.2388 11.3884 7.22362C11.4061 7.21392 11.4237 7.2042 11.4405 7.1925C11.4547 7.18273 11.4686 7.17268 11.4825 7.16267C11.5283 7.12965 11.5736 7.09698 11.6265 7.0755C11.7135 7.0405 11.8055 7.0305 11.8975 7.0205C11.9132 7.01876 11.9278 7.01473 11.9422 7.01074C11.9608 7.00559 11.9792 7.0005 11.9995 7.0005C12.0005 7.0005 12.0015 7.00076 12.0025 7.00101C12.0035 7.00126 12.0045 7.0015 12.0055 7.0015C12.1355 7.0015 12.2645 7.0275 12.3855 7.0785Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
  </svg>
);
