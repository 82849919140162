import React from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { TreeView, TreeItem } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ACCESS_TOKEN } from "@constants/constant";
import { styles } from "@styles/amdbStyles";
import { commonStyles } from "@styles/commonStyles";
import { getStorageValue } from "@utils/localStorage";
import DropDownLabel from "./DropDownLabel";
import { PrimaryButton, LightButton } from "./button";
import { theme } from "../styles/theme";

export default function AmdbSideBar({
  isEditShape,
  isLoadingAllAirportamdb,
  isLoadingAMDBDetail,
  isExportGeoJSONLoading,
  allAirports = [],
  features = [],
  selectedFeatures = [],
  selctedAirport,
  isActiveAirport,
  exportGeoJSON = [],
  handleFeatureCheck,
  handleParentFeatureCheck,
  handleSelectAllFeature,
  handleClearAllFeature,
  handleAirport,
  handleExportGeoJSON,
}) {
  const isDisabled = isActiveAirport || isLoadingAMDBDetail || isEditShape;
  const currentUser = useSelector((state) => state.userSession.user);
  const accesstoken = getStorageValue(ACCESS_TOKEN);

  const handleClickFeatureLabel = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="sidebar">
      <Box>
        <DropDownLabel label="Airport" isRequired />
        <Select
          isClearable
          isLoading={isLoadingAllAirportamdb}
          value={selctedAirport || null}
          placeholder="Select..."
          onChange={handleAirport}
          options={allAirports}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          styles={commonStyles.selectScrollbar}
        />
      </Box>
      {isLoadingAMDBDetail ? (
        <LinearProgress sx={{ mt: 2, mb: 1 }} />
      ) : (
        <Divider sx={{ mt: 2, mb: 1 }} />
      )}
      <div className="check-list-header">
        <Typography sx={styles.sidebarFeaturelabel}>Features</Typography>
        <div className="check-list-action">
          <LightButton
            label="SELECT ALL"
            onClick={handleSelectAllFeature}
            disabled={isDisabled}
            sx={styles.checklistBtn}
          />
          <LightButton
            label="CLEAR ALL"
            onClick={handleClearAllFeature}
            disabled={isDisabled}
            sx={styles.checklistBtn}
          />
        </div>
      </div>
      <div className="check-list amdb-check-list">
        <TreeView
          aria-label="amdb-features"
          defaultCollapseIcon={<FaAngleDown />}
          defaultExpandIcon={<FaAngleRight />}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "4px",
              backgroundColor: theme.palette.white.main,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "5px",
            },
          }}
        >
          {features.map((feature) => {
            return (
              <TreeItem
                key={feature.id.toString()}
                nodeId={feature.id.toString()}
                label={
                  <FormControlLabel
                    label={feature.name}
                    disabled={isDisabled}
                    onClick={(e) => handleClickFeatureLabel(e)}
                    control={
                      <Checkbox
                        checked={selectedFeatures.includes(
                          `${feature.value}__${feature.color}`
                        )}
                        onChange={(event) => {
                          handleParentFeatureCheck(
                            event,
                            feature,
                            feature.color
                          );
                        }}
                        sx={{
                          color: feature.color,
                          "&.Mui-checked": {
                            color: feature.color,
                          },
                        }}
                      />
                    }
                  />
                }
              >
                {feature.sub &&
                  feature.sub.map((layer1) => (
                    <TreeItem
                      key={layer1.id.toString()}
                      nodeId={layer1.id.toString()}
                      label={
                        <FormControlLabel
                          label={layer1.name}
                          disabled={isDisabled}
                          control={
                            <Checkbox
                              checked={selectedFeatures.includes(
                                `${layer1.value}__${layer1.color}`
                              )}
                              onChange={() => {
                                handleFeatureCheck(layer1, layer1.color);
                              }}
                              sx={{
                                color: layer1.color,
                                "&.Mui-checked": {
                                  color: layer1.color,
                                },
                              }}
                            />
                          }
                        />
                      }
                    />
                  ))}
              </TreeItem>
            );
          })}
        </TreeView>
      </div>
      <div className="amdb-sidebar-footer">
        <PrimaryButton
          isLoading={isExportGeoJSONLoading}
          disabled={
            isDisabled ||
            exportGeoJSON.length <= 0 ||
            !Boolean(accesstoken) ||
            !currentUser?.user_access?.export
          }
          label="Export GeoJSON"
          onClick={() => handleExportGeoJSON()}
          sx={{ width: "100%" }}
        />
      </div>
    </div>
  );
}
