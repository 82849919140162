import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function Seasonalservice({ selctedAirport, activeCategory, seasonalservice }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {seasonalservice ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Type(s) of clearing equipment
                </StyledTableCell>
                <StyledTableCell>
                  {seasonalservice.CLEARANCE_EQUIPMENT
                    ? seasonalservice.CLEARANCE_EQUIPMENT
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Clearance priorities
                </StyledTableCell>
                <StyledTableCell>
                  {seasonalservice.CLEARANCE_PRIORITIES
                    ? seasonalservice.CLEARANCE_PRIORITIES
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>Remarks</StyledTableCell>
                <StyledTableCell>
                  {seasonalservice.SEASONAL_REMARKS
                    ? seasonalservice.SEASONAL_REMARKS
                    : "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Seasonalservice;
