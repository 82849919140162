export const AD22initialValue = {
  site: "",
  dir_distance_city: "",
  elevation: "",
  fieldElevationAccuracy: "",
  temp: "",
  dateMagneticVariation: "",
  magneticVariation: "",
  magneticVariationChange: "",
  magneticVariationAccuracy: "",
  traffic_type: "",
  verticalDatum: "",
  authority_name: "",
  address: "",
  telephone: "",
  fax: "",
  afs: "",
  email: "",
  remark: "",
};

export const AD23initialValue = {
  air_traffic: "",
  areodome_op: "",
  aro: "",
  bof: "",
  custom: "",
  fuel: "",
  handling: "",
  health: "",
  met: "",
  security: "",
  remarks: "",
};

export const fuelBody = {
  category: "",
  remark: "",
};

export const oilBody = {
  category: "",
  remark: "",
};

export const AD24initialValue = {
  Cargo_handling_facilities: "",
  Deicing: "",
  Fuel: [fuelBody],
  Fuelling_facilities: "",
  HANDLING_SERVICES_REMARK: "",
  HANGAR: "",
  Oil: [oilBody],
  REPAIR: "",
};

export const AD25initialValue = {
  BANK: "",
  HOTEL: "",
  INFO: "",
  MEDIC: "",
  POST: "",
  REST: "",
  TRANSPORT: "",
  REMARKS: "",
};

export const AD26initialValue = {
  REMOVAL_AIRCRAFT: "",
  RESCUE_EQPT_DESC: "",
  REMARKS: "",
};

export const AD27initialValue = {
  CLEARANCE_EQUIPMENT: "",
  CLEARANCE_PRIORITIES: "",
  SEASONAL_REMARKS: "",
};

export const apronDetail = {
  id: "",
  appronName: "",
  composition: "",
  classPCN: "",
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
};

export const INSDetail = {
  id: "",
  lattitude: "",
  longitude: "",
  elevation: "",
};

export const VORDetail = {
  id: "",
  lattitude: "",
  longitude: "",
  elevation: "",
};

export const taxiwayDetail = {
  id: "",
  txwyname: "",
  type: "",
  width: "",
  widthShoulder: "",
  length: "",
  composition: "",
  classPCN: "",
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
};

export const AD29initialValue = {
  aircraftstands: "",
  markings: "",
  stopbars: "",
  remarks: "",
};

export const AD210initialValue = {
  elevation: "",
  horizontalDatum: "",
  lat: "",
  lng: "",
  lighted: "",
  marking: "",
  obstacle_type: "",
  runwaydirection_id: "",
  uuid: "",
  verticalDatum: "",
  remarks: "",
};

export const AD211initialValue = {
  metName: "",
  office: "",
  hours: "",
  air_traffic: "",
  briefing: "",
  charts: "",
  flight_documentation: "",
  forecast: "",
  sup_met: "",
  hours_remarks: "",
  remarks: "",
};

export const runwayDetail = {
  id: "",
  designator: "",
  trueBearing: "",
  thr_lat: "",
  thr_lng: "",
  end_lat: "",
  end_lng: "",
  thr_el: "",
  tdz_el: "",
  slopeTDZ: "",
  resalength: "",
  resawidth: "",
  stopwaylength: "",
  stopwaywidth: "",
  clearwaylength: "",
  clearwaywidth: "",
  ofz: "",
  remarks: "",
};

export const AD212CreateFormInitialValue = {
  nominalLength: "",
  nominalWidth: "",
  lengthStrip: "",
  widthStrip: "",
  lengthAccuracy: "",
  widthAccuracy: "",
  widthShoulder: "",
  composition: "",
  classPCN: "",
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
  runways: [runwayDetail, runwayDetail],
};

export const AD212EditFormInitialValue = {
  nominalLength: "",
  nominalWidth: "",
  lengthStrip: "",
  widthStrip: "",
  lengthAccuracy: "",
  widthAccuracy: "",
  widthShoulder: "",
  composition: "",
  classPCN: "",
  pavementTypePCN: "",
  pavementSubgradePCN: "",
  maxTyrePressurePCN: "",
  evaluationMethodPCN: "",
  designator: "",
  trueBearing: "",
  thr_lat: "",
  thr_lng: "",
  end_lat: "",
  end_lng: "",
  thr_el: "",
  tdz_el: "",
  slopeTDZ: "",
  resalength: "",
  resawidth: "",
  stopwaylength: "",
  stopwaywidth: "",
  clearwaylength: "",
  clearwaywidth: "",
  ofz: "",
  remarks: "",
};

export const AD213bodyDetail = {
  asda: "",
  lda: "",
  rd: "",
  toda: "",
  tora: "",
  remarks: "",
};

 export const AD214bodyDetail = {
   rd: "",
   approachlightingsystems: {
     classICAO: "",
     intensityLevel: "",
     length: "",
   },
   clrunwaylights: { colour: "", intensityLevel: "", length: "", spacing: "" },
   edgerunwaylights: {
     colour: "",
     intensityLevel: "",
     length: "",
     spacing: "",
   },
   endrunwaylights: { colour: "" },
   stopwaylights: { colour: "", length: "" },
   thrrunwaylights: { colour: "", wingbar: "" },
   visualglideslopeindicators: {
     minimumEyeHeightOverThreshold: "",
     position: "",
     slopeAngle: "",
     type: "",
   },
   remarks: "",
 };

export const AD215initialValue = {
  colour: "",
  flashing: "",
  groundlightdescrpt: "",
  landingDirectionIndicator: "",
  landingDirectiondescrpt: "",
  secondaryPowerSupply: "",
  secondaryPowerSupplydescrpt: "",
  windDirectionIndicator: "",
  windDirectiondescrpt: "",
};

export const AD217initialValue = {
  callsign: "",
  classification: "",
  hours: "",
  language: "",
  lateral_limit: "",
  transition_altitude: "",
  upper_limit: "",
  remark: "",
};

export const airtrafficserviceDetail = {
  id: "",
  callsign: "",
  designation: "",
  frequency: "",
  logon: "",
  operationalstatus: "",
  satvoiceNumber: "",
  remarks: "",
};

export const navidDetail = {
  id: "",
  identification: "",
  type: "",
  rwy_dir: "",
  signalPerformance: "",
  LOC: {
    frequency: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    remarks: "",
  },
  GP: {
    frequency: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    remarks: "",
  },
  DME: {
    channel: "",
    latitude: "",
    longitude: "",
    hours_of_operation: "",
    elevation: "",
    remarks: "",
  },
  frequency: "",
  channel: "",
  latitude: "",
  longitude: "",
  hours_of_operation: "",
  elevation: "",
  class: "",
  remarks: "",
};