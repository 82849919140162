import * as yup from "yup";

export const rwyDesignationDir = [
  {
    id: 0,
    label: "Clear",
    value: "",
  },
  {
    id: 1,
    label: "R",
    value: "R",
  },
  {
    id: 2,
    label: "L",
    value: "L",
  },
  {
    id: 3,
    label: "C",
    value: "C",
  },
];

export const icaoPrefixTypes = [
  {
    id: 1,
    label: "VO",
    value: "VO",
  },
  {
    id: 2,
    label: "VE",
    value: "VE",
  },
  {
    id: 3,
    label: "VA",
    value: "VA",
  },
  {
    id: 4,
    label: "VI",
    value: "VI",
  },
];

export const airportDataCategories = [
  {
    id: 1,
    code: "(AD 2.2)",
    codeId: "AD2.2",
    label: "Aerodrome Geographical and Administrative Data",
    value: "AD 2.2 Aerodrome Geographical and Administrative Data",
  },
  {
    id: 2,
    code: "(AD 2.3)",
    codeId: "AD2.3",
    label: "Operational Hours",
    value: "AD 2.3 Operational Hours",
  },
  {
    id: 3,
    code: "(AD 2.4)",
    codeId: "AD2.4",
    label: "Handling Services and Facilities",
    value: "AD 2.4 Handling Services and Facilities",
  },
  {
    id: 4,
    code: "(AD 2.5)",
    codeId: "AD2.5",
    label: "Passenger Facilities",
    value: "AD 2.5 Passenger Facilities",
  },
  {
    id: 5,
    code: "(AD 2.6)",
    codeId: "AD2.6",
    label: "Rescue and Fire Fighting Services",
    value: "AD 2.6 Rescue and Fire Fighting Services",
  },
  {
    id: 6,
    code: "(AD 2.7)",
    codeId: "AD2.7",
    label: "Seasonal Availability Clearing",
    value: "AD 2.7 Seasonal Availability Clearing",
  },
  {
    id: 7,
    code: "(AD 2.8)",
    codeId: "AD2.8apr",
    label: "Apron",
    value: "AD 2.8 Apron",
  },
  {
    id: 8,
    code: "(AD 2.8)",
    codeId: "AD2.8txy",
    label: "Taxiway",
    value: "AD 2.8 Taxiway",
  },
  {
    id: 9,
    code: "(AD 2.8)",
    codeId: "AD2.8chk",
    label: "Checkpoints",
    value: "AD 2.8 Checkpoints",
  },
  {
    id: 10,
    code: "(AD 2.9)",
    codeId: "AD2.9",
    label: "Surface Movement Guidance And Control System And Markings",
    value: "AD 2.9 Surface Movement Guidance And Control System And Markings",
  },
  {
    id: 11,
    code: "(AD 2.10)",
    codeId: "AD2.10",
    label: "Aerodrome Obstacles",
    value: "AD 2.10 Aerodrome Obstacles",
  },
  {
    id: 12,
    code: "(AD 2.11)",
    codeId: "AD2.11",
    label: "Meteorological Information Provided",
    value: "AD 2.11 Meteorological Information Provided",
  },
  {
    id: 13,
    code: "(AD 2.12)",
    codeId: "AD2.12rwydetail",
    label: "Runway Physical Characteristics",
    value: "AD 2.12 Runway Details",
  },
  {
    id: 15,
    code: "(AD 2.13)",
    codeId: "AD2.13",
    label: "Declared Distances",
    value: "AD 2.13 Declared Distances",
  },
  {
    id: 16,
    code: "(AD 2.14)",
    codeId: "AD2.14",
    label: "Approach and Runway Lighting",
    value: "AD 2.14 Approach and Runway Lighting",
  },
  {
    id: 17,
    code: "(AD 2.15)",
    codeId: "AD2.15",
    label: "Other Lighting, Secondary Power Supply",
    value: "AD 2.15 Other Lighting, Secondary Power Supply",
  },
  // {
  //   id: 18,
  //   code: "(AD 2.16)",
  //   codeId: "AD2.16",
  //   label: "Helicopter Landing Area",
  //   value: "AD 2.16 Helicopter Landing Area",
  // },
  {
    id: 19,
    code: "(AD 2.17)",
    codeId: "AD2.17",
    label: "Air Traffic Service Airspace",
    value: "AD 2.17 Air Traffic Service Airspace",
  },
  {
    id: 20,
    code: "(AD 2.18)",
    codeId: "AD2.18",
    label: "Air Traffic Services Communication Facilities",
    value: "AD 2.18 Air Traffic Services Communication Facilities",
  },
  {
    id: 21,
    code: "(AD 2.19)",
    codeId: "AD2.19",
    label: "Radio Navigation and Landing Aids",
    value: "AD 2.19 Radio Navigation and Landing Aids",
  },
  {
    id: 22,
    code: "(AD 2.20)",
    codeId: "AD2.20",
    label: "Local Aerodrome Regulations",
    value: "AD 2.20 Local Aerodrome Regulations",
  },
  {
    id: 23,
    code: "(AD 2.21)",
    codeId: "AD2.21",
    label: "Noise Abatement Procedures",
    value: "AD 2.21 Noise Abatement Procedures",
  },
  {
    id: 24,
    code: "(AD 2.22)",
    codeId: "AD2.22",
    label: "Flight Procedures",
    value: "AD 2.22 Flight Procedures",
  },
  {
    id: 25,
    code: "(AD 2.23)",
    codeId: "AD2.23",
    label: "Additional Information",
    value: "AD 2.23 Additional Information",
  },
  {
    id: 26,
    code: "(AD 2.24)",
    codeId: "AD2.24",
    label: "Charts Related to an Aerodromes",
    value: "AD 2.24 Charts Related to an Aerodromes",
  },
];

export const ad2Category = {
  ad2_2_aerodom_geographical_and_administrative_data:
    "AD 2.2 Aerodrome Geographical and Administrative Data",
  ad2_3_operationalhours: "AD 2.3 Operational Hours",
  ad2_4_handlingservice: "AD 2.4 Handling Services and Facilities",
  ad2_5_passenger_facilities: "AD 2.5 Passenger Facilities",
  ad2_6_rescueservices: "AD 2.6 Rescue and Fire Fighting Services",
  ad2_7_seasonalservice: "AD 2.7 Seasonal Availability Clearing",
  ad2_8_apron: "AD 2.8 Apron",
  ad2_8_taxiway: "AD 2.8 Taxiway",
  ad2_8_checkpoints: "AD 2.8 Checkpoints",
  ad2_9_surfacmovements:
    "AD 2.9 Surface Movement Guidance And Control System And Markings",
  ad2_10_aerodom_obstacles: "AD 2.10 Aerodrome Obstacles",
  ad2_11_meteorological: "AD 2.11 Meteorological Information Provided",
  ad2_12_runway_details: "AD 2.12 Runway Details",
  ad2_13: "AD 2.13 Declared Distances",
  ad2_14: "AD 2.14 Approach and Runway Lighting",
  ad2_15: "AD 2.15 Other Lighting, Secondary Power Supply",
  ad2_17: "AD 2.17 Air Traffic Service Airspace",
  ad2_18_airtraffic_service:
    "AD 2.18 Air Traffic Services Communication Facilities",
  ad2_19_navids: "AD 2.19 Radio Navigation and Landing Aids",
  ad2_20: "AD 2.20 Local Aerodrome Regulations",
  ad2_21_noise: "AD 2.21 Noise Abatement Procedures",
  ad2_22_flight: "AD 2.22 Flight Procedures",
  ad2_23: "AD 2.23 Additional Information",
  ad2_24_charts: "AD 2.24 Charts Related to an Aerodromes",
};

export const ad2ExportCategoryTypes = [
  {
    id: 1,
    label: "Airport Heliport",
    value: "airportheliport",
  },
  {
    id: 2,
    label: "(AD 2.12) Runway",
    value: "runway",
  },
  {
    id: 3,
    label: "Runway Direction",
    value: "runway_direction",
  },
  {
    id: 4,
    label: "Runway Protect Area",
    value: "runway_protect_area",
  },
  {
    id: 5,
    label: "(AD 2.10) Aerodrome Obstacles",
    value: "obstacles",
  },
  {
    id: 6,
    label: "(AD 2.8) Apron",
    value: "apron",
  },
  {
    id: 7,
    label: "(AD 2.8) Taxiway",
    value: "taxiway",
  },
];

export const AirportStatus = {
  notStarted: "Not Started",
  inProcess: "In Process",
  closed: "Closed",
};

// export const hoursOfOperation = [
//   {
//     id: 1,
//     label: "H24",
//     value: "H24",
//   },
//   {
//     id: 2,
//     label: "HJ",
//     value: "HJ",
//   },
//   {
//     id: 3,
//     label: "HN",
//     value: "HN",
//   },
//   {
//     id: 4,
//     label: "HX",
//     value: "HX",
//   },
//   {
//     id: 5,
//     label: "HO",
//     value: "HO",
//   },
//   {
//     id: 6,
//     label: "NOTAM",
//     value: "NOTAM",
//   },
// ];

export const Ad212SurfaceTypes = [
  {
    id: 1,
    label: "(ASPH) Asphalt",
    value: "ASPH",
  },
  {
    id: 2,
    label: "(ASPH_GRASS) Asphalt and grass",
    value: "ASPH_GRASS",
  },
  {
    id: 3,
    label: "(CONC) Concrete",
    value: "CONC",
  },
  {
    id: 4,
    label: "(CONC_ASPH) Concrete and asphalt",
    value: "CONC_ASPH",
  },
  {
    id: 5,
    label: "(CONC_GRS) Concrete and grass",
    value: "CONC_GRS",
  },
  {
    id: 6,
    label: "(GRASS) Grass including portions of turf or bare earth",
    value: "GRASS",
  },
  {
    id: 7,
    label: "(SAND) Sand",
    value: "SAND",
  },
  {
    id: 8,
    label: "(WATER) Water",
    value: "WATER",
  },
  {
    id: 9,
    label: "(BITUM) Bituminous tar or asphalt",
    value: "BITUM",
  },
  {
    id: 10,
    label: "(BRICK) Brick",
    value: "BRICK",
  },
  {
    id: 11,
    label:
      "(MACADAM) A macadam or tarmac surface consisting of water-bound crushed rock",
    value: "MACADAM",
  },
  {
    id: 12,
    label: "(STONE) Stone",
    value: "STONE",
  },
  {
    id: 13,
    label: "(CORAL) Coral",
    value: "CORAL",
  },
  {
    id: 14,
    label: "(CLAY) Clay",
    value: "CLAY",
  },
  {
    id: 15,
    label: "(LATERITE) Laterite - a high iron clay formed in tropical areas",
    value: "LATERITE",
  },
  {
    id: 16,
    label: "(GRAVEL) Gravel",
    value: "GRAVEL",
  },
  {
    id: 17,
    label: "(EARTH) Earth (in general)",
    value: "EARTH",
  },
  {
    id: 18,
    label: "(ICE) Ice",
    value: "ICE",
  },
  {
    id: 19,
    label: "(SNOW) Snow",
    value: "SNOW",
  },
  {
    id: 20,
    label: "(MEMBRANE) A protective laminate usually made of rubber",
    value: "MEMBRANE",
  },
  {
    id: 21,
    label: "(METAL) Metal - steel, aluminium",
    value: "METAL",
  },
  {
    id: 22,
    label: "(MATS) Landing mat portable system usually made of aluminium",
    value: "MATS",
  },
  {
    id: 23,
    label: "(PIERCED_STEEL) Pierced steel planking",
    value: "PIERCED_STEEL",
  },
  {
    id: 24,
    label: "(WOOD) Wood",
    value: "WOOD",
  },
  {
    id: 25,
    label: "(NON_BITUM_MIX) Non Bituminous mix",
    value: "NON_BITUM_MIX",
  },
  {
    id: 26,
    label: "Other",
    value: "OTHER",
  },
];

export const SurfaceTypesKeyVaue = {
  ASPH: "Asphalt",
  ASPH_GRASS: "Asphalt and grass",
  CONC: "Concrete",
  CONC_ASPH: "Concrete and asphalt",
  CONC_GRS: "Concrete and grass",
  GRASS: "Grass including portions of turf or bare earth",
  SAND: "Sand",
  WATER: "Water",
  BITUM: "Bituminous tar or asphalt",
  BRICK: "Brick",
  BRIMACADAMCK:
    "A macadam or tarmac surface consisting of water-bound crushed rock",
  STONE: "Stone",
  CORAL: "Coral",
  CLAY: "Clay",
  LATERITE: "Laterite - a high iron clay formed in tropical areas",
  GRAVEL: "Gravel",
  EARTH: "Earth (in general)",
  ICE: "Ice",
  SNOW: "Snow",
  MEMBRANE: "A protective laminate usually made of rubber",
  METAL: "Metal - steel, aluminium",
  MATS: "Landing mat portable system usually made of aluminium",
  PIERCED_STEEL: "Pierced steel planking",
  WOOD: "Wood",
  NON_BITUM_MIX: "Non Bituminous mix",
  OTHER: "Other",
};

export const Ad212PavementTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "Rigid pavement",
    value: "RIGID",
  },
  {
    id: 3,
    label: "Flexible pavement",
    value: "FLEXIBLE",
  },
  {
    id: 4,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212EveluationTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(TECH) Technical evaluation",
    value: "TECH",
  },
  {
    id: 3,
    label: "(ACFT) Based on aircraft experience",
    value: "ACFT",
  },
  {
    id: 4,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212MaxTyrePressureTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(W) High: No pressure limit",
    value: "W",
  },
  {
    id: 3,
    label: "(X) Medium: Pressure limited to 1.5 MPa (217 psi)",
    value: "X",
  },
  {
    id: 4,
    label: "(Y) Low: Pressure limited to 1.00 MPa (145 psi)",
    value: "Y",
  },
  {
    id: 5,
    label: "(Z) Very low: Pressure limited to 0.50 MPa (73 psi)",
    value: "Z",
  },
  {
    id: 6,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212PavementSubgradeTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "(A) High strength sub-grade",
    value: "A",
  },
  {
    id: 3,
    label: "(B)Medium strength sub-grade",
    value: "B",
  },
  {
    id: 4,
    label: "(C) Low strength sub-grade",
    value: "C",
  },
  {
    id: 5,
    label: "(D) Ultra-low strength sub-grade",
    value: "D",
  },
  {
    id: 6,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212ApproachLightingTypes = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label:
      "(ALSAF) 3000 Foot high intensity Approach Lighting System with Sequenced Flashing Lights",
    value: "ALSAF",
  },
  {
    id: 3,
    label: "(MALS) 1400 Foot Medium Intensity Approach Lighting System",
    value: "MALS",
  },
  {
    id: 4,
    label:
      "(MALSR) 1400 Foot Medium Intensity Approach Lighting System with Runway Alignment Indicator Lights",
    value: "MALSR",
  },
  {
    id: 5,
    label: "(SALS) Simple Approach Lighting System",
    value: "SALS",
  },
  {
    id: 6,
    label: "(SSALS) Simplified Short Approach Lighting System",
    value: "SSALS",
  },
  {
    id: 7,
    label:
      "(SSALR) Simplified Short Approach Lighting System with Runway Alignment Indicator Lights",
    value: "SSALR",
  },
  {
    id: 8,
    label: "(LDIN) Lead-In Lighting System",
    value: "LDIN",
  },
  {
    id: 9,
    label: "(ODALS) Omni Directional Approach Lighting System",
    value: "ODALS",
  },
  {
    id: 10,
    label:
      "(AFOVRN) US Air Force Overrun 1000 Foot Standard Approach Lighting System",
    value: "AFOVRN",
  },
  {
    id: 11,
    label: "(MILOVRN) Military Overrun",
    value: "MILOVRN",
  },
  {
    id: 12,
    label: "(CALVERT) Calvert Approach Lighting System",
    value: "CALVERT",
  },
  {
    id: 13,
    label: "Other",
    value: "OTHER",
  },
];

export const Ad212Colours = [
  {
    id: 1,
    label: "nilReason",
    value: "nilReason",
  },
  {
    id: 2,
    label: "Yellow",
    value: "YELLOW",
  },
  {
    id: 3,
    label: "Red",
    value: "RED",
  },
  {
    id: 4,
    label: "White",
    value: "WHITE",
  },
  {
    id: 5,
    label: "Blue",
    value: "BLUE",
  },
  {
    id: 6,
    label: "Green",
    value: "GREEN",
  },
  {
    id: 7,
    label: "Purple",
    value: "PURPLE",
  },
  {
    id: 8,
    label: "Orange",
    value: "ORANGE",
  },
  {
    id: 9,
    label: "amber",
    value: "AMBER",
  },
  {
    id: 10,
    label: "Black",
    value: "BLACK",
  },
  {
    id: 11,
    label: "Brown",
    value: "BROWN",
  },
  {
    id: 12,
    label: "Grey",
    value: "GREY",
  },
  {
    id: 13,
    label: "Light grey",
    value: "LIGHT_GREY",
  },
  {
    id: 14,
    label: "Magenta",
    value: "MAGENTA",
  },
  {
    id: 15,
    label: "Pink",
    value: "PINK",
  },
  {
    id: 16,
    label: "Violet",
    value: "VIOLET",
  },
  {
    id: 17,
    label: "Other",
    value: "OTHER",
  },
];

export const navidTypes = [
  {
    id: 1,
    label: "ILS",
    value: "ILS",
  },
  {
    id: 2,
    label: "ILS_DME",
    value: "ILS_DME",
  },
  {
    id: 3,
    label: "NDB",
    value: "NDB",
  },
  {
    id: 4,
    label: "MKR",
    value: "MKR",
  },
  {
    id: 5,
    label: "DME",
    value: "DME",
  },
  {
    id: 6,
    label: "VOR_DME",
    value: "VOR_DME",
  },
];

export const hoursOfOperation = [
  {
    id: 1,
    label: "H24",
    value: "H24",
  },
  {
    id: 2,
    label: "HJ",
    value: "HJ",
  },
  {
    id: 3,
    label: "HN",
    value: "HN",
  },
  {
    id: 4,
    label: "HX",
    value: "HX",
  },
  {
    id: 5,
    label: "HO",
    value: "HO",
  },
  {
    id: 6,
    label: "NOTAM",
    value: "NOTAM",
  },
  {
    id: 7,
    label: "ASATS",
    value: "ASATS",
  },
];

const icaoRegEx = /^[A-Z]{2}$/;
// const latRegEx = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
// const lngRegEx = /^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g;
export const createAirportValidationSchema = yup.object().shape({
  icaoPrefix: yup.string().required("Required"),
  icao: yup
    .string()
    .min(2, "Min 2 characters")
    .max(2, "Max 2 characters")
    .matches(icaoRegEx, "Enter a valid code")
    .required("Required"),
  name: yup.string("Enter airport name").required("Required"),
  latitude: yup.string().required("Required"),
  longitude: yup.string().required("Required"),
  effective_date: yup.string().required("Required"),
  published_date: yup.string().required("Required"),
});

// const phoneRegEx =
//   /^((\\+[1-9]{1,4}[\\-]*)|(\\([0-9]{2,3}\\)[\\-]*)|([0-9]{2,4})[\\-]*)*?[0-9]{3,4}?[\\-]*[0-9]{3,4}?$/;
// const numberRegEx = /^[0-9]*$/;
const numberRegEx = /^\d*\.?\d*$/;

export const createAd22tValidationSchema = yup.object().shape({
  site: yup.string().required("site is required"),
  dir_distance_city: yup
    .string()
    .required("Direction Distance and City is required"),
  elevation: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Elevation is required"),
  temp: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Temperature is required"),
  dateMagneticVariation: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Magnetic variation is required"),
  magneticVariation: yup.string().required("Date of information is required"),
  magneticVariationChange: yup
    .string()
    .matches(numberRegEx, "Number is not valid")
    .required("Annual change is required"),
  traffic_type: yup.string().required("Types of traffic permitted is required"),
  authority_name: yup
    .string()
    .required("Name of aerodrome operator name is required"),
  address: yup.string().required("Address is required"),
  telephone: yup
    .string()
    // .min(10, "Phone Number min 10 characters")
    // .max(10, "Phone Number max 10 characters")
    // .matches(phoneRegEx, "Phone Number is not valid")
    .required("Required"),
  email: yup.string().email().required("Email is required"),
  // remark: yup.string().required("Remark is required"),
});

export const createAd23ValidationSchema = yup.object().shape({
  // air_traffic: yup.string().required("Required"),
  areodome_op: yup.string().required("Required"),
  // aro: yup.string().required("Required"),
  // bof: yup.string().required("Required"),
  // custom: yup.string().required("Required"),
  // fuel: yup.string().required("Required"),
  // handling: yup.string().required("Required"),
  health: yup.string().required("Required"),
  // met: yup.string().required("Required"),
  // security: yup.string().required("Required"),
});

export const createAd217ValidationSchema = yup.object().shape({
  callsign: yup.string().required("Required"),
  classification: yup.string().required("Required"),
  // hours: yup.string().required("Required"),
  // language: yup.string().required("Required"),
  // lateral_limit: yup.string().required("Required"),
  // transition_altitude: yup.string().required("Required"),
  // upper_limit: yup.string().required("Required"),
});

export const createAd211ValidationSchema = yup.object().shape({
  metName: yup.string().required("Required"),
  office: yup.string().required("Required"),
  hours: yup.string().required("Required"),
  air_traffic: yup.string().required("Required"),
  briefing: yup.string().required("Required"),
  charts: yup.string().required("Required"),
  flight_documentation: yup.string().required("Required"),
  forecast: yup.string().required("Required"),
  sup_met: yup.string().required("Required"),
});

export const createAd25tValidationSchema = yup.object().shape({
  BANK: yup.string().required("Required"),
  HOTEL: yup.string().required("Required"),
  INFO: yup.string().required("Required"),
  MEDIC: yup.string().required("Required"),
  POST: yup.string().required("Required"),
  REST: yup.string().required("Required"),
  TRANSPORT: yup.string().required("Required"),
});

export const createAd215ValidationSchema = yup.object().shape({
  colour: yup.string().required("Required"),
  flashing: yup.string().required("Required"),
  groundlightdescrpt: yup.string().required("Required"),
  landingDirectionIndicator: yup.string().required("Required"),
  landingDirectiondescrpt: yup.string().required("Required"),
  secondaryPowerSupply: yup.string().required("Required"),
  secondaryPowerSupplydescrpt: yup.string().required("Required"),
  windDirectionIndicator: yup.string().required("Required"),
  windDirectiondescrpt: yup.string().required("Required"),
});

export const createAd218ValidationSchema = yup.object({
  airtrafficservices: yup.array().of(
    yup.object().shape({
      callsign: yup.string().required("Required"),
      designation: yup.string().required("Required"),
      // frequency: yup.string().required("Required"),
      // logon: yup.string().required("Required"),
      // operationalstatus: yup.string().required("Required"),
      // satvoiceNumber: yup.string().required("Required"),
    })
  ),
});

export const createAd26ValidationSchema = yup.object().shape({
  REMOVAL_AIRCRAFT: yup.string().required("Required"),
  RESCUE_EQPT_DESC: yup.string().required("Required"),
});

export const createAd27ValidationSchema = yup.object().shape({
  CLEARANCE_EQUIPMENT: yup.string().required("Required"),
  CLEARANCE_PRIORITIES: yup.string().required("Required"),
});

export const createAd29ValidationSchema = yup.object().shape({
  aircraftstands: yup.string().required("Required"),
  markings: yup.string().required("Required"),
  stopbars: yup.string().required("Required"),
});

export const createAd228ValidationSchema = yup.object().shape({
  file_name: yup.string().required("Required"),
  file: yup.string().required("Required"),
});

// const rwyDsgRegEx = /^[0-9]{2}$/;
export const createAd212ValidationSchema = yup.object({
  nominalLength: yup.string().required("Required"),
  nominalWidth: yup.string().required("Required"),
  lengthStrip: yup.string().required("Required"),
  widthStrip: yup.string().required("Required"),
  lengthAccuracy: yup.string().required("Required"),
  widthAccuracy: yup.string().required("Required"),
  composition: yup.string().required("Required"),
  classPCN: yup.string().required("Required"),
  pavementTypePCN: yup.string().required("Required"),
  pavementSubgradePCN: yup.string().required("Required"),
  maxTyrePressurePCN: yup.string().required("Required"),
  evaluationMethodPCN: yup.string().required("Required"),
  runways: yup.array().of(
    yup.object().shape({
      designator: yup.string().required("Required"),
      // trueBearing: yup.string().required("Required"),
      // thr_lat: yup.string().required("Required"),
      // thr_lng: yup.string().required("Required"),
      // end_lat: yup.string().required("Required"),
      // end_lng: yup.string().required("Required"),
      // thr_el: yup.string().required("Required"),
      // tdz_el: yup.string().required("Required"),
      // slopeTDZ: yup.string().required("Required"),
      // resalength: yup.string().required("Required"),
      // resawidth: yup.string().required("Required"),
      // stopwaylength: yup.string().required("Required"),
      // stopwaywidth: yup.string().required("Required"),
      // clearwaylength: yup.string().required("Required"),
      // clearwaywidth: yup.string().required("Required"),
    })
  ),
});

export const createAd28ApronValidationSchema = yup.object({
  aprons: yup.array().of(
    yup.object().shape({
      appronName: yup.string().required("Required"),
      // composition: yup.string().required("Required"),
      // classPCN: yup.string().required("Required"),
      // pavementTypePCN: yup.string().required("Required"),
      // pavementSubgradePCN: yup.string().required("Required"),
      // maxTyrePressurePCN: yup.string().required("Required"),
      // evaluationMethodPCN: yup.string().required("Required"),
    })
  ),
});

export const createAd28TaxiwayValidationSchema = yup.object({
  taxiways: yup.array().of(
    yup.object().shape({
      txwyname: yup.string().required("Required"),
      type: yup.string().required("Required"),
      // width: yup.string().required("Required"),
      // widthShoulder: yup.string().required("Required"),
      // length: yup.string().required("Required"),
      // composition: yup.string().required("Required"),
      // classPCN: yup.string().required("Required"),
      // pavementTypePCN: yup.string().required("Required"),
      // pavementSubgradePCN: yup.string().required("Required"),
      // maxTyrePressurePCN: yup.string().required("Required"),
      // evaluationMethodPCN: yup.string().required("Required"),
    })
  ),
});

export const createAd28CheckpointsValidationSchema = yup.object({
  INS: yup.array().of(
    yup.object().shape({
      lattitude: yup.string().required("Required"),
      longitude: yup.string().required("Required"),
      elevation: yup.string().required("Required"),
    })
  ),
  VOR: yup.array().of(
    yup.object().shape({
      lattitude: yup.string().required("Required"),
      longitude: yup.string().required("Required"),
      elevation: yup.string().required("Required"),
    })
  ),
});

export const createAd24ValidationSchema = yup.object({
  Cargo_handling_facilities: yup.string().required("Required"),
  Deicing: yup.string().required("Required"),
  Fuel: yup.array().of(
    yup.object().shape({
      category: yup.string().required("Required"),
    })
  ),
  Fuelling_facilities: yup.string().required("Required"),
  HANGAR: yup.string().required("Required"),
  Oil: yup.array().of(
    yup.object().shape({
      category: yup.string().required("Required"),
    })
  ),
  REPAIR: yup.string().required("Required"),
});

export const createAd221ValidationSchema = yup.object({
  noise: yup.string().required("Required"),
});

export const createAd222ValidationSchema = yup.object({
  flight: yup.string().required("Required"),
});

/* eslint-disable */
export const createAd219NavidValidationSchema = yup.object({
  navids: yup.array().of(
    yup.object().shape({
      identification: yup.string().required("Required"),
      type: yup.string().required("Required"),
      rwy_dir: yup.string().required("Required"),
      LOC: yup.object().when("type", {
        is: "ILS",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          signalperformance: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      GP: yup.object().when("type", {
        is: "ILS",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      LOC: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          signalperformance: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      GP: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          frequency: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      DME: yup.object().when("type", {
        is: "ILS_DME",
        then: yup.object({
          channel: yup.string().required("Required"),
          latitude: yup.string().required("Required"),
          longitude: yup.string().required("Required"),
          hours_of_operation: yup.string().required("Required"),
          elevation: yup.string().required("Required"),
          remarks: yup.string().required("Required"),
        }),
      }),
      frequency: yup.string().when("type", {
        is: "NBD",
        then: yup.string().required("Required"),
      }),
      frequency: yup.string().when("type", {
        is: "MKR",
        then: yup.string().required("Required"),
      }),
      frequency: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      channel: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      channel: yup.string().when("type", {
        is: "DME",
        then: yup.string().required("Required"),
      }),
      latitude: yup.string().when("type", {
        is: "DME",
        then: yup.string().required("Required"),
      }),
      longitude: yup.string().when("type", {
        is: "DME",
        then: yup.string().required("Required"),
      }),
      channel: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      latitude: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      longitude: yup.string().when("type", {
        is: "VOR_DME",
        then: yup.string().required("Required"),
      }),
      // latitude: yup.string().required("Required"),
      // longitude: yup.string().required("Required"),
      // hours_of_operation: yup.string().required("Required"),
      // elevation: yup.string().required("Required"),
      // remarks: yup.string().required("Required"),
    })
  ),
});
/* eslint-enable */
