import React, { Fragment, useState } from "react";
import { Polygon } from "react-leaflet";
import { flatten, chain } from "lodash";
import { areaHighLightColor } from "@constants/colors";

export default function RenderOLSHorizontalSurface({
  OLSHorizontalSurfaceList,
  selectedOls,
  getOLSColors,
}) {
  const [selectedOverE, setSelectedOverE] = useState(null);
  const olsColor = getOLSColors();
  const visibleOLSHorizontalSurfaceList = chain(OLSHorizontalSurfaceList)
    .filter((hslObj) => {
      return selectedOls.includes(
        `${hslObj.sub_category}__${olsColor[hslObj.sub_category]}`
      );
    })
    .value();

  const handlePolygonOver = (e, element) => {
    e.target.bringToFront();
    setSelectedOverE(element);
  };

  const handlePolygonOut = () => {
    setSelectedOverE(null);
  };

  return (
    <Fragment>
      {visibleOLSHorizontalSurfaceList.map((olsHsl, i) => {
        const hslCoordinates = olsHsl.coordinates.coordinates;
        const hslCoordinateMap = flatten(hslCoordinates).map((hslCoords) => {
          return hslCoords.map((hsl) => {
            return [hsl[1], hsl[0]];
          });
        });

        return (
          <Polygon
            key={i}
            pane="tilePane"
            positions={hslCoordinateMap}
            pathOptions={{
              fillColor: olsColor[olsHsl.sub_category],
              color:
                selectedOverE?.sub_category === olsHsl.sub_category
                  ? areaHighLightColor
                  : olsColor[olsHsl.sub_category],
              weight:
                selectedOverE?.sub_category === olsHsl.sub_category ? 4 : 2,
            }}
            eventHandlers={{
              mouseover: (e) => handlePolygonOver(e, olsHsl),
              mouseout: () => handlePolygonOut(),
            }}
          />
        );
      })}
    </Fragment>
  );
}
