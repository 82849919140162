import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { filter, find, isEmpty } from "lodash";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { EditIcon } from "@assets/svg/edit";
import { LightButton, PrimaryButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd218ValidationSchema,
  hoursOfOperation,
} from "@constants/ad2Data";
import { airtrafficserviceDetail } from "@constants/ad2DataForm.constant";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  deleteAD2AAIAirport,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad218Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const {
    isLoadingAD28AAIAirportDetail,
    AAIAirport,
    isLoadingDeleteAD210AAIAirport,
  } = useSelector((state) => state.airport);
  const [editServiceId, setEditServiceId] = useState(null);
  const [editServiceIndex, setEditServiceIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    if (Array.isArray(AAIAirport)) {
      AAIAirport.map((service, index) => {
        const frequency = service.frequency
          ? service.frequency.split(" ")[0]
          : "";
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].id`,
          service.id ? service.id : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].callsign`,
          service.callsign ? service.callsign : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].designation`,
          service.designation ? service.designation : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].frequency`,
          frequency ? Number(frequency) : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].logon`,
          service.logon ? service.logon : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].operationalstatus`,
          service.operationalstatus ? service.operationalstatus : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].satvoiceNumber`,
          service.satvoiceNumber ? service.satvoiceNumber : ""
        );
        createAd218Form.setFieldValue(
          `airtrafficservices[${index}].remarks`,
          service.remarks ? service.remarks : ""
        );

        return true;
      });
    }
  }, [AAIAirport, createAd218Form]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "airtrafficservices",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleSuccessCallback = () => {
    createAd218Form.resetForm();
    createAd218Form.setSubmitting(false);
  };

  const createAd218Form = useFormik({
    enableReinitialize: true,
    initialValues: { airtrafficservices: [airtrafficserviceDetail] },
    validationSchema: createAd218ValidationSchema,
    onSubmit: (values) => {
      const serviceValues = values.airtrafficservices.map((service) => {
        return {
          callsign: service.callsign ? service.callsign : "",
          designation: service.designation ? service.designation : "",
          frequency: service.frequency
            ? `${String(service.frequency)} MHZ`
            : "",
          logon: service.logon ? service.logon : "",
          operationalstatus: service.operationalstatus
            ? service.operationalstatus
            : "",
          satvoiceNumber: service.satvoiceNumber
            ? String(service.satvoiceNumber)
            : "",
          remarks: service.remarks ? service.remarks : "",
        };
      });

      if (editServiceId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "airtrafficservices",
              { ...serviceValues[editServiceIndex], id: editServiceId },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditServiceId(null);
                setEditServiceIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      } else {
        const serviceData = filter(values.airtrafficservices, ["id", ""]).map(
          (service) => {
            return {
              callsign: service.callsign ? service.callsign : "",
              designation: service.designation ? service.designation : "",
              frequency: service.frequency
                ? `${String(service.frequency)} MHZ`
                : "",
              logon: service.logon ? service.logon : "",
              operationalstatus: service.operationalstatus
                ? service.operationalstatus
                : "",
              satvoiceNumber: service.satvoiceNumber
                ? String(service.satvoiceNumber)
                : "",
              remarks: service.remarks ? service.remarks : "",
            };
          }
        );
        if (isEmpty(serviceData)) return true;
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addAD2AAIAirportDetail(
              "airtrafficservices",
              {
                forms: serviceData,
              },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditServiceId(null);
                setEditServiceIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      }
    },
  });

  const handleEditServicedetail = (id) => {
    setEditServiceId(id);
  };

  const handleEditDoneApron = (editId, index) => {
    setEditServiceId(editId);
    setEditServiceIndex(index);
  };

  const handleDeleteApron = (deleteId) => {
    let id;
    if (editServiceId) {
      id = editServiceId;
    } else {
      id = deleteId;
    }
    if (!id) return;
    setDeleteId(id);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleSaveDeleteDialog = () => {
    if (!deleteId) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "airtrafficservices",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            ids: [String(deleteId)],
          },
          () => {
            setEditServiceId(null);
            setEditServiceIndex(null);
            handleSuccessCallback();
            setOpenDeleteConfirmDialog(false);
            handleGetADCallBack();
          },
          token
        )
      );
    });
  };

  return (
    <Paper className="add-airport-data-form">
      <FormikProvider value={createAd218Form}>
        <form
          onSubmit={createAd218Form.handleSubmit}
          style={{ height: "100%" }}
        >
          <Box sx={{ ...styles.adFormField, height: "calc(100% - 54px)" }}>
            <FieldArray
              name="airtrafficservices"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {createAd218Form.values.airtrafficservices.map(
                      (_, index) => {
                        const isTouched = createAd218Form.touched
                          ?.airtrafficservices
                          ? createAd218Form.touched?.airtrafficservices[index]
                          : false;
                        const isErrors = createAd218Form.errors
                          ?.airtrafficservices
                          ? createAd218Form.errors?.airtrafficservices[index]
                          : false;

                        const serviceIds = !isEmpty(AAIAirport)
                          ? Array.isArray(AAIAirport) &&
                            AAIAirport.map((servicedata) => servicedata.id)
                          : [];

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={styles.adVORMain}>
                                <Typography
                                  variant="span"
                                  sx={{ width: "85%", display: "inline-block" }}
                                >
                                  Air traffic service {index + 1}
                                </Typography>
                                <Box sx={styles.adFuelDeleteBtn}>
                                  {!isEmpty(AAIAirport) &&
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].id &&
                                    (editServiceId === serviceIds[index] ? (
                                      <button
                                        type="button"
                                        style={styles.ad28Btn}
                                        onClick={() => {
                                          createAd218Form.handleSubmit();
                                          handleEditDoneApron(
                                            editServiceId,
                                            index
                                          );
                                        }}
                                      >
                                        Done
                                      </button>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0 }}
                                        onClick={() =>
                                          handleEditServicedetail(
                                            serviceIds[index]
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    ))}
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (editServiceId) {
                                        handleDeleteApron();
                                      } else if (!isEmpty(AAIAirport)) {
                                        if (
                                          serviceIds.includes(
                                            createAd218Form.values
                                              .airtrafficservices[index].id
                                          )
                                        ) {
                                          handleDeleteApron(serviceIds[index]);
                                        } else {
                                          arrayHelpers.remove(index);
                                        }
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                            <input
                              hidden
                              name={`airtrafficservices[${index}].id`}
                              onChange={createAd218Form.handleChange}
                              value={
                                createAd218Form.values.airtrafficservices[index]
                                  .id
                              }
                            />
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Service Designation
                              </Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`airtrafficservices[${index}].designation`}
                                  InputProps={{
                                    readOnly: !createAd218Form.values
                                      .airtrafficservices[index].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        ),
                                  }}
                                  value={
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].designation
                                  }
                                  onChange={createAd218Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].designation &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index].designation
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index]
                                          .designation
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Call sign</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`airtrafficservices[${index}].callsign`}
                                  InputProps={{
                                    readOnly: !createAd218Form.values
                                      .airtrafficservices[index].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        ),
                                  }}
                                  value={
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].callsign
                                  }
                                  onChange={createAd218Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].callsign &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index].callsign
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index].callsign
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Channel(s)</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`airtrafficservices[${index}].frequency`}
                                  value={
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].frequency
                                  }
                                  onChange={createAd218Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].frequency &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index].frequency
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index].frequency
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                  InputProps={{
                                    readOnly: !createAd218Form.values
                                      .airtrafficservices[index].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        MHZ
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                SATVOICE Number(s)
                              </Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`airtrafficservices[${index}].satvoiceNumber`}
                                  value={
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].satvoiceNumber
                                  }
                                  onChange={createAd218Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].satvoiceNumber &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index]
                                        .satvoiceNumber
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index]
                                          .satvoiceNumber
                                      : ""
                                  }
                                  sx={{ mt: 2 }}
                                  InputProps={{
                                    readOnly: !createAd218Form.values
                                      .airtrafficservices[index].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Logon address</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`airtrafficservices[${index}].logon`}
                                  InputProps={{
                                    readOnly: !createAd218Form.values
                                      .airtrafficservices[index].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        ),
                                  }}
                                  value={
                                    createAd218Form.values.airtrafficservices[
                                      index
                                    ].logon
                                  }
                                  onChange={createAd218Form.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].logon &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index].logon
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index].logon
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Hours of operation
                              </Box>
                              <Box sx={styles.adInput}>
                                <SelectInput
                                  name={`airtrafficservices[${index}].operationalstatus`}
                                  value={find(hoursOfOperation, {
                                    value:
                                      createAd218Form.values.airtrafficservices[
                                        index
                                      ].operationalstatus,
                                  })}
                                  onChange={(option) => {
                                    createAd218Form.setFieldValue(
                                      `airtrafficservices[${index}].operationalstatus`,
                                      option.value
                                    );
                                  }}
                                  options={hoursOfOperation}
                                  isDisabled={
                                    !createAd218Form.values.airtrafficservices[
                                      index
                                    ].id
                                      ? false
                                      : !Boolean(
                                          serviceIds[index] === editServiceId &&
                                            editServiceId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Remarks</Box>
                              <Box sx={styles.adInput}>
                                <FormControl
                                  variant="outlined"
                                  sx={{ mb: 2, mt: 2, width: "100%" }}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd218Form.touched?.airtrafficservices[
                                      index
                                    ].remarks &&
                                    Boolean(
                                      createAd218Form.errors
                                        ?.airtrafficservices[index].remarks
                                    )
                                  }
                                >
                                  <TextareaAutosize
                                    minRows={2}
                                    id={`airtrafficservices[${index}].remarks`}
                                    name={`airtrafficservices[${index}].remarks`}
                                    value={
                                      createAd218Form.values.airtrafficservices[
                                        index
                                      ].remarks
                                    }
                                    onChange={createAd218Form.handleChange}
                                    aria-describedby="aerodrome-air-traffic-remarks"
                                    readOnly={
                                      !createAd218Form.values
                                        .airtrafficservices[index].id
                                        ? false
                                        : !Boolean(
                                            serviceIds[index] ===
                                              editServiceId && editServiceId
                                          )
                                    }
                                  />
                                  <FormHelperText id="aerodrome-air-traffic-remarks">
                                    {isErrors && isTouched
                                      ? createAd218Form.errors
                                          ?.airtrafficservices[index].remarks
                                      : ""}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                            <Divider sx={{ mb: 1 }} />
                          </Fragment>
                        );
                      }
                    )}
                    <PrimaryButton
                      label="Add More"
                      variant="outlined"
                      sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                      onClick={() => arrayHelpers.push(airtrafficserviceDetail)}
                    />
                  </div>
                );
              }}
            />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={styles.adFormBtn}
          >
            <LightButton
              label="Clear"
              sx={{ mr: 4 }}
              isLoading={false}
              onClick={() => {
                createAd218Form.resetForm();
              }}
            />
            <PrimaryButton
              type="submit"
              label="Submit"
              isLoading={isLoadingAD28AAIAirportDetail}
              disabled={isLoadingAD28AAIAirportDetail || Boolean(editServiceId)}
              sx={{ width: 200 }}
            />
          </Stack>
        </form>
      </FormikProvider>
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Paper>
  );
}
