import React, { Fragment, useState, useEffect } from "react";
import { orderBy, filter } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Box,
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { AES_KEY } from "@constants/constant";
import { PrimaryButton } from "@components/button";
import StyledBoxV2 from "@components/core/StyledBoxV2";
import FullContentLoader from "@components/loader/FullContentLoader";
import DiffTableCell from "@components/tabel-component/DiffTableCell";
import DiffStack from "@components/tabel-component/DiffStack";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TableLoader from "@components/tabel-component/TableLoader";
import ValueItem from "@components/tabel-component/ValueItem";
import { dataChangedRowColorMap } from "@constants/colors";
import { surveyReportStatus } from "@constants/surveyorData";
import { theme } from "@styles/theme";
import { decryptStatic } from "@utils/decodeEncodeData";
import { getStorageValue } from "@utils/localStorage";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import SurveyorTopTableRow from "./SurveyorTopTableRow";
import AirportDetailTableRow from "./AirportDetailTableRow";
import DataTableFooterRow from "./DataTableFooterRow";
import ApproachAcceptDataTable from "./ApproachAcceptDataTable";
import ApproachInReviewDataTable from "./ApproachInReviewDataTable";

export default function ApproachReviewTable({
  isLoadingVerification,
  isLoadingCompareData,
  selected,
  selectedReport,
  surveyReportDetail,
  handleSelectAllClick,
  handleClick,
  handleAccept,
  handleReview,
}) {
  const aesKey = getStorageValue(AES_KEY);
  const [isOpenReviewModal, setOpenReviewModal] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const notVerified = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.notVerified,
  ]);
  const acceptedData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.accepted,
  ]);
  const inReviewData = filter(surveyReportDetail, [
    "verification1",
    surveyReportStatus.inReview,
  ]);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (selected.length === 0) {
      setOpenReviewModal(false);
    }

    const initReviewData = filter(notVerified, (d) =>
      selected.includes(d.id1)
    ).map((d) => ({
      ...d,
      id: d.id1,
      latitude: false,
      longitude: false,
      top_elve: false,
    }));

    setReviewData(initReviewData);
  }, [selected]); // eslint-disable-line

  const handleCellClick = (key, id) => {
    const updateReviewData = reviewData.map((review) => {
      if (id === review.id1) {
        return {
          ...review,
          [key]: !review[key],
        };
      }

      return review;
    });

    setReviewData(updateReviewData);
  };

  const handleSubmitReviewData = () => {
    const rData = reviewData.map((r) => ({
      id: r.id,
      latitude: r.latitude,
      longitude: r.longitude,
      top_elve: r.top_elve,
    }));

    handleReview(rData);
  };

  const handleCloseInReviewModal = () => {
    setOpenReviewModal(false);
  };

  return (
    <Fragment>
      <SurveyorTopTableRow
        serveyorName={
          selectedReport
            ? decryptStatic(selectedReport?.user_name, aesKey)
            : "-"
        }
        userId={selectedReport?.user_empid}
      />
      <AirportDetailTableRow selectedReport={selectedReport} />
      <div style={{ marginTop: "78px" }} />
      {(isLoadingCompareData || surveyReportDetail.length === 0) && (
        <TableLoader
          isLoading={isLoadingCompareData}
          data={surveyReportDetail}
        />
      )}
      {isLoadingCompareData && surveyReportDetail.length > 0 && (
        <FullContentLoader />
      )}
      {selected.length > 0 && (
        <EnhancedTableToolbar numSelected={selected.length} />
      )}
      <TableContainer>
        {notVerified.length > 0 && (
          <Fragment>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={7}></StyledTableCell>
                  <StyledTableCell colSpan={4} align="center">
                    280M RUNWAY STRIP
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell padding="checkbox" rowSpan={2}>
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < notVerified.length
                      }
                      checked={
                        notVerified.length > 0 &&
                        selected.length === notVerified.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all rows",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    UID
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    OBJECT
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    <Box>LATITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    <Box>LONGITUDE</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    <Stack>
                      <Box>TOP ELEV. IN m. (EGM 08)</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                        <StyledBoxV2>DIFF</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    <Stack>
                      <Box>BASE ELEV. IN m. (EGM 08)</Box>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <StyledBoxV2>NEW</StyledBoxV2>
                        <StyledBoxV2>OLD</StyledBoxV2>
                        <StyledBoxV2>DIFF</StyledBoxV2>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>
                    <Stack alignItems="center">
                      <StyledBoxV2>DISTANCE (IN M)</StyledBoxV2>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Stack alignItems="center">
                          <StyledBoxV2>X</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                        <Stack alignItems="center">
                          <StyledBoxV2>Y</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                        <Stack alignItems="center">
                          <StyledBoxV2>YY</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack alignItems="center">
                      <StyledBoxV2>
                        W.R.T 1:50 IN APP FUNNEL {"&"} 1:7 IN T.S. (IN M.)
                      </StyledBoxV2>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Stack alignItems="center">
                          <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                        <Stack alignItems="center">
                          <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack alignItems="center">
                      <StyledBoxV2>
                        W.R.T. I.H.S./C.S./O.H.S. (IN M.)
                      </StyledBoxV2>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Stack alignItems="center">
                          <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                        <Stack alignItems="center">
                          <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <StyledBoxV2>NEW</StyledBoxV2>
                            <StyledBoxV2>OLD</StyledBoxV2>
                            <StyledBoxV2>DIFF</StyledBoxV2>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center" rowSpan={2}>
                    <StyledBoxV2>REMARKS</StyledBoxV2>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                    </Stack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(notVerified, ["uid1"], ["asc"]).map((row, i) => {
                  const isItemSelected = isSelected(row.id1);
                  const labelId = `enhanced-table-checkbox-${i}`;

                  return (
                    <TableRow
                      key={i}
                      hover
                      onClick={() => handleClick(row.id1)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        background:
                          dataChangedRowColorMap[row.result] ??
                          theme.palette.white.main,
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledTableCell>
                      <DiffTableCell>{row["uid1"]}</DiffTableCell>
                      <DiffTableCell>{row["object1"] || "-"}</DiffTableCell>
                      <DiffTableCell isdiff={row["latdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["latitude1"]} />
                          <ValueItem value={row["latitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell isdiff={row["longdev"]}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["longitude1"]} />
                          <ValueItem value={row["longitude2"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell
                        align="center"
                        isdiff={Boolean(row["top_elevdiff"])}
                      >
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["top_elev1"]} />
                          <ValueItem value={row["top_elev2"]} />
                          <ValueItem value={row["top_elevdiff"]} />
                        </Stack>
                      </DiffTableCell>
                      <DiffTableCell isdiff={Boolean(row["base_elevdiff"])}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["base_elev1"]} />
                          <ValueItem value={row["base_elev2"]} />
                          <ValueItem value={row["base_elevdiff"]} />
                        </Stack>
                      </DiffTableCell>
                      <StyledTableCell>
                        <Stack>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <DiffStack isdiff={Boolean(row["xdiff"])}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem value={row["x1"]} />
                                <ValueItem value={row["x2"]} />
                                <ValueItem value={row["xdiff"]} />
                              </Stack>
                            </DiffStack>
                            <DiffStack isdiff={Boolean(row["ydiff"])}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem value={row["y1"]} />
                                <ValueItem value={row["y2"]} />
                                <ValueItem value={row["ydiff"]} />
                              </Stack>
                            </DiffStack>
                            <DiffStack isdiff={Boolean(row["yydiff"])}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem value={row["yy1"]} />
                                <ValueItem value={row["yy2"]} />
                                <ValueItem value={row["yydiff"]} />
                              </Stack>
                            </DiffStack>
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <DiffStack
                              isdiff={Boolean(row["APPpermissible_elevdiff"])}
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem
                                  value={row["APPpermissible_elev1"]}
                                />
                                <ValueItem
                                  value={row["APPpermissible_elev2"]}
                                />
                                <ValueItem
                                  value={row["APPpermissible_elevdiff"]}
                                />
                              </Stack>
                            </DiffStack>
                            <DiffStack isdiff={Boolean(row["APPobstaclediff"])}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem value={row["APPobstacle1"]} />
                                <ValueItem value={row["APPobstacle2"]} />
                                <ValueItem value={row["APPobstaclediff"]} />
                              </Stack>
                            </DiffStack>
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <DiffStack
                              isdiff={Boolean(row["IHSpermissible_elevdiff"])}
                            >
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem
                                  value={row["IHSpermissible_elev1"]}
                                />
                                <ValueItem
                                  value={row["IHSpermissible_elev2"]}
                                />
                                <ValueItem
                                  value={row["IHSpermissible_elevdiff"]}
                                />
                              </Stack>
                            </DiffStack>
                            <DiffStack isdiff={Boolean(row["IHSobstaclediff"])}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                divider={
                                  <Divider orientation="vertical" flexItem />
                                }
                                spacing={1}
                              >
                                <ValueItem value={row["IHSobstacle1"]} />
                                <ValueItem value={row["IHSobstacle2"]} />
                                <ValueItem value={row["IHSobstaclediff"]} />
                              </Stack>
                            </DiffStack>
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                      <DiffTableCell>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <ValueItem value={row["remarks1"]} />
                          <ValueItem value={row["remarks2"]} />
                        </Stack>
                      </DiffTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Fragment>
        )}
        {acceptedData.length > 0 && (
          <ApproachAcceptDataTable data={acceptedData} />
        )}
        {inReviewData.length > 0 && (
          <ApproachInReviewDataTable data={inReviewData} />
        )}
      </TableContainer>
     
      {notVerified.length > 0 && <DataTableFooterRow
        isLoadingVerification={isLoadingVerification}
        selectedReport={selectedReport}
        selected={selected}
        handleAccept={handleAccept}
        handleReview={() => setOpenReviewModal(true)}
      />}
      <Dialog fullWidth open={isOpenReviewModal} maxWidth="xl">
        <DialogTitle>
          <Box sx={{ mb: -2 }}>In Review Data</Box>
          <Typography variant="caption">
            Select the data set where deviation is found before submit.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={7}></StyledTableCell>
                <StyledTableCell colSpan={4} align="center">
                  280M RUNWAY STRIP
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align="center" rowSpan={2}>
                  UID
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  OBJECT
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  <Box>LATITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  <Box>LONGITUDE</Box>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  <Stack>
                    <Box>TOP ELEV. IN m. (EGM 08)</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                      <StyledBoxV2>DIFF</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  <Stack>
                    <Box>BASE ELEV. IN m. (EGM 08)</Box>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <StyledBoxV2>NEW</StyledBoxV2>
                      <StyledBoxV2>OLD</StyledBoxV2>
                      <StyledBoxV2>DIFF</StyledBoxV2>
                    </Stack>
                  </Stack>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Stack alignItems="center">
                    <StyledBoxV2>DISTANCE (IN M)</StyledBoxV2>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Stack alignItems="center">
                        <StyledBoxV2>X</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                      <Stack alignItems="center">
                        <StyledBoxV2>Y</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                      <Stack alignItems="center">
                        <StyledBoxV2>YY</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack alignItems="center">
                    <StyledBoxV2>
                      W.R.T 1:50 IN APP FUNNEL {"&"} 1:7 IN T.S. (IN M.)
                    </StyledBoxV2>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Stack alignItems="center">
                        <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                      <Stack alignItems="center">
                        <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Stack alignItems="center">
                    <StyledBoxV2>W.R.T. I.H.S./C.S./O.H.S. (IN M.)</StyledBoxV2>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Stack alignItems="center">
                        <StyledBoxV2>PERMISSIBLE TOP ELEV. IN M.</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                      <Stack alignItems="center">
                        <StyledBoxV2>OBSTACLE IN M.</StyledBoxV2>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <StyledBoxV2>NEW</StyledBoxV2>
                          <StyledBoxV2>OLD</StyledBoxV2>
                          <StyledBoxV2>DIFF</StyledBoxV2>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  <StyledBoxV2>REMARKS</StyledBoxV2>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                  >
                    <StyledBoxV2>NEW</StyledBoxV2>
                    <StyledBoxV2>OLD</StyledBoxV2>
                  </Stack>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(reviewData, ["uid1"], ["asc"]).map((row, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      background:
                        dataChangedRowColorMap[row.result] ??
                        theme.palette.white.main,
                    }}
                  >
                    <DiffTableCell>{row["uid1"]}</DiffTableCell>
                    <DiffTableCell>{row["object1"] || "-"}</DiffTableCell>
                    <DiffTableCell
                      isdiff={row["latdev"]}
                      isselected={row.latitude.toString()}
                      onClick={() => handleCellClick("latitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["latitude1"]} />
                        <ValueItem value={row["latitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      isdiff={row["longdev"]}
                      isselected={row.longitude.toString()}
                      onClick={() => handleCellClick("longitude", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["longitude1"]} />
                        <ValueItem value={row["longitude2"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell
                      align="center"
                      isdiff={Boolean(row["top_elevdiff"])}
                      isselected={row.top_elve.toString()}
                      onClick={() => handleCellClick("top_elve", row.id1)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["top_elev1"]} />
                        <ValueItem value={row["top_elev2"]} />
                        <ValueItem value={row["top_elevdiff"]} />
                      </Stack>
                    </DiffTableCell>
                    <DiffTableCell isdiff={Boolean(row["base_elevdiff"])}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["base_elev1"]} />
                        <ValueItem value={row["base_elev2"]} />
                        <ValueItem value={row["base_elevdiff"]} />
                      </Stack>
                    </DiffTableCell>
                    <StyledTableCell>
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <DiffStack isdiff={Boolean(row["xdiff"])}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["x1"]} />
                              <ValueItem value={row["x2"]} />
                              <ValueItem value={row["xdiff"]} />
                            </Stack>
                          </DiffStack>
                          <DiffStack isdiff={Boolean(row["ydiff"])}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["y1"]} />
                              <ValueItem value={row["y2"]} />
                              <ValueItem value={row["ydiff"]} />
                            </Stack>
                          </DiffStack>
                          <DiffStack isdiff={Boolean(row["yydiff"])}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["yy1"]} />
                              <ValueItem value={row["yy2"]} />
                              <ValueItem value={row["yydiff"]} />
                            </Stack>
                          </DiffStack>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <DiffStack
                            isdiff={Boolean(row["APPpermissible_elevdiff"])}
                          >
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["APPpermissible_elev1"]} />
                              <ValueItem value={row["APPpermissible_elev2"]} />
                              <ValueItem
                                value={row["APPpermissible_elevdiff"]}
                              />
                            </Stack>
                          </DiffStack>
                          <DiffStack isdiff={Boolean(row["APPobstaclediff"])}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["APPobstacle1"]} />
                              <ValueItem value={row["APPobstacle2"]} />
                              <ValueItem value={row["APPobstaclediff"]} />
                            </Stack>
                          </DiffStack>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={1}
                        >
                          <DiffStack
                            isdiff={Boolean(row["IHSpermissible_elevdiff"])}
                          >
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["IHSpermissible_elev1"]} />
                              <ValueItem value={row["IHSpermissible_elev2"]} />
                              <ValueItem
                                value={row["IHSpermissible_elevdiff"]}
                              />
                            </Stack>
                          </DiffStack>
                          <DiffStack isdiff={Boolean(row["IHSobstaclediff"])}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              divider={
                                <Divider orientation="vertical" flexItem />
                              }
                              spacing={1}
                            >
                              <ValueItem value={row["IHSobstacle1"]} />
                              <ValueItem value={row["IHSobstacle2"]} />
                              <ValueItem value={row["IHSobstaclediff"]} />
                            </Stack>
                          </DiffStack>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <DiffTableCell>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <ValueItem value={row["remarks1"]} />
                        <ValueItem value={row["remarks2"]} />
                      </Stack>
                    </DiffTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            size="small"
            label="Cancel"
            onClick={handleCloseInReviewModal}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
          <PrimaryButton
            label="Submit"
            size="small"
            onClick={handleSubmitReviewData}
            isLoading={isLoadingVerification}
            disabled={isLoadingVerification}
          />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
