import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTypography from "@components/core/StyledTypography";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/dataManagementStyles";
import { theme } from "@styles/theme";

function Approachrunwaylighting({ selctedAirport, activeCategory, rwylights }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          ...styles.addAirportName,
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.black.main,
        }}
      >
        {selctedAirport.name} {">"} {activeCategory.label}
      </Stack>
      <Paper sx={{ mb: 5 }}>
        {!isEmpty(rwylights) ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Runway Designator</StyledTableCell>
                  <StyledTableCell>
                    Type, length and intensity of approach lighting system
                  </StyledTableCell>
                  <StyledTableCell>
                    Runway threshold lights, colour and wing bars
                  </StyledTableCell>
                  <StyledTableCell>
                    Type of visual slope indicator system
                  </StyledTableCell>
                  <StyledTableCell>
                    Length of runway touchdown zone lights
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rwylights && rwylights.length > 0 ? (
                  rwylights.map((rwy, j) => {
                    return (
                      <TableRow key={`${j}_vor`}>
                        <StyledTableCell>{rwy.rd || "-"}</StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.approachlightingsystems?.classICAO || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.approachlightingsystems?.length || "-"}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.approachlightingsystems?.intensityLevel ||
                                ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.thrrunwaylights?.colour || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.thrrunwaylights?.length || ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.visualglideslopeindicators?.type || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.visualglideslopeindicators?.position || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.visualglideslopeindicators?.slopeAngle || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.minimumEyeHeightOverThreshold?.slopeAngle ||
                                ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>{"-"}</StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      No data found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    Length, spacing, colour and intensity of runway centre line
                    lights
                  </StyledTableCell>
                  <StyledTableCell>
                    Length, spacing, colour and intensity of runway edge lights
                  </StyledTableCell>
                  <StyledTableCell>
                    Colour of runway end lights and wing bars
                  </StyledTableCell>
                  <StyledTableCell>
                    Length and colour of stopway lights
                  </StyledTableCell>
                  <StyledTableCell>Remarks</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rwylights && rwylights.length > 0 ? (
                  rwylights.map((rwy, j) => {
                    return (
                      <TableRow key={`${j}_ins`}>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.clrunwaylights.colour || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.clrunwaylights.length || "-"}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.clrunwaylights.spacing || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.clrunwaylights.intensityLevel || ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.edgerunwaylights.length || "-"}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.edgerunwaylights.spacing || ""}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.edgerunwaylights.colour || ""}
                            </StyledTypography>

                            <StyledTypography>
                              {rwy.edgerunwaylights.intensityLevel || ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {rwy.endrunwaylights.colour || "NIL"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box>
                            <StyledTypography>
                              {rwy.stopwaylights.length || "-"}
                            </StyledTypography>
                            <StyledTypography>
                              {rwy.stopwaylights.colour || ""}
                            </StyledTypography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {rwy.remarks || "NIL"}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      No data found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Fragment>
        ) : (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
    </Fragment>
  );
}

export default Approachrunwaylighting;
