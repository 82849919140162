import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  GOOGLE_MAP_API_KEY,
  mapStyleWithoutLabel,
  mapStyles,
} from "@constants/constant";

export default function GoogleMapLayer({ maptype = mapStyles.roadmap }) {
  return (
    <ReactLeafletGoogleLayer
      apiKey={GOOGLE_MAP_API_KEY}
      styles={mapStyleWithoutLabel}
      type={maptype}
      googleMapsLoaderConf={{
        region: "IN",
      }}
    />
  );
}
