import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, isEmpty } from "lodash";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import QuestionIcon from "@assets/svg/QuestionIcon";
import SatsureiNetraLogo from "@assets/images/SatsureInetraLogo.png";
import LogoutIcon from "@assets/svg/LogoutIcon";
import UserIcon from "@assets/svg/UserIcon";
import { PrimaryButton } from "@components/button";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import { routeNames } from "@constants/pageRoutes.constants";
import { userLogout } from "@store/userSession/actions";
import { getUserRegion } from "@store/userSession";
import { getSurveyorAirport } from "@store/survey/actions";
import { styles } from "@styles/headerStyles";
import { decryptValue } from "@utils/decodeEncodeData";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";
import { shortLabel } from "@utils/util";

export default function MainHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = useSelector((state) => state.userSession.user);
  const userRegion = useSelector((state) => getUserRegion(state));
  const accesstoken = getStorageValue(ACCESS_TOKEN);

  useEffect(() => {
    if (!user) return false;
    if (user?.role === roles.surveyor) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(getSurveyorAirport(token));
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogin = () => {
    navigate(routeNames.Authentication);
  };

  const handleLogOut = () => {
    dispatch(userLogout(handleLogoutCallback));
  };

  const handleLogoutCallback = () => {
    navigate(routeNames.Authentication);
    window.location.reload();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box className="main-header">
      <Box className="satsture-inetra-logo">
        <img src={SatsureiNetraLogo} alt="satsure-iNetra" height={40} />
      </Box>
      <Box className="user-logo" justifyContent="flex-end">
        {!isEmpty(user) && accesstoken ? (
          <>
            <QuestionIcon />
            <UserIcon onClick={handleOpenUserMenu} />

            <Menu
              sx={{ top: "42px", left: "5px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ minWidth: "200px" }}>
                <Box sx={styles.userName}>
                  <Avatar sx={{ bgcolor: "#59178A", mr: 1 }}>
                    {shortLabel(decryptValue(user.userName).replace(/_/g, " "))}
                  </Avatar>
                  <Box>
                    <Typography sx={styles.userAvatar}>
                      {capitalize(
                        decryptValue(user.userName).replace(/_/g, " ")
                      )}
                    </Typography>
                    <Typography sx={styles.userRegion}>
                      {userRegion || ""}
                    </Typography>
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem onClick={() => handleLogOut()}>
                <Box sx={styles.logoutIcon}>
                  <LogoutIcon className="logout-icon" />
                  <Typography className="icon-text">Log out</Typography>
                </Box>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <PrimaryButton
            label="Log In"
            type="submit"
            onClick={handleLogin}
            className="header-login-btn"
          />
        )}
      </Box>
    </Box>
  );
}
