import React, { Fragment, useState, useMemo, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapContainer, ScaleControl, ZoomControl } from "react-leaflet";
import { isEmpty } from "lodash";
import { ACCESS_TOKEN,roles } from "@constants/constant";
import ConfirmationDialog2 from "@components/core/ConfirmationDialog2";
import ControlMapCenter from "@components/map/ControlMapCenter";
import FullScreenControl from "@components/map/FullScreenControl";
import MapLayers from "@components/map/MapLayers";
import MeasurementControl from "@components/map/MeasurementControl";
import { editDeleteEchartPolyline } from "@store/electronicChart/actions";
import { getAsyncStorageValue } from "@utils/localStorage";
import RenderGeometryLine from "./RenderGeometryLine";
import RenderGeometryLabelLine from "./RenderGeometryLabelLine";
import RenderGeometryGridLine from "./RenderGeometryGridLine";
import RenderGeometryLabel from "./RenderGeometryLabel";
import EchartDrawControl from "./EchartDrawControl";

const ElecronicChartMap = ({
  selctedAirport,
  selectedEchart,
  airportLocation,
  zoomLevel,
  eChartData,
  isShowLabel,
  isShowTable,
  selectedExportType,
  handleLabelClickOnMap,
  handleChartGridExport,
}) => {
  const drawRef = useRef(null);
   const dispatch = useDispatch();
  const { isLoadingEditDeletePolyline } = useSelector((state) => state.eChart);
  const user = useSelector((state) => state.userSession.user);
  const [editableLayer, setEditableLayer] = useState(null);
  const [deleteLayer, setDeleteLayer] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);
  const isAdmin = user?.role === roles.admin;

  const handleEditShap = (data) => {
    setEditableLayer(data);
    setTimeout(() => {
      drawRef?.current?._toolbars?.edit._modes.edit.handler.enable();
    });
  };

  const handleonMountedControl = (ctl) => {
    drawRef.current = ctl;
  };

  const handleDrawControlCallback = () => {
    setDeleteLayer(null);
    setEditableLayer(null);
    setOpenDeleteConfirmDialog(false);
  };

  const handleLabelClick = (label) => {
    handleLabelClickOnMap(label);
  };

  const handleDeleteShap = (data) => {
    setDeleteLayer(data);
    setOpenDeleteConfirmDialog(true);
  };

  const handleSaveDeleteDialog = () => {
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        editDeleteEchartPolyline(
          "DELETE",
          "",
          deleteLayer?.id,
          selctedAirport?.id,
          token,
          handleDrawControlCallback
        )
      );
    });
  }

  const eChartMapE = useMemo(() => {
    return (
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={airportLocation}
        minZoom={zoomLevel - 1}
        zoom={zoomLevel}
        zoomControl={false}
        className="amdb-map"
      >
        <RenderGeometryLine
          data={eChartData}
          selectedEchart={selectedEchart}
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
          isShowTable={isShowTable}
          handleEditShap={handleEditShap}
          handleDeleteShap={handleDeleteShap}
        />
        {isShowLabel && selectedEchart && (
          <RenderGeometryLabelLine data={eChartData[selectedEchart]} />
        )}
        <RenderGeometryLabel
          data={!isEmpty(selectedEchart) ? eChartData[selectedEchart] : null}
          isShowLabel={isShowLabel}
          handleLabelClick={handleLabelClick}
        />
        {selectedExportType === "grids" && (
          <RenderGeometryGridLine
            data={eChartData.grids}
            handleChartGridExport={handleChartGridExport}
            zoomLevel={zoomLevel}
          />
        )}
        <ControlMapCenter
          airportLocation={airportLocation}
          zoomLevel={zoomLevel}
        />
        {/* <PrintMap
          pageTitle={selctedAirport ? `${selctedAirport.name} (${selctedAirport.location_indicator})` : undefined}
        /> */}
        <ZoomControl position="bottomright" />
        <FullScreenControl />
        <MeasurementControl />
        {isAdmin && (
          <EchartDrawControl
            selctedAirport={selctedAirport}
            editableLayer={editableLayer}
            handleCallback={handleDrawControlCallback}
            handleonMountedControl={handleonMountedControl}
            handleEditShap={handleEditShap}
          />
        )}
        <MapLayers />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    airportLocation,
    zoomLevel,
    selctedAirport,
    eChartData,
    isShowLabel,
    selectedEchart,
    isShowTable,
    editableLayer,
    selectedExportType,
    drawRef,
    deleteLayer,
  ]);

  return (
    <Fragment>
      {eChartMapE}
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog2
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this polyline?"
          saveButtonLabel="Yes"
          cancelText="No"
          saveButtonColor="error"
          isLoading={isLoadingEditDeletePolyline}
          isDisabled={isLoadingEditDeletePolyline}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleDrawControlCallback}
        />
      )}
    </Fragment>
  );
};

export default memo(ElecronicChartMap);
