export const styles = {
  menucontainer: {
    pl: 2.5,
    pr: 2.5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2,
  },
};
