import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { filter, find, isEmpty } from "lodash";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@assets/svg/DeleteIcon";
import { EditIcon } from "@assets/svg/edit";
import { PrimaryButton, LightButton } from "@components/button";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd28ApronValidationSchema,
  Ad212SurfaceTypes,
  Ad212PavementTypes,
  Ad212EveluationTypes,
  Ad212MaxTyrePressureTypes,
  Ad212PavementSubgradeTypes,
} from "@constants/ad2Data";
import { apronDetail } from "@constants/ad2DataForm.constant";
import {
  addAD2AAIAirportDetail,
  addEditAAIAirportDetail,
  deleteAD2AAIAirport,
  getAAIAirport,
} from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

export default function Ad28ApronForm({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const {
    isLoadingAD28AAIAirportDetail,
    AAIAirport,
    isLoadingDeleteAD210AAIAirport,
  } = useSelector((state) => state.airport);
  const [editApronId, setEditApronId] = useState(null);
  const [editApronIndex, setEditApronIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isOpenDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    useState(false);

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    if (Array.isArray(AAIAirport)) {
      AAIAirport.map((apron, index) => {
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].id`,
          apron.id ? apron.id : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].appronName`,
          apron.name ? apron.name : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].composition`,
          apron.composition ? apron.composition : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].classPCN`,
          apron.classPCN ? Number(apron.classPCN) : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].pavementTypePCN`,
          apron.pavementTypePCN ? apron.pavementTypePCN : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].pavementSubgradePCN`,
          apron.pavementSubgradePCN ? apron.pavementSubgradePCN : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].maxTyrePressurePCN`,
          apron.maxTyrePressurePCN ? apron.maxTyrePressurePCN : ""
        );
        createAd28ApronForm.setFieldValue(
          `aprons[${index}].evaluationMethodPCN`,
          apron.evaluationMethodPCN ? apron.evaluationMethodPCN : ""
        );

        return true;
      });
    }
  }, [AAIAirport, createAd28ApronForm]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "apron",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleSuccessCallback = () => {
    createAd28ApronForm.resetForm();
    createAd28ApronForm.setSubmitting(false);
  };

  const createAd28ApronForm = useFormik({
    enableReinitialize: true,
    initialValues: { aprons: [apronDetail] },
    validationSchema: createAd28ApronValidationSchema,
    onSubmit: (values) => {
      const apronValues = values.aprons.map((apron) => {
        return {
          name: apron.appronName ? apron.appronName : "",
          composition: apron.composition,
          classPCN: String(apron.classPCN),
          pavementTypePCN: apron.pavementTypePCN,
          pavementSubgradePCN: apron.pavementSubgradePCN,
          maxTyrePressurePCN: apron.maxTyrePressurePCN,
          evaluationMethodPCN: apron.evaluationMethodPCN,
        };
      });
      if (editApronId) {
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "apron",
              { ...apronValues[editApronIndex], id: editApronId },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditApronId(null);
                setEditApronIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      } else {
        const apronData = filter(values.aprons, ["id", ""]).map((apron) => {
          return {
            name: apron.appronName ? apron.appronName : "",
            composition: apron.composition,
            classPCN: String(apron.classPCN),
            pavementTypePCN: apron.pavementTypePCN,
            pavementSubgradePCN: apron.pavementSubgradePCN,
            maxTyrePressurePCN: apron.maxTyrePressurePCN,
            evaluationMethodPCN: apron.evaluationMethodPCN,
          };
        });
        if (isEmpty(apronData)) return true;
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addAD2AAIAirportDetail(
              "apron",
              {
                form: apronData,
              },
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditApronId(null);
                setEditApronIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      }
    },
  });

  const handleEditAprondetail = (id) => {
    setEditApronId(id);
  };

  const handleEditDoneApron = (editId, index) => {
    setEditApronId(editId);
    setEditApronIndex(index);
  };

  const handleDeleteApron = (deleteId) => {
    let id;
    if (editApronId) {
      id = editApronId;
    } else {
      id = deleteId;
    }
    if (!id) return;
    setDeleteId(id);
    setOpenDeleteConfirmDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleSaveDeleteDialog = () => {
    if (!deleteId) return;
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        deleteAD2AAIAirport(
          "apron",
          selectedAirport.id,
          selectedAirport.version_id,
          {
            apronId: String(deleteId),
          },
          () => {
            setEditApronId(null);
            setEditApronIndex(null);
            handleSuccessCallback();
            setOpenDeleteConfirmDialog(false);
            handleGetADCallBack();
          },
          token
        )
      );
    });
  };

  return (
    <Paper className="add-airport-data-form">
      {/* <Box sx={styles.adForm}> */}
        <FormikProvider value={createAd28ApronForm}>
          <form
            onSubmit={createAd28ApronForm.handleSubmit}
            style={{ height: "100%" }}
          >
            <Box sx={{ ...styles.adFormField, height: "calc(100% - 54px)" }}>
              <FieldArray
                name="aprons"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {createAd28ApronForm.values.aprons.map((_, index) => {
                        const isTouched = createAd28ApronForm.touched?.aprons
                          ? createAd28ApronForm.touched?.aprons[index]
                          : false;
                        const isErrors = createAd28ApronForm.errors?.aprons
                          ? createAd28ApronForm.errors?.aprons[index]
                          : false;

                        const apronIds = !isEmpty(AAIAirport)
                          ? Array.isArray(AAIAirport) &&
                            AAIAirport.map((aprondata) => aprondata.id)
                          : [];

                        return (
                          <Fragment key={index}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={styles.adFuelMain}>
                                <Typography
                                  variant="span"
                                  sx={{ width: "85%", display: "inline-block" }}
                                >
                                  Apron {index + 1}
                                </Typography>
                                <Box sx={styles.adFuelDeleteBtn}>
                                  {!isEmpty(AAIAirport) &&
                                    createAd28ApronForm.values.aprons[index]
                                      .id &&
                                    (editApronId === apronIds[index] ? (
                                      <button
                                        type="button"
                                        style={styles.ad28Btn}
                                        onClick={() => {
                                          createAd28ApronForm.handleSubmit();
                                          handleEditDoneApron(
                                            editApronId,
                                            index
                                          );
                                        }}
                                      >
                                        Done
                                      </button>
                                    ) : (
                                      <IconButton
                                        color="primary"
                                        sx={{ p: 0 }}
                                        onClick={() =>
                                          handleEditAprondetail(apronIds[index])
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    ))}
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (editApronId) {
                                        handleDeleteApron();
                                      } else if (!isEmpty(AAIAirport)) {
                                        if (
                                          apronIds.includes(
                                            createAd28ApronForm.values.aprons[
                                              index
                                            ].id
                                          )
                                        ) {
                                          handleDeleteApron(apronIds[index]);
                                        } else {
                                          arrayHelpers.remove(index);
                                        }
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Stack>
                            <input
                              hidden
                              name={`aprons[${index}].id`}
                              onChange={createAd28ApronForm.handleChange}
                              value={
                                createAd28ApronForm.values.aprons[index].id
                              }
                            />
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Name</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  name={`aprons[${index}].appronName`}
                                  InputProps={{
                                    readOnly: !createAd28ApronForm.values
                                      .aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        ),
                                  }}
                                  value={
                                    createAd28ApronForm.values.aprons[index]
                                      .appronName
                                  }
                                  onChange={createAd28ApronForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd28ApronForm.touched?.aprons[index]
                                      .appronName &&
                                    Boolean(
                                      createAd28ApronForm.errors?.aprons[index]
                                        .appronName
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd28ApronForm.errors?.aprons[
                                          index
                                        ].appronName
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Composition</Box>
                              <Box sx={styles.adInput}>
                                <SelectInput
                                  name={`aprons[${index}].composition`}
                                  value={find(Ad212SurfaceTypes, {
                                    value:
                                      createAd28ApronForm.values.aprons[index]
                                        .composition,
                                  })}
                                  onChange={(option) => {
                                    createAd28ApronForm.setFieldValue(
                                      `aprons[${index}].composition`,
                                      option.value
                                    );
                                  }}
                                  options={Ad212SurfaceTypes}
                                  isDisabled={
                                    !createAd28ApronForm.values.aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Class PCN</Box>
                              <Box sx={styles.adInput}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  name={`aprons[${index}].classPCN`}
                                  value={
                                    createAd28ApronForm.values.aprons[index]
                                      .classPCN
                                  }
                                  onChange={createAd28ApronForm.handleChange}
                                  error={
                                    isTouched &&
                                    isErrors &&
                                    createAd28ApronForm.touched?.aprons[index]
                                      .classPCN &&
                                    Boolean(
                                      createAd28ApronForm.errors?.aprons[index]
                                        .classPCN
                                    )
                                  }
                                  helperText={
                                    isErrors && isTouched
                                      ? createAd28ApronForm.errors?.aprons[
                                          index
                                        ].classPCN
                                      : ""
                                  }
                                  sx={{ mb: 2, mt: 2 }}
                                  InputProps={{
                                    readOnly: !createAd28ApronForm.values
                                      .aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        NUM
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Pavement Type PCN</Box>
                              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                                <SelectInput
                                  name={`aprons[${index}].pavementTypePCN`}
                                  value={find(Ad212PavementTypes, {
                                    value:
                                      createAd28ApronForm.values.aprons[index]
                                        .pavementTypePCN,
                                  })}
                                  onChange={(option) => {
                                    createAd28ApronForm.setFieldValue(
                                      `aprons[${index}].pavementTypePCN`,
                                      option.value
                                    );
                                  }}
                                  options={Ad212PavementTypes}
                                  isDisabled={
                                    !createAd28ApronForm.values.aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Pavement Subgrade PCN
                              </Box>
                              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                                <SelectInput
                                  name={`aprons[${index}].pavementSubgradePCN`}
                                  value={find(Ad212PavementSubgradeTypes, {
                                    value:
                                      createAd28ApronForm.values.aprons[index]
                                        .pavementSubgradePCN,
                                  })}
                                  onChange={(option) => {
                                    createAd28ApronForm.setFieldValue(
                                      `aprons[${index}].pavementSubgradePCN`,
                                      option.value
                                    );
                                  }}
                                  options={Ad212PavementSubgradeTypes}
                                  isDisabled={
                                    !createAd28ApronForm.values.aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>Max Tyre Pressure</Box>
                              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                                <SelectInput
                                  name={`aprons[${index}].maxTyrePressurePCN`}
                                  value={find(Ad212MaxTyrePressureTypes, {
                                    value:
                                      createAd28ApronForm.values.aprons[index]
                                        .maxTyrePressurePCN,
                                  })}
                                  onChange={(option) => {
                                    createAd28ApronForm.setFieldValue(
                                      `aprons[${index}].maxTyrePressurePCN`,
                                      option.value
                                    );
                                  }}
                                  options={Ad212MaxTyrePressureTypes}
                                  isDisabled={
                                    !createAd28ApronForm.values.aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ pl: 2, pr: 2 }}
                            >
                              <Box sx={{ width: "30%" }}>
                                Evaluation Method PCN
                              </Box>
                              <Box sx={{ pl: 2, minWidth: "260px", mb: 2 }}>
                                <SelectInput
                                  name={`aprons[${index}].evaluationMethodPCN`}
                                  value={find(Ad212EveluationTypes, {
                                    value:
                                      createAd28ApronForm.values.aprons[index]
                                        .evaluationMethodPCN,
                                  })}
                                  onChange={(option) => {
                                    createAd28ApronForm.setFieldValue(
                                      `aprons[${index}].evaluationMethodPCN`,
                                      option.value
                                    );
                                  }}
                                  options={Ad212EveluationTypes}
                                  isDisabled={
                                    !createAd28ApronForm.values.aprons[index].id
                                      ? false
                                      : !Boolean(
                                          apronIds[index] === editApronId &&
                                            editApronId
                                        )
                                  }
                                />
                              </Box>
                            </Stack>
                            <Divider
                              sx={{
                                mb: 1,
                              }}
                            />
                          </Fragment>
                        );
                      })}
                      <PrimaryButton
                        label="Add More"
                        variant="outlined"
                        sx={{ mt: 2, mb: 4, pl: 2, pr: 2 }}
                        onClick={() => arrayHelpers.push(apronDetail)}
                      />
                    </div>
                  );
                }}
              />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={styles.adFormBtn}
            >
              <LightButton
                label="Clear"
                sx={{ mr: 4 }}
                isLoading={false}
                onClick={() => {
                  createAd28ApronForm.resetForm();
                }}
              />
              <PrimaryButton
                type="submit"
                label="Submit"
                isLoading={isLoadingAD28AAIAirportDetail}
                disabled={isLoadingAD28AAIAirportDetail || Boolean(editApronId)}
                sx={{ width: 200 }}
              />
            </Stack>
          </form>
        </FormikProvider>
      {/* </Box> */}
      {isOpenDeleteConfirmDialog && (
        <ConfirmationDialog
          fullWidth
          open={isOpenDeleteConfirmDialog}
          title="Delete"
          content="Are you sure you want to delete this data?"
          saveButtonLabel="Delete"
          saveButtonColor="error"
          isLoading={isLoadingDeleteAD210AAIAirport}
          isDisabled={isLoadingDeleteAD210AAIAirport}
          handleSave={handleSaveDeleteDialog}
          handleCancel={handleCloseDeleteDialog}
        />
      )}
    </Paper>
  );
}
