import { flatten } from "lodash";

export const convertDms = (dd, isLng = false) => {
  const dir = dd < 0 ? (isLng ? "W" : "S") : isLng ? "E" : "N";

  const absDd = Math.abs(dd);
  let deg = absDd | 0;
  const frac = absDd - deg;
  let min = (frac * 60) | 0;
  let sec = (frac * 3600 - min * 60).toFixed(2);
  deg = String(deg).padStart(2, "0");
  min = String(min).padStart(2, "0");
  sec = String(sec).padStart(5, "0");

  return { dir, deg, min, sec };
};

export const convertToDms = (dd, isLng = false) => {
  const dms = convertDms(dd, isLng);

  if (isLng) {
    return "0" + dms.deg + "° " + dms.min + "' " + dms.sec + '" ' + dms.dir;
  }

  return dms.deg + "° " + dms.min + "' " + dms.sec + '" ' + dms.dir;
};

export const convertToDmsString = (dd, isLng = false) => {
  const dms = convertDms(dd, isLng);

  if (isLng) {
    return `0${dms.deg}${dms.min}${dms.sec}${dms.dir}`;
  }

  return `${dms.deg}${dms.min}${dms.sec}${dms.dir}`;
};

export const convertDmsToDD = (dms) => {
  let coord = dms
    .replace(" ", "")
    .replace("°", "")
    .replace("'", "")
    .replace('"', "");
  const directions = { N: 1, S: -1, E: 1, W: -1 };
  const direction = coord.charAt(coord.length - 1);
  coord = coord.substring(0, coord.length - 1);
  const decimals = coord.split(".");
  let decimal = "00";
  if (decimals.length > 1) {
    coord = decimals[0];
    decimal = decimals[1];
  }

  const SS = coord.slice(-2);
  coord = coord.substring(0, coord.length - 2);
  const MM = coord.slice(-2);
  coord = coord.substring(0, coord.length - 2);
  const HH = coord;

  return (
    (Number(HH) +
      Number(MM) / 60 +
      Number(String(SS) + "." + String(decimal)) / 3600) *
    directions[direction]
  );
};

export const getCenterPosition = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = flatten(coordinates).map((apnCoords) => {
    return apnCoords.map((reml) => ({ lng: reml[0], lat: reml[1] }));
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng(),
  };
};

export const getCenterAirspacePosition = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();
  const polygonCoords = flatten(coordinates).map((coords) => {
    return { lng: coords.lng, lat: coords.lat };
  });

  flatten(polygonCoords).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng(),
  };
};

export const getCenterPositionFromArray = (coordinates) => {
  const bounds = new window.google.maps.LatLngBounds();

  flatten(coordinates).forEach((element) => {
    bounds.extend(element);
  });

  const centerBound = bounds.getCenter();

  return {
    lat: centerBound.lat(),
    lng: centerBound.lng(),
  };
};
