import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useFormik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { routeNames } from "@constants/pageRoutes.constants";
import {
  loginValidationSchema,
  requestNewPasswordValidationSchema,
} from "@constants/surveyorData";
import { OutlinedButton, PrimaryButton, LightButton } from "@components/button";
import Header from "@components/Header";
import Loader from "@components/loader/FullScreenLoader";
import {
  userLogin,
  getRSA,
  requestNewPassword,
} from "@store/userSession/actions";
import { styles } from "@styles/loginStyles";
import { rsaEnc } from "@utils/decodeEncodeData";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isLoginLoading,
    isRSALoading,
    rsaKey,
    isRequestNewPassowrdLoading,
    loginCallbakUrl,
  } = useSelector((state) => state.userSession);
  const [isShowPassword, setShowPassword] = useState(false);
  const [openRequestPasswordDialog, setOpenRequestPasswordDialog] =
    useState(false);
  const loginForm = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      dispatch(
        userLogin(
          {
            username: rsaEnc(values.username, rsaKey),
            password: rsaEnc(values.password, rsaKey),
          },
          handleLoginCallback
        )
      );
    },
  });
  const newPasswordRequestForm = useFormik({
    initialValues: {
      user_email: "",
    },
    validationSchema: requestNewPasswordValidationSchema,
    onSubmit: (values) => {
      dispatch(requestNewPassword(values, handleCloseRequestPasswordDialog));
    },
  });

  useEffect(() => {
    if (!rsaKey) {
      dispatch(getRSA());
    }
  }, []); // eslint-disable-line

  const handleLoginCallback = () => {
    if (loginCallbakUrl) {
      navigate(loginCallbakUrl);
    } else {
      navigate(routeNames.UserDashboard, { replace: true });
    }
  };

  const handleCloseRequestPasswordDialog = () => {
    setOpenRequestPasswordDialog(false);
    newPasswordRequestForm.resetForm();
    newPasswordRequestForm.setSubmitting(false);
  };

  return (
    <>
      <div className="wrapper">
        <Header
          active={
            loginCallbakUrl
              ? loginCallbakUrl.replace(/\//g, "")
              : "data-management"
          }
        />
        <div className="main-content">
          <div className="main">
            <div className="main-login">
              <div className="content-area ">
                <div className="login-area">
                  {loginCallbakUrl === "/etod" && (
                    <Typography variant="span" sx={styles.logintitle}>
                      To Generate new eTOD, please login as Admin user
                    </Typography>
                  )}
                  {loginCallbakUrl === "/obstacle-analysis" && (
                    <Typography variant="span" sx={styles.logintitle}>
                      To Conduct Obstacle Analysis, please login as Admin user
                    </Typography>
                  )}
                  <div className="login-box">
                    <Typography className="login-box-header">
                      User Login
                    </Typography>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="login-form"
                    >
                      <InputLabel
                        htmlFor="username"
                        required
                        className="field-label"
                      >
                        Email
                      </InputLabel>
                      <TextField
                        sx={{ width: "100%" }}
                        className="text-field"
                        autoComplete="current-email"
                        name="username"
                        value={loginForm.values.username}
                        onChange={loginForm.handleChange}
                        placeholder="Enter your mail address"
                        variant="standard"
                        inputProps={{
                          style: { padding: 0, height: "40px" },
                        }}
                        helperText={
                          loginForm.touched.username &&
                          loginForm.errors.username
                        }
                        error={
                          loginForm.touched.username &&
                          Boolean(loginForm.errors.username)
                        }
                      />
                      <InputLabel
                        htmlFor="password"
                        required
                        className="field-label"
                      >
                        Password
                      </InputLabel>
                      <TextField
                        sx={{ width: "100%" }}
                        name="password"
                        type={isShowPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        autoComplete="current-password"
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        variant="standard"
                        inputProps={{
                          style: { padding: 0, height: "40px" },
                        }}
                        error={
                          loginForm.touched.password &&
                          Boolean(loginForm.errors.password)
                        }
                        helperText={
                          loginForm.touched.password &&
                          loginForm.errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!isShowPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!isShowPassword)
                                }
                                edge="end"
                                size="small"
                              >
                                {isShowPassword ? <FaEye /> : <FaEyeSlash />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <LightButton
                        label="Request Password?"
                        sx={styles.forgetPassBtn}
                        onClick={() => setOpenRequestPasswordDialog(true)}
                      />
                      <PrimaryButton
                        label="Log In"
                        type="submit"
                        onClick={() => loginForm.handleSubmit()}
                        className="login-btn"
                        isLoading={isLoginLoading}
                        disabled={isLoginLoading}
                      />
                    </form>
                  </div>
                  <div>
                    <PrimaryButton
                      label="Check CRC32Q"
                      variant="outlined"
                      sx={{ width: 192, p: "2.75px 15px" }}
                      onClick={() => navigate(routeNames.CRCCheck)}
                    />
                  </div>
                </div>
                {isRSALoading && <Loader />}
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
      <Dialog fullWidth open={openRequestPasswordDialog}>
        <DialogTitle>Request New Password</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              id="user_email"
              name="user_email"
              label="Email"
              value={newPasswordRequestForm.values.user_email}
              onChange={newPasswordRequestForm.handleChange}
              error={
                newPasswordRequestForm.touched.user_email &&
                Boolean(newPasswordRequestForm.errors.user_email)
              }
              helperText={
                newPasswordRequestForm.touched.user_email &&
                newPasswordRequestForm.errors.user_email
              }
              sx={{ mb: 2, mt: 2 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            label="Cancel"
            color="secondary"
            onClick={handleCloseRequestPasswordDialog}
            sx={{ width: 120 }}
          />
          <PrimaryButton
            label="Request"
            onClick={() => newPasswordRequestForm.handleSubmit()}
            disabled={isRequestNewPassowrdLoading}
            isLoading={isRequestNewPassowrdLoading}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
