import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  width: 140,
  fontSize: 18,
  fontWeight: 600,
  lineHeight: 2.1,
  "&:hover": {
    color: theme.palette.primary.dark,
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
  },
  "&:disabled": {
    color: "#00000042 !important",
    backgroundColor: "#cccccc",
    boxShadow: "none",
  },
}));

export default function StyledButton({ label, ...props }) {
  return (
    <ColorButton variant="contained" {...props}>
      {label}
    </ColorButton>
  );
}
