import React from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

const TextMarker = ({ label, position, className }) => {
  const icon = L.divIcon({ html: label, className: className });

  return <Marker position={position} icon={icon} />;
};

export default TextMarker;
