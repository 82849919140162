import React from "react";
import { theme } from "@styles/theme";

const CircleCheckedIcon = ({ disabled = false, ...props }) => {
  return (
    <svg
      width={props.width || "14"}
      height={props.height || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="7"
        cy="7"
        r="6.5"
        fill={props.color || theme.palette.primary.main}
        stroke={props.color || theme.palette.primary.main}
      />
      <circle
        cx="7"
        cy="7"
        r="3"
        fill={props.color || theme.palette.white.main}
      />
    </svg>
  );
};

export default CircleCheckedIcon;
