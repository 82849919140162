import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import TopTableHeaderRow from "./TopTableHeaderRow";
import TableSheetHeaderRow from "./TableSheetHeaderRow";
import MainTableHeaderRow from "./MainTableHeaderRow";
import DataTableRow from "./DataTableRow";
import TableFooterRow from "./TableFooterRow";

export default function DataCompareTable({
  isLoading,
  rows,
  selctedAirport,
  selctedCategory,
  headerName,
}) {
  return (
    <Fragment>
      <TopTableHeaderRow />
      <TableSheetHeaderRow
        selctedAirport={selctedAirport}
        selctedCategory={selctedCategory}
      />
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            {headerName && (
              <TableRow>
                <StyledTableCell align="center" colSpan={6}>
                  {headerName}
                </StyledTableCell>
              </TableRow>
            )}
            <MainTableHeaderRow selctedCategory={selctedCategory} />
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <StyledTableCell align="center" colSpan={6}>
                  Loading...
                </StyledTableCell>
              </TableRow>
            )}
            {!isLoading && rows.length === 0 && (
              <TableRow>
                <StyledTableCell align="center" colSpan={6}>
                  No Record Found.
                </StyledTableCell>
              </TableRow>
            )}
            {rows.map((row, i) => (
              <DataTableRow
                key={i}
                row={row}
                selctedCategory={selctedCategory}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableFooterRow
              selctedAirport={selctedAirport}
              selctedCategory={selctedCategory}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
}
