import React, { useEffect, useState, useRef, memo } from "react";
import { useSelector } from "react-redux";
import { Polyline, Popup, FeatureGroup, useMap } from "react-leaflet";
import { chunk, flattenDeep } from "lodash";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { ACCESS_TOKEN, roles } from "@constants/constant";
import { LightButton } from "@components/button";
import { areaHighLightColor } from "@constants/colors";
import { layerWiseColor } from "@constants/eChartData";
import { theme } from "@styles/theme";
import { getStorageValue } from "@utils/localStorage";

const RenderGeometryLine = ({
  data,
  selectedEchart,
  airportLocation,
  zoomLevel,
  isShowTable,
  handleEditShap,
  handleDeleteShap,
}) => {
  const map = useMap();
  const popupElRef = useRef(null);
  const lineRef = useRef(null);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const user = useSelector((state) => state.userSession.user);
  const [selectedOverE, setSelectedOverE] = useState(null);
  const isAdmin = user?.role === roles.admin;
  const charData = data ? data[selectedEchart] : null;
  const geoData =
    charData && charData.GeometryLine_List ? charData.GeometryLine_List : [];
  const geometryLineChunk = chunk(geoData, 10);

  useEffect(() => {
    if (!selectedEchart) {
      map.setView(airportLocation, zoomLevel, { animate: false });
      map.setMaxBounds(map.getBounds());
      return;
    }

    const flattenCoordinates = flattenDeep(
      geoData.flatMap((x) => x.geometry_json.coordinates)
    );
    let lats = flattenCoordinates
      .map((c) => c.lat)
      .reduce((previous, current) => (current += previous), 0.0);
    let longs = flattenCoordinates
      .map((c) => c.lng)
      .reduce((previous, current) => (current += previous), 0.0);

    const position = [
      lats / flattenCoordinates.length,
      longs / flattenCoordinates.length,
    ];
    map.setView(position, zoomLevel, { animate: false });
    map.fitBounds(lineRef.current.getBounds());
  }, [selectedEchart, isShowTable]); // eslint-disable-line

  const handlePolylineOver = (element) => {
    setSelectedOverE(element);
  };

  const handlePolylineOut = () => {
    setSelectedOverE(null);
  };

  const handlePolygonLine = (data) => {
    handleEditShap(data);
    if (!popupElRef.current || !map) return;
    map.closePopup();
  };

  const handlePolygonLineDelete = (data) => {
    handleDeleteShap(data);
    if (!popupElRef.current || !map) return;
    map.closePopup();
  };

  return (
    <FeatureGroup ref={lineRef}>
      {geometryLineChunk.map((geometryLines) => {
        return geometryLines.map((geometryLine) => {
          const coordinates = geometryLine.geometry_json.coordinates;

          return coordinates.map((coord, i) => {
            return (
              <Polyline
                key={`${geometryLine.id}_${i}`}
                positions={coord}
                pathOptions={{
                  color:
                    selectedOverE?.id === geometryLine.id
                      ? areaHighLightColor
                      : layerWiseColor[geometryLine.layer] ||
                        theme.palette.black.main,
                  weight: selectedOverE?.id === geometryLine.id ? 3 : 1.2,
                  opacity: selectedOverE?.id === geometryLine.id ? 1 : 0.8,
                }}
                eventHandlers={{
                  mouseover: () => handlePolylineOver(geometryLine),
                  mouseout: () => handlePolylineOut(),
                }}
              >
                <Popup ref={popupElRef} className="polygon-popup">
                  <Box
                    sx={{
                      borderBottom: "0.5px solid #42444A",
                      mb: 0.5,
                      p: "0px 0px 12px 10px",
                      fontSize: "14px",
                      color: "#333333",
                    }}
                  >
                    <Box>
                      <Typography variant="span" sx={{ pr: 1.5 }}>
                        ID:
                      </Typography>
                      <Typography variant="span" sx={{ fontWeight: 500 }}>
                        {geometryLine.id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="span" sx={{ pr: 1.5 }}>
                        Layer:
                      </Typography>
                      <Typography variant="span" sx={{ fontWeight: 500 }}>
                        {geometryLine.layer}
                      </Typography>
                    </Box>
                  </Box>
                  {isAdmin && accessToken && (
                    <Stack
                      sx={{ width: 200 }}
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <LightButton
                        label="Modify"
                        onClick={() => handlePolygonLine(geometryLine)}
                      />
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ borderColor: theme.palette.grey.main }}
                      />
                      <LightButton
                        label="Delete"
                        onClick={() => handlePolygonLineDelete(geometryLine)}
                      />
                    </Stack>
                  )}
                </Popup>
              </Polyline>
            );
          });
        });
      })}
    </FeatureGroup>
  );
};

export default memo(RenderGeometryLine);
