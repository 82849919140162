import { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function FlightProcedures({ selctedAirport, activeCategory, flight }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {flight ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell>{flight?.flight || "NIL"}</StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No data found.
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default FlightProcedures;
