import React from "react";
import { useNavigate } from "react-router-dom";
import SatsureiNetraLogo from "@assets/images/SatsureInetraLogo.png";
import { routeNames } from "@constants/pageRoutes.constants";

export default function BrandLogo() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(routeNames.Home);
  };

  return (
    <div className="logo-brand" onClick={handleClick}>
      <img src={SatsureiNetraLogo} alt="logo" className="satsure-img" />
    </div>
  );
}
