import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FieldArray, FormikProvider } from "formik";
import { isEmpty } from "lodash";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { EditIcon } from "@assets/svg/edit";
import { ACCESS_TOKEN } from "@constants/constant";
import { AD213bodyDetail } from "@constants/ad2DataForm.constant";
import { addEditAAIAirportDetail, getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad213Form({ selectedAirport, selectedCategory }) {
  const dispatch = useDispatch();
  const { AAIAirport, AD212RWYDetail, isLoadingAddAAIAirportDetail } =
    useSelector((state) => state.airport);
  const [editDDId, setEditDDId] = useState(null);
  const [editDDIndex, setEditDDIndex] = useState(null);

  useEffect(() => {
    if (isEmpty(AAIAirport)) return;
    if (Array.isArray(AAIAirport)) {
      AAIAirport.map((data, index) => {
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].rd`,
          data.rd ? data.rd : ""
        );
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].asda`,
          data.asda ? data.asda : ""
        );
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].lda`,
          data.lda ? data.lda : ""
        );
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].toda`,
          data.toda ? Number(data.toda) : ""
        );
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].tora`,
          data.tora ? data.tora : ""
        );
        createAd213Form.setFieldValue(
          `declareddistaces[${index}].remarks`,
          data.remarks ? data.remarks : ""
        );

        return true;
      });
    }
  }, [AAIAirport, createAd213Form]); // eslint-disable-line

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "declareddistaces",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleSuccessCallback = () => {
    createAd213Form.resetForm();
    createAd213Form.setSubmitting(false);
  };

  const createAd213Form = useFormik({
    enableReinitialize: true,
    initialValues: { declareddistaces: [AD213bodyDetail] },
    // validationSchema: createAd213ValidationSchema,
    onSubmit: (values) => {
      if (editDDId) {
        const payload = { ...values.declareddistaces[editDDIndex] };
        if (isEmpty(payload)) return;
        getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
          dispatch(
            addEditAAIAirportDetail(
              "declareddistaces",
              payload,
              selectedAirport.id,
              selectedAirport.version_id,
              token,
              () => {
                setEditDDId(null);
                setEditDDIndex(null);
                handleSuccessCallback();
                handleGetADCallBack();
              }
            )
          );
        });
      }
    },
  });

  const handleEditAprondetail = (id) => {
    setEditDDId(id);
  };

  const handleEditDoneApron = (editId, index) => {
    setEditDDId(editId);
    setEditDDIndex(index);
  };

  return (
    <Paper className="add-airport-data-form">
      {!isEmpty(AD212RWYDetail) && AD212RWYDetail.length > 0 ? (
        <FormikProvider value={createAd213Form}>
          <form
            onSubmit={createAd213Form.handleSubmit}
            style={{ height: "calc(100% - 0px)" }}
          >
            <Box sx={{ height: "calc(100% - 10px)", overflow: "auto", ml: 2 }}>
              <FieldArray
                name="declareddistaces"
                render={(arrayHelpers) => {
                  return (
                    <div>
                      {createAd213Form.values.declareddistaces.map(
                        (_, index) => {
                          const isTouched = createAd213Form.touched
                            ?.declareddistaces
                            ? createAd213Form.touched?.declareddistaces[index]
                            : false;
                          const isErrors = createAd213Form.errors
                            ?.declareddistaces
                            ? createAd213Form.errors?.declareddistaces[index]
                            : false;

                          const ddIds = !isEmpty(AAIAirport)
                            ? Array.isArray(AAIAirport) &&
                              AAIAirport.map((data) => data.rd)
                            : [];

                          return (
                            <Fragment key={index}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={styles.adVORMain}>
                                  <Typography
                                    variant="span"
                                    sx={{width: "85%",display:"inline-block",
                                    }}
                                  >
                                    Declared Distaces {index + 1}
                                  </Typography>
                                  <Box sx={styles.adFuelDeleteBtn}>
                                    {!isEmpty(AAIAirport) &&
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].rd &&
                                      (editDDId === ddIds[index] ? (
                                        <button
                                          type="button"
                                          style={styles.ad28Btn}
                                          onClick={() => {
                                            createAd213Form.handleSubmit();
                                            handleEditDoneApron(
                                              editDDId,
                                              index
                                            );
                                          }}
                                          disabled={
                                            isLoadingAddAAIAirportDetail
                                          }
                                        >
                                          Done
                                        </button>
                                      ) : (
                                        <IconButton
                                          color="primary"
                                          sx={{ p: 0 }}
                                          onClick={() =>
                                            handleEditAprondetail(ddIds[index])
                                          }
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      ))}
                                  </Box>
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>RWY Designator</Box>
                                <Box sx={styles.adInput}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`declareddistaces[${index}].rd`}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    value={
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].rd
                                    }
                                    onChange={createAd213Form.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].rd &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].rd
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].rd
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Accelerate distance available (ASDA)
                                </Box>
                                <Box sx={styles.adInput}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`declareddistaces[${index}].asda`}
                                    InputProps={{
                                      readOnly: !createAd213Form.values
                                        .declareddistaces[index].rd
                                        ? false
                                        : !Boolean(
                                            ddIds[index] === editDDId &&
                                              editDDId
                                          ),
                                    }}
                                    value={
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].asda
                                    }
                                    onChange={createAd213Form.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].asda &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].asda
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].asda
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Landing distance available (LDA)
                                </Box>
                                <Box sx={styles.adInput}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`declareddistaces[${index}].lda`}
                                    InputProps={{
                                      readOnly: !createAd213Form.values
                                        .declareddistaces[index].rd
                                        ? false
                                        : !Boolean(
                                            ddIds[index] === editDDId &&
                                              editDDId
                                          ),
                                    }}
                                    value={
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].lda
                                    }
                                    onChange={createAd213Form.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].lda &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].lda
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].lda
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Take-off distance available (TODA)
                                </Box>
                                <Box sx={styles.adInput}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`declareddistaces[${index}].toda`}
                                    InputProps={{
                                      readOnly: !createAd213Form.values
                                        .declareddistaces[index].rd
                                        ? false
                                        : !Boolean(
                                            ddIds[index] === editDDId &&
                                              editDDId
                                          ),
                                    }}
                                    value={
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].toda
                                    }
                                    onChange={createAd213Form.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].toda &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].toda
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].toda
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>
                                  Take-off run available (TORA)
                                </Box>
                                <Box sx={styles.adInput}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={`declareddistaces[${index}].tora`}
                                    InputProps={{
                                      readOnly: !createAd213Form.values
                                        .declareddistaces[index].rd
                                        ? false
                                        : !Boolean(
                                            ddIds[index] === editDDId &&
                                              editDDId
                                          ),
                                    }}
                                    value={
                                      createAd213Form.values.declareddistaces[
                                        index
                                      ].tora
                                    }
                                    onChange={createAd213Form.handleChange}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].tora &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].tora
                                      )
                                    }
                                    helperText={
                                      isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].tora
                                        : ""
                                    }
                                    sx={{ mb: 2, mt: 2 }}
                                  />
                                </Box>
                              </Stack>
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pl: 2, pr: 2 }}
                              >
                                <Box sx={{ width: "30%" }}>remarks</Box>
                                <Box sx={styles.adInput}>
                                  <FormControl
                                    variant="outlined"
                                    sx={{ mb: 2, mt: 2, width: "100%" }}
                                    error={
                                      isTouched &&
                                      isErrors &&
                                      createAd213Form.touched?.declareddistaces[
                                        index
                                      ].remarks &&
                                      Boolean(
                                        createAd213Form.errors
                                          ?.declareddistaces[index].remarks
                                      )
                                    }
                                  >
                                    <TextareaAutosize
                                      minRows={2}
                                      id={`declareddistaces[${index}].remarks`}
                                      name={`declareddistaces[${index}].remarks`}
                                      value={
                                        createAd213Form.values.declareddistaces[
                                          index
                                        ].remarks
                                      }
                                      onChange={createAd213Form.handleChange}
                                      aria-describedby="aerodrome-dd-remarks"
                                      readOnly={
                                        !createAd213Form.values
                                          .declareddistaces[index].rd
                                          ? false
                                          : !Boolean(
                                              ddIds[index] === editDDId &&
                                                editDDId
                                            )
                                      }
                                    />
                                    <FormHelperText id="aerodrome-dd-remarks">
                                      {isErrors && isTouched
                                        ? createAd213Form.errors
                                            ?.declareddistaces[index].remarks
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </Box>
                              </Stack>
                              <Divider sx={{ mb: 1 }} />
                            </Fragment>
                          );
                        }
                      )}
                    </div>
                  );
                }}
              />
            </Box>
          </form>
        </FormikProvider>
      ) : (
        <Box sx={styles.ad214RwyLabel}>Runway detail not added.</Box>
      )}
    </Paper>
  );
}

export default Ad213Form;
