import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { find } from "lodash";
import {
  Box,
  Paper,
  Stack,
  TextareaAutosize,
  FormHelperText,
  FormControl,
  TextField,
} from "@mui/material";
import { LightButton } from "@components/button";
import SelectInput from "@components/core/SelectInput";
import { ACCESS_TOKEN } from "@constants/constant";
import {
  createAd211ValidationSchema,
  hoursOfOperation,
} from "@constants/ad2Data";
import { AD211initialValue } from "@constants/ad2DataForm.constant";
import { PrimaryButton } from "@components/button";
import { addEditAAIAirportDetail } from "@store/airport/actions";
import { getAAIAirport } from "@store/airport/actions";
import { setAAIAirport } from "@store/airport";
import { styles } from "@styles/dataManagementStyles";
import { getAsyncStorageValue } from "@utils/localStorage";

function Ad211MeteorologicalForm({
  isReadOnly,
  selectedAirport,
  selectedCategory,
  handleCancelReadOnly,
}) {
  const dispatch = useDispatch();
  const { isLoadingAddAAIAirportDetail, AAIAirport } = useSelector(
    (state) => state.airport
  );

  useEffect(() => {
    handleCancelReadOnly(false);
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    if (!AAIAirport) return;

    createAd211Form.setFieldValue(
      "metName",
      AAIAirport?.name ? AAIAirport?.name : ""
    );
    createAd211Form.setFieldValue(
      "office",
      AAIAirport?.office ? AAIAirport?.office : ""
    );
    createAd211Form.setFieldValue(
      "hours",
      AAIAirport?.hours ? AAIAirport?.hours : ""
    );
    createAd211Form.setFieldValue(
      "air_traffic",
      AAIAirport?.air_traffic ? AAIAirport?.air_traffic : ""
    );
    createAd211Form.setFieldValue(
      "briefing",
      AAIAirport?.briefing ? AAIAirport?.briefing : ""
    );
    createAd211Form.setFieldValue(
      "charts",
      AAIAirport?.charts ? AAIAirport?.charts : ""
    );
    createAd211Form.setFieldValue(
      "flight_documentation",
      AAIAirport?.flight_documentation ? AAIAirport?.flight_documentation : ""
    );
    createAd211Form.setFieldValue(
      "forecast",
      AAIAirport?.forecast ? AAIAirport?.forecast : ""
    );
    createAd211Form.setFieldValue(
      "sup_met",
      AAIAirport?.sup_met ? AAIAirport?.sup_met : ""
    );
    createAd211Form.setFieldValue(
      "hours_remarks",
      AAIAirport?.hours_remarks ? AAIAirport?.hours_remarks : ""
    );
    createAd211Form.setFieldValue(
      "remarks",
      AAIAirport?.remarks ? AAIAirport?.remarks : ""
    );
    // eslint-disable-next-line
  }, [AAIAirport]);

  const handleGetADCallBack = () => {
    dispatch(
      getAAIAirport(
        "meteorologicalinfo",
        selectedAirport.id,
        selectedAirport.version_id,
        (data) => {
          dispatch(setAAIAirport(data));
        }
      )
    );
  };

  const handleAddSuccessCallback = () => {
    createAd211Form.resetForm();
    createAd211Form.setSubmitting(false);
  };

  const createAd211Form = useFormik({
    enableReinitialize: true,
    initialValues:AD211initialValue,
    validationSchema: createAd211ValidationSchema,
    onSubmit: (values) => {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(
          addEditAAIAirportDetail(
            "meteorologicalinfo",
            {
              name: values.metName,
              office: values.office,
              hours: values.hours,
              air_traffic: values.air_traffic,
              briefing: values.briefing,
              charts: values.charts,
              flight_documentation: values.flight_documentation,
              forecast: values.forecast,
              sup_met: values.sup_met,
              hours_remarks: values.hours_remarks,
              remarks: values.remarks,
            },
            selectedAirport.id,
            selectedAirport.version_id,
            token,
            () => {
              handleAddSuccessCallback();
              handleGetADCallBack();
              handleCancelReadOnly(false);
            }
          )
        );
      });
    },
  });

  return (
    <Paper className="add-airport-data-form">
      <Box sx={styles.adForm}>
        <form>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Name</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="metName"
                name="metName"
                value={createAd211Form.values.metName}
                onChange={createAd211Form.handleChange}
                error={
                  createAd211Form.touched.metName &&
                  Boolean(createAd211Form.errors.metName)
                }
                helperText={
                  createAd211Form.touched.metName &&
                  createAd211Form.errors.metName
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Office</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="office"
                name="office"
                value={createAd211Form.values.office}
                onChange={createAd211Form.handleChange}
                error={
                  createAd211Form.touched.office &&
                  Boolean(createAd211Form.errors.office)
                }
                helperText={
                  createAd211Form.touched.office &&
                  createAd211Form.errors.office
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mb: 2, mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Hours</Box>
            <Box sx={styles.adInput}>
              <SelectInput
                name="hours"
                value={find(hoursOfOperation, {
                  value: createAd211Form.values.hours,
                })}
                onChange={(option) => {
                  createAd211Form.setFieldValue("hours", option.value);
                }}
                options={hoursOfOperation}
                isDisabled={!isReadOnly}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Air Traffic</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="air_traffic"
                name="air_traffic"
                value={createAd211Form.values.air_traffic}
                onChange={createAd211Form.handleChange}
                error={
                  createAd211Form.touched.air_traffic &&
                  Boolean(createAd211Form.errors.air_traffic)
                }
                helperText={
                  createAd211Form.touched.air_traffic &&
                  createAd211Form.errors.air_traffic
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Briefing</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="briefing"
                name="briefing"
                value={createAd211Form.values.briefing}
                onChange={createAd211Form.handleChange}
                error={
                  createAd211Form.touched.briefing &&
                  Boolean(createAd211Form.errors.briefing)
                }
                helperText={
                  createAd211Form.touched.briefing &&
                  createAd211Form.errors.briefing
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Charts</Box>
            <Box sx={styles.adInput}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                id="charts"
                name="charts"
                value={createAd211Form.values.charts}
                onChange={createAd211Form.handleChange}
                error={
                  createAd211Form.touched.charts &&
                  Boolean(createAd211Form.errors.charts)
                }
                helperText={
                  createAd211Form.touched.charts &&
                  createAd211Form.errors.charts
                }
                InputProps={{
                  readOnly: !isReadOnly,
                }}
                sx={{ mt: 2 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Flight Documentation</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd211Form.touched?.flight_documentation &&
                  Boolean(createAd211Form.errors?.flight_documentation)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="flight_documentation"
                  name="flight_documentation"
                  value={createAd211Form.values.flight_documentation}
                  onChange={createAd211Form.handleChange}
                  aria-describedby="aerodrome-flight_documentation"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-flight_documentation">
                  {createAd211Form.touched?.flight_documentation &&
                    createAd211Form.errors?.flight_documentation}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Forecast</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd211Form.touched?.forecast &&
                  Boolean(createAd211Form.errors?.forecast)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="forecast"
                  name="forecast"
                  value={createAd211Form.values.forecast}
                  onChange={createAd211Form.handleChange}
                  aria-describedby="aerodrome-forecast"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-forecast">
                  {createAd211Form.touched?.forecast &&
                    createAd211Form.errors?.forecast}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Supplementary equipment</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd211Form.touched?.sup_met &&
                  Boolean(createAd211Form.errors?.sup_met)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="sup_met"
                  name="sup_met"
                  value={createAd211Form.values.sup_met}
                  onChange={createAd211Form.handleChange}
                  aria-describedby="aerodrome-sup_met"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-sup_met">
                  {createAd211Form.touched?.sup_met &&
                    createAd211Form.errors?.sup_met}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Hours Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd211Form.touched?.hours_remarks &&
                  Boolean(createAd211Form.errors?.hours_remarks)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="hours_remarks"
                  name="hours_remarks"
                  value={createAd211Form.values.hours_remarks}
                  onChange={createAd211Form.handleChange}
                  aria-describedby="aerodrome-hours_remarks"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-hours_remarks">
                  {createAd211Form.touched?.hours_remarks &&
                    createAd211Form.errors?.hours_remarks}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
            <Box sx={{ width: "30%" }}>Remarks</Box>
            <Box sx={styles.adInput}>
              <FormControl
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
                error={
                  createAd211Form.touched?.remarks &&
                  Boolean(createAd211Form.errors?.remarks)
                }
              >
                <TextareaAutosize
                  minRows={2}
                  id="remarks"
                  name="remarks"
                  value={createAd211Form.values.remarks}
                  onChange={createAd211Form.handleChange}
                  aria-describedby="aerodrome-remarks"
                  readOnly={!isReadOnly}
                />
                <FormHelperText id="aerodrome-remarks">
                  {createAd211Form.touched?.remarks &&
                    createAd211Form.errors?.remarks}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </form>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={styles.adFormBtn}
      >
        <LightButton
          label="Clear"
          sx={{ mr: 4 }}
          isLoading={false}
          onClick={() => {
            createAd211Form.resetForm();
          }}
        />
        <PrimaryButton
          label="Submit"
          onClick={() => createAd211Form.handleSubmit()}
          isLoading={isLoadingAddAAIAirportDetail}
          disabled={isLoadingAddAAIAirportDetail || !isReadOnly}
          sx={{ width: 200 }}
        />
      </Stack>
    </Paper>
  );
}

export default Ad211MeteorologicalForm;
