import React, { Fragment, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Marker, CircleMarker, useMap } from "react-leaflet";
import { airspaceType } from "@constants/airspaceData";
import { getNavaidIcon } from "@utils/navaidIcon";

export default function RenderNavidsAirspace({
  navidAirspaceGeometry,
  handleViewMore,
}) {
  const map = useMap();
  const [isShowIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (navidAirspaceGeometry?.length <= 15) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [navidAirspaceGeometry]);

  map.on("zoomend", function (e) {
    if (navidAirspaceGeometry?.length <= 15) {
      return;
    }

    const zoomLevel = e.target.getZoom();
    if (zoomLevel >= 7) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  });

  const handlePolygonOver = (e) => {
    e.target.openPopup();
  };

  const handlePolygonClick = (info) => {
    handleViewMore(info, airspaceType.navaids);
  };

  if (isEmpty(navidAirspaceGeometry)) return null;

  return (
    <Fragment>
      {!isShowIcon &&
        navidAirspaceGeometry.map((data, i) => {
          return (
            <CircleMarker
              key={i}
              pane="tooltipPane"
              center={{
                lat: data.dd_coordinates[0],
                lng: data.dd_coordinates[1],
              }}
              pathOptions={{
                color: "#d702db",
                fillOpacity: 0.8,
                weight: 1,
              }}
              radius={4}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e),
                click: () => handlePolygonClick(data),
              }}
            />
          );
        })}
      {isShowIcon &&
        navidAirspaceGeometry.map((data, i) => {
          return (
            <Marker
              key={i}
              icon={getNavaidIcon(data.type)}
              position={{
                lat: data.dd_coordinates[0],
                lng: data.dd_coordinates[1],
              }}
              eventHandlers={{
                mouseover: (e) => handlePolygonOver(e),
                click: () => handlePolygonClick(data),
              }}
            />
          );
        })}
    </Fragment>
  );
}
