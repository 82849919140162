import React from "react";

export const CirclePlusIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 8.25H9.75V6.75C9.75 6.3375 9.4125 6 9 6C8.5875 6 8.25 6.3375 8.25 6.75V8.25H6.75C6.3375 8.25 6 8.5875 6 9C6 9.4125 6.3375 9.75 6.75 9.75H8.25V11.25C8.25 11.6625 8.5875 12 9 12C9.4125 12 9.75 11.6625 9.75 11.25V9.75H11.25C11.6625 9.75 12 9.4125 12 9C12 8.5875 11.6625 8.25 11.25 8.25ZM9 15C5.69175 15 3 12.3082 3 9C3 5.69175 5.69175 3 9 3C12.3082 3 15 5.69175 15 9C15 12.3082 12.3082 15 9 15ZM9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5Z"
      fill={props.color ? props.color : "white"}
    />
  </svg>
);
