import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import { theme } from "@styles/theme";

export default function RestrictedAirspaceDetail({
  isLoadingRestrictiveAirspaceDetails,
  restrictiveAirspaceDetails,
}) {
  return (
    <TableContainer>
      <Table>
        <TableBody sx={{ backgroundColor: theme.palette.white.main }}>
          {isLoadingRestrictiveAirspaceDetails && (
            <TableRow>
              <TableCell align="center" colSpan={2}>
                Loading...
              </TableCell>
            </TableRow>
          )}
          {!isLoadingRestrictiveAirspaceDetails &&
            Object.keys(restrictiveAirspaceDetails).length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  No Record Found.
                </TableCell>
              </TableRow>
            )}
          {Object.keys(restrictiveAirspaceDetails).map((key, i) => {
            return (
              <TableRow
                key={i}
                sx={{
                  ":first-of-type": {
                    backgroundColor: theme.palette.secondary.light,
                  },
                }}
              >
                <TableCell sx={{ width: "100px" }}>
                  {key.replace("_", " ")}
                </TableCell>
                <TableCell>{restrictiveAirspaceDetails[key]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
