import { isEmpty } from "lodash";
import RenderConventionalRouteAirspaceUsePlan from "./RenderConventionalRouteAirspaceUsePlan";

export default function RenderCNAVLiveTrackerAUP({
  filterCNAVAUPdata,
  CNAVRouteLiveGeometryData,
  handleAirspaceUsePlanDetails,
}) {
  if (isEmpty(filterCNAVAUPdata) || isEmpty(CNAVRouteLiveGeometryData))
    return null;

  return (
    filterCNAVAUPdata &&
    filterCNAVAUPdata.map((data) => {
      const routekey = data.id;
      
      return (
        <RenderConventionalRouteAirspaceUsePlan
          key={data.id}
          filterCNAVAirspace={data}
          routeData={CNAVRouteLiveGeometryData[`${routekey}`] || []}
          handleAirspaceUsePlanDetails={handleAirspaceUsePlanDetails}
        />
      );
    })
  );
}
