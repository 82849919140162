export const firData = {
  VI: "Delhi Flight Information Region (FIR)",
  VA: "Mumbai Flight Information Region (FIR)",
  VE: "Kolkata Flight Information Region (FIR)",
  VO: "Chennai Flight Information Region (FIR)",
};

export const firShortData = {
  VI: "Delhi FIR",
  VA: "Mumbai FIR",
  VE: "Kolkata FIR",
  VO: "Chennai FIR",
};
