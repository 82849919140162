import React, { Fragment } from "react";
import { Polyline, Marker } from "react-leaflet";
import { airspaceType } from "@constants/airspaceData";
import { getRouteWayPointIcon } from "@utils/routeWayPointIcon";

export default function RenderWayPointsRouteAirspace({
  routeData,
  handleViewMore,
}) {
  const handlePolygonOver = (e) => {
    e.target.openPopup();
  };

  const handlePolygonClick = (info, subRouteData) => {
    handleViewMore(info, airspaceType.wayPointRoutes, subRouteData);
  };

  return (
    <Fragment>
      {routeData.map((subRouteData, i) => {
        const arrLength = subRouteData?.length || 0;
        const lastRowIndex = arrLength === 0 ? 0 : arrLength - 1;
        const lastRow = subRouteData[lastRowIndex];

        return (
          <Fragment key={i}>
            {subRouteData.map((rnav, i) => {
              const path = [
                rnav.DD_startpoint_coordinates,
                rnav.DD_endpoint_coordinates,
              ];

              return (
                <Polyline
                  key={i}
                  pane="markerPane"
                  positions={path}
                  pathOptions={{ color: "#ff0000", weight: 2 }}
                  eventHandlers={{
                    mouseover: (e) => handlePolygonOver(e),
                    click: () => handlePolygonClick(rnav, subRouteData),
                  }}
                />
              );
            })}
            {subRouteData.map((rnav, i) => {
              return (
                <Marker
                  key={i}
                  pane="tooltipPane"
                  icon={getRouteWayPointIcon(rnav.startpoint_reportingATC)}
                  position={rnav.DD_startpoint_coordinates}
                  eventHandlers={{
                    mouseover: (e) => handlePolygonOver(e),
                    click: () => handlePolygonClick(rnav, subRouteData),
                  }}
                />
              );
            })}
            {Boolean(lastRow) && (
              <Marker
                pane="tooltipPane"
                icon={getRouteWayPointIcon(lastRow.endpoint_reportingATC)}
                position={lastRow.DD_endpoint_coordinates}
                eventHandlers={{
                  mouseover: (e) => handlePolygonOver(e),
                  click: () => handlePolygonClick(lastRow, subRouteData),
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
}
