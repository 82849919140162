import React, { Fragment } from "react";
import * as Cesium from "cesium";
import { Entity, EntityDescription } from "resium";
import { chain } from "lodash";
import PopupItem from "@components/map/PopupItem";
import { staticColorMap } from "@constants/colors";
import { ZvalueMultiplyby } from "@constants/constant";
import { styles } from "@styles/etodStyles";

export default function Render3DAnalysisAreaObstacles({
  selctedAirport,
  obstacles,
  isShowPenetrationObstacle,
  isAbovePermissibleClick,
  isBelowPermissibleClick,
  selectedAreas,
}) {
  const areas = selectedAreas.map((area) => area.split("__")[0]);
  let visibleObstacles = chain(obstacles)
    .filter((obstacle) => {
      let name = `${obstacle.layer_rwy}_${obstacle.layer_sub_category}`;
      if (obstacle.layer_category === "AREA4") {
        name = `${obstacle.layer_rwy}_${obstacle.layer_category}`;
      }
      if (obstacle.layer_sub_category === "2D") {
        name = "Area2D";
      }

      return areas.includes(name);
    })
    .value();
  if (isAbovePermissibleClick && !isBelowPermissibleClick) {
    visibleObstacles = visibleObstacles.filter(
      (obstacle) => obstacle.penetration
    );
  } else if (isBelowPermissibleClick && !isAbovePermissibleClick) {
    visibleObstacles = visibleObstacles.filter(
      (obstacle) => !obstacle.penetration
    );
  }

  return (
    <Fragment>
      {visibleObstacles.map((obstacle, i) => {
        let obstacleColor = staticColorMap["Obstacles"];

        if (isShowPenetrationObstacle) {
          obstacleColor = obstacle.penetration
            ? staticColorMap["RedObstacle"]
            : staticColorMap["GreenObstacle"];
        }

        return (
          <Entity
            key={`${i}-${obstacle.id}`}
            name={obstacle.point_name}
            position={Cesium.Cartesian3.fromDegrees(
              obstacle.dd_longitude,
              obstacle.dd_latitude,
              (obstacle.top_elev - selctedAirport.elevation) * ZvalueMultiplyby
            )}
            ellipse={{
              semiMinorAxis: 10,
              semiMajorAxis: 10,
              height: 0,
              extrudedHeight:
                (obstacle.top_elev - selctedAirport.elevation) *
                ZvalueMultiplyby,
              material:
                Cesium.Color.fromCssColorString(obstacleColor).withAlpha(0.5),
            }}
          >
            <EntityDescription>
              <div style={styles.etodObstaclesLabel}>
                <PopupItem>Point Name - {obstacle.point_name || "-"}</PopupItem>
                <PopupItem>Object - {obstacle.object || "-"}</PopupItem>
                <PopupItem>Latitude - {obstacle.latitude || "-"}</PopupItem>
                <PopupItem>Longitude - {obstacle.longitude || "-"}</PopupItem>
                <PopupItem>
                  Affected Area - {obstacle.affected_area || "-"}
                </PopupItem>
                <PopupItem>
                  UOM Elevation - {obstacle.uom_elevation || "-"}
                </PopupItem>
                <PopupItem>
                  Top Elevation - {obstacle.top_elev || "-"}
                </PopupItem>
                <PopupItem>
                  Permissible Elevation -{" "}
                  {obstacle.permissible_elevation || "-"}
                </PopupItem>
                <PopupItem>Difference - {obstacle.difference || "-"}</PopupItem>
                <PopupItem>
                  Horizontal Datum - {obstacle.horizontal_datum || "-"}
                </PopupItem>
                <PopupItem>
                  Vertical datum - {obstacle.vertical_datum || "-"}
                </PopupItem>
                <PopupItem>
                  Marking LGT - {obstacle.marking_lgt || "-"}
                </PopupItem>
              </div>
            </EntityDescription>
          </Entity>
        );
      })}
    </Fragment>
  );
}
