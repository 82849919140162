import React from "react";
import { theme } from "@styles/theme";

export const EditIcon = ({ disabled, ...props }) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.6066 0.5C11.7392 0.5 11.8664 0.552678 11.9602 0.646447L14.7886 3.47487C14.9838 3.67014 14.9838 3.98672 14.7886 4.18198L5.59619 13.3744C5.53337 13.4372 5.45495 13.4821 5.369 13.5046L1.54057 14.5046C1.36883 14.5494 1.18617 14.4999 1.06066 14.3744C0.93514 14.2489 0.885581 14.0662 0.930441 13.8945L1.93044 10.066C1.95289 9.98007 1.99784 9.90165 2.06066 9.83883L11.253 0.646447C11.3468 0.552678 11.474 0.5 11.6066 0.5Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
    <path
      d="M1 16.25C0.585786 16.25 0.25 16.5858 0.25 17C0.25 17.4142 0.585786 17.75 1 17.75H16C16.4142 17.75 16.75 17.4142 16.75 17C16.75 16.5858 16.4142 16.25 16 16.25H1Z"
      fill={disabled ? "#D9D9D9" : theme.palette.primary.main}
    />
  </svg>
);
