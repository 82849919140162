import { orderBy } from 'lodash';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { shortScheduleLabel } from "@utils/util";

const AixmAD13Table = ({ AD1_3Aerodrome }) => {
  return (
    <Box className="selected-general-data">
      <Box className="selected-ad-data">
        <TableContainer>
          <Table className="ad-table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={2} rowSpan={2} align="center">
                  Aerodrome/Heliport Name Location Indicator
                </StyledTableCell>
                <StyledTableCell colSpan={3} align="center">
                  Type of traffic permitted to use the aerodrome/heliport
                </StyledTableCell>
                <StyledTableCell rowSpan={2} align="center">
                  Reference to AD Section and Remarks
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align="center">
                  International- Domestic (INTL-DOM)
                </StyledTableCell>
                <StyledTableCell align="center">IFR/VFR</StyledTableCell>
                <StyledTableCell align="center">
                  S =Scheduled, NS =Non-scheduled, P =Private
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className="ad-table">
              {orderBy(AD1_3Aerodrome, ["airport_name"], ["asc"]).map(
                (ADdata, i) => {
                  return (
                    <TableRow key={i}>
                      <StyledTableCell>{ADdata.airport_name}</StyledTableCell>
                      <StyledTableCell>{ADdata.icao}</StyledTableCell>
                      <StyledTableCell>
                        {ADdata?.fc_origin === "NTL" ? "DOM" : "INTL - DOM"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ADdata.fc_rule === "ALL" ? "IFR/VFR" : ADdata.fc_rule}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ADdata.fc_purpose === "ALL"
                          ? "S-NS-P"
                          : shortScheduleLabel(ADdata.fc_purpose)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        AD 2-{ADdata.icao}
                      </StyledTableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AixmAD13Table;