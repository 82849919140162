import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function Surfacmovements({ selctedAirport, activeCategory, surfacmovements }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {surfacmovements ? (
            <TableBody>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Use of aircraft stand identification signs, taxiway guidelines
                  and visual docking/parking guidance system at aircraft stands
                </StyledTableCell>
                <StyledTableCell>
                  {surfacmovements?.aircraftstands
                    ? surfacmovements?.aircraftstands
                    : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>
                  Runway and taxiway markings and lights
                </StyledTableCell>
                <StyledTableCell>
                  {surfacmovements?.markings ? surfacmovements?.markings : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell>Stop bars</StyledTableCell>
                <StyledTableCell sx={{ width: 450 }}>
                  {surfacmovements?.stopbars ? surfacmovements?.stopbars : "-"}
                </StyledTableCell>
              </TableRow>
              <TableRow sx={styles.tableRowLastBorderNone}>
                <StyledTableCell sx={{ width: 450 }}>Remarks</StyledTableCell>
                <StyledTableCell>
                  {surfacmovements?.remarks ? surfacmovements?.remarks : "-"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default Surfacmovements;
