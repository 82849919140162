import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, uniq } from "lodash";
import { Box, Typography } from "@mui/material";
import UserSurfaceGen from "@assets/svg/UserSurfaceGen";
import StyledButton from "@components/button/StyledButton";
import ConfirmationDialog from "@components/core/ConfirmationDialog";
import EtodSideBar from "@components/EtodSideBar";
import EtodMap from "@components/etodOSMMap/MapContainer";
import Etod3dMap from "@components/etod3Dmap/MapContainer";
import Header from "@components/Header";
import VisualSurveyUploadModal from "@components/Modals/VisualSurveyUploadModal";
import ProceedObstacleAnalysisModal from "@components/Modals/ProceedObstacleAnalysisModal";
import NavButton from "@components/NavButton";
import {
  roles,
  defaultLocationCenter,
  ACCESS_TOKEN,
} from "@constants/constant";
import { colorMap } from "@constants/colors";
import { routeNames } from "@constants/pageRoutes.constants";
import {
  getEtodAllAirport,
  getEtodLayers,
  getEtodAixmAirports,
  getEtodArea1,
  getEtodDems,
  getAnalysisObstacle,
  uploadeObstacleFileWithoutReview,
} from "@store/etod/actions";
import {
  selectEtodAixmAirports,
  selectEtodAirports,
  selectEtodAreaList,
  selectEtodArea2D,
  selectOLSApproachList,
  selectOLSHorizontalSurfaceList,
  selectOLSTransitionalSurfaceList,
  selectOLSOuterTransitionalSurfaceList,
  selectOLSObstacleFreeZoneList,
  selectOLSRwyStripList,
  selectOLSTakeOffList,
  selectOLSConicalSurfaceList,
  selectEtodArea1,
  selectOLSOptions,
  selectAreaOptions,
  selectedEtodAirportAreaFilter,
  selectDemOptions,
  setAnalysisObstacles,
  setEtodLayers,
  selectAreaAnalysisObstacle,
  selectOlsAnalysisObstacle,
  setVisualiseObstacles,
} from "@store/etod";
import { commonStyles } from "@styles/commonStyles";
import { styles } from "@styles/etodStyles";
import { theme } from "@styles/theme";
import { getStorageValue, getAsyncStorageValue } from "@utils/localStorage";

export default function Etod() {
  const [isEtod] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const {
    isLoadingEtodAirport,
    isLoadingEtodAixmAirports,
    isLoadingEtodLayers,
    isLoadingArea1,
    isLoadingExportObstacle,
    isLoadingEtodDems,
    etodDems,
    visualiseObstacles,
    createlayerAirportData,
    analysisObstacles,
  } = useSelector((state) => state.etod);
  const currentUser = useSelector((state) => state.userSession.user);
  const etodAixmAirports = useSelector(selectEtodAixmAirports);
  const allAirports = useSelector(selectEtodAirports);
  const etodAreaListData = useSelector(selectEtodAreaList);
  const etodArea2D = useSelector(selectEtodArea2D);
  const OLSApproachList = useSelector(selectOLSApproachList);
  const OLSHorizontalSurfaceList = useSelector(selectOLSHorizontalSurfaceList);
  const OLSTransitionalSurfaceList = useSelector(
    selectOLSTransitionalSurfaceList
  );
  const OLSOuterTransitionalSurfaceList = useSelector(
    selectOLSOuterTransitionalSurfaceList
  );
  const OLSObstacleFreeZoneList = useSelector(selectOLSObstacleFreeZoneList);
  const OLSRwyStripList = useSelector(selectOLSRwyStripList);
  const OLSTakeOffList = useSelector(selectOLSTakeOffList);
  const OLSConicalSurfaceList = useSelector(selectOLSConicalSurfaceList);
  const area1Data = useSelector(selectEtodArea1);
  const areaAnalysisObstacle = useSelector(selectAreaAnalysisObstacle);
  const olsAnalysisObstacle = useSelector(selectOlsAnalysisObstacle);
  const selectedAirportFeature = useSelector((state) =>
    selectedEtodAirportAreaFilter(state)
  );
  const areaOptions = useSelector((state) => selectAreaOptions(state));
  const demOptions = useSelector((state) => selectDemOptions(state));
  const OLSOptions = useSelector((state) => selectOLSOptions(state));
  const [selctedAirport, setAirport] = useState(null);
  const [selctedFeature, setFeature] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [airportLocation, setAirportLocation] = useState(defaultLocationCenter);
  const [selectedAreas, setSelectedArea] = useState([]);
  const [selectedOls, setSelectedOls] = useState([]);
  const [selectedDems, setSelectedDem] = useState([]);
  const [isShowPenetrationObstacle, setShowPenetrationObstacle] =
    useState(false);
  const [is3DMap, set3DMap] = useState(false);
  const [selectedAirportDetail, setSelectedAirportDetail] = useState(null);
  const [isSuccessProceedObstacle, setSuccessProceedObstacle] = useState(false);
  const [isVisualUploadProceedObstacle, setVisualUploadProceedObstacle] =
    useState(false);
  const [
    isOpenConfirmProceedObstacleModal,
    setOpenConfirmProceedObstacleModal,
  ] = useState(false);
  const [isShowProceedObstacleModal, setShowProceedObstacleModal] =
    useState(false);
  const [proceedTitle, setProceedTitle] = useState("");
  const [proceedDescription, setProceedDescription] = useState("");
  const [uploadedObstacelFile, setUploadedObstacelFile] = useState(null);
  const [isShowVisualSurveyModal, setShowVisualSurveyModal] = useState(false);
  const [isShowVisualSurveyBtn, setShowVisualSurveyBtn] = useState(false);
  const [isShowRWYrightSidebar, setShowRWYrightSidebar] = useState(true);
  const [isShowProceedbtn, setShowProceedbtn] = useState(false);
  const [isShowObstacleAirportFeature, setShowObstacleAirportFeature] =
    useState(true);
  const [surafceTitle, setSurafceTitle] = useState(null);

  useEffect(() => {
    dispatch(getEtodArea1());
    dispatch(getEtodAllAirport());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (currentUser && etodAixmAirports && etodAixmAirports.length === 0) {
      getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
        dispatch(getEtodAixmAirports(token));
      });
    }
  }, [currentUser]); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(createlayerAirportData)) {
      setFeature(null);
      setAirport(null);
      setAirportLocation(defaultLocationCenter);
      dispatch(setAnalysisObstacles([]));
      dispatch(setEtodLayers([]));
      setZoomLevel(5);
      handleClearAll();
    } else {
      setShowVisualSurveyBtn(createlayerAirportData.isGenerateSurface);
      setSurafceTitle("Generate Surfaces");
      setAirport(createlayerAirportData);
      setAirportLocation([
        createlayerAirportData.arp_latitude,
        createlayerAirportData.arp_longitude,
      ]);
      setZoomLevel(14);
      dispatch(getEtodLayers(createlayerAirportData.id));
    }
  }, [createlayerAirportData, dispatch]);

  const handle3DMap = () => {
    set3DMap(!is3DMap);
  };

  const handleAirport = (option) => {
    handleClearAll();

    if (option === null) {
      setFeature(null);
      setAirport(null);
      setAirportLocation(defaultLocationCenter);
      dispatch(setAnalysisObstacles([]));
      setZoomLevel(5);
    } else {
      dispatch(getEtodLayers(option.id));
      dispatch(getEtodDems(option.id));
      dispatch(getAnalysisObstacle(option.id));
      setAirport(option);
      setAirportLocation(option.coordinates.coordinates);
      setZoomLevel(14);
    }
  };

  const handlePenetrationObstacleToggle = (event) => {
    setShowPenetrationObstacle(event.target.checked);
  };

  const handleFeature = (option) => {
    handleClearAll();

    if (option === null) {
      setFeature(null);
    } else {
      setFeature(option);
    }
  };

  const updateSelectedOptions = (selectedValues, value) => {
    if (!selectedValues.includes(value)) {
      selectedValues.push(value);
    } else {
      selectedValues.splice(selectedValues.indexOf(value), 1);
    }
  };

  const handleAreaCheck = (area, color) => {
    updateSelectedOptions(selectedAreas, `${area.value}__${color}`);
    setSelectedArea([...uniq(selectedAreas)]);
  };

  const handleParentAreaCheck = (event, area, color) => {
    const subAreas = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subAreas.push(`${area.value}__${color}`);
      area.sub?.forEach((f1) => {
        subAreas.push(`${f1.value}__${f1.color}`);
      });
    } else {
      selectedAreas.splice(selectedAreas.indexOf(`${area.value}__${color}`), 1);
      area.sub?.forEach((f1) => {
        selectedAreas.splice(
          selectedAreas.indexOf(`${f1.value}__${f1.color}`),
          1
        );
      });
    }

    setSelectedArea(uniq([...selectedAreas, ...subAreas]));
  };

  const handleDemCheck = (area) => {
    updateSelectedOptions(selectedDems, area.value);
    setSelectedDem([...uniq(selectedDems)]);
  };

  const handleParentDemCheck = (event, area) => {
    const subDems = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subDems.push(area.value);
      area.sub?.forEach((f1) => {
        subDems.push(f1.value);
      });
    } else {
      selectedDems.splice(selectedDems.indexOf(area.value), 1);
      area.sub?.forEach((f1) => {
        selectedDems.splice(selectedDems.indexOf(f1.value), 1);
      });
    }

    setSelectedDem(uniq([...selectedDems, ...subDems]));
  };

  const handleParentOlsCheck = (event, ols, color) => {
    const subOls = [];
    const isChecked = event.target.checked;
    if (isChecked) {
      subOls.push(`${ols.value}__${color}`);
      ols.sub?.forEach((f1) => {
        subOls.push(`${f1.value}__${f1.color}`);
      });
    } else {
      selectedOls.splice(selectedOls.indexOf(`${ols.value}__${color}`), 1);
      ols.sub?.forEach((f1) => {
        selectedOls.splice(selectedOls.indexOf(`${f1.value}__${f1.color}`), 1);
      });
    }

    setSelectedOls(uniq([...selectedOls, ...subOls]));
  };

  const handleOlsCheck = (ols, color) => {
    updateSelectedOptions(selectedOls, `${ols.value}__${color}`);

    ols.sub?.forEach((f1) => {
      updateSelectedOptions(selectedOls, `${f1.value}__${f1.color}`);
    });

    setSelectedOls(uniq([...selectedOls]));
  };

  const handleSelectAllDems = () => {
    const allDems = [];
    demOptions.forEach((f0) => {
      allDems.push(f0.value);

      f0.sub?.forEach((f1) => {
        allDems.push(f1.value);
      });
    });

    setSelectedDem(uniq([...allDems]));
  };

  const handleSelectAllArea = () => {
    const allArea = [];
    areaOptions.forEach((f0) => {
      allArea.push(`${f0.value}__${f0.color}`);

      f0.sub?.forEach((f1) => {
        allArea.push(`${f1.value}__${f1.color}`);
      });
    });

    setSelectedArea(uniq([...allArea]));
  };

  const handleSelectAllOls = () => {
    const allOls = [];
    OLSOptions.forEach((f0) => {
      allOls.push(`${f0.value}__${f0.color}`);

      f0.sub?.forEach((f1) => {
        allOls.push(`${f1.value}__${f1.color}`);
      });
    });

    setSelectedOls(uniq([...allOls]));
  };

  const handleSelectAll = () => {
    if (selctedFeature?.value === "OLS") {
      handleSelectAllOls();
    } else {
      handleSelectAllArea();
      handleSelectAllDems();
    }
  };

  const handleClearAll = () => {
    setSelectedArea([]);
    setSelectedOls([]);
    setSelectedDem([]);
    setShowPenetrationObstacle(false);
  };

  const handleClearAllArea = () => {
    setSelectedArea([]);
  };

  const handleClearAlldem = () => {
    setSelectedDem([]);
  };

  const handleClearAllOls = () => {
    setSelectedOls([]);
  };

  const handleLogin = () => navigate(routeNames.Login);

  const handleAirportDetail = () => {
    navigate(`/etod/airport-detail/${selectedAirportDetail?.id}`);
  };

  const handleAirportSelect = (option) => {
    if (option === null) {
      setSelectedAirportDetail(null);
    } else {
      setSelectedAirportDetail(option);
    }
  };

  const handleGenerateAirportSurface = () => {
    navigate(routeNames.EtodAirportGenerateSurface);
  };

  const handleEmptySurfaceTitle = () => {
    setSurafceTitle(null);
  };

  const handleCancelProceedOls = () => {
    setSurafceTitle("Obstacle Analysis");
    setShowProceedObstacleModal(false);
    setShowVisualSurveyBtn(false);
    setShowVisualSurveyModal(false);
  };
  const handleSuccessProceedOls = () => {
    handleCancelProceedOls();
    setShowProceedbtn(false);
    setShowObstacleAirportFeature(true);
  };

  const handleCancelVisualSurvey = () => {
    setSurafceTitle("Generate Surfaces");
    setSuccessProceedObstacle(false);
    setShowVisualSurveyModal(false);
  };

  const handleUploadProceedObstacle = (file) => {
    setShowObstacleAirportFeature(false);
    setVisualUploadProceedObstacle(true);
    setShowProceedbtn(true);
    setShowRWYrightSidebar(false);
    setShowVisualSurveyModal(false);
    setShowVisualSurveyBtn(false);
    setUploadedObstacelFile(file);
  };

  const handleOpenVisualSurveyModal = () => {
    setSurafceTitle("Visualise Surfaces");
    setShowVisualSurveyModal(true);
  };

  const handleProceedVisualObstacle = () => {
    setOpenConfirmProceedObstacleModal(true);
  };

  const callbackObstacleAnalysis = (status) => {
    if (status) {
      dispatch(getEtodAllAirport());
      setSuccessProceedObstacle(true);
      setProceedTitle("Success!");
      setProceedDescription(
        `Click on view to ${analysisObstacles.length} visualise obstacle analysis for selected Airport`
      );
    } else {
      setProceedTitle("Error!");
      setProceedDescription(
        "Sorry!! We didn’t find any obstacle analysis for selected Airport. Request Surveyor to upload survey data for selected Airport."
      );
    }
  };

  const handleSuccessUpload = (status) => {
    setUploadedObstacelFile(null);
    dispatch(setVisualiseObstacles([]));
    callbackObstacleAnalysis(status);
  };

  const handleConfrimUploadObstacle = () => {
    if (!uploadedObstacelFile) {
      toast.error("File not Upload");
      return;
    }

    setOpenConfirmProceedObstacleModal(false);
    setProceedTitle("Checking...");
    setProceedDescription("Checking obstacle analysis for selected Airport");
    setShowProceedObstacleModal(true);
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        uploadeObstacleFileWithoutReview(
          createlayerAirportData.id,
          uploadedObstacelFile,
          token,
          handleSuccessUpload
        )
      );
    });
  };

  const handleCancelUploadObstacel = () => {
    setOpenConfirmProceedObstacleModal(false);
  };

  const handleBackGenerateSurface = () => {
    setOpenConfirmProceedObstacleModal(false);
    setVisualUploadProceedObstacle(false);
    setShowProceedObstacleModal(false);
    setShowVisualSurveyBtn(true);
    setProceedTitle("");
    setProceedDescription("");
    setUploadedObstacelFile(null);
    setShowProceedbtn(false);
    dispatch(setVisualiseObstacles([]));
    dispatch(setAnalysisObstacles([]));
  };

  return (
    <div className="wrapper">
      <Header active="etod" />
      <div className="main-content">
        <EtodSideBar
          isLoadingEtodAirport={isLoadingEtodAirport}
          isLoadingArea1={isLoadingArea1}
          isLoadingExportObstacle={isLoadingExportObstacle}
          isLoadingEtodLayers={isLoadingEtodLayers}
          isLoadingEtodDems={isLoadingEtodDems}
          allAirports={allAirports}
          selectedAreas={selectedAreas}
          selctedAirport={selctedAirport}
          isActiveAirport={!selctedAirport}
          selctedFeature={selctedFeature}
          selectedOls={selectedOls}
          olsOptions={OLSOptions}
          areaOptions={areaOptions}
          selectedAirportFeature={selectedAirportFeature}
          handleAirport={handleAirport}
          handleFeature={handleFeature}
          handleAreaCheck={handleAreaCheck}
          handleSelectAll={handleSelectAll}
          handleClearAll={handleClearAll}
          handleParentOlsCheck={handleParentOlsCheck}
          handleOlsCheck={handleOlsCheck}
          isShowPenetrationObstacle={isShowPenetrationObstacle}
          handlePenetrationObstacleToggle={handlePenetrationObstacleToggle}
          handleParentAreaCheck={handleParentAreaCheck}
          handle3DMap={handle3DMap}
          is3DMap={is3DMap}
          demOptions={demOptions}
          etodDems={etodDems}
          selectedDems={selectedDems}
          handleParentDemCheck={handleParentDemCheck}
          handleDemCheck={handleDemCheck}
          handleSelectAllArea={handleSelectAllArea}
          handleSelectAllDems={handleSelectAllDems}
          handleSelectAllOls={handleSelectAllOls}
          handleClearAllArea={handleClearAllArea}
          handleClearAlldem={handleClearAlldem}
          handleClearAllOls={handleClearAllOls}
          surafceTitle={surafceTitle}
          isShowVisualSurveyBtn={isShowVisualSurveyBtn}
          isVisualUploadProceedObstacle={isVisualUploadProceedObstacle}
          isShowProceedbtn={isShowProceedbtn}
          isShowObstacleAirportFeature={isShowObstacleAirportFeature}
          handleProceedVisualObstacle={handleProceedVisualObstacle}
          handleOpenVisualSurveyModal={handleOpenVisualSurveyModal}
          handleBackGenerateSurface={handleBackGenerateSurface}
          handleEmptySurfaceTitle={handleEmptySurfaceTitle}
          areaAnalysisObstacle={areaAnalysisObstacle}
          olsAnalysisObstacle={olsAnalysisObstacle}
        />
        <div className="main">
          <div className="content-area">
            {!selctedAirport && (!currentUser || !accessToken) && (
              <div className="welcome-etod">
                <div style={{ paddingTop: 60 }}>
                  <Typography
                    component="div"
                    sx={styles.etodLabelfs48}
                    align="center"
                  >
                    WELCOME
                  </Typography>
                  <Typography
                    component="div"
                    sx={styles.etodLabelfs24}
                    align="center"
                  >
                    Data defining eTOD will be displayed here
                  </Typography>
                </div>
                <div
                  style={{ marginTop: 160, marginBottom: 40 }}
                  align="center"
                >
                  <Typography component="div" sx={styles.etodLabelfs24}>
                    To Generate new eTOD, please login as Admin user
                  </Typography>
                </div>
                <div>
                  <StyledButton
                    className="etod-dashboard-btn"
                    label="Login"
                    onClick={() => handleLogin()}
                  />
                </div>
              </div>
            )}
            {!selctedAirport &&
              currentUser &&
              accessToken &&
              currentUser.role !== roles.admin && (
                <div className="welcome-etod">
                  <div style={{ paddingTop: 60 }}>
                    <Typography
                      component="div"
                      sx={styles.etodLabelfs48}
                      align="center"
                    >
                      WELCOME
                    </Typography>
                    <Typography
                      component="div"
                      sx={styles.etodLabelfs24}
                      align="center"
                    >
                      Data defining eTOD will be displayed here
                    </Typography>
                  </div>
                </div>
              )}
            {!selctedAirport &&
              currentUser &&
              accessToken &&
              currentUser.role === roles.admin && (
                <div className="main-new-airport">
                  <Typography component="p">
                    To Generate a new Airport data, please select an airport
                    from the below list
                  </Typography>
                  <div className="inr-new-airport">
                    <Select
                      isClearable
                      isLoading={isLoadingEtodAixmAirports}
                      isDisabled={isLoadingEtodAixmAirports}
                      value={selectedAirportDetail || null}
                      placeholder="Select..."
                      className="select-new-airport"
                      classNamePrefix="select"
                      onChange={handleAirportSelect}
                      options={etodAixmAirports}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={commonStyles.selectScrollbar}
                    />
                    <StyledButton
                      label="See Details"
                      className="see-details-btn"
                      disabled={!selectedAirportDetail}
                      onClick={() => handleAirportDetail()}
                    />
                  </div>
                  <Box
                    className="inr-new-airport"
                    sx={{ mt: 0, flexDirection: "column" }}
                  >
                    <Typography>
                      To Generate Surfaces based on User Input
                    </Typography>
                    <Box sx={styles.userInputBtn}>
                      <NavButton
                        icon={
                          <UserSurfaceGen color={theme.palette.black.light} />
                        }
                        label="USER DEFINED SURFACE GENERATION"
                        onClick={handleGenerateAirportSurface}
                      />
                    </Box>
                  </Box>
                </div>
              )}
            {!is3DMap && selctedAirport && (
              <EtodMap
                isEtod={isEtod}
                airportLocation={airportLocation}
                zoomLevel={zoomLevel}
                selctedAirport={selctedAirport}
                colors={colorMap}
                etodAreaList={etodAreaListData}
                etodArea2D={etodArea2D}
                selectedAreas={selectedAreas}
                area1Data={area1Data}
                selectedOls={selectedOls}
                OLSApproachList={OLSApproachList}
                OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
                OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
                OLSOuterTransitionalSurfaceList={
                  OLSOuterTransitionalSurfaceList
                }
                OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
                OLSRwyStripList={OLSRwyStripList}
                OLSTakeOffList={OLSTakeOffList}
                OLSConicalSurfaceList={OLSConicalSurfaceList}
                isShowPenetrationObstacle={isShowPenetrationObstacle}
                etodDems={etodDems}
                selectedDems={selectedDems}
                visualiseObstacles={visualiseObstacles}
                areaAnalysisObstacle={areaAnalysisObstacle}
                olsAnalysisObstacle={olsAnalysisObstacle}
                createlayerAirportData={createlayerAirportData}
                isShowRWYrightSidebar={isShowRWYrightSidebar}
              />
            )}
            {is3DMap && selctedAirport && (
              <Etod3dMap
                isEtod={isEtod}
                airportLocation={airportLocation}
                zoomLevel={zoomLevel}
                selctedAirport={selctedAirport}
                colors={colorMap}
                etodAreaList={etodAreaListData}
                etodArea2D={etodArea2D}
                selectedAreas={selectedAreas}
                area1Data={area1Data}
                selectedOls={selectedOls}
                OLSApproachList={OLSApproachList}
                OLSHorizontalSurfaceList={OLSHorizontalSurfaceList}
                OLSTransitionalSurfaceList={OLSTransitionalSurfaceList}
                OLSOuterTransitionalSurfaceList={
                  OLSOuterTransitionalSurfaceList
                }
                OLSObstacleFreeZoneList={OLSObstacleFreeZoneList}
                OLSRwyStripList={OLSRwyStripList}
                OLSTakeOffList={OLSTakeOffList}
                OLSConicalSurfaceList={OLSConicalSurfaceList}
                isShowPenetrationObstacle={isShowPenetrationObstacle}
                etodDems={etodDems}
                selectedDems={selectedDems}
                visualiseObstacles={visualiseObstacles}
                areaAnalysisObstacle={areaAnalysisObstacle}
                olsAnalysisObstacle={olsAnalysisObstacle}
              />
            )}
          </div>
        </div>
      </div>
      <VisualSurveyUploadModal
        isOpen={isShowVisualSurveyModal}
        title="Visualise Survey Data without review"
        description="Upload the xls file for visualising"
        okBtnText="Download Template"
        cancelBtnText="Cancel"
        handleCancel={() => handleCancelVisualSurvey()}
        handleUpload={(file) => handleUploadProceedObstacle(file)}
      />
      <ProceedObstacleAnalysisModal
        isOpen={isShowProceedObstacleModal}
        isSuccess={isSuccessProceedObstacle}
        title={proceedTitle}
        description={proceedDescription}
        successBtnText="View"
        cancelBtnText="Cancel"
        handleCancel={() => handleCancelProceedOls()}
        handleSuccess={() => handleSuccessProceedOls()}
      />
      <ConfirmationDialog
        fullWidth
        open={isOpenConfirmProceedObstacleModal}
        title="Proceed to Obstacles Analysis"
        content="Click on confirm to proceed to obstacle analysis for uploaded survey data"
        saveButtonLabel="Confirm"
        isLoading={false}
        isDisabled={false}
        handleSave={() => handleConfrimUploadObstacle()}
        handleCancel={() => handleCancelUploadObstacel()}
      />
    </div>
  );
}
