import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Checkbox,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { includes, remove } from "lodash";
import { ACCESS_TOKEN } from "@constants/constant";
import { OutlinedButton, PrimaryButton } from "@components/button";
import { airspaceSelectExportType } from "@constants/airspaceData";
import { airspaceSelectExportTypeExcept } from "@constants/airspaceData";
import { exportAirspaceGeoJSONData } from "@store/airspace/actions";
import { styles } from "@styles/aixmStyles";
import { theme } from "@styles/theme";
import { getAsyncStorageValue, getStorageValue } from "@utils/localStorage";

export default function EnrouteExportDataModal({
  isOpen,
  handleCancel,
  title = "Export GeoJSON",
  description = "Download GeoJSON for any category from the below",
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSession.user);
  const accessToken = getStorageValue(ACCESS_TOKEN);
  const isLoadingEnrouteExportJson = useSelector(
    (state) => state.airspace.isLoadingEnrouteExportJson
  );
  const [selected, setSelected] = useState([]);

  const handleSuccessExportData = () => {
    const payload = { categories: selected };
    getAsyncStorageValue(ACCESS_TOKEN).then((token) => {
      dispatch(
        exportAirspaceGeoJSONData(payload, token, handleCancelExportModal)
      );
    });
  };

  const handleCancelExportModal = () => {
    setSelected([]);
    handleCancel();
  };

  const handleClick = (value) => {
    if (includes(selected, value)) {
      remove(selected, (type) => {
        return type === value;
      });
    } else {
      selected.push(value);
    }
    setSelected([...selected]);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.exportDataModal}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={styles.exportDataModalTitle}
        >
          {title}
        </Typography>
        <Typography
          id="modal-modal-title"
          component="span"
          sx={{ fontSize: 16, fontWeight: 400, lineHeight: "19px" }}
        >
          {description}
        </Typography>
        <Box sx={{ mt: 2.5, mb: 6 }}>
          <Table aria-labelledby="tableTitle">
            <TableBody>
              {airspaceSelectExportType.map((type) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={type.id}>
                    <TableCell
                      padding="checkbox"
                      sx={styles.enrouteExportTable}
                    >
                      <Checkbox
                        onClick={() => handleClick(type.value)}
                        disabled={
                          (airspaceSelectExportTypeExcept.includes(
                            type.value
                          ) &&
                            !currentUser?.user_access?.export) ||
                          !accessToken
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      sx={{
                        borderBottom: `0.5px solid ${theme.palette.grey.borderclr}`,
                        fontSize: 18,
                        fontWeight: 500,
                        color: includes(selected, type.value)
                          ? theme.palette.primary.main
                          : theme.palette.grey.light,
                      }}
                    >
                      {type.label}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Box sx={styles.exportDataModalExportBtn}>
          <PrimaryButton
            label="Export"
            disabled={selected.length === 0 || isLoadingEnrouteExportJson}
            isLoading={isLoadingEnrouteExportJson}
            sx={{ width: 98, p: "2.75px 16px", mr: 3 }}
            onClick={handleSuccessExportData}
          />
          <OutlinedButton
            label="Cancel"
            disabled={isLoadingEnrouteExportJson}
            sx={{ width: 98, p: "1.75px 16px" }}
            onClick={handleCancelExportModal}
          />
        </Box>
      </Box>
    </Modal>
  );
}
