import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { StyledTypographyV2 } from "@components/core/StyledTypography";
import HeaderTableCell from "@components/tabel-component/HeaderTableCell";
import StyledTableCell from "@components/tabel-component/StyledTableCell";
import { styles } from "@styles/aixmStyles";

function PowerLightsSupply({ selctedAirport, activeCategory, powerlights }) {
  if (isEmpty(activeCategory) && isEmpty(selctedAirport)) return null;

  return (
    <Fragment key={activeCategory.label}>
      <Paper sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell colSpan={2}>
                {selctedAirport.name} {">"} {activeCategory.label}
              </HeaderTableCell>
            </TableRow>
          </TableHead>
          {powerlights ? (
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Location, characteristics and hours of operation of aerodrome
                  beacon/identification beacon (if any)
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "80%",
                    borderLeft: "0.5px solid #dcdbdc",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <Box sx={{ p: 0, pr: 0 }}>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        alignItems: "center",
                        paddingTop: 0,
                        paddingBottom: 1,
                        borderColor: "#dcdbdc",
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        ABN:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {powerlights?.groundlightdescrpt || "NIL"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        borderBottom: 0,
                        paddingBottom: 0,
                        paddingTop: 1,
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        IBN:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        NIL
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Location and lighting (if any) of anemometer/landing direction
                  indicator;
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "80%",
                    borderLeft: "0.5px solid #dcdbdc",
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderColor: "#dcdbdc",
                  }}
                >
                  <Box sx={{ p: 0, pr: 0 }}>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        alignItems: "center",
                        paddingTop: 0,
                        paddingBottom: 1,
                        borderColor: "#dcdbdc",
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        LDI:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {powerlights?.landingDirectiondescrpt || "NIL"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        borderBottom: 0,
                        paddingBottom: 0,
                        paddingTop: 1,
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        Anemometer:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {powerlights?.windDirectiondescrpt || "NIL"}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Taxiway edge and taxiway centre line lights;
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    width: "80%",
                    borderLeft: "0.5px solid #dcdbdc",
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <Box sx={{ p: 0, pr: 0 }}>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        alignItems: "center",
                        paddingTop: 0,
                        paddingBottom: 1,
                        borderColor: "#dcdbdc",
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        Edge:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {powerlights?.taxiway_edge_lights || "NIL"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        ...styles.administrativePhone,
                        borderBottom: 0,
                        paddingBottom: 0,
                        paddingTop: 1,
                      }}
                    >
                      <StyledTypographyV2
                        variant="span"
                        sx={{ paddingLeft: "10px", minWidth: "150px" }}
                      >
                        Centre Line:
                      </StyledTypographyV2>
                      <Typography variant="span" sx={{ fontSize: "14px" }}>
                        {powerlights?.taxiway_CL_lights || "NIL"}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>
                  Secondary power supply including switch-over time;
                </StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {powerlights?.secondaryPowerSupplydescrpt || "NIL"}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={{ width: "30%" }}>Remarks</StyledTableCell>
                <StyledTableCell sx={{ width: "80%" }}>
                  {powerlights?.remarks || "NIL"}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center">No data found.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </Fragment>
  );
}

export default PowerLightsSupply;
